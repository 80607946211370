// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"menuColor": "rgba(255, 255, 255, 0.65)",
	"menuLightColor": "rgba(0, 0, 0, 0.7)",
	"menuColorActive": "#fff",
	"menuBackground": "#001529",
	"menuLightBackground": "#ffffff",
	"subMenuBackground": "#000c17",
	"subMenuHover": "#001528",
	"sideBarWidth": "242px",
	"logoTitleColor": "#ffffff",
	"logoLightTitleColor": "#001529"
};
module.exports = exports;
