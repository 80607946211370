const importCol = (ctx) => {
  return [
    {
      label: "模块",
      code: "importTaskKey",
      width:120,
      render: (h, row) => {
        let item = ctx.taskDicts.find(el => {
          return el.value == row.importTaskKey
        })
        return <span>{item.label}</span>;
      },
    },
    {
      label: "文件名称",
      code: "fileName",
      width:210,
      render: (h, row) => {
        return (
          <div class="flex-row" style="align-items:center">
            <img style="width:16px;height:16x;margin-right:5px" src={require("@/assets/images/crm/excel.png")} />
            {row.fileName}
          </div>)
      },
    },
    {
      label: "操作时间",
      code: "createTime",
      width:150,
      render: (h, row) => {
        return <span>{ctx.parseTime(row.createTime)}</span>;
      },
    },
    {
      label: "操作人",
      code: "userId",
      width:100,
      render: (h, row) => {
        let item = ctx.userList.find((element) => {
          return element.userId == row.userId;
        });
        return <span>{item ? item.userName : "-"}</span>;
      },
    },
    {
      label: "任务阶段",
      code: "error",
      render: (h, row) => {
        let percent = 0
        if(row.isSuccess){
          percent = 100
        }else{
          if(row.totalNum == 0 || row.successNum < 0){
            percent = 0
          }else{
            percent = ctx.formatAmount((row.successNum/row.totalNum) || 0)*100
          }
        }
        return (
            <div style="height:60px;display:flex;flex-direction:column;justify-content:center">
                <div>总数{row.totalNum}条，成功{row.successNum}条</div>
                <div style={{color:'red',display:row.errorUrl ? 'block' : 'none'}}>失败{row.errorNum}条</div>
                <el-progress percentage={percent}></el-progress>
            </div>
        )
      },
    },
  ];
};

const includeCol = (ctx) => {
  return [
    {
      label: ctx.$t("title5342"),
      code: "uidOrLink",
      width: 400,
      render: (h, row) => {
        let str = null;
        if (row.region) {
          str = (
            <div>
              <img
                style="margin-right: 2px"
                src={ctx.country[row.region].img}
              />
              {ctx.country[row.region].name}
            </div>
          );
        }
        return (
          <div style={{ display: "flex" }}>
            <img
              src={row.avatar}
              style={{
                width: "48px",
                height: "48px",
                display: row.avatar ? "block" : "none",
                borderRadius: "50%",
              }}
            ></img>
            <img
              style={{
                width: "48px",
                height: "48px",
                display: row.avatar ? "none" : "block",
                borderRadius: "50%",
              }}
              src={require("@/assets/images/noimg.png")}
            ></img>
            <div class="flex-col" style={{ marginLeft: "10px",justifyContent:'center' }}>
              <div>
                <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">
                       <span>{ row.nickName }</span>
                       <img style="width: 12px;cursor: pointer;margin-left: 5px;" id={`nickName${row.id}`} onClick={ctx.navigatorCopy.bind(ctx, row.nickName)} src={require("@/assets/images/crm/copy-white.png")}/>
                    </div>
                    <span class="kolName" style="font-size:14px;font-weight:600;color: #333333;cursor:pointer">{row.nickName}</span>
                </el-tooltip>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {row.type == "1" ? "ID：" : null}
                {row.uidOrLink}
                <img
                  style={{ width: "12px", cursor: "pointer", marginLeft:"7px" }}
                  id={`uidOrLink${row.id}`}
                  onClick={ctx.copy.bind(ctx, row, "uidOrLink")}
                  src={require("@/assets/images/crm/copy.png")}
                />
              </div>
              {str}
            </div>
          </div>
        );
      },
    },
    {
      label: ctx.$t("title5410"),
      code: "type",
      render: (h, row) => {
        return <span>{row.type == 1 ? "达人ID" : "达人主页链接"}</span>;
      },
    },
    {
      label: ctx.$t("title2924"),
      code: "createTime",
      render: (h, row) => {
        return <span>{ctx.parseTime(row.createTime)}</span>;
      },
    },
    {
      label: ctx.$t("title5411"),
      code: "status",
      render: (h, row) => {
        const map = {
          "-1": {
            label: ctx.$t("title5414"),
            color: "#eb4242",
          },
          0: {
            label: ctx.$t("title5413"),
            color: "#eb4242",
          },
          1: {
            label: ctx.$t("title5412"),
            color: "#03cbc4",
          },
        };
        return (
          <div>
            <li style={{ color: map[row.status].color }}>
              {map[row.status].label}
            </li>
            <div style={{ display: row.status == -1 ? "block" : "none" }}>
              原因:{row.failReason}
            </div>
          </div>
        );
      },
    },
  ];
};

export { importCol, includeCol };
