import request from '@/utils/request'
import { getRefreshToken } from '@/utils/auth'
import service from '@/utils/request'

// 登录方法
export function login(username, password, captchaVerification, socialType, socialCode, socialState) {
  const data = {
    username,
    password,
    captchaVerification,
    // 社交相关
    socialType,
    socialCode,
    socialState
  }
  return request({
    url: '/system/auth/login',
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/system/auth/get-permission-info',
    method: 'get'
  })
}

// 退出方法
export function logout(isRunas) {
  let headers = {};
  if (isRunas) {
    headers = {"runas": true};
  }
  return request({
    headers: headers,
    url: '/system/auth/logout',
    method: 'post'
  })
}

// 社交授权的跳转
export function socialAuthRedirect(type, redirectUri) {
  return request({
    url: '/system/auth/social-auth-redirect?type=' + type + '&redirectUri=' + redirectUri,
    method: 'get'
  })
}

// 社交快捷登录，使用 code 授权码
export function socialLogin(type, code, state) {
  return request({
    url: '/system/auth/social-login',
    method: 'post',
    data: {
      type,
      code,
      state
    }
  })
}

// 获取登录验证码
export function sendSmsCode(mobile, scene,captchaVerification) {
  return request({
    url: '/system/auth/send-sms-code',
    method: 'post',
    data: {
      mobile,
      scene,
      captchaVerification
    }
  })
}
// 发送手机验证码
export function tenantSendSmsCode(mobile, scene,captchaVerification) {
  return request({
    url: '/system/tenant/send-sms-code',
    method: 'post',
    data: {
      mobile,
      scene,
      captchaVerification
    }
  })
}
// 租户自助注册
export function tenantRegister(data) {
  return request({
    url: '/erp/tenant/register',
    method: 'post',
    data: data
  })
}
// 租户修改登录密码
export function tenantResetPwd(data) {
  return request({
    url: '/system/tenant/reset-pwd',
    method: 'post',
    data: data
  })
}

// 短信验证码登录
export function smsLogin(mobile, code) {
  return request({
    url: '/system/auth/sms-login',
    method: 'post',
    data: {
      mobile,
      code
    }
  })
}
// 获取二维码
export function generateQrCode(params) {
  return request({
    url: '/mp/account/generate-qr-code',
    method: 'put',
    params
  })
}
// 二维码轮询
export function wxScanLogin(params) {
  return request({
    url: '/system/auth/wx-scan-login',
    method: 'get',
    params
  })
}

// 刷新访问令牌
export function refreshToken() {
  return service({
    url: '/system/auth/refresh-token?refreshToken=' + getRefreshToken(),
    method: 'post'
  })
}

//获取文章简要详情-服务条款
export function getArticleDetail(id) {
  // 发起请求
  return service({
    url: '/system/article/get-simple?id='+id,
    method: 'get'
  })
}

// ========== OAUTH 2.0 相关 ==========

export function getAuthorize(clientId) {
  return request({
    url: '/system/oauth2/authorize?clientId=' + clientId,
    method: 'get'
  })
}

export function authorize(responseType, clientId, redirectUri, state,
  autoApprove, checkedScopes, uncheckedScopes) {
  // 构建 scopes
  const scopes = {}
  for (const scope of checkedScopes) {
    scopes[scope] = true
  }
  for (const scope of uncheckedScopes) {
    scopes[scope] = false
  }
  // 发起请求
  return service({
    url: '/system/oauth2/authorize',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    },
    params: {
      response_type: responseType,
      client_id: clientId,
      redirect_uri: redirectUri,
      state: state,
      auto_approve: autoApprove,
      scope: JSON.stringify(scopes)
    },
    method: 'post'
  })
}
export class socialBindLogin {
}
