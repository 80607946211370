var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel-tab__content" }, [
    _c("div", { staticClass: "element-property input-property" }, [
      _c("div", { staticClass: "element-property__label" }, [
        _vm._v(_vm._s(_vm.$t("title10602"))),
      ]),
      _c(
        "div",
        { staticClass: "element-property__value" },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              size: "mini",
              resize: "vertical",
              autosize: { minRows: 2, maxRows: 4 },
            },
            on: {
              input: _vm.updateDocumentation,
              blur: _vm.updateDocumentation,
            },
            model: {
              value: _vm.documentation,
              callback: function ($$v) {
                _vm.documentation = $$v
              },
              expression: "documentation",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }