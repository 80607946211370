var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm._l(this.getDictDatas2(_vm.type, _vm.value), function (dict, index) {
        return [
          dict.colorType === "default" ||
          dict.colorType === "" ||
          dict.colorType === undefined
            ? _c(
                "span",
                {
                  key: dict.value,
                  class: dict.cssClass,
                  attrs: { index: index },
                },
                [_vm._v(_vm._s(_vm.$t(dict.label)))]
              )
            : _c(
                "el-tag",
                {
                  key: dict.value,
                  class: dict.cssClass,
                  attrs: {
                    "disable-transitions": true,
                    index: index,
                    type: dict.colorType,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t(dict.label)) + " ")]
              ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }