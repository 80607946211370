var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-table-virtual-scroll",
      class: [
        _vm.isExpanding ? "is-expanding" : "",
        _vm.isHideAppend ? "hide-append" : "",
        _vm.scrollPosition ? "is-scrolling-" + _vm.scrollPosition : "",
        _vm.hasFixedRight ? "has-custom-fixed-right" : "",
      ],
    },
    [
      _vm._t("default", null, null, {
        headerCellFixedStyle: _vm.headerCellFixedStyle,
        cellFixedStyle: _vm.cellFixedStyle,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }