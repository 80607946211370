var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar-logo-container", on: { click: _vm.toggleNav } },
    [
      _c("transition", { attrs: { name: "sidebarLogoFade" } }, [
        true
          ? _c("div", { key: "collapse", staticClass: "sidebar-logo-link" }, [
              _c("img", {
                staticClass: "sidebar-logo",
                attrs: { src: _vm.logo },
              }),
            ])
          : _c("div", { key: "expand", staticClass: "sidebar-logo-link" }, [
              _vm.logo
                ? _c("img", {
                    staticClass: "sidebar-logo",
                    class: [_vm.type === "nav" && "small"],
                    attrs: { src: _vm.type === "nav" ? _vm.logo : _vm.logo1 },
                  })
                : _vm._e(),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }