import request from '@/utils/request'

// 创建店铺
export function createShop(data) {
  return request({
    url: '/erp/shop/create',
    method: 'post',
    data: data
  })
}

export function setSyncTime(data) {
  return request({
    url: '/erp/shop/set-sync-time',
    method: 'post',
    data: data
  })
}

// 更新店铺
export function updateShop(data) {
  return request({
    url: '/erp/shop/update',
    method: 'put',
    data: data
  })
}

export function assignDept(data) {
  return request({
    url: '/erp/shop/assign',
    method: 'put',
    data: data
  })
}

export function refreshShopAuth(id) {
  return request({
    url: '/erp/shop/refresh-auth?id=' + id,
    method: 'post'
  })
}

// 删除店铺
export function deleteShop(id) {
  return request({
    url: '/erp/shop/delete?id=' + id,
    method: 'delete'
  })
}

// 获得店铺
export function getShop(id) {
  return request({
    url: '/erp/shop/get?id=' + id,
    method: 'get'
  })
}

export function getAuthUrl(data) {
  return request({
    url: '/erp/shop/get-auth-url',
    method: 'get',
    params: data
  })
}

// 获得店铺分页
export function getShopPage(query) {
  return request({
    url: '/erp/shop/page',
    method: 'get',
    params: query
  })
}

export function getShops(query) {
  return request({
    url: '/erp/shop/list-all',
    method: 'get',
    params: query
  })
}

export function getShopsByPlatform(platformId) {
  return request({
    url: '/erp/shop/list-by-plat',
    method: 'get',
    params: {platformId: platformId}
  })
}

export function getShopDeptList() {
  return request({
    url: '/erp/shop/list-dept',
    method: 'get'
  })
}

// 导出店铺 Excel
export function exportShopExcel(query) {
  return request({
    url: '/erp/shop/export-excel',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}

export function reAuth (id) {
  return request({
    url: `/erp/shop/re-auth?id=${id}`,
    method: 'post'
  })
}

export function adAuth (id) {
  return request({
    url: `/erp/shop/ad-auth?id=${id}`,
    method: 'post'
  })
}
//查询店铺授权
export function shopAdAuth () {
  return request({
    url: '/erp/shop/get-ad-auth',
    method: 'get'
  })
}
//批量更新仓库
export function batchUpdateWarehouse (data) {
  return request({
    url: '/erp/shop/batch-update-warehouse',
    method: 'put',
    data: data
  })
}
