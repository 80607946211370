<template>
  <section :class="['app-main',
  (key==='/index/home'||~key.indexOf('/data/plate'))&&'home',
  (key==='/user/profile'
  ||key==='/user/package'
  ||key==='/product-data/remark-report'
  ||key==='/data/data/data-chart'
  ||key==='/order/shop-order'
  ||key==='/shop-data/shop-health'
  ||key==='/shop-data/shop-cycle'
  ||key==='/shop-data/disk-face'
  ||key==='/order-data/disk-face'
  ||~key.indexOf('/shop-sale/detail')
  ||key==='/shop-data/user-analyze'
  ||key==='/goods/create'
  ||key==='/index/thali'
  ||key==='/index/ad'
  ||key==='/shop-data/report'
  ||key==='/finance/operator'
  ||key==='/finance/report'
  ||key==='/system/profit-set'
  ||key==='/crm/order/list'
  ||key==='/crm/cooperation-detail'
  ||key==='/performance/index'
  ||key==='/ad/ad-overview'
  ||~key.indexOf('user-info')
  ||key==='/finance/offline-out')&&'height-auto',
  key==='/finance/shop-order'&&'app-main-scroll'
  ]">
    <!-- <breadcrumb id="breadcrumb-container" class="breadcrumb-container" /> -->
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view :key="key" />
      </keep-alive>
    </transition>
  </section>
</template>

<script>
  import Breadcrumb from '@/components/Breadcrumb'
export default {
  name: 'AppMain',
  components:{Breadcrumb,},
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 500px) {
  .app-main {
    min-width: 100%;
    min-height: 900px;
    overflow: hidden;
    overflow-x: scroll;
    overflow-y: scroll;
  }
}
.app-main {
  /* 50= navbar  50  */
  padding-top:10px;
	height:calc(100vh - 86px);
  position: relative;
  width:100%;
  overflow:hidden;
  &.home{
    height:auto;
    background:#f4f7fc;
    width:auto;
  }
  &.height-auto{
    height:auto;
  }
  &.app-main-scroll{
    overflow-y: auto;
  }
}

.fixed-header+.app-main {
  padding-top: 50px;
}

</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
