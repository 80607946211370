import Vue from "vue"
import VueI18n from "vue-i18n"
import zh from './langs/zh'
import en from './langs/en'
import id from './langs/id'
import th from './langs/th'
import vi from './langs/vi'
import ElementLocale from "element-ui/lib/locale"
import enLocale from "element-ui/lib/locale/lang/en"
import zhLocale from "element-ui/lib/locale/lang/zh-CN"
import idLocale from "element-ui/lib/locale/lang/id"
import thLocale from "element-ui/lib/locale/lang/th"
import viLocale from "element-ui/lib/locale/lang/vi"


ElementLocale.i18n((key, value) => i18n.t(key, value))
Vue.use(VueI18n)
 let lang=localStorage.getItem('xinjianLang') || 'cn'
const i18n = new VueI18n({
  locale: lang,
  messages: {
    'cn': {
      ...zh,
      ...zhLocale
    },
    'en': {
      ...en,
      ...enLocale
    },
    'id': {
      ...id,
      ...idLocale
    },
    'th': {
      ...th,
      ...thLocale
    },
    'vn': {
      ...vi,
      ...viLocale
    },
  },
  silentTranslationWarn: true,
  globalInjection: true,
  fallbackLocale: lang
})
export {lang};
export default i18n
