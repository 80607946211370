var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-tab__content" },
    [
      _c(
        "el-form",
        {
          attrs: { size: "mini", "label-width": "90px" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("title10625") } },
            [
              _c("el-checkbox", {
                attrs: { label: _vm.$t("title10591") },
                on: { change: _vm.changeTaskAsync },
                model: {
                  value: _vm.taskConfigForm.asyncBefore,
                  callback: function ($$v) {
                    _vm.$set(_vm.taskConfigForm, "asyncBefore", $$v)
                  },
                  expression: "taskConfigForm.asyncBefore",
                },
              }),
              _c("el-checkbox", {
                attrs: { label: _vm.$t("title10592") },
                on: { change: _vm.changeTaskAsync },
                model: {
                  value: _vm.taskConfigForm.asyncAfter,
                  callback: function ($$v) {
                    _vm.$set(_vm.taskConfigForm, "asyncAfter", $$v)
                  },
                  expression: "taskConfigForm.asyncAfter",
                },
              }),
              _vm.taskConfigForm.asyncAfter || _vm.taskConfigForm.asyncBefore
                ? _c("el-checkbox", {
                    attrs: { label: _vm.$t("title10593") },
                    on: { change: _vm.changeTaskAsync },
                    model: {
                      value: _vm.taskConfigForm.exclusive,
                      callback: function ($$v) {
                        _vm.$set(_vm.taskConfigForm, "exclusive", $$v)
                      },
                      expression: "taskConfigForm.exclusive",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            _vm.witchTaskComponent,
            _vm._b({ tag: "component" }, "component", _vm.$props, false)
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }