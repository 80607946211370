var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "16px" } },
    [
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("title10800") } },
        [
          _c("el-input", {
            attrs: { clearable: "" },
            on: {
              change: function ($event) {
                return _vm.updateElementTask("dueDate")
              },
            },
            model: {
              value: _vm.userTaskForm.dueDate,
              callback: function ($$v) {
                _vm.$set(_vm.userTaskForm, "dueDate", $$v)
              },
              expression: "userTaskForm.dueDate",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("title10817") } },
        [
          _c("el-input", {
            attrs: { clearable: "" },
            on: {
              change: function ($event) {
                return _vm.updateElementTask("followUpDate")
              },
            },
            model: {
              value: _vm.userTaskForm.followUpDate,
              callback: function ($$v) {
                _vm.$set(_vm.userTaskForm, "followUpDate", $$v)
              },
              expression: "userTaskForm.followUpDate",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("title10802") } },
        [
          _c("el-input", {
            attrs: { clearable: "" },
            on: {
              change: function ($event) {
                return _vm.updateElementTask("priority")
              },
            },
            model: {
              value: _vm.userTaskForm.priority,
              callback: function ($$v) {
                _vm.$set(_vm.userTaskForm, "priority", $$v)
              },
              expression: "userTaskForm.priority",
            },
          }),
        ],
        1
      ),
      _vm._v(" " + _vm._s(_vm.$t("title10818")) + " "),
      _c(
        "router-link",
        { attrs: { target: "_blank", to: { path: "/bpm/manager/model" } } },
        [
          _c("el-link", { attrs: { type: "danger" } }, [
            _vm._v(_vm._s(_vm.$t("title10819"))),
          ]),
        ],
        1
      ),
      _vm._v(
        " " +
          _vm._s(_vm.$t("title10820")) +
          " 7 " +
          _vm._s(_vm.$t("title10821")) +
          " "
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }