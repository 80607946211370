export default {
  menu: {
    '单量套餐': "Layanan Berlangganan",
    "利润月报": "Laporan bulanan keuntungan",
    "调拨": "Pemindahan",
    "入库": "Penambahan Stok",
    "店铺利润报表": "Laporan keuntungan toko",
    "Shopee店铺数据表": "Shopee Tabel data toko",
    "业绩利润报表": "Laporan keuntungan kinerja",
    "Shopee商品数据表": "Shopee Tabel data produk",
    '首页': "Halaman Beranda",
    '订单地区分布': 'Distribusi wilayah pesanan',
    '店铺分析': "Laporan Toko",
    '店铺销售日历': "Statistik Pesanan",
    '店铺销售分析': " Analitika Penjualan Toko",
    '店铺利润分析': "Analitika Keuntungan Toko",
    '店铺周期性对比': "Kontras Penjualan Periodik",
    '账户健康状况': "Kesehatan Toko",
    '客户分析': "Analitika Pelanggan",
    '商品分析': "Analitika Produk",
    '商品数据分析': " Analisis Data Produk",
    '评论分析': "Ulasan Wawasan",
    '分析报告': "Laporan Analitika",
    '数据诊断': "Wawasan Data",
    '物流管理': 'Manajemen Logistik',
    "物流设置": "Pengaturan Pengiriman",
    '订单分析': "Laporan Pesanan",
    '退货报告': "Laporan pengembalian",
    '利润分析': "Analitik Laba",
    '订单统计': 'Statistik Pesanan',
    '订单利润明细': "Detail Keuntungan Pesanan",
    '广告概览': "Ikhtisar iklan",
    "退货退款": "Pengelolaan Retur",
    '广告业绩': "Kinerja iklan",
    '广告采集': "Pengumpulan iklan",
    "广告分析": "Ad analysis",
    '商品配置': "Konfigurasi produk",
    '本地SKU': "SKU lokal",
    '商品配对': "Pencocokan Barang",
    '报表中心': "Pusat Pelaporan",
    '经营看板': "Dasbor bisnis",
    '报表下载': "Unduhan laporan",
    '单量套餐': "Layanan Berlangganan",
    '运营待办': "Daftar tugas operasi",
    '订单管理': "Manajemen Pesanan",
    '订单处理': "Proses Pesanan",
    '退货管理': "Pengelolaan Retur",
    '线上订单': "Pesanan Manual",
    '线下订单': "Retail",
    '运费管理': "Manajemen pengiriman",
    '商品管理': "Manajemen Komoditas",
    '商品SKU': "SKU Gudang",
    'SKU匹配关系': "Hubungan Pemetaan SKU",
    '库龄': "Usia inventaris",
    '成本中心': "Pusat biaya",
    '仓库管理': "Gudang",
    '货架位': "Nomor Rak",
    '商品推送': "Dorongan produk",
    '店铺库存预警': " Stok Peringatan ",
    '库存列表': "Rincian Stok",
    '仓库列表': "Daftar Gudang",
    '仓库盘点': "Penghitungan Stok",
    '出库': "Pengurangan Stok",
    '出入库记录': "Riwayat Keluar/Masuk",
    '采购管理': "Pengelolaan Pembelian",
    '采购单': "Pesanan Pembelian",
    '采购建议': "Saran Pembelian",
    '供应商列表': "Daftar Pemasok",
    '收货管理': "Manajemen Penerimaan",
    '站点': '站点',
    '短信渠道': '短信渠道',
    '短信模板': '短信模板',
    '短信日志': '短信日志',
    '文件列表': '文件列表',
    '文件配置': '文件配置',
    'Banner管理': 'Banner管理',
    '令牌管理': '令牌管理',
    '应用管理': '应用管理',
    '通知公告': '通知公告',
    '文章管理': '文章管理',
    '第三方服务配置': '第三方服务配置',
    '业务参数配置': '业务参数配置',
    '支付': "Pembayaran",
    '商户信息': '商户信息',
    '应用信息': '应用信息',
    '支付订单': '支付订单',
    '退款订单量': "Jumlah Pengembalian Dana Pesanan",
    '监控': '监控',
    'API:日志': "API:日志",
    '访问日志': '访问日志',
    '错误日志': '错误日志',
    '审计日志': '审计日志',
    'MySQL:监控': "'MySQL:监控'",
    'Redis:监控': "Redis:监控",
    'Java:监控': "Java:监控",
    '监控平台': '监控平台',
    'DEV': 'DEV',
    '代码生成': '代码生成',
    '数据源配置': '数据源配置',
    '表单构建': '表单构建',
    '系统接口': '系统接口',
    '数据库文档': '数据库文档',
    '设置': "Pengaturan",
    '租户': '租户',
    '租户列表': '租户列表',
    '租户套餐': '租户套餐',
    '操作日志': "Log Aktivitas",
    '账号管理': "Pengaturan Sub Akun",
    '角色管理': "Manajemen Peran ",
    '子账号管理': "Sub Akun",
    '授权管理': "Manajemen Otorisasi",
    '账号管理': "Pengaturan Sub Akun",
    '店铺管理': "Integrasi",
    '仓库授权': "Otorisasi Penyedia Layanan",
    '设置': "Pengaturan",
    '预估利润设置': "Pengaturan Perkiraan Keuntungan",
    '测评参数': "Parameter evaluasi",
    '汇率设置': "Pengaturan Kurs",
    '标签管理': "Pengelolaan Penandaan",
    '订单导出模板': "Template ekspor pesanan",
    '下载中心': '下载中心',
    '超级管理员': "Super administrator",
    '用户管理': "Manajemen pengguna",
    '系统角色管理': '系统角色管理',
    '菜单管理': '菜单管理',
    '字典管理': '字典管理',
    '定时任务': '定时任务',
    '公告中心': '公告中心',
    '租户订单': '租户订单',
    '三方仓': "Gudang Pihak Ketiga",
    '三方仓厂商管理': "Penyedia Layanan Gudang",
    '三方仓授权仓库管理': "Gudang otorisasi",
    '其他': "Lainnya",
    '部门管理': '部门管理',
    '岗位管理': '岗位管理',
    '配置管理': '配置管理',
    '错误码管理': '错误码管理',
    '敏感词管理': '敏感词管理',
    '地区管理': '地区管理',
    '电商平台管理': '电商平台管理',
    '个人中心': "Akun Saya",
    '字典数据': '字典数据',
    //:AI
    '模板管理': "Manajemen template",
    'AI模板管理': 'AI模板管理',
    'AI模板分类管理': 'AI模板分类管理',
    '会话管理': "Manajemen sesi",
    '创作训练': "Pelatihan kreatif",
    '授权结果': "Hasil otorisasi",
    '授权失败': "Otorisasi gagal",

    //  2024.3.5 物流功能新增翻译
    "物流管理": "Manajemen Logistik",
    "物流设置": "Pengaturan Logistik"
  },
  title: {
    '项目': "Proyek",
    '总数据': "Ringkasan Data",
    '件数': "Jumlah",
    '销售': "Jumlah Produk yang Terjual",
    '测评销量': "Jumlah Penjualan Uji",
    '测评金额': "Jumlah evaluasi",
    '平均客单价': "Nilai Pesanan Rata-rata",
    '支出': "Pengeluaran",
    '搜索广告费': "Biaya Iklan Pencarian",
    '搜索广告转化': "Konversi Iklan Pencarian",
    '搜索广告点击率': "Tingkat Klik Iklan Pencarian",
    '搜索广告转化率': "Tingkat Konversi Iklan Pencarian",
    '搜索广告ROI': "ROI Iklan Pencarian",
    '关联广告费': "Biaya Iklan Afiliasi",
    '关联广告转化': "Konversi Iklan Afiliasi",
    '关联广告销售额': "Penjualan Iklan Afiliasi",
    '关联广告点击率': "Tingkat Klik Iklan Afiliasi",
    '关联广告转化率': "Tingkat Konversi Iklan Afiliasi",
    '关联广告ROI': "ROI Iklan Afiliasi",
    '推广广告费': "Biaya Iklan Promosi",
    '推广广告转化': "Konversi Iklan Promosi",
    '推广广告销售额': "Penjualan Iklan Promosi",
    '推广广告点击率': "Tingkat Klik Iklan Promosi",
    '推广广告转化率': "Tingkat Konversi Iklan Promosi",
    '推广广告ROI': "ROI Iklan Promosi",
    '测评损耗': "Kerugian Evaluasi",
    '商品成本': "Modal Produk",
    '退货金额': "Dana Pengembalian",
    '预估运费': "Biaya Pengiriman Perkiraan",
    '产出': "Output",
    '利润': "Keuntungan/ Kerugian",
    '销售利润率': "Persentase Keuntungan",
  },
  order: {
    '不在待打单状态': "Tidak dalam Status Tertunda",
    '请扫描或输入订单号或运单号': "Silakan Pindai atau Masukkan Nomor Pesanan atau Nomor Lacak",
    '发货记录': "Catatan Pengiriman",
    'Free': "Biaya Promosi Gratis",
    'Item': "Pembayaran Barang",
    'Payment': "Biaya layanan transaksi",
    'Commission': "Komisi",
    'Promotional': "Kupon Penjual",
    'Lazcoin discount': "Diskon Lazcoin",
    'LazFlash Extra/Everyday Below $9.99 Subsidy': "Tambahan/Subsidi untuk LazFlash Setiap Hari di Bawah $9.99",
  },
  user: {
    "请输入新手机号码": "Masukkan nomor telepon baru",
    "输入旧密码": "Masukkan kata sandi lama",
    "请先使用主账号绑定手机号码": "Silakan ikat nomor telepon Anda dengan akun utama terlebih dahulu",
    "销售地区": "Wilayah Penjualan",
    "时区": "Zona Waktu",
    "您的套餐还剩天，为避免服务中断，请尽快续订。立即购买": "Paket Anda masih tersisa {days} hari. Agar layanan tidak terputus, silakan perbarui secepat mungkin. Klik di sini untuk membeli sekarang",
  },
  "商品缺件": "Barang Hilang",
  "亏损商品": "Produk Rugi",
  "默认日销量": "Volume penjualan harian default",
  "推送成功": "Berhasil Push",
  "调拨收货": "Daftar Pemindahan",
  "采购收货": "Daftar Pembelian",
  "在途中": "Reservasi Promo",
  "采购计划": "Rencana pembelian",
  "部分收货": "Sudah Diterima Sebagian",
  "盘点中": "Dalam Penghitungan",
  "是": "Ya",
  "已完成": "Selesai",
  "已取消": "Dibatalkan",
  "否": "Tidak",
  "空包费用": "Biaya Bungkus Kosong",
  "站外广告费": "Biaya Iklan di Luar Situs",
  "台湾": "Taiwan, China",
  "站内广告费": "Biaya Iklan di Situs",
  "缺货": "Kehabisan stok",
  "平台调整": "Penyesuaian Platform",
  "改变主意": "Berubah Pikiran",
  "低销商品": "Produk dengan Penjualan Rendah",
  "商品和描述不一致": "Barang tidak sesuai deskripsi",
  "利润偏低": "Margin Keuntungan Rendah",
  "退货暴增": "Lonjakan Retur",
  "销量暴跌": "Penjualan Turun Tajam",
  "销量暴涨": "Penjualan Meningkat Pesat",
  "低回应时间": "Waktu Respon Rendah",
  "逾期发货率过高": "Tingkat Keterlambatan Pengiriman Tinggi",
  "商品损坏": "Barang Rusak",
  "其他": "Lainnya",
  "商品发错了": "Barang Salah Diterima",
  "商品存在瑕疵": "Barang Mengalami Cacat",
  "印尼": "Indonesia",
  "马来西亚": "Malaysia",
  "菲律宾": "Philippines",
  "新加坡": "Singapore",
  "泰国": "Thailand",
  "越南": "Vietnam",
  "快递取件":"Pengambilan Ekspres",
  "打印拣货（含汇总）":"Cetak Pemilihan (termasuk ringkasan)",
  "确定批量设置仓库":"Konfirmasi Pengaturan Gudang Batch",
  "仓库盘点时请勿关闭当前窗口，盘点完成后可关闭窗口":"Jangan tutup jendela saat inventarisasi gudang. Anda dapat menutup jendela setelah inventarisasi selesai.",
  "创建盘点任务时请勿关闭当前窗口，创建完成后可关闭窗口":"Jangan tutup jendela saat membuat tugas inventaris. Anda dapat menutup jendela setelah pembuatan selesai.",
  "正在导入数据":"Sedang mengimpor data",
  "设置全部页商品仓库":"Set gudang untuk semua halaman barang",
  "设置已选商品仓库":"Set gudang untuk barang yang dipilih",
  "印度尼西亚":"Indonesia",
  "当前账号下无可新增子账号的额度，请升级版本或删除无用的子账号空出名额后再次尝试。":"Tidak ada kuota yang tersedia untuk menambahkan sub-akun di bawah akun saat ini. Harap tingkatkan versi Anda atau hapus sub-akun yang tidak digunakan untuk memberi ruang dan coba lagi.",
  "佣金计算公式":"Rumus Perhitungan Komisi",
  "由于Lazada、TikTok平台佣金在订单完成时才结算，此处需要设置一个预估的佣金比例，用于订单未回款时利润的估算。":"Karena platform Lazada dan TikTok menyelesaikan komisi hanya saat pesanan selesai, di sini Anda perlu menetapkan tingkat komisi perkiraan untuk mengestimasi keuntungan saat pesanan belum dibayarkan kembali.",
  "物流管理":"Manajemen Logistik",
  "运费正常":"Biaya pengiriman normal",
  "运费亏损":"Kerugian biaya pengiriman",
  "运费盈余":"Kelebihan biaya pengiriman",
  "可按物流方式分别设置揽收方式":"Metode pengambilan berdasarkan metode pengiriman.",
  "自行寄件":"Pengambilan sendiri",
  "请选择揽收方式":"Harap pilih metode pengambilan",
  "线上物流":"Logistik online",
  "待处理": "Menunggu penanganan",
  "待打单": "Menunggu Dicetak",
  "待揽收":"Chờ lấy hàng",
  "运输中":"Sedang dalam pengiriman",
  "已妥投":"Sudah berhasil disampaikan",
  "运输失败":"Pengiriman gagal",
  "未审核": "Belum diverifikasi",
  "审核中": "Sedang dalam tinjauan",
  "审核失败": "Không được duyệt",
  "缺货": "Kehabisan stok",
  "title0": "Pilih Toko",
  "title1": "Waktu Sekarang",
  "title2": "Tiongkok",
  "title3": "Panduan pengaturan awal",
  "title4": "Lipat",
  "title5": "Bantuan",
  "title6": "Data Penjualan Real Time",
  "title7": "Waktu Diperbarui：",
  "title8": "Nama Toko",
  "title9": "Daerah platform",
  "title10": "Kemarin",
  "title11": "Analisis Iklan Real-time",
  "title12": "Indikator Komprehensif",
  "title13": "Periode ini:",
  "title14": "Periode Bulan demi Bulan",
  "title15": "Nilai Selisih Bulan demi Bulan",
  "title16": "Chain ratio",
  "title17": "Peringkat Penjualan Produk",
  "title18": "Peringkat Gudang",
  "title19": "Daftar Peringkat Keuntungan",
  "title20": "Daftar Peringkat Pengembalian Barang",
  "title21": "Peringkat",
  "title22": "Analisis",
  "title23": "Produk",
  "title24": "Jumlah Penjualan Pesanan Valid",
  "title25": "Jumlah Penjualan Produk Valid",
  "title26": "Keuntungan/ Kerugian",
  "title27": "Persentase Keuntungan",
  "title28": "Jumlah pengembalian",
  "title29": "Tingkat Pengembalian",
  "title30": "Analisis Purna Jual",
  "title31": "7 Hari Terakhir",
  "title32": "Dana Pengembalian",
  "title33": "Jumlah Pengembalian Dana Pesanan",
  "title34": "Jumlah Pembatalan Pesanan",
  "title35": "Tingkat Pembatalan",
  "title36": "Pengingat Cerdas",
  "title37": "Penjualan Meningkat Pesat",
  "title38": "Penjualan Turun Tajam",
  "title39": "Produk Rugi",
  "title40": "Produk dengan Penjualan Rendah",
  "title41": "Margin Keuntungan Rendah",
  "title42": "Lonjakan Retur",
  "title43": "Peringatan Status Toko",
  "title44": "Ulasan Netral atau Negatif",
  "title45": "Promosi Berakhir",
  "title46": "Penghapusan Pelanggaran Produk",
  "title47": "Waktu Respon Rendah",
  "title48": "Tingkat Keterlambatan Pengiriman Tinggi",
  "title49": "Kondisi Toko",
  "title50": "Toko Terbaik",
  "title51": "Toko Baik",
  "title52": "Peningkatan Toko",
  "title53": "Buruk",
  "title54": "Pusat Notifikasi",
  "title55": "Sistem",
  "title56": "Pembaruan",
  "title57": "Pesanperingatan",
  "title58": "福建象岸科技有限公司",
  "title59": "闽",
  "title60": "备2023007214号",
  "title61": "Minggu",
  "title62": "Senin",
  "title63": "Selasa",
  "title64": "Rabu",
  "title65": "Kamis",
  "title66": "Jumat",
  "title67": "Sabtu",
  "title68": "Integrasi",
  "title69": "Beri izin toko Anda ke sistem untuk sinkronisasi yang lancar pesanan, produk, dll.",
  "title70": "Menghapus izin",
  "title71": "Manajemen Komoditas",
  "title72": "Menjaga informasi produk lokal di sistem untuk analisis keuntungan, biaya, dan data lainnya.",
  "title73": "Ke Pengaturan",
  "title74": "Pencocokan Barang",
  "title75": "Cocokkan produk lokal dengan platform online untuk integrasi yang mulus.",
  "title76": "Cocokkan",
  "title77": "Gudang",
  "title78": "Inisialisasi inventaris untuk pembelian, pengisian ulang, pengiriman, dll. berikutnya",
  "title79": "Pengaturan Sub Akun",
  "title80": "Konfigurasi izin dan operasi untuk peran yang berbeda di sistem perusahaan Anda",
  "title81": "Instalasi Plugin",
  "title82": "Membantu dalam mendapatkan data non-API, data terkait iklan, dan data kinerja produk.",
  "title83": "Ke Instalasi",
  "title84": "Data keuangan",
  "title85": "Analisis data operasional seperti toko, produk, pesanan, dan secara otomatis menghasilkan dan mengunduh data laporan",
  "title86": "Detail",
  "title88": "Jumlah Pesanan Valid",
  "title89": "Penjualan iklan",
  "title90": "belanja iklan",
  "title91": "Pesanan iklan",
  "title92": "Tingkat Klik Iklan",
  "title93": "Hari ini",
  "title94": "Hari yang sama minggu lalu",
  "title95": "Menurut pesanan",
  "title96": "7 Hari",
  "title97": "30 Hari",
  "title98": "Pilih setidaknya satu indikator",
  "title99": "领取阿里云通用云产品1888优惠券",
  "title100": "领取腾讯云通用云产品2860优惠券",
  "title101": "阿里云服务器折扣区",
  "title102": "点我进入",
  "title103": "腾讯云服务器秒杀区",
  "title104": "云产品通用红包，可叠加官网常规优惠使用。",
  "title105": "仅限新用户",
  "title106": "芋道后台管理框架",
  "title107": "一直想做一款后台管理系统，看了很多优秀的开源项目但是发现没有合适自己的。于是利用空闲休息时间开始自己写一套后台系统。如此有了芋道管理系统。，她可以用于所有的",
  "title108": "应用程序，如网站管理后台，网站会员中心，",
  "title109": "等等，当然，您也可以对她进行深度定制，以做出更强系统。所有前端后台代码封装过后十分精简易上手，出错概率低。同时支持移动客户端访问。系统会陆续更新一些实用功能。",
  "title110": "当前版本",
  "title111": "免费开源",
  "title112": "访问码云",
  "title113": "访问主页",
  "title114": "技术选型",
  "title115": "后端技术",
  "title116": "前端技术",
  "title117": "联系信息",
  "title118": "官网：",
  "title119": "群：",
  "title120": "满937441",
  "title121": "满887144332",
  "title122": "满180251782",
  "title123": "微信：",
  "title124": "芋道",
  "title125": "支付宝：",
  "title126": "支付宝信息",
  "title127": "更新日志",
  "title128": "新增缓存监控功能",
  "title129": "支持主题风格配置",
  "title130": "修复多级菜单之间切换无法缓存的问题",
  "title131": "多级菜单自动配置组件",
  "title132": "代码生成预览支持高亮显示",
  "title133": "支持",
  "title134": "请求映射",
  "title135": "参数",
  "title136": "删除用户和角色解绑关联",
  "title137": "去除用户手机邮箱部门必填验证",
  "title138": "支持注解",
  "title139": "对齐方式",
  "title140": "支持导入",
  "title141": "型数据",
  "title142": "优化头像样式，鼠标移入悬停遮罩",
  "title143": "代码生成预览提供滚动机制",
  "title144": "代码生成删除多余的数字",
  "title145": "类型",
  "title146": "修正转换字符串的目标字符集属性",
  "title147": "回显数据字典防止空值报错",
  "title148": "日志记录增加过滤多文件场景",
  "title149": "修改缓存",
  "title150": "方法可能导致嵌套的问题",
  "title151": "移除前端一些多余的依赖",
  "title152": "防止安全扫描",
  "title153": "出现的风险提示",
  "title154": "修改",
  "title155": "为",
  "title156": "到最新版本2",
  "title157": "到最新版本4",
  "title158": "到最新版本",
  "title159": "到最新版本1",
  "title160": "到最新版本0",
  "title161": "到最新版本3",
  "title162": "到版本4",
  "title163": "到最新版本6",
  "title164": "到最新版本5",
  "title165": "到最新版本7",
  "title166": "到最新版本10",
  "title167": "其他细节优化",
  "title168": "阻止任意文件下载漏洞",
  "title169": "代码生成支持上传控件",
  "title170": "新增图片上传组件",
  "title171": "调整默认首页",
  "title172": "配置支持分隔符",
  "title173": "权限信息调整",
  "title174": "调整",
  "title175": "默认时间",
  "title176": "解决代码生成没有",
  "title177": "类型的问题",
  "title178": "到最新版1",
  "title179": "版本到2",
  "title180": "提升安全性",
  "title181": "到版本0",
  "title182": "避免",
  "title183": "图标乱码",
  "title184": "代码生成支持同步数据库",
  "title185": "代码生成支持富文本控件",
  "title186": "代码生成页面时不忽略",
  "title187": "属性",
  "title188": "代码生成添加",
  "title189": "必填选项",
  "title190": "导出类型",
  "title191": "支持精度浮点类型",
  "title192": "Ekspor",
  "title193": "优化获取值，防止",
  "title194": "方法不规范",
  "title195": "注解支持自动统计数据总和",
  "title196": "注解支持设置",
  "title197": "精度",
  "title198": "舍入规则",
  "title199": "Menu",
  "title200": "数据权限新增（展开",
  "title201": "Lipat",
  "title202": "Pilih semua",
  "title203": "Batalkan semua",
  "title204": "父子联动）",
  "title205": "允许用户分配到部门父节点",
  "title206": "菜单新增是否缓存",
  "title207": "表格操作列间距调整",
  "title208": "限制系统内置参数不允许删除",
  "title209": "富文本组件优化，支持自定义高度",
  "title210": "图片冲突问题",
  "title211": "富文本工具栏样式对齐",
  "title212": "Impor",
  "title213": "整形值校验优化",
  "title214": "修复页签关闭所有时固定标签路由不刷新问题",
  "title215": "表单构建布局型组件新增按钮",
  "title216": "左侧菜单文字过长显示省略号",
  "title217": "修正根节点为子部门时，树状结构显示问题",
  "title218": "修正调用目标字符串最大长度",
  "title219": "修正菜单提示信息错误",
  "title220": "修正定时任务执行一次权限标识",
  "title221": "修正数据库字符串类型",
  "title222": "优化递归子节点",
  "title223": "优化数据权限判断",
  "title224": "表格工具栏右侧添加刷新",
  "title225": "显隐查询组件",
  "title226": "后端支持",
  "title227": "跨域请求",
  "title228": "代码生成支持选择上级菜单",
  "title229": "代码生成支持自定义路径",
  "title230": "代码生成支持复选框",
  "title231": "导出导入支持",
  "title232": "字典类型",
  "title233": "支持分割字符串组内容",
  "title234": "验证码类型支持（数组计算、字符验证）",
  "title235": "版本到4",
  "title236": "表单类型为",
  "title237": "设置整形默认值",
  "title238": "代码生成器默认",
  "title239": "路径与默认",
  "title240": "路径不一致",
  "title241": "优化防重复提交拦截器",
  "title242": "优化上级菜单不能选择自己",
  "title243": "修复角色的权限分配后，未实时生效问题",
  "title244": "修复在线用户日志记录类型",
  "title245": "修复富文本空格和缩进保存后不生效问题",
  "title246": "修复在线用户判断逻辑",
  "title247": "唯一限制条件只返回单条数据",
  "title248": "添加获取当前的环境配置方法",
  "title249": "超时登录后页面跳转到首页",
  "title250": "全局异常状态汉化拦截处理",
  "title251": "过滤器改为将",
  "title252": "转义",
  "title253": "检查字符支持小数点",
  "title254": "降级改成异常提醒",
  "title255": "单应用调整为多模块项目",
  "title256": "Hapus",
  "title257": "提高编译速度。",
  "title258": "新增菜单默认主类目",
  "title259": "编码文件名修改为",
  "title260": "Metode",
  "title261": "定时任务",
  "title262": "表达式验证",
  "title263": "角色权限修改时已有权限未自动勾选异常修复",
  "title264": "防止切换权限用户后登录出现404",
  "title265": "导出排序",
  "title266": "创建用户不允许选择超级管理员角色",
  "title267": "修复代码生成导入表结构出现异常页面不提醒问题",
  "title268": "修复代码生成点击多次表修改数据不变化的问题",
  "title269": "修复头像上传成功二次打开无法改变裁剪框大小和位置问题",
  "title270": "修复布局为",
  "title271": "者",
  "title272": "用户表单显示错位问题",
  "title273": "修复热部署导致的强换异常问题",
  "title274": "修改用户管理复选框宽度，防止部分浏览器出现省略号",
  "title275": "工具，清除",
  "title276": "特殊字符，防止",
  "title277": "注入攻击",
  "title278": "生成",
  "title279": "如果是浮点型",
  "title280": "统一用",
  "title281": "定时任务调整",
  "title282": "防止部署出现错位",
  "title283": "调整表头固定列默认样式",
  "title284": "代码生成模板调整，字段为",
  "title285": "并且必填则加空串条件",
  "title286": "代码生成字典",
  "title287": "使用",
  "title288": "修复",
  "title289": "不可",
  "title290": "为0的问题",
  "title291": "查询返回增加",
  "title292": "升序排序",
  "title293": "修正岗位导出权限注解",
  "title294": "禁止加密密文返回前端",
  "title295": "修复代码生成页面中的查询条件创建时间未生效的问题",
  "title296": "修复首页搜索菜单外链无法点击跳转问题",
  "title297": "修复菜单管理选择图标，",
  "title298": "删除时不过滤数据",
  "title299": "用户管理部门分支节点不可检查",
  "title300": "显示计数",
  "title301": "数据范围过滤属性调整",
  "title302": "修复高危安全漏洞",
  "title303": "启动默认打开浏览器",
  "title304": "使用默认",
  "title305": "报错优化",
  "title306": "当",
  "title307": "滚动关闭右键菜单",
  "title308": "字典管理添加缓存读取",
  "title309": "参数管理支持缓存操作",
  "title310": "支持一级菜单（和主页同级）在",
  "title311": "区域显示",
  "title312": "限制外链地址必须以",
  "title313": "开头",
  "title314": "主题颜色与",
  "title315": "全局",
  "title316": "同步",
  "title317": "修改数据源类型优先级，先根据方法，再根据类",
  "title318": "支持是否需要设置",
  "title319": "属性，自定义返回码消息。",
  "title320": "请求前缀加入配置。",
  "title321": "登录地点设置内容过长则隐藏显示",
  "title322": "修复定时任务执行一次按钮后不提示消息问题",
  "title323": "修改上级部门（选择项排除本身和下级）",
  "title324": "通用",
  "title325": "发送方法增加参数",
  "title326": "编码类型",
  "title327": "更换",
  "title328": "地址查询接口",
  "title329": "修复页签变量",
  "title330": "添加校验部门包含未停用的子部门",
  "title331": "修改定时任务详情下次执行时间日期显示错误",
  "title332": "角色管理查询设置默认排序字段",
  "title333": "添加",
  "title334": "参数控制是否启用",
  "title335": "只对",
  "title336": "类型请求构建可重复读取",
  "title337": "的",
  "title338": "修改代码生成字典字段",
  "title339": "类型没有自动选中问题",
  "title340": "用户名取值修正",
  "title341": "表格树模板去掉多余的",
  "title342": "代码生成序号修正",
  "title343": "全屏情况下不调整上外边距",
  "title344": "代码生成",
  "title345": "字段添加默认格式",
  "title346": "用户管理角色选择权限控制",
  "title347": "修复路由懒加载报错问题",
  "title348": "模板",
  "title349": "添加菜单状态",
  "title350": "设置用户名称不能修改",
  "title351": "属性，防止",
  "title352": "遮罩",
  "title353": "菜单区分状态和显示隐藏功能",
  "title354": "修复安全加固",
  "title355": "修复代码生成如果选择字典类型缺失逗号问题",
  "title356": "登录请求",
  "title357": "更换为",
  "title358": "防止暴露",
  "title359": "日志返回时间格式处理",
  "title360": "控制允许拖动的元素",
  "title361": "布局设置点击扩大范围",
  "title362": "代码生成列属性排序查询",
  "title363": "代码生成列支持拖动排序",
  "title364": "修复时间格式不支持",
  "title365": "问题",
  "title366": "表单构建添加父级",
  "title367": "防止冲突",
  "title368": "定时任务并发属性修正",
  "title369": "角色禁用",
  "title370": "菜单隐藏不查询权限",
  "title371": "系统监控新增定时任务功能",
  "title372": "添加一个打包",
  "title373": "工程",
  "title374": "修复页签鼠标滚轮按下的时候，可以关闭不可关闭的",
  "title375": "修复点击退出登录有时会无提示问题",
  "title376": "修复防重复提交注解无效问题",
  "title377": "修复通知公告批量删除异常问题",
  "title378": "添加菜单时路由地址必填限制",
  "title379": "代码生成字段描述可编辑",
  "title380": "修复用户修改个人信息导致缓存不过期问题",
  "title381": "个人信息创建时间获取正确属性值",
  "title382": "操作日志详细显示正确类型",
  "title383": "导入表单击行数据时选中对应的复选框",
  "title384": "批量替换表前缀逻辑调整",
  "title385": "固定重定向路径表达式",
  "title386": "操作日志排序调整",
  "title387": "切换侧边栏或者缩放窗口显示",
  "title388": "新增表单构建",
  "title389": "代码生成支持树表结构",
  "title390": "新增用户导入",
  "title391": "修复动态加载路由页面刷新问题",
  "title392": "修复地址开关无效问题",
  "title393": "汉化错误提示页面",
  "title394": "代码生成已知问题修改",
  "title395": "修复多数据源下配置关闭出现异常处理",
  "title396": "过滤器，用于去除",
  "title397": "漏洞隐患",
  "title398": "修复上传头像控制台出现异常",
  "title399": "修改用户管理分页不正确的问题",
  "title400": "修复验证码记录提示错误",
  "title401": "缺少",
  "title402": "引用",
  "title403": "修复表格时间为空出现的异常",
  "title404": "日期反序列化时区配置",
  "title405": "调整根据用户权限加载菜单数据树形结构",
  "title406": "调整成功登录不恢复按钮，防止多次点击",
  "title407": "修改用户个人资料同步缓存信息",
  "title408": "修复页面同时出现",
  "title409": "和",
  "title410": "不显示处理",
  "title411": "修复在角色管理页修改菜单权限偶尔未选中问题",
  "title412": "配置文件新增",
  "title413": "密码属性",
  "title414": "Pengaturan",
  "title415": "全局的配置文件",
  "title416": "新增代码生成",
  "title417": "Tambah",
  "title418": "注解，防止重复提交",
  "title419": "新增菜单主目录添加",
  "title420": "删除操作",
  "title421": "日志记录过滤特殊对象，防止转换异常",
  "title422": "修改代码生成路由脚本错误",
  "title423": "用户上传头像实时同步缓存，无需重新登录",
  "title424": "调整切换页签后不重新加载数据",
  "title425": "实现参数的前端加密",
  "title426": "系统退出删除用户缓存记录",
  "title427": "新增在线用户管理",
  "title428": "新增按钮组功能实现（批量删除、导出、清空）",
  "title429": "新增查询条件重置按钮",
  "title430": "Konfigurasi",
  "title431": "新增后端参数校验",
  "title432": "修复字典管理页面的日期查询异常",
  "title433": "修改时间函数命名防止冲突",
  "title434": "去除菜单上级校验，默认为顶级",
  "title435": "修复用户密码无法修改问题",
  "title436": "修复菜单类型为按钮时不显示权限标识",
  "title437": "芋道前后端分离系统正式发布",
  "title438": "捐赠支持",
  "title439": "你可以请作者喝杯咖啡表示鼓励",
  "title440": "Selamat datang di xinjian",
  "title441": "Daftar",
  "title442": "Lupa Kata Sandi",
  "title443": "Penyewa",
  "title444": "Masukkan nomor telepon",
  "title445": "Nama Pengguna",
  "title446": "Password",
  "title447": "Masuk",
  "title448": "录",
  "title449": "中",
  "title450": "Login otomatis dalam beberapa hari",
  "title451": "Lupa Password ?",
  "title452": "Belum punya akun?",
  "title453": "Daftar",
  "title454": "Masukkan nomor telepon",
  "title455": "Masukkan kata sandi",
  "title456": "Harap masukan kode verifikasi",
  "title457": "Dapatkan kode verifikasi",
  "title458": "Kirim ulang setelah detik",
  "title459": "Baca dan terima",
  "title460": "Syarat Layanan",
  "title461": "Sudah punya akun？",
  "title462": "Pergi ke login",
  "title463": "Harap masukan kode verifikasi",
  "title464": "Konfirmasi",
  "title465": "Tidak perlu mengambil",
  "title466": "Masukkan kata sandi",
  "title467": "Masukkan ulang kata sandi",
  "title468": "Reset kata sandi",
  "title469": "Selesai",
  "title470": "Mulai menggunakan",
  "title471": "Kembali ke langkah sebelumnya",
  "title472": "Masuk",
  "title473": "Setuju dan Lanjut",
  "title474": "Karakter",
  "title475": "Huruf harus mengandung huruf besar",
  "title476": "Harus mengandung setidaknya 2 jenis huruf, angka, dan karakter",
  "title477": "Kata sandi tidak boleh sama dengan nama pengguna",
  "title478": "Silakan masukkan ulang kata sandi Anda",
  "title479": "Kata sandi yang dimasukkan tidak cocok",
  "title480": "E-commerce lintas batas di Asia Tenggara",
  "title481": "Operasi yang disesuaikan dengan baik dan analisis data",
  "title482": "Harap masukkan kode verifikasi",
  "title483": "Harap Isi Nomor Telepon",
  "title484": "Nomor Telepon Harus Diawali dengan Angka 1 dan Terdiri dari 11 Digit Angka",
  "title485": "Penyewa tidak boleh kosong",
  "title486": "Indonesia",
  "title487": "Filipina",
  "title488": "Thailand",
  "title489": "Vietnam",
  "title490": "Malaysia",
  "title491": "Singapura",
  "title492": "Taiwan, Cina",
  "title493": "Akun tidak ada",
  "title494": "Harap baca dan setujui persyaratan",
  "title495": "Berhasil mendapatkan kode verifikasi",
  "title496": "Perubahan berhasil, silakan masuk kembali",
  "title497": "Pendaftaran berhasil, silakan masuk",
  "title498": "Xinjian",
  "title499": "三方授权（",
  "title500": "此第三方应用请求获得以下权限：",
  "title501": "同意授权",
  "title502": "授权",
  "title503": "权",
  "title504": "拒绝",
  "title505": "自动授权未通过！",
  "title506": "访问你的个人信息",
  "title507": "修改你的个人信息",
  "title508": "Mengikat akun",
  "title509": "Akun",
  "title510": "Ingat kata sandi",
  "title511": "Nama pengguna tidak boleh kosong",
  "title512": "Kata sandi tidak boleh kosong",
  "title513": "Basis pengetahuan",
  "title514": "Pengunjung produk",
  "title515": "Pesan",
  "title516": "Jumlah",
  "title517": "Pesanan",
  "title518": "Kembali",
  "title519": "Kesalahan",
  "title520": "Anda tidak memiliki izin akses!",
  "title521": "Maaf, Anda tidak memiliki izin akses. Harap hindari tindakan ilegal! Anda dapat kembali ke halaman utama.",
  "title522": "Kembali ke Beranda",
  "title523": "Fungsi ini perlu diaktifkan",
  "title524": "Paket",
  "title525": "Untuk digunakan, lanjutkan ke",
  "title526": "Pelajari lebih lanjut",
  "title527": "atau",
  "title528": "Hubungi layanan pelanggan",
  "title529": "Maaf, halaman yang Anda cari tidak ada. Coba periksa",
  "title530": "kesalahan dan kemudian tekan tombol refresh pada peramban Anda atau coba cari konten lain dalam aplikasi kami.",
  "title531": "Kembali ke beranda",
  "title532": "Halaman tidak ditemukan！",
  "title533": "Favorit saya",
  "title534": "Tanya Jawab",
  "title535": "答",
  "title536": "Salin",
  "title537": "Batal favorit",
  "title538": "Sesi bebas",
  "title539": "Sesi template",
  "title540": "Sesi dokumen",
  "title541": "Berhasil disalin",
  "title542": "Batal favorit berhasil",
  "title543": "Sesi baru",
  "title544": "Hapus semua sesi",
  "title545": "Sesi baru",
  "title546": "Silakan ubah lagi",
  "title547": "Modifikasi berhasil",
  "title548": "Konfirmasi penghapusan sesi",
  "title549": "Peringatan",
  "title550": "Penghapusan berhasil！",
  "title551": "Hapus semua sesi?",
  "title552": "Basis Pengetahuan Cerdas",
  "title553": "Terlibat dengan dokumen, ekstrak dengan cepat, tunjuk, dan rangkum informasi yang Anda butuhkan.",
  "title554": "Buat basis pengetahuan baru",
  "title555": "Bangun milik Anda sendiri",
  "title556": "Menyiapkan basis pengetahuan templat, cepat mengurai dan memahami dokumen teknis yang kompleks, mencatat pertanyaan yang sering diajukan, hindari pertanyaan yang berulang, dan tingkatkan efisiensi kerja.",
  "title557": "Edit template",
  "title558": "Hapus template",
  "title559": "Konfirmasi hapus templat ini?",
  "title560": "Penghapusan berhasil",
  "title561": "Kategori",
  "title562": "Silakan masukkan kategori",
  "title563": "Jenis template",
  "title564": "Silakan pilih jenis template",
  "title565": "Judul template",
  "title566": "Silakan masukkan judul template",
  "title567": "Pencarian",
  "title568": "Reset",
  "title569": "Kunci Primer",
  "title570": "Pembuat",
  "title571": "Pengenalan Templat",
  "title572": "Konten Pertanyaan",
  "title573": "Variabel Pertanyaan",
  "title574": "Waktu Dibuat",
  "title575": "Aksi",
  "title576": "Silakan masukkan pembuat",
  "title577": "Silakan masukkan pengantar template",
  "title578": "Silakan masukkan konten pertanyaan",
  "title579": "Variabel Pertanyaan",
  "title580": "Silakan masukkan variabel pertanyaan, menggunakan",
  "title581": "Array disimpan. Setiap objek dalam array berisi bidang berikut: Nama Variabel",
  "title582": "Salinan Deskripsi yang Sesuai",
  "title583": "Wajib",
  "title584": "Nilai Default",
  "title585": "Konfirmasi",
  "title586": "定",
  "title587": "Batalkan",
  "title588": "",
  "title589": "Modifikasi berhasil",
  "title590": "Penambahan berhasil",
  "title591": "Konfirmasi penghapusan",
  "title592": "Nomor templat adalah",
  "title593": "Data item dari",
  "title594": "Konfirmasi ekspor semua",
  "title595": "Item data templat",
  "title596": "Eksklusif",
  "title597": "Mengedit basis pengetahuan",
  "title598": "林珍",
  "title599": "Ubah pertanyaan",
  "title600": "Tutup",
  "title601": "Selamat datang di milikku",
  "title602": "频道！今天的主题是如何在生活中实现健康的生活方式。",
  "title603": "首先，一个健康的生活方式需要有一个良好的饮食习惯。我们应该尽量食用新鲜、天然的食物，避免摄入过多的加工食品和糖分。另外，我们也需要保持适当的饮食量，不要过量进食或过度节食。",
  "title604": "其次，适量的运动也是保持健康的重要一环。我们可以选择自己喜欢的运动方式，比如跑步、瑜伽、游泳等等。无论是室内还是户外运动，每天坚持30分钟以上的运动可以帮助我们保持身体健康，同时也有助于缓解压力和焦虑感。",
  "title605": "除了饮食和运动之外，我们还需要注意睡眠质量的保证。一个良好的睡眠环境可以帮助我们更好地入睡和保持深度睡眠状态。同时，规律的作息时间也是非常重要的，它可以帮助我们的身体建立一个健康的生物钟。",
  "title606": "最后，我们还可以通过一些放松的方式来缓解生活中的压力和疲劳感。比如听音乐、读书、冥想等等。这些活动可以帮助我们放松身心，让我们更加愉悦地度过每一天。",
  "title607": "以上就是我对如何在生活中实现健康生活方式的一些",
  "title608": "Menjawab, harap tunggu",
  "title609": "Silakan masukkan konten pertanyaan Anda (Pemisahan baris:",
  "title610": "Tekan Enter untuk Mengirim",
  "title611": "Menggunakan 1 poin",
  "title612": "Kirim",
  "title613": "Tolong tulis kehidupan",
  "title614": "Skrip",
  "title615": "Silakan Deskripsikan Masalah",
  "title616": "Gambar",
  "title617": "Masukkan Gambar",
  "title618": "Nama Kategori",
  "title619": "Masukkan Nama Kategori",
  "title620": "Tingkat Kategori",
  "title621": "Masukkan Tingkat Kategori",
  "title622": "Induk",
  "title623": "Masukkan Induk",
  "title624": "Tanggal Mulai",
  "title625": "Tanggal Berakhir",
  "title626": "Kategori Templat",
  "title627": "Nomor Kategori Templat adalah",
  "title628": "Item Data Kategori Templat",
  "title629": "Tidak yakin apa yang akan ditulis? Cobalah masukkan contoh",
  "title630": "Bersihkan input",
  "title631": "Mulai menghasilkan",
  "title632": "Silakan masukkan konten",
  "title633": "Semua",
  "title634": "Penanda berhasil",
  "title635": "Status Penempatan Iklan",
  "title636": "Status Pengumpulan",
  "title637": "Integritas Pengumpulan",
  "title638": "Waktu Pengumpulan Terakhir",
  "title639": "Silakan lihat 'Integritas Pengumpulan Data' untuk mengetahui apakah data iklan selama sebulan terakhir telah berhasil dikumpulkan. Jika data tidak lengkap, Anda dapat memeriksa catatan pengumpulan untuk detail spesifik.",
  "title640": "Lihat tutorial operasi",
  "title641": "Nama Toko",
  "title642": "Belum dikumpulkan",
  "title643": "Menunda penayangan",
  "title644": "Sedang disiarkan",
  "title645": "Sedang mengumpulkan",
  "title646": "Sedang disiarkan",
  "title647": "Dijeda",
  "title648": "Offline",
  "title649": "Mengaktifkan",
  "title650": "Menonaktifkan",
  "title651": "Periode pengumpulan",
  "title652": "Catatan pengumpulan",
  "title653": "Harap dicatat bahwa tidak semua data iklan telah dikumpulkan oleh sistem. Untuk mendapatkan data lengkap, harap aktifkan plugin iklan dan jalankan kembali proses pengumpulan.",
  "title654": "Kinerja produk",
  "title655": "Data iklan",
  "title656": "Tanggal",
  "title657": "Lengkap",
  "title658": "Tidak lengkap",
  "title659": "Telah dikumpulkan",
  "title660": "Tidak ada iklan",
  "title661": "Waktu statistik",
  "title662": "Ad analysis",
  "title663": " Urutkan Berdasarkan ",
  "title664": "Daerah",
  "title665": "Grafik tren",
  "title666": "Biaya iklan toko",
  "title667": "Penjualan iklan produk",
  "title668": "Produk",
  "title669": "Efek kampanye iklan",
  "title670": "Kampanye iklan",
  "title671": "Klik iklan",
  "title672": "Hsrian",
  "title673": "Mingguan",
  "title674": "Bulanan",
  "title675": "Brazil",
  "title676": "Mexico",
  "title677": "Tingkat klik",
  "title678": "Tampilan iklan",
  "title679": "Tampilan Iklan",
  "title680": "Hari Ini",
  "title681": "Kemarin",
  "title682": "Hampir 7 Hari",
  "title683": "Hampir 15 Hari",
  "title684": "Hampir 30 Hari",
  "title685": "Klik Iklan",
  "title686": "Lebih dari hari kemarin",
  "title687": "Dibandingkan dengan hari sebelumnya",
  "title688": "Dibandingkan dengan yang sebelumnya",
  "title689": "hari",
  "title690": "Kinerja",
  "title691": "Data toko",
  "title692": "Data produk",
  "title693": "Data operasi",
  "title694": "Laporan keuntungan toko",
  "title695": "Keuntungan toko statistik untuk menganalisis operasi toko",
  "title696": "Laporan keuntungan kinerja",
  "title697": "Laporan kinerja statistik untuk personil operasi",
  "title698": "Tabel data produk",
  "title699": "Indikator kunci statistik untuk memahami kinerja operasi keseluruhan produk",
  "title700": "Tabel data toko",
  "title701": "Analisis data operasi toko untuk melihat situasi toko secara keseluruhan.",
  "title702": "Pilih wilayah",
  "title703": "Periode saat ini",
  "title704": "Periode perbandingan",
  "title705": "Perbandingan data penjualan",
  "title706": "Perbandingan data biaya",
  "title707": "Data detail perbandingan",
  "title708": "Data ekspor terbaru",
  "title709": "Nama laporan",
  "title710": "Sedang diproses",
  "title711": "Sedang diproses",
  "title712": "Data penjualan",
  "title713": "Data Penjualan",
  "title714": "Data Biaya",
  "title715": "unit",
  "title716": "Modal Produk",
  "title717": "Ad Cost",
  "title718": "Biaya Pengemasan",
  "title719": "Biaya Evaluasi",
  "title720": "Diskon Penjual",
  "title721": "Biaya Pengiriman",
  "title722": "Biaya Platform",
  "title723": "Biaya Platform Lainnya",
  "title724": "Pilih setidaknya satu toko",
  "title725": "Individu",
  "title726": "Perbandingan periode toko-",
  "title727": "Konfirmasi ekspor ikhtisar komposisi biaya",
  "title728": "Ikhtisar komposisi biaya",
  "title729": "Konfirmasi pesanan",
  "title730": "Detail Pesanan",
  "title731": "Layanan pesanan",
  "title732": "Satu",
  "title733": "Ulasan Wawasan",
  "title734": "Kali",
  "title735": "Poin",
  "title736": "Membeli",
  "title737": "Jumlah",
  "title738": "Durasi",
  "title739": "Total paket",
  "title740": "Jumlah paket",
  "title741": "Durasi paket",
  "title742": "Berlaku Sampai",
  "title743": "Unit",
  "title744": "Saya telah membaca dan menerima",
  "title745": "Jumlah yang Perlu Dibayar",
  "title746": "Saya telah membaca dan menerima",
  "title747": "Ketentuan Layanan Pengguna",
  "title748": "Kirim Pesanan",
  "title749": "Bayar Sekarang",
  "title750": "Jumlah yang Perlu Dibayar",
  "title751": "Waktu Pembayaran Tersisa",
  "title752": "Pesanan yang belum dibayar akan otomatis dibatalkan jika kedaluwarsa",
  "title753": "Buka ponsel Anda",
  "title754": "Alipay",
  "title755": "WeChat",
  "title756": "Pindai untuk melanjutkan pembayaran",
  "title757": "Metode pembayaran: Transfer Bank (Jangan tutup halaman ini sebelum mentransfer, klik 'Pembayaran Selesai' setelah transfer berhasil)",
  "title758": "Nama Perusahaan Penerima:",
  "title759": "Nomor Rekening Bank Penerima:",
  "title760": "Nama Bank:",
  "title761": "Bank Konstruksi China Co., Ltd. Cabang Fuzhou Shida",
  "title762": "Pembayaran selesai",
  "title763": "Pembayaran Sukses",
  "title764": "Anda dapat melihatnya di [Pusat Pribadi] - [Pesanan Saya].",
  "title765": "Estimasi waktu masuk adalah 5-10 menit, harap tunggu sebentar",
  "title766": "Anda dapat melihatnya di [Pusat Pribadi] - [Pesanan Saya].",
  "title767": "Nomor Pesanan",
  "title768": "Pembayaran Alipay",
  "title769": "Transfer Bank",
  "title770": "Ikhtisar Analisis Pengguna",
  "title771": "Tingkat Pembelian Ulang",
  "title772": "Dibandingkan dengan Periode Sebelumnya",
  "title773": "Kinerja Penjualan",
  "title774": "Jumlah Pesanan Valid",
  "title776": "Penjualan per Pembeli",
  "title777": "Pengguna Baru",
  "title778": "Pengguna Kembali",
  "title779": "Daftar Pengguna",
  "title780": "Masukkan Informasi Pencarian",
  "title781": "Jumlah Pembayaran Pembeli",
  "title782": "Daftar Pelanggan",
  "title783": "Informasi Pembeli",
  "title784": "Waktu Pesanan Terakhir",
  "title785": "Catatan pembelian",
  "title786": "Pengguna [",
  "title787": "Catatan produk",
  "title788": "Informasi Barang",
  "title790": "Catatan pesanan",
  "订单号": "Nomor Pesanan",
  "title792": "Kategori produk",
  "title793": "Waktu Pemesanan",
  "title794": "Melalui",
  "title795": "Cari pengguna",
  "title796": "Ya",
  "title797": "Tidak",
  "title798": "15 Hari Terakhir",
  "title799": "Total Pengguna",
  "title800": "Jumlah Pesanan Berulang",
  "title801": "7 Hari Terakhir",
  "title802": "Tanggal",
  "title803": "Konfirmasi ekspor semua item data?",
  "title804": "Data Penjualan Toko",
  "title805": "Data Komprehensif",
  "title806": "Perbandingan Waktu",
  "title807": "Perbandingan Indikator",
  "title808": "Periode Saat Ini",
  "title809": "Siklus Periode-ke-Periode",
  "title810": "Selisih Periode-ke-Periode",
  "title811": "per jam",
  "title812": "Statistik tepat waktu mengacu pada menganalisis kinerja data harian selama 24 jam dari toko.",
  "title813": "Komposisi Biaya Produk",
  "title814": "Perbandingan Biaya vs. Penjualan Efektif",
  "title815": "Laporan Stok",
  "title816": "Semua Gudang",
  "title817": "Gudang Pihak Ketiga",
  "title818": "Gudang Sendiri",
  "title819": "Nilai Inventaris",
  "title820": "Usia Persediaan",
  "title821": "Durasi",
  "title822": "Persentase",
  "title823": "Distribusi Wilayah Pesanan",
  "title824": "Silakan pilih area",
  "title825": "Pengembalian ",
  "title826": "Kota",
  "title827": "Jumlah Pesanan Valid",
  "title828": "Jumlah Pesanan Dikembalikan",
  "title829": "Proporsi Jumlah Pesanan Efektif",
  "title830": "Proporsi Jumlah Pesanan Dikembalikan",
  "title831": "Stok Tersedia",
  "title832": "Dialokasikan",
  "title833": "Reservasi Promo",
  "title834": "Melebihi",
  "title835": "Berdasarkan Toko",
  "title836": "Berdasarkan Situs",
  "title837": "Menurut wilayah",
  "title838": "Total Penjualan",
  "title839": "Total penjualan",
  "title840": "Jumlah Pesanan",
  "title841": "Kerugian Evaluasi",
  "title842": "Jumlah Total",
  "title843": "Total Nilai Persediaan",
  "title844": "Total Biaya Produk",
  "title845": "Informasi Akun",
  "title846": "Informasi Dasar",
  "title847": "Catatan login",
  "title848": "Informasi langganan",
  "title849": "Paket saya",
  "title850": "Pesanan saya",
  "title851": "Akun saya",
  "title852": "Akun",
  "title853": "Kata sandi",
  "title854": "Atur kata sandi",
  "title855": "Nama",
  "title856": "Nomor HP",
  "title857": "Ganti telepon",
  "title858": "Waktu login",
  "title859": "Akun login",
  "title860": "Peran yang ditugaskan",
  "title861": "Phone",
  "title862": "Alamat login",
  "title863": "Kuota paket Anda telah habis, silakan membeli paket baru untuk melanjutkan layanan.",
  "title864": "Beli sekarang",
  "title865": "Status paket",
  "title866": "Versi saat ini",
  "title867": "Masa berlaku",
  "title868": "Pesanan tersisa:",
  "title869": "Beli paket",
  "title870": "Hak dasar",
  "title871": "Jumlah toko",
  "title872": "Sub Akun",
  "title873": "Poin",
  "title874": "Nama paket",
  "title875": "Pesanan tersisa",
  "title876": "Waktu tersisa",
  "title877": "Waktu pembelian",
  "title878": "Status",
  "title879": "Keterangan",
  "title880": "Hak paket pengisian bahan bakar",
  "title881": "Jenis paket pengisian bahan bakar",
  "title882": "Konten pembelian",
  "title883": "Jumlah paket pengisian bahan bakar",
  "title884": "Jumlah Tersisa",
  "title885": "Hari Tersisa",
  "title886": "Jenis Pesanan",
  "title887": "Masukkan Nomor Pesanan",
  "title888": "Waktu Transaksi",
  "title889": "Konten pesanan",
  "title890": "Paket pengisian bahan bakar",
  "title891": "Status pembayaran",
  "title892": "Bayar sekarang",
  "title893": "Pesanan Dibatalkan",
  "title894": "Silakan hubungi layanan pelanggan",
  "title895": "Telepon faktur",
  "title896": "Waktu Pembayaran",
  "title897": "Langganan",
  "title898": "Belum Dibayar",
  "title899": "Pembayaran Sukses",
  "title900": "Pembayaran Ditutup",
  "title901": "Konfirmasi pembatalan pesanan?",
  "title902": "Pesanan dibatalkan berhasil",
  "title903": "Unggah berhasil",
  "title904": "Pilih status",
  "title905": "Metrik Kesehatan Toko",
  "title906": "Toko yang di bawah rata-rata",
  "title907": "Skor rata-rata kuartal ini",
  "title908": "Rata-rata poin pelanggaran pada kuartal ini untuk toko yang dipilih. Poin yang terakumulasi akan tetap ada hingga akhir kuartal.",
  "title909": "poin akan direset pada hari Senin pertama setiap kuartal (Jan, Apr, Jul, Okt). Pindahkan kursor ke poin untuk melihat rata-rata poin dari metrik penting.",
  "title910": "Indikator",
  "title911": "Rata-rata",
  "title912": "Poin",
  "title913": "Melanggar peraturan penempatan",
  "title914": "Produk Yang Dilarang",
  "title915": "Jumlah Produk Pre-sale",
  "title916": "Pelanggaran Lainnya",
  "title917": "Tingkat Penyelesaian Pesanan",
  "title918": "Tingkat Kegagalan Pesanan",
  "title919": "Tingkat Pengiriman Terlambat",
  "title920": "Waktu Persiapan",
  "title921": "Layanan Pelanggan & Kepuasan",
  "title922": "Persentase Chat Dibalas",
  "title923": "Ulasan Pembeli",
  "title924": "Nilai Rata-rata Indikator Penting",
  "title925": "Peringatan Penalti Toko",
  "title926": "Penalti Berjalan",
  "title927": "Sangat Baik! Anda Tidak Memiliki Peringatan Toko",
  "title928": "Status Kesehatan Toko",
  "title929": "Lisensi toko kedaluwarsa, silakan",
  "title930": "Otorisasi ulang",
  "title10930": "Integrasi Toko Ini Kedaluwarsa, Harap Integrasi Ulang",
  "title931": "Poin Penalti Kuartal Ini",
  "title932": "Tingkat Peringatan Penalti",
  "title933": "Peringatan Penalti akan Dipicu Bila Berkurang 1 Poin, Peringatan Awal akan Dipicu pada Poin 2, 5, 8, 11, 14",
  "title934": "Tidak ada peringatan",
  "title935": "Tingkat",
  "title936": "Distribusi peringatan skor denda toko",
  "title937": "Tidak ada peringatan",
  "title938": "Toko Anda saat ini dalam kondisi baik tanpa hukuman",
  "title939": "Penalti",
  "title940": "Tingkat Penalti",
  "title941": "Waktu Tersisa Penalti",
  "title942": "Tingkat Penalti Toko",
  "title943": "Situasi toko",
  "title944": "Perbarui data",
  "title945": "Pengaturan item daftar",
  "title946": "Nilai Kesehatan Tokomu secara keseluruhan. Sangat Baik, Baik, Perlu Ditingkatkan, Buruk",
  "title947": "Poin Penalti Kuartal Ini (Poin)",
  "title948": "Akumulasi Poin Penalti akan Tetap Tercatat hingga Akhir Kuartal. Poin Penalti akan Diatur Ulang pada hari Senin pertama masing-masing Kuartal (Jan, Apr, Jul, Okt).",
  "title949": "Pelanggaran Pemenuhan (Poin)",
  "title950": "Poin Penalti untuk Pesanan Tidak Terselesaikan Karena Dibatalkan atau Pengembalian Pesanan",
  "title951": "Pelanggaran Penundaan Pengiriman(Poin)",
  "title952": "Poin Penalti Keterlambatan Pengiriman Pesanan",
  "title953": "Poin Penalti Keterlambatan Pengiriman Pesanan",
  "title954": "Pelanggaran Lainnya (Poin)",
  "title955": "Poin Penalti untuk Pelanggaran Lainnya",
  "title956": "Produk Yang Dilarang",
  "title957": "Produk akan Dihapus Bila Terjadi Pelanggaran Berat Terhadap Kebijakan Shopee.",
  "title958": "Toko Saya",
  "title959": "Target",
  "title960": "Poin Penalti",
  "title961": " Produk Spam",
  "title962": "Setiap Upaya untuk Memanipulasi Hasil Pencarian untuk Mendapatkan Keuntungan yang Tidak Adil Akan Terkena Penalti. Seperti: Produk Spam, Kata Kunci Tidak Relevan dan Harga Sembarangan dan Lainnya.",
  "title963": "Pemalsuan/ Pelanggaran IP",
  "title964": "Pemalsuan/ Pelanggaran IP",
  "title965": "Produk yang Dilarang",
  "title966": "Produk yang Tidak Diperbolehkan di Shopee",
  "title967": "Barang yang Terdaftar",
  "title968": "Jumlah Produk Pre-order",
  "title969": "Jumlah Produk Pre-order",
  "title970": "Persentase Produk Pre-order dari Total Semua Produk yang Ditampilkan",
  "title971": "Jumlah Hari dari Pelanggaran Produk Pre-order",
  "title972": "Target",
  "title973": "Jumlah Hari dengan Persentase Produk Pre-order Melebihi Target dalam 30 Hari Terakhir",
  "title974": "Aturan Lainnya",
  "title975": "Pelanggaran Produk Lainnya",
  "title976": "Peraturan Upload Produk Lainnya yang Ditetapkan oleh Marketplace, Pelanggaran Dapat Dikenakan Penalti, Seperti Kategori Produk yang Salah, Gambar Berkualitas Rendah dan Lainnya.",
  "title977": "Persentase Pesanan yang Dibatalkan oleh Penjual dalam 7 Hari Terakhir.",
  "title978": "Tingkat Pembatalan",
  "title979": "Persentase Pesanan yang Dikembalikan Atas Permintaan Pembeli dalam 7 Hari Terakhir.",
  "title980": "Tingkat Pengembalian",
  "title981": "Persentase Pesanan yang Terlambat Dikirimkan dalam 7 Hari Terakhir.",
  "title982": " Tingkat Keterlambatan Pengiriman Pesanan",
  "title983": "Persentase Pesanan yang Terlambat Dikirimkan dalam 7 Hari Terakhir.",
  "title984": "Jumlah Hari yang Diperlukan Penjual untuk Persiapan dan Pengiriman Pesanan",
  "title985": "Layanan Pelanggan",
  "title986": "Kepuasan",
  "title987": "Persentase Chat & Penawaran Baru yang Dibalas oleh Penjual dalam waktu 12 jam setelah pesan diterima.",
  "title988": "Kecepatan Chat Dibalas",
  "title989": "Rata-Rata Waktu yang Dibutuhkan oleh Penjual untuk menjawab Chat dari Pembeli",
  "title990": " Keseluruhan Penilaian",
  "title991": "Jumlah Rata-Rata dari Semua Penilaian Pesanan yang Dikonfirmasi oleh Pembeli Anda",
  "title992": "Poin Penalti Kuartal Ini-Poin Penalti Kuartal Ini (Poin)",
  "title993": "Poin Penalti Kuartal Ini-Pelanggaran Pemenuhan (Poin)",
  "title994": "Poin Penalti Kuartal Ini-Pelanggaran Penundaan Pengiriman(Poin)",
  "title995": "Poin Penalti Kuartal Ini",
  "title996": "Poin Penalti Kuartal Ini-Pelanggaran Lainnya (Poin)",
  "title997": "Produk Yang Dilarang-Produk Yang Dilarang",
  "title998": "Produk Yang Dilarang-Produk Spam",
  "title999": "Produk Yang Dilarang-Pemalsuan/ Pelanggaran IP",
  "title1000": "Produk Yang Dilarang-Produk yang Dilarang",
  "title1001": "Produk Pre-order-Jumlah Produk Pre-order",
  "title1002": "Produk Pre-order- Jumlah Hari dari Pelanggaran Produk Pre-order",
  "title1003": "Produk Pre-order-Pelanggaran Produk Lainnya",
  "title1004": "Tingkat Pesanan Terselesaikan-Tingkat Pesanan Tidak Terselesaikan",
  "title1005": "Tingkat Pesanan Terselesaikan-Tingkat Pembatalan",
  "title1006": "Tingkat Pesanan Terselesaikan-Tingkat Pengembalian",
  "title1007": "Tingkat Pesanan Terselesaikan-Tingkat Keterlambatan Pengiriman Pesanan",
  "title1008": "Tingkat Pesanan Terselesaikan-Waktu Persiapan",
  "title1009": "Layanan Pelanggan Kepuasan-Persentase Chat Dibalas",
  "title1010": "Layanan Pelanggan Kepuasan-Kecepatan Chat Dibalas",
  "title1011": "Layanan Pelanggan Kepuasan-Keseluruhan Penilaian",
  "title1012": "Pulihkan default",
  "title1013": "Konfirmasi",
  "title1014": "Sangat Baik",
  "title1015": "Baik",
  "title1016": "Perlu Ditingkatkan",
  "title1017": "Kurs pengembalian tidak boleh kosong",
  "title1018": "Pembaruan data berhasil",
  "title1019": "Konfirmasi untuk mengotorisasi ulang toko",
  "title1020": "Apakah Anda yakin untuk mengekspor situasi toko",
  "title1021": "Situasi toko-",
  "title1022": "Layanan Berlangganan",
  "title1023": "Paket saat ini",
  "title1024": "Versi Gratis",
  "title1025": "Paket berlaku selama 1 tahun.",
  "title1026": "Penggunaan unit tunggal mulai dihitung dari awal paket, hingga unit tunggal habis atau melewati tanggal kadaluarsa.",
  "title1027": "Jika Anda memerlukan layanan implementasi offline atau bantuan demo online, silakan hubungi tim layanan pelanggan kami.",
  "title1028": "Tahun",
  "title1029": "Jumlah akun",
  "title1030": "流程表单",
  "title1031": "表单名",
  "title1032": "请输入表单名",
  "title1033": "Status aktif",
  "title1034": "Harap masukkan catatan",
  "title1035": "Simpan",
  "title1036": "Lihat",
  "title1037": "Hapus",
  "title1038": "从左侧拖入或点选组件进行表单设计",
  "title1039": "输入型组件",
  "title1040": "选择型组件",
  "title1041": "布局型组件",
  "title1042": "表单名不能为空",
  "title1043": "开启状态不能为空",
  "title1044": "Sukses",
  "title1045": "代码已复制到剪切板，可粘贴。",
  "title1046": "代码复制失败",
  "title1047": "确定要清空所有组件吗？",
  "title1048": "提示",
  "title1049": "工作流",
  "title1050": "编号",
  "title1051": "表单详情",
  "title1052": "是否确认删除工作表单的编号为",
  "title1053": "定义编号",
  "title1054": "定义名称",
  "title1055": "定义分类",
  "title1056": "表单信息",
  "title1057": "暂无表单",
  "title1058": "流程版本",
  "title1059": "未部署",
  "title1060": "激活",
  "title1061": "挂起",
  "title1062": "部署时间",
  "title1063": "定义描述",
  "title1064": "分配规则",
  "title1065": "流程图",
  "title1066": "组名",
  "title1067": "请输入组名",
  "title1068": "Silakan pilih status",
  "title1069": "Deskripsi",
  "title1070": "Anggota",
  "title1071": "Harap masukkan deskripsi",
  "title1072": "Silakan pilih anggota",
  "title1073": "Nama grup tidak boleh kosong",
  "title1074": "Deskripsi tidak boleh kosong",
  "title1075": "Anggota tidak boleh kosong",
  "title1076": "Status tidak boleh kosong",
  "title1077": "Tambahkan grup pengguna",
  "title1078": "Ubah grup pengguna",
  "title1079": "Apakah Anda yakin ingin menghapus grup pengguna dengan nomor grup",
  "title1080": "Tidak diketahui",
  "title1081": "Berhasil disimpan",
  "title1082": "流程标识",
  "title1083": "请输入流程标识",
  "title1084": "流程名称",
  "title1085": "请输入流程名称",
  "title1086": "流程分类",
  "title1087": "新建流程",
  "title1088": "导入流程",
  "title1089": "最新部署的流程定义",
  "title1090": "激活状态",
  "title1091": "修改流程",
  "title1092": "设计流程",
  "title1093": "发布流程",
  "title1094": "流程定义",
  "title1095": "请输入流标标识",
  "title1096": "新建后，流程标识不可修改！",
  "title1097": "流程标识不可修改！",
  "title1098": "请选择流程分类",
  "title1099": "流程描述",
  "title1100": "表单类型",
  "title1101": "表单提交路由",
  "title1102": "请输入表单提交路由",
  "title1103": "自定义表单的提交路径，使用",
  "title1104": "的路由地址，例如说：",
  "title1105": "表单查看路由",
  "title1106": "请输入表单查看路由",
  "title1107": "自定义表单的查看路径，使用",
  "title1108": "将文件拖到此处，或",
  "title1109": "Klik untuk mengunggah",
  "title1110": "提示：仅允许导入“",
  "title1111": "Atau",
  "title1112": "格式文件！",
  "title1113": "流程标识不能为空",
  "title1114": "流程名称不能为空",
  "title1115": "业务表单不能为空",
  "title1116": "流程分类不能为空",
  "title1117": "表单提交路由不能为空",
  "title1118": "表单查看路由不能为空",
  "title1119": "新建模型",
  "title1120": "修改模型",
  "title1121": "修改模型成功",
  "title1122": "新建模型成功！",
  "title1123": "后续需要执行如下",
  "title1124": "个步骤：",
  "title1125": "点击【修改流程】按钮，配置流程的分类、表单信息",
  "title1126": "点击【设计流程】按钮，绘制流程图",
  "title1127": "点击【分配规则】按钮，设置每个用户任务的审批人",
  "title1128": "点击【发布流程】按钮，完成流程的最终发布",
  "title1129": "另外，每次流程修改后，都需要点击【发布流程】按钮，才能正式生效！！！",
  "title1130": "重要提示",
  "title1131": "是否删除该流程！！",
  "title1132": "是否部署该流程！！",
  "title1133": "部署成功",
  "title1134": "是否确认",
  "title1135": "流程名字为",
  "title1136": "导入流程成功！请点击【设计流程】按钮，进行编辑保存后，才可以进行【发布流程】",
  "title1137": "选择",
  "title1138": "申请信息【",
  "title1139": "选择其它流程",
  "title1140": "发起流程成功",
  "title1141": "审批任务【",
  "title1142": "流程名",
  "title1143": "流程发起人",
  "title1144": "审批建议",
  "title1145": "请输入审批建议",
  "title1146": "不通过",
  "title1147": "转办",
  "title1148": "委派",
  "title1149": "退回",
  "title1150": "点击查看",
  "title1151": "审批记录",
  "title1152": "任务：",
  "title1153": "审批人：",
  "title1154": "创建时间：",
  "title1155": "审批时间：",
  "title1156": "耗时：",
  "title1157": "转派审批人",
  "title1158": "新审批人",
  "title1159": "审批建议不能为空",
  "title1160": "新审批人不能为空",
  "title1161": "未传递",
  "title1162": "参数，无法查看流程信息",
  "title1163": "查询不到流程信息！",
  "title1164": "审批通过成功！",
  "title1165": "审批不通过成功！",
  "title1166": "转派任务成功！",
  "title1167": "暂不支持【委派】功能，可以使用【转派】替代！",
  "title1168": "暂不支持【退回】功能！",
  "title1169": "请输入流程名",
  "title1170": "所属流程",
  "title1171": "请输入流程定义的编号",
  "title1172": "提交时间",
  "title1173": "结果",
  "title1174": "请选择流结果",
  "title1175": "发起流程",
  "title1176": "当前审批任务",
  "title1177": "结束时间",
  "title1178": "请输入取消原因？",
  "title1179": "取消流程",
  "title1180": "取消原因不能为空",
  "title1181": "取消成功",
  "title1182": "任务编号",
  "title1183": "任务名称",
  "title1184": "审批意见",
  "title1185": "审批时间",
  "title1186": "耗时",
  "title1187": "审批",
  "title1188": "任务分配规则",
  "title1189": "任务名",
  "title1190": "任务标识",
  "title1191": "规则类型",
  "title1192": "规则范围",
  "title1193": "修改任务规则",
  "title1194": "指定角色",
  "title1195": "指定部门",
  "title1196": "请选择指定部门",
  "title1197": "指定岗位",
  "title1198": "指定用户",
  "title1199": "指定用户组",
  "title1200": "指定脚本",
  "title1201": "规则类型不能为空",
  "title1202": "指定角色不能为空",
  "title1203": "指定部门不能为空",
  "title1204": "指定岗位不能为空",
  "title1205": "指定用户不能为空",
  "title1206": "指定用户组不能为空",
  "title1207": "指定脚本不能为空",
  "title1208": "Perbandingan Data Jumlah Penjualan",
  "title1209": "Perbandingan Data Pesanan",
  "title1210": "Perbandingan Data Lalu Lintas",
  "title1211": "Data Konversi Iklan",
  "title1212": "Komentar Platform",
  "title1213": "Silakan Masukkan Komentar Platform",
  "title1214": "Produk Platform",
  "title1215": "Silakan Masukkan Produk Platform",
  "title1216": "Silakan Masukkan Produk",
  "title1217": "Waktu Komentar",
  "title1218": "Komentar",
  "title1219": "Pembeli",
  "title1220": "Video",
  "title1221": "Isi Ulasan",
  "title1222": "Balasan Penjual",
  "title1223": "Penilaian",
  "title1224": "Masukkan Pembeli",
  "title1225": "Masukkan Video",
  "title1226": "Masukkan Isi Ulasan",
  "title1227": "Masukkan Balasan Penjual",
  "title1228": "Masukkan Penilaian",
  "title1229": "Pilih Waktu Ulasan",
  "title1230": "Tidak Boleh Kosong",
  "title1231": "Nomor Pesanan Tidak Boleh Kosong",
  "title1232": "Waktu Ulasan Tidak Boleh Kosong",
  "title1233": "Tambah Ulasan Produk",
  "title1234": "Ubah Ulasan Produk",
  "title1235": "Apakah Anda yakin ingin menghapus komentar produk dengan nomor",
  "title1236": "Apakah Anda yakin ingin mengekspor semua item data komentar produk",
  "title1237": "Komentar Produk",
  "title1238": "Masukkan Biaya Kustom",
  "title1239": "Catatan Modifikasi Biaya",
  "title1240": "Impor Biaya Batch",
  "title1241": "Impor Modal Produk",
  "title1242": "Impor Biaya Historis",
  "title1243": "Tambahkan Biaya Kustom",
  "title1244": "Impor Biaya Kustom Massal",
  "title1245": "Hapus Massal",
  "title1246": "Pilihan",
  "title1247": "Status Penjualan",
  "title1248": "Status Biaya Produk",
  "title1249": "Tipe Biaya",
  "title1250": "Ekspor Biaya Produk",
  "title1251": "Aktif",
  "title1252": "Tidak Aktif",
  "title1253": "Dengan Biaya Produk",
  "title1254": "Tanpa Biaya Produk",
  "title1255": "Biaya Historis",
  "title1256": "Template Impor Biaya Produk",
  "title1257": "Template Impor Biaya Kustom",
  "title1258": "Impor Biaya Kustom",
  "title1259": "Tabel Biaya Kustom",
  "title1260": "Saran Hangat",
  "title1261": "Apakah Anda yakin ingin mengekspor",
  "title1262": "Rincian Biaya Produk -",
  "title1263": "Buka",
  "title1264": "Puncak",
  "title1265": "Pindah Mata Uang",
  "title1266": "Jumlah Tampilan Halaman",
  "title1268": "Pengunjung Iklan",
  "title1269": "Pengunjung Organik",
  "title1270": "Suka",
  "title1271": "Tingkat Suka",
  "title1272": "Tambahkan ke Keranjang",
  "title1273": "Tingkat Konversi Tambahkan ke Keranjang",
  "title1274": "Penjualan Iklan",
  "title1275": "Penjualan Organik",
  "title1276": "Jumlah Penjualan Organik",
  "title1277": "Tingkat Konversi",
  "title1278": "Biaya Iklan",
  "title1279": "Kesalahan data, hubungi administrator",
  "title1280": "Berhasil Top",
  "title1281": "Batal Top berhasil",
  "title1282": "Proporsi Pesanan Organik",
  "title1283": "Apakah Anda yakin ingin mengekspor semua catatan gudang",
  "title1285": "Analisis lalu lintas iklan dapat mengumpulkan dan menganalisis data lalu lintas dan konversi produk toko, memungkinkan Anda untuk dengan cepat melihat metrik kunci seperti kunjungan pembeli, tingkat konversi, volume penjualan, pendapatan penjualan, dan lainnya, untuk memahami kinerja produk dalam operasi.",
  "title1286": "Silakan masukkan pencarian atribut produk",
  "title1287": "Kategori iklan",
  "title1288": "Klik iklan",
  "title1289": "Jumlah Penjualan",
  "title1290": "Details",
  "title1291": "Advertisement details",
  "title1292": "Cancel top",
  "title1293": "Product name",
  "title1294": "Confirm export all products",
  "title1295": "Data item",
  "title1296": "Diagnostic data",
  "title1297": "Past",
  "title1298": "Store information",
  "title1299": "Group",
  "title1301": "Penjualan efektif MoM",
  "title1302": "Keuntungan/ Kerugian",
  "title1303": "Silakan pilih jenis pencarian",
  "title1304": "Silakan masukkan konten pencarian",
  "title1305": "Pilih waktu",
  "title1306": "Ulasan",
  "title1307": "Indikator pemasaran",
  "title1308": "Jumlah ulasan:",
  "title1309": "Skor total:",
  "title1310": "Poin penjualan kunci",
  "title1311": "Informasi pesaing",
  "title1312": "Catatan kata kunci inti",
  "title1313": "Status produk",
  "title1314": "Diturunkan",
  "title1315": "Naikkan",
  "title1316": "Ulasan produk",
  "title1317": "Nama pengguna",
  "title1318": "Nomor pesanan:",
  "title1319": "Detail data produk",
  "title1320": "Lihat grafik tren",
  "title1321": "Total pengunjung",
  "title1322": "Total pesanan",
  "title1323": "Pengunjung iklan kata kunci",
  "title1324": "Tingkat klik kata kunci",
  "title1325": "Volume iklan kata kunci",
  "title1326": "Tingkat konversi kata kunci",
  "title1327": "Rata-rata biaya per pesanan",
  "title1328": "Kata kunci",
  "title1329": "Iklan kata kunci",
  "title1330": "Pengunjung iklan terkait",
  "title1331": "Pesanan iklan terkait",
  "title1332": "Iklan terkait",
  "title1333": "Pengunjung organik",
  "title1334": "Tingkat klik organik",
  "title1335": "Pesanan organik",
  "title1336": "Tingkat konversi organik",
  "title1337": "Grafik analisis tren data produk",
  "title1338": "Klik label untuk membuka atau menutup kurva",
  "title1339": "Catatan titik penjualan",
  "title1340": "Tautan",
  "title1341": "Penjualan panas pesaing",
  "title1342": "Harga",
  "title1343": "Volume penjualan bulanan pesaing",
  "title1344": "Kata kunci lokal",
  "title1345": "Terjemahan Cina",
  "title1346": "Volume pencarian",
  "title1347": "Harap masukkan titik penjualan",
  "title1348": "Tautan pesaing",
  "title1349": "Harap masukkan tautan pesaing",
  "title1350": "Harap masukkan penjualan panas pesaing",
  "title1351": "Harap masukkan volume penjualan bulanan pesaing",
  "title1352": "Harap masukkan kata kunci lokal",
  "title1353": "Terjemahan otomatis kata kunci",
  "title1354": "Harap masukkan volume pencarian kata kunci",
  "title1355": "Catatan poin penjualan kunci",
  "title1356": "Tambahkan catatan poin penjualan kunci",
  "title1357": "Catatan tautan pesaing",
  "title1358": "Tambahkan tautan pesaing",
  "title1359": "Tambahkan kata kunci inti",
  "title1360": "Nama produk online",
  "title1361": "Produk",
  "title1362": "Sudah dihapus",
  "title1363": "Sudah dijual",
  "title1364": "Ngày tuỳ chỉnh",
  "title1365": "Harap masukkan catatan poin penjualan",
  "title1366": "Biaya Rata-rata per Pesanan (Kata Kunci)",
  "title1367": "Biaya per Pesanan (Iklan Terkait)",
  "title1368": "Bulan",
  "title1369": "Apakah Anda yakin untuk mengekspor semua item data pesanan?",
  "title1370": "Koper Upgrade 23 inci Sandi Hitam Tebal Hitam Tebal Hitam",
  "title1371": "Masukkan Kata Kunci untuk Mencari",
  "title1372": "Tampilkan Lokasi",
  "title1373": "Konversi",
  "title1374": "Barang Sudah Terjual",
  "title1375": "Pengaturan Kondisi",
  "title1377": "Aturan Diagnostik",
  "title1378": "Silakan Pilih Waktu",
  "title1379": "Jam",
  "title1380": "Pengaturan Belum Selesai",
  "title1381": "Lebih Besar Dari",
  "title1382": "Lebih Besar Dari atau Sama Dengan",
  "title1383": "Kurang Dari",
  "title1384": "Kurang Dari atau Sama Dengan",
  "title1385": "Mulai Bulan",
  "title1386": "Akhir Bulan",
  "title1387": "Data Dasar",
  "title1388": "Jumlah pelanggan",
  "title1389": "Jumlah pembatalan pesanan",
  "title1390": "Perubahan tren",
  "title1391": "Jumlah pesanan dibatalkan",
  "title1392": "14 hari terakhir",
  "title1393": "Tháng này",
  "title1394": "Bulan lalu",
  "title1395": "6 Bulan Terakhir",
  "title1396": "1 Tahun Terakhir",
  "title1397": "3 Bulan Terakhir",
  "title1398": "6 Bulan Terakhir",
  "title1399": "1 Tahun Terakhir",
  "title1400": "Berdasarkan Tahun",
  "title1401": "Dibandingkan dengan Hari Sebelumnya",
  "title1402": "Tambahkan Tautan Produk",
  "title1403": "Informasi Produk",
  "title1404": "Platform",
  "title1405": "Waktu Analisis",
  "title1406": "Timeout Operasi. Silakan diulang analisis.",
  "title1407": "Analisis Selesai",
  "title1408": "Analisis Sedang Berlangsung",
  "title1409": "Analisis Ulang",
  "title1410": "Laporan Analisis",
  "title1411": "Tautan Produk",
  "title1412": "Silakan masukkan tautan produk online untuk menganalisis konten ulasan. Hanya satu tautan produk yang dapat ditambahkan setiap kali.",
  "title1413": "Masukkan Tautan Produk",
  "title1414": "Contoh:",
  "title1415": "Nama Produk",
  "title1416": "Nomor Produk",
  "title1417": "Silakan masukkan tautan produk",
  "title1418": "Silakan masukkan alamat yang valid",
  "title1419": "Berhasil Ditambahkan",
  "title1420": "Berhasil Diperbarui",
  "title1421": "Konfirmasi Hapus?",
  "title1422": "Harga:",
  "title1423": "Total Volume Penjualan:",
  "title1424": "Volume Penjualan Bulanan:",
  "title1425": "Rating Bintang:",
  "title1426": "Tingkat Penerimaan Ulasan:",
  "title1427": "Waktu Penyimpanan:",
  "title1428": "Buka Tautan Toko",
  "title1429": "Tinjauan",
  "title1430": "Kelebihan",
  "title1431": "Kekurangan",
  "title1432": "Harapan Pelanggan",
  "title1433": "Motivasi Pembelian",
  "title1434": "Varian yang saat ini dijual:",
  "title1435": "varian dengan ulasan historis:",
  "title1436": "varian dengan daftar ulasan sebagai berikut:",
  "title1437": "Varian",
  "title1438": "Jumlah Ulasan",
  "title1439": "Persentase Ulasan",
  "title1440": "Lihat Ulasan",
  "title1441": "Alasan",
  "title1442": "Persentase Kategori Saat Ini",
  "title1443": "Persentase Total Ulasan Produk Saat Ini",
  "title1444": "Lihat Ulasan Pembeli",
  "title1445": "Daftar Ulasan",
  "title1446": "Masukkan Konten Pencarian",
  "title1447": "Rating Bintang",
  "title1448": "Jenis Ulasan",
  "title1449": "Konten Diterjemahkan",
  "title1450": "Unduh Ulasan",
  "title1451": "Bintang",
  "title1452": "Teks",
  "title1453": "Lainnya",
  "title1454": "Apakah Anda yakin ingin mengekspor ulasan produk?",
  "title1455": "Ulasan Produk",
  "title1456": "Pilih Jenis Pencarian",
  "title1457": "Jumlah Penjualan Produk",
  "title1458": "Penilaian Toko",
  "title1459": "Waktu Pengiriman",
  "title1460": "Tingkat Keterlambatan Pengiriman",
  "title1461": "Tingkat Belum Selesai",
  "title1462": "Waktu Respons",
  "title1463": "Skor Denda",
  "title1464": "Ulasan Baru Hari Ini",
  "title1465": "林明芳-",
  "title1466": "Pengguna",
  "title1467": "Silakan Masukkan Pengguna",
  "title1468": "Nama Diagnosis",
  "title1469": "Silakan Masukkan Nama Diagnosis",
  "title1470": "Jenis Diagnosis",
  "title1471": "Silakan Pilih Jenis Diagnosis",
  "title1472": "Jenis Pengingat",
  "title1473": "Silakan Pilih Jenis Pengingat",
  "title1474": "Nomor Inkremental",
  "title1475": "Deskripsi Diagnosis",
  "title1476": "Silakan Masukkan Aturan Diagnosis",
  "title1477": "Nama Diagnosis Tidak Boleh Kosong",
  "title1478": "Jenis Diagnosis Tidak Boleh Kosong",
  "title1479": "Jenis Pengingat Tidak Boleh Kosong",
  "title1480": "Tambahkan Diagnosis Data",
  "title1481": "Ubah Diagnosis Data",
  "title1482": "Apakah Anda yakin ingin menghapus diagnosis data dengan ID",
  "title1483": "Apakah Anda yakin ingin mengekspor semua item data diagnosis?",
  "title1484": "Diagnosis Data",
  "title1485": "Peringkat",
  "title1486": "Waktu Pembaruan",
  "title1487": "Peringkat Harian",
  "title1488": "Peringkat Mingguan",
  "title1489": "Peringkat Bulanan",
  "title1490": "Tautan Online",
  "title1491": "Informasi Tautan",
  "title1492": "Informasi",
  "title1493": "Informasi Akun",
  "title1494": "Informasi Platform",
  "title1495": "Peringkat Pendapatan Penjualan",
  "title1496": "Peringkat Margin Keuntungan",
  "title1497": "Peringkat Tingkat Pengembalian",
  "title1498": "Peringkat Kinerja",
  "title1499": "Peringkat Platform",
  "title1500": "Peringkat Toko",
  "title1501": "Total Penjualan",
  "title1502": "Spesifikasi Produk",
  "title1503": "Nama",
  "title1504": "Silakan masukkan nama",
  "title1505": "Nama Templat",
  "title1506": "Jenis Barcode",
  "title1507": "Ukuran Label",
  "title1508": "Lebar",
  "title1509": "Tinggi",
  "title1510": "Default",
  "title1511": "Silakan pilih jenis barcode",
  "title1512": "Lebar",
  "title1513": "Silakan masukkan lebar",
  "title1514": "Tinggi",
  "title1515": "Silakan masukkan tinggi",
  "title1516": "Definisi Templat",
  "title1517": "Nama tidak boleh kosong",
  "title1518": "Jenis barcode tidak boleh kosong",
  "title1519": "Lebar tidak boleh kosong",
  "title1520": "Tinggi tidak boleh kosong",
  "title1521": "Default tidak boleh kosong",
  "title1522": "Tambahkan Template Label",
  "title1523": "Ubah Template Label",
  "title1524": "Konfirmasi untuk menghapus template label dengan ID",
  "title1525": "Konfirmasi untuk mengekspor semua item data template label",
  "title1526": "Template Label",
  "title1527": "Masukkan Produk",
  "title1528": "Lihat Peringkat",
  "title1529": "Silakan masukkan nama platform",
  "title1530": "Nama Platform",
  "title1531": "Ikon Platform",
  "title1532": "Tautan Platform",
  "title1533": "Silakan masukkan tautan platform",
  "title1534": "Nama platform tidak boleh kosong",
  "title1535": "Ikon platform tidak boleh kosong",
  "title1536": "Tautan platform tidak boleh kosong",
  "title1537": "Tambahkan Platform E-commerce",
  "title1538": "Ubah Platform E-commerce",
  "title1539": "Konfirmasi Hapus",
  "title1540": "Konfirmasi untuk mengekspor semua item data platform e-commerce",
  "title1541": "Platform E-commerce",
  "title1542": "Silakan masukkan nama kategori",
  "title1543": "Kategori",
  "title1544": "Kategori Induk",
  "title1545": "Nama Kategori",
  "title1546": "Tampilan Pesanan",
  "title1547": "Tingkat",
  "title1548": "Silakan masukkan kategori induk",
  "title1549": "Silakan masukkan urutan tampilan",
  "title1550": "Silakan masukkan tingkat",
  "title1551": "Nama kategori tidak boleh kosong",
  "title1552": "Urutan tampilan tidak boleh kosong",
  "title1553": "Tingkat tidak boleh kosong",
  "title1554": "Tambahkan Kategori Produk",
  "title1555": "Ubah Kategori Produk",
  "title1556": "Konfirmasi untuk menghapus kategori produk",
  "title1557": "Konfirmasi untuk mengekspor semua item data kategori produk",
  "title1558": "Kategori Produk",
  "title1559": "Anda memiliki",
  "title1560": "Your store authorization has expired, please",
  "title1561": "bấm để kết nối lại",
  "title1562": "Cần làm",
  "title1563": "Menunggu penanganan",
  "title1564": "Hampir berakhir",
  "title1565": "Kehabisan stok",
  "title1566": "Belum Dicocokkan",
  "title1567": "Không được duyệt",
  "title1568": "thất bại Đẩy kho hàng bên thứ 3 ",
  "title1569": "Chờ lấy hàng",
  "title1570": "Tồn kho",
  "title1571": "Tidak ada stok",
  "title1572": "Thấp hơn cảnh báo tồn kho",
  "title1573": " Cảnh báo tồn kho ",
  "title1574": "Đặt hàng",
  "title1575": "Gợi ý đặt hàng",
  "title1576": "Kế hoạch đặt hàng",
  "title1577": "Số lượng gian hàng đã kết nối",
  "title1578": "gian hàng đã quá hạn cấp quyền",
  "title1579": "Số lượng đơn hàng hoàn tiền",
  "title1580": "Silakan Masukkan",
  "title1581": "Pesaing",
  "title1582": "Volume Penjualan Bulanan",
  "title1583": "Silakan Masukkan Tautan",
  "title1584": "Silakan Masukkan Harga",
  "title1585": "Silakan Masukkan Volume Penjualan Bulanan",
  "title1586": "Tambahkan Tautan Pesaing",
  "title1587": "Ubah Tautan Pesaing",
  "title1588": "Apakah Anda yakin ingin menghapus tautan pesaing dengan ID",
  "title1589": "Apakah Anda yakin ingin mengekspor semua item data tautan pesaing?",
  "title1590": "Produk Tunggal",
  "title1591": "Produk Gabungan",
  "title1592": "Semua Toko",
  "title1593": "Edit",
  "title1594": "Nama Produk Gabungan",
  "title1595": "Silakan Pilih Produk",
  "title1596": "Silakan Pilih Toko",
  "title1597": "Produk tidak boleh kosong",
  "title1598": "Toko tidak boleh kosong",
  "title1599": "Tambahkan Pasangan Produk",
  "title1600": "Apakah Anda yakin untuk mengekspor semua item data pasangan produk?",
  "title1601": "Pasangan Produk",
  "title1602": "Detail Pasangan",
  "title1603": "Hapus",
  "title1604": "Apakah Anda yakin untuk menghapus pasangan produk?",
  "title1605": "Silakan masukkan toko",
  "title1606": "Silakan masukkan platform",
  "title1607": "Silakan masukkan daerah",
  "title1608": "Terbaru",
  "title1609": "Silakan masukkan terbaru",
  "title1610": "Bertambah",
  "title1611": "Mata Uang",
  "title1612": "Kurs",
  "title1613": "Jumlah Pengembalian",
  "title1614": "Jumlah Pesanan Pengembalian MoM",
  "title1615": "Jumlah Pembatalan",
  "title1616": "Tingkat Pengembalian MoM",
  "title1617": "Jumlah Pembatalan MoM",
  "title1618": "Tingkat Pembatalan",
  "title1619": "Tingkat Pembatalan MoM",
  "title1620": "Jumlah Evaluasi",
  "title1621": "Jumlah Evaluasi MoM",
  "title1622": "Jumlah Pesanan Efektif MoM",
  "title1623": "Số tiền",
  "title1624": "Người mua đã thanh toán ",
  "title1625": "Penjualan efektif MoM",
  "title1626": "Phí vận chuyển do người mua trả",
  "title1627": "Biaya MoM",
  "title1628": "Biaya lainnya",
  "title1629": "Jumlah penyelesaian",
  "title1630": "Jumlah penyelesaian penilaian",
  "title1631": "Jumlah pembayaran penilaian",
  "title1632": "Biaya penilaian",
  "title1633": "Perbandingan pengeluaran penilaian",
  "title1634": "Nilai tukar pembayaran penilaian",
  "title1635": "Komisi",
  "title1636": "Biaya iklan",
  "title1637": "Perbandingan jumlah pengembalian",
  "title1638": "Total Pendapatan",
  "title1639": "Total Pengeluaran",
  "title1640": "Total biaya",
  "title1641": "Perkiraan Keuntungan",
  "title1642": "Perbandingan keuntungan",
  "title1643": "Keuntungan rata-rata",
  "title1644": "Perbandingan margin keuntungan",
  "title1645": "Telah Dialokasikan",
  "title1646": "Margin laba biaya MoM",
  "title1647": "Silakan masukkan mata uang",
  "title1648": "Silakan masukkan kurs",
  "title1649": "Silakan masukkan jumlah pengembalian",
  "title1650": "Silakan masukkan jumlah pesanan retur MoM",
  "title1651": "Silakan masukkan jumlah pembatalan",
  "title1652": "Silakan masukkan persentase retur MoM",
  "title1653": "Silakan masukkan jumlah pembatalan MoM",
  "title1654": "Silakan masukkan tingkat pembatalan",
  "title1655": "Silakan masukkan perbandingan tingkat pembatalan",
  "title1656": "Silakan masukkan volume pesanan penilaian",
  "title1657": "Silakan masukkan perbandingan volume pesanan penilaian",
  "title1658": "Silakan masukkan volume pesanan efektif",
  "title1659": "Silakan masukkan perbandingan volume pesanan efektif",
  "title1660": "Silakan masukkan volume penjualan efektif",
  "title1661": "Silakan masukkan perbandingan volume penjualan efektif",
  "title1662": "Silakan masukkan jumlah pesanan",
  "title1663": "Silakan masukkan jumlah pembayaran yang sebenarnya oleh pembeli",
  "title1664": "Silakan masukkan perbandingan pendapatan penjualan efektif",
  "title1665": "Ongkos Kirim Dibayar Oleh Pembeli",
  "title1666": "Harap masukkan biaya total",
  "title1667": "Harap masukkan rasio biaya",
  "title1668": "Harap masukkan biaya lainnya",
  "title1669": "Harap masukkan biaya pengiriman",
  "title1670": "Harap masukkan biaya pengemasan",
  "title1671": "Harap masukkan jumlah penyelesaian",
  "title1672": "Harap masukkan jumlah penyelesaian evaluasi",
  "title1673": "Harap masukkan jumlah pembayaran evaluasi",
  "title1674": "Harap masukkan biaya evaluasi",
  "title1675": "Harap masukkan biaya evaluasi",
  "title1676": "Harap masukkan rasio biaya evaluasi",
  "title1677": "Harap masukkan kurs pembayaran evaluasi",
  "title1678": "Harap masukkan komisi platform",
  "title1679": "Harap masukkan biaya iklan",
  "title1680": "Harap masukkan jumlah pengembalian",
  "title1681": "Harap masukkan rasio pengembalian",
  "title1682": "Harap masukkan diskon penjual",
  "title1683": "Harap masukkan total pendapatan",
  "title1684": "Harap masukkan total biaya",
  "title1685": "Harap masukkan total biaya",
  "title1686": "Harap masukkan keuntungan perkiraan",
  "title1687": "Harap masukkan rasio keuntungan",
  "title1688": "Harap masukkan rata-rata keuntungan",
  "title1689": "Harap masukkan margin keuntungan",
  "title1690": "Harap masukkan rasio margin keuntungan",
  "title1691": "Harap masukkan margin keuntungan biaya",
  "title1692": "Harap masukkan rasio margin keuntungan biaya",
  "title1693": "Platform tidak boleh kosong",
  "title1694": "Wilayah tidak boleh kosong",
  "title1695": "Hari tidak boleh kosong",
  "title1696": "Mata uang tidak boleh kosong",
  "title1697": "Kurs tidak boleh kosong",
  "title1698": "Jumlah pengembalian tidak boleh kosong",
  "title1699": "Volume pesanan pengembalian MOM tidak boleh kosong",
  "title1700": "Jumlah pembatalan tidak boleh kosong",
  "title1701": "Tingkat pengembalian MOM tidak boleh kosong",
  "title1702": "Volume pembatalan MOM tidak boleh kosong",
  "title1703": "Tingkat pembatalan tidak boleh kosong",
  "title1704": "Tingkat pembatalan MOM tidak boleh kosong",
  "title1705": "Jumlah evaluasi tidak boleh kosong",
  "title1706": "Volume evaluasi MOM tidak boleh kosong",
  "title1707": "Jumlah pesanan efektif tidak boleh kosong",
  "title1708": "Volume pesanan efektif MOM tidak boleh kosong",
  "title1709": "Volume penjualan efektif tidak boleh kosong",
  "title1710": "Volume penjualan efektif MOM tidak boleh kosong",
  "title1711": "Jumlah pesanan tidak boleh kosong",
  "title1712": "Jumlah pembayaran sebenarnya dari pembeli tidak boleh kosong",
  "title1713": "Jumlah penjualan efektif MOM tidak boleh kosong",
  "title1714": "Pembeli membayar biaya pengiriman tidak boleh kosong",
  "title1715": "Total biaya produk tidak boleh kosong",
  "title1716": "Perbandingan biaya MOM tidak boleh kosong",
  "title1717": "Biaya lainnya tidak boleh kosong",
  "title1718": "Biaya pengiriman tidak boleh kosong",
  "title1719": "Biaya kemasan tidak boleh kosong",
  "title1720": "Jumlah penyelesaian tidak boleh kosong",
  "title1721": "Jumlah penyelesaian evaluasi tidak boleh kosong",
  "title1722": "Jumlah pembayaran evaluasi tidak boleh kosong",
  "title1723": "Biaya evaluasi tidak boleh kosong",
  "title1724": "Pengeluaran evaluasi tidak boleh kosong",
  "title1725": "Pengeluaran evaluasi MOM tidak boleh kosong",
  "title1726": "Kurs pembayaran evaluasi tidak boleh kosong",
  "title1727": "Komisi platform tidak boleh kosong",
  "title1728": "Biaya iklan tidak boleh kosong",
  "title1729": "Jumlah pengembalian tidak boleh kosong",
  "title1730": "Perbandingan jumlah pengembalian MOM tidak boleh kosong",
  "title1731": "Diskon penjual tidak boleh kosong",
  "title1732": "Total pendapatan tidak boleh kosong",
  "title1733": "Total pengeluaran tidak boleh kosong",
  "title1734": "Total biaya tidak boleh kosong",
  "title1735": "Keuntungan perkiraan tidak boleh kosong",
  "title1736": "MOM keuntungan tidak boleh kosong",
  "title1737": "Rata-rata keuntungan tidak boleh kosong",
  "title1738": "Margin keuntungan tidak boleh kosong",
  "title1739": "MOM Margin keuntungan tidak boleh kosong",
  "title1740": "Margin keuntungan biaya tidak boleh kosong",
  "title1741": "MOM Margin keuntungan biaya tidak boleh kosong",
  "title1742": "Tambah terbaru",
  "title1743": "Keuntungan barang dalam beberapa hari",
  "title1744": "Mengubah terbaru",
  "title1745": "Konfirmasi penghapusan terbaru",
  "title1746": "Nomor keuntungan barang dalam beberapa hari adalah",
  "title1747": "Konfirmasi ekspor semua terbaru",
  "title1748": "Item data keuntungan barang dalam beberapa hari",
  "title1749": "Tanggal data",
  "title1750": "Kata kunci iklan tunggal",
  "title1751": "Biaya rata-rata per pesanan (iklan terkait)",
  "title1752": "Pilih tanggal data",
  "title1753": "Masukkan total pengunjung",
  "title1754": "Masukkan total pesanan",
  "title1755": "Masukkan pengunjung iklan kata kunci",
  "title1756": "Masukkan tingkat klik kata kunci",
  "title1757": "Masukkan kata kunci iklan tunggal",
  "title1758": "Masukkan tingkat konversi kata kunci",
  "title1759": "Masukkan biaya rata-rata per pesanan",
  "title1760": "Masukkan iklan kata kunci",
  "title1761": "Masukkan pengunjung iklan terkait",
  "title1762": "Masukkan iklan terkait tunggal",
  "title1763": "Masukkan iklan terkait",
  "title1764": "Masukkan biaya rata-rata per pesanan (iklan terkait)",
  "title1765": "Masukkan pengunjung alami",
  "title1766": "Masukkan tingkat klik alami",
  "title1767": "Masukkan jumlah pesanan alami",
  "title1768": "Masukkan tingkat konversi alami",
  "title1769": "Tanggal data tidak boleh kosong",
  "title1770": "Tambahkan data produk",
  "title1771": "Ubah data produk",
  "title1772": "Konfirmasi penghapusan nomor data produk adalah",
  "title1773": "Konfirmasi ekspor semua item data produk",
  "title1774": "Masukkan terjemahan Cina",
  "title1775": "Masukkan volume pencarian",
  "title1776": "Tambahkan kata kunci produk",
  "title1777": "Ubah kata kunci produk",
  "title1778": "Konfirmasi penghapusan nomor kata kunci produk adalah",
  "title1779": "Konfirmasi ekspor semua item data kata kunci produk",
  "title1780": "Kata kunci produk",
  "title1781": "Point penjualan",
  "title1782": "Konten poin penjualan",
  "title1783": "Tambahkan catatan titik penjualan",
  "title1784": "Ubah catatan titik penjualan",
  "title1785": "Konfirmasi penghapusan nomor catatan titik penjualan adalah",
  "title1786": "Konfirmasi ekspor semua item catatan titik penjualan",
  "title1787": "Aksi Massal",
  "title1788": "Tetapkan status penjualan",
  "title1789": "Edit grup",
  "title1790": "Pengaturan kelompok ",
  "title1791": "Impor Ekspor ",
  "title1792": "Impor kombinasi baru",
  "title1793": "Ekspor produk gabungan",
  "title1794": " Pencocokan impor",
  "title1795": "Ekspor pasangan",
  "title1796": "klik dua kali Pencarian Massal",
  "title1797": "Semua grup",
  "title1798": "Informasi subproduk",
  "title1799": "Perluas",
  "title1800": "Toko barang dipasangkan",
  "title1801": "Perluas yang dipasangkan",
  "title1802": "Grup",
  "title1803": "Waktu",
  "title1804": "Toko dipasangkan",
  "title1805": "Masukkan nama produk",
  "title1806": "Masukkan produk",
  "title1807": "Pengelompokan produk",
  "title1808": "Pengaturan pengelompokan produk",
  "title1809": "Silakan pilih status penjualan",
  "title1810": "Apakah ini merupakan produk gabungan?",
  "title1811": "Silakan pilih apakah ini merupakan produk gabungan",
  "title1812": "Batalkan pilihan",
  "title1813": "Dipilih",
  "title1814": "Pencarian Massal",
  "title1815": "Silakan masukkan beberapa",
  "title1816": "Pisahkan dengan baris pemisah",
  "title1817": "Contoh",
  "title1818": "Kombinasi",
  "title1819": "Impor produk baru",
  "title1820": "Template impor produk",
  "title1821": "Impor pasangan produk",
  "title1822": "Template impor pasangan produk",
  "title1823": "Subproduk",
  "title1824": "Nama subproduk",
  "title1825": "Nama produk tidak boleh kosong",
  "title1826": "Kombinasi produk tidak boleh kosong",
  "title1827": "Status penjualan tidak boleh kosong",
  "title1828": "Harap pilih setidaknya satu produk",
  "title1829": "Pengaturan berhasil disimpan",
  "title1830": "Pilih operasi produk",
  "title1831": "Konfirmasi penghapusan produk? Penghapusan tidak dapat dikembalikan, dan juga akan menghapus inventaris terkait dan hubungan pasangan secara bersamaan",
  "title1832": "Konfirmasi ekspor pasangan produk",
  "title1833": "Tambah SKU Kombinasi",
  "title1834": "Mengubah produk kombinasi",
  "title1835": "Status penjualan berhasil diatur",
  "title1836": "Harap pilih subproduk",
  "title1837": "Masukkan jumlah subproduk",
  "title1838": "Konfirmasi penghapusan barang sebagai",
  "title1839": "Tambahkan inventaris",
  "title1840": "Push Gudang Online",
  "title1841": "Atur gudang",
  "title1842": "Atur tingkat aktivitas",
  "title1843": "Impor pesanan baru",
  "title1844": "Impor pembaruan produk",
  "title1845": "Ekspor produk",
  "title1846": "Tingkat aktivitas",
  "title1847": "Integritas produk",
  "title1848": "Pilih Semua",
  "title1849": "Telah dipilih",
  "title1850": "Semua",
  "title1851": " Gudang",
  "title1852": "Margin keuntungan rata-rata",
  "title1853": "Perkiraan Penjualan Harian",
  "title1854": "Data inventaris (",
  "title1855": "Toko yang dipasangkan",
  "title1856": "Berat produk",
  "title1857": "Dimensi produk",
  "title1858": "Log produk",
  "title1859": "Data penjualan online",
  "title1860": "Pilih Gudang",
  "title1861": "Masukkan nama gudang",
  "title1862": "Nama gudang",
  "title1863": "Impor pembaruan produk",
  "title1864": "Template impor pembaruan produk",
  "title1865": "Laku keras",
  "title1866": "Barang tren",
  "title1867": "Barang biasa",
  "title1868": "Barang tidak terjual",
  "title1869": "Harap gunakan akun utama atau akun admin untuk impor produk dan otorisasi",
  "title1870": "Silakan Pilih Gudang",
  "title1871": "Penyiapan gudang berhasil",
  "title1872": "Apakah Anda yakin ingin menghapus barang",
  "title1873": "Penghapusan tidak dapat dikembalikan, dan juga akan menghapus inventaris terkait dan hubungan pasangan secara bersamaan",
  "title1874": "Apakah Anda yakin ingin mendorong produk",
  "title1875": "Nomor sebagai",
  "title1876": "Berhasil Push",
  "title1877": "Informasi Dasar",
  "title1878": "Pilih kategori",
  "title1879": "Link Sumber Pemasok",
  "title1880": "Masukkan tautan sumber",
  "title1881": "Salin Tautan",
  "title1882": "Link Sumber Produk",
  "title1883": "Masukkan tautan sumber",
  "title1884": "Deskripsi Produk",
  "title1885": "Sisipkan gambar",
  "title1886": "Catatan: Deskripsi produk dapat menyisipkan maksimal 12 gambar",
  "title1887": "Tidak ada gambar dimasukkan",
  "title1888": "Atribut produk",
  "title1889": "Merek",
  "title1890": "Merek Lainnya",
  "title1891": "Informasi Penjualan",
  "title1892": "Jenis Produk",
  "title1893": "Variasi Tunggal",
  "title1894": "Berbagai Variasi",
  "title1895": "Harga Promo",
  "title1896": "Pilih Promo ",
  "title1897": " Refresh ",
  "title1898": "Masukkan jumlah persediaan",
  "title1899": "Grosir",
  "title1900": "Tambah Harga Grosir",
  "title1901": "Media gambar",
  "title1902": "Foto Produk",
  "title1903": "Pilih Gambar",
  "title1904": "Format gambar: Silakan unggah ukuran file tidak melebihi",
  "title1905": "dengan format",
  "title1906": "; Ukuran gambar tidak boleh kurang dari 100",
  "title1907": "Tambahkan video",
  "title1908": "Format video: Silakan unggah ukuran file tidak melebihi",
  "title1909": "Durasi 10",
  "title1910": "file",
  "title1911": "Silakan publikasikan produk dalam 7 hari setelah mengunggah video, jika tidak video akan dihapus secara otomatis.",
  "title1912": "Kemasan logistik",
  "title1913": "Ongkos kirim",
  "title1914": "Masukkan biaya pengiriman",
  "title1915": "Masukkan berat produk",
  "title1916": "Panjang",
  "title1917": "Tinggi",
  "title1918": "Pre-Order",
  "title1919": "Kondisi barang",
  "title1920": "Baru",
  "title1921": "Bekas",
  "title1922": "Nama produk yang dihasilkan secara cerdas",
  "title1923": "Penafian: Semua konten yang dihasilkan oleh layanan ini secara otomatis dihasilkan oleh model. Kami tidak dapat menjamin keakuratan dan kelengkapan konten yang dihasilkan. Konten yang dihasilkan hanya untuk referensi dan tidak mewakili pandangan dan sikap perusahaan. 新舰 tidak bertanggung jawab atas kerugian langsung atau tidak langsung yang timbul dari penggunaan layanan ini.",
  "title1924": "Model dihasilkan secara otomatis. Kami tidak dapat menjamin keakuratannya dan kelengkapannya. Konten yang dihasilkan hanya untuk referensi, dan tidak mewakili pandangan dan sikap Xinjian. Xinjian tidak bertanggung jawab atas kerugian langsung atau tidak langsung yang timbul dari penggunaan layanan ini.",
  "title1925": "Gaya dan murah hati",
  "title1926": "Tambahkan kata kunci baru",
  "title1927": "Gambarkan gambaran dalam pikiran Anda, kata kunci termasuk merek, bahan, fitur utama, dan skenario penggunaan, dll.",
  "title1928": "Bahasa hasil yang dihasilkan",
  "title1929": "Jumlah penggunaan gratis hari ini",
  "title1930": "kali, tersedia berbayar",
  "title1931": "Harap klik 'Mulai Pembuatan' di sebelah kiri terlebih dahulu, hasil yang dihasilkan akan ditampilkan secara otomatis di sini.",
  "title1932": "Nomor seri",
  "title1933": "Terapkan",
  "title1934": "Deskripsi produk yang dihasilkan secara cerdas",
  "title1935": "Hasil yang dihasilkan",
  "title1936": "Silakan pilih kategori",
  "title1937": "Silakan masukkan pencarian kata kunci",
  "title1938": "Edit kategori secara massal",
  "title1939": "Tetapkan semua item yang dipilih ke kategori dan atribut yang sama.",
  "title1940": "Edit atribut secara massal",
  "title1941": "Total 3 produk telah dipilih. Silakan edit atribut masing-masing.",
  "title1942": "Edit gambar batch",
  "title1943": "Setiap produk mendukung hingga 9 gambar produk. Seret gambar untuk menyusun kembali.",
  "title1944": "Gambar ukuran",
  "title1945": "Gambar produk",
  "title1946": "Dapat di-drag untuk diurutkan",
  "title1947": "Tambah lokal",
  "title1948": "Pilih gambar ruang",
  "title1949": "Pilih gambar koleksi",
  "title1950": "Tambahkan gambar koleksi",
  "title1951": "Pilihan 1",
  "title1952": "Kue emas",
  "title1953": "Pilihan 2",
  "title1954": "Susu kulit ganda",
  "title1955": "Pilihan 3",
  "title1956": "Telur tiruan",
  "title1957": "Pilihan 4",
  "title1958": "Mie rambut naga",
  "title1959": "Pilihan 5",
  "title1960": "Bebek Peking",
  "title1961": "【Oversize】kotak pasir kucing",
  "title1962": "Kotak: Ruang pribadi eksklusif untuk kucing, membuat mereka lebih sehat dan lebih bahagia!",
  "title1963": "Lainnya",
  "title1964": "Pilih produk",
  "title1965": "Tampilkan",
  "title1966": "Tambah SKU Gudang",
  "title1967": "Edit Kategori",
  "title1968": "Edit Atribut",
  "title1969": "Tambahkan Watermark",
  "title1970": "Sebelum mempublikasikan, pastikan untuk mengedit informasi produk untuk menghindari informasi yang identik dengan produk asli, sehingga menghindari hukuman platform.",
  "title1971": "Anak",
  "title1972": "Perluas Semua Variasi",
  "title1973": "Tampilkan Terjadwal",
  "title1974": "Telah dipublikasikan, silakan periksa status publikasi nanti",
  "title1975": "Halaman saat ini hanya mendorong produk",
  "title1976": "Ke gudang pihak ketiga, hanya produk anak yang didorong untuk produk bundel",
  "title1977": "Jika",
  "title1978": "Jika kode tidak ada di gudang pihak ketiga, produk ini akan dibuat. Jika ada, perbarui produk ini.",
  "title1979": "Penyedia layanan yang berbeda, produk",
  "title1980": "Ada kemungkinan perbedaan dalam standar penamaan, dan penamaan yang tidak sesuai akan mengakibatkan kegagalan dorongan.",
  "title1981": "Perbarui informasi",
  "title1982": "Waktu dorong",
  "title1983": "Informasi produk diperbarui",
  "title1984": "Sinkronisasi Produk",
  "title1985": "Cepat menghasilkan pencocokan",
  "title1986": "Menghasilkan produk",
  "title1987": "Gabungkan produk",
  "title1988": "Pemetaan Otomatis",
  "title1989": "Buat Aturan Hubungan Pemetaan",
  "title1990": "Hapus Hubungan Pemetaan",
  "title1991": "Sudah dipasangkan?",
  "title1992": "Sudah dijual?",
  "title1993": "Informasi produk online",
  "title1994": "Sudah Dihubungkan",
  "title1995": "Belum Dihubungkan",
  "title1996": "Informasi produk lokal",
  "title1997": "Pasangan",
  "title1998": "Perbaikan pasangan",
  "title1999": "Tambahkan aturan",
  "title2000": "Aturan dieksekusi dari atas ke bawah secara berurutan",
  "title2001": "Masukkan nama aturan",
  "title2002": "Abaikan Awalan",
  "title2003": "Karakter",
  "title2004": "Jumlah digit",
  "title2005": "Pemisah",
  "title2006": "Ketika ditemukan beberapa pemisah, gunakan",
  "title2007": "Gunakan yang paling kiri",
  "title2008": "Gunakan yang paling kanan",
  "title2009": "Abaikan Akhiran",
  "title2010": "Jangan abaikan",
  "title2011": "Awalan",
  "title2012": "Akhiran",
  "title2013": "Ganti karakter",
  "title2014": "Masukkan karakter",
  "title2015": "Ganti dengan",
  "title2016": "Masukkan karakter pengganti",
  "title2017": "Abaikan karakter",
  "title2018": "Masukkan karakter yang diabaikan, satu per baris",
  "title2019": "Contoh",
  "title2020": "Abaikan spasi",
  "title2021": "Uji aturan",
  "title2022": "Silakan masukkan kode produk",
  "title2023": "Tes",
  "title2024": "Hasil uji",
  "title2025": "Hasil uji",
  "title2026": "Terjemahan",
  "title2027": "Gabung dan edit",
  "title2028": "Gabung",
  "title2029": "Dan",
  "title2030": "Simpan",
  "title2031": "Simpan",
  "title2032": "Kategori",
  "title2033": "Default Warehouse",
  "title2034": "Pembuatan selesai",
  "title2035": "Sedang menyinkronkan produk online, harap tunggu",
  "title2036": "Konfirmasi mulai menyinkronkan produk online",
  "title2037": "Perkiraan penyelesaian sinkronisasi produk online dalam 1-3 menit. Setelah sinkronisasi selesai, Anda dapat dengan mudah melihat informasi produk yang diperbarui.",
  "title2038": "Identifikasi pasangan otomatis",
  "title2039": "Aturan pencocokan",
  "title2040": "Cocok【Produk online",
  "title2041": "Dan [Produk lokal",
  "title2042": "Benar-benar identik",
  "title2043": "Menurut produk online",
  "title2044": "Pencocokan, tidak dapat dipasangkan jika produk online kosong",
  "title2045": "Abaikan awalan dan akhir dari 【Produk online",
  "title2046": "Sama",
  "title2047": "Masukkan konten yang diabaikan, pisahkan dengan koma",
  "title2048": "Dipotong dari 【Produk online",
  "title2049": "Potong yang ke",
  "title2050": "Masukkan jumlah karakter yang akan dipotong",
  "title2051": "ke",
  "title2052": "karakter",
  "title2053": "Abaikan karakter yang ditentukan setelah 【Produk online",
  "title2054": "Karakter yang ditentukan",
  "title2055": "Masukkan konten yang ditentukan, pisahkan dengan koma",
  "title2056": "Tentukan karakter",
  "title2057": "Masukkan konten yang ditentukan, pisahkan dengan koma",
  "title2058": "Tidak membedakan huruf besar/kecil",
  "title2059": "Rentang produk yang cocok",
  "title2060": "Produk halaman saat ini",
  "title2061": "Semua hasil filter produk",
  "title2062": "Produk yang dipilih",
  "title2063": "Mulai",
  "title2064": "Mengidentifikasi hasil pencocokan otomatis",
  "title2065": "Pencocokan otomatis berhasil",
  "title2066": "Produk, konfirmasi simpan",
  "title2067": "Hapus secara massal",
  "title2068": "Memasangkan informasi produk lokal",
  "title2069": "Hapus",
  "title2070": "Langkah sebelumnya",
  "title2071": "Belum terdaftar",
  "title2072": "Karakter tetap di awal",
  "title2073": "Jumlah digit tetap di awal",
  "title2074": "Karakter sebelum pemisah tetap",
  "title2075": "Nama aturan tidak boleh kosong",
  "title2076": "Konfirmasi pencocokan otomatis produk yang dipilih",
  "title2077": "item (produk)",
  "title2078": "Berisi duplikat",
  "title2079": "Konfirmasi membatalkan pasangan",
  "title2080": "Penjodohan berhasil dibatalkan",
  "title2081": "Sinkronisasi produk online, harap periksa kembali nanti",
  "title2082": "Harap konfigurasi aturan pasangan terlebih dahulu",
  "title2083": "Berhasil dipasangkan",
  "title2084": "Aturan berhasil disimpan",
  "title2085": "Gunakan akun utama atau akun administrator untuk menghasilkan produk dan otorisasi",
  "title2086": "Pasangan berhasil",
  "title2087": "Rak",
  "title2088": "Tambah Tunggal",
  "title2089": "Tambah Massal",
  "title2090": "Cetak Label Rak",
  "title2091": "Total Stok",
  "title2092": "Klik untuk menyembunyikan",
  "title2093": "Perluas sisanya",
  "title2094": "Gudang",
  "title2095": "Nama Rak",
  "title2096": "Kategori:",
  "title2097": "Nomor rak",
  "title2098": "Kosong",
  "title2099": "Sudah Dipakai",
  "title2100": "Sudah Penuh",
  "title2101": "Membuat",
  "title2102": "Memperbarui",
  "title2103": "Bersihkan",
  "title2104": "Nama rak dibutuhkan",
  "title2105": "Kapasitas maksimum",
  "title2106": "Silakan masukkan kapasitas maksimum",
  "title2107": "Awalan",
  "title2108": "Kolom",
  "title2109": "Tingkat",
  "title2110": "Tanda Penghubung",
  "title2111": "Tinjauan",
  "title2112": "Dapat Dibersihkan",
  "title2113": "Nomor rak tidak boleh kosong",
  "title2114": "Harap isi Kolom",
  "title2115": "Harap isi Tingkat",
  "title2116": "Tambah Nomor Rak",
  "title2117": "Konfirmasi hapus posisi rak?",
  "title2118": "Ubah informasi rak",
  "title2119": "Konfirmasi membersihkan jumlah 0?",
  "title2120": "?",
  "title2121": "Pembersihan berhasil",
  "title2122": "Gagal ",
  "title2123": "Sinkronisasi inventaris gudang pihak ketiga",
  "title2124": "Cetak label",
  "title2125": "Ubah biaya produk",
  "title2126": "Ubah biaya kemasan",
  "title2127": "Impor biaya produk",
  "title2128": "Impor biaya kemasan",
  "title2129": "Ekspor inventaris produk",
  "title2130": "Biaya impor",
  "title2131": "Status inventaris",
  "title2132": "Total Seluruh Stok",
  "title2133": "Total Modal Seluruh Stok",
  "title2134": "Jumlah beku:",
  "title2135": "Nilai beku:",
  "title2136": "Jumlah dalam perjalanan:",
  "title2137": "Nilai dalam perjalanan:",
  "title2138": "Pengadaan",
  "title2139": "Nomor Rak",
  "title2140": "Nilai persediaan",
  "title2141": "Rata-Rata",
  "title2142": "Penjualan Harian(Dinamis)",
  "title2143": "Tingkat stok aman",
  "title2144": "Tanggal kehabisan stok",
  "title2145": "Jumlah pembelian yang direkomendasikan saat kehabisan stok",
  "title2146": "Jumlah pembelian yang direkomendasikan saat kekurangan",
  "title2147": "Jumlah kekurangan",
  "title2148": "Tanggal pembelian yang direkomendasikan",
  "title2149": "Perkiraan waktu kedatangan untuk partai terbaru",
  "title2150": "Atur Nomor Rak",
  "title2151": "Silakan masukkan biaya produk",
  "title2152": "Mengubah volume penjualan terbaru secara massal",
  "title2153": "Volume penjualan terbaru",
  "title2154": "Silakan masukkan volume penjualan terbaru",
  "title2155": "Mengubah parameter inventaris secara massal",
  "title2156": "Hari produksi",
  "title2157": "Silakan masukkan hari produksi",
  "title2158": "Hari pengiriman laut",
  "title2159": "Silakan masukkan jumlah hari pengiriman laut",
  "title2160": "Hari persediaan tambahan",
  "title2161": "Silakan masukkan hari persediaan tambahan",
  "title2162": "Rumus perhitungan stok aman",
  "title2163": "(Hari persediaan tambahan)",
  "title2164": "Volume penjualan harian rata-rata selama 15 hari terakhir",
  "title2165": "Impor inventaris produk",
  "title2166": "Template impor inventaris produk",
  "title2167": "Template impor biaya kemasan produk",
  "title2168": "Total inventaris gudang pihak ketiga",
  "title2169": "Kesenjangan persediaan antara kedua belah pihak",
  "title2170": "Inventaris tersedia yang diharapkan diperbarui",
  "title2171": "Status kunci inventaris",
  "title2172": "Total jumlah terkunci:",
  "title2173": "Terkunci",
  "title2174": "Masukkan beberapa produk",
  "title2175": "Pisahkan dengan baris",
  "title2176": "Pisahkan dengan baris atau koma",
  "title2177": "Mengubah biaya produk secara massal",
  "title2178": "Di bawah level peringatan",
  "title2179": "Volume penjualan harian default",
  "title2180": "Pembaruan sukses",
  "title2181": "Silakan pilih operasi data",
  "title2182": "Mengubah biaya kemasan secara massal",
  "title2183": "Modifikasi stok aman",
  "title2184": "Persediaan dari",
  "title2185": "Konfirmasi penghapusan persediaan produk yang dipilih",
  "title2186": "Konfirmasi penghapusan inventaris produk dengan ID",
  "title2187": "Konfirmasi pengaturan lokasi rak",
  "title2188": "Operasi berhasil",
  "title2189": "Persediaan produk -",
  "title2190": "Unggah tabel informasi selesai",
  "title2191": "Harap terlebih dahulu",
  "title2192": "Ekspor data formulir",
  "title2193": "Nama header tidak dapat diubah, baris header tidak bisa dihapus",
  "title2194": "Ekstensi file harus",
  "title2195": "yaitu",
  "title2196": "format), ukuran file tidak boleh lebih besar dari",
  "title2197": "Mendukung impor hingga",
  "title2198": "entri data",
  "title2199": "Unggah berkas",
  "title2200": "Unduh Template Impor",
  "title2201": "Impor berhasil! Anda telah berhasil mengimpor",
  "title2202": "entri.",
  "title2203": "Tidak ada data dalam file, harap periksa.",
  "title2204": "Impor gagal! Harap periksa laporan kesalahan, atau periksa apakah templat tidak benar sebelum mengimpor ulang.",
  "title2205": "Unduh laporan kesalahan",
  "title2206": "Impor ulang",
  "title2207": "File kesalahan",
  "title2208": "Silakan unggah file",
  "title2209": "มูลค่าในสต็อก",
  "title2210": "Days Available for Sale",
  "title2211": "มูลค่าระหว่างทาง",
  "title2212": "Pratinjau data",
  "title2213": "Impor data",
  "title2214": "Impor selesai",
  "title2215": "Pengaturan usia inventaris",
  "title2216": "Inventaris total",
  "title2217": "Biaya total inventaris",
  "title2218": "Usia rata-rata inventaris",
  "title2219": "ต้นทุนในสต็อก",
  "title2220": "Jumlah kelompok",
  "title2221": "Hari minimum",
  "title2222": "Hari maksimum",
  "title2223": "Kelompok pertama",
  "title2224": "Kelompok kedua",
  "title2225": "Kelompok ketiga",
  "title2226": "Kelompok keempat",
  "title2227": "Kelompok kelima",
  "title2228": "hari atau lebih",
  "title2229": "Jumlah hari adalah bilangan bulat yang lebih besar dari 0",
  "title2230": "Hari maksimum grup pertama tidak boleh kosong",
  "title2231": "Hari maksimum grup kedua tidak boleh kosong",
  "title2232": "Hari maksimum grup ketiga tidak boleh kosong",
  "title2233": "Hari maksimum grup keempat tidak boleh kosong",
  "title2234": "Pengaturan Hari Pembelian",
  "title2235": "Hari pembelian yang disarankan",
  "title2236": "Perkiraan hari kehabisan stok",
  "title2237": "Waktu kedatangan terbaru",
  "title2238": "Gambar utama",
  "title2239": "Spesifikasi pembelian",
  "title2240": "Persediaan gudang luar negeri",
  "title2241": "Rata-rata harian",
  "title2242": "Hari putaran",
  "title2243": "Tanggal perkiraan kehabisan stok",
  "title2244": "Jumlah pembelian yang direkomendasikan",
  "title2245": "Kuantitas Dalam Perjalanan",
  "title2246": "Jumlah kedatangan perkiraan terbaru",
  "title2247": "Biaya produk tidak boleh kosong",
  "title2248": "Volume penjualan dalam 15 hari terakhir",
  "title2249": "Persediaan aktual",
  "title2250": "Jumlah pembelian yang direkomendasikan",
  "title2251": "Persediaan aman - Persediaan dalam perjalanan - Persediaan aktual",
  "title2252": "Pembelian dalam perjalanan",
  "title2253": "Pengalihan dalam perjalanan",
  "title2254": "Konfirmasi ekspor semua item data inventaris produk",
  "title2255": "Persediaan produk",
  "title2256": "Inventaris di platform",
  "title2257": "Buka halaman",
  "title2258": "  Telah Diproses",
  "title2259": "Konfirmasi operasi telah diproses",
  "title2260": "Laporan keuntungan toko bulanan",
  "title2261": "Laporan keuntungan operasional bulanan",
  "title2262": "Manajemen Penerimaan",
  "title2263": "Gudang untuk pesanan pembelian gudang pihak ketiga tidak mendukung penerimaan manual, dan sistem akan secara otomatis menyinkronkan hasil penerimaan gudang pihak ketiga",
  "title2264": "Gudang Asal",
  "title2265": "Gudang Tujuan",
  "title2266": "Waktu Pengiriman",
  "title2267": "Waktu Diterima",
  "title2268": "Jumlah Pemindahan",
  "title2269": "Jumlah Penerimaan Barang ",
  "title2270": "Nomor pesanan transfer",
  "title2271": "Catatan transfer",
  "title2272": "Nomor Resi",
  "title2273": "Jumlah Pemindahan total",
  "title2274": "Status Penerimaan",
  "title2275": "Belum Diterima",
  "title2276": "Sudah Diterima Sebagian",
  "title2277": "Sudah Diterim",
  "title2278": "Konfirmasi Menerima",
  "title2279": "Jumlah Pembelian",
  "title2280": "Nama pesanan pembelian",
  "title2281": "Catatan pembelian",
  "title2282": "Nomor Pembelian",
  "title2283": "Jumlah Pembelian",
  "title2284": "Ubah catatan",
  "title2285": "Harap isi catatan",
  "title2286": "Daftar Pemindahan",
  "title2287": "Nomor pesanan transfer：",
  "title2288": "Jumlah Diterima",
  "title2289": "Tidak Ada Nomor Rak ",
  "title2290": "Daftar Pembelian",
  "title2291": "Gudang Tujuan",
  "title2292": "Tanggal pengiriman",
  "title2293": "Biaya Pengiriman Internasional",
  "title2294": "Biaya Pajak",
  "title2295": "Biaya Lainnya",
  "title2296": "Jumlah produk:",
  "title2297": "Total:",
  "title2298": "Total uang",
  "title2299": "Volume",
  "title2300": "Berat",
  "title2301": "Gudang pengiriman tidak boleh kosong",
  "title2302": "Gudang penerimaan tidak boleh kosong",
  "title2303": "Harap pilih proyek",
  "title2304": "Mengganti gudang pengiriman akan menghapus barang yang ditambahkan, apakah Anda ingin memperbarui?",
  "title2305": "Harap masukkan jumlah penerimaan",
  "title2306": "Berhasil diterima",
  "title2307": "Pemindahan",
  "title2308": "Laporan",
  "title2309": "Harap masukkan nama pesanan pembelian",
  "title2310": "Tanggal Pembuatan",
  "title2311": "Harga per unit",
  "title2312": "Jumlah total pembelian",
  "title2313": "Jumlah Penambahan Stok",
  "title2314": "Pilih pesanan pembelian",
  "title2315": "Saran Kekurangan Stok",
  "title2316": "Pesanan Pembelian",
  "title2317": "Tambah Pesanan Pembelian",
  "title2318": "Dorong pesanan pembelian",
  "title2319": "Kirim secara massal",
  "title2320": "Menunda Pembelian",
  "title2321": "Kembalikan",
  "title2322": "Buat Aturan",
  "title2323": "Saran kehabisan stok",
  "title2324": "Hari Pembelian",
  "title2325": "Perkiraan waktu dari pesanan pembelian hingga penyimpanan pembelian, hari",
  "title2326": "Hari transportasi",
  "title2327": "Hari Aman",
  "title2328": "Hari buffer yang diatur untuk mencegah produk yang dibeli tiba terlambat",
  "title2329": "Total penjualan 3 hari",
  "title2330": "Penjualan rata-rata harian selama 3 hari",
  "title2331": "Jumlah Disarankan",
  "title2332": "Yang lebih besar dari saran kehabisan stok dan saran stok habis",
  "title2333": "Waktu persiapan",
  "title2334": "Waktu persiapan - Persediaan yang tersedia - Jumlah dalam perjalanan",
  "title2335": "Jumlah Rencana",
  "title2336": "Jumlah total pengadaan",
  "title2337": "Kedatangan perkiraan",
  "title2338": "Daftar produk",
  "title2339": "Silakan masukkan daftar produk",
  "title2340": "Waktu Perkiraan Tiba",
  "title2341": "Silakan pilih tanggal",
  "title2342": "Masukkan nomor resi",
  "title2343": "Masukkan biaya pengiriman",
  "title2344": "Pilih metode alokasi biaya",
  "title2345": "Harap masukkan biaya kepala",
  "title2346": "Harap masukkan biaya pajak",
  "title2347": "Harap masukkan biaya lainnya",
  "title2348": "Ulasan pemeriksaan",
  "title2349": "Komentar diperlukan saat menolak",
  "title2350": "Silakan masukkan komentar tinjauan",
  "title2351": "Masukkan catatan",
  "title2352": "Biaya yang Dibagi",
  "title2353": "Biaya pergudangan",
  "title2354": "Impor pesanan pembelian",
  "title2355": "Template impor pesanan pembelian",
  "title2356": "Gudang tidak boleh kosong",
  "title2357": "Nama pesanan pembelian tidak boleh kosong",
  "title2358": "Daftar produk tidak boleh kosong",
  "title2359": "Tambah Pesanan Pembelian",
  "title2360": "Lihat Pesanan Pembelian",
  "title2361": "Edit Pesanan Pembelian",
  "title2362": "Pengajuan Berhasil",
  "title2363": "Apakah Anda yakin ingin membatalkan pesanan pembelian?",
  "title2364": "Apakah Anda yakin ingin menerima dan masuk ke dalam stok?",
  "title2365": "Apakah Anda yakin ingin menghapus pesanan pembelian dengan nama",
  "title2366": "dari pesanan pembelian?",
  "title2367": "Apakah Anda yakin ingin mengekspor semua item data pesanan pembelian?",
  "title2368": "Apakah Anda yakin ingin mengekspor saran pembelian?",
  "title2369": "Gambaran Kembali",
  "title2370": "Laporan Pengembalian",
  "title2371": "Detail Pengembalian",
  "title2372": "Statistik Data Keuntungan Toko",
  "title2373": "Mudah menganalisis dan statistik status operasi toko, membantu pemilik toko memahami situasi keuntungan toko, dan mengoptimalkan strategi bisnis untuk meningkatkan keuntungan.",
  "title2374": "Pilih semua",
  "title2375": "Silakan pilih personel operasi",
  "title2376": "Pilih tanggal",
  "title2377": "Pendapatan pesanan",
  "title2378": "Rincian pendapatan",
  "title2379": "Laporan",
  "title2380": "Jumlah produk",
  "title2382": "Subsidi Marketplace",
  "title2383": "Subsidi ongkos kirim",
  "title2384": "Biaya",
  "title2385": "Detail Pengeluaran",
  "title2386": "Biaya Promosi",
  "title2387": "Detail Promosi",
  "title2388": "Biaya Iklan di Situs",
  "title2389": "Biaya Iklan di Luar Situs",
  "title2390": "Jumlah Pembayaran Evaluasi",
  "title2391": "Jumlah Pengembalian Evaluasi",
  "title2392": "Biaya Operasional",
  "title2393": "Biaya Bungkus Kosong",
  "title2394": "Jumlah Pembayaran Evaluasi - Jumlah Pengembalian Evaluasi",
  "title2395": "Detail Lainnya",
  "title2396": "Penyesuaian Lintas Bulan",
  "title2397": "Penyesuaian Total untuk Pesanan dengan Situasi Penyelesaian Lintas Bulan",
  "title2398": "Penyesuaian Platform",
  "title2399": "Total biaya penyesuaian platform",
  "title2400": "Biaya Toko",
  "title2401": "Detail Profit",
  "title2402": "Persentase Keuntungan",
  "title2403": "Distribusi Proporsi Pengeluaran",
  "title2404": "Distribusi Pengeluaran sebagai Persentase Penjualan",
  "title2405": "Data Keuntungan Toko",
  "title2406": "Menurut Waktu Pembayaran",
  "title2407": "Menurut Waktu Pemesanan",
  "title2408": "Silakan Pilih Bulan untuk Diperbarui",
  "title2409": "Laporan Bulanan Laba -",
  "title2410": "Toko Berhasil Diberi Wewenang",
  "title2411": "Selamat, Anda Telah Berhasil Memberi Wewenang Toko",
  "title2412": "Sebelum Penerimaan Pembeli",
  "title2413": "Untuk pesanan di mana gudang pengiriman ditentukan sebagai gudang pihak ketiga, gudang penerimaan akan default ke gudang pengiriman saat mengembalikan barang. Setelah gudang pihak ketiga berhasil menerima pengembalian, sistem akan secara otomatis menyinkronkan hasil penerimaan gudang pihak ketiga.",
  "title2414": "Sinkronisasi Pesanan",
  "title2415": "Dorong pesanan kembali",
  "title2416": "Status Penambahan Stok",
  "title2417": "Batas Waktu",
  "title2418": "Nomor pesanan produk:",
  "title2419": "Alasan pengembalian barang",
  "title2420": "Waktu pesanan",
  "title2421": "Waktu Purnajual",
  "title2422": "Status Marketplace",
  "title2423": "Dalam",
  "title2424": "Merespons Dalam",
  "title2425": "Mohon di",
  "title2426": "Merespons Sebelum",
  "title2427": "Nomor Retur",
  "title2428": "Nomor Retur",
  "title2429": "Silakan masukkan nomor pesanan pengembalian",
  "title2430": "Masukkan alasan",
  "title2431": "Status Pengembalian",
  "title2432": "Silakan Pilih Pembuatan Kamus",
  "title2433": "Kembali ke Status Lokal",
  "title2434": "Waktu Pembuatan Pesanan Kembali",
  "title2435": "Pilih Waktu Pembuatan Pesanan Kembali",
  "title2436": "Waktu Pembaharuan Pesanan Kembali",
  "title2437": "Pilih Waktu Pembaharuan Pesanan Kembali",
  "title2438": "Nomor pesanan pengembalian tidak boleh kosong",
  "title2439": "Alasan tidak boleh kosong",
  "title2440": "Status pengembalian tidak boleh kosong",
  "title2441": "Status pengembalian tidak boleh kosong",
  "title2442": "Status lokal pengembalian tidak boleh kosong",
  "title2443": "Waktu pembuatan pesanan pengembalian tidak boleh kosong",
  "title2444": "Waktu pembaharuan pesanan pengembalian tidak boleh kosong",
  "title2445": "Waktu penciptaan pesanan pengembalian tidak boleh kosong",
  "title2446": "Waktu pembaruan pesanan pengembalian tidak boleh kosong",
  "title2447": "Tambahkan pesanan pengembalian",
  "title2448": "Ubah pesanan pengembalian",
  "title2449": "Apakah Anda yakin untuk menghapus nomor pesanan pengembalian sebagai",
  "title2450": "Apakah Anda yakin untuk mengekspor semua item data pesanan pengembalian",
  "title2451": "Pesanan pengembalian",
  "title2452": "Semua Situs",
  "title2453": "Apakah",
  "title2454": "Operator",
  "title2455": "Proses untuk penyimpanan",
  "title2456": "Lihat detail",
  "title2457": "Tambahkan catatan",
  "title2458": "Alasan pengembalian barang",
  "title2459": "Detail barang yang sudah disimpan",
  "title2460": "Pengolahan penyimpanan",
  "title2461": "Apakah akan disimpan",
  "title2462": "Penambahan Stok",
  "title2463": "Jangan simpan semua",
  "title2464": "Begitu diproses menjadi penyimpanan, pesanan kembali tidak dapat dibatalkan untuk penyimpanan.",
  "title2465": "Harap berhati-hati",
  "title2466": "Nomor Paket",
  "title2467": "Tutup",
  "title2468": "闭",
  "title2469": "Jumlah kompensasi",
  "title2470": "Masukkan catatan",
  "title2471": "Tanggal catatan",
  "title2472": "Informasi catatan",
  "title2473": "Alasan pengembalian barang",
  "title2474": "Masukkan bilangan bulat positif",
  "title2475": "Nomor pesanan purna jual",
  "title2476": "Nomor pesanan produk",
  "title2477": "Silakan pilih apakah akan disimpan",
  "title2478": "Pengelolaan Retur",
  "title2479": "Masukkan nama peran",
  "title2480": "Status peran",
  "title2481": "Nama Peran",
  "title2482": "Izin fungsional",
  "title2483": "Tetapkan izin",
  "title2484": "Perizinan data",
  "title2485": "Silakan masukkan nama peran",
  "title2486": "Identifier Peran",
  "title2487": "Masukkan Identifier Peran",
  "title2488": "Pesanan Peran",
  "title2489": "Masukkan Keterangan",
  "title2490": "Izin Menu",
  "title2491": "Memuat, harap tunggu",
  "title2492": "Menetapkan Izin Data",
  "title2493": "Rentang Izin",
  "title2494": "Keterkaitan Induk-Anak",
  "title2495": "Pilih node induk, otomatis pilih node anak",
  "title2496": "Nama peran tidak boleh kosong",
  "title2497": "Identifier peran tidak boleh kosong",
  "title2498": "Urutan peran tidak boleh kosong",
  "title2499": "Aktifkan",
  "title2500": "Menonaktifkan",
  "title2501": "Konfirmasi untuk peran?",
  "title2502": "Nama panggilan toko",
  "title2503": "Tambah Peran",
  "title2504": "Edit Peran",
  "title2505": "Berhasil Diedit",
  "title2506": "Konfirmasi penghapusan peran:",
  "title2507": "Konfirmasi ekspor semua item data peran",
  "title2508": "Data Peran",
  "title2509": "Masukkan Kata Kunci",
  "title2510": "Pilih Platform",
  "title2511": "Kedaluwarsa",
  "title2512": "Sudah Diotorisasi",
  "title2513": "Waktu penyegaran",
  "title2514": "Waktu Otorisasi",
  "title2515": "Klik 'Otorisasi'",
  "title2516": "Status Otorisasi",
  "title2517": "Akan membuka halaman otorisasi untuk Anda memberikan izin",
  "title2518": "Akan dibuka untuk Anda",
  "title2519": "Mengotorisasi di halaman otorisasi",
  "title2520": "Silakan masukkan alias toko",
  "title2521": "Hubungkan",
  "title2522": "Alias toko tidak boleh kosong",
  "title2523": "Tambah Otorisasi Toko",
  "title2524": "Edit Toko",
  "title2525": "Penyegaran Otorisasi Berhasil",
  "title2526": "Penyegaran otorisasi gagal, harap otorisasi ulang",
  "title2527": "Otorisasi Toko Baru Ditambahkan dengan Sukses",
  "title2528": "Harap berhati-hati, menghapus toko akan menghapus data berikut: Informasi produk toko akan dihapus secara permanen dan tidak dapat dipulihkan. Informasi pesanan toko akan dibersihkan dan tidak dapat dipulihkan. Informasi pengaturan terkait toko akan dihapus secara permanen dan tidak dapat dipulihkan.",
  "title2529": "Konfirmasi ekspor semua item data toko",
  "title2530": "Dibuat Berhasil",
  "title2531": "Tingkat Omset",
  "title2532": "Ulasan Baru",
  "title2533": "Masukkan Rating Toko",
  "title2534": "Pilih Waktu Pengiriman",
  "title2535": "Masukkan Tingkat Keterlambatan Pengiriman",
  "title2536": "Masukkan Tingkat Belum Selesai",
  "title2537": "Masukkan Tingkat Balasan",
  "title2538": "Pilih Waktu Respon",
  "title2539": "Masukkan Tingkat Omset",
  "title2540": "Masukkan Ulasan Baru",
  "title2541": "Tambah Data Toko",
  "title2542": "Edit Data Toko",
  "title2543": "Konfirmasi penghapusan data toko dengan ID",
  "title2544": "Konfirmasi ekspor semua item data toko",
  "title2545": "Pilih Bulan Biaya",
  "title2546": "Silakan pilih jenis biaya",
  "title2547": "Bulan biaya",
  "title2548": "Silakan masukkan jumlah (positif untuk pengeluaran, negatif untuk pendapatan)",
  "title2549": "Bulan biaya tidak boleh kosong",
  "title2550": "Jenis biaya tidak boleh kosong",
  "title2551": "Jumlah tidak boleh kosong",
  "title2552": "Tambahkan biaya toko",
  "title2553": "Edit biaya toko",
  "title2554": "Konfirmasi penghapusan biaya toko",
  "title2555": " Semua Jasa Kirim ",
  "title2556": "Nama Logistik",
  "title2557": "Jenis Paket",
  "title2558": "Nominal Rugi Belum Diproses",
  "title2559": "Jumlah biaya pengiriman kerugian yang sudah diproses",
  "title2561": "Selisih Ongkos Kirim",
  "title2562": "Diskon Ongkos Kirim Penjual",
  "title2563": "Pengembalian Ongkos Kirim",
  "title2564": "Penyesuaian Ongkos Kirim",
  "title2565": "Status Ongkos Kirim",
  "title2566": "Normal",
  "title2567": "Kerugian",
  "title2568": "Kelebihan",
  "title2569": "Logistik",
  "title2570": "Nomor pesanan",
  "title2571": "Jasa Kirim yang Dipilih Pembel",
  "title2572": "Memproses",
  "title2573": "Silakan masukkan beberapa nomor pesanan, dipisahkan oleh baris",
  "title2574": "Telah Diproses Rugi",
  "title2575": "Setelah konfirmasi, status pengiriman akan diubah menjadi [Kerugian Diproses], tidak dapat dikembalikan. Apakah Anda yakin ingin melanjutkan?",
  "title2576": "Pesanan platform",
  "title2577": "Logistik",
  "title2578": "Produk Tunggal 1 Jenis",
  "title2579": "Barang Banyak, Banyak Jumlah",
  "title2580": "Ada Catatan",
  "title2581": "Tidak Ada Catatan",
  "title2582": "Apakah Anda yakin untuk mengirim pesanan yang dipilih?",
  "title2583": "Pengiriman Berhasil",
  "title2584": "Mohon isi catatan",
  "title2585": "Catatan berhasil ditambahkan",
  "title2586": "Tidak ada izin",
  "title2587": "Apakah Anda yakin untuk mengekspor informasi pesanan?",
  "title2588": "Semua Pesanan",
  "title2589": "Pesanan Uji",
  "title2590": "Pesanan Disisihkan",
  "title2591": "Audit",
  "title2592": "Pengiriman",
  "title2593": "Tarik",
  "title2594": "Buat Pesanan Manual",
  "title2595": "Dorong pesanan",
  "title2596": "Ubah Metode Pengiriman",
  "title2597": "Segarkan biaya terbaru",
  "title2598": "Sisihkan Pesanan",
  "title2599": "Batalkan ditahan",
  "title2600": "Ulasan Tag",
  "title2601": "PembatalanUlasan Tag",
  "title2602": "Hubungan Pemetaan ",
  "title2603": "Pindah ke peninjauan tertunda",
  "title2604": "Tambah Produk ",
  "title2605": "Cetak Massal",
  "title2606": "Cetak Daftar Pengambilan Barang",
  "title2607": "Cetak Label Pengiriman",
  "title2608": "Cetak Label Pengiriman&Daftar Pengambilan Barang",
  "title2609": "Tandai sebagai bukti pengiriman yang sudah dicetak",
  "title2610": "Tandai sebagai bukti pengiriman yang belum dicetak",
  "title2611": "Tandai sebagai daftar pengambilan yang sudah dicetak",
  "title2612": "Tandai sebagai daftar pengambilan yang belum dicetak",
  "title2613": "Refresh Stok",
  "title2614": "Status Cetak",
  "title2615": "Integritas",
  "title2616": "Seluruh Status",
  "title2617": "Tandai",
  "title2618": "Ekspor daftar pesanan",
  "title2619": "Ekspor informasi pembeli",
  "title2620": "Pesanan yang gagal sebenarnya masih ditangani oleh gudang pihak ketiga. Harap segera hubungi penyedia layanan pergudangan pihak ketiga untuk memverifikasi status pesanan guna menghindari kerugian ekonomi akibat pengiriman.",
  "title2621": "Catatan pembeli",
  "title2622": "Tambah Penandaan",
  "title2623": "Jumlah Pembayaran",
  "title2624": "Pendapatan",
  "title2625": "Total",
  "title2626": "Pendapatan Sebenarnya",
  "title2627": "Pengeluaran",
  "title2628": "Perhitungan",
  "title2629": "Biaya Pengemasan",
  "title2630": "Diskon Platform",
  "title2631": "Penerima",
  "title2632": "Status tidak diketahui",
  "title2633": "Pra-pesan",
  "title2634": "Evaluasi",
  "title2635": "Waktu kedaluwarsa",
  "title2636": "Berdasarkan berbagai kebijakan platform, waktu kedaluwarsa tidak selalu berarti pesanan dibatalkan oleh platform. Apakah pesanan dibatalkan oleh platform tergantung pada status platform tersebut",
  "title2637": "Untuk referensi",
  "title2638": "Pengaturan Pengiriman",
  "title2639": "Penjelasan penghapusan:",
  "title2640": "Pesanan dihapus dan ditambahkan ke daftar dorongan yang berhasil.",
  "title2641": "Belum Diproses ",
  "title2642": "Waktu ditandai:",
  "title2643": "Daftar yang telah dipilih dicetak",
  "title2644": "Faktur tercetak",
  "title2645": "Belum diverifikasi",
  "title2646": "Sedang dalam tinjauan",
  "title2647": "Disetujui",
  "title2648": "Ubah Hubungan Pemetaan",
  "title2649": "Ubah Pesanan Saat Ini",
  "title2650": "Ubah Semua",
  "title2651": "Hubungan pencocokan (berlaku hanya untuk pesanan tertunda di bawah toko ini, dan memperbarui hubungan pencocokan secara bersamaan)",
  "title2652": "Pilih jenis",
  "title2653": "Total",
  "title2654": "Pengaturan Waktu Sinkronisasi Pesanan",
  "title2655": "Mulai Sinkronisasi",
  "title2656": "Sinkronkan Pesanan",
  "title2657": "Sinkronkan Pesanan",
  "title2658": "Semua marketplace",
  "title2659": "Silakan masukkan nama toko",
  "title2660": "Sedang Sinkronisasi",
  "title2661": "Sinkronisasi Data Pesanan",
  "title2662": "Kemajuan",
  "title2663": "Lihat Nanti",
  "title2664": "Buat Pesanan Manual",
  "title2665": "Metode pembayaran",
  "title2666": "Pesanan manual online",
  "title2667": "Pesanan Manual offline",
  "title2668": "Informasi pesanan",
  "title2669": "Pilih metode pembayaran",
  "title2670": "Nama logistik",
  "title2671": "Masukkan nama logistik",
  "title2672": "Masukkan nama logistik",
  "title2673": "Masukkan logistik yang ditentukan pembeli",
  "title2674": "Lampiran lainnya",
  "title2675": "Unggah lampiran",
  "title2676": "Penerima",
  "title2677": "Masukkan penerima",
  "title2678": "Nomor telepon seluler",
  "title2679": "Masukkan nomor telepon",
  "title2680": "Negara",
  "title2681": "Pilih Wilayah",
  "title2682": "Provinsi",
  "title2683": "Negara Bagian",
  "title2684": "Masukkan provinsi",
  "title2685": "Masukkan kota",
  "title2686": "Daerah",
  "title2687": "Kabupaten",
  "title2688": "Masukkan distrik",
  "title2689": "Kode Pos",
  "title2690": "Masukkan Kode Pos",
  "title2691": "Alamat detail",
  "title2692": "Masukkan Alamat detail",
  "title2693": "Pesan Pembeli",
  "title2694": "Masukkan pesan pembeli",
  "title2695": "Pilih mata uang",
  "title2696": "Tambahkan produk",
  "title2697": "Total Harga",
  "title2698": "Masukkan bilangan bulat positif lebih dari 0",
  "title2699": "Belum Dicetak",
  "title2700": "Label Pengiriman",
  "title2701": "Sudah Dicetak ",
  "title2702": "Daftar Pengambilan Barang",
  "title2703": "Non pra-pesan",
  "title2704": "Banyak item tunggal",
  "title2705": "Paket campuran",
  "title2706": "Pembeli membayar",
  "title2707": "Dibayar Oleh Penjual",
  "title2708": "2 bulan terakhir",
  "title2709": "Pilih Jangka Waktu",
  "title2710": "Nomor pesanan sistem tidak boleh kosong",
  "title2711": "Nomor pesanan platform tidak boleh kosong",
  "title2712": "Wilayah tidak boleh kosong",
  "title2713": "Informasi pembeli tidak boleh kosong",
  "title2714": "Informasi pelacakan logistik tidak boleh kosong",
  "title2715": "Apakah prapesanan tidak boleh kosong",
  "title2716": "Apakah kekurangan stok tidak boleh kosong",
  "title2717": "Status sistem tidak boleh kosong",
  "title2718": "Tanda tidak boleh kosong",
  "title2719": "Metode pembayaran tidak boleh kosong",
  "title2720": "Silakan pilih negara",
  "title2721": "Silakan masukkan provinsi",
  "title2722": "Telah lewat waktu",
  "title2723": "Dibatalkan",
  "title2724": "Menunggu Dicetak",
  "title2725": "Sedang dalam pengiriman",
  "title2726": "Sudah berhasil disampaikan",
  "title2727": "Pengiriman gagal",
  "title2728": "Pesanan pengembalian",
  "title2729": "Pengembalian dan pengembalian sedang berlangsung",
  "title2730": "Silakan pilih operasi pesanan",
  "title2731": "Apakah Anda yakin untuk menyinkronkan pesanan?",
  "title2732": "Permintaan sinkronisasi berhasil, harap segarkan untuk melihat",
  "title2733": "Apakah Anda yakin ingin menyegarkan biaya terbaru?",
  "title2734": "Biaya berhasil disegarkan",
  "title2735": "Tandai penilaian berhasil",
  "title2736": "Pembatalan penandaan evaluasi berhasil",
  "title2737": "Menyimpan gudang berhasil",
  "title2738": "Apakah Anda yakin untuk menandai pesanan yang dipilih untuk evaluasi?",
  "title2739": "Apakah Anda yakin ingin meninjau pesanan yang dipilih?",
  "title2740": "Apakah Anda yakin ingin mengirimkan pesanan yang dipilih?",
  "title2741": "Pesanan yang dipilih?",
  "title2742": "Apakah Anda yakin ingin mendorong pesanan yang dipilih?",
  "title2743": "Sedang didorong, silakan periksa nanti",
  "title2744": "Apakah Anda yakin ingin membatalkan penandaan evaluasi untuk pesanan yang dipilih?",
  "title2745": "Apakah Anda yakin ingin menunda pesanan yang dipilih?",
  "title2746": "Penundaan berhasil",
  "title2747": "Apakah Anda yakin ingin membatalkan penundaan untuk pesanan yang dipilih?",
  "title2748": "Pembatalan penahanan berhasil",
  "title2749": "Ditandai berhasil",
  "title2750": "Apakah akan memindahkan pesanan yang dipilih ke peninjauan tertunda?",
  "title2751": "Penanda berhasil ditambahkan",
  "title2752": "Konfirmasi penghapusan penanda?",
  "title2753": "Penanda berhasil dihapus",
  "title2754": "Sinkronisasi berhasil",
  "title2755": "Setelah membatalkan pemisahan, semua sub-paket akan digulir kembali ke paket utama. Apakah Anda yakin ingin membatalkan pemisahan?",
  "title2756": "Pemisahan dibatalkan dengan sukses",
  "title2757": "Konfirmasi nomor audit untuk",
  "title2758": "pesanan",
  "title2759": "Konfirmasi penarikan pesanan yang dipilih",
  "title2760": "Penarikan berhasil",
  "title2761": "Konfirmasi penghapusan pesanan yang dipilih",
  "title2762": "Penghapusan berhasil",
  "title2763": "Konfirmasi nomor tunduk untuk",
  "title2764": "Konfirmasi pembatalan nomor tunduk",
  "title2765": "Konfirmasi menandai nomor sebagai",
  "title2766": "pesanan evaluasi",
  "title2767": "Peringatan sistem",
  "title2768": "Pesanan evaluasi berhasil ditandai",
  "title2769": "Konfirmasi pembatalan penanda",
  "title2770": "Berhasil membatalkan pesanan evaluasi yang ditandai",
  "title2771": "Catatan untuk Pengambilan Barang",
  "title2772": "Konfirmasi penghapusan nomor pesanan sebagai",
  "title2773": "Konfirmasi penghapusan barang tambahan",
  "title2774": "Silakan pilih metode pengambilan",
  "title2775": "Metode pengambilan tersimpan dengan sukses",
  "title2776": "Tolong pilih produk atau batalkan",
  "title2777": "Penambahan berhasil",
  "title2778": "Konfirmasi ekspor informasi pembeli",
  "title2779": "Harap masukkan atribut pesanan pembelian",
  "title2780": "Silakan pilih apakah pra-penjualan",
  "title2781": "Silakan pilih kondisi produk",
  "title2782": "Video produk",
  "title2783": "Tabel ukuran",
  "title2784": "Pra-penjualan",
  "title2785": "Kondisi produk",
  "title2786": "Deskripsi singkat",
  "title2787": "Deskripsi lengkap",
  "title2788": "Harga jual",
  "title2789": "Iklan dilihat",
  "title2790": "Jumlah favorit",
  "title2791": "Silakan masukkan kategori",
  "title2792": "Silakan masukkan merek",
  "title2793": "Harap masukkan gambar produk",
  "title2794": "Silakan masukkan video produk",
  "title2795": "Harap masukkan atribut",
  "title2796": "Harap masukkan berat produk",
  "title2797": "Silakan masukkan ukuran produk",
  "title2798": "Harap masukkan tabel ukuran",
  "title2799": "Harap masukkan deskripsi singkat",
  "title2800": "Harap masukkan deskripsi detail",
  "title2801": "Silakan masukkan harga jual",
  "title2802": "Harap masukkan iklan dilihat",
  "title2803": "Harap masukkan jumlah favorit",
  "title2805": "Harap masukkan jumlah komentar",
  "title2806": "Kategori tidak boleh kosong",
  "title2807": "Merek tidak boleh kosong",
  "title2808": "Status pra-penjualan tidak boleh kosong",
  "title2809": "Kondisi produk tidak boleh kosong",
  "title2810": "Jenis tidak boleh kosong",
  "title2811": "Harga jual tidak boleh kosong",
  "title2812": "Iklan dilihat tidak boleh kosong",
  "title2813": "Jumlah favorit tidak boleh kosong",
  "title2815": "Peringkat tidak boleh kosong",
  "title2816": "Jumlah komentar tidak boleh kosong",
  "title2817": "Tambahkan produk online",
  "title2818": "Ubah produk online",
  "title2819": "Konfirmasi menghapus produk online",
  "title2820": "Konfirmasi ekspor semua data produk online",
  "title2821": "Produk online",
  "title2823": "Ongkos kirim pembeli",
  "title2824": "Ongkos kirim penjual",
  "title2825": "Margin laba kotor",
  "title2826": "Daftar pesanan",
  "title2827": "Informasi logistik penjual",
  "title2828": "Pendapatan aktual:",
  "title2829": "Laba aktual:",
  "title2830": "Pendapatan perkiraan:",
  "title2832": "Total",
  "title2833": "Konfirmasi ekspor tabel keuntungan produk",
  "title2834": "Laba Produk",
  "title2835": "Silakan masukkan produk online",
  "title2836": "Ayah",
  "title2837": "Masukkan Ayah",
  "title2838": "Silakan masukkan inventaris",
  "title2839": "Silakan pilih status platform",
  "title2840": "Status lokal",
  "title2841": "Silakan masukkan status lokal",
  "title2842": "Inventaris tidak boleh kosong",
  "title2843": "Status platform tidak boleh kosong",
  "title2844": "Status lokal tidak boleh kosong",
  "title2845": "Konfirmasi ekspor semua produk online",
  "title2846": "Perbarui Modal",
  "title2847": "Perbarui Modal Produk",
  "title2848": "Tambahkan label pesanan",
  "title2849": "Verifikasi pembayaran pesanan",
  "title2850": "Pilih jenis waktu",
  "title2851": "Status pembayaran",
  "title2852": "Integritas pesanan",
  "title2853": "Pesanan normal",
  "title2854": "Biaya pengiriman belum diperbarui",
  "title2855": "Biaya belum diperbarui",
  "title2856": "Saat ini dalam hasil pencarian",
  "title2857": "Pesanan belum dibayar",
  "title2858": "Pemesanan:",
  "title2859": "Pembayaran diterima:",
  "title2860": "tag",
  "title2861": "Jumlah pengembalian dana",
  "title2862": "Sudah dibayar",
  "title2863": "Belum dibayar",
  "title2864": "Rekonsiliasi pembayaran",
  "title2865": "Unggah tabel pembayaran",
  "title2866": "Jumlah Pesanan",
  "title2867": "Jumlah pengembalian dana:",
  "title2868": "Jumlah pesanan platform:",
  "title2869": "Salin nomor pesanan",
  "title2870": "Pesanan ekstra",
  "title2871": "Pesanan ekstra di backend toko",
  "title2872": "Jumlah pembayaran toko",
  "title2873": "Semua jenis",
  "title2874": "Jumlah pembayaran tidak konsisten",
  "title2875": "Mulai rekonsiliasi",
  "title2876": "Unggah biaya produk",
  "title2877": "Harap siapkan data yang akan diimpor sesuai dengan format templat",
  "title2878": "Format",
  "title2879": "Ukuran file tidak boleh melebihi",
  "title2880": "Melalui produk",
  "title2882": "Harap dicatat, tidak didukung untuk mengubah pesanan tanpa barang.",
  "title2883": "Menurut",
  "title2884": "Klik ganda untuk memasukkan banyak",
  "title2885": "Biaya satu produk",
  "title2886": "Edit Massa",
  "title2887": "Waktu pembayaran",
  "title2888": " Edit Secara Massal di Draf ",
  "title2889": "Semua pesanan",
  "title2890": "Pesanan ditunda",
  "title2891": "Pesanan hilang",
  "title2892": "Pesanan penyesuaian lintas bulan",
  "title2893": "Konfirmasi dibatalkan",
  "title2894": "Silakan masukkan biaya",
  "title2895": "Tidak ada biaya produk yang dimodifikasi terdeteksi, harap modifikasi biaya produk sebelum melanjutkan",
  "title2896": "Pembaruan biaya berhasil",
  "title2897": "Template rekonsiliasi",
  "title2898": "Perbarui template impor biaya produk",
  "title2899": "Pilih bulan",
  "title2900": "Pilih waktu pesanan",
  "title2901": "Detail keuntungan pesanan",
  "title2902": "Pilih produk",
  "title2903": "Pilih produk",
  "title2904": "Silakan masukkan nomor penerimaan",
  "title2905": "Harga satuan penerimaan",
  "title2906": "ID nhập kho",
  "title2907": "Ditolak",
  "title2908": "Menunggu pengajuan",
  "title2909": "Gudang Tujuan Penambahan Stok",
  "title2910": "Harga Satuan",
  "title2911": "Impor penerimaan",
  "title2912": "Template impor penerimaan",
  "title2913": "Silakan pilih gudang",
  "title2914": "Konfirmasi hapus item yang dipilih?",
  "title2915": "Tambah Daftar Penambahan Stok",
  "title2916": "Modifikasi penerimaan",
  "title2917": "Lihat penerimaan",
  "title2918": "Konfirmasi penerimaan",
  "title2919": "Penerimaan berhasil",
  "title2920": "Silakan masukkan jumlah penerimaan",
  "title2921": "Konfirmasi penghapusan catatan inventaris sebagai:",
  "title2922": "Konfirmasi ekspor semua item data catatan inventaris",
  "title2923": "Catatan inventaris",
  "title2924": "Waktu operasi",
  "title2925": "Perubahan inventaris",
  "title2926": "Persediaan beku asli",
  "title2927": "Persediaan beku baru",
  "title2928": "Persediaan yang tersedia asli",
  "title2929": "Persediaan yang tersedia baru",
  "title2930": "Informasi masuk dan keluar",
  "title2932": "Silakan pilih rentang waktu",
  "title2933": "Unggah cek inventaris",
  "title2934": "Impor berhasil! Anda telah berhasil mengimpor 1000 entri data.",
  "title2935": "Silakan masukkan nomor keluar",
  "title2936": "Jumlah Pengiriman",
  "title2937": "Nomor Pengurangan Stok",
  "title2938": "Pengurangan Stok",
  "title2939": "Gudang Asal Pengurangan Stok",
  "title2940": "Impor penerimaan keluar",
  "title2941": "Template impor penerimaan keluar",
  "title2942": "Tambah Daftar Pengurangan Stok",
  "title2943": "Modifikasi penerimaan keluar",
  "title2944": "Lihat penerimaan keluar",
  "title2945": "Konfirmasi keluar",
  "title2946": "Keluar berhasil",
  "title2947": "Silakan masukkan jumlah keluar",
  "title2948": "Area",
  "title2949": "Masukkan area",
  "title2950": "Silakan masukkan tingkat pengembalian",
  "title2951": "Tingkat retur tidak boleh kosong",
  "title2952": "Laba toko harian",
  "title2953": "Nomor laba toko harian adalah",
  "title2954": "Data laba toko harian",
  "title2955": "Tambah Tugas Penghitungan",
  "title2956": "Jenis Penghitungan",
  "title2957": "Nomor Penghitungan",
  "title2958": "Penghitungan",
  "title2959": "Nomor",
  "title2960": " Hasil Penghitungan (SKU)",
  "title2961": "Selesai",
  "title2962": "Mulai Penghitungan",
  "title2963": "Akhiri Penghitungan",
  "title2964": "Silakan pilih jenis penghitungan stok",
  "title2965": "Inventaris yang tersedia untuk pemeriksaan inventaris",
  "title2966": "Impor produk",
  "title2967": "Dalam Penghitungan",
  "title2968": "Ekspor jumlah barang",
  "title2969": "Impor Penghitungan",
  "title2970": "Jumlah Penghitungan",
  "title2971": "Hasil Penghitungan",
  "title2972": "Impor templat",
  "title2973": "Impor pemeriksaan inventaris",
  "title2974": "Templat impor pemeriksaan inventaris",
  "title2975": "Jenis inventaris tidak boleh kosong",
  "title2976": "Bertambah",
  "title2977": "Normal",
  "title2978": "Berkurang",
  "title2979": "Buat tugas inventaris baru",
  "title2980": "Modifikasi pemeriksaan inventaris",
  "title2981": "Lihat pemeriksaan inventaris",
  "title2982": "Penghitungan Stok",
  "title2983": "Konfirmasi akhir pemeriksaan inventaris",
  "title2984": "Pemeriksaan inventaris selesai dengan sukses",
  "title2985": "Pembuatan pemeriksaan inventaris baru berhasil",
  "title2986": "Konfirmasi penghapusan nomor sebagai",
  "title2987": "pemeriksaan inventaris",
  "title2988": "Pemeriksaan inventaris dihapus berhasil",
  "title2989": "Konfirmasi ekspor daftar inventaris",
  "title2990": "Daftar pemeriksaan inventaris",
  "title2991": "Konfirmasi ekspor semua item data inventaris gudang",
  "title2992": "Buat pesanan transfer baru",
  "title2993": "Impor pesanan transfer",
  "title2994": "Ekspor pesanan transfer",
  "title2995": "Terima",
  "title2996": "Konfirmasi pengiriman",
  "title2997": "Pilih gudang pengiriman",
  "title2998": "Silakan pilih gudang penerimaan",
  "title2999": "Metode Pembagian Biaya",
  "title3000": "Silakan pilih metode alokasi biaya",
  "title3001": "Edit batch",
  "title3002": "Kuantitas:",
  "title3003": "Harga Pemindahan",
  "title3004": "Harga Pemindahan Terakhir",
  "title3005": "Edit jumlah transfer batch",
  "title3006": "Sama dengan persediaan yang tersedia",
  "title3007": "Atur nilai persediaan seragam",
  "title3008": "Masukkan nilai persediaan seragam",
  "title3009": "Impor pesanan transfer",
  "title3010": "Template impor pesanan transfer",
  "title3011": "Silakan pilih gudang pengiriman",
  "title3012": "Konfirmasi pengiriman",
  "title3013": "Persediaan yang belum diterima akan dikembalikan ke gudang pengiriman, apakah Anda yakin ingin membatalkan?",
  "title3014": "Modifikasi pesanan transfer",
  "title3015": "Masukkan jumlah transfer",
  "title3016": "Persediaan yang tersedia tidak mencukupi untuk transfer",
  "title3017": "Pesanan transfer",
  "title3018": "Konfirmasi ekspor semua data transfer",
  "title3019": "Pesanan transfer",
  "title3020": "Silakan pilih metode pembayaran",
  "title3021": "Nama Pemasok",
  "title3022": "Kontak",
  "title3023": "Nomor Telepon",
  "title3024": "Metode Pembayaran",
  "title3025": "Harap masukkan Nama Pemasok",
  "title3026": "Saluran pembelian",
  "title3027": "Masukkan saluran pembelian",
  "title3028": "Nama Perusahaan",
  "title3029": "Masukkan nama perusahaan",
  "title3030": "Alamat Perusahaan",
  "title3031": "Masukkan alamat perusahaan",
  "title3032": "Website Pemasok",
  "title3033": "Masukkan situs web pemasok",
  "title3034": "Akun bank",
  "title3035": "Masukkan akun bank",
  "title3036": "Nomor rekening",
  "title3037": "Masukkan nomor rekening",
  "title3038": "Masukkan kontak person",
  "title3039": "Masukkan nomor kontak",
  "title3040": "Masukkan WeChat",
  "title3041": "Email",
  "title3042": "Masukkan alamat email",
  "title3043": "Nama pemasok tidak boleh kosong",
  "title3044": "Saluran pembelian tidak boleh kosong",
  "title3045": "Tambah Pemasok",
  "title3046": "Edit pemasok",
  "title3047": "Konfirmasi hapus pemasok",
  "title3048": "Konfirmasi ekspor semua item data pemasok",
  "title3049": "Pemasok",
  "title3050": "Masukkan otorisasi",
  "title3051": "Nomor situs",
  "title3052": "Masukkan nomor situs",
  "title3053": "Nomor gudang",
  "title3054": "Masukkan nomor gudang",
  "title3055": "Diaktifkan",
  "title3056": "Alamat gudang",
  "title3057": "Masukkan alamat gudang",
  "title3058": "Nama gudang tidak boleh kosong",
  "title3059": "Status diaktifkan tidak boleh kosong",
  "title3060": "Nomor situs tidak boleh kosong",
  "title3061": "Nomor gudang tidak boleh kosong",
  "title3062": "Tambahkan gudang berwenang pihak ketiga",
  "title3063": "Ubah gudang berwenang pihak ketiga",
  "title3064": "Konfirmasi penghapusan gudang berwenang pihak ketiga dengan nomor",
  "title3065": "Konfirmasi ekspor semua item data gudang berwenang pihak ketiga",
  "title3066": "Gudang berwenang pihak ketiga",
  "title3067": "Kurs pembayaran",
  "title3068": "Ubah parameter evaluasi",
  "title3069": "Tambahkan parameter evaluasi",
  "title3070": "Masukkan biaya operasional",
  "title3071": "Masukkan biaya paket kosong",
  "title3072": "Harap pilih toko",
  "title3073": "Silakan masukkan kurs pembayaran",
  "title3074": "Fluktuasi kurs pembayaran yang Anda tetapkan signifikan, harap verifikasi sebelum mengirimkan",
  "title3075": "Biaya operasional tidak boleh kosong",
  "title3076": "Biaya paket kosong tidak boleh kosong",
  "title3077": "Konfirmasi penghapusan parameter evaluasi",
  "title3078": "Konfirmasi ekspor semua item data parameter evaluasi",
  "title3079": "Parameter evaluasi",
  "title3080": "Silakan cari nama gudang",
  "title3081": "Kunci",
  "title3082": "Kunci yang sesuai dengan kunci",
  "title3083": "Akun",
  "title3084": "Silakan masukkan akun",
  "title3085": "Informasi otorisasi",
  "title3086": "Tidak Terhubung",
  "title3087": "Tambah Gudang",
  "title3088": "Harap dapatkan informasi otorisasi dari penyedia layanan pihak ketiga, ikat beberapa",
  "title3089": "Ini akan mengakibatkan penolakan pesanan ganda, menyebabkan pesanan ganda dan keliru, disarankan hanya mengikat akun saat ini",
  "title3090": "Nama kustom",
  "title3091": "Masukkan nama kustom gudang",
  "title3092": "Nama penyedia layanan pihak ketiga",
  "title3093": "Masukkan",
  "title3094": "Kode pelanggan",
  "title3095": "Silakan masukkan kode pelanggan",
  "title3096": "Nama kustom tidak boleh kosong",
  "title3097": "Mengotorisasi penyedia layanan gudang pihak ketiga",
  "title3098": "Otorisasi berhasil",
  "title3099": "Konfirmasi penghapusan informasi konfigurasi gudang pihak ketiga dengan ID",
  "title3100": "Konfirmasi ekspor semua item data informasi konfigurasi gudang pihak ketiga",
  "title3101": "Informasi konfigurasi gudang pihak ketiga",
  "title3102": "Nama Produsen",
  "title3103": "Silakan masukkan nama produsen",
  "title3104": "Nomor Produsen",
  "title3105": "Silakan masukkan nomor produsen",
  "title3106": "Produsen",
  "title3107": "Tidak aktif",
  "title3108": "Diaktifkan",
  "title3109": "Situs resmi",
  "title3110": "Merek dagang",
  "title3111": "Tidak aktif",
  "title3112": "Nama produsen tidak boleh kosong",
  "title3113": "Nomor produsen tidak boleh kosong",
  "title3114": "Tambahkan produsen gudang pihak ketiga",
  "title3115": "Modifikasi produsen gudang pihak ketiga",
  "title3116": "Konfirmasi pengalihan status produsen gudang pihak ketiga, dengan ID",
  "title3117": "Berhasil mengubah status",
  "title3118": "Konfirmasi penghapusan produsen gudang pihak ketiga dengan ID",
  "title3119": "Konfirmasi ekspor semua item data produsen gudang pihak ketiga",
  "title3120": "Gudang Online Penyedia Layanan",
  "title3121": "Manajemen Peran ",
  "title3122": "Masukkan informasi akun",
  "title3123": "Semua peran",
  "title3124": "Nama",
  "title3125": "Kelola Peran",
  "title3126": "Alokasi toko",
  "title3127": "Alokasi gudang",
  "title3128": "Alokasi peran",
  "title3129": "Berperan",
  "title3130": "Masukkan nama pengguna",
  "title3131": "Masukkan nama",
  "title3132": "Tambahkan pengguna",
  "title3133": "Kata sandi login",
  "title3134": "Masukkan kata sandi 6 digit",
  "title3135": "Buat sub-akun",
  "title3136": "Masukkan pengguna login",
  "title3137": "Nama asli",
  "title3138": "Konfirmasi kata sandi",
  "title3139": "Izin peran",
  "title3140": "Buat peran",
  "title3141": "Izin toko",
  "title3142": "Izin gudang",
  "title3143": "Izin gudang",
  "title3144": "Konfirmasi pembaruan data pengguna yang ada",
  "title3145": "Hanya boleh impor",
  "title3146": "Format file",
  "title3147": "Belum Diotorisasi",
  "title3148": "Otorisasi Penyedia Layanan",
  "title3149": "Belum Diotorisasi",
  "title3150": "Kata sandi login baru",
  "title3151": "Konfirmasi kata sandi baru",
  "title3152": "Nama tidak boleh kosong",
  "title3153": "Kata sandi login tidak boleh kosong",
  "title3154": "Peran tidak boleh kosong",
  "title3155": "Masukkan nomor telepon yang benar",
  "title3156": "ID pengguna",
  "title3157": "Ubah pengguna",
  "title3158": "Modifikasi berhasil, kata sandi baru adalah:",
  "title3159": "Konfirmasi peran untuk pengguna",
  "title3160": "Konfirmasi mengubah akhiran login",
  "title3161": "Penugasan peran berhasil",
  "title3162": "Konfirmasi penghapusan pengguna:",
  "title3163": "Konfirmasi mengekspor semua item data pengguna",
  "title3164": "Data pengguna",
  "title3165": "Impor pengguna",
  "title3166": "Templat impor pengguna",
  "title3167": "Jumlah pembuatan berhasil",
  "title3168": "Jumlah pembaruan berhasil",
  "title3169": "Jumlah pembaruan gagal",
  "title3170": "Hasil impor",
  "title3171": "Jenis Gudang",
  "title3172": "Sendiri",
  "title3173": "Gudang layanan",
  "title3174": "Gudang Pihak Ketiga",
  "title3175": "Menyediakan Layanan yang Berkualitas",
  "title3176": "Tipe gudang tidak boleh kosong",
  "title3177": "Penyedia layanan tidak boleh kosong",
  "title3178": "Gudang pihak ketiga tidak boleh kosong",
  "title3179": "Mengubah gudang",
  "title3180": "Konfirmasi penghapusan gudang dengan ID",
  "title3181": "Konfirmasi ekspor semua item data gudang",
  "title3182": "Log sistem",
  "title3183": "ID Pengguna",
  "title3184": "Silakan masukkan ID pengguna",
  "title3185": "Jenis pengguna",
  "title3186": "Silakan pilih jenis pengguna",
  "title3187": "应用名",
  "title3188": "请输入应用名",
  "title3189": "请求地址",
  "title3190": "请输入请求地址",
  "title3191": "请求时间",
  "title3192": "执行时长",
  "title3193": "请输入执行时长",
  "title3194": "结果码",
  "title3195": "请输入结果码",
  "title3196": "日志编号",
  "title3197": "请求方法名",
  "title3198": "操作结果",
  "title3199": "详细",
  "title3200": "访问日志详细",
  "title3201": "日志主键：",
  "title3202": "链路追踪：",
  "title3203": "应用名：",
  "title3204": "用户信息：",
  "title3205": "请求信息：",
  "title3206": "请求参数：",
  "title3207": "开始时间：",
  "title3208": "操作结果：",
  "title3209": "访问日志数据项",
  "title3210": "访问日志",
  "title3211": "生成类型",
  "title3212": "文件名",
  "title3213": "请输入文件名",
  "title3214": "生成类型不能为空",
  "title3215": "页面",
  "title3216": "弹窗",
  "title3217": "异常时间",
  "title3218": "处理状态",
  "title3219": "请选择处理状态",
  "title3220": "异常发生时间",
  "title3221": "异常名",
  "title3222": "已忽略",
  "title3223": "异常日志详细",
  "title3224": "异常时间：",
  "title3225": "处理时间",
  "title3226": "确认标记为",
  "title3227": "错误日志数据项",
  "title3228": "错误日志",
  "title3229": "没有与",
  "title3230": "匹配的",
  "title3231": "选择图标",
  "title3232": "请输入图标名称",
  "title3233": "文件",
  "title3234": "复制代码",
  "title3235": "选择生成类型",
  "title3236": "资源引用",
  "title3237": "格式不能识别，仅支持修改",
  "title3238": "的对象内容",
  "title3239": "请使用",
  "title3240": "错误：",
  "title3241": "导出文件",
  "title3242": "格式错误，请检查",
  "title3243": "外部资源引用",
  "title3244": "资源路径",
  "title3245": "添加其他",
  "title3246": "资源已存在",
  "title3247": "表名称",
  "title3248": "表描述",
  "title3249": "实体类名称",
  "title3250": "默认去除表名的前缀。如果存在重复，则需要手动添加前缀，避免",
  "title3251": "报",
  "title3252": "重复的问题。",
  "title3253": "作者",
  "title3254": "请输入表名称",
  "title3255": "请输入表描述",
  "title3256": "请输入实体类名称",
  "title3257": "请输入作者",
  "title3258": "字段信息",
  "title3259": "字段列名",
  "title3260": "字段描述",
  "title3261": "物理类型",
  "title3262": "插入",
  "title3263": "列表",
  "title3264": "查询",
  "title3265": "查询方式",
  "title3266": "允许空",
  "title3267": "显示类型",
  "title3268": "文本框",
  "title3269": "文本域",
  "title3270": "下拉框",
  "title3271": "单选框",
  "title3272": "复选框",
  "title3273": "日期控件",
  "title3274": "图片上传",
  "title3275": "文件上传",
  "title3276": "富文本控件",
  "title3277": "示例",
  "title3278": "生成信息",
  "title3279": "表单校验未通过，请重新检查提交内容",
  "title3280": "选项名",
  "title3281": "请输入选项名",
  "title3282": "选项值",
  "title3283": "请输入选项值",
  "title3284": "字符串",
  "title3285": "数字",
  "title3286": "生成模板",
  "title3287": "生成场景",
  "title3288": "模块名",
  "title3289": "模块名，即一级目录，例如",
  "title3290": "等等",
  "title3291": "业务名",
  "title3292": "业务名，即二级目录，例如",
  "title3293": "类名称",
  "title3294": "类名称（首字母大写），例如",
  "title3295": "类描述",
  "title3296": "用作类描述，例如",
  "title3297": "上级菜单",
  "title3298": "分配到指定菜单下，例如",
  "title3299": "系统管理",
  "title3300": "请选择系统菜单",
  "title3301": "自定义路径",
  "title3302": "填写磁盘绝对路径，若不填写，则生成到当前",
  "title3303": "项目下",
  "title3304": "最近路径快速选择",
  "title3305": "恢复默认的生成基础路径",
  "title3306": "其他信息",
  "title3307": "树编码字段",
  "title3308": "树显示的编码字段名，",
  "title3309": "树父编码字段",
  "title3310": "树显示的父编码字段名，",
  "title3311": "树名称字段",
  "title3312": "树节点的显示名称字段名，",
  "title3313": "关联信息",
  "title3314": "关联子表的表名",
  "title3315": "关联子表的表名，",
  "title3316": "子表关联的外键名",
  "title3317": "子表关联的外键名，",
  "title3318": "请选择生成模板",
  "title3319": "请选择生成场景",
  "title3320": "请输入生成模块名",
  "title3321": "请输入生成业务名",
  "title3322": "请输入生成业务包",
  "title3323": "请输入生成类名称",
  "title3324": "请输入生成类描述",
  "title3325": "暂时不考虑支持【树形】和【主子表】的代码生成。原因是：导致",
  "title3326": "模板过于复杂，不利于胖友二次开发",
  "title3327": "导入表",
  "title3328": "数据源",
  "title3329": "请选择数据源",
  "title3330": "导入成功",
  "title3331": "单元测试",
  "title3332": "实体",
  "title3333": "预览",
  "title3334": "生成代码",
  "title3335": "代码预览",
  "title3336": "确认要强制同步",
  "title3337": "表结构吗？",
  "title3338": "生成配置",
  "title3339": "是否确认删除表名称为",
  "title3340": "未知【",
  "title3341": "配置中心",
  "title3342": "参数名称",
  "title3343": "请输入参数名称",
  "title3344": "参数键名",
  "title3345": "请输入参数键名",
  "title3346": "系统内置",
  "title3347": "参数主键",
  "title3348": "参数分类",
  "title3349": "参数键值",
  "title3350": "是否可见",
  "title3351": "请输入参数分类",
  "title3352": "请输入参数键值",
  "title3353": "参数分类不能为空",
  "title3354": "参数名称不能为空",
  "title3355": "参数键名不能为空",
  "title3356": "参数键值不能为空",
  "title3357": "添加参数",
  "title3358": "修改参数",
  "title3359": "是否确认删除参数编号为",
  "title3360": "是否确认导出所有参数数据项",
  "title3361": "参数配置",
  "title3362": "组件属性",
  "title3363": "表单属性",
  "title3364": "查看组件文档",
  "title3365": "组件类型",
  "title3366": "请选择组件类型",
  "title3367": "字段名",
  "title3368": "请输入字段名（",
  "title3369": "组件名",
  "title3370": "标题",
  "title3371": "请输入标题",
  "title3372": "占位提示",
  "title3373": "请输入占位提示",
  "title3374": "开始占位",
  "title3375": "结束占位",
  "title3376": "表单栅格",
  "title3377": "栅格间隔",
  "title3378": "布局模式",
  "title3379": "水平排列",
  "title3380": "请选择水平排列",
  "title3381": "垂直排列",
  "title3382": "标签宽度",
  "title3383": "请输入标签宽度",
  "title3384": "组件宽度",
  "title3385": "请输入组件宽度",
  "title3386": "请输入默认值",
  "title3387": "至少应选",
  "title3388": "最多可选",
  "title3389": "请输入前缀",
  "title3390": "后缀",
  "title3391": "请输入后缀",
  "title3392": "前图标",
  "title3393": "请输入前图标名称",
  "title3394": "后图标",
  "title3395": "请输入后图标名称",
  "title3396": "按钮图标",
  "title3397": "请输入按钮图标名称",
  "title3398": "选项分隔符",
  "title3399": "请输入选项分隔符",
  "title3400": "最小行数",
  "title3401": "最大行数",
  "title3402": "最小值",
  "title3403": "最大值",
  "title3404": "组件高度",
  "title3405": "步长",
  "title3406": "步数",
  "title3407": "按钮位置",
  "title3408": "默认",
  "title3409": "右侧",
  "title3410": "最多输入",
  "title3411": "请输入字符长度",
  "title3412": "开启提示",
  "title3413": "请输入开启提示",
  "title3414": "关闭提示",
  "title3415": "请输入关闭提示",
  "title3416": "开启值",
  "title3417": "请输入开启值",
  "title3418": "关闭值",
  "title3419": "请输入关闭值",
  "title3420": "时间类型",
  "title3421": "请选择时间类型",
  "title3422": "文件字段名",
  "title3423": "请输入上传文件字段名",
  "title3424": "文件类型",
  "title3425": "请选择文件类型",
  "title3426": "主键编号",
  "title3427": "数据源名称",
  "title3428": "数据源连接",
  "title3429": "请输入数据源连接",
  "title3430": "数据源名称不能为空",
  "title3431": "数据源连接不能为空",
  "title3432": "添加数据源配置",
  "title3433": "修改数据源配置",
  "title3434": "是否确认删除数据源配置编号为",
  "title3435": "数据库文档",
  "title3436": "上传下载",
  "title3437": "文件路径",
  "title3438": "请输入文件路径",
  "title3439": "文件大小",
  "title3440": "文件内容",
  "title3441": "无法预览，点击",
  "title3442": "上传时间",
  "title3443": "提示：仅允许导入",
  "title3444": "是否确认删除文件编号为",
  "title3445": "配置名",
  "title3446": "请输入配置名",
  "title3447": "存储器",
  "title3448": "请选择存储器",
  "title3449": "主配置",
  "title3450": "基础路径",
  "title3451": "请输入基础路径",
  "title3452": "主机地址",
  "title3453": "请输入主机地址",
  "title3454": "主机端口",
  "title3455": "请输入主机端口",
  "title3456": "连接模式",
  "title3457": "主动模式",
  "title3458": "节点地址",
  "title3459": "请输入节点地址",
  "title3460": "存储",
  "title3461": "自定义域名",
  "title3462": "请输入自定义域名",
  "title3463": "配置名不能为空",
  "title3464": "存储器不能为空",
  "title3465": "基础路径不能为空",
  "title3466": "主机地址不能为空",
  "title3467": "主机端口不能为空",
  "title3468": "连接模式不能为空",
  "title3469": "节点地址不能为空",
  "title3470": "自定义域名不能为空",
  "title3471": "添加文件配置",
  "title3472": "修改文件配置",
  "title3473": "是否确认删除文件配置编号为",
  "title3474": "是否确认修改配置编号为",
  "title3475": "的数据项为主配置",
  "title3476": "测试通过，上传文件成功！访问地址：",
  "title3477": "数据库",
  "title3478": "多数据源（读写分离）",
  "title3479": "异步任务",
  "title3480": "消息队列",
  "title3481": "处理器的名字",
  "title3482": "请输入处理器的名字",
  "title3483": "开始执行时间",
  "title3484": "选择开始执行时间",
  "title3485": "结束执行时间",
  "title3486": "选择结束执行时间",
  "title3487": "任务状态",
  "title3488": "请选择任务状态",
  "title3489": "处理器的参数",
  "title3490": "第几次执行",
  "title3491": "执行时间",
  "title3492": "毫秒",
  "title3493": "调度日志详细",
  "title3494": "日志编号：",
  "title3495": "任务编号：",
  "title3496": "处理器的名字：",
  "title3497": "处理器的参数：",
  "title3498": "第几次执行：",
  "title3499": "执行时间：",
  "title3500": "执行时长：",
  "title3501": "任务状态：",
  "title3502": "执行结果：",
  "title3503": "是否确认导出所有定时任务日志数据项",
  "title3504": "定时任务日志",
  "title3505": "请输入任务名称",
  "title3506": "执行日志",
  "title3507": "表达式",
  "title3508": "暂停",
  "title3509": "更多",
  "title3510": "执行一次",
  "title3511": "任务详细",
  "title3512": "调度日志",
  "title3513": "请输入处理器的参数",
  "title3514": "生成表达式",
  "title3515": "重试次数",
  "title3516": "请输入重试次数。设置为",
  "title3517": "时，不进行重试",
  "title3518": "重试间隔",
  "title3519": "请输入重试间隔，单位：毫秒。设置为",
  "title3520": "时，无需间隔",
  "title3521": "监控超时时间",
  "title3522": "请输入监控超时时间，单位：毫秒",
  "title3523": "表达式生成器",
  "title3524": "任务名称：",
  "title3525": "表达式：",
  "title3526": "重试次数：",
  "title3527": "重试间隔：",
  "title3528": "监控超时时间：",
  "title3529": "后续执行时间：",
  "title3530": "任务名称不能为空",
  "title3531": "处理器的名字不能为空",
  "title3532": "表达式不能为空",
  "title3533": "重试次数不能为空",
  "title3534": "重试间隔不能为空",
  "title3535": "确认要立即执行一次",
  "title3536": "任务吗",
  "title3537": "执行成功",
  "title3538": "添加任务",
  "title3539": "修改任务",
  "title3540": "是否确认删除定时任务编号为",
  "title3541": "定时任务编号为",
  "title3542": "是否确认导出所有定时任务数据项",
  "title3543": "服务监控",
  "title3544": "接口文档",
  "title3545": "参数分组",
  "title3546": "是否敏感",
  "title3547": "请输入参数分组",
  "title3548": "参数分组不能为空",
  "title3549": "缓存",
  "title3550": "本地缓存",
  "title3551": "版本",
  "title3552": "运行模式",
  "title3553": "单机",
  "title3554": "集群",
  "title3555": "端口",
  "title3556": "客户端数",
  "title3557": "运行时间",
  "title3558": "使用内存",
  "title3559": "内存配置",
  "title3560": "是否开启",
  "title3561": "是否成功",
  "title3562": "网络入口",
  "title3563": "出口",
  "title3564": "命令统计",
  "title3565": "内存信息",
  "title3566": "超时时间",
  "title3567": "秒",
  "title3568": "键名列表",
  "title3569": "缓存键名",
  "title3570": "缓存内容",
  "title3571": "清理全部",
  "title3572": "命令",
  "title3573": "峰值",
  "title3574": "内存消耗",
  "title3575": "正在加载缓存监控数据，请稍后！",
  "title3576": "刷新键名列表成功",
  "title3577": "清理缓存键名",
  "title3578": "名字",
  "title3579": "请输入名字",
  "title3580": "名字不能为空",
  "title3581": "分类不能为空",
  "title3582": "添加字典类型",
  "title3583": "修改字典类型",
  "title3584": "是否确认删除字典类型编号为",
  "title3585": "是否确认导出所有字典类型数据项",
  "title3586": "第三方服务类型",
  "title3587": "请选择第三方服务类型",
  "title3588": "配置编码",
  "title3589": "请输入配置编码",
  "title3590": "请选择开启状态",
  "title3591": "配置内容",
  "title3592": "第三方服务类型不能为空",
  "title3593": "配置编码不能为空",
  "title3594": "配置内容不能为空",
  "title3595": "添加第三方服务配置",
  "title3596": "修改第三方服务配置",
  "title3597": "是否确认删除第三方服务配置编号为",
  "title3598": "是否确认导出所有第三方服务配置数据项",
  "title3599": "优惠券类",
  "title3600": "请选择优惠券类型",
  "title3601": "满减",
  "title3602": "折扣",
  "title3603": "随机",
  "title3604": "优惠券名称",
  "title3605": "请输入优惠券名称",
  "title3606": "请选择状态（1进行中2已结束-1已关闭）",
  "title3607": "有效日期结束时间",
  "title3608": "优惠券类型",
  "title3609": "优惠券图片",
  "title3610": "发放数量",
  "title3611": "已领取数量",
  "title3612": "已使用数量",
  "title3613": "使用门槛",
  "title3614": "优惠金额",
  "title3615": "有效期限",
  "title3616": "领取上限",
  "title3617": "优惠叠加",
  "title3618": "是否显示",
  "title3619": "订单的优惠总金额",
  "title3620": "用券总成交额",
  "title3621": "是否禁止发放",
  "title3622": "使用优惠券购买的商品数量",
  "title3623": "名称备注",
  "title3624": "请输入名称备注",
  "title3625": "请输入发放数量",
  "title3626": "适用商品类型",
  "title3627": "适用商品",
  "title3628": "请输入适用商品",
  "title3629": "满多少元使用",
  "title3630": "请输入满多少元使用",
  "title3631": "代表无限制",
  "title3632": "发放面额",
  "title3633": "请输入发放面额",
  "title3634": "时需要添加",
  "title3635": "请输入1",
  "title3636": "最多折扣金额",
  "title3637": "请输入最多折扣金额",
  "title3638": "时可选择性添加",
  "title3639": "最低金额",
  "title3640": "请输入最低金额",
  "title3641": "最大金额",
  "title3642": "请输入最大金额",
  "title3643": "过期类型",
  "title3644": "使用开始日期",
  "title3645": "过期类型1时必填",
  "title3646": "选择使用开始日期",
  "title3647": "使用结束日期",
  "title3648": "选择使用结束日期",
  "title3649": "为2或者3时需要添加",
  "title3650": "领取之日起或者次日",
  "title3651": "天内有效",
  "title3652": "请输入当",
  "title3653": "是否无限制0-否",
  "title3654": "每人最大领取个数",
  "title3655": "请输入每人最大领取个数",
  "title3656": "是否开启过期提醒0-不开启",
  "title3657": "过期前",
  "title3658": "天提醒",
  "title3659": "请输入过期前",
  "title3660": "不限制",
  "title3661": "优惠券仅原价购买商品时可用",
  "title3662": "请输入是否显示",
  "title3663": "请输入订单的优惠总金额",
  "title3664": "请输入用券总成交额",
  "title3665": "是否禁止发放0-否",
  "title3666": "请输入使用优惠券购买的商品数量",
  "title3667": "选择有效日期结束时间",
  "title3668": "随机不能为空",
  "title3669": "优惠券名称不能为空",
  "title3670": "发放数量不能为空",
  "title3671": "已领取数量不能为空",
  "title3672": "已使用数量不能为空",
  "title3673": "适用商品类型1-全部商品可用；2-指定商品可用；3-指定商品不可用不能为空",
  "title3674": "使用门槛0-无门槛",
  "title3675": "有门槛不能为空",
  "title3676": "代表无限制不能为空",
  "title3677": "时需要添加不能为空",
  "title3678": "时可选择性添加不能为空",
  "title3679": "过期类型1-时间范围过期",
  "title3680": "领取之日固定日期后过期",
  "title3681": "领取次日固定日期后过期不能为空",
  "title3682": "天内有效不能为空",
  "title3683": "是不能为空",
  "title3684": "每人最大领取个数不能为空",
  "title3685": "开启不能为空",
  "title3686": "天提醒不能为空",
  "title3687": "优惠券仅原价购买商品时可用不能为空",
  "title3688": "是否显示不能为空",
  "title3689": "订单的优惠总金额不能为空",
  "title3690": "用券总成交额不能为空",
  "title3691": "使用优惠券购买的商品数量不能为空",
  "title3692": "状态（1进行中2已结束-1已关闭）不能为空",
  "title3693": "添加优惠券模板",
  "title3694": "修改优惠券模板",
  "title3695": "是否确认删除优惠券模板编号为",
  "title3696": "是否确认导出所有优惠券模板数据项",
  "title3697": "优惠券模板",
  "title3698": "身份证号",
  "title3699": "请输入身份证号",
  "title3700": "审核状态",
  "title3701": "请选择审核状态",
  "title3702": "审核时间",
  "title3703": "选择审核时间",
  "title3704": "关联的用户编号不能为空",
  "title3705": "身份证号不能为空",
  "title3706": "审核状态不能为空",
  "title3707": "审核时间不能为空",
  "title3708": "添加会员实名认证",
  "title3709": "修改会员实名认证",
  "title3710": "是否确认删除会员实名认证编号为",
  "title3711": "是否确认导出所有会员实名认证数据项",
  "title3712": "请输入推荐人编号",
  "title3713": "邀请人手机号",
  "title3714": "请输入邀请人手机号",
  "title3715": "会员编号",
  "title3716": "邀请人编号",
  "title3717": "关系路径",
  "title3718": "请输入邀请人编号",
  "title3719": "请输入关系路径",
  "title3720": "邀请人编号不能为空",
  "title3721": "关系路径不能为空",
  "title3722": "添加用户邀请关系",
  "title3723": "修改用户邀请关系",
  "title3724": "是否确认删除用户邀请关系编号为",
  "title3725": "是否确认导出所有用户邀请关系数据项",
  "title3726": "会员手机号",
  "title3727": "请输入会员手机号",
  "title3728": "收货号码",
  "title3729": "请输入收货号码",
  "title3730": "请选择是否默认",
  "title3731": "市",
  "title3732": "地址",
  "title3733": "请输入市",
  "title3734": "请输入地址",
  "title3735": "用户编号不能为空",
  "title3736": "地址不能为空",
  "title3737": "添加收货地址",
  "title3738": "修改收货地址",
  "title3739": "是否确认删除收货地址编号为",
  "title3740": "是否确认导出所有收货地址数据项",
  "title3741": "用户昵称",
  "title3742": "请输入用户昵称",
  "title3743": "用户状态",
  "title3744": "是否实名",
  "title3745": "请输入注册",
  "title3746": "最后登录",
  "title3747": "最后登录时间",
  "title3748": "重置短信",
  "title3749": "重置实名",
  "title3750": "重置锁单",
  "title3751": "重置成功",
  "title3752": "添加会员",
  "title3753": "修改会员",
  "title3754": "是否确认导出所有会员数据项",
  "title3755": "商户名称",
  "title3756": "请输入商户名称",
  "title3757": "应用编号",
  "title3758": "支付宝配置",
  "title3759": "微信配置",
  "title3760": "所属商户",
  "title3761": "请选择所属商户",
  "title3762": "支付结果的回调地址",
  "title3763": "请输入支付结果的回调地址",
  "title3764": "退款结果的回调地址",
  "title3765": "请输入退款结果的回调地址",
  "title3766": "应用名不能为空",
  "title3767": "支付结果的回调地址不能为空",
  "title3768": "退款结果的回调地址不能为空",
  "title3769": "商户编号不能为空",
  "title3770": "添加支付应用信息",
  "title3771": "修改支付应用信息",
  "title3772": "应用吗",
  "title3773": "是否确认删除支付应用信息编号为",
  "title3774": "已取消删除",
  "title3775": "是否确认导出所有支付应用信息数据项",
  "title3776": "支付应用信息",
  "title3777": "商户号",
  "title3778": "请输入商户号",
  "title3779": "商户全称",
  "title3780": "请输入商户全称",
  "title3781": "商户简称",
  "title3782": "请输入商户简称",
  "title3783": "商户编号",
  "title3784": "商户号不能为空",
  "title3785": "商户全称不能为空",
  "title3786": "商户简称不能为空",
  "title3787": "添加支付商户信息",
  "title3788": "修改支付商户信息",
  "title3789": "商户吗",
  "title3790": "是否确认删除支付商户信息编号为",
  "title3791": "是否确认导出所有支付商户信息数据项",
  "title3792": "支付商户信息",
  "title3793": "请选择应用信息",
  "title3794": "渠道编码",
  "title3795": "请输入渠道编码",
  "title3796": "商户订单编号",
  "title3797": "请输入商户订单编号",
  "title3798": "渠道订单号",
  "title3799": "请输入渠道订单号",
  "title3800": "请选择支付状态",
  "title3801": "退款状态",
  "title3802": "请选择退款状态",
  "title3803": "回调商户状态",
  "title3804": "请选择订单回调商户状态",
  "title3805": "支付渠道",
  "title3806": "应用名称",
  "title3807": "渠道名称",
  "title3808": "支付订单",
  "title3809": "商户",
  "title3810": "支付",
  "title3811": "商品标题",
  "title3812": "手续金额",
  "title3813": "回调状态",
  "title3814": "商户订单号",
  "title3815": "支付订单号",
  "title3816": "手续费",
  "title3817": "手续费比例",
  "title3818": "回调地址",
  "title3819": "失效时间",
  "title3820": "通知时间",
  "title3821": "退款次数",
  "title3822": "支付通道异步回调内容",
  "title3823": "时间范围最大为",
  "title3824": "天！",
  "title3825": "是否确认导出所有支付订单数据项",
  "title3826": "退款类型",
  "title3827": "请选择退款类型",
  "title3828": "商户退款订单号",
  "title3829": "请输入商户退款订单号",
  "title3830": "退款回调状态",
  "title3831": "请选择通知商户退款结果的回调状态",
  "title3832": "退款",
  "title3833": "交易",
  "title3834": "渠道",
  "title3835": "元",
  "title3836": "退款原因",
  "title3837": "退款成功时间",
  "title3838": "退款订单详情",
  "title3839": "商户退款单号",
  "title3840": "交易订单号",
  "title3841": "退款失效时间",
  "title3842": "回调时间",
  "title3843": "渠道退款单号",
  "title3844": "渠道错误码",
  "title3845": "渠道错误码描述",
  "title3846": "渠道额外参数",
  "title3847": "是否确认导出所有退款订单量据项",
  "title3848": "字典名称",
  "title3849": "字典标签",
  "title3850": "请输入字典标签",
  "title3851": "数据状态",
  "title3852": "字典编码",
  "title3853": "字典键值",
  "title3854": "字典排序",
  "title3855": "颜色类型",
  "title3856": "数据标签",
  "title3857": "请输入数据标签",
  "title3858": "数据键值",
  "title3859": "请输入数据键值",
  "title3860": "显示排序",
  "title3861": "数据标签不能为空",
  "title3862": "数据键值不能为空",
  "title3863": "数据顺序不能为空",
  "title3864": "主要",
  "title3865": "危险",
  "title3866": "添加字典数据",
  "title3867": "修改字典数据",
  "title3868": "是否确认删除字典编码为",
  "title3869": "字典数据",
  "title3870": "请输入字典名称",
  "title3871": "请输入字典类型",
  "title3872": "字典状态",
  "title3873": "字典编号",
  "title3874": "字典名称不能为空",
  "title3875": "字典类型不能为空",
  "title3876": "是否确认删除字典编号为",
  "title3877": "部门名称",
  "title3878": "请输入部门名称",
  "title3879": "菜单状态",
  "title3880": "负责人",
  "title3881": "上级部门",
  "title3882": "选择上级部门",
  "title3883": "请输入负责人",
  "title3884": "部门状态",
  "title3885": "部门名称不能为空",
  "title3886": "显示排序不能为空",
  "title3887": "请输入正确的邮箱地址",
  "title3888": "未设置",
  "title3889": "添加部门",
  "title3890": "修改部门",
  "title3891": "异常处理（错误码）",
  "title3892": "错误码类型",
  "title3893": "请选择错误码类型",
  "title3894": "错误码编码",
  "title3895": "请输入错误码编码",
  "title3896": "错误码提示",
  "title3897": "请输入错误码提示",
  "title3898": "错误码编码不能为空",
  "title3899": "错误码提示不能为空",
  "title3900": "添加错误码",
  "title3901": "修改错误码",
  "title3902": "是否确认删除错误码编号为",
  "title3903": "是否确认导出所有错误码数据项",
  "title3904": "错误码",
  "title3905": "文章类型",
  "title3906": "请选择文章类型",
  "title3907": "文章标题",
  "title3908": "请输入文章标题",
  "title3909": "文章内容",
  "title3910": "文章类型不能为空",
  "title3911": "文章标题不能为空",
  "title3912": "文章内容不能为空",
  "title3913": "添加文章",
  "title3914": "修改文章",
  "title3915": "是否确认删除文章编号为",
  "title3916": "是否确认导出所有文章数据项",
  "title3917": "Masukkan simbol mata uang",
  "title3918": "Nama mata uang",
  "title3919": "Halaman saat ini menyediakan fungsi modifikasi 'Kurs Historis', yang akan memengaruhi semua pesanan 'belum dibayar' dalam tanggal yang dimodifikasi dan memiliki dampak pada laporan keuangan dan perhitungan laba. Jika Anda perlu mengubah kurs 'dibayar' silakan ke Analisis Laba -",
  "title3920": "Laporan bulanan keuntungan",
  "title3921": "Untuk modifikasi di halaman",
  "title3922": "Simbol mata uang",
  "title3923": "Kurs resmi",
  "title3924": "Nilai tukar diambil dari kurs resmi, 0 setiap hari",
  "title3925": "Perbarui kurs",
  "title3926": "Jenis nilai tukar eksekusi",
  "title3927": "Kurs tetap: Tarif tetap pengguna",
  "title3928": "Diskon kurs: Nilai tukar yang dihitung dengan mengalikan kurs resmi dengan persentase diskon pada hari tersebut",
  "title3929": "Misalnya, Dolar Amerika Serikat",
  "title3930": "Nilai tukar adalah 7",
  "title3931": "Dan Anda telah mengatur persentase diskon sebesar 98",
  "title3932": "Maka tingkat pelaksanaan sebenarnya",
  "title3933": "Persentase diskon nilai tukar",
  "title3934": "Kurs tetap",
  "title3935": "Tingkat Eksekusi",
  "title3936": "Kurs Sejarah",
  "title3937": "Log",
  "title3938": "Tambahkan Template",
  "title3939": "Masukkan Nama Template",
  "title3940": "Nama Template",
  "title3941": "Pilih setidaknya satu template",
  "title3942": "Hapus template yang dipilih",
  "title3943": "Hapus?",
  "title3944": "请输入登录地址",
  "title3945": "请输入用户名称",
  "title3946": "访问编号",
  "title3947": "日志类型",
  "title3948": "登录日期",
  "title3949": "是否确认导出所有操作日志数据项",
  "title3950": "菜单路由",
  "title3951": "请输入菜单名称",
  "title3952": "菜单名称",
  "title3953": "分组名称",
  "title3954": "图标",
  "title3955": "权限标识",
  "title3956": "组件路径",
  "title3957": "选择上级菜单",
  "title3958": "菜单类型",
  "title3959": "菜单图标",
  "title3960": "点击选择图标",
  "title3961": "请输入分组名称",
  "title3962": "路由地址",
  "title3963": "访问的路由地址，如：",
  "title3964": "如需外网地址时，则以",
  "title3965": "请输入路由地址",
  "title3966": "请输入组件路径",
  "title3967": "方法上的权限字符，如：",
  "title3968": "权限字符",
  "title3969": "请权限标识",
  "title3970": "选择停用时，路由将不会出现在侧边栏，也不能被访问",
  "title3971": "显示状态",
  "title3972": "选择隐藏时，路由将不会出现在侧边栏，但仍然可以访问",
  "title3973": "显示",
  "title3974": "隐藏",
  "title3975": "选择缓存时，则会被",
  "title3976": "缓存，需要匹配组件的",
  "title3977": "和路由地址保持一致",
  "title3978": "是否缓存",
  "title3979": "不缓存",
  "title3980": "菜单名称不能为空",
  "title3981": "菜单顺序不能为空",
  "title3982": "路由地址不能为空",
  "title3983": "主类目",
  "title3984": "添加菜单",
  "title3985": "修改菜单",
  "title3986": "前端必须以",
  "title3987": "前端不能以",
  "title3988": "公告标题",
  "title3989": "请输入公告标题",
  "title3990": "操作人员",
  "title3991": "请输入操作人员",
  "title3992": "公告类型",
  "title3993": "内容类型",
  "title3994": "创建者",
  "title3995": "内容",
  "title3996": "公告标题不能为空",
  "title3997": "公告类型不能为空",
  "title3998": "添加公告",
  "title3999": "修改公告",
  "title4000": "是否确认删除公告编号为",
  "title4001": "系统公告",
  "title4002": "平台公告",
  "title4003": "东南亚资讯",
  "title4004": "全部已读",
  "title4005": "全部标记已读成功",
  "title4006": "发布时间：",
  "title4007": "确认支付",
  "title4008": "确认该订单完成支付？",
  "title4009": "岗位编码",
  "title4010": "请输入岗位编码",
  "title4011": "岗位名称",
  "title4012": "请输入岗位名称",
  "title4013": "岗位状态",
  "title4014": "岗位编号",
  "title4015": "岗位排序",
  "title4016": "请输入编码名称",
  "title4017": "岗位顺序",
  "title4018": "岗位名称不能为空",
  "title4019": "岗位编码不能为空",
  "title4020": "岗位顺序不能为空",
  "title4021": "添加岗位",
  "title4022": "修改岗位",
  "title4023": "是否确认删除岗位编号为",
  "title4024": "是否确认导出所有岗位数据项",
  "title4025": "岗位数据",
  "title4026": "操作模块",
  "title4027": "请输入操作模块",
  "title4028": "操作名",
  "title4029": "请输入操作名",
  "title4030": "操作类型",
  "title4031": "操作状态",
  "title4032": "操作人",
  "title4033": "操作日期",
  "title4034": "操作信息：",
  "title4035": "方法名：",
  "title4036": "方法参数：",
  "title4037": "操作日志",
  "title4038": "请输入地区父",
  "title4039": "地区名称",
  "title4040": "请输入地区名称",
  "title4041": "地区类型",
  "title4042": "请选择地区类型",
  "title4043": "地区父",
  "title4044": "地区名称不能为空",
  "title4045": "地区类型不能为空",
  "title4046": "添加地区",
  "title4047": "修改地区",
  "title4048": "是否确认删除地区编号为",
  "title4049": "是否确认导出所有地区数据项",
  "title4050": "角色编号",
  "title4051": "Jenis peran",
  "title4052": "Mengubah peran",
  "title4053": "Karena",
  "title4054": "Komisi platform hanya diselesaikan saat pesanan selesai, di sini Anda perlu menetapkan tingkat komisi perkiraan untuk perkiraan keuntungan pada pesanan yang belum dibayar",
  "title4055": "Rumus perhitungan komisi: (Jumlah produk - Diskon platform - Diskon penjual)",
  "title4056": "Persentase komisi toko",
  "title4057": "Catatan: Setiap kali tingkat komisi diubah, akan dihitung ulang ketika ada pesanan tidak dibayar yang disinkronkan; tingkat komisi default adalah 3",
  "title4058": "Tingkat komisi platform",
  "title4059": "敏感词",
  "title4060": "请输入敏感词",
  "title4061": "请选择标签",
  "title4062": "请选择启用状态",
  "title4063": "请选择文章标签",
  "title4064": "检测敏感词",
  "title4065": "文本",
  "title4066": "请输入测试文本",
  "title4067": "检",
  "title4068": "测",
  "title4069": "敏感词不能为空",
  "title4070": "标签不能为空",
  "title4071": "测试文本不能为空",
  "title4072": "添加敏感词",
  "title4073": "检测文本是否含有敏感词",
  "title4074": "修改敏感词",
  "title4075": "不包含敏感词！",
  "title4076": "包含敏感词：",
  "title4077": "是否确认删除敏感词编号为",
  "title4078": "是否确认导出所有敏感词数据项",
  "title4079": "短信配置",
  "title4080": "短信签名",
  "title4081": "请输入短信签名",
  "title4082": "启用状态",
  "title4083": "短信",
  "title4084": "的账号",
  "title4085": "的密钥",
  "title4086": "短信发送回调",
  "title4087": "请选择渠道编码",
  "title4088": "请输入短信",
  "title4089": "请输入短信发送回调",
  "title4090": "短信签名不能为空",
  "title4091": "渠道编码不能为空",
  "title4092": "启用状态不能为空",
  "title4093": "的账号不能为空",
  "title4094": "添加短信渠道",
  "title4095": "修改短信渠道",
  "title4096": "是否确认删除短信渠道编号为",
  "title4097": "短信渠道",
  "title4098": "请选择短信渠道",
  "title4099": "模板编号",
  "title4100": "请输入模板编号",
  "title4101": "发送状态",
  "title4102": "请选择发送状态",
  "title4103": "发送时间",
  "title4104": "接收状态",
  "title4105": "请选择接收状态",
  "title4106": "接收时间",
  "title4107": "短信内容",
  "title4108": "短信类型",
  "title4109": "短信日志详细",
  "title4110": "短信渠道：",
  "title4111": "短信模板：",
  "title4112": "的模板编号：",
  "title4113": "短信内容：",
  "title4114": "短信参数：",
  "title4115": "发送状态：",
  "title4116": "发送时间：",
  "title4117": "发送结果：",
  "title4118": "短信编号：",
  "title4119": "请求编号：",
  "title4120": "接收状态：",
  "title4121": "接收时间：",
  "title4122": "接收结果：",
  "title4123": "是否确认导出所有短信日志数据项",
  "title4124": "短信日志",
  "title4125": "找不到签名：",
  "title4126": "请输入标签内容",
  "title4127": "标签内容",
  "title4128": "颜色",
  "title4129": "标签排序",
  "title4130": "请输入标签排序",
  "title4131": "请输入颜色",
  "title4132": "标签排序不能为空",
  "title4133": "标签内容不能为空",
  "title4134": "添加标签",
  "title4135": "修改标签",
  "title4136": "是否确认删除标签",
  "title4137": "是否确认导出所有标签数据项",
  "title4138": "多租户",
  "title4139": "租户名",
  "title4140": "请输入租户名",
  "title4141": "联系手机",
  "title4142": "请输入联系手机",
  "title4143": "租户状态",
  "title4144": "请选择租户状态",
  "title4145": "租户编号",
  "title4146": "租户套餐",
  "title4147": "系统租户",
  "title4148": "子账号数",
  "title4149": "过期时间",
  "title4150": "绑定域名",
  "title4151": "请选择租户套餐",
  "title4152": "用户密码",
  "title4153": "请输入用户密码",
  "title4154": "默认入口",
  "title4155": "请选择默认入口",
  "title4156": "请选择过期时间",
  "title4157": "请输入绑定域名",
  "title4158": "租户名不能为空",
  "title4159": "租户套餐不能为空",
  "title4160": "联系人不能为空",
  "title4161": "租户状态不能为空",
  "title4162": "过期时间不能为空",
  "title4163": "绑定域名不能为空",
  "title4164": "添加租户",
  "title4165": "修改租户",
  "title4166": "是否确认删除租户编号为",
  "title4167": "是否确认导出所有租户数据项",
  "title4168": "未知套餐",
  "title4169": "套餐名",
  "title4170": "请输入套餐名",
  "title4171": "套餐编号",
  "title4172": "受限页面",
  "title4173": "勾选的菜单将会被限制，在这些页面将会有弹框弹出显示",
  "title4174": "套餐名不能为空",
  "title4175": "关联的菜单编号不能为空",
  "title4176": "添加租户套餐",
  "title4177": "修改租户套餐",
  "title4178": "是否确认删除租户套餐编号为",
  "title4179": "请选择短信类型",
  "title4180": "模板编码",
  "title4181": "请输入模板编码",
  "title4182": "的模板编号",
  "title4183": "模板内容",
  "title4184": "短信渠道编号",
  "title4185": "请选择短信渠道编号",
  "title4186": "请输入模板内容",
  "title4187": "测试发送短信",
  "title4188": "短信类型不能为空",
  "title4189": "模板编码不能为空",
  "title4190": "模板名称不能为空",
  "title4191": "模板内容不能为空",
  "title4192": "的模板编号不能为空",
  "title4193": "短信渠道编号不能为空",
  "title4194": "手机不能为空",
  "title4195": "添加短信模板",
  "title4196": "修改短信模板",
  "title4197": "是否确认删除短信模板编号为",
  "title4198": "是否确认导出所有短信模板数据项",
  "title4199": "短信模板",
  "title4200": "提交发送成功！发送结果，见发送日志编号：",
  "title4201": "用户体系",
  "title4202": "三方登陆",
  "title4203": "部门",
  "title4204": "归属部门",
  "title4205": "请选择归属部门",
  "title4206": "用户性别",
  "title4207": "岗位",
  "title4208": "用户名称不能为空",
  "title4209": "用户昵称不能为空",
  "title4210": "用户密码不能为空",
  "title4211": "的新密码",
  "title4212": "报表设计器",
  "title4213": "Selamat datang untuk menggunakan",
  "title4214": "Dialog bebas",
  "title4215": "Berbasis",
  "title4216": "Model cerdas",
  "title4217": "Membuat komunikasi Anda lebih pintar, efisien, dan nyaman!",
  "title4218": "Bagaimana cara bertanya dengan lebih efektif?",
  "title4219": "Anda bisa mencoba bertanya seperti ini:",
  "title4220": "Simpan",
  "title4221": "Semua konten yang dihasilkan oleh layanan dibuat oleh model AI, dan akurasi serta kelengkapan konten yang dihasilkan tidak dapat dijamin, dan tidak mewakili sikap atau sudut pandang kami",
  "title4222": "Tetapkan peran",
  "title4223": "Berikan identitas profesional",
  "title4224": "Misalnya: Anda adalah ahli pemasaran berpengalaman",
  "title4225": "Deskripsi pertanyaan",
  "title4226": "Masukkan pertanyaan Anda beserta informasi latar belakang, persyaratan spesifik, tujuan tugas, dll.;",
  "title4227": "Format output",
  "title4228": "Informasi",
  "title4229": "Dalam format apa Anda membutuhkannya dan mengeluarkan kontennya?",
  "title4230": "Buat basis pengetahuan pintar",
  "title4231": "Edit basis pengetahuan pintar",
  "title4232": "Nama basis pengetahuan cerdas",
  "title4233": "Masukkan nama basis pengetahuan",
  "title4234": "Pilih platform",
  "title4235": "Pendahuluan basis pengetahuan",
  "title4236": "Masukkan pengantar",
  "title4237": "Buat templat",
  "title4238": "Simpan templat",
  "title4239": "Sudah digunakan",
  "title4240": "Nama dokumen",
  "title4241": "Ukuran",
  "title4242": "Silakan pilih file",
  "title4243": "Ukuran file yang diunggah tidak boleh melebihi",
  "title4244": "Masukkan pengantar basis pengetahuan",
  "title4245": "Daftar kurs",
  "title4246": "Grafik tren kurs",
  "title4247": "Kurs resmi bulanan",
  "title4248": "Rata-rata pembayaran bulanan",
  "title4249": "Selesai seketika",
  "title4250": "Masukkan kebutuhan salinan Anda di halaman 'Masukkan Konten' untuk segera menghasilkan salinan berkualitas eksklusif",
  "title4251": "Perbarui keuntungan",
  "title4252": "Setelan kurs pembayaran",
  "title4253": "Tambahkan biaya khusus",
  "title4254": "Ekspor laporan",
  "title4255": "Pernyataan rekonsiliasi pembayaran",
  "title4256": "Alih mata uang hanya didukung ketika memilih satu toko, bukan beberapa toko",
  "title4257": "Jumlah pesanan pembayaran",
  "title4258": "Jumlah total pembayaran pesanan",
  "title4259": "Pengeluaran produk",
  "title4260": "Jumlah pembayaran evaluasi - jumlah pembayaran kembali evaluasi",
  "title4261": "Biaya khusus",
  "title4262": "Pelajari Lebih Lanjut",
  "title4263": "Tabel ringkasan pendapatan pesanan",
  "title4264": "Pernyataan akun platform",
  "title4265": "Biaya",
  "title4266": "Jumlah pembayaran pembeli",
  "title4267": "Total biaya",
  "title4269": "Diskon penjual",
  "title4270": "Jumlah pembayaran pesanan yang efektif",
  "title4271": "Jumlah penyesuaian lintas bulan",
  "title4272": "Jumlah pesanan ditangguhkan",
  "title4273": "Jumlah pesanan evaluasi",
  "title4274": "Jumlah pembayaran pesanan ditangguhkan",
  "title4275": "Jumlah pembayaran pesanan evaluasi",
  "title4276": "Jumlah pembayaran evaluasi",
  "title4277": "Pendapatan - Biaya produk - Biaya kustom - Kerugian evaluasi",
  "title4278": "Pendapatan platform",
  "title4279": "Biaya Marketplace",
  "title4280": "Komisi, biaya layanan, biaya transaksi",
  "title4281": "Biaya lainnya di dalam toko",
  "title4282": "Biaya evaluasi",
  "title4283": "Jumlah pesanan evaluasi",
  "title4284": "Proyek",
  "title4285": "Pemasukan dan pengeluaran kustom",
  "title4286": "Rincian biaya",
  "title4287": "Pengeluaran sebagai persentase dari total pengeluaran",
  "title4288": "Pengeluaran sebagai persentase dari penjualan efektif",
  "title4289": "Rincian biaya promosi",
  "title4290": "Pemasukan dan pengeluaran lain-lain",
  "title4291": "Biaya produk online",
  "title4292": "Dana Pengembalian",
  "title4293": "Pilih data yang akan diekspor dengan menyaring kriteria, semua data yang dipilih akan diekspor saat membuat laporan",
  "title4294": "Ringkasan Data",
  "title4295": "Total jumlah pengunjung unik yang mengunjungi toko dan halaman detail produk Anda selama periode yang dipilih",
  "title4296": "Total jumlah pesanan yang dikonfirmasi",
  "title4297": "Total jumlah nilai pesanan yang dikonfirmasi",
  "title4298": "Volume penjualan evaluasi",
  "title4299": "Jumlah evaluasi",
  "title4300": "Jumlah penjualan per pesanan",
  "title4301": "Tingkat konversi toko",
  "title4302": "Menyaring ulasan + ditunda + dibatalkan + penjualan belum dibayar",
  "title4303": "Biaya iklan pencarian",
  "title4304": "Konversi iklan pencarian",
  "title4305": "Jumlah penjualan iklan pencarian",
  "title4306": "Tingkat klik iklan pencarian",
  "title4307": "Tingkat konversi iklan pencarian",
  "title4308": "Iklan pencarian",
  "title4309": "Biaya iklan afiliasi",
  "title4310": "Konversi iklan afiliasi",
  "title4311": "Jumlah penjualan iklan afiliasi",
  "title4312": "Tingkat klik iklan afiliasi",
  "title4313": "Tingkat konversi iklan afiliasi",
  "title4314": "Biaya iklan promosi",
  "title4315": "Konversi iklan promosi",
  "title4316": "Jumlah penjualan iklan promosi",
  "title4317": "Tingkat klik iklan promosi",
  "title4318": "Tingkat konversi iklan promosi",
  "title4319": "Iklan promosi",
  "title4320": "Biaya pengiriman perkiraan",
  "title4321": "Output",
  "title4322": "Penjualan bersih - Biaya iklan pencarian - Biaya iklan afiliasi - Biaya iklan promosi - Biaya produk - Kerugian penilaian - Biaya pengiriman perkiraan - Biaya platform - Jumlah pengembalian",
  "title4324": "Kuantitas",
  "title4325": "Jumlah total item terkonfirmasi",
  "title4326": "Jumlah Produk yang Terjual",
  "title4327": "Jumlah pesanan aktual",
  "title4328": "Jumlah total pesanan - Jumlah pesanan evaluasi",
  "title4329": "Rata-rata nilai pesanan",
  "title4330": "Jumlah pembeli terkonfirmasi",
  "title4331": "Ubah Password",
  "title4332": "Kata sandi lama",
  "title4333": "Masukkan kata sandi lama",
  "title4334": "Mengubah nomor telepon memerlukan verifikasi nomor telepon asli, dan kode verifikasi SMS akan dikirim ke ponsel Anda",
  "title4335": "Pada ponsel",
  "title4336": "结束时间：",
  "title4337": "请假类型：",
  "title4338": "原因：",
  "title4339": "参数，无法查看",
  "title4340": "请假信息",
  "title4341": "请假类型",
  "title4342": "请选择请假类型",
  "title4343": "申请时间",
  "title4344": "发起请假",
  "title4345": "申请编号",
  "title4346": "开始时间",
  "title4347": "取消请假",
  "title4348": "审批进度",
  "title4349": "选择开始时间",
  "title4350": "选择结束时间",
  "title4351": "提",
  "title4352": "交",
  "title4353": "开始时间不能为空",
  "title4354": "结束时间不能为空",
  "title4355": "请假类型不能为空",
  "title4356": "请假原因不能为空",
  "title4357": "发起成功",
  "title4358": "Klik iklan",
  "title4359": "Sekarang didukung",
  "title4360": "Analisis data iklan sekarang didukung. Anda dapat dengan mudah mendapatkan data yang relevan melalui plugin. Silakan lihat panduan pengguna untuk petunjuk spesifik!",
  "title4361": "Menurut kecenderungan",
  "title4362": "Menurut nilai",
  "title4363": "Klik organik",
  "title4364": "Tampilan iklan",
  "title4365": "Persentase penjualan iklan",
  "title4366": "Tingkat konversi iklan",
  "title4367": "Pilih hingga dua indikator",
  "title4368": "Edit biaya produk",
  "title4369": "Tambahkan biaya baru",
  "title4370": "Tambahkan biaya historis",
  "title4371": "Tambah biaya historis",
  "title4372": "Mengedit biaya produk adalah memperbarui biaya produk saat ini, tanpa memengaruhi pesanan masa lalu. Pesanan di masa depan akan dihitung menggunakan biaya terbaru.",
  "title4373": "Jumlah biaya",
  "title4374": "Setelah memodifikasi biaya produk untuk tanggal historis tertentu, sistem akan secara otomatis memperbarui biaya produk dalam semua pesanan pada tanggal tersebut menjadi nilai baru dan menghitung ulang data laporan yang relevan.",
  "title4375": "Instruksi pengaturan biaya",
  "title4376": "Metode perhitungan biaya untuk satu produk",
  "title4377": "Biaya dari satu produk",
  "title4378": "Saldo historis barang",
  "title4379": "Biaya pembelian unit historis",
  "title4380": "Total biaya pembelian dari beberapa batch pada tanggal yang bersangkutan",
  "title4381": "Total barang yang dibeli dalam beberapa batch",
  "title4382": "Contoh ilustrasi",
  "title4383": "Pada akhir bulan, ada sisa penjualan",
  "title4384": "barang, harga pembelian",
  "title4385": "seharga yuan",
  "title4386": "Dipenuhi pada bulan",
  "title4387": "Perhitungan harga beli untuk Juni adalah sebagai berikut",
  "title4388": "Harga pembelian",
  "title4389": "Jika masih ada 10 barang tersisa setelah penjualan pada bulan Juni, dengan harga pembelian 17 yuan, harga pembelian untuk Juli, jika tidak menggunakan fungsi pembelian, akan mengacu pada harga pembelian terakhir, yaitu 17 yuan",
  "title4390": "Ada konflik tanggal di gudang yang sama, silakan pilih lagi",
  "title4391": "Konflik tanggal yang sama di gudang, harap pilih lagi",
  "title4392": "Edit biaya kustom",
  "title4393": "Tambahkan biaya kustom",
  "title4394": "Harap pilih setidaknya satu data",
  "title4395": "Mengubah jenis",
  "title4396": "Ubah konten",
  "title4397": "Ke",
  "title4398": "Biaya produk:",
  "title4399": "Akun operasional",
  "title4400": "Catatan modifikasi biaya produk",
  "title4401": "Jumlah biaya default",
  "title4402": "Modifikasi jumlah biaya",
  "title4403": "Catatan modifikasi biaya historis",
  "title4404": "Rentang tanggal biaya",
  "title4405": "Jumlah biaya historis",
  "title4406": "Unggah",
  "title4407": "Harap siapkan data untuk impor dalam format templat data",
  "title4408": "Konflik tanggal yang sama di gudang, harap pilih lagi",
  "title4409": "Edit biaya kustom",
  "title4410": "Tambahkan biaya kustom",
  "title4411": "Harap pilih setidaknya satu data",
  "title4412": "Mengubah jenis",
  "title4413": "Ubah konten",
  "title4414": "Ke",
  "title4415": "Biaya produk:",
  "title4416": "Akun operasional",
  "title4417": "Catatan modifikasi biaya produk",
  "title4418": "Jumlah biaya default",
  "title4419": "Dana Pengembalian",
  "title4420": "Modifikasi jumlah biaya",
  "title4421": "Catatan modifikasi biaya historis",
  "title4422": "Rentang tanggal biaya",
  "title4423": "Jumlah biaya historis",
  "title4424": "Unggah",
  "title4425": "Harap siapkan data untuk impor dalam format templat data",
  "title4426": "Gambar telah disimpan",
  "title4427": "potong",
  "title4428": "Tingkatkan kapasitas",
  "title4429": "Pemanfaatan kapasitas",
  "title4430": "Telah digunakan",
  "title4431": "Total",
  "title4432": "Sudah terkait",
  "title4433": "Analitika Produk",
  "title4434": "Total biaya barang untuk pesanan yang valid",
  "title4435": "Jumlah pesanan pengembalian",
  "title4436": "Total biaya iklan",
  "title4437": "Peringkat volume penjualan yang valid",
  "title4438": "Tren Penjualan",
  "title4439": "Modifikasi aktivitas",
  "title4440": "Rentang volume penjualan dari",
  "title4441": "ke",
  "title4442": "Didefinisikan sebagai",
  "title4443": "Dan jumlah persediaan berada di",
  "title4444": "Harap atur rentang interval penjualan",
  "title4445": "Harap masukkan inventaris dari kecil ke besar",
  "title4446": "Harap masukkan rentang dari kecil ke besar",
  "title4447": "Pilih gambar online (",
  "title4448": "Masukkan alamat gambar online, satu alamat per baris",
  "title4449": "Informasi pesaing",
  "title4450": "Tautan pesaing",
  "title4451": "Alamat verifikasi",
  "title4452": "Judul pesaing",
  "title4453": "Catatan produk",
  "title4454": "Verifikasi gagal",
  "title4455": "Templat impor posisi rak",
  "title4456": "Pilih gambar lokal",
  "title4457": "Pilih gambar online",
  "title4458": "Tidak ada gambar tersedia",
  "title4459": "Harap pilih file gambar lokal atau pilih gambar online untuk ditambahkan, Anda dapat mengunggah maksimal 8 gambar",
  "title4460": "Format gambar:",
  "title4461": "Ukuran gambar tidak boleh melebihi",
  "title4462": "Ukuran gambar tidak boleh lebih kecil dari",
  "title4463": "Informasi Gudang",
  "title4464": "Jumlah persediaan",
  "title4465": "Frekuensi pengisian kembali",
  "title4466": "Informasi harga",
  "title4467": "Harga pembelian",
  "title4468": "Pemasok Informasi",
  "title4469": "Pilih penyedia",
  "title4470": "Tautan pemasok",
  "title4471": "Masalah purna jual",
  "title4472": "Nomor Retur",
  "title4473": "Alasan Pembatalan",
  "title4474": "Metode penanganan",
  "title4475": "Pengelolaan Retur",
  "title4476": "Pengolahan pengembalian",
  "title4477": "Deskripsi operasi",
  "title4478": "Waktu pembelian",
  "title4479": "Jumlah pembelian",
  "title4480": "Waktu Pemesanan",
  "title4481": "Waktu pengiriman:",
  "title4482": "Jumlah pergerakan persediaan",
  "title4483": "Alasan pergerakan persediaan",
  "title4484": "Log perubahan",
  "title4485": "Batas pilihan saat ini",
  "title4486": "file, dipilih kali ini",
  "title4487": "file secara total terpilih",
  "title4488": "file",
  "title4489": "Dipilih",
  "title4490": "Pilih posisi rak",
  "title4491": "Tambahkan ke rencana pembelian",
  "title4492": "Setelah menambahkan rencana pembelian, silakan pergi ke 'Pembelian - '",
  "title4493": "halaman untuk melihat rencana pembelian.",
  "title4494": "Jumlah cetak",
  "title4495": "Barcode",
  "title4496": "Kode QR",
  "title4497": "Kertas label",
  "title4498": "Satu kolom",
  "title4499": "Dua kolom",
  "title4500": "Tiga kolom",
  "title4501": "Cetak",
  "title4502": "Cetak",
  "title4503": "Silakan pilih kertas label",
  "title4504": "Silakan masukkan jumlah",
  "title4505": "Yang terakhir tidak bisa dihapus!",
  "title4506": "Pengaturan grup",
  "title4507": "Masukkan kata kunci untuk menyaring",
  "title4508": "Hapus grup",
  "title4509": "Juga hapus subgrupnya, dan sesuaikan pengelompokan data yang diterapkan menjadi tidak dikelompokkan.",
  "title4510": "Data saran pengisian ulang akan menghitung data yang disarankan berdasarkan durasi pengisian ulang, harap isi sesuai dengan penggunaan aktual",
  "title4511": "Tambahkan penjualan harian",
  "title4512": "Aturan pengisian ulang",
  "title4513": "Keluarkan data yang kehabisan stok dari penjualan historis",
  "title4514": "Aturan penjualan harian",
  "title4515": "Penjualan dinamis",
  "title4516": "Penjualan tetap",
  "title4517": "Penjualan persentase",
  "title4518": "Rentang waktu",
  "title4519": "Semua waktu",
  "title4520": "Penjualan Harian",
  "title4521": "Hitung",
  "title4522": "Rata-rata harian",
  "title4523": "Waktu Pemesanan",
  "title4524": "Waktu permintaan pembeli",
  "title4525": "Alasan pengembalian barang",
  "title4526": " Status Pengembalian ",
  "title4527": "Belum selesai",
  "title4528": "Selesai",
  "title4529": "Belum Menerima Barang",
  "title4530": "Barang Hilang",
  "title4531": "Barang Salah Diterima",
  "title4532": "Barang Rusak",
  "title4533": "Barang Mengalami Cacat",
  "title4534": "Barang Kedaluwarsa",
  "title4535": "Barang Palsu",
  "title4536": "Barang tidak sesuai deskripsi",
  "title4537": "Barang Tidak Cocok",
  "title4538": "Berubah Pikiran",
  "title4539": "Paket Mencurigakan",
  "title4540": "Kemasan Rusak",
  "title4541": "Permintaan Pengembalian",
  "title4542": "Pesanan Duplikat",
  "title4543": "Pesanan Salah",
  "title4544": "Tidak Ingin Lagi",
  "title4545": "Pembatalan dari Platform",
  "title4546": "Biaya pengiriman tinggi",
  "title4547": "Tidak Ada Metode Pembayaran yang Diinginkan",
  "title4548": "Ubah cara pembayaran",
  "title4549": "Harga lebih murah ditemukan",
  "title4550": "Harga salah",
  "title4551": "Pembeli tidak membayar",
  "title4552": "Diskon tidak sesuai harapan",
  "title4553": "Ubah diskon",
  "title4554": "Informasi pengiriman salah",
  "title4555": "Alamat pembeli tidak dapat dijangkau",
  "title4556": "Barang tidak sampai tepat waktu",
  "title4557": "Barang tidak dikirim tepat waktu",
  "title4558": "Barang memiliki masalah kualitas",
  "title4559": "Aksesoris atau hadiah hilang",
  "title4560": "Barang tidak sesuai harapan",
  "title4561": "Berikut adalah beberapa alasan pengembalian umum",
  "title4562": "Jumlah retur",
  "title4563": "Jumlah proporsi",
  "title4564": "Jumlah proporsi alasan pengembalian",
  "title4565": "Per hari",
  "title4566": "Siklus penyelesaian",
  "title4567": "Status penyelesaian",
  "title4568": "Catatan pembayaran",
  "title4569": "Jumlah transfer",
  "title4570": "Tanggal transfer",
  "title4571": "Status transfer",
  "title4572": "Nomor ekor bank",
  "title4573": "Kode transfer bank",
  "title4574": "Jumlah yang belum diselesaikan",
  "title4575": "Jumlah yang diselesaikan",
  "title4576": "Jumlah transfer gagal",
  "title4577": "Rasio biaya",
  "title4578": "Pilih banyak wilayah",
  "title4579": "Analisis perbandingan",
  "title4580": "Pesanan ekspor",
  "title4581": "Yuan China (CNY)",
  "title4582": "Mata uang lokal",
  "title4583": "Tambahkan templat kustom",
  "title4584": "Sedang diekspor, harap tunggu!",
  "title4585": "Memproses data ekspor, jangan tutup halaman saat ini. Ini akan diunduh secara otomatis setelah selesai. Menutup halaman akan membatalkan pengunduhan.",
  "title4586": "Pilih template",
  "title4587": "Template standar",
  "title4588": "Pilih label",
  "title4589": "Pesanan, hanya mengubah toko yang dipilih dalam pesanan",
  "title4590": "Hubungan pencocokan",
  "title4591": "Produk yang cocok",
  "title4592": "Pilih platform",
  "title4593": "Utama",
  "title4594": "Membagi",
  "title4595": "Status pesanan",
  "title4596": "Informasi dasar",
  "title4597": "Jumlah produk",
  "title4598": "Spesifikasi",
  "title4599": "Nama panggilan",
  "title4600": "Alamat 1",
  "title4601": "Ponsel",
  "title4602": "Kota",
  "title4603": "Alamat 2",
  "title4604": "Telepon faktur",
  "title4605": "Nomor rumah",
  "title4606": "Alamat 3",
  "title4607": "Informasi Logistik",
  "title4608": "Jasa Kirim yang Dipilih Pembeli",
  "title4609": "Metode pengambilan",
  "title4610": "Catatan saya",
  "title4611": "Informasi keuangan",
  "title4612": "Mata uang saat ini",
  "title4613": "Pertukaran",
  "title4614": "Kurs tetap",
  "title4615": "Perhitungan uji",
  "title4616": "Pendapatan yang diestimasikan",
  "title4617": "Biaya yang diestimasi",
  "title4618": "Pengeluaran pengiriman (uji)",
  "title4619": "Total pengeluaran",
  "title4621": "Total keuntungan",
  "title4622": "Pendapatan",
  "title4623": "Tidak ada kemasan pesanan",
  "title4624": "Paket",
  "title4625": "Tidak ada informasi paket",
  "title4626": "Tidak ada informasi",
  "title4627": "Pisahkan pesanan",
  "title4628": "Batalkan pesanan terpisah",
  "title4629": "paket",
  "title4630": "produk",
  "title4631": "Gudang:",
  "title4632": "Pisahkan paket",
  "title4633": "Paket utama",
  "title4634": "Pindahkan ke",
  "title4635": "Nomor paket",
  "title4636": "Harga satuan barang",
  "title4637": "Pilih setidaknya satu produk",
  "title4638": "Paket utama harus memiliki setidaknya satu produk",
  "title4639": "Nama merek",
  "title4640": "Silakan masukkan nama merek",
  "title4641": "Nomor merek",
  "title4642": "Gambar merek",
  "title4643": "Gambar kategori",
  "title4644": "Urutan merek",
  "title4645": "Deskripsi merek",
  "title4646": "Silakan masukkan pengurutan merek",
  "title4647": "Nama merek tidak boleh kosong",
  "title4648": "Gambar merek tidak boleh kosong",
  "title4649": "Urutan merek tidak boleh kosong",
  "title4650": "Tambah merek",
  "title4651": "Ubah merek",
  "title4652": "Apakah Anda yakin untuk menghapus nomor merek",
  "title4653": "Thumbnail",
  "title4654": "Gambar mini",
  "title4655": "Tautan pengalihan",
  "title4656": "Harap masukkan tautan pengalihan",
  "title4657": "Silakan masukkan pengurutan",
  "title4658": "Judul tidak boleh kosong",
  "title4659": "Alamat gambar tidak boleh kosong",
  "title4660": "Alamat pengalihan tidak boleh kosong",
  "title4661": "Pengurutan tidak boleh kosong",
  "title4662": "Pengurutan kategori",
  "title4663": "Kategori induk",
  "title4664": "Merekomendasikan",
  "title4665": "Resolusi gambar",
  "title4666": "Deskripsi kategori",
  "title4667": "Silakan masukkan deskripsi kategori",
  "title4668": "Silakan pilih kategori induk",
  "title4669": "Nama kategori tidak boleh kosong",
  "title4670": "Gambar kategori tidak boleh kosong",
  "title4671": "Pengurutan kategori tidak boleh kosong",
  "title4672": "Kategori level atas",
  "title4673": "Tambah kategori produk",
  "title4674": "Ubah kategori produk",
  "title4675": "Apakah Anda yakin ingin menghapus nomor kategori produk",
  "title4676": "Nama spesifikasi",
  "title4677": "Silakan masukkan nama spesifikasi",
  "title4678": "Nilai atribut",
  "title4679": "Nilai atribut tidak boleh kosong",
  "title4680": "Tambah spesifikasi",
  "title4681": "Ubah spesifikasi",
  "title4682": "Apakah Anda yakin ingin menghapus nama spesifikasi",
  "title4683": "Apakah Anda yakin ingin mengekspor semua nama spesifikasi",
  "title4684": "SKU商品编码；Nama produk商品名称",
  "title4685": "Penjualan produk, jumlah produk",
  "title4686": "Harap pilih status rak",
  "title4687": "Antarmuka referensi;",
  "title4688": "Bidang: Informasi produk, harga, persediaan, penjualan, pengurutan, waktu pembuatan, status, operasi;",
  "title4689": "Semua, dalam penjualan, di gudang, dalam peringatan",
  "title4690": "Alamat gambar utama produk",
  "title4691": "Bidang pengurutan",
  "title4692": "Klik",
  "title4693": "Rentang harga",
  "title4694": "Ubah produk",
  "title4695": "Pengaturan dasar",
  "title4696": "Titik penjualan produk",
  "title4697": "Silakan masukkan titik penjualan produk",
  "title4698": "Gambar utama produk",
  "title4699": "Merek produk",
  "title4700": "Silakan pilih merek produk",
  "title4701": "Kategori SKU Gudang",
  "title4702": "Daftar sekarang",
  "title4703": "Masukkan gudang",
  "title4704": "Harga dan stok",
  "title4705": "Aktifkan variasi",
  "title4706": "Varian tunggal",
  "title4707": "Varian ganda",
  "title4708": "Variasi:",
  "title4709": "Tambahkan item variasi",
  "title4710": "Detail variasi",
  "title4711": "Gambar variasi",
  "title4712": "Harga pasar",
  "title4713": "Harga jual",
  "title4714": "Harga biaya",
  "title4715": "Stok peringatan",
  "title4716": "Kode batang",
  "title4717": "Menonaktifkan",
  "title4718": "Penjualan virtual",
  "title4719": "Silakan masukkan penjualan virtual",
  "title4720": "Detail produk",
  "title4721": "Pengaturan lanjutan",
  "title4722": "Silakan masukkan bidang pengurutan",
  "title4723": "Tampilkan stok",
  "title4724": "Konfirmasi",
  "title4725": "Status produk tidak boleh kosong",
  "title4726": "Alamat gambar carousel produk tidak boleh kosong",
  "title4727": "Penjualan virtual tidak boleh kosong",
  "title4728": "渠道费率",
  "title4729": "请输入渠道费率",
  "title4730": "开放平台",
  "title4731": "请输入开放平台",
  "title4732": "渠道状态",
  "title4733": "网关地址",
  "title4734": "算法类型",
  "title4735": "公钥类型",
  "title4736": "商户私钥",
  "title4737": "请输入商户私钥",
  "title4738": "支付宝公钥字符串",
  "title4739": "请输入支付宝公钥字符串",
  "title4740": "商户公钥应用证书",
  "title4741": "请上传商户公钥应用证书",
  "title4742": "支付宝公钥证书",
  "title4743": "请上传支付宝公钥证书",
  "title4744": "根证书",
  "title4745": "请上传根证书",
  "title4746": "请输入开放平台上创建的应用的",
  "title4747": "渠道状态不能为空",
  "title4748": "请传入网关地址",
  "title4749": "请传入签名算法类型",
  "title4750": "公钥类型不能为空",
  "title4751": "请上传指定根证书",
  "title4752": "编辑支付渠道",
  "title4753": "创建支付渠道",
  "title4754": "请上传指定格式",
  "title4755": "文件大小超过",
  "title4756": "公众号",
  "title4757": "请输入公众号",
  "title4758": "商户密钥",
  "title4759": "请输入商户密钥",
  "title4760": "证书",
  "title4761": "请上传",
  "title4762": "请传入商户号",
  "title4763": "版本不能为空",
  "title4764": "密钥值",
  "title4765": "Silakan masukkan nilai tukar pelaksanaan",
  "title4766": "Fluktuasi nilai tukar pelaksanaan yang Anda atur besar, harap periksa dan kirim lagi",
  "title4767": "Simbol mata uang:",
  "title4768": "Nama mata uang:",
  "title4769": "Rincian operasi",
  "title4770": "Template kustom",
  "title4771": "Silakan pilih setidaknya satu parameter",
  "title4772": "Pengaturan kurs pembayaran hanya memengaruhi pesanan 'pembayaran diterima ' dan memengaruhi laporan keuangan dan perhitungan laba.",
  "title4773": "Setelah memodifikasi kurs, diharapkan berlaku dalam 10 hingga 15 menit. Mohon bersabar.",
  "title4774": "Pilih bulan",
  "title4775": "Kurs pembayaran",
  "title4776": "Sedang memperbarui...",
  "title4777": "Ubah kurs",
  "title4778": "Kurs internasional waktu nyata",
  "title4779": "Silakan masukkan kurs pembayaran",
  "title4780": "Fluktuasi nilai tukar pengembalian yang Anda tetapkan signifikan. Harap periksa kembali sebelum mengirimkan.",
  "title4781": "Mengedit nilai tukar",
  "title4782": "Diskon nilai tukar",
  "title4783": "Tips:",
  "title4784": "Bagaimana cara menggunakan diskon nilai tukar?",
  "title4785": "Ketika memilih diskon nilai tukar, nilai tukar akan dieksekusi sesuai dengan diskon. Ini benar-benar diterapkan di mana nilai tukar dihitung untuk pesanan dan informasi lainnya.",
  "title4786": "Bagaimana cara menghitung diskon nilai tukar?",
  "title4787": "Diskon nilai tukar biasanya dihitung dengan membagi nilai tukar pembayaran dengan nilai tukar resmi.",
  "title4788": "Misalnya, jika nilai tukar resmi adalah 7, dan nilai tukar pembayaran adalah 7,",
  "title4789": "Dan kurs pengembalian adalah 7",
  "title4790": "maka rasio diskon nilai tukar",
  "title4791": "dibulatkan menjadi empat tempat desimal, yang setara dengan 99.",
  "title4792": "Masukkan 99 dalam kotak input.",
  "title4793": "Selesai.",
  "title4794": "Silakan masukkan nilai tukar tetap",
  "title4795": "Fluktuasi nilai tukar yang Anda tetapkan signifikan. Harap periksa kembali sebelum mengirimkan.",
  "title4796": "Silakan masukkan proporsi",
  "title4797": "Mengubah nilai tukar pelaksanaan",
  "title4798": "Mengubah nilai tukar pelaksanaan historis, diperkirakan mulai berlaku dalam 10 hingga 15 menit. Harap bersabar.",
  "title4799": "Diskon nilai tukar:",
  "title4800": "Silakan pilih tanggal",
  "title4801": "Tidak dapat memilih hari ini",
  "title4802": "客户端编号",
  "title4803": "客户端密钥",
  "title4804": "应用图标",
  "title4805": "访问令牌的有效期",
  "title4806": "刷新令牌的有效期",
  "title4807": "授权类型",
  "title4808": "请输入客户端编号",
  "title4809": "请输入客户端密钥",
  "title4810": "应用描述",
  "title4811": "单位：秒",
  "title4812": "请输入授权类型",
  "title4813": "授权范围",
  "title4814": "请输入授权范围",
  "title4815": "自动授权范围",
  "title4816": "可重定向的",
  "title4817": "请输入可重定向的",
  "title4818": "权限",
  "title4819": "请输入权限",
  "title4820": "资源",
  "title4821": "请输入资源",
  "title4822": "附加信息",
  "title4823": "请输入附加信息，",
  "title4824": "格式数据",
  "title4825": "客户端编号不能为空",
  "title4826": "客户端密钥不能为空",
  "title4827": "应用图标不能为空",
  "title4828": "访问令牌的有效期不能为空",
  "title4829": "刷新令牌的有效期不能为空",
  "title4830": "授权类型不能为空",
  "title4831": "客户端",
  "title4832": "是否确认删除客户端编号为",
  "title4833": "访问令牌",
  "title4834": "刷新令牌",
  "title4835": "强退",
  "title4836": "是否确认强退令牌为",
  "title4837": "强退成功",
  "title4838": "Masukkan kata sandi lama",
  "title4839": "Password Baru",
  "title4840": "Harap isi Password",
  "title4841": "Harap konfirmasi kata sandi",
  "title4842": "Password yang diisi berbeda",
  "title4843": "Kata sandi lama tidak boleh kosong",
  "title4844": "Kata sandi baru tidak boleh kosong",
  "title4845": "Panjang di",
  "title4846": "Harap isi Password",
  "title4847": "Sidik jari",
  "title4848": "Platform sosial",
  "title4849": "Sudah terikat",
  "title4850": "Membatalkan",
  "title4851": "Tidak terikat",
  "title4852": "Mengikat",
  "title4853": "Berhasil terikat",
  "title4854": "Berhasil melepaskan",
  "title4855": "Modifikasi profil",
  "title4856": "Foto profil",
  "title4857": "Ganti foto profil",
  "title4858": "Pria",
  "title4859": "Wanita",
  "title4860": "Verifikasi seluler",
  "title4861": "Ponsel terikat Anda",
  "title4862": "Kode Verifikasi",
  "title4863": "Klik untuk mengunggah avatar",
  "title4864": "Mengedit avatar",
  "title4865": "Kesalahan format file, harap unggah tipe gambar",
  "title4866": "file dengan ekstensi tersebut.",
  "title4867": "Thu thập sản phẩm",
  "title4868": "Pengumpulan data sedang berlangsung, jangan tutup halaman saat ini. Jika halaman ditutup, operasi pengumpulan produk akan dibatalkan.",
  "title4869": "Produk sedang diambil, harap tunggu",
  "title4870": "Pengumpulan berhasil, harap periksa hasilnya dalam daftar pengumpulan",
  "title4871": "Deskripsi panjang",
  "title4872": "Deskripsi pendek",
  "title4873": "Berdasarkan pembatasan dari setiap platform mengenai jumlah gambar produk, gambar dengan prioritas lebih tinggi akan dipilih sebagai gambar produk.",
  "title4874": "Unggah lokal",
  "title4875": "Alamat online",
  "title4876": "Merah",
  "title4877": "Kuning",
  "title4878": "Biru",
  "title4879": "Halaman saat ini",
  "title4880": "Indikator Inti",
  "title4881": "Situasi inventaris",
  "title4882": "Trend biaya dan penjualan",
  "title4883": "Situasi penjualan",
  "title4884": "Rincian biaya",
  "title4885": "Situasi evaluasi",
  "title4886": "Distribusi data",
  "title4887": "Rincian biaya kustom",
  "title4888": "Tidak ada biaya tersedia",
  "title4889": "Menempati area ini",
  "title4890": "Menempati semua toko",
  "title4891": "Pengurutan indikator",
  "title4892": "Tarik indikator untuk menyortir",
  "title4893": "Total keuntungan, tidak termasuk pesanan yang dibatalkan, dievaluasi, dan ditunda",
  "title4894": "Total jumlah yang dipesan oleh pembeli, termasuk subsidi platform",
  "title4895": "Total penjualan di bawah pesanan yang efektif, tidak termasuk pesanan yang dibatalkan, dievaluasi, dan ditunda, termasuk subsidi platform",
  "title4896": "Total jumlah pesanan yang ditempatkan oleh pembeli.",
  "title4897": "Total penjualan pesanan, Termasuk pesanan dibatalkan dan pengembalian",
  "title4898": "Lihat berdasarkan grafik",
  "title4899": "Lihat berdasarkan tabel",
  "title4900": "Tampilkan maksimal 15 entri",
  "title4901": "Total biaya",
  "title4902": "Grafik gabungan",
  "title4903": "Grafik perbandingan",
  "title4904": "Ulasan positif",
  "title4905": "Ulasan Netral/Negatif",
  "title4906": "Komentar sedang dikumpulkan, harap periksa kembali nanti",
  "title4907": "Analisis komentar",
  "title4908": "Tanggal komentar",
  "title4909": "Perbandingan Biaya vs. Penjualan",
  "title4910": "Grafik tren",
  "title4911": "Penjualan rata-rata harian per hari",
  "title4912": "Total penjualan per hari",
  "title4913": "Perkiraan hari penjualan",
  "title4914": "Persediaan dalam perjalanan",
  "title4915": "Usia persediaan",
  "title4916": "Nilai total persediaan",
  "title4917": "Total persediaan tersedia",
  "title4918": "Total persediaan beku",
  "title4919": "Total dalam perjalanan",
  "title4920": "Rata-rata hari penjualan",
  "title4921": "Yang",
  "title4922": "Jumlah maksimum hari untuk grup tidak boleh kosong",
  "title4923": "Nilai harus lebih besar dari kelompok sebelumnya",
  "title4924": "Dana Pengembalian",
  "title4925": "Pembayaran WeChat",
  "title4926": "Informasi toko",
  "title4927": "Jumlah diterima yang diestimasi",
  "title4928": "Biaya penanganan yang diestimasi",
  "title5000": "Cocok",
  "title5001": "Huruf harus mengandung huruf besar",
  "title5002": "Huruf, angka, dan karakter harus mengandung setidaknya 2 jenis",
  "title5003": "Kata sandi tidak boleh sama dengan nama pengguna",
  "title5004": "Keluar",
  "title5005": "Selamat atas pendaftaran berhasil! Untuk memberikan pengalaman yang lebih baik, silakan isi informasi berikut.",
  "title5006": "Silakan pilih area penjualan dan konfirmasi mata uang utama. Setelah area penjualan dan mata uang utama akun dikonfirmasi, mereka tidak dapat diubah lagi. Harap periksa dengan cermat sebelum mengirimkan.",
  "title5007": "Pilih area penjualan",
  "title5008": "Mata uang akun",
  "title5010": "Hubungi Kami",
  "title5011": "Tambahkan layanan pelanggan khusus, selesaikan masalah dengan cepat",
  "title5012": "Menyediakan",
  "title5013": "Untuk",
  "title5014": "demonstrasi operasi produk",
  "title5015": "Biarkan Anda dan tim Anda dengan cepat memahami dan menggunakan",
  "title5016": "Pindai kode untuk mendapatkan layanan profesional",
  "title5017": "Silakan pilih area penjualan",
  "title5018": "Taiwan",
  "title5019": "Indonesia",
  "title5020": "Malaysia",
  "title5021": "Philippines",
  "title5022": "Singapore",
  "title5023": "Thailand",
  "title5024": "Vietnam",
  "title5025": "Apakah Anda yakin ingin keluar dan keluar dari sistem?",
  "title5026": "Petunjuk",
  "title5027": "Skrip",
  "title5028": "Salinan deskripsi yang sesuai",
  "title5029": "Wajib",
  "title5030": "Nilai Default",
  "title5031": "Template",
  "title5032": "Nomor template adalah",
  "title5033": "Item data template",
  "title5034": "Kategori template",
  "title5035": "Nomor kategori template adalah",
  "title5036": "Item data kategori template",
  "title5037": "Hari",
  "title5038": "Potong",
  "title5039": "Total",
  "title5040": "Jumlah analisis komentar",
  "title5041": "Poin",
  "title5042": "Periode Validitas",
  "title5043": "Silakan lengkapi pembayaran di halaman pop-up baru, dan klik tombol di bawah setelah pembayaran selesai.",
  "title5044": "Pesan ramah",
  "title5045": "Pembayaran selesai",
  "title5046": "Selamat, paket dibeli dengan sukses",
  "title5047": "Pembelian paket gagal, pembayaran tidak berhasil",
  "title5048": "Cari pengguna",
  "title5049": "Untuk melanjutkan, ikuti langkah-langkah berikut:",
  "title5050": "Parameter, tidak dapat melihat informasi proses",
  "title5051": "Tidak boleh kosong",
  "title5052": "Pengunjung organik",
  "title5053": "Penjualan organik",
  "title5054": "Pendapatan penjualan organik",
  "title5055": "Item data",
  "title5056": "Produk",
  "title5057": "Produk",
  "title5058": "Jam",
  "title5059": "Harga",
  "title5060": "Masukkan nama produk",
  "title5061": "Nama produk",
  "title5062": "Tinggi,",
  "title5063": "hari tidak boleh kosong",
  "title5064": "Keuntungan barang dalam beberapa hari",
  "title5065": "Nomor keuntungan barang dalam beberapa hari adalah",
  "title5066": "Item data keuntungan barang dalam beberapa hari",
  "title5067": "Penghapusan tidak dapat dikembalikan, juga akan menghapus inventaris terkait dan hubungan pasangan",
  "title5068": "Nomor sebagai",
  "title5069": "Kotak: Ruang pribadi eksklusif untuk kucing, membuat mereka hidup lebih sehat dan lebih bahagia!",
  "title5070": "Bagaimana cara menggunakan?",
  "title5071": "Informasi bantuan hubungan pencocokan",
  "title5072": "Sinkronisasi produk online",
  "title5073": "Memperbarui produk dari toko yang telah diotorisasi ke lokal.",
  "title5074": "Perbarui ke lokal.",
  "title5075": "Cepat menghasilkan pertandingan",
  "title5076": "Menghilangkan pembuatan produk lokal secara manual.",
  "title5077": "Proses yang rumit.",
  "title5078": "Menghasilkan produk",
  "title5079": "Segera sinkronkan produk setelah sinkronisasi",
  "title5080": "dan secara otomatis membangun hubungan pasangan",
  "title5081": "Menggabungkan produk",
  "title5082": "Pilih produk yang perlu digabungkan",
  "title5083": "Gabungkan menjadi satu produk lokal",
  "title5084": "Penjodohan otomatis",
  "title5085": "Capai online dan offline melalui aturan pengenalan otomatis",
  "title5086": "hubungan pasangan otomatis",
  "title5087": "Membatalkan pasangan",
  "title5088": "Batalkan Produk",
  "title5089": "dengan Produk Lokal",
  "title5090": "hubungan pasangan",
  "title5091": "Pengingat ramah:",
  "title5092": "Saat ini hanya mendukung pembuatan tunggal",
  "title5093": "Saya mengerti",
  "title5094": "Produk tidak boleh kosong",
  "title5095": "Tambah Nomor Rak",
  "title5096": "Tambah Tunggal",
  "title5097": "Tambah Massal",
  "title5098": "Impor Nomor Rak",
  "title5099": "Cetak label rak",
  "title5100": "Spesifikasi rak",
  "title5101": "Maksimum 100 kolom",
  "title5102": "Jumlah kolom",
  "title5103": "Maksimum 100 lapisan",
  "title5104": "Tingkat",
  "title5105": "Tanda Penghubung",
  "title5106": "Tinjauan",
  "title5107": "Bagian bawah rak",
  "title5108": "Silakan masukkan jumlah",
  "title5109": "Silakan masukkan bilangan bulat positif antara 1-100",
  "title5110": "Semua ",
  "title5111": "Kosong",
  "title5112": "Sudah Dipakai",
  "title5113": "Sudah Penuh",
  "title5114": "Dapat Dibersihkan",
  "title5115": "Silakan pilih item",
  "title5116": "?",
  "title5117": "Gagal",
  "title5118": "Rata-Rata",
  "title5119": "Hari pengiriman laut",
  "title5120": "Hari persediaan tambahan",
  "title5121": "Penjualan harian rata-rata dalam 15 hari terakhir",
  "title5122": "Pemindahan sedang dalam perjalanan",
  "title5123": "Scan Untuk Tambah Stok",
  "title5124": "Cetak label",
  "title5125": "Hari pengiriman",
  "title5126": "Jumlah kekurangan stok",
  "title5127": "Keuntungan toko per hari",
  "title5128": "Nomor laba toko harian adalah",
  "title5129": "Data laba toko harian",
  "title5130": "Pindai operasi",
  "title5131": "Scan dan Kirim",
  "title5132": "Jumlah produk",
  "title5135": "Label Pengiriman",
  "title5136": "Daftar Pengambilan Barang",
  "title5137": "Negara bagian",
  "title5138": "Kabupaten",
  "title5139": "Jumlah sukses baru:",
  "title5140": "Jumlah gagal baru:",
  "title5141": "错误日志数据项",
  "title5142": "访问日志数据项",
  "title5143": "匹配的",
  "title5144": "的对象内容",
  "title5145": "资源路径",
  "title5146": "重复的问题。",
  "title5147": "模板过于复杂，不利于胖友二次开发",
  "title5148": "折扣",
  "title5149": "随机",
  "title5150": "为",
  "title5151": "时需要添加",
  "title5152": "时可选择性添加",
  "title5153": "领取之日起或者次日",
  "title5154": "天内有效",
  "title5155": "优惠券仅原价购买商品时可用",
  "title5156": "满减",
  "title5157": "随机不能为空",
  "title5158": "有门槛不能为空",
  "title5159": "代表无限制不能为空",
  "title5160": "当",
  "title5161": "时需要添加不能为空",
  "title5162": "时可选择性添加不能为空",
  "title5163": "领取之日固定日期后过期",
  "title5164": "领取次日固定日期后过期不能为空",
  "title5165": "为2或者3时需要添加",
  "title5166": "天内有效不能为空",
  "title5167": "是不能为空",
  "title5168": "开启不能为空",
  "title5169": "天提醒不能为空",
  "title5170": "不限制",
  "title5171": "优惠券仅原价购买商品时可用不能为空",
  "title5172": "天！",
  "title5173": "开头",
  "title5174": "的账号不能为空",
  "title5175": "的模板编号不能为空",
  "title5176": "赋予专业身份",
  "title5177": "Misalnya: Anda adalah seorang master pemasaran senior",
  "title5178": "Format yang dibutuhkan dan konten output",
  "title5179": "Biaya evaluasi",
  "title5180": "Informasi bantuan laporan bulanan keuntungan",
  "title5181": "Periksa pesanan:",
  "title5182": "Periksa apakah pesanan cocok atau biaya dipelihara, di",
  "title5183": "Analisis laba - Rincian keuntungan pesanan",
  "title5184": "Pemeliharaan pesanan yang disaring untuk 【tanpa biaya produk】",
  "title5185": "Tandai evaluasi dan tahan pesanan",
  "title5186": "Dalam manajemen pesanan, tandai pesanan yang perlu dievaluasi atau ditahan sesuai",
  "title5187": "Manajemen pesanan - Pemrosesan pesanan",
  "title5188": "Atur tingkat pertukaran pembayaran",
  "title5189": "Sesuaikan dengan pengaturan kurs terbaru untuk memastikan perhitungan jumlah pembayaran yang akurat",
  "title5190": "Analisis laba - Laporan keuntungan bulanan - Pengaturan kurs pembayaran",
  "title5191": "Kesalahan pengaturan kurs evaluasi",
  "title5192": "Ketika pengaturan kurs pembayaran evaluasi salah, di",
  "title5193": "Pengaturan - Parameter Evaluasi",
  "title5194": "Lakukan perubahan di dalam, setelah selesai modifikasi, di",
  "title5195": "Temukan pesanan yang sesuai dan pilih 【Operasi berkelompok - Segarkan biaya terbaru】",
  "title5196": "Ketika salah satu dari konten di atas dimodifikasi, Anda perlu mengklik tombol 【Perbarui Keuntungan】untuk memperbarui data keuntungan.",
  "title5197": "Sistem akan secara otomatis memperbarui data pada tanggal 1 setiap bulan. Jika Anda perlu menghitung lebih awal, klik tombol 【Perbarui Keuntungan】untuk memperbarui data.",
  "title5198": "Biaya kemasan kosong",
  "title5199": "Verifikasi nomor ponsel asli",
  "title5200": "Verifikasi",
  "title5201": "Nomor telepon baru",
  "title5202": "Nomor telepon",
  "title5203": "Kode verifikasi salah, silakan masukkan lagi",
  "title5204": "Parameter, tidak dapat dilihat",
  "title5205": "Informasi cuti",
  "title5206": "Hari",
  "title5207": "berkas, kali ini dipilih",
  "title5208": "berkas, total terpilih",
  "title5209": "berkas",
  "title5210": "Juga hapus subkelompoknya dan sesuaikan pengelompokan data yang diterapkan menjadi tidak dikelompokkan",
  "title5211": "Pesanan error",
  "title5212": "Gudang penerima akan secara otomatis memilih gudang default, bukan gudang keluaran dari salah satu pesanan. Pemindaian dua kali pada satu paket bisa melakukan operasi masuk. Batas maksimum untuk pemindaian sekali adalah menangani 500 paket pengembalian.",
  "title5213": "Apakah ingin dimasukkan ke dalam stok?",
  "title5215": "Semua tidak dimasukkan ke dalam stok",
  "title5216": "Gudang Tujuan",
  "title5217": "Pindai atau masukkan nomor pesanan",
  "title5218": "Nomor Resi",
  "title5219": "Impor nomor pengiriman secara massal",
  "title5220": "Hasil pemindaian",
  "title5221": "Masukkan nomor pesanan pengembalian",
  "title6000": "Masukkan nomor pengiriman pengembalian",
  "title6001": "Pindai atau masukkan nomor pesanan pengembalian",
  "title6002": "Nomor Resi Pesanan Pengembalian",
  "title6003": "Harap pindai atau masukkan nomor pesanan pengembalian terlebih dahulu",
  "title5222": "Tidak ada data pemindaian",
  "title5223": "Marketplace",
  "title5224": "Nomor Retur",
  "title5225": "Nomor Resi Pesanan Pengembalian",
  "title5226": "Produk",
  "title5228": "Rak Penerima",
  "title5230": "Aksi",
  "title5231": "Hapus",
  "title5232": "Pengingat ramah: Setelah diproses ke dalam penyimpanan, pesanan pengembalian tidak dapat dibatalkan untuk penyimpanan",
  "title5233": "Mohon beroperasi dengan hati-hati",
  "title5234": "Tutup",
  "title5235": "Pesanan ini sudah ada dalam daftar pemindaian",
  "title5236": "Berhasil disimpan",
  "title5237": "Setelah pemindaian untuk pengiriman, paket akan otomatis beralih ke daftar 'Menunggu dijemput'",
  "title5238": "Konfirmasi pengiriman",
  "title5240": "Pemindaian berhasil",
  "title5241": "Bersihkan dan lanjutkan pemindaian",
  "title5242": "Scan sekarang",
  "title5243": "Nomor Resi",
  "title5244": "Metode pengiriman:",
  "title5245": "Silakan hubungkan pemindai untuk memindai paket",
  "title5246": "Informasi",
  "title5247": "Jumlah",
  "title5248": "Pindai Catatan",
  "title5249": "Sudah discan：",
  "title5250": "Pengiriman Berhasil",
  "title5251": "Nomor Pesanan",
  "title5252": "Total",
  "title5253": "Status Pengiriman",
  "title5254": "Pengiriman Berhasil",
  "title5255": "Pindai sekali menunjukkan pengiriman sekali, pemindaian dua kali menunjukkan menampilkan informasi pesanan untuk pertama kalinya, dan pemindaian dua kali menunjukkan pengiriman.",
  "title5256": "Jumlah Pemindaian",
  "title5257": "Pindai Sekali",
  "title5258": "Pindai Dua Kali",
  "title5259": "Kesalahan pemindaian, silakan klik kotak masukan dengan mouse untuk membuatnya biru",
  "title5260": "Petunjuk Sistem",
  "title5261": "Konfirmasi Hapus Semua Catatan Pemindaian",
  "title5262": "Rincian Jumlah",
  "title5263": "Mata Uang Saat Ini",
  "title5264": "Alihkan",
  "title5265": "Kurs Saat Ini:",
  "title5266": "Pembayaran yang Dibayarkan oleh Pembeli",
  "title5267": "Subtotal Setelah Diskon",
  "title5268": "Subtotal Sebelum Diskon",
  "title5269": "Diskon Barang Penjual",
  "title5270": "Diskon Barang",
  "title5271": "Biaya Pengiriman Setelah Diskon",
  "title5272": "Biaya Pengiriman Sebelum Diskon",
  "title5273": "Diskon Biaya Pengiriman Penjual",
  "title5274": "Diskon Biaya Pengiriman",
  "title5275": "Pajak",
  "title5276": "Jumlah Pembayaran Platform",
  "title5278": "Subtotal setelah diskon penjual",
  "title5279": "Subtotal pengembalian setelah diskon penjual",
  "title5280": "Total biaya",
  "title5281": "Biaya layanan transaksi",
  "title5282": "Komisi",
  "title5283": "Biaya Pengiriman Penjual",
  "title5284": "Biaya Pengiriman Pengguna",
  "title5285": "Biaya Pengiriman Sebenarnya",
  "title5286": "Subsidi Pengiriman",
  "title5287": "Biaya Pengiriman Pengembalian Sebenarnya",
  "title5288": "Biaya Pengiriman Pelanggan yang Dikembalikan",
  "title5289": "Komisi Influencer",
  "title5290": "Biaya Layanan",
  "title5292": "Jumlah Pembayaran yang Diperkirakan",
  "title5293": "Jumlah pengembalian dana",
  "title5296": "Biaya kemasan",
  "title5297": "Biaya pengiriman perkiraan",
  "title5298": "Biaya penanganan perkiraan",
  "title5301": "Keuntungan/ Kerugian",
  "title5302": "Persentase Keuntungan",
  "title5303": "Ongkos kirim",
  "title5304": "Biaya pengiriman asli",
  "title5305": "Voucher",
  "title5306": "Voucher penjual",
  "title5307": "Jumlah produk",
  "title5308": "Biaya logistik lintas batas (aktual)",
  "title5311": "Pengembalian Ongkos Kirim Shopee",
  "title5312": "Jumlah Kupon Penjual",
  "title5313": "Biaya dan Beban",
  "title5314": "Biaya Komisi Pemasaran Afiliasi",
  "title5315": "Biaya Pengiriman Asli",
  "title5316": "Diskon Pengiriman Platform",
  "title5317": "Diskon Penjual",
  "title5318": "Biaya Penyesuaian",
  "title5319": "Informasi Keuangan",
  "title5320": "Nomor Paket",
  "title5321": "Sertifikat",
  "title5322": "Nilai Kunci",
  "title5323": "Sukses",
  "title5324": "Alamat Tidak Boleh Kosong",
  "title5325": "Klien",
  "title5326": "Ke",
  "title5327": "karakter",
  "title5328": "Seperti",
  "title5329": "file dengan akhiran",
  "title5330": "Penyesuaian Lintas Bulan",
  "title5332": "Hari maksimum kelompok tidak boleh kosong",
  "title5333": "Baru saja",
  "title5334": "Total penjualan dalam beberapa hari terakhir",
  "title5335": "Silakan masukkan konten pesanan",
  "title10000": "Halaman Beranda",
  "title10001": "Jam, Karakter Wildcard Diperbolehkan",
  "title10002": "Periode Dimulai dari",
  "title10003": "Jam",
  "title10004": "Dari",
  "title10005": "Dimulai pada Jam, Setiap",
  "title10006": "Eksekusi Sekali SeJam",
  "title10007": "Tentukan",
  "title10008": "Boleh Memilih Banyak",
  "title10009": "Menit, Karakter Wildcard Diperbolehkan",
  "title10010": "Menit",
  "title10011": "Dimulai pada Menit, Setiap",
  "title10012": "Eksekusi Sekali SeMenit",
  "title10013": "Detik",
  "title10014": "Hari",
  "title10015": "Bulan",
  "title10016": "Minggu",
  "title10017": "Tahun",
  "title10018": "Ekspresi Waktu",
  "title10019": "Ekspresi",
  "title10021": "Reset",
  "title10022": "Tutup",
  "title10023": "Dari Komponen",
  "title10024": "Berubah",
  "title10025": "Siap untuk Mengembalikan",
  "title10026": "Satu",
  "title10027": "Dua",
  "title10028": "Tiga",
  "title10029": "Empat",
  "title10030": "Lima",
  "title10031": "Enam",
  "title10032": "5 Kali Terakhir",
  "title10033": "Dalam Hasil Perhitungan",
  "title10034": "Tidak Ada Hasil yang Memenuhi Syarat!",
  "title10035": "Hanya di atas dalam 100 tahun terakhir",
  "title10036": "Hasil!",
  "title10037": "Hari, Karakter Wildcard Diperbolehkan",
  "title10038": "Tidak Ditentukan",
  "title10039": "Dimulai pada, Setiap",
  "title10040": "Eksekusi Sekali Sehari",
  "title10041": "Setiap Bulan",
  "title10042": "Hari Kerja Terdekat dalam Sebulan",
  "title10043": "Hari Terakhir Bulan Ini",
  "title10044": "Detik, Karakter Wildcard Diperbolehkan",
  "title10045": "Dimulai pada Detik, Setiap",
  "title10046": "Eksekusi Sekali SeDetik",
  "title10047": "Minggu, Karakter Wildcard Diperbolehkan",
  "title10048": "Periode Dimulai dari Minggu",
  "title10049": "Yang Ke-",
  "title10050": "Hari dalam Seminggu",
  "title10051": "Hari Kerja Terakhir Bulan Ini",
  "title10052": "Senin",
  "title10053": "Selasa",
  "title10054": "Rabu",
  "title10055": "Kamis",
  "title10056": "Jumat",
  "title10057": "Sabtu",
  "title10058": "Minggu",
  "title10059": "Jangan isi, wildcard diizinkan",
  "title10060": "Setiap tahun",
  "title10061": "Dimulai dari tahun, setiap",
  "title10062": "Tahun berjalan sekali",
  "title10063": "Bulan, wildcard diizinkan",
  "title10064": "Dimulai dari bulan, setiap",
  "title10065": "Eksekusi bulanan sekali",
  "title10066": "Alamat dokumen",
  "title10067": "Silakan masukkan konten",
  "title10068": "Ukuran file yang diunggah tidak boleh melebihi",
  "title10069": "Gagal memasukkan gambar",
  "title10070": "Silakan masukkan alamat tautan",
  "title10071": "Simpan",
  "title10072": "Silakan masukkan alamat video",
  "title10073": "Teks",
  "title10074": "Judul 1",
  "title10075": "Judul 2",
  "title10076": "Judul 3",
  "title10077": "Judul 4",
  "title10078": "Judul 5",
  "title10079": "Judul 6",
  "title10080": "Font standar",
  "title10081": "Font serif",
  "title10082": "Font monospace",
  "title10083": "Teks satu baris",
  "title10084": "Format nomor telepon salah",
  "title10085": "Silakan masukkan nomor telepon",
  "title10086": "Pilih file",
  "title10087": "Silakan unggah",
  "title10088": "Ukuran tidak melebihi",
  "title10089": "Format adalah",
  "title10090": "file",
  "title256": "Hapus",
  "title10092": "Format file salah",
  "title10093": "Format file",
  "title10094": "Mengunggah file, harap tunggu",
  "title10095": "Jumlah file yang diunggah tidak boleh melebihi",
  "title10096": "Potong",
  "title10097": "Gagal mengunggah gambar, silakan coba lagi",
  "title10098": "Silakan masukkan",
  "title10099": "Teks multi-baris",
  "title10100": "Password",
  "title10101": "Penghitung",
  "title10102": "Editor",
  "title10103": "Pilihan turun",
  "title10104": "Opsi satu",
  "title10105": "Opsi dua",
  "title10106": "Silakan pilih",
  "title10107": "Seleksi bertingkat",
  "title10108": "Opsi 1",
  "title10109": "Opsi 1-1",
  "title10110": "Grup tombol radio",
  "title10111": "Grup kotak centang",
  "title10112": "Sakelar",
  "title10113": "Slider",
  "title10114": "Pilih Waktu",
  "title10115": "Rentang waktu",
  "title10116": "Ke",
  "title10117": "Waktu Mulai",
  "title10118": "Waktu Selesai",
  "title10119": "Pemilihan tanggal",
  "title10120": "Rentang tanggal",
  "title10121": "Mulai Tanggal ",
  "title10122": "Tanggal akhir",
  "title10123": "Penilaian",
  "title10124": "Pemilihan warna",
  "title10125": "Unggah",
  "title10126": "Klik untuk mengunggah",
  "title10127": "Kontainer baris",
  "title10128": "Tombol",
  "title10129": "Tombol primer",
  "title10130": "Tabel",
  "title10131": "Sedang dalam pengembangan",
  "title10133": "Alamat",
  "title10135": "Operasi",
  "title10136": "Silakan masukkan nama ikon",
  "title10137": "Pilih setidaknya satu",
  "title10138": "Tidak boleh kosong",
  "title10139": "Ukuran file melebihi",
  "title10140": "Harus memilih",
  "title10141": "Jenis file",
  "title10142": " Pencarian ",
  "title10143": "Mengirimkan",
  "title10144": "Hanya dapat mengunggah hingga",
  "title10145": "dari",
  "title10146": "file",
  "title10147": "Pratinjau",
  "title10148": "File format gambar",
  "title10149": "Ukuran gambar profil yang diunggah tidak boleh melebihi",
  "title10150": "Sedang mengunggah gambar, tunggu",
  "title10151": "Hari ini",
  "title10152": "Kemarin",
  "title10153": "7 Hari",
  "title10154": "14 hari terakhir",
  "title10155": "30 hari terakhir",
  "title10156": "Bulan ini",
  "title10157": "Bulan lalu",
  "title10158": "6 bulan terakhir",
  "title10159": "Tahun lalu",
  "title10160": "Status",
  "title10161": "Sedang diproses, jangan tutup jendela saat ini",
  "title10162": "Selesai",
  "title10163": "Memproses data",
  "title10164": "Ekspor",
  "title10165": "Jangan tutup jendela saat mengekspor data, Anda dapat mengunduh laporan setelah ekspor selesai",
  "title10166": "Unduh",
  "title10167": "Tutup",
  "title10168": "Siap ekspor",
  "title10169": "Sedang diekspor",
  "title10170": "Pengolahan selesai, unduh tersedia",
  "title10171": "Judul",
  "title10172": "Sinkronisasi",
  "title10173": "Jangan tutup jendela saat ini selama sinkronisasi data. Anda dapat menutup jendela setelah sinkronisasi selesai.",
  "title10174": "Gunakan setelah naik menjadi pengguna berbayar.",
  "title10175": "Ajukan Pelatihan",
  "title10176": "Lihat Bantuan",
  "title10177": "Tingkatkan Sekarang",
  "title10178": "Tidak ada yang cocok dengan:",
  "title10179": "Cocok dengan:",
  "title10180": "Peramban Anda tidak mendukung layar penuh",
  "title10181": "Sembunyikan Pencarian",
  "title10182": "Tampilkan Pencarian",
  "title10183": "Segarkan",
  "title10184": "Tampilkan Kolom",
  "title10185": "Tampilkan",
  "title10186": "Sembunyikan",
  "title10187": "Gabung dan Lanjutkan",
  "title10188": "Lebih",
  "title10189": "Tutorial Inisialisasi",
  "title10190": "Kelola toko dengan mudah, tingkatkan efisiensi, dan kurangi biaya bisnis.",
  "title10191": "Tutorial Operasi",
  "title10192": "Menyetujui Tiga Gudang",
  "title10194": "Harap masukkan alias toko",
  "title10195": " Gudang",
  "title10196": "Pilih gudang",
  "title10197": "Ketika Anda mendaftar akun Xinzhan Anda, sistem secara otomatis menghasilkan gudang untuk Anda dan mengaturnya sebagai gudang default. Jika Anda perlu memodifikasinya, silakan masuk ke [Inventaris-Manajemen Inventaris-Daftar Gudang] untuk mengaturnya.",
  "title10198": "Beri izin toko",
  "title10199": "Tambah produk",
  "title10200": "Menjaga informasi produk lokal dalam sistem untuk statistik laba, biaya",
  "title10201": "Pencocokan Barang",
  "title10202": "Padankan produk online, padankan produk lokal dengan platform online",
  "title10203": "Padanan",
  "title10204": "Pasangan produk online",
  "title10205": "Gudang",
  "title10206": "Inisialisasi inventaris untuk pembelian, pengisian ulang, pengiriman berikutnya, dan operasi lainnya",
  "title10207": "Tambahkan gudang",
  "title10208": "Manajemen personil",
  "title10209": "Konfigurasi izin yang sesuai untuk peran seperti operasional, pengadaan, keuangan, dan supervisor di perusahaan Anda",
  "title10210": "Pengaturan parameter",
  "title10211": "Tetapkan tampilan mata uang sistem Anda sesuai dengan nilai tukar negara, mengevaluasi pengaturan nilai tukar pesanan",
  "title10212": "Pengaturan Kurs",
  "title10213": "Tidak ada pengingat",
  "title10214": "Mengaktifkan panduan",
  "title10215": "Kembali",
  "title10216": "Berikutnya",
  "title10217": "Alias toko tidak boleh kosong",
  "title10218": "Gudang tidak boleh kosong",
  "title10219": "Panduan pemula",
  "title10220": "Selamat datang",
  "title10221": "Otorisasi toko",
  "title10222": "Tambahkan otorisasi toko",
  "title10223": "Mengotorisasi toko Anda ke sistem untuk sinkronisasi pesanan toko, produk online, dll.",
  "title10224": "Toko dapat diotorisasi melalui [Sistem-Manajemen Otorisasi-Manajemen Toko]. Tambahkan otorisasi toko baru.",
  "title10225": "Halaman otorisasi pihak ketiga adalah halaman resmi toko. Akun dan sandi penjual hanya digunakan untuk otorisasi toko, Xinzhan tidak akan menyimpan sandi Anda.",
  "title10226": "Produk",
  "title10227": "Dapat dilakukan melalui [Persediaan-Produk",
  "title10228": "Produk",
  "title10229": "Buat, tambahkan secara manual, impor spreadsheet untuk membuat produk",
  "title10230": "Dapat membuat individu",
  "title10231": "Produk dan kombinasi",
  "title10232": "Produk, perlu diisi dalam format standar saat membuat produk",
  "title10233": "Dengan online",
  "title10234": "Pencocokan, digunakan untuk mengurangi inventaris secara otomatis dalam sistem, dan statistik data terkait",
  "title10235": "Pencocokan produk dapat dilakukan melalui [Persediaan-Produk",
  "title10236": "Pengaturan pasangan produk online",
  "title10237": "Gudang Milik Sendiri: Saat mendaftar akun Xinzhan, sistem secara otomatis akan membuatkan Anda sebuah gudang dan mengaturnya sebagai gudang default. Jika perlu mengubahnya, silakan pergi ke [Inventaris-Manajemen Gudang-Daftar Gudang] untuk mengaturnya.",
  "title10238": "Gudang Pihak Ketiga: Otorisasi gudang pihak ketiga. Di [Inventaris-Manajemen Gudang-Daftar Gudang], buat gudang baru dan pilih gudang pihak ketiga yang sudah diotorisasi saat pembuatan.",
  "title10239": "Setelah mengotorisasi gudang pihak ketiga, pesanan dapat dipindahkan ke gudang pihak ketiga, dan mendukung sinkronisasi persediaan produk dari gudang pihak ketiga. Pesanan pembelian juga mendukung penekanan otomatis untuk pemrosesan masuk.",
  "title10240": "Pengaturan Personil",
  "title10241": "Manajemen Personil dapat diatur melalui [Sistem-Manajemen Akun-Manajemen Akun Sub]. Pengaturan sub-akun dapat mengalokasikan izin peran yang berbeda, menetapkan izin toko, izin gudang, dll.",
  "title10242": "Pengaturan peran dapat dibuat melalui [Sistem-Manajemen Akun-Manajemen Peran].",
  "title10243": "Pengaturan kurs dapat diubah melalui [Sistem-Pengaturan-Pengaturan Kurs]. Sistem baku untuk mendapatkan kurs secara real-time, dan Anda dapat menyesuaikan kurs. Setelah mengatur kurs, pesanan akan menampilkan harga sesuai dengan pengaturan terbaru.",
  "title10244": "Parameter evaluasi dapat diatur melalui [Sistem-Pengaturan-Parameter Evaluasi]. Setelah pengaturan selesai, biaya pesanan evaluasi akan dihitung sesuai dengan parameter yang diatur.",
  "title10245": "Tidak ada izin",
  "title10246": "Harap tambahkan produk terlebih dahulu",
  "title10247": "Harap lengkapi otorisasi toko terlebih dahulu",
  "title10248": "Harap lengkapi verifikasi keamanan",
  "title10249": "Seret penggeser di bawah untuk menyelesaikan teka-teki",
  "title10250": "中文",
  "title10251": "Anggota saat ini",
  "title10252": "Membeli",
  "title10253": "Informasi dasar",
  "title10254": "Paket saya",
  "title10255": "Pesanan saya",
  "title10256": "Keluar dari peran bermain",
  "title10257": "Keluar",
  "title10258": "林珍",
  "title10259": "Masukkan kata sandi login",
  "title10260": "Masuk menggunakan akun lain",
  "title10261": "Hak cipta semua",
  "title10262": "Min",
  "title10263": "Nomor cadangan 2023007214-2",
  "title10264": "Apakah Anda yakin ingin keluar dan keluar dari sistem?",
  "title10265": "Apakah Anda yakin ingin keluar dari peran bermain?",
  "title10266": "Petunjuk",
  "title10267": "消息启动事件",
  "title10268": "定时启动事件",
  "title10269": "条件启动事件",
  "title10270": "信号启动事件",
  "title10271": "错误启动事件",
  "title10272": "升级启动事件",
  "title10273": "补偿启动事件",
  "title10274": "非中断",
  "title10275": "测试按钮1",
  "title10276": "测试按钮1】点击事件里可以访问当前表单：",
  "title10277": "所以",
  "title10278": "可以拿到当前表单的",
  "title10279": "组件",
  "title10280": "表单的",
  "title10281": "提交数据：",
  "title10282": "在这里输入文字",
  "title10283": "配置文档参阅：",
  "title10284": "验证成功",
  "title10285": "验证失败",
  "title10286": "请依次点击【",
  "title10287": "向右滑动完成验证",
  "title10288": "主题风格设置",
  "title10289": "图标",
  "title10290": "主题颜色",
  "title10291": "系统布局配置",
  "title10292": "开启",
  "title10293": "固定",
  "title10294": "动态标题",
  "title10295": "保存配置",
  "title10296": "重置配置",
  "title10297": "正在保存到本地，请稍候",
  "title10298": "正在清除设置缓存并刷新，请稍候",
  "title10299": "xinjian",
  "title10300": "刷新页面",
  "title10301": "关闭当前",
  "title10302": "关闭其他",
  "title10303": "关闭左侧",
  "title10304": "关闭右侧",
  "title10305": "全部关闭",
  "title10306": "测试任务",
  "title10307": "打开文件",
  "title10308": "下载为",
  "title10309": "下载文件",
  "title10310": "退出模拟",
  "title10311": "开启模拟",
  "title10312": "模拟",
  "title10313": "向左对齐",
  "title10314": "向右对齐",
  "title10315": "向上对齐",
  "title10316": "向下对齐",
  "title10317": "水平居中",
  "title10318": "垂直居中",
  "title10319": "缩小视图",
  "title10320": "放大视图",
  "title10321": "重置视图并居中",
  "title10322": "撤销",
  "title10323": "恢复",
  "title10324": "重新绘制",
  "title10325": "保存模型",
  "title10326": "业务流程",
  "title10327": "请按住",
  "title10328": "键选择多个元素对齐",
  "title10329": "自动对齐可能造成图形变形，是否继续？",
  "title10330": "警告",
  "title10331": "保存模型失败，请重试！",
  "title10332": "发起人：",
  "title10333": "部门：",
  "title10334": "创建时间：",
  "title10335": "审批人：",
  "title10336": "结果：",
  "title10337": "结束时间：",
  "title10338": "审批建议：",
  "title10339": "常规",
  "title10340": "消息与信号",
  "title10341": "流转条件",
  "title10342": "表单",
  "title10343": "友情提示：使用",
  "title10344": "流程表单",
  "title10345": "替代，提供更好的表单设计功能",
  "title10346": "任务",
  "title10347": "多实例",
  "title10348": "执行监听器",
  "title10349": "任务监听器",
  "title10350": "扩展属性",
  "title10351": "Lainnya",
  "title10352": "Berikan identitas profesional",
  "title10353": "Seperti: Anda adalah seorang master pemasaran senior",
  "title10354": "Format apa yang diperlukan dan konten output",
  "title10355": "Bagaimana cara penggunaan?",
  "title10356": "Biaya evaluasi",
  "title10357": "Informasi bantuan laporan keuntungan bulanan",
  "title10358": "Periksa pesanan:",
  "title10359": "Periksa apakah pesanan dipasangkan atau biaya dipertahankan, di",
  "title10361": "Jaga pesanan dengan filter 【Tanpa biaya produk】",
  "title10362": "Tandai penilaian dan tahan pesanan:",
  "title10363": "Dalam manajemen pesanan, tandai pesanan yang memerlukan penilaian atau ditahan",
  "title10364": "Manajemen Pesanan - Pengolahan Pesanan",
  "title10365": "Tetapkan kurs pertukaran pembayaran:",
  "title10366": "Sesuaikan berdasarkan pengaturan kurs terbaru untuk memastikan perhitungan jumlah pembayaran yang akurat",
  "title10367": "Analisis Keuntungan - Laporan Bulanan Keuntungan - Pengaturan Kurs Pembayaran",
  "title10368": "Kesalahan pengaturan nilai tukar evaluasi",
  "title10369": "Ketika pengaturan nilai tukar pembayaran evaluasi salah, di",
  "title10370": "Pengaturan - Parameter Evaluasi",
  "title10371": "Lakukan perubahan di dalam, setelah modifikasi, di",
  "title10372": "Temukan pesanan yang sesuai dan pilih 【Operasi Batch - Segarkan Biaya Terbaru】.",
  "title10373": "Pengingat ramah:",
  "title10374": "Ketika salah satu dari konten di atas diubah, Anda perlu mengklik tombol 【Perbarui Laba】untuk memperbarui data laba.",
  "title10375": "Sistem akan secara otomatis memperbarui data pada tanggal 1 setiap bulan. Jika Anda perlu menghitung sebelumnya, klik tombol 【Perbarui Laba】untuk memperbarui data.",
  "title10376": "Jangan tampilkan lagi",
  "title10377": "Sudah saya ketahui",
  "title10378": "Huruf harus mengandung huruf kapital",
  "title10379": "Huruf, angka, dan karakter harus mencakup setidaknya 2 tipe",
  "title10380": "hari",
  "title10381": "file yang terpilih kali ini:",
  "title10382": "file terpilih secara keseluruhan:",
  "title10383": "file",
  "title10384": "Juga hapus subkelompoknya dan sesuaikan pengelompokan data yang diterapkan menjadi tidak dikelompokkan.",
  "title10385": "Pesanan salah",
  "title10386": "Scan Untuk Tambah Stok",
  "title10387": "Gudang penerima akan secara otomatis memilih gudang default, bukan gudang keluar dari salah satu pesanan. Memindai sebuah paket dua kali dapat melakukan operasi masuk. Batas maksimum untuk pemindaian sekali adalah menangani 500 paket pengembalian.",
  "title10388": "Apakah itu di stok?",
  "title10390": "Tidak ada yang disimpan",
  "title10391": "Gudang Tujuan",
  "title10392": "Pindai atau masukkan nomor pesanan",
  "title10393": "Nomor Resi",
  "title10394": "Nomor pengiriman masuk massal",
  "title10395": "Hasil pemindaian",
  "title10396": "Tidak ada data pemindaian tersedia",
  "title10397": "Silakan hubungkan pemindai untuk memindai paket",
  "title10398": "Marketplace",
  "title10399": "Nomor Retur",
  "title10400": "Nomor Resi Pesanan Pengembalian",
  "title10401": "Produk",
  "title10403": "Rak Penerima",
  "title10405": "Menghapus",
  "title10407": "Memindai sekali berarti memindai sekali untuk pengiriman, memindai dua kali berarti menampilkan informasi pesanan untuk pertama kalinya, dan memindai untuk pengiriman kedua kalinya.",
  "title10408": "Jumlah pemindaian",
  "title10409": "Rincian jumlah",
  "title10410": "Mata uang saat ini",
  "title10411": "Beralih",
  "title10412": "Kurs saat ini",
  "title10413": "Pembayaran yang Dibayarkan oleh Pembeli",
  "title10414": "Subtotal Setelah Diskon",
  "title10415": "Subtotal Sebelum Diskon",
  "title10416": "Diskon Produk Penjual",
  "title10417": "Diskon Produk",
  "title10418": "Ongkos Kirim Setelah Diskon",
  "title10419": "Ongkos Kirim Sebelum Diskon",
  "title10420": "Diskon Ongkos Kirim Penjual",
  "title10421": "Diskon Ongkos Kirim",
  "title10422": "Pajak",
  "title10423": "Jumlah Pembayaran Platform",
  "title10425": "Subtotal setelah diskon penjual",
  "title10426": "Subtotal setelah pengembalian diskon penjual",
  "title10427": "Total biaya",
  "title10428": "Biaya layanan transaksi",
  "title10429": "Komisi",
  "title10430": "Ongkos Kirim Penjual",
  "title10431": "Ongkos Kirim Pembeli",
  "title10432": "Ongkos Kirim Aktual",
  "title10433": "Subsidi Ongkos Kirim",
  "title10434": "Ongkos Kirim Pengembalian Aktual",
  "title10435": "Ongkos Kirim Pelanggan Pengembalian",
  "title10436": "Komisi Influencer",
  "title10437": "Biaya Layanan",
  "title10438": "Jumlah Pengembalian Perkiraan",
  "title10439": "Jumlah pengembalian dana",
  "title10441": "Biaya kemasan",
  "title10442": "Biaya pengiriman perkiraan",
  "title10443": "Biaya penanganan perkiraan",
  "title10444": "Ke Pengaturan",
  "title10446": "Keuntungan/ Kerugian",
  "title10447": "Persentase Keuntungan",
  "title10448": "Jumlah produk",
  "title10449": "Ongkos Kirim",
  "title10450": "Biaya pengiriman asli",
  "title10451": "Kupon",
  "title10452": "Kupon penjual",
  "title10453": "Jumlah produk",
  "title10454": "Biaya logistik lintas batas (aktual)",
  "title10457": "Pengembalian ongkos kirim Shopee",
  "title10458": "Jumlah kupon penjual",
  "title10459": "Biaya dan biaya",
  "title10460": "Biaya komisi pemasaran afiliasi",
  "title10461": "Biaya pengiriman asli",
  "title10462": "Diskon pengiriman platform",
  "title10463": "Diskon",
  "title10464": "Diskon penjual",
  "title10465": "Biaya penyesuaian",
  "title10466": "Informasi keuangan",
  "title10467": "Scan dan Kirim",
  "title10468": "Setelah pemindaian untuk pengiriman, paket akan otomatis pindah ke daftar [Akan diambil]",
  "title10469": "Konfirmasi pengiriman",
  "title10470": "Pemindaian sukses",
  "title10471": "Bersihkan dan lanjutkan pemindaian",
  "title10472": "Scan sekarang",
  "title10473": "Nomor Resi",
  "title10474": "Metode pengiriman:",
  "title10475": "Informasi",
  "title10476": "Kuantitas",
  "title10477": "Pindai Catatan",
  "title10478": "Sudah discan",
  "title10479": "Telah berhasil dikirim:",
  "title10480": "Nomor pesanan",
  "title10481": "Total",
  "title10482": "Status pengiriman",
  "title10483": "Pengiriman sukses",
  "title10484": "Pindai sekali",
  "title10485": "Pindai dua kali",
  "title10486": "Kesalahan pemindaian, harap klik kotak masukan dengan mouse terlebih dahulu, kotak masukan berwarna biru",
  "title10487": "Peringatan sistem",
  "title10488": "Apakah Anda yakin ingin menghapus semua catatan pemindaian?",
  "title10489": "Sukses",
  "title10490": "Sertifikat",
  "title10491": "Nilai kunci",
  "title10492": "Nomor paket",
  "title10493": "如何实现实现会签、或签？",
  "title10494": "流程标识",
  "title10495": "请输入流标标识",
  "title10496": "流程名称",
  "title10497": "请输入流程名称",
  "title10498": "流程标识不能为空",
  "title10499": "流程名称不能为空",
  "title10500": "不满足",
  "title10501": "规则，所以不进行赋值",
  "title10502": "满足",
  "title10503": "规则，所以进行赋值",
  "title10504": "表单标识",
  "title10505": "业务标识",
  "title10506": "无",
  "title10507": "表单字段",
  "title10508": "序号",
  "title10509": "字段名称",
  "title10510": "字段类型",
  "title10511": "默认值",
  "title1593": "编辑",
  "title10513": "添加字段",
  "title10514": "字段配置",
  "title10515": "字段",
  "title10516": "类型",
  "title10517": "请选择字段类型",
  "title10518": "类型名称",
  "title10519": "时间格式",
  "title10520": "枚举值列表：",
  "title10521": "添加枚举值",
  "title10522": "枚举值编号",
  "title10523": "枚举值名称",
  "title10524": "约束条件列表：",
  "title10525": "添加约束",
  "title10526": "约束名称",
  "title10527": "约束配置",
  "title10528": "字段属性列表：",
  "title10529": "添加属性",
  "title10530": "属性编号",
  "title10531": "属性值",
  "title10532": "取",
  "title10534": "保",
  "title10535": "存",
  "title10536": "编号",
  "title10537": "配置",
  "title10538": "值",
  "title10539": "确 定",
  "title10540": "",
  "title10541": "长整型",
  "title10542": "字符串",
  "title10543": "布尔类",
  "title10544": "日期类",
  "title10545": "枚举类",
  "title10546": "自定义类型",
  "title10547": "属性配置",
  "title10548": "枚举值配置",
  "title10549": "约束条件配置",
  "title10550": "流转类型",
  "title10551": "普通流转路径",
  "title10552": "默认流转路径",
  "title10553": "条件流转路径",
  "title10554": "条件格式",
  "title10555": "脚本",
  "title10556": "脚本语言",
  "title10557": "脚本类型",
  "title10558": "内联脚本",
  "title10559": "外部脚本",
  "title10560": "资源地址",
  "title10561": "事件类型",
  "title10562": "监听器类型",
  "title10563": "添加监听器",
  "title10564": "类",
  "title10565": "代理表达式",
  "title10566": "脚本格式",
  "title10567": "请填写脚本格式",
  "title10568": "请选择脚本类型",
  "title10569": "脚本内容",
  "title10570": "请填写脚本内容",
  "title10571": "请填写资源地址",
  "title10572": "定时器类型",
  "title10573": "持续时长",
  "title10574": "循环",
  "title10575": "定时器",
  "title10576": "注入字段：",
  "title10577": "字段值",
  "title10578": "字段名称：",
  "title10579": "字段类型：",
  "title10580": "字段值：",
  "title10581": "表达式：",
  "title10582": "回路特性",
  "title10583": "并行多重事件",
  "title10584": "时序多重事件",
  "title10585": "循环事件",
  "title10586": "循环基数",
  "title10587": "集合",
  "title10588": "元素变量",
  "title10589": "完成条件",
  "title10590": "异步状态",
  "title10591": "异步前",
  "title10592": "异步后",
  "title10593": "排除",
  "title10594": "重试周期",
  "title10595": "确认移除该字段吗？",
  "title10596": "认",
  "title10597": "操作取消",
  "title10598": "确认移除该监听器吗？",
  "title10599": "事件",
  "title10600": "监听器",
  "title10601": "请填写定时器配置",
  "title10602": "元素文档：",
  "title10603": "创建",
  "title10604": "指派",
  "title10605": "完成",
  "title10606": "更新",
  "title10607": "超时",
  "title10608": "属性名",
  "title10609": "属性名：",
  "title10610": "属性值：",
  "title10611": "确认移除该属性吗？",
  "title10612": "消息列表",
  "title10613": "创建新消息",
  "title10614": "消息",
  "title10615": "消息名称",
  "title10616": "信号列表",
  "title10617": "创建新信号",
  "title10618": "信号",
  "title10619": "信号名称",
  "title10620": "创建消息",
  "title10621": "创建信号",
  "title10622": "该消息已存在，请修改",
  "title10623": "后重新保存",
  "title10624": "该信号已存在，请修改",
  "title10625": "异步延续",
  "title10626": "Penyesuaian lintas bulan",
  "title10627": "Biaya paket kosong",
  "title10629": "追加结束事件",
  "title10630": "追加网关",
  "title10631": "追加任务",
  "title10632": "追加中间抛出事件",
  "title10633": "边界事件",
  "title10634": "激活全局连接工具",
  "title10635": "添加",
  "title10636": "在上面添加道",
  "title10637": "分割成两个道",
  "title10638": "分割成三个道",
  "title10639": "在下面添加道",
  "title10640": "追加补偿活动",
  "title10641": "修改类型",
  "title10642": "使用关联连接",
  "title10643": "使用顺序",
  "title10644": "消息流或者关联连接",
  "title10645": "使用数据输入关联连接",
  "title10646": "激活抓手工具",
  "title10647": "激活套索工具",
  "title10648": "激活创建",
  "title10649": "删除空间工具",
  "title10650": "创建扩展子过程",
  "title10651": "创建中间抛出事件",
  "title10652": "创建池",
  "title10653": "参与者",
  "title10654": "数据对象参考",
  "title10655": "数据存储参考",
  "title10656": "即席",
  "title10657": "发送任务",
  "title10658": "接收任务",
  "title10659": "用户任务",
  "title10660": "手工任务",
  "title10661": "业务规则任务",
  "title10662": "服务任务",
  "title10663": "脚本任务",
  "title10664": "调用活动",
  "title10665": "子流程（折叠的）",
  "title10666": "子流程（展开的）",
  "title10667": "开始事件",
  "title10668": "中间事件",
  "title10669": "结束事件",
  "title10670": "创建开始事件",
  "title10671": "创建结束事件",
  "title10672": "创建任务",
  "title10673": "创建用户任务",
  "title10674": "创建网关",
  "title10675": "创建数据对象",
  "title10676": "创建数据存储",
  "title10677": "创建分组",
  "title10678": "创建中间",
  "title10679": "消息开始事件",
  "title10680": "定时开始事件",
  "title10681": "条件开始事件",
  "title10682": "信号开始事件",
  "title10683": "错误开始事件",
  "title10684": "升级开始事件",
  "title10685": "补偿开始事件",
  "title10686": "消息开始事件（非中断）",
  "title10687": "定时开始事件（非中断）",
  "title10688": "条件开始事件（非中断）",
  "title10689": "信号开始事件（非中断）",
  "title10690": "升级开始事件（非中断）",
  "title10691": "消息中间捕获事件",
  "title10692": "消息中间抛出事件",
  "title10693": "定时中间捕获事件",
  "title10694": "升级中间抛出事件",
  "title10695": "条件中间捕获事件",
  "title10696": "链接中间捕获事件",
  "title10697": "链接中间抛出事件",
  "title10698": "补偿中间抛出事件",
  "title10699": "信号中间捕获事件",
  "title10700": "信号中间抛出事件",
  "title10701": "消息结束事件",
  "title10702": "定时结束事件",
  "title10703": "错误结束事件",
  "title10704": "取消结束事件",
  "title10705": "补偿结束事件",
  "title10706": "信号结束事件",
  "title10707": "终止结束事件",
  "title10708": "消息边界事件",
  "title10709": "消息边界事件（非中断）",
  "title10710": "定时边界事件",
  "title10711": "定时边界事件（非中断）",
  "title10712": "升级边界事件",
  "title10713": "升级边界事件（非中断）",
  "title10714": "条件边界事件",
  "title10715": "条件边界事件（非中断）",
  "title10716": "错误边界事件",
  "title10717": "取消边界事件",
  "title10718": "信号边界事件",
  "title10719": "信号边界事件（非中断）",
  "title10720": "补偿边界事件",
  "title10721": "互斥网关",
  "title10722": "并行网关",
  "title10723": "相容网关",
  "title10724": "复杂网关",
  "title10725": "事件网关",
  "title10726": "转运",
  "title10727": "子流程",
  "title10728": "事件子流程",
  "title10729": "折叠池",
  "title10730": "展开池",
  "title10731": "在",
  "title10732": "里，",
  "title10733": "没有父类",
  "title10734": "没有指定的形状类型",
  "title10735": "流元素必须是池",
  "title10736": "参与者的子类",
  "title10737": "子道大于",
  "title10738": "元素不能为空",
  "title10739": "流程图不符合",
  "title10740": "规范",
  "title10741": "没有可展示的流程图",
  "title10742": "没有可展示的流程",
  "title10743": "协作",
  "title10744": "由",
  "title10745": "引用的",
  "title10746": "元素仍未绘制",
  "title10747": "已被渲染",
  "title10748": "导入",
  "title10749": "失败",
  "title10750": "详情",
  "title10751": "创建者",
  "title10752": "持续异步",
  "title10753": "工作配置",
  "title10754": "工作优先级",
  "title10755": "重试时间周期",
  "title10756": "文档",
  "title10757": "元素文档",
  "title10758": "历史配置",
  "title10759": "历史的生存时间",
  "title10760": "业务",
  "title10761": "校验",
  "title10762": "属性",
  "title10763": "执行监听",
  "title10764": "必须提供一个值",
  "title10765": "资源",
  "title10766": "字段注入",
  "title10767": "扩展",
  "title10768": "输入",
  "title10769": "输出",
  "title10770": "输入参数",
  "title10771": "输出参数",
  "title10772": "参数",
  "title10773": "定时器定义类型",
  "title10774": "定时器定义",
  "title10775": "持续",
  "title10776": "升级",
  "title10777": "错误",
  "title10778": "链接名称",
  "title10779": "条件名称",
  "title10780": "变量名称",
  "title10781": "变量事件",
  "title10782": "多个变量事件以逗号隔开",
  "title10783": "等待完成",
  "title10784": "活动参考",
  "title10785": "版本标签",
  "title10786": "可执行文件",
  "title10787": "扩展任务配置",
  "title10788": "任务优先级",
  "title10789": "外部",
  "title10790": "连接器",
  "title10791": "必须配置连接器",
  "title10792": "连接器编号",
  "title10793": "实现方式",
  "title10794": "结果变量",
  "title10795": "主题",
  "title10796": "配置连接器",
  "title10797": "代理人",
  "title10798": "候选用户",
  "title10799": "候选组",
  "title10800": "到期时间",
  "title10801": "跟踪日期",
  "title10802": "优先级",
  "title10803": "跟踪日期必须符合",
  "title10804": "表达式，如：",
  "title10805": "或者一个",
  "title10806": "标准日期，如：2015-06-26",
  "title10807": "变量",
  "title10808": "候选人起动器配置",
  "title10809": "候选人起动器组",
  "title10810": "这映射到流程定义键。",
  "title10811": "候选人起动器的用户",
  "title10812": "指定多个用户作为逗号分隔的列表。",
  "title10813": "启动",
  "title10814": "指定多个组作为逗号分隔的列表。",
  "title10815": "消息实例",
  "title10816": "外部资源",
  "title10817": "跟踪时间",
  "title10818": "友情提示：任务的分配规则，使用",
  "title10819": "流程模型",
  "title10820": "下的【分配规则】替代，提供指定角色、部门负责人、部门成员、岗位、工作组、自定义脚本等",
  "title10821": "种维护的任务分配维度，更加灵活！",
  "title10822": "Terkini",
  "title10823": "Total penjualan dalam beberapa hari",
  "title10824": "Jumlah hari maksimum untuk pengelompokan tidak boleh kosong",
  "title10825": "Jumlah pengembalian dana (uang)",
  "title10826": "Harap masukkan nama penyedia layanan pihak ketiga",
  "title10827": "Dorongan massal",
  "title10828": "Pilih barang yang akan didorong",
  "title10829": "Dorong semua barang",


  "magellan_600001": "Peralatan Rumah",
  "magellan_600024": "Peralatan Dapur",
  "magellan_600154": "Tekstil & Hiasan Halus",
  "magellan_600942": "Peralatan Rumah Tangga",
  "magellan_601152": "Pakaian Wanita & Onderdil",
  "magellan_601303": "Mode Muslim",
  "magellan_601352": "Sepatu",
  "magellan_601450": "Kecantikan & Perawatan Pribadi",
  "magellan_601739": "Telepon & Elektronik",
  "magellan_601755": "Komputer & Peralatan Kantor",
  "magellan_602118": "Peralatan Hewan Peliharaan",
  "magellan_602284": "Bayi & Kehamilan",
  "magellan_603014": "Olahraga & Outdoor",
  "magellan_604206": "Mainan & Hobi",
  "magellan_604453": "Furnitur",
  "magellan_604579": "Alat & Perkakas",
  "magellan_604968": "Pembangunan Rumah",
  "magellan_605196": "Otomotif & Sepeda Motor",
  "magellan_605248": "Aksesori Mode",
  "magellan_700437": "Makanan & Minuman",
  "magellan_700645": "Kesehatan",
  "magellan_801928": "Buku, Majalah & Audio",
  "magellan_802184": "Mode Anak-anak",
  "magellan_824328": "Pakaian Pria & Onderdil",
  "magellan_824584": "Beg & Tas",
  "magellan_834312": "Produk Virtual",
  "magellan_856720": "Barang Bekas",
  "magellan_951432": "Koleksi",
  "magellan_953224": "Aksesori Perhiasan & Derivatif",
  // 2024.3.5 物流功能新增翻译
  "可按物流方式分别设置揽收方式": "Atur metode pengambilan secara terpisah berdasarkan metode pengiriman.",
  "物流方式": "Metode Pengiriman",
  "物流名称": "Nama Pengiriman",
  "状态": "Status",
  "操作": "Aksi",
  "设置": "Pengaturan",
  "揽货方式": "Metode Pengambilan",
  "快递取件": "Pengambilan Kurir",
  "自行寄件": "Pengiriman Sendiri",
  "请选择揽收方式": "Silakan pilih metode pengambilan",
  "全部": "Semua",
  "印尼": "Indonesia",
  "马来西亚": "Malaysia",
  "菲律宾": "Filipina",
  "泰国": "Thailand",
  "越南": "Vietnam",
  "中国台湾":"Taiwan, China",
  "新加坡":"Singapura",
  "线上物流": "Logistik Online",
  "操作成功！": "Operasi Berhasil!",
  "打印拣货单(含汇总)":"Cetak Pemilihan(dengan ringkasan)",
  "商品SKU": "SKU Produk",
  "产品SKU": "SKU Toko",
  "包裹数量": "Jumlah Paket",
  "SKU数量": "Jumlah SKU",
  "货品总数": "Total Produk",
  "打印时间": "Waktu Cetak",
  "货品信息": "Detail Produk",
  "变种名称": "Nama Variasi",
  "货架位": "Lokasi Rak",
  "数量": "Jumlah",
  "包裹信息": "Informasi Paket",
  "包裹号": "Nomor Paket",
  "总计": "Total",
  "打印":"Cetak",
  "拣货单":"Daftar Pengambilan",
  "包裹数量":"Jumlah Paket",
  "货品总数":"Total Barang",
  "打单时间":"Waktu Cetakan",
  "货品信息":"Informasi Barang",
  "买家信息":"Informasi Pembeli",
  "备注信息":"Catatan",
  "总数":"Total",
  "订单号":"Nomor Pesanan",
  "运单号":"Nomor Resi",
  "物流方式":"Metode Pengiriman",
  "店铺名称":"Nama Toko",
  "货品总数":"Total Produk",
  "打印面单和配货单":"Mencetak Waybill dan Daftar Pengemasan",
  "稳定出单的商品，销量下滑":"Pesanan stabil, penjualan menurun",
  "爆款商品":"Produk laris",
  "出单较为稳定，销量暴涨的潜力商品":"Produk berpotensi dengan pesanan stabil dan penjualan melesat",
  "稳定出单的商品，销量持续上涨":"Produk dengan pesanan stabil dan pertumbuhan penjualan yang berkelanjutan",
  "上架时间长，销量较低":"Lama di rak, penjualan rendah",
  "高退货率":"Tingkat pengembalian tinggi",
  "高差评率":"Tingkat ulasan negatif tinggi",
  "低回复率":"Tingkat respon rendah",
  "取消率过高":"Tingkat pembatalan tinggi",
  "样品费用":"Biaya sampel",
  "空包费用":"Biaya kemasan kosong",
  "测评佣金":"Komisi evaluasi",
  "测评付款佣金/手续费":"Komisi/biaya pembayaran evaluasi",
  "修改自定义费用":"Mengubah biaya kustom",
  "小件包裹":"Paket kecil",
  "测试刷单":"Sikat uji",
  "漏单":"Ketinggalan pesanan",
  "预售":"Pra-penjualan",
  "海运":"Pengiriman laut",
  "不发":"Belum dikirim",
  "补寄":"Kirim ulang",
  "已采":"Dipesan",
  "未分组":"Belum dikelompokkan",
  "测评佣金":"Komisi evaluasi",
  "测评付款佣金/手续费":"Komisi/biaya pembayaran evaluasi",
  "提现支出":"Biaya penarikan",
  "线下售后退款":"Pengembalian penjualan offline",
  "线下补发运费":"Biaya pengiriman ulang offline",
  "线下补发成本":"Biaya pengiriman ulang offline",
  "退件处理费":"Biaya pemrosesan pengembalian",
  "活动包裹费用":"Biaya paket kegiatan",
  "其他杂费":"Biaya lainnya",
  "仓库费用":"Biaya gudang",
  "未推送":"Belum dipush",
  "推送中":"Sedang diproses",
  "推送失败":"Push gagal",
  "待盘点":"Persediaan tertunda",
  "按仓库":"Berdasarkan gudang",
  "按商品SKU":"Berdasarkan SKU produk",
  "调拨计划":"Rencana alokasi",
  "不分摊":"Tidak dibagi",
  "按价格分摊":"Dibagi menurut harga",
  "按数量分摊":"Dibagi menurut jumlah",
  "按体积分摊":"Dibagi menurut volume",
  "待出库":"Keluaran tertunda",
  "已出库":"Keluar",
  "采购入库":"Penerimaan pembelian",
  "销售出库":"Masalah penjualan",
  "手动入库":"Penerimaan manual",
  "手动出库":"Issue manual",
  "回滚出库":"Issue rollback",
  "导入入库":"Penerimaan impor",
  "盘点入库":"Penerimaan persediaan",
  "盘点出库":"Masalah persediaan",
  "调拨入库":"Penerimaan alokasi",
  "调拨出库":"Masalah alokasi",
  "取消调拨入库":"Membatalkan penerimaan alokasi",
  "三方仓同步入库":"Penerimaan sinkronisasi gudang pihak ketiga",
  "三方仓同步出库":"Perselisihan sinkronisasi gudang pihak ketiga",
  "退货入库":"Penerimaan pengembalian",
  "到付":"Bayar di tempat",
  "现结":"Bayar sekarang",
  "分期付款":"Pembayaran cicilan",
  "周结":"Penyelesaian mingguan",
  "月结":"Penyelesaian bulanan",
  "到期支付":"Pembayaran saat jatuh tempo",
  "SKU编号": "SKU nomor",
  "未扣除广告费后的利润":"Laba bersih setelah dikurangi biaya iklan",
  "排除测评搁置取消未付款的订单量":"Mengesampingkan evaluasi + penyimpanan + pembatalan + volume pesanan yang belum dibayar",
  "排除测评搁置取消未付款的销量":"Mengesampingkan evaluasi + penyimpanan + pembatalan + volume penjualan yang belum dibayar",
  "有效销售额释义":"Jumlah penjualan efektif = Pembayaran pembeli + Subsidi platform + Subsidi pengiriman (tidak termasuk jumlah penjualan yang belum dibayar)",
  "每次最多可选取50条数据进行打印。":"Maksimal 50 rekaman dapat dipilih untuk dicetak setiap kali",
  "导出已选商品": "Ekspor Produk yang Dipilih",
  "导出全部页商品": "Ekspor Semua Halaman Produk",
  "导出SKU配对关系": "Ekspor Penjodohan SKU",
  "显示下架商品":"Menampilkan produk yang tidak dijual",
  "扫描或输入包裹号": "Pindai atau masukkan nomor paket / nomor resi",
  "拆单成功": "Berhasil memisahkan",
  "已确定订单销售额除以已确定订单订单数": "Jumlah penjualan total pesanan yang sudah dikonfirmasi dibagi dengan total jumlah pesanan yang sudah dikonfirmasi",
  "访客数": "Pengunjung",
  "订单数": "Pesanan",
  "销售额": "Penjualan",
  "环比":"Bulan-ke-Bulan",
  "本地状态是退货退款中和退货订单或退款金额大于0的退款金额":"Status lokal adalah pengembalian dan pengembalian atau jumlah pengembalian yang lebih besar dari 0.",
  "退款金额=本地状态是退货退款中和退货订单或退款金额大于0的退款金额":"Jumlah pengembalian sama dengan jumlah pengembalian saat status lokal adalah pengembalian dan pengembalian atau jumlah pengembalian lebih besar dari 0.",
  "退货订单量=本地状态是退货退款中和退货订单或退款金额大于0的订单数量":"Jumlah pesanan pengembalian sama dengan jumlah pesanan ketika status lokal adalah pengembalian dan pengembalian atau jumlah pengembalian lebih besar dari 0.",
  "有效订单量=排除测评+搁置+取消+未付款的订单量":"Jumlah pesanan yang valid sama dengan jumlah pesanan yang tidak termasuk evaluasi, ditahan, dibatalkan, dan belum dibayar.",
  "有效销售额=排除测评+搁置+取消+未付款的销售额":"Jumlah penjualan yang valid sama dengan jumlah penjualan yang tidak termasuk evaluasi, ditahan, dibatalkan, dan belum dibayar.",
  "销售额=已下单的总销售额（未取消订单销售额+取消订单销售额 ）":"Jumlah penjualan sama dengan jumlah penjualan total dari pesanan yang ditempatkan (jumlah penjualan dari pesanan yang tidak dibatalkan + jumlah penjualan dari pesanan yang dibatalkan).",
  "订单量=已下单的订单数量":"Jumlah pesanan sama dengan jumlah pesanan yang ditempatkan.",
  "共{cont}条":"Total {cont} item",
  "发现新版本，是否更新":"Versi baru ditemukan, perbarui sekarang?",
  "高级版":"Versi Lanjutan",
  "您有62个店铺授权过期，请点击重新授权":"Anda memiliki 62 izin toko kedaluwarsa, silakan klik untuk memperbarui izin",
  "亏损商品":"Produk yang Merugikan",
  "利润":"Keuntungan",
  "利润偏低":"Keuntungan Rendah",
  "利润率":"Margin Keuntungan",
  "销量暴跌":"Volume Penjualan Turun Drastis",
  "稳定出单的商品，销量下滑":"Produk yang memesan stabil, volume penjualan menurun",
  "销量环比":"Perbandingan Volume Penjualan",
  "出单较为稳定，销量暴涨的潜力商品":"Produk dengan pesanan yang relatif stabil, potensi peningkatan tajam dalam volume penjualan",
  "稳定出单的商品，销量持续上涨":"Produk dengan pesanan stabil, volume penjualan terus meningkat",
  "销量环比":"Volume Penjualan Dibandingkan Bulan Lalu",
  "上架时间长，销量较低":"Lama di rak, volume penjualan rendah",
  "销量":"Volume Penjualan",
  "高退货率":"Tingkat Pengembalian Tinggi",
  "退货率":"Tingkat Pengembalian",
  "高差评率":"Tingkat Umpan Balik Negatif Tinggi",
  "差评率":"Tingkat Umpan Balik Negatif",
  "低回复率":"Tingkat Respon Rendah",
  "回复率":"Tingkat Respon",
  "回应时间":"Waktu Respon",
  "回应时间>1小时":"Waktu Respon >1 Jam",
  "逾期发货率过高":"Tingkat Pengiriman Terlambat Berlebihan",
  "逾期发货率":"Tingkat Pengiriman Terlambat",
  "取消率过高":"Tingkat Pembatalan Tinggi",
  "取消率":"Tingkat Pembatalan",
  "联系我们":"Hubungi Kami",
  "修改自定义费用":"Ubah Biaya Kustom",
  "快递取件":"Pengambilan Ekspres",
  "自行取件":"Pengambilan Sendiri",
  "未分组":"Tidak Dikelompokkan",
  "更新时间":"Waktu Pembaharuan",
  "默认仓库":"Gudang Default",
  "商品SKU推送失败:2000035000: 授权失败":"SKU produk gagal dikirim:2000035000: Gagal otorisasi",
  "商品SKU推送失败:2000035000: 授权失败,请检查appKey和appSecret是否正确":"SKU produk gagal dikirim: 2000035000: Gagal otorisasi, harap periksa apakah appKey dan appSecret benar",
  "调拨计划":"Rencana Alokasi",
  "在途中":"Sedang dalam perjalanan",
  "部分收货":"Diterima sebagian",
  "已完成":"Selesai",
  "已取消":"Dibatalkan",
  "待出库":"Tertunda keluar",
  "已出库":"Sudah Dikirim",
  "待出库":"Menunggu pengiriman",
  "已入库":"Sudah Diterima",
  "订单 销售出库1":"Pengiriman Pesanan Penjualan 1",
  "联系我们":"Hubungi kami",
  "采购订单推送失败:2000035000: 授权失败":"Gagal mengirimkan pesanan pembelian: 2000035000: Autorisasi gagal",
  "采购订单推送失败:2000035000: 客户SKU 不存在。":"Gagal mengirimkan pesanan pembelian: 2000035000: SKU pelanggan tidak ada",
  "采购订单推送失败:2000035000: Item not exist: sku = Testing Product K, name = null;":"Gagal mengirimkan pesanan pembelian: 2000035000: Item tidak ada: sku = Testing Product K, name = null",
  "AI模板管理":"Pengelolaan Template AI",
  "修改":"Edit",
  "AI模板分类管理":"Pengelolaan Kategori Template AI",
  "自由会话":"Sesi Bebas",
  "模板会话":"Sesi Template",
  "shopee listing写作与优化6。":"Penulisan dan Optimalisasi Shopee Listing 6",
  "根据提供的关键词、类目、商品卖点信息，为你生成地道流畅的语言表达，提升商品曝光量；使用客观和中立的语言，避免夸张和绝对化的词汇。":"Membuat ungkapan bahasa yang otentik dan lancar berdasarkan kata kunci, kategori, dan poin penjualan produk yang diberikan, untuk meningkatkan paparan produk; Gunakan bahasa yang objektif dan netral, hindari kata-kata berlebihan dan absolut.",
  "商品特性的条数":"Jumlah Fitur Produk",
  "商品关键词":"Kata Kunci Produk",
  "商品品牌":"Merek Produk",
  "商品类目":"Kategori Produk",
  "商品卖点":"Poin Penjualan Produk",
  "我的收藏":"Favorit Saya",
  "知识库":"Pangkalan Pengetahuan",
  "发现新版本，点击更新完成升级。体验新版本":"Temukan versi baru, klik untuk memperbarui dan selesaikan pembaruan.",
  "系统消息":"Pesan Sistem",
  "今日不再提醒":"Tidak mengingatkan lagi hari ini",
  "去授权或删除":"Pergi untuk memberikan izin atau menghapus",
  "为避免使用及数据分析受影响，请尽快处理过期的店铺数据，您可以":"Untuk menghindari pengaruh penggunaan dan analisis data, vui lòng xử lý dữ liệu cửa hàng yang kadaluarsa càng sớm càng tốt. Anda dapat",
  "已过期的店铺":"Toko yang telah kedaluwarsa",
  "最多置顶5个":"Maksimal menempelkan 5",
  "综合分析":"Analisis komprehensif",
  "受限页面":"Halaman terbatas",
  "已下单的订单数量 （包含取消和退款订单）":"Jumlah pesanan yang sudah ditempatkan (termasuk pesanan yang dibatalkan dan dikembalikan)",
  "已下单的用户数量（按买家姓名/邮箱去重）":"Jumlah pelanggan yang telah melakukan pemesanan (tidak ditambahkan kembali dengan nama/email pembeli)",
  "付款客户数":"Jumlah pelanggan yang membayar",
  "退款客户数":"Jumlah pelanggan yang melakukan pengembalian",
  "已退款的用户数（包含部分退款）":"Jumlah pengguna yang telah dikembalikan (termasuk pengembalian sebagian)",
  "已下单的用户数量（按买家姓名/邮箱去重）":"",
  "已取消订单数量":"Jumlah pelanggan unik yang melakukan pemesanan (tidak ditambahkan kembali dengan nama/email pembeli)",
  "仅统计已付款的订单销售数量":"Jumlah penjualan hanya untuk pesanan yang sudah dibayar",
  "销售额/客户数":"Pendapatan Penjualan/Pelanggan",
  "退款金额":"Jumlah Pengembalian",
  "店铺销售详情":"Rincian Penjualan Toko",
  "店铺详情":"Rincian Toko",
  "商品销量统计":"Statistik Penjualan Produk",
  "非追加产品主包裹至少保留一个":"Produk non-tambahan setidaknya satu paket utama",

  "批量添加标记":"Menambahkan Tag secara Massal",
  "添加标记":"Tambah Tag",
  "选择标记":"Pilih Tag",
  "shopeeCoinsRedeemed":"Shopee Coins Redeemed"
}
