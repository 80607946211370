var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "process-panel__container",
      style: { width: this.width + "px" },
    },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { name: "base" } },
            [
              _c(
                "div",
                {
                  staticClass: "panel-tab__title",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("i", { staticClass: "el-icon-info" }),
                  _vm._v(_vm._s(_vm.$t("title10339"))),
                ]
              ),
              _c("element-base-info", {
                attrs: {
                  "id-edit-disabled": _vm.idEditDisabled,
                  "business-object": _vm.elementBusinessObject,
                  type: _vm.elementType,
                  model: _vm.model,
                },
              }),
            ],
            1
          ),
          _vm.elementType === "Process"
            ? _c(
                "el-collapse-item",
                { key: "message", attrs: { name: "condition" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "panel-tab__title",
                      attrs: { slot: "title" },
                      slot: "title",
                    },
                    [
                      _c("i", { staticClass: "el-icon-s-comment" }),
                      _vm._v(_vm._s(_vm.$t("title10340"))),
                    ]
                  ),
                  _c("signal-and-massage"),
                ],
                1
              )
            : _vm._e(),
          _vm.conditionFormVisible
            ? _c(
                "el-collapse-item",
                { key: "condition", attrs: { name: "condition" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "panel-tab__title",
                      attrs: { slot: "title" },
                      slot: "title",
                    },
                    [
                      _c("i", { staticClass: "el-icon-s-promotion" }),
                      _vm._v(_vm._s(_vm.$t("title10341"))),
                    ]
                  ),
                  _c("flow-condition", {
                    attrs: {
                      "business-object": _vm.elementBusinessObject,
                      type: _vm.elementType,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.formVisible
            ? _c(
                "el-collapse-item",
                { key: "form", attrs: { name: "condition" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "panel-tab__title",
                      attrs: { slot: "title" },
                      slot: "title",
                    },
                    [
                      _c("i", { staticClass: "el-icon-s-order" }),
                      _vm._v(_vm._s(_vm.$t("title10342"))),
                    ]
                  ),
                  _vm._v(" " + _vm._s(_vm.$t("title10343")) + " "),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        target: "_blank",
                        to: { path: "/bpm/manager/form" },
                      },
                    },
                    [
                      _c("el-link", { attrs: { type: "danger" } }, [
                        _vm._v(_vm._s(_vm.$t("title10344"))),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" " + _vm._s(_vm.$t("title10345")) + " "),
                ],
                1
              )
            : _vm._e(),
          _vm.elementType.indexOf("Task") !== -1
            ? _c(
                "el-collapse-item",
                { key: "task", attrs: { name: "task" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "panel-tab__title",
                      attrs: { slot: "title" },
                      slot: "title",
                    },
                    [
                      _c("i", { staticClass: "el-icon-s-claim" }),
                      _vm._v(_vm._s(_vm.$t("title10346"))),
                    ]
                  ),
                  _c("element-task", {
                    attrs: { id: _vm.elementId, type: _vm.elementType },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.elementType.indexOf("Task") !== -1
            ? _c(
                "el-collapse-item",
                { key: "multiInstance", attrs: { name: "multiInstance" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "panel-tab__title",
                      attrs: { slot: "title" },
                      slot: "title",
                    },
                    [
                      _c("i", { staticClass: "el-icon-s-help" }),
                      _vm._v(_vm._s(_vm.$t("title10347"))),
                    ]
                  ),
                  _c("element-multi-instance", {
                    attrs: {
                      "business-object": _vm.elementBusinessObject,
                      type: _vm.elementType,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-collapse-item",
            { key: "listeners", attrs: { name: "listeners" } },
            [
              _c(
                "div",
                {
                  staticClass: "panel-tab__title",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("i", { staticClass: "el-icon-message-solid" }),
                  _vm._v(_vm._s(_vm.$t("title10348"))),
                ]
              ),
              _c("element-listeners", {
                attrs: { id: _vm.elementId, type: _vm.elementType },
              }),
            ],
            1
          ),
          _vm.elementType === "UserTask"
            ? _c(
                "el-collapse-item",
                { key: "taskListeners", attrs: { name: "taskListeners" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "panel-tab__title",
                      attrs: { slot: "title" },
                      slot: "title",
                    },
                    [
                      _c("i", { staticClass: "el-icon-message-solid" }),
                      _vm._v(_vm._s(_vm.$t("title10349"))),
                    ]
                  ),
                  _c("user-task-listeners", {
                    attrs: { id: _vm.elementId, type: _vm.elementType },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-collapse-item",
            { key: "extensions", attrs: { name: "extensions" } },
            [
              _c(
                "div",
                {
                  staticClass: "panel-tab__title",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("i", { staticClass: "el-icon-circle-plus" }),
                  _vm._v(_vm._s(_vm.$t("title10350"))),
                ]
              ),
              _c("element-properties", {
                attrs: { id: _vm.elementId, type: _vm.elementType },
              }),
            ],
            1
          ),
          _c(
            "el-collapse-item",
            { key: "other", attrs: { name: "other" } },
            [
              _c(
                "div",
                {
                  staticClass: "panel-tab__title",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("i", { staticClass: "el-icon-s-promotion" }),
                  _vm._v(_vm._s(_vm.$t("title10351"))),
                ]
              ),
              _c("element-other-config", { attrs: { id: _vm.elementId } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }