var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-wrapper",
      class: _vm.classObj,
      style: { "--current-color": _vm.theme },
    },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      !_vm.sidebar.hide
        ? _c("sidebar", { staticClass: "sidebar-container" })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "main-container" },
        [
          _c("navbar"),
          _c("div", { class: { "fixed-header": _vm.fixedHeader } }),
          _c("app-main"),
          _c("right-panel", [_c("settings")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }