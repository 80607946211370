<template>
  <div v-if="!item.hidden">
    <template v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <el-menu-item class="sidebar-nav" :index="resolvePath(onlyOneChild.path)">
          <item :icon="onlyOneChild.meta.icon"  />
          <div class="title-text">{{onlyOneChild.meta.title}}</div>
        </el-menu-item>
      </app-link>
    </template>

    <div class="sidebar-nav" :class="{active:$route.path.indexOf(item.path)===0}" v-else @mouseleave="leaveDom" @mouseenter="emitChildren(item.children)" @click="changeShow(item)">
      <item v-if="item.meta" :icon="item.meta && item.meta.icon" :title="item.meta.title" />
    </div>
    <el-submenu v-if="false" :show-timeout="0" :hide-timeout="0" popper-class="sidebar-fixed-sub" ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
      <div class="left-menu" style="height:100%" @mouseleave="closeSub">
        <div class="w-logo"></div>
          <!-- <span v-if="!sidebar.opened">{{item.name}}</span> -->
        <div class="navs">
          <sidebar-item
            v-for="(child, index) in item.children"
            :key="child.path + index"
            :is-nest="true"
            :item="child"
            :base-path="resolvePath(child.path)"
            class="nest-menu"
          />
        </div>
      </div>
    </el-submenu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import path from 'path'
import { isExternal } from '@/utils/validate'
import Item from './Item'
import AppLink from './Link'
import FixiOSBug from './FixiOSBug'

export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  // mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data() {
    this.onlyOneChild = null
    return {
      current:null
    }
  },
  computed:{
    ...mapGetters([ "sidebar"]),
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      if (!children) {
        children = [];
      }
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        // console.log("showingChildren",showingChildren,this.onlyOneChild )
        if (parent.redirect === 'index') {
          return true;
        } else {
          return false
        }
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ... parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    },
    emitChildren(children){
      children.forEach(child=>child.resolvePath=this.resolvePath(child.path))
      this.$emit('changeSub',children)
      const dom=this.$el.getElementsByClassName('sidebar-nav')[0];
      dom.classList.add('click-active');
    },
    leaveDom(){
      const dom=this.$el.getElementsByClassName('sidebar-nav')[0];

      dom.classList.remove('click-active');
    },
    changeShow(item){
      if(!this.current){
        this.$emit('changeShow',true)
      }else{
        this.$emit('changeShow',this.current.id!==item.id)
      }
    }
  }
}
</script>
