<template>
  <div class="panel-tab__content">
    <div class="panel-tab__content--title">
      <span><i class="el-icon-menu" style="margin-right: 8px; color: #555555"></i>{{ $t('title10612') }}</span>
      <el-button size="mini" type="primary" icon="el-icon-plus" @click="openModel('message')">{{ $t('title10613') }}</el-button>
    </div>
    <el-table :data="messageList" size="mini" border>
      <el-table-column type="index" :label="$t('title10508')" width="60px" />
      <el-table-column :label="$t('title10614') + 'ID'" prop="id" max-width="300px" show-overflow-tooltip />
      <el-table-column :label="$t('title10615')" prop="name" max-width="300px" show-overflow-tooltip />
    </el-table>
    <div class="panel-tab__content--title" style="padding-top: 8px; margin-top: 8px; border-top: 1px solid #eeeeee">
      <span><i class="el-icon-menu" style="margin-right: 8px; color: #555555"></i>{{ $t('title10616') }}</span>
      <el-button size="mini" type="primary" icon="el-icon-plus" @click="openModel('signal')">{{ $t('title10617') }}</el-button>
    </div>
    <el-table :data="signalList" size="mini" border>
      <el-table-column type="index" :label="$t('title10508')" width="60px" />
      <el-table-column :label="$t('title10618') + 'ID'" prop="id" max-width="300px" show-overflow-tooltip />
      <el-table-column :label="$t('title10619')" prop="name" max-width="300px" show-overflow-tooltip />
    </el-table>

    <el-dialog :visible.sync="modelVisible" :title="modelConfig.title" :close-on-click-modal="false" width="400px" append-to-body destroy-on-close>
      <el-form :model="modelObjectForm" size="mini" label-width="90px" @submit.native.prevent>
        <el-form-item :label="modelConfig.idLabel">
          <el-input v-model="modelObjectForm.id" clearable />
        </el-form-item>
        <el-form-item :label="modelConfig.nameLabel">
          <el-input v-model="modelObjectForm.name" clearable />
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button size="mini" @click="modelVisible = false">{{ $t('title10532') }} {{ $t('title588') }}</el-button>
        <el-button size="mini" type="primary" @click="addNewObject">{{ $t('title10534') }} {{ $t('title10535') }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "SignalAndMassage",
  data() {
    return {
      signalList: [],
      messageList: [],
      modelVisible: false,
      modelType: "",
      modelObjectForm: {}
    };
  },
  computed: {
    modelConfig() {
      if (this.modelType === "message") {
        return { title: this.$t('title10620'), idLabel: `${this.$t('title10614')}ID`, nameLabel: this.$t('title10615') };
      } else {
        return { title: this.$t('title10621'), idLabel: `${this.$t('title10618')}ID`, nameLabel: this.$t('title10619') };
      }
    }
  },
  mounted() {
    this.initDataList();
  },
  methods: {
    initDataList() {
      this.rootElements = window.bpmnInstances.modeler.getDefinitions().rootElements;
      this.messageIdMap = {};
      this.signalIdMap = {};
      this.messageList = [];
      this.signalList = [];
      this.rootElements.forEach(el => {
        if (el.$type === "bpmn:Message") {
          this.messageIdMap[el.id] = true;
          this.messageList.push({ ...el });
        }
        if (el.$type === "bpmn:Signal") {
          this.signalIdMap[el.id] = true;
          this.signalList.push({ ...el });
        }
      });
    },
    openModel(type) {
      this.modelType = type;
      this.modelObjectForm = {};
      this.modelVisible = true;
    },
    addNewObject() {
      if (this.modelType === "message") {
        if (this.messageIdMap[this.modelObjectForm.id]) {
          return this.$message.error(`${this.$t('title10622')}id后重新保存`);
        }
        const messageRef = window.bpmnInstances.moddle.create("bpmn:Message", this.modelObjectForm);
        this.rootElements.push(messageRef);
      } else {
        if (this.signalIdMap[this.modelObjectForm.id]) {
          return this.$message.error(`${this.$t('title10624')}id后重新保存`);
        }
        const signalRef = window.bpmnInstances.moddle.create("bpmn:Signal", this.modelObjectForm);
        this.rootElements.push(signalRef);
      }
      this.modelVisible = false;
      this.initDataList();
    }
  }
};
</script>
