import request from '@/utils/request'

// 查询公告列表
export function listNotice(query) {
  return request({
    url: '/system/notice/page',
    method: 'get',
    params: query
  })
}

export function listUserNotice(query) {
  return request({
    url: '/system/notice/user-page',
    method: 'get',
    params: query
  })
}

// 查询公告详细
export function getNotice(noticeId) {
  return request({
    url: '/system/notice/get?id=' + noticeId,
    method: 'get'
  })
}

// 新增公告
export function addNotice(data) {
  return request({
    url: '/system/notice/create',
    method: 'post',
    data: data
  })
}

// 修改公告
export function updateNotice(data) {
  return request({
    url: '/system/notice/update',
    method: 'put',
    data: data
  })
}

// 删除公告
export function delNotice(noticeId) {
  return request({
    url: '/system/notice/delete?id=' + noticeId,
    method: 'delete'
  })
}

export function markRead(id) {
  return request({
    url: '/system/notice/mark-read?id='+id,
    method: 'post'
  })
}

export function markReadAll() {
  return request({
    url: '/system/notice/mark-read-all',
    method: 'post'
  })
}

export function getUnReadCnt() {
  return request({
    url: '/system/notice/unread-cnt',
    method: 'get'
  })
}
