var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tags-view-container",
      class: { morep: _vm.showScrollBtn },
      attrs: { id: "tags-view-container" },
    },
    [
      _vm.showScrollBtn
        ? _c("div", { staticClass: "arrow-box left" }, [
            _c("div", {
              staticClass: "arrow el-icon-caret-left",
              on: {
                click: function ($event) {
                  return _vm.moveTag(100)
                },
              },
            }),
          ])
        : _vm._e(),
      _vm.showScrollBtn
        ? _c("div", { staticClass: "arrow-box right" }, [
            _c("div", {
              staticClass: "arrow el-icon-caret-right",
              on: {
                click: function ($event) {
                  return _vm.moveTag(-100)
                },
              },
            }),
          ])
        : _vm._e(),
      _c(
        "scroll-pane",
        {
          ref: "scrollPane",
          staticClass: "tags-view-wrapper",
          attrs: { visitedViews: _vm.visitedViews },
          on: { scroll: _vm.handleScroll },
        },
        [
          _c(
            "div",
            { staticClass: "scroll-box" },
            _vm._l(_vm.visitedViews, function (tag) {
              return _c(
                "router-link",
                {
                  key: tag.path,
                  ref: "tag",
                  refInFor: true,
                  staticClass: "tags-view-item",
                  class: _vm.isActive(tag) ? "active" : "",
                  attrs: {
                    to: {
                      path: tag.path,
                      query: tag.query,
                      fullPath: tag.fullPath,
                    },
                    tag: "span",
                  },
                  nativeOn: {
                    mouseup: function ($event) {
                      if ("button" in $event && $event.button !== 1) {
                        return null
                      }
                      !_vm.isAffix(tag) ? _vm.closeSelectedTag(tag) : ""
                    },
                    contextmenu: function ($event) {
                      $event.preventDefault()
                      return _vm.openMenu(tag, $event)
                    },
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("menu." + tag.title)) + " "),
                  !_vm.isAffix(tag)
                    ? _c("span", {
                        staticClass: "el-icon-close",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.closeSelectedTag(tag)
                          },
                        },
                      })
                    : _vm._e(),
                ]
              )
            }),
            1
          ),
        ]
      ),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          staticClass: "contextmenu",
          style: { left: _vm.left + "px", top: _vm.top + "px" },
        },
        [
          _c(
            "li",
            {
              on: {
                click: function ($event) {
                  return _vm.refreshSelectedTag(_vm.selectedTag)
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-refresh-right" }),
              _vm._v(" " + _vm._s(_vm.$t("title10300"))),
            ]
          ),
          !_vm.isAffix(_vm.selectedTag)
            ? _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeSelectedTag(_vm.selectedTag)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-close" }),
                  _vm._v(" " + _vm._s(_vm.$t("title10301"))),
                ]
              )
            : _vm._e(),
          _c("li", { on: { click: _vm.closeOthersTags } }, [
            _c("i", { staticClass: "el-icon-circle-close" }),
            _vm._v(" " + _vm._s(_vm.$t("title10302"))),
          ]),
          !_vm.isFirstView()
            ? _c("li", { on: { click: _vm.closeLeftTags } }, [
                _c("i", { staticClass: "el-icon-back" }),
                _vm._v(" " + _vm._s(_vm.$t("title10303"))),
              ])
            : _vm._e(),
          !_vm.isLastView()
            ? _c("li", { on: { click: _vm.closeRightTags } }, [
                _c("i", { staticClass: "el-icon-right" }),
                _vm._v(" " + _vm._s(_vm.$t("title10304"))),
              ])
            : _vm._e(),
          _c(
            "li",
            {
              on: {
                click: function ($event) {
                  return _vm.closeAllTags(_vm.selectedTag)
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _vm._v(" " + _vm._s(_vm.$t("title10305"))),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }