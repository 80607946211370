import request from '@/utils/request'

export default {
  getKolAuth(params) {
    return request({
      url: '/crm/kol-auth/get-kol-auth',
      method: 'get',
      params
    })
  },
  update(data) {
    return request({
      url: '/crm/kol-auth/update',
      method: 'put',
      data
    })
  },
}