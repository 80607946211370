var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "16px" } },
    [
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("title10566") } },
        [
          _c("el-input", {
            attrs: { clearable: "" },
            on: {
              input: function ($event) {
                return _vm.updateElementTask()
              },
              change: function ($event) {
                return _vm.updateElementTask()
              },
            },
            model: {
              value: _vm.scriptTaskForm.scriptFormat,
              callback: function ($$v) {
                _vm.$set(_vm.scriptTaskForm, "scriptFormat", $$v)
              },
              expression: "scriptTaskForm.scriptFormat",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("title10557") } },
        [
          _c(
            "el-select",
            {
              model: {
                value: _vm.scriptTaskForm.scriptType,
                callback: function ($$v) {
                  _vm.$set(_vm.scriptTaskForm, "scriptType", $$v)
                },
                expression: "scriptTaskForm.scriptType",
              },
            },
            [
              _c("el-option", {
                attrs: { label: _vm.$t("title10558"), value: "inline" },
              }),
              _c("el-option", {
                attrs: { label: _vm.$t("title10816"), value: "external" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.scriptTaskForm.scriptType === "inline",
              expression: "scriptTaskForm.scriptType === 'inline'",
            },
          ],
          attrs: { label: _vm.$t("title10555") },
        },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              resize: "vertical",
              autosize: { minRows: 2, maxRows: 4 },
              clearable: "",
            },
            on: {
              input: function ($event) {
                return _vm.updateElementTask()
              },
              change: function ($event) {
                return _vm.updateElementTask()
              },
            },
            model: {
              value: _vm.scriptTaskForm.script,
              callback: function ($$v) {
                _vm.$set(_vm.scriptTaskForm, "script", $$v)
              },
              expression: "scriptTaskForm.script",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.scriptTaskForm.scriptType === "external",
              expression: "scriptTaskForm.scriptType === 'external'",
            },
          ],
          attrs: { label: _vm.$t("title10560") },
        },
        [
          _c("el-input", {
            attrs: { clearable: "" },
            on: {
              input: function ($event) {
                return _vm.updateElementTask()
              },
              change: function ($event) {
                return _vm.updateElementTask()
              },
            },
            model: {
              value: _vm.scriptTaskForm.resource,
              callback: function ($$v) {
                _vm.$set(_vm.scriptTaskForm, "resource", $$v)
              },
              expression: "scriptTaskForm.resource",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("title10794") } },
        [
          _c("el-input", {
            attrs: { clearable: "" },
            on: {
              input: function ($event) {
                return _vm.updateElementTask()
              },
              change: function ($event) {
                return _vm.updateElementTask()
              },
            },
            model: {
              value: _vm.scriptTaskForm.resultVariable,
              callback: function ($$v) {
                _vm.$set(_vm.scriptTaskForm, "resultVariable", $$v)
              },
              expression: "scriptTaskForm.resultVariable",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }