// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/empty.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.no-golbal-table .el-table__cell {\n  border-right: none\n}\n.no-golbal-table .el-table--group, .el-table--border {\n  border: none\n}\n.no-golbal-table .is-group tr:last-child th {\n  border-top: 1px solid #D3D3D3 !important;\n}\n.no-golbal-table .is-group tr:last-child th:first-child {\n  border-radius: 0\n}\n.el-table th.el-table__cell > .cell{\n  color: #666666;\n}\n.table-empty{\n  padding-top: 105px;\n  background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat top 50px center;\n  background-size: 100px;\n  color:#999;\n}\n", ""]);
// Exports
module.exports = exports;
