import request from "@/utils/request";

export default {
  // 获得达人库分页
  getPage(data) {
    return request({
      url: "/crm/kol/page",
      method: "post",
      data,
    });
  },
  getNaturePage(params) {
    return request({
      url: "/crm/kol-team-sea/nature-page",
      method: "get",
      params,
    });
  },
  // 带货商品分类
  getCategories() {
    return request({
      url: "/crm/kol/product-categories",
      method: "get",
    });
  },
  // 跟进
  followUpUser(data) {
    return request({
      url: "/crm/kol/follow-up-user",
      method: "post",
      data,
    });
  },
  // 批量跟进
  batchFollowUpUser(data) {
    return request({
      url: "/crm/kol/batch-follow-up-user",
      method: "post",
      data,
    });
  },
  // 分配
  assignUser(data) {
    return request({
      url: "/crm/kol/assign-user",
      method: "post",
      data,
    });
  },
  // 批量分配
  batchAssignUser(data) {
    return request({
      url: "/crm/kol/batch-assign-user",
      method: "post",
      data,
    });
  },
  // 收录达人
  create(data) {
    return request({
      url: "/crm/include-task/create",
      method: "post",
      data,
    });
  },
  // 收录达人查询
  // /kol/search-kol?uidOrLink=prawpim8999
  createKol(params) {
    return request({
      url: "/crm/kol/search-kol",
      method: "get",
      params,
    })
  },
  // 获取分配员工列表
  getUserList(params) {
    return request({
      url: "/crm/kol/get-user-page",
      method: "get",
      params,
    });
  },
  // 获取所有分配员工列表（去掉权限）
  getUserAllList(params) {
    return request({
      url: "/crm/kol/get-user-all-page",
      method: "get",
      params,
    });
  },
  // 获取达人分类列表
  getKolCategories(params) {
    return request({
      url: "/crm/kol/kol-categories",
      method: "get",
      params,
    });
  },
  //获取达人库
  getMasterInfo(id) {
    return request({
      url: "/crm/kol/get",
      method: "get",
      params: {
        id,
      },
    });
  },
  //获取达人信息统计
  getKolStatistic(params) {
    return request({
      url: "/crm/kol-order/getKolStatistic",
      method: "get",
      params,
    });
  },
  //获取达人商品列表
  getKolProducts(params) {
    return request({
      url: "/crm/kol-collaboration-product/page-by-kol",
      method: "get",
      params,
    });
  },
  //获取达人订单列表
  getKolOrders(params) {
    return request({
      url: "/crm/kol-order/orderPage",
      method: "get",
      params,
    });
  },
  //获取达人样品订单列表
  getKolSample(params) {
    return request({
      url: "/crm/kol-sample-order/samplePage",
      method: "get",
      params,
    });
  },
  //获取达人合作单列表
  getKolCollaboration(params) {
    return request({
      url: "/crm/kol-collaboration/page",
      method: "get",
      params,
    });
  },
  //获取达人视频/直播列表
  getKolVedioLive(params) {
    return request({
      url: "/crm/kol-livestream-video-data/page",
      method: "get",
      params,
    });
  },
  //获取达人更多联系方式
  getKolMoreConcat(params) {
    return request({
      url: "/crm/kol/get-contact",
      method: "get",
      params,
    });
  },
  //获取达人状态
  getKolStatus(params) {
    return request({
      url: "/crm/kol-claim/get-kol-status",
      method: "get",
      params,
    });
  },
  //获取达人橱窗商品
  getKolShowCaseProduct(params) {
    return request({
      url: "/crm/showcase-product/page",
      method: "get",
      params,
    });
  },
  //达人合作列表分页
  pageByProduct(params){
    return request({
      url: "/crm/kol-collaboration-product/page-by-product",
      method: "get",
      params,
    });
  },
  //获得商品信息统计
  getProductStatistic(params) {
    return request({
      url: "/crm/kol-order/getProductStatistic",
      method: "get",
      params,
    });
  },
    //获得商品链接库统计
  getProductLinkStatistic(params){
    return request({
      url: "/crm/kol-sample-order/getProductLinkStatistic",
      method: "get",
      params,
    });
  },
  // 达人广场导出
  exportExcel(params) {
    return request({
      url: '/crm/kol/export-excel',
      method: 'get',
      params,
      responseType: 'blob',
      timeout: 600000
    })
  },
  // 下载批量录入模版
  downloadInputTemp() {
    return request({
      url: '/crm/kol/download-kol-tpl',
      method: 'get',
      responseType: 'blob',
      timeout: 600000
    })
  },
  // 批量录入
  importInputTemp(formData) {
    return request({
      url: '/crm/kol/import-kol',
      method: 'post',
      data: formData,
      timeout: 600000
    })
  },
  //下载达人建联模板
  downloadMyKolContactTpl(){
    return request({
      url: '/crm/kol/download-my-kol-contact-tpl',
      method: 'get',
      responseType: 'blob',
      timeout: 600000
    })
  },
  //导入我的达人建联
  importMyKolContact(){
    return request({
      url: '/crm/kol/import-my-kol-contact',
      method: 'post',
      data: formData,
      timeout: 600000
    })
  },
  //更新达人收录任务
  update_info(uidOrLink){
    return request({
      url: "crm/include-task/update-info",
      method: "post",
      data:{
        uidOrLink
      }
    });
  },
};
