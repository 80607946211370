<template>
  <el-menu
      :default-active="activeMenu"
      mode="horizontal"
      @select="handleSelect"
      class="top-nav"
      router
      active-text-color="#1E64C8"
  >
    <!-- <template v-for="(item, index) in topMenus">
      <el-menu-item :style="{'--theme': theme}" :index="item.path" :key="index" v-if="index < visibleNumber"
      ><svg-icon :icon-class="item.meta.icon" />
        {{ item.meta.title }}</el-menu-item
      >
    </template> -->

    <el-submenu v-for="(item, index) in topMenus" v-if="index < visibleNumber" :index="item.path" :key="index">
      <template slot="title">
        <div @click="hand(item)">
          <svg-icon :icon-class="item.meta.icon" />
          {{ item.meta.title }}
        </div>
      </template>
      <el-menu-item
        :index="route.path"
        :key="index+'-'+i"
        v-for="(route,i) in item.children"
      ><svg-icon :icon-class="route.meta.icon" />
        {{ route.meta.title }}
        </el-menu-item>
    </el-submenu>

    <!-- 顶部菜单超出数量折叠 -->
    <el-submenu :style="{'--theme': theme}" index="more" v-if="topMenus.length > visibleNumber">
      <template slot="title">{{ $t('title10188') }}</template>
      <el-submenu v-for="(item, index) in topMenus" v-if="index >= visibleNumber" :index="item.path" :key="index">
        <template slot="title">
          <svg-icon :icon-class="item.meta.icon" />
          {{ item.meta.title }}
        </template>
        <el-menu-item
          :index="route.path"
          :key="index+'-'+i"
          v-for="(route,i) in item.children"
        ><svg-icon :icon-class="route.meta.icon" />
          {{ route.meta.title }}
          </el-menu-item>
      </el-submenu>
      <!-- <template v-for="(item, index) in topMenus">
        <el-menu-item
            :index="item.path"
            :key="index"
            v-if="index >= visibleNumber"
        ><svg-icon :icon-class="item.meta.icon" />
          {{ item.meta.title }}
        </el-menu-item>
      </template> -->
    </el-submenu>
  </el-menu>
</template>

<script>
import { constantRoutes } from "@/router";

// 隐藏侧边栏路由
const hideList = ['/index', '/user/profile'];

export default {
  data() {
    return {
      // 顶部栏初始数
      visibleNumber: 20,
      // 当前激活菜单的 index
      currentIndex: 0
    };
  },
  computed: {
    theme() {
      return this.$store.state.settings.theme;
    },
    // 顶部显示菜单
    topMenus() {
      let topMenus = [];
      this.routers.map((menu) => {
        if (menu.hidden !== true) {
          // 兼容顶部栏一级菜单内部跳转
          if (menu.path === "/") {
            topMenus.push(menu.children[0]);
          } else {
            topMenus.push(menu);
          }
        }
      });
      return topMenus;
    },
    // 所有的路由信息
    routers() {
      return this.$store.state.permission.topbarRouters;
    },
    // 设置子路由
    childrenMenus() {
      var childrenMenus = [];
      this.routers.map((router) => {
        for (var item in router.children) {
          if (router.children[item].parentPath === undefined) {
            if(router.path === "/") {
              router.children[item].path = "/" + router.children[item].path;
            } else {
              if(!this.ishttp(router.children[item].path)) {
                router.children[item].path = router.path + "/" + router.children[item].path;
              }
            }
            router.children[item].parentPath = router.path;
          }
          childrenMenus.push(router.children[item]);
        }
      });
      return constantRoutes.concat(childrenMenus);
    },
    // 默认激活的菜单
    activeMenu() {
      const path = this.$route.path;
      let activePath = path;
      if (path !== undefined && path.lastIndexOf("/") > 0 && hideList.indexOf(path) === -1) {
        const tmpPath = path.substring(1, path.length);
        activePath = "/" + tmpPath.substring(0, tmpPath.indexOf("/"));
        // this.$store.dispatch('app/toggleSideBarHide', false);
      } else if(!this.$route.children) {
        activePath = path;
        this.$store.dispatch('app/toggleSideBarHide', true);
      }
      this.activeRoutes(activePath);
      return activePath;
    },
  },
  beforeMount() {
    window.addEventListener('resize', this.setVisibleNumber)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setVisibleNumber)
  },
  mounted() {
    this.setVisibleNumber();
  },
  methods: {
    hand(item){
      if(item.children&&item.children.length>0){
        const _act=item.children[0];
        this.handleSelect(_act.path,[_act.parentPath,_act.path])
      }
    },
    // 根据宽度计算设置显示栏数
    setVisibleNumber() {
      const width = document.body.getBoundingClientRect().width-700;
      this.visibleNumber = Math.floor(width / 95);
    },
    // 菜单选择事件
    handleSelect(key, keyPath) {
      // console.log('key',key,keyPath)
      this.currentIndex = key;
      const route = this.routers.find(item => item.path === key);
      if (this.ishttp(key)) {
        // http(s):// 路径新窗口打开
        window.open(key, "_blank");
      } else if (!route || !route.children) {
        // 没有子路由路径内部打开
        this.$router.push({ path: key });
        this.$store.dispatch('app/toggleSideBarHide', true);
      } else {
        // 显示左侧联动菜单
        this.activeRoutes(key);
        // this.$store.dispatch('app/toggleSideBarHide', false);
      }
    },
    // 当前激活的路由
    activeRoutes(key) {
      var routes = [];
      if (this.childrenMenus && this.childrenMenus.length > 0) {
        this.childrenMenus.map((item) => {
          if (key == item.parentPath || (key == "index" && "" == item.path)) {
            routes.push(item);
          }
        });
      }
      if(routes.length > 0) {
        this.$store.commit("SET_SIDEBAR_ROUTERS", routes);
      }
    },
    ishttp(url) {
      return url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1
    }
  },
};
</script>

<style lang="scss">
.topmenu-container.el-menu--horizontal{
  flex:1;
  border:0;
	padding-left:25px;
	border-left:1px solid #e7e7e7;
  margin-left: 20px;
	.el-menu-item .svg-icon{
		vertical-align:-0.15em;
	}
  .el-menu-item,.el-submenu .el-submenu__title {
    font-size:14px;
    height: 30px;
    line-height: 30px;
    color:#333;
    padding: 0 5px;
    margin-right:20px;
    border-radius:2px;
    border:0;
    .el-submenu__icon-arrow{
      display:none;
    }
    .svg-icon{
      font-size: 12px;
      vertical-align: -1px;
    }
    &.is-active{
      // color:#1E64C8;
    }
  }
}

</style>
