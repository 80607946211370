<template>
  <div>
    <!-- <div class="el-icon-coin" @click="openDialog" ref="trigger"></div> -->
    <el-tooltip class="mission-item" effect="dark" :content="$t('title15243')" placement="top-start" >
      <div class="btn" v-if="!isHideTask" @click="openDialog" ref="trigger">
        <img src="@/assets/images/control-task.png" style="width: 20px;height: 20px;"/>
      </div>
    </el-tooltip>
    <el-dialog
      v-show="visible"
      ref="dialog"
      :visible="visible"
      title="任务中心"
      width="1000px"
      :showClose="true"
      @open="handleOpen"
      :before-close="handleClose"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="收录任务" name="include" v-if="isInclude">
          <div style="height: calc(100vh - 500px + 47px)">
            <my-table
              class="missionTable"
              id="myTable"
              ref="myTable"
              :loading="loading1"
              :defaultCol="tableCol2"
              :tableData="include.data"
            >
            </my-table>
          </div>
          <pagination
            :total="include.total"
            layout="total, prev, pager, next"
            :page="include.page"
            :pageSize="include.pageSize"
            @pagination="incluede_pagenation"
          ></pagination>
        </el-tab-pane>
        <el-tab-pane label="导入任务" name="import" v-if="isImport">
          <div style="margin-bottom: 15px">
            <el-select
              clearable
              v-model="taskModule"
              placeholder="任务模块"
              style="width: 130px; margin-right: 10px"
              @change="getImportTask"
            >
              <el-option
                v-for="item in taskDicts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-select
              clearable
              v-model="user"
              placeholder="操作人"
              style="width: 100px"
              @change="getImportTask"
            >
              <el-option
                v-for="item in userList"
                :key="item.userId"
                :label="item.userName"
                :value="item.userId"
              ></el-option>
            </el-select>
          </div>
          <div style="height: calc(100vh - 500px)">
            <my-table
              class="missionTable"
              id="myTable"
              ref="myTable"
              :tableData="imports.data"
              :defaultCol="tableCol1"
              :loading="loading2"
            >
            </my-table>
          </div>
          <pagination
            :total="imports.total"
            layout="total, prev, pager, next"
            :page="imports.page"
            :pageSize="imports.pageSize"
            @pagination="imports_pagenation"
          ></pagination>
        </el-tab-pane>
      </el-tabs>
      <template slot="footer">
        <el-button size="small" style="width: 80px" @click="handleClose">{{
          $t("title650")
        }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import ClipboardJS from "clipboard";
import Api from "@/api/crm/includeTask.js";
import kolApi from "@/api/crm/kolApi";
import MyTable from "@/components/MyTable";
import { importCol, includeCol } from "./col";
export default {
  props:{
    isInclude:{
      type:Boolean,
      default:true
    },
    isImport:{
      type:Boolean,
      default:true
    },
    isHideTask:{
      type:Boolean,
      default:false
    },
  },
  data: function () {
    return {
      outSide_id:null,
      originX: 0,
      originY: 0,
      visible: false,
      activeName: "include",
      taskModule: null,
      user: "",
      loading1: false,
      loading2: false,
      include: {
        page: 1,
        pageSize: 20,
        data: [],
        total: 0,
      },
      imports: {
        page: 1,
        pageSize: 20,
        data: [],
        total: 0,
      },
      taskDicts: [],
      userList: [],
      country: {
        BR: {
          img: require("@/assets/images/country/BR.png"),
          name: this.$t("title675"),
        },
        TW: {
          img: require("@/assets/images/country/CN.png"),
          name: this.$t("title492"),
        },
        ID: {
          img: require("@/assets/images/country/ID.png"),
          name: this.$t("title486"),
        },
        MX: {
          img: require("@/assets/images/country/MX.png"),
          name: this.$t("title676"),
        },
        MY: {
          img: require("@/assets/images/country/MY.png"),
          name: this.$t("title490"),
        },
        PH: {
          img: require("@/assets/images/country/PH.png"),
          name: this.$t("title487"),
        },
        SG: {
          img: require("@/assets/images/country/SG.png"),
          name: this.$t("title491"),
        },
        TH: {
          img: require("@/assets/images/country/TH.png"),
          name: this.$t("title488"),
        },
        VN: {
          img: require("@/assets/images/country/VN.png"),
          name: this.$t("title489"),
        },
      },
    };
  },
  components: {
    MyTable,
  },
  watch:{
    isInclude:{
      handler(val) {
      	if(val) this.activeName = 'include'
      },
      immediate: true,
    },
    isImport:{
      handler(val) {
      	if(val && !this.isInclude) this.activeName = 'import'
      },
      immediate: true,
    }
  },
  computed: {
    tableCol1() {
      let arr = [];
      const act = {
        label: this.$t("title575"),
        isFixed: "right",
        render: (h, row) => {
          let str;
          if (row.error) {
            str = <span>{row.error}</span>;
          } else if (!row.error && row.errorUrl) {
            str = (
              <span
                style="color:#1377FF;cursor:pointer"
                onClick={this.downError.bind(this, row)}
              >
                下载错误数据
              </span>
            );
          } else {
            str = <span>-</span>;
          }
          return <div>{str}</div>;
        },
      };
      let col = importCol(this);
      arr = [...col, act];
      return arr;
    },
    tableCol2() {
      let col = includeCol(this);
      return col;
    },
  },
  created() {},
  mounted() {
    if(this.isHideTask) return
    if(this.activeName == 'include'){
      this.getIncludeTask();
    }else{
      this.getImportTask();
    }
    this.getUserList();
    this.taskDicts = this.getDictDatas(this.DICT_TYPE.CRM_IMPORT_TASK);
  },
  destroy() {
    if (this.timer) clearInterval(this.timer);
  },
  methods: {
    openDialog(data) {
      if(this.visible){
        this.outSide_id = data.id
        this.handleClick();
        return
      }
      const rect = this.$store.state.missionCenter.$el.getBoundingClientRect()
      this.originX = rect.left;
      this.originY = rect.top;
      // 获取点击按钮位置
      const dialog = this.$refs.dialog.$el;
      // 设置 transform-origin 为点击位置
      dialog.style.transition = 'none';
      dialog.style.transformOrigin = `${this.originX}px ${this.originY}px`;
      dialog.style.transform = 'scale(0)';
      this.visible = true;
      if(data){
        this.outSide_id = data.id
      }
      this.handleClick();
    },
    handleOpen(){
      const dialog = this.$refs.dialog.$el;
      this.$nextTick(() => {
        dialog.style.transition = 'all 0.5s ease';
        dialog.style.transform = 'scale(1)';
      });
    },
    handleClose() {
      // 执行飞出动画后关闭对话框
      const dialog = this.$refs.dialog.$el;
      dialog.style.transition = 'all 0.5s ease';
      dialog.style.transformOrigin = `${this.originX}px ${this.originY}px`;
      dialog.style.transform = 'scale(0)';
      
      setTimeout(() => {
        this.visible = false
        this.outSide_id = null
      }, 450);
      if (this.timer) clearInterval(this.timer);
    },
    async getUserList() {
      const { data } = await kolApi.getUserList({ pageSize: 100, pageNo: 1 });
      this.userList = data.list;
    },
    handleClick() {
      if(this.outSide_id){
        this.activeName = 'import'
      }
      if (this.activeName == "include") {
        if (this.timer) clearInterval(this.timer);
        this.getIncludeTask();
      } else {
        this.getImportTask();
        this.timer = setInterval(() => {
          this.getImportTask("interval");
        }, 2000);
      }
    },
    incluede_pagenation(obj) {
      this.include.page = obj.page;
      // this.include.pageSize = obj.pageSize
      this.getIncludeTask();
    },
    imports_pagenation(obj) {
      clearInterval(this.timer);
      this.imports.page = obj.page;
      // this.imports.pageSize = obj.pageSize
      this.getImportTask();
      this.timer = setInterval(() => {
        this.getImportTask("interval");
      }, 2000);
    },
    //获取收录任务
    async getIncludeTask() {
      this.loading1 = true;
      let res = await Api.getIncludeTask({
        pageNo: this.include.page,
        pageSize: this.include.pageSize,
      });
      const { list, total } = res.data;
      this.include.data = list;
      this.include.total = total;
      this.loading1 = false;
    },
    //获取导入任务
    async getImportTask(flag) {
      if (flag !== 'interval') this.loading2 = true;
      let obj = {
        pageNo: this.imports.page,
        pageSize: this.imports.pageSize,
        importTaskKey: this.taskModule || null,
        userId: this.user || null,
        type: 2,
        id:this.outSide_id
      }
      let res = await Api.getImportTask(obj);
      const { list, total } = res.data;
      this.imports.data = list;
      this.imports.total = total;
      if (!flag) this.loading2 = false;
    },
    //下载错误信息
    async downError(row) {
      let _this = this;
      let res = await Api.downError(row.errorUrl);
      let obj = res;
      var reader = new FileReader();
      reader.readAsText(obj, "utf-8");
      reader.onload = function () {
        try {
          obj = JSON.parse(reader.result);
          if (obj.msg) {
            _this.getImportTask();
            _this.$modal.msgError(obj.msg);
          }
        } catch (error) {
          _this.$download.excel(res, "错误信息.xlsx");
        }
      };
    },
    copy(info, key) {
      const clipboard = new ClipboardJS(`#${key}${info.id}`, {
        text: () => info[key],
      });
      clipboard.on("success", (e) => {
        this.$modal.msgSuccess(this.$t("title541"));
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        this.$modal.msgError(this.$t("title1046"));
        clipboard.destroy();
      });
    },
    navigatorCopy(txt) {
      navigator.clipboard
        .writeText(txt)
        .then(() => {
          this.$modal.msgSuccess(this.$t("title541"));
        })
        .catch(() => {
          this.$modal.msgError(this.$t("title1046"));
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__wrapper {
  animation: none !important;
}
::v-deep .el-tabs__nav-wrap{
  border-bottom: 1px solid #f4f7fb;
}
:v-deep .el-dialog {
  transition: none !important;
}
::v-deep .el-tabs__header {
  margin: 0 0 20px;
}
::v-deep .el-tabs__nav-scroll {
  padding-left: 20px;
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid #f4f7fb;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
  background-color: #f4f7fb;
}
::v-deep .el-table th.el-table__cell > .cell {
  padding-left: 20px;
}
::v-deep .el-table .cell {
  padding-left: 20px;
}
::v-deep .el-input__inner {
  height: 32px;
  line-height: 32px;
  border-color: rgba(0, 0, 0, 0.06);
  &::placeholder {
    color: #666666;
  }
}
::v-deep .el-progress {
  padding-top: 6px !important;
}
::v-deep .el-progress-bar__outer {
  height: 4px !important;
}
::v-deep .el-progress__text {
  font-size: 12px !important;
}
.pagination-container {
  padding: 10px 0 0 0 !important;
}
</style>
<style lang="scss">
.kolName:hover {
  color: #1377ff !important;
}
.missionTable .el-table__header-wrapper th:first-child,
.my-table.white-bg .el-table__fixed-header-wrapper th:first-child,
.el-table.white-bg .el-table__header-wrapper th:first-child,
.el-table.white-bg .el-table__fixed-header-wrapper th:first-child {
  border-radius: 4px 0 0 4px;
}
.mission-item{
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.mission-item:hover{
  background: #f2f2f2;
}
</style>
