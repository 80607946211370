var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "myDialog",
        {
          attrs: {
            width: "860px",
            title: " ",
            modal: "",
            modalAppendToBody: false,
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex-col",
              staticStyle: { "align-items": "center", "font-size": "14px" },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "font-weight": "600",
                    color: "#333",
                    "margin-bottom": "10px",
                  },
                },
                [_vm._v(_vm._s(_vm.title))]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "font-weight": "600",
                    color: "#999",
                    "margin-bottom": "20px",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("title10174")))]
              ),
              _c("div", { staticClass: "flex-row" }, [
                _c(
                  "div",
                  {
                    staticClass: "imgView flex-col",
                    staticStyle: {
                      "align-items": "center",
                      "margin-right": "20px",
                    },
                  },
                  [
                    _c("img", { attrs: { src: _vm.banner1 } }),
                    _c("span", { staticClass: "btn" }, [
                      _vm._v(_vm._s(_vm.$t("title10175")) + " >"),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "imgView flex-col",
                    staticStyle: { "align-items": "center" },
                  },
                  [
                    _c("img", { attrs: { src: _vm.banner2 } }),
                    _c(
                      "span",
                      { staticClass: "btn", on: { click: _vm.open } },
                      [_vm._v(_vm._s(_vm.$t("title10176")) + " >")]
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submit },
                },
                [_vm._v(_vm._s(_vm.$t("title10177")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }