import {login, logout, getInfo, socialLogin, socialBindLogin, smsLogin} from '@/api/login'
import {getMyPackage} from "@/api/bi";
import { getTenantInfo } from "@/api/system/tenant";
import {setToken, removeToken, removeTenantId, isRunas} from '@/utils/auth'
import { getCurrency } from "@/api/erp/testOrderParam"
import { getShowField } from "@/api/system/tenant"
import { shopAdAuth } from "@/api/erp/shop"
import symbolJSON from '@/utils/symbol.json'
const user = {
  state: {
    id: 0, // 用户编号
    name: '',
    avatar: '',
    roles: [],
    permissions: [],
    currency: 'CNY',
    currencySymbol: '￥',
    myPackage:{},
    initialState:{
      initialCost:'false',
      finalCost:'false'
    },
    isShopAdAuth:false,
    showHelp: {
      show: false,
      value: ''
    }
  },

  mutations: {
    SET_ID: (state, id) => {
      state.id = id
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_NICKNAME: (state, nickname) => {
      state.nickname = nickname
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_CURRENCY: (state, currency) => {
      localStorage.setItem('currency', currency)
      state.currency = currency
    },
    SET_MYPACKAGE: (state, _package) => {
      state.myPackage = _package
    },
    SET_CURRENCY_SYMBOL: (state, currencySymbol) => {
      localStorage.setItem('currencySymbol', currencySymbol)
      state.currencySymbol = currencySymbol
    },
    setShopHelp: (state, showHelp) => {
      state.showHelp = showHelp
    },
    SET_INITIALCOST: (state, data) => {
      if(data){
        state.initialState.initialCost = data.initialCost||'false'
        state.initialState.finalCost = data.finalCost||'false'
      }
    },
    SET_SHOPADAUTH: (state, bool) => {
      state.isShopAdAuth=bool
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim().split('@')[0];
      const password = userInfo.password
      const captchaVerification = userInfo.captchaVerification
      const socialCode = userInfo.socialCode
      const socialState = userInfo.socialState
      const socialType = userInfo.socialType
      return new Promise((resolve, reject) => {
        login(username, password, captchaVerification, socialType, socialCode, socialState).then(res => {
          res = res.data;
          // 设置 token
          setToken(res)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 社交登录
    SocialLogin({ commit }, userInfo) {
      const code = userInfo.code
      const state = userInfo.state
      const type = userInfo.type
      return new Promise((resolve, reject) => {
        socialLogin(type, code, state).then(res => {
          res = res.data;
          // 设置 token
          setToken(res)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 我的套餐
    setMyPackage({ commit }) {
      getMyPackage().then(res => {
        commit('SET_MYPACKAGE', res.data)
      })
    },
    loadInitialCostState({ commit }) {
      getShowField().then(res => {
        commit('SET_INITIALCOST', res.data)
      })
    },
    getShopAdAuth({ commit }) {
      shopAdAuth().then(res => {
        commit('SET_SHOPADAUTH', res.data)
      })
    },

    // 短信登录
    SmsLogin({ commit }, userInfo) {
      const mobile = userInfo.mobile.trim()
      const mobileCode = userInfo.mobileCode
      return new Promise((resolve, reject) => {
        smsLogin(mobile,mobileCode).then(res => {
          res = res.data;
          // 设置 token
          setToken(res)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          // 没有 data 数据，赋予个默认值
          if (!res) {
            res = {
              data: {
                roles: [],
                user: {
                  id: '',
                  avatar: '',
                  userName: '',
                  nickname: ''
                }
              }
            }
          }

          res = res.data; // 读取 data 数据
          const user = res.user
          const avatar = ( user.avatar === "" || user.avatar == null ) ? require("@/assets/images/profile.jpg") : user.avatar;
          if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', res.roles)
            commit('SET_PERMISSIONS', res.permissions)
          } else {
            commit('SET_ROLES', ['ROLE_DEFAULT'])
          }
          commit('SET_ID', user.id)
          commit('SET_NAME', user.userName)
          commit('SET_NICKNAME', user.nickname)
          commit('SET_AVATAR', avatar)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 判断是否设置地区
    isTenantInfo({ commit }) {
      return new Promise(async (resolve, reject) => {
        const isCheckOver = localStorage.getItem('checkOver')
        if (isCheckOver) {
          resolve()
        }
        const { data } = await getTenantInfo()
        const url = window.location.href
        if (!data.localRegion && (!url.includes('erp.xinjianerp.com') && !url.includes('localhost') && !url.includes('erptest.chainfurther.com') && !url.includes('192.168') && !url.includes('bi.chainfurther.com'))) {
          reject(new Error(false))
        } else {
          if (data.localRegion) {
            const res = await getCurrency({
              region: data.localRegion
            })
            localStorage.setItem('checkOver', 1)
            commit('SET_CURRENCY', res.data)
            commit('SET_CURRENCY_SYMBOL', symbolJSON[res.data])
          }
          resolve()
        }
      })
    },
    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(isRunas()).then(() => {
          commit('SET_ROLES', [])
          commit('SET_PERMISSIONS', [])
          removeTenantId()
          removeToken()
          localStorage.removeItem('checkOver')
          localStorage.removeItem('currency')
          localStorage.removeItem('currencySymbol')
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },


  }
}

export default user
