var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu",
    {
      staticClass: "top-nav",
      attrs: {
        "default-active": _vm.activeMenu,
        mode: "horizontal",
        router: "",
        "active-text-color": "#1E64C8",
      },
      on: { select: _vm.handleSelect },
    },
    [
      _vm._l(_vm.topMenus, function (item, index) {
        return index < _vm.visibleNumber
          ? _c(
              "el-submenu",
              { key: index, attrs: { index: item.path } },
              [
                _c("template", { slot: "title" }, [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.hand(item)
                        },
                      },
                    },
                    [
                      _c("svg-icon", {
                        attrs: { "icon-class": item.meta.icon },
                      }),
                      _vm._v(" " + _vm._s(item.meta.title) + " "),
                    ],
                    1
                  ),
                ]),
                _vm._l(item.children, function (route, i) {
                  return _c(
                    "el-menu-item",
                    { key: index + "-" + i, attrs: { index: route.path } },
                    [
                      _c("svg-icon", {
                        attrs: { "icon-class": route.meta.icon },
                      }),
                      _vm._v(" " + _vm._s(route.meta.title) + " "),
                    ],
                    1
                  )
                }),
              ],
              2
            )
          : _vm._e()
      }),
      _vm.topMenus.length > _vm.visibleNumber
        ? _c(
            "el-submenu",
            { style: { "--theme": _vm.theme }, attrs: { index: "more" } },
            [
              _c("template", { slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("title10188"))),
              ]),
              _vm._l(_vm.topMenus, function (item, index) {
                return index >= _vm.visibleNumber
                  ? _c(
                      "el-submenu",
                      { key: index, attrs: { index: item.path } },
                      [
                        _c(
                          "template",
                          { slot: "title" },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": item.meta.icon },
                            }),
                            _vm._v(" " + _vm._s(item.meta.title) + " "),
                          ],
                          1
                        ),
                        _vm._l(item.children, function (route, i) {
                          return _c(
                            "el-menu-item",
                            {
                              key: index + "-" + i,
                              attrs: { index: route.path },
                            },
                            [
                              _c("svg-icon", {
                                attrs: { "icon-class": route.meta.icon },
                              }),
                              _vm._v(" " + _vm._s(route.meta.title) + " "),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e()
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }