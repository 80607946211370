export default {
  menu: {
    '单量套餐': "Dịch vụ đăng ký",
    '首页': "Trang chủ",
    "调拨": "Chuyển kho",
    "店铺利润报表": "Báo cáo lợi nhuận cửa hàng",
    "入库": "Nhập kho",
    '订单地区分布': 'Phân phối khu vực đơn hàng',
    "业绩利润报表": "Báo cáo lợi nhuận hiệu suất",
    "Shopee店铺数据表": "Shopee Bảng dữ liệu cửa hàng",
    "Shopee商品数据表": "Shopee Bảng dữ liệu sản phẩm",
    '店铺分析': "Báo cáo gian hàng",
    "利润月报": "Báo cáo lợi nhuận hàng tháng",
    '店铺销售日历': "Thống kê thứ tự",
    '店铺销售分析': "Phân Tích Doanh Số Cửa Hàng",
    '店铺利润分析': "Phân tích Lợi nhuận Cửa hàng",
    '店铺周期性对比': "So sánh Doanh số Định kỳ",
    '账户健康状况': "Hiệu Quả Hoạt Động",
    '客户分析': "Phân tích Khách hàng",
    '商品分析': "Phân tích Sản phẩm",
    "退货退款": "Quản lý trả hàng",
    '商品数据分析': "Phân tích dữ liệu sản phẩm",
    '评论分析': "Đánh giá Thông tin",
    '分析报告': "Báo cáo Phân tích",
    '数据诊断': "Thông tin Dữ liệu",
    '订单分析': "Báo cáo gian hàng",
    '物流管理': 'Quản lý logistics',
    '物流设置': 'Cài đặt vận chuyển',
    '退货报告': "Báo cáo trả hàng",
    '利润分析': "Phân tích Lợi nhuận",
    '订单利润明细': "Chi tiết Lợi nhuận đơn hàng",
    '广告概览': "Tổng quan quảng cáo",
    '广告业绩': "Hiệu suất quảng cáo",
    '广告采集': "Thu thập quảng cáo",
    "广告分析": "Ad analysis",
    '商品配置': "Cấu hình sản phẩm",
    '本地SKU': "SKU địa phương",
    '商品配对': "Ghép nối hàng hóa",
    '报表中心': "Trung tâm báo cáo",
    '经营看板': "Bảng điều khiển kinh doanh",
    '单量套餐': "Dịch vụ đăng ký",
    '运营待办': "Danh sách công việc vận hành",
    '订单管理': "Quản lý đơn hàng",
    '订单处理': "Xử lý đơn hàng",
    '退货管理': "Quản lý trả hàng",
    '线上订单': "Đơn hàng thủ công",
    '线下订单': "Đơn hàng bán lẻ ngoại tuyến",
    '运费管理': "Quản lý vận chuyển",
    '商品管理': "Quản lý hàng hóa",
    '商品SKU': "SKU hàng hóa",
    'SKU匹配关系': "Quan hệ ghép nối SKU",
    '库龄': "Tuổi của kho",
    '成本中心': "Trung tâm chi phí",
    '仓库管理': "Quản lý kho",
    '货架位': "Vị trí",
    '商品推送': "Đẩy sản phẩm",
    '店铺库存预警': " Cảnh báo tồn kho ",
    '库存列表': "Danh sách tồn kho",
    '仓库列表': "Kho",
    '仓库盘点': "Kiểm kho",
    '出库': "Hết hàng",
    '出入库记录': "Lịch sử xuất nhập kho",
    '采购管理': "Quản lý đặt hàng",
    '采购单': "Đơn đặt hàng",
    '采购建议': "Gợi ý đặt hàng",
    '供应商列表': "Danh sách NCC",
    '收货管理': "Quản lý nhận hàng",
    '站点': '站点',
    '短信渠道': '短信渠道',
    '短信模板': '短信模板',
    '短信日志': '短信日志',
    '文件列表': '文件列表',
    '文件配置': '文件配置',
    'Banner管理': 'Banner管理',
    '令牌管理': '令牌管理',
    '应用管理': '应用管理',
    '通知公告': '通知公告',
    '文章管理': '文章管理',
    '第三方服务配置': '第三方服务配置',
    '业务参数配置': '业务参数配置',
    '支付': "Thanh toán",
    '商户信息': '商户信息',
    '应用信息': '应用信息',
    '支付订单': '支付订单',
    '退款订单量': "Số lượng đơn hàng hoàn tiền",
    '监控': '监控',
    'API:日志': "API:日志",
    '访问日志': '访问日志',
    '错误日志': '错误日志',
    '审计日志': '审计日志',
    'MySQL:监控': "MySQL:监控",
    'Redis:监控': "Redis:监控",
    'Java:监控': "Java:监控",
    '监控平台': '监控平台',
    'DEV': 'DEV',
    '代码生成': '代码生成',
    '数据源配置': '数据源配置',
    '表单构建': '表单构建',
    '系统接口': '系统接口',
    '数据库文档': '数据库文档',
    '设置': "Cài đặt",
    '租户': '租户',
    '租户列表': '租户列表',
    '租户套餐': '租户套餐',
    '操作日志': "Hoạt động hệ thống",
    '账号管理': "Cài đặt tài khoản phụ",
    '角色管理': "Quản lý vai trò ",
    '子账号管理': "Tài khoản phụ",
    '授权管理': "Quản lý ủy quyền",
    '账号管理': "Cài đặt tài khoản phụ",
    '店铺管理': "Kết nối gian hàng",
    '仓库授权': "Kết nối nhà cung cấp dịch vụ",
    '设置': "Cài đặt",
    '预估利润设置': "Thiết lập Lợi nhuận ước tính",
    '测评参数': "Tham số đánh giá",
    '汇率设置': "Cài đặt tỷ giá",
    '标签管理': "Quản lý ký hiệu",
    '订单导出模板': "Mẫu xuất đơn hàng",
    '下载中心': '下载中心',
    '报表下载': "Tải báo cáo",
    '超级管理员': "Quản trị viên siêu",
    '用户管理': "Quản lý người dùng",
    '系统角色管理': '系统角色管理',
    '菜单管理': '菜单管理',
    '字典管理': '字典管理',
    '定时任务': '定时任务',
    '公告中心': '公告中心',
    '租户订单': '租户订单',
    '三方仓': "Kho hàng bên thứ 3",
    '三方仓厂商管理': "Nhà cung cấp dịch vụ kho",
    '三方仓授权仓库管理': "Kho đã kết nối",
    '其他': "Khác",
    '部门管理': '部门管理',
    '岗位管理': '岗位管理',
    '配置管理': '配置管理',
    '错误码管理': '错误码管理',
    '敏感词管理': '敏感词管理',
    '地区管理': '地区管理',
    '电商平台管理': '电商平台管理',
    '个人中心': "Trung tâm cá nhân",
    '字典数据': '字典数据',
    //:AI
    '模板管理': "Quản lý mẫu",
    'AI模板管理': 'AI模板管理',
    'AI模板分类管理': 'AI模板分类管理',
    '会话管理': "Quản lý phiên",
    '创作训练': "Đào tạo sáng tạo",
    '授权结果': "Kết quả ủy quyền",
    '授权失败': "Ủy quyền thất bại",

    //  2024.3.5 物流功能新增翻译
    "物流管理": "Quản lý vận chuyển",
    "物流设置": "Thiết lập vận chuyển"
  },
  title: {
    '项目': "Dự án",
    '总数据': "Tổng hợp dữ liệu",
    '件数': "Số lượng",
    '销售': "Số lượng đã bán",
    '测评销量': "Số lượng bán hàng kiểm tra",
    '测评金额': "Số tiền đánh giá",
    '平均客单价': "Giá Trị Đơn Hàng Trung Bình",
    '支出': "Chi phí",
    '搜索广告费': "Chi Phí Quảng Cáo Tìm Kiếm",
    '搜索广告转化': "Chuyển Đổi Quảng Cáo Tìm Kiếm",
    '搜索广告点击率': "Tỷ lệ Nhấp vào Quảng cáo Tìm kiếm",
    '搜索广告转化率': "Tỷ lệ Chuyển đổi Quảng cáo Tìm kiếm",
    '搜索广告ROI': "ROI Quảng cáo Tìm kiếm",
    '关联广告费': "Chi Phí Quảng Cáo Liên kết",
    '关联广告转化': "Chuyển Đổi Quảng Cáo Liên kết",
    '关联广告销售额': "Doanh Số Quảng Cáo Liên kết",
    '关联广告点击率': "Tỷ lệ Nhấp vào Quảng cáo Liên kết",
    '关联广告转化率': "Tỷ lệ Chuyển đổi Quảng cáo Liên kết",
    '关联广告ROI': "ROI Quảng cáo Liên kết",
    '推广广告费': "Chi Phí Quảng Cáo Khuyến Mại",
    '推广广告转化': "Chuyển Đổi Quảng Cáo Khuyến Mại",
    '推广广告销售额': "Doanh Số Quảng Cáo Khuyến Mại",
    '推广广告点击率': "Tỷ lệ Nhấp vào Quảng cáo Khuyến Mại",
    '推广广告转化率': "Tỷ lệ Chuyển đổi Quảng cáo Khuyến Mại",
    '推广广告ROI': "ROI Quảng cáo Khuyến Mại",
    '测评损耗': "Mất Mát Đánh Giá",
    '商品成本': "Chi phí hàng hóa",
    '退货金额': "Tiền hoàn",
    '预估运费': "Chi Phí Vận Chuyển Ước Tính",
    '产出': "Đầu Ra",
    '利润': "Lời / Lỗ",
    '销售利润率': "Tỷ suất lợi nhuận",
  },
  order: {
    '不在待打单状态': "Không ở Trạng thái Chờ",
    '请扫描或输入订单号或运单号': "Vui lòng quét hoặc nhập số đơn đặt hàng hoặc số theo dõi",
    '发货记录': "Hồ sơ Vận chuyển",
    'Free': "Chi Phí Giao Hàng Miễn Phí",
    'Item': "Thanh Toán Hàng",
    'Payment': "Phí thanh toán",
    'Commission': "Phí hoa hồng",
    'Promotional': "Phiếu Giảm giá của Người bán",
    'Lazcoin': "Giảm giá Lazcoin",
    'LazFlash': "Phụ Cấp/Trợ cấp cho LazFlash Mỗi Ngày Dưới $9.99",
  },
  user: {
    "请输入新手机号码": "Nhập số điện thoại mới",
    "输入旧密码": "Nhập mật khẩu cũ",
    "请先使用主账号绑定手机号码": "Vui lòng ràng buộc số điện thoại của bạn với tài khoản chính trước",
    "销售地区": "Khu vực bán hàng",
    "时区": "Múi giờ",
    "您的套餐还剩天，为避免服务中断，请尽快续订。立即购买": "Gói của bạn còn {days} ngày. Để tránh gián đoạn dịch vụ, vui lòng gia hạn ngay. Nhấn vào đây để mua ngay",
  },
  "亏损商品": "Sản phẩm lỗ",
  "在途中": "Dự trữ chương trình",
  "部分收货": "Nhận hàng một phần",
  "推送成功": "Đẩy thành công",
  "调拨收货": "Nhận hàng chuyển kho",
  "采购收货": "Nhận hàng đặt hàng",
  "默认日销量": "Số lượng bán hàng ngày mặc định",
  "采购计划": "Kế hoạch mua",
  "是": "Có",
  "否": "Không",
  "已取消": "Đã hủy",
  "已完成": "Đã hoàn thành",
  "盘点中": "Đang kiểm kho",
  "平台调整": "Điều Chỉnh Nền Tảng",
  "站外广告费": "Phí Quảng Cáo Ngoài Trang",
  "站内广告费": "Phí Quảng Cáo Trên Trang",
  "台湾": "Trung Quốc, Đài Loan",
  "空包费用": "Phí Gói Trống",
  "缺货": "Hết hàng",
  "商品缺件": "Thiếu hàng",
  "改变主意": "Thay đổi quyết định",
  "低销商品": "Sản phẩm bán chậm",
  "利润偏低": "Lợi nhuận thấp",
  "商品和描述不一致": "Hàng không đúng mô tả",
  "退货暴增": "Số lượng đổi trả tăng mạnh",
  "销量暴跌":"Doanh số giảm mạnh",
  "销量暴涨": "Doanh số tăng vọt",
  "低回应时间": "Thời gian phản hồi thấp",
  "逾期发货率过高": "Tỷ lệ giao hàng quá hạn cao",
  "商品损坏": "Hàng bị hỏng",
  "其他": "Khác",
  "商品发错了": "Nhận sai hàng",
  "商品存在瑕疵": "สินค้ามีข้อบกพร่อง",
  "印尼": "Indonesia",
  "马来西亚": "Malaysia",
  "菲律宾": "Philippines",
  "新加坡": "Singapore",
  "泰国": "Thailand",
  "越南": "Vietnam",
  "快递取件":"Lấy hàng",
  "打印拣货（含汇总）":"In ấn lựa chọn (bao gồm tóm tắt)",
  "确定批量设置仓库":"Xác nhận cài đặt lô nhà kho",
  "仓库盘点时请勿关闭当前窗口，盘点完成后可关闭窗口":"Vui lòng không đóng cửa sổ hiện tại trong quá trình kiểm kê kho. Bạn có thể đóng cửa sổ sau khi kiểm kê hoàn tất.",
  "创建盘点任务时请勿关闭当前窗口，创建完成后可关闭窗口":"Vui lòng không đóng cửa sổ hiện tại khi tạo nhiệm vụ kiểm kê. Bạn có thể đóng cửa sổ sau khi tạo xong.",
  "正在导入数据":"Đang nhập dữ liệu",
  "设置全部页商品仓库":"Thiết lập kho cho tất cả các trang hàng hóa",
  "设置已选商品仓库":"Thiết lập kho cho hàng hóa đã chọn",
  "印度尼西亚":"Indonesia",
  "当前账号下无可新增子账号的额度，请升级版本或删除无用的子账号空出名额后再次尝试。":"Không có hạn mức nào để thêm tài khoản con dưới tài khoản hiện tại. Vui lòng nâng cấp phiên bản của bạn hoặc xóa tài khoản con không sử dụng để tạo không gian và thử lại.",
  "佣金计算公式":"Công thức tính hoa hồng",
  "由于Lazada、TikTok平台佣金在订单完成时才结算，此处需要设置一个预估的佣金比例，用于订单未回款时利润的估算。":"Do Lazada và TikTok chỉ giải quyết hoa hồng khi đơn hàng được hoàn thành, ở đây bạn cần thiết lập một tỷ lệ hoa hồng ước tính để ước tính lợi nhuận khi đơn hàng chưa được trả lại.",
  "物流管理":"Quản lý logistics",
  "运费正常":"Phí vận chuyển bình thường",
  "运费亏损":"Phí vận chuyển thua lỗ",
  "运费盈余":"Phí vận chuyển thặng dư",
  "可按物流方式分别设置揽收方式":"Phương thức lấy hàng theo phương thức vận chuyển",
  "自行寄件":"Tự lấy hàng",
  "请选择揽收方式":"Vui lòng chọn phương thức lấy hàng",
  "线上物流":"Vận chuyển trực tuyến",
  "待处理": "Đang chờ xử lý",
  "待打单":"Chờ in",
  "待揽收":"รอรับสินค้า",
  "运输中":"Đang vận chuyển",
  "已妥投":"Đã giao thành công",
  "运输失败":"Giao hàng thất bại",
  "未审核": "Chưa xác minh",
  "审核中": "Đang kiểm tra",
  "审核失败": "ไม่ผ่านอนุมัติ",
  "缺货": "Hết hàng",
  "title0": "Chọn cửa hàng",
  "title1": "Thời gian hiện tại:",
  "title2": "Trung Quốc",
  "title3": "Hướng dẫn thiết lập ban đầu",
  "title4": "Gấp",
  "title5": "Hỗ trợ",
  "title6": "Dữ liệu bán hàng trực tiếp",
  "title7": "Thời gian cập nhật：",
  "title8": "Tên gian hàng",
  "title9": "Khu vực nền tảng",
  "title10": "Hôm qua",
  "title11": "Phân Tích Quảng Cáo Thời Gian Thực",
  "title12": "Chỉ Số Tổng Hợp",
  "title13": "Kỳ Này:",
  "title14": "Chu Kỳ So Với Tháng Trước",
  "title15": "Chênh Lệch So Với Tháng Trước",
  "title16": "So sánh giữa các tháng liền kề",
  "title17": "Bảng Xếp Hạng Bán Hàng",
  "title18": "Xếp hạng bán hàng",
  "title19": "Bảng xếp hạng lợi nhuận",
  "title20": "Bảng xếp hạng hàng hóa trả lại",
  "title21": "Xếp hạng",
  "title22": "Phân tích",
  "title23": "sản phẩm",
  "title24": "Doanh thu hiệu quả",
  "title25": "Số lượng doanh thu hiệu quả",
  "title26": "Lời / Lỗ",
  "title27": "Tỷ suất lợi nhuận",
  "title28": "Số lượng hàng hóa trả lại",
  "title29": "Tỷ lệ trả hàng",
  "title30": "Phân tích sau bán hàng",
  "title31": "7 ngày qua",
  "title32": "Tiền hoàn",
  "title33": "Số lượng đơn hàng hoàn tiền",
  "title34": "Số đơn hàng bị hủy",
  "title35": "Tỷ lệ đơn hàng bị hủy",
  "title36": "Nhắc nhở thông minh",
  "title37": "Doanh số tăng vọt",
  "title38": "Doanh số giảm mạnh",
  "title39": "Sản phẩm lỗ",
  "title40": "Sản phẩm bán chậm",
  "title41": "Lợi nhuận thấp",
  "title42": "Số lượng đổi trả tăng mạnh",
  "title43": "Cảnh báo trạng thái cửa hàng",
  "title44": "Đánh giá Trung bình hoặc Tiêu cực",
  "title45": "Chương trình khuyến mãi kết thúc",
  "title46": "Sản phẩm vi phạm bị gỡ bỏ",
  "title47": "Thời gian phản hồi thấp",
  "title48": "Tỷ lệ giao hàng quá hạn cao",
  "title49": "Tình hình cửa hàng",
  "title50": "Cửa Hàng Xuất Sắc",
  "title51": "Cửa Hàng Tốt",
  "title52": "Cửa Hàng Cần Cải Thiện",
  "title53": "Không tốt",
  "title54": "Trung tâm thông báo",
  "title55": "Hệ thống",
  "title56": "Cập nhật",
  "title57": "nhắc nhở",
  "title58": "福建象岸科技有限公司",
  "title59": "闽",
  "title60": "备2023007214号",
  "title61": "Chủ Nhật",
  "title62": "Thứ Hai",
  "title63": "Thứ Ba",
  "title64": "Thứ Tư",
  "title65": "Thứ Năm",
  "title66": "Thứ Sáu",
  "title67": "Thứ Bảy",
  "title68": "Kết nối gian hàng",
  "title69": "Ủy quyền cửa hàng của bạn cho hệ thống để đồng bộ hóa đơn, sản phẩm, vv. một cách mượt mà.",
  "title70": "Hủy ủy quyền",
  "title71": "Quản lý hàng hóa",
  "title72": "Duy trì thông tin sản phẩm địa phương trong hệ thống để phân tích lợi nhuận, chi phí và dữ liệu khác.",
  "title73": "Đi tới Cài đặt",
  "title74": "Ghép nối hàng hóa",
  "title75": "Kết hợp sản phẩm địa phương với các nền tảng trực tuyến để tích hợp mượt mà.",
  "title76": "Phù hợp",
  "title77": "Quản lý kho",
  "title78": "Khởi tạo tồn kho cho các hoạt động mua hàng, nhập hàng, vận chuyển, v.v. sau này",
  "title79": "Cài đặt tài khoản phụ",
  "title80": "Cấu hình quyền và hoạt động cho các vai trò khác nhau trong hệ thống của công ty bạn",
  "title81": "Cài đặt Plugin",
  "title82": "Hỗ trợ trong việc thu thập dữ liệu không thông qua API, dữ liệu liên quan đến quảng cáo và dữ liệu hiệu suất sản phẩm.",
  "title83": "Đi tới Cài đặt",
  "title84": "Dữ liệu tài chính",
  "title85": "Phân tích dữ liệu vận hành như cửa hàng, sản phẩm, đơn hàng và tự động tạo và tải xuống dữ liệu báo cáo",
  "title86": "Chi tiết",
  "title88": "Số lượng đơn hàng có hiệu lực",
  "title89": "Bán hàng quảng cáo",
  "title90": "Chi tiêu quảng cáo",
  "title91": "Đơn đặt hàng chèn",
  "title92": "Tỷ lệ Nhấp vào Quảng Cáo",
  "title93": "Hôm nay",
  "title94": "Cùng ngày tuần trước",
  "title95": "Theo đơn hàng",
  "title96": "7 ngày",
  "title97": "30 ngày",
  "title98": "Hãy chọn ít nhất một chỉ số",
  "title99": "领取阿里云通用云产品1888优惠券",
  "title100": "领取腾讯云通用云产品2860优惠券",
  "title101": "阿里云服务器折扣区",
  "title102": "点我进入",
  "title103": "腾讯云服务器秒杀区",
  "title104": "云产品通用红包，可叠加官网常规优惠使用。",
  "title105": "仅限新用户",
  "title106": "芋道后台管理框架",
  "title107": "一直想做一款后台管理系统，看了很多优秀的开源项目但是发现没有合适自己的。于是利用空闲休息时间开始自己写一套后台系统。如此有了芋道管理系统。，她可以用于所有的",
  "title108": "应用程序，如网站管理后台，网站会员中心，",
  "title109": "等等，当然，您也可以对她进行深度定制，以做出更强系统。所有前端后台代码封装过后十分精简易上手，出错概率低。同时支持移动客户端访问。系统会陆续更新一些实用功能。",
  "title110": "当前版本",
  "title111": "免费开源",
  "title112": "访问码云",
  "title113": "访问主页",
  "title114": "技术选型",
  "title115": "后端技术",
  "title116": "前端技术",
  "title117": "联系信息",
  "title118": "官网：",
  "title119": "群：",
  "title120": "满937441",
  "title121": "满887144332",
  "title122": "满180251782",
  "title123": "微信：",
  "title124": "芋道",
  "title125": "支付宝：",
  "title126": "支付宝信息",
  "title127": "更新日志",
  "title128": "新增缓存监控功能",
  "title129": "支持主题风格配置",
  "title130": "修复多级菜单之间切换无法缓存的问题",
  "title131": "多级菜单自动配置组件",
  "title132": "代码生成预览支持高亮显示",
  "title133": "支持",
  "title134": "请求映射",
  "title135": "参数",
  "title136": "删除用户和角色解绑关联",
  "title137": "去除用户手机邮箱部门必填验证",
  "title138": "支持注解",
  "title139": "对齐方式",
  "title140": "支持导入",
  "title141": "型数据",
  "title142": "优化头像样式，鼠标移入悬停遮罩",
  "title143": "代码生成预览提供滚动机制",
  "title144": "代码生成删除多余的数字",
  "title145": "类型",
  "title146": "修正转换字符串的目标字符集属性",
  "title147": "回显数据字典防止空值报错",
  "title148": "日志记录增加过滤多文件场景",
  "title149": "修改缓存",
  "title150": "方法可能导致嵌套的问题",
  "title151": "移除前端一些多余的依赖",
  "title152": "防止安全扫描",
  "title153": "出现的风险提示",
  "title154": "修改",
  "title155": "为",
  "title156": "到最新版本2",
  "title157": "到最新版本4",
  "title158": "到最新版本",
  "title159": "到最新版本1",
  "title160": "到最新版本0",
  "title161": "到最新版本3",
  "title162": "到版本4",
  "title163": "到最新版本6",
  "title164": "到最新版本5",
  "title165": "到最新版本7",
  "title166": "到最新版本10",
  "title167": "其他细节优化",
  "title168": "阻止任意文件下载漏洞",
  "title169": "代码生成支持上传控件",
  "title170": "新增图片上传组件",
  "title171": "调整默认首页",
  "title172": "配置支持分隔符",
  "title173": "权限信息调整",
  "title174": "调整",
  "title175": "默认时间",
  "title176": "解决代码生成没有",
  "title177": "类型的问题",
  "title178": "到最新版1",
  "title179": "版本到2",
  "title180": "提升安全性",
  "title181": "到版本0",
  "title182": "避免",
  "title183": "图标乱码",
  "title184": "代码生成支持同步数据库",
  "title185": "代码生成支持富文本控件",
  "title186": "代码生成页面时不忽略",
  "title187": "属性",
  "title188": "代码生成添加",
  "title189": "必填选项",
  "title190": "导出类型",
  "title191": "支持精度浮点类型",
  "title192": "Xuất khẩu",
  "title193": "优化获取值，防止",
  "title194": "方法不规范",
  "title195": "注解支持自动统计数据总和",
  "title196": "注解支持设置",
  "title197": "精度",
  "title198": "舍入规则",
  "title199": "Thực đơn",
  "title200": "数据权限新增（展开",
  "title201": "Gập",
  "title202": "Chọn tất cả",
  "title203": "Bỏ chọn tất cả",
  "title204": "父子联动）",
  "title205": "允许用户分配到部门父节点",
  "title206": "菜单新增是否缓存",
  "title207": "表格操作列间距调整",
  "title208": "限制系统内置参数不允许删除",
  "title209": "富文本组件优化，支持自定义高度",
  "title210": "图片冲突问题",
  "title211": "富文本工具栏样式对齐",
  "title212": "Nhập",
  "title213": "整形值校验优化",
  "title214": "修复页签关闭所有时固定标签路由不刷新问题",
  "title215": "表单构建布局型组件新增按钮",
  "title216": "左侧菜单文字过长显示省略号",
  "title217": "修正根节点为子部门时，树状结构显示问题",
  "title218": "修正调用目标字符串最大长度",
  "title219": "修正菜单提示信息错误",
  "title220": "修正定时任务执行一次权限标识",
  "title221": "修正数据库字符串类型",
  "title222": "优化递归子节点",
  "title223": "优化数据权限判断",
  "title224": "表格工具栏右侧添加刷新",
  "title225": "显隐查询组件",
  "title226": "后端支持",
  "title227": "跨域请求",
  "title228": "代码生成支持选择上级菜单",
  "title229": "代码生成支持自定义路径",
  "title230": "代码生成支持复选框",
  "title231": "导出导入支持",
  "title232": "字典类型",
  "title233": "支持分割字符串组内容",
  "title234": "验证码类型支持（数组计算、字符验证）",
  "title235": "版本到4",
  "title236": "表单类型为",
  "title237": "设置整形默认值",
  "title238": "代码生成器默认",
  "title239": "路径与默认",
  "title240": "路径不一致",
  "title241": "优化防重复提交拦截器",
  "title242": "优化上级菜单不能选择自己",
  "title243": "修复角色的权限分配后，未实时生效问题",
  "title244": "修复在线用户日志记录类型",
  "title245": "修复富文本空格和缩进保存后不生效问题",
  "title246": "修复在线用户判断逻辑",
  "title247": "唯一限制条件只返回单条数据",
  "title248": "添加获取当前的环境配置方法",
  "title249": "超时登录后页面跳转到首页",
  "title250": "全局异常状态汉化拦截处理",
  "title251": "过滤器改为将",
  "title252": "转义",
  "title253": "检查字符支持小数点",
  "title254": "降级改成异常提醒",
  "title255": "单应用调整为多模块项目",
  "title256": "Xóa",
  "title257": "提高编译速度。",
  "title258": "新增菜单默认主类目",
  "title259": "编码文件名修改为",
  "title260": "Phương thức",
  "title261": "定时任务",
  "title262": "表达式验证",
  "title263": "角色权限修改时已有权限未自动勾选异常修复",
  "title264": "防止切换权限用户后登录出现404",
  "title265": "导出排序",
  "title266": "创建用户不允许选择超级管理员角色",
  "title267": "修复代码生成导入表结构出现异常页面不提醒问题",
  "title268": "修复代码生成点击多次表修改数据不变化的问题",
  "title269": "修复头像上传成功二次打开无法改变裁剪框大小和位置问题",
  "title270": "修复布局为",
  "title271": "者",
  "title272": "用户表单显示错位问题",
  "title273": "修复热部署导致的强换异常问题",
  "title274": "修改用户管理复选框宽度，防止部分浏览器出现省略号",
  "title275": "工具，清除",
  "title276": "特殊字符，防止",
  "title277": "注入攻击",
  "title278": "生成",
  "title279": "如果是浮点型",
  "title280": "统一用",
  "title281": "定时任务调整",
  "title282": "防止部署出现错位",
  "title283": "调整表头固定列默认样式",
  "title284": "代码生成模板调整，字段为",
  "title285": "并且必填则加空串条件",
  "title286": "代码生成字典",
  "title287": "使用",
  "title288": "修复",
  "title289": "不可",
  "title290": "为0的问题",
  "title291": "查询返回增加",
  "title292": "升序排序",
  "title293": "修正岗位导出权限注解",
  "title294": "禁止加密密文返回前端",
  "title295": "修复代码生成页面中的查询条件创建时间未生效的问题",
  "title296": "修复首页搜索菜单外链无法点击跳转问题",
  "title297": "修复菜单管理选择图标，",
  "title298": "删除时不过滤数据",
  "title299": "用户管理部门分支节点不可检查",
  "title300": "显示计数",
  "title301": "数据范围过滤属性调整",
  "title302": "修复高危安全漏洞",
  "title303": "启动默认打开浏览器",
  "title304": "使用默认",
  "title305": "报错优化",
  "title306": "当",
  "title307": "滚动关闭右键菜单",
  "title308": "字典管理添加缓存读取",
  "title309": "参数管理支持缓存操作",
  "title310": "支持一级菜单（和主页同级）在",
  "title311": "区域显示",
  "title312": "限制外链地址必须以",
  "title313": "开头",
  "title314": "主题颜色与",
  "title315": "全局",
  "title316": "同步",
  "title317": "修改数据源类型优先级，先根据方法，再根据类",
  "title318": "支持是否需要设置",
  "title319": "属性，自定义返回码消息。",
  "title320": "请求前缀加入配置。",
  "title321": "登录地点设置内容过长则隐藏显示",
  "title322": "修复定时任务执行一次按钮后不提示消息问题",
  "title323": "修改上级部门（选择项排除本身和下级）",
  "title324": "通用",
  "title325": "发送方法增加参数",
  "title326": "编码类型",
  "title327": "更换",
  "title328": "地址查询接口",
  "title329": "修复页签变量",
  "title330": "添加校验部门包含未停用的子部门",
  "title331": "修改定时任务详情下次执行时间日期显示错误",
  "title332": "角色管理查询设置默认排序字段",
  "title333": "添加",
  "title334": "参数控制是否启用",
  "title335": "只对",
  "title336": "类型请求构建可重复读取",
  "title337": "的",
  "title338": "修改代码生成字典字段",
  "title339": "类型没有自动选中问题",
  "title340": "用户名取值修正",
  "title341": "表格树模板去掉多余的",
  "title342": "代码生成序号修正",
  "title343": "全屏情况下不调整上外边距",
  "title344": "代码生成",
  "title345": "字段添加默认格式",
  "title346": "用户管理角色选择权限控制",
  "title347": "修复路由懒加载报错问题",
  "title348": "模板",
  "title349": "添加菜单状态",
  "title350": "设置用户名称不能修改",
  "title351": "属性，防止",
  "title352": "遮罩",
  "title353": "菜单区分状态和显示隐藏功能",
  "title354": "修复安全加固",
  "title355": "修复代码生成如果选择字典类型缺失逗号问题",
  "title356": "登录请求",
  "title357": "更换为",
  "title358": "防止暴露",
  "title359": "日志返回时间格式处理",
  "title360": "控制允许拖动的元素",
  "title361": "布局设置点击扩大范围",
  "title362": "代码生成列属性排序查询",
  "title363": "代码生成列支持拖动排序",
  "title364": "修复时间格式不支持",
  "title365": "问题",
  "title366": "表单构建添加父级",
  "title367": "防止冲突",
  "title368": "定时任务并发属性修正",
  "title369": "角色禁用",
  "title370": "菜单隐藏不查询权限",
  "title371": "系统监控新增定时任务功能",
  "title372": "添加一个打包",
  "title373": "工程",
  "title374": "修复页签鼠标滚轮按下的时候，可以关闭不可关闭的",
  "title375": "修复点击退出登录有时会无提示问题",
  "title376": "修复防重复提交注解无效问题",
  "title377": "修复通知公告批量删除异常问题",
  "title378": "添加菜单时路由地址必填限制",
  "title379": "代码生成字段描述可编辑",
  "title380": "修复用户修改个人信息导致缓存不过期问题",
  "title381": "个人信息创建时间获取正确属性值",
  "title382": "操作日志详细显示正确类型",
  "title383": "导入表单击行数据时选中对应的复选框",
  "title384": "批量替换表前缀逻辑调整",
  "title385": "固定重定向路径表达式",
  "title386": "操作日志排序调整",
  "title387": "切换侧边栏或者缩放窗口显示",
  "title388": "新增表单构建",
  "title389": "代码生成支持树表结构",
  "title390": "新增用户导入",
  "title391": "修复动态加载路由页面刷新问题",
  "title392": "修复地址开关无效问题",
  "title393": "汉化错误提示页面",
  "title394": "代码生成已知问题修改",
  "title395": "修复多数据源下配置关闭出现异常处理",
  "title396": "过滤器，用于去除",
  "title397": "漏洞隐患",
  "title398": "修复上传头像控制台出现异常",
  "title399": "修改用户管理分页不正确的问题",
  "title400": "修复验证码记录提示错误",
  "title401": "缺少",
  "title402": "引用",
  "title403": "修复表格时间为空出现的异常",
  "title404": "日期反序列化时区配置",
  "title405": "调整根据用户权限加载菜单数据树形结构",
  "title406": "调整成功登录不恢复按钮，防止多次点击",
  "title407": "修改用户个人资料同步缓存信息",
  "title408": "修复页面同时出现",
  "title409": "和",
  "title410": "不显示处理",
  "title411": "修复在角色管理页修改菜单权限偶尔未选中问题",
  "title412": "配置文件新增",
  "title413": "密码属性",
  "title414": "Cài đặt",
  "title415": "全局的配置文件",
  "title416": "新增代码生成",
  "title417": "Thêm",
  "title418": "注解，防止重复提交",
  "title419": "新增菜单主目录添加",
  "title420": "删除操作",
  "title421": "日志记录过滤特殊对象，防止转换异常",
  "title422": "修改代码生成路由脚本错误",
  "title423": "用户上传头像实时同步缓存，无需重新登录",
  "title424": "调整切换页签后不重新加载数据",
  "title425": "实现参数的前端加密",
  "title426": "系统退出删除用户缓存记录",
  "title427": "新增在线用户管理",
  "title428": "新增按钮组功能实现（批量删除、导出、清空）",
  "title429": "新增查询条件重置按钮",
  "title430": "Cấu hình",
  "title431": "新增后端参数校验",
  "title432": "修复字典管理页面的日期查询异常",
  "title433": "修改时间函数命名防止冲突",
  "title434": "去除菜单上级校验，默认为顶级",
  "title435": "修复用户密码无法修改问题",
  "title436": "修复菜单类型为按钮时不显示权限标识",
  "title437": "芋道前后端分离系统正式发布",
  "title438": "捐赠支持",
  "title439": "你可以请作者喝杯咖啡表示鼓励",
  "title440": "Chào mừng đến xinjian",
  "title441": "Đăng ký",
  "title442": "Quên mật khẩu",
  "title443": "Người thuê",
  "title444": "Nhập số điện thoại",
  "title445": "Tên người dùng",
  "title446": "Mật khẩu",
  "title447": "Đang đăng nhập",
  "title448": "录",
  "title449": "中",
  "title450": "Đăng nhập tự động trong số ngày",
  "title451": "Quên mật khẩu ?",
  "title452": "Chưa có tài khoản?",
  "title453": "Đăng ký",
  "title454": "Nhập số điện thoại",
  "title455": "Nhập mật khẩu",
  "title456": "Hãy nhập mã xác minh đồ họa",
  "title457": "Nhận mã xác minh",
  "title458": "Gửi lại sau giây",
  "title459": "Đọc và chấp nhận",
  "title460": "Điều khoản Dịch vụ",
  "title461": "Đã có tài khoản？",
  "title462": "Đi đến đăng nhập",
  "title463": "Hãy nhập mã xác minh đồ họa",
  "title464": "Đệ trình",
  "title465": "Không cần phục hồi",
  "title466": "Nhập mật khẩu",
  "title467": "Nhập lại mật khẩu",
  "title468": "Đặt lại mật khẩu",
  "title469": "Hoàn thành",
  "title470": "Bắt đầu sử dụng",
  "title471": "Quay lại bước trước",
  "title472": "Đăng nhập",
  "title473": "Đồng ý và tiếp tục",
  "title474": "Ký tự",
  "title475": "Chữ cái phải chứa ký tự viết hoa",
  "title476": "Phải chứa ít nhất 2 loại chữ cái, số và ký tự",
  "title477": "Mật khẩu không thể giống như tên người dùng",
  "title478": "Vui lòng nhập lại mật khẩu của bạn",
  "title479": "Mật khẩu nhập lần 2 không khớp",
  "title480": "Thương mại điện tử xuyên biên giới tại Đông Nam Á",
  "title481": "Hoạt động được điều chỉnh một cách tinh tế và phân tích dữ liệu",
  "title482": "Mã xác minh đồ họa không thể để trống",
  "title483": "Vui lòng nhập số điện thoại",
  "title484": "Số điện thoại nên bắt đầu bằng số 1 và có 11 chữ số",
  "title485": "Không thể để trống người thuê",
  "title486": "Indonesia",
  "title487": "Philippines",
  "title488": "Thái Lan",
  "title489": "Việt Nam",
  "title490": "Malaysia",
  "title491": "Singapore",
  "title492": "Trung Quốc, Đài Loan",
  "title493": "Tài khoản không tồn tại",
  "title494": "Vui lòng đọc và đồng ý với các điều khoản",
  "title495": "Nhận mã xác minh thành công",
  "title496": "Sửa đổi thành công, vui lòng đăng nhập lại",
  "title497": "Đăng ký thành công, vui lòng đăng nhập",
  "title498": "Xinjian",
  "title499": "三方授权（",
  "title500": "此第三方应用请求获得以下权限：",
  "title501": "同意授权",
  "title502": "授权",
  "title503": "权",
  "title504": "拒绝",
  "title505": "自动授权未通过！",
  "title506": "访问你的个人信息",
  "title507": "修改你的个人信息",
  "title508": "Ràng buộc tài khoản",
  "title509": "Tài khoản",
  "title510": "Nhớ mật khẩu",
  "title511": "Tên đăng nhập không được để trống",
  "title512": "Mật khẩu không được để trống",
  "title513": "Cơ sở kiến thức",
  "title514": "Lượt truy cập sản phẩm",
  "title515": "Tin nhắn",
  "title516": "Số tiền",
  "title517": "Đơn hàng",
  "title518": "Trở lại",
  "title519": "Lỗi",
  "title520": "Bạn không có quyền truy cập!",
  "title521": "Xin lỗi, bạn không có quyền truy cập. Vui lòng không thực hiện các hoạt động bất hợp pháp! Bạn có thể quay lại trang chính.",
  "title522": "Quay về Trang Chính",
  "title523": "Chức năng này cần được kích hoạt",
  "title524": "Gói",
  "title525": "Để sử dụng, tiếp tục tới",
  "title526": "Tìm hiểu thêm",
  "title527": "hoặc",
  "title528": "Liên hệ với dịch vụ khách hàng",
  "title529": "Xin lỗi, trang bạn đang tìm không tồn tại. Hãy thử kiểm tra",
  "title530": "lỗi sau đó nhấn nút làm mới trên trình duyệt hoặc thử tìm nội dung khác trong ứng dụng của chúng tôi.",
  "title531": "Quay về trang chính",
  "title532": "Không tìm thấy trang！",
  "title533": "Yêu thích của tôi",
  "title534": "Câu hỏi và trả lời",
  "title535": "答",
  "title536": "Sao chép",
  "title537": "Hủy yêu thích",
  "title538": "Phiên tự do",
  "title539": "Phiên mẫu",
  "title540": "Phiên tài liệu",
  "title541": "Sao chép thành công",
  "title542": "Hủy yêu thích thành công",
  "title543": "Phiên mới",
  "title544": "Xóa tất cả các phiên",
  "title545": "Phiên mới",
  "title546": "Vui lòng chỉnh sửa lại",
  "title547": "Sửa đổi thành công",
  "title548": "Xác nhận xóa phiên",
  "title549": "Cảnh báo",
  "title550": "Xóa thành công！",
  "title551": "Xóa tất cả các phiên?",
  "title552": "Cơ sở kiến thức thông minh",
  "title553": "Tương tác với tài liệu, trích xuất nhanh chóng, xác định, và tóm tắt thông tin bạn cần.",
  "title554": "Tạo cơ sở kiến thức mới",
  "title555": "Xây dựng của riêng bạn",
  "title556": "Thiết lập một cơ sở kiến thức mẫu, phân tích và hiểu nhanh chóng các tài liệu kỹ thuật phức tạp, ghi lại các câu hỏi thường gặp, tránh các câu hỏi trùng lặp, và nâng cao hiệu suất làm việc.",
  "title557": "Chỉnh sửa mẫu",
  "title558": "Xóa mẫu",
  "title559": "Xác nhận xóa mẫu này?",
  "title560": "Xóa thành công",
  "title561": "Danh mục",
  "title562": "Vui lòng nhập danh mục",
  "title563": "Loại mẫu",
  "title564": "Vui lòng chọn loại mẫu",
  "title565": "Tiêu đề mẫu",
  "title566": "Vui lòng nhập tiêu đề mẫu",
  "title567": "Tìm kiếm",
  "title568": "Đặt lại",
  "title569": "Khóa chính",
  "title570": "Người tạo ",
  "title571": "Giới thiệu mẫu",
  "title572": "Nội dung câu hỏi",
  "title573": "Biến câu hỏi",
  "title574": "Thời gian tạo",
  "title575": "Thao tác",
  "title576": "Vui lòng nhập người tạo",
  "title577": "Vui lòng nhập giới thiệu mẫu",
  "title578": "Vui lòng nhập nội dung câu hỏi",
  "title579": "Biến câu hỏi",
  "title580": "Vui lòng nhập biến câu hỏi, sử dụng",
  "title581": "Mảng được lưu trữ. Mỗi đối tượng trong mảng chứa các trường sau: Tên biến",
  "title582": "Bản sao mô tả tương ứng",
  "title583": "Bắt buộc",
  "title584": "Giá trị mặc định",
  "title585": "Đồng ý",
  "title586": "定",
  "title587": "Đồng ý",
  "title588": "",
  "title589": "Sửa đổi thành công",
  "title590": "Thêm mới thành công",
  "title591": "Xác nhận xóa",
  "title592": "Số mẫu là",
  "title593": "Mục dữ liệu của",
  "title594": "Xác nhận xuất tất cả",
  "title595": "Mục dữ liệu mẫu",
  "title596": "Riêng biệt",
  "title597": "Chỉnh sửa cơ sở kiến thức",
  "title598": "林珍",
  "title599": "Chỉnh sửa câu hỏi",
  "title600": "Đóng",
  "title601": "Chào mừng đến với của tôi",
  "title602": "频道！今天的主题是如何在生活中实现健康的生活方式。",
  "title603": "首先，一个健康的生活方式需要有一个良好的饮食习惯。我们应该尽量食用新鲜、天然的食物，避免摄入过多的加工食品和糖分。另外，我们也需要保持适当的饮食量，不要过量进食或过度节食。",
  "title604": "其次，适量的运动也是保持健康的重要一环。我们可以选择自己喜欢的运动方式，比如跑步、瑜伽、游泳等等。无论是室内还是户外运动，每天坚持30分钟以上的运动可以帮助我们保持身体健康，同时也有助于缓解压力和焦虑感。",
  "title605": "除了饮食和运动之外，我们还需要注意睡眠质量的保证。一个良好的睡眠环境可以帮助我们更好地入睡和保持深度睡眠状态。同时，规律的作息时间也是非常重要的，它可以帮助我们的身体建立一个健康的生物钟。",
  "title606": "最后，我们还可以通过一些放松的方式来缓解生活中的压力和疲劳感。比如听音乐、读书、冥想等等。这些活动可以帮助我们放松身心，让我们更加愉悦地度过每一天。",
  "title607": "以上就是我对如何在生活中实现健康生活方式的一些",
  "title608": "Đang trả lời, vui lòng đợi",
  "title609": "Vui lòng nhập nội dung câu hỏi của bạn (Xuống dòng:",
  "title610": "Nhấn Enter để Gửi",
  "title611": "Tiêu tốn 1 điểm",
  "title612": "Gửi",
  "title613": "Hãy giúp tôi viết cuộc sống",
  "title614": "Kịch bản",
  "title615": "Mô tả vấn đề",
  "title616": "Hình ảnh",
  "title617": "Vui lòng nhập hình ảnh",
  "title618": "Tên danh mục",
  "title619": "Vui lòng nhập tên danh mục",
  "title620": "Cấp độ danh mục",
  "title621": "Vui lòng nhập cấp độ danh mục",
  "title622": "Phân cấp cha",
  "title623": "Vui lòng nhập phân cấp cha",
  "title624": "Ngày bắt đầu",
  "title625": "Ngày kết thúc",
  "title626": "Danh mục mẫu",
  "title627": "Số thứ tự danh mục mẫu là",
  "title628": "Mục dữ liệu danh mục mẫu",
  "title629": "Không biết viết gì? Thử chèn ví dụ",
  "title630": "Xóa nội dung",
  "title631": "Bắt đầu tạo ra",
  "title632": "Vui lòng nhập nội dung",
  "title633": "Tất cả",
  "title634": "Lưu trữ thành công",
  "title635": "Trạng thái Đặt Quảng cáo",
  "title636": "Trạng thái Thu thập",
  "title637": "Tính nguyên vẹn của việc thu thập",
  "title638": "Thời gian thu thập cuối cùng",
  "title639": "Vui lòng tham khảo 'Tính toàn vẹn của việc thu thập dữ liệu' để xác định liệu dữ liệu quảng cáo của tháng vừa qua đã được thu thập thành công chưa. Nếu dữ liệu không đầy đủ, bạn có thể xem lại các bản ghi thu thập để biết thông tin cụ thể.",
  "title640": "Xem hướng dẫn hoạt động",
  "title641": "Tên gian hàng",
  "title642": "Chưa thu thập",
  "title643": "Tạm dừng phát",
  "title644": "Đang phát sóng",
  "title645": "Đang thu thập",
  "title646": "Đang phát sóng",
  "title647": "Đã tạm dừng",
  "title648": "Ngoại tuyến",
  "title649": "Kích hoạt",
  "title650": "Tắt",
  "title651": "Chu kỳ thu thập",
  "title652": "Bản ghi thu thập",
  "title653": "Vui lòng lưu ý rằng hệ thống không thu thập được tất cả dữ liệu quảng cáo. Để có được dữ liệu hoàn chỉnh, vui lòng bật plugin quảng cáo và thực hiện lại quá trình thu thập.",
  "title654": "Hiệu suất sản phẩm",
  "title655": "Dữ liệu quảng cáo",
  "title656": "Ngày",
  "title657": "Hoàn chỉnh",
  "title658": "Không đầy đủ",
  "title659": "Đã thu thập",
  "title660": "Không có quảng cáo",
  "title661": "Thời gian thống kê",
  "title662": "Ad analysis",
  "title663": " Sắp xếp thứ tự ",
  "title664": "Khu vực",
  "title665": "Biểu đồ xu hướng",
  "title666": "Chi phí quảng cáo cửa hàng",
  "title667": "Doanh số quảng cáo sản phẩm",
  "title668": "sản phẩm",
  "title669": "Hiệu quả chiến dịch quảng cáo",
  "title670": "Chiến dịch quảng cáo",
  "title671": "Số lần nhấp vào quảng cáo",
  "title672": "Theo ngày",
  "title673": "Theo tuần",
  "title674": "Theo tháng",
  "title675": "Brazil",
  "title676": "Mexico",
  "title677": "Tỷ lệ nhấp chuột",
  "title678": "Lượt xem quảng cáo",
  "title679": "Lượt Xem Quảng cáo",
  "title680": "Hôm nay",
  "title681": "Hôm qua",
  "title682": "7 ngày qua",
  "title683": "15 ngày qua",
  "title684": "30 ngày qua",
  "title685": "Lượt Nhấp Quảng cáo",
  "title686": "So với ngày hôm qua",
  "title687": "So với ngày trước",
  "title688": "So với trước",
  "title689": "ngày",
  "title690": "Hiệu suất",
  "title691": "Dữ liệu cửa hàng",
  "title692": "Dữ liệu sản phẩm",
  "title693": "Dữ liệu vận hành",
  "title694": "Báo cáo lợi nhuận cửa hàng",
  "title695": "Lợi nhuận cửa hàng thống kê để phân tích hoạt động cửa hàng",
  "title696": "Báo cáo lợi nhuận hiệu suất",
  "title697": "Báo cáo hiệu suất thống kê cho nhân viên vận hành",
  "title698": "Bảng dữ liệu sản phẩm",
  "title699": "Các chỉ số chính thống kê để hiểu về hiệu suất hoạt động tổng thể của sản phẩm",
  "title700": "Bảng dữ liệu cửa hàng",
  "title701": "Phân tích dữ liệu vận hành cửa hàng để hiểu tổng thể về tình hình hoạt động của cửa hàng.",
  "title702": "Chọn khu vực",
  "title703": "Giai đoạn hiện tại",
  "title704": "Giai đoạn so sánh",
  "title705": "So sánh dữ liệu bán hàng",
  "title706": "So sánh dữ liệu chi phí",
  "title707": "Dữ liệu chi tiết so sánh",
  "title708": "Dữ liệu xuất mới nhất",
  "title709": "Tên báo cáo",
  "title710": "Đang xử lý",
  "title711": "Đang xử lý",
  "title712": "Dữ liệu bán hàng",
  "title713": "Dữ liệu bán",
  "title714": "Dữ liệu chi",
  "title715": "đơn vị",
  "title716": "Chi phí hàng hóa",
  "title717": "Ad Cost",
  "title718": "Phí đóng gói",
  "title719": "Chi phí đánh giá",
  "title720": "Ưu đãi người bán",
  "title721": "Chi phí vận chuyển",
  "title722": "Phí nền tảng",
  "title723": "Các loại phí khác của nền tảng",
  "title724": "Chọn ít nhất một cửa hàng",
  "title725": "Cá nhân",
  "title726": "So sánh giai đoạn cửa hàng-",
  "title727": "Xác nhận xuất bản tổng quan cấu trúc chi phí",
  "title728": "Tổng quan về cấu trúc chi phí",
  "title729": "Xác nhận đơn hàng",
  "title730": "Chi tiết đơn hàng",
  "title731": "Dịch vụ đặt hàng",
  "title732": "Độc lập",
  "title733": "Đánh giá Thông tin",
  "title734": "Lần",
  "title735": "Điểm",
  "title736": "Mua",
  "title737": "Số lượng",
  "title738": "Thời lượng",
  "title739": "Tổng gói",
  "title740": "Số lượng gói",
  "title741": "Thời lượng gói",
  "title742": "Có hiệu lực đến",
  "title743": "Đơn vị",
  "title744": "Tôi đã đọc và chấp nhận",
  "title745": "Số tiền phải trả",
  "title746": "Tôi đã đọc và chấp nhận",
  "title747": "Điều khoản Dịch vụ Người dùng",
  "title748": "Gửi Đơn hàng",
  "title749": "Thanh toán ngay",
  "title750": "Số tiền phải trả",
  "title751": "Thời gian thanh toán còn lại",
  "title752": "Các đơn hàng chưa thanh toán sẽ tự động bị hủy nếu hết hạn",
  "title753": "Mở điện thoại của bạn",
  "title754": "Alipay",
  "title755": "WeChat",
  "title756": "Quét để tiếp tục thanh toán",
  "title757": "Phương thức thanh toán: Chuyển khoản ngân hàng (Đừng đóng trang hiện tại trước khi chuyển, nhấn vào 'Đã Thực Hiện Chuyển Khoản ' sau khi chuyển thành công)",
  "title758": "Tên Công Ty Nhận:",
  "title759": "Số Tài Khoản Ngân Hàng Nhận:",
  "title760": "Tên Ngân Hàng:",
  "title761": "Ngân hàng Xây dựng Trung Quốc Cơ hội Công ty TNHH Chi nhánh Fuzhou Shida",
  "title762": "Thanh toán đã hoàn tất",
  "title763": "Thanh toán thành công",
  "title764": "Bạn có thể xem ở [Trung tâm cá nhân] - [Đơn hàng của tôi].",
  "title765": "Thời gian ghi nhận dự kiến là 5-10 phút, vui lòng đợi một chút",
  "title766": "Bạn có thể xem ở [Trung tâm cá nhân] - [Đơn hàng của tôi].",
  "title767": "Mã đơn hàng",
  "title768": "Thanh toán Alipay",
  "title769": "Chuyển khoản ngân hàng",
  "title770": "Tổng quan phân tích người dùng",
  "title771": "Tỷ lệ mua lại",
  "title772": "So với giai đoạn trước",
  "title773": "Hiệu suất bán hàng",
  "title774": "Số lượng đơn hàng có hiệu lực",
  "title776": "Doanh thu/khách",
  "title777": "Người dùng mới",
  "title778": "Người dùng trở lại",
  "title779": "Danh sách người dùng",
  "title780": "Nhập thông tin tìm kiếm",
  "title781": "Số tiền thanh toán của người mua",
  "title782": "Danh sách khách hàng",
  "title783": "Thông tin người mua",
  "title784": "Thời gian đặt đơn cuối cùng",
  "title785": "Hồ sơ mua hàng",
  "title786": "Người dùng [",
  "title787": "Hồ sơ sản phẩm",
  "title788": "Thông tin hàng hóa",
  "title790": "Hồ sơ đơn hàng",
  "订单号": "ID đơn hàng",
  "title792": "Loại sản phẩm",
  "title793": "Thời gian đặt đơn",
  "title794": "Qua",
  "title795": "Tìm kiếm người dùng",
  "title796": "Có",
  "title797": "Không",
  "title798": "15 Ngày Gần Đây",
  "title799": "Tổng số người dùng",
  "title800": "Số lượng đơn hàng lặp lại",
  "title801": "7 ngày qua",
  "title802": "Ngày",
  "title803": "Xác nhận xuất tất cả các mục dữ liệu?",
  "title804": "Dữ liệu bán hàng cửa hàng",
  "title805": "Dữ liệu tổng hợp",
  "title806": "So sánh thời gian",
  "title807": "So sánh chỉ số",
  "title808": "Giai đoạn hiện tại",
  "title809": "Chu kỳ so sánh",
  "title810": "Sự khác biệt so với cùng kỳ trước",
  "title811": "theo giờ",
  "title812": "Thống kê đúng giờ đề cập đến việc phân tích hiệu suất dữ liệu hàng ngày trong 24 giờ của cửa hàng.",
  "title813": "Thành phần chi phí sản phẩm",
  "title814": "So sánh Chi phí và Doanh số Hiệu quả",
  "title815": "Báo cáo tồn kho",
  "title816": "Tất cả các kho",
  "title817": "Kho hàng bên thứ 3",
  "title818": "Kho riêng",
  "title819": "Giá trị hàng tồn kho",
  "title820": "Tình trạng tồn kho",
  "title821": "Thời gian",
  "title822": "Tỷ lệ",
  "title823": "Phân phối địa bàn đơn hàng",
  "title824": "Vui lòng chọn khu vực",
  "title825": "Trả hàng",
  "title826": "Thành phố",
  "title827": "Số lượng đơn hàng có hiệu lực",
  "title828": "Số lượng Đơn hàng Trả lại",
  "title829": "Tỷ lệ Số lượng Đơn hàng Hiệu quả",
  "title830": "Tỷ lệ Số lượng Đơn hàng Trả lại",
  "title831": "Tồn kho khả dụng",
  "title832": "Đã khóa",
  "title833": "Dự trữ chương trình",
  "title834": "Vượt qua",
  "title835": "Theo gian hàng",
  "title836": "Theo site",
  "title837": "Theo khu vực",
  "title838": "Tổng số doanh thu",
  "title839": "Tổng doanh số",
  "title840": "Tổng số đơn hàng",
  "title841": "Thiệt hại đánh giá",
  "title842": "Tổng số lượng",
  "title843": "Tổng giá trị hàng tồn kho",
  "title844": "Tổng chi phí sản phẩm",
  "title845": "Account Info",
  "title846": "Thông tin cơ bản",
  "title847": "Nhật ký đăng nhập",
  "title848": "Thông tin đặt hàng",
  "title849": "Gói của tôi",
  "title850": "Đơn hàng của tôi",
  "title851": "Tài khoản của tôi",
  "title852": "Tài khoản",
  "title853": "Password",
  "title854": "Đặt mật khẩu",
  "title855": "Tên",
  "title856": "Số điện thoại",
  "title857": "Thay đổi điện thoại",
  "title858": "Thời gian đăng nhập",
  "title859": "Tài khoản đăng nhập",
  "title860": "Vai trò đã được gán",
  "title861": "Số điện thoại",
  "title862": "Địa chỉ đăng nhập",
  "title863": "Hạn mức gói của bạn đã được sử dụng hết, vui lòng mua gói mới để tiếp tục dịch vụ.",
  "title864": "Mua ngay",
  "title865": "Tình trạng gói",
  "title866": "Current Version",
  "title867": "term of Validity",
  "title868": "Đơn hàng còn lại:",
  "title869": "Mua gói",
  "title870": "Quyền cơ bản",
  "title871": "Số cửa hàng",
  "title872": "Tài khoản phụ",
  "title873": "Điểm",
  "title874": "Tên gói",
  "title875": "Đơn hàng còn lại",
  "title876": "Thời gian còn lại",
  "title877": "Thời gian đặt hàng",
  "title878": "Trạng thái",
  "title879": "Ghi chú",
  "title880": "Quyền gói nạp nhiên liệu",
  "title881": "Loại gói nạp nhiên liệu",
  "title882": "Nội dung mua hàng",
  "title883": "Số lượng gói nạp nhiên liệu",
  "title884": "Số lượng còn lại",
  "title885": "Số ngày còn lại",
  "title886": "Loại Đơn hàng",
  "title887": "Nhập số đơn hàng",
  "title888": "Thời gian giao dịch",
  "title889": "Nội dung đặt hàng",
  "title890": "Gói nạp nhiên liệu",
  "title891": "Trạng thái thanh toán",
  "title892": "Thanh toán ngay",
  "title893": "Số lượng huỷ đơn",
  "title894": "Vui lòng liên hệ dịch vụ khách hàng",
  "title895": "Điện thoại hóa đơn.",
  "title896": "Thời gian thanh toán",
  "title897": "Đăng ký",
  "title898": "Chưa thanh toán",
  "title899": "Thanh toán thành công",
  "title900": "Thanh toán đóng cửa",
  "title901": "Xác nhận hủy đơn hàng?",
  "title902": "Hủy đơn hàng thành công",
  "title903": "Tải lên thành công",
  "title904": "Chọn trạng thái",
  "title905": "Chỉ số Hiệu Quả Hoạt Động",
  "title906": "Cửa hàng không hoạt động tốt",
  "title907": "Điểm trung bình trong quý này",
  "title908": "Điểm phạt trung bình trong quý này cho các cửa hàng đã chọn. Các điểm tích lũy sẽ được giữ lại cho đến cuối quý.",
  "title909": "các điểm sẽ được đặt lại vào thứ Hai đầu tiên của mỗi quý (Tháng 1, Tháng 4, Tháng 7, Tháng 10). Di chuyển chuột vào số điểm để xem điểm trung bình của các chỉ số quan trọng.",
  "title910": "Chỉ số",
  "title911": "Trung bình",
  "title912": "Điểm",
  "title913": "Vi phạm quy định đăng sản phẩm",
  "title914": "Vi phạm về đăng bán",
  "title915": "Số lượng sản phẩm đặt trước",
  "title916": "Vi phạm khác",
  "title917": "Tỷ lệ hoàn thành đơn hàng",
  "title918": "Tỷ lệ không hoàn thành đơn hàng",
  "title919": "Tỷ lệ giao hàng trễ",
  "title920": "Thời gian chuẩn bị hàng",
  "title921": "CSKH & Mức độ hài lòng",
  "title922": "Tỷ lệ phản hồi",
  "title923": "Đánh giá của Người mua",
  "title924": "Giá trị trung bình của các chỉ số quan trọng",
  "title925": "Cảnh báo Shop",
  "title926": "Đang diễn ra",
  "title927": "Shop hoạt động hiệu quả, tạm không có cảnh báo",
  "title928": "Tình trạng vận hành",
  "title929": "Quyền cửa hàng đã hết hạn, vui lòng",
  "title930": "Reauthorize",
  "title10930": "Gian hàng này đã hết hạn, vui lòng kết nối lại",
  "title931": "Điểm Sao Quả Tạ trong Quý",
  "title932": "Mức Cảnh báo Phạt",
  "title933": "Cảnh báo sẽ được kích hoạt khi chỉ còn 1 điểm từ các mức kích hoạt hình phạt, có nghĩa là kích hoạt cảnh báo tại các mức 2, 5, 8, 11, 14",
  "title934": "Không cảnh báo",
  "title935": "Cấp độ",
  "title936": "Phân phối cảnh báo điểm phạt cửa hàng",
  "title937": "Không có cảnh báo",
  "title938": "Cửa hàng của bạn hiện đang ở trong tình trạng tốt mà không có hình phạt",
  "title939": "Tên xử phạt",
  "title940": "Mức Phạt",
  "title941": "Số ngày xử phạt còn lại",
  "title942": "Phân bổ mức phạt của Shop",
  "title943": "Tình hình cửa hàng",
  "title944": "Cập nhật dữ liệu",
  "title945": "Cài đặt mục danh sách",
  "title946": "Mức độ đánh giá hiệu suất tổng thể của shop. Xuất sắc, Chưa tốt, Cần cải thiện, Không tốt.",
  "title947": "Điểm Sao Quả Tạ trong Quý (điểm phạt)",
  "title948": "Điểm phạt sẽ được cộng dồn và giữ tới cuối mỗi quý (3 tháng). Điểm này sẽ được đặt lại vào thứ 2 đầu tiên của quý tiếp theo (tháng 1, tháng 4, tháng 7, tháng 10).",
  "title949": "Vi phạm Không thực hiện (điểm phạt)",
  "title950": "Điểm phạt đối với tình huống không thực hiện do việc hủy đơn hoặc trả hàng",
  "title951": "Vi Phạm Giao Hàng Trễ (điểm phạt)",
  "title952": "Điểm phạt đối với đơn hàng giao hàng trễ",
  "title953": "Điểm phạt đối với các vi phạm về đăng bán",
  "title954": "Vi phạm khác (điểm phạt)",
  "title955": "Điểm phạt đối với các vi phạm khác",
  "title956": "Vi phạm về đăng bán",
  "title957": "Các đăng bán sản phẩm vi phạm qui định đăng bán của Shopee sẽ bị xóa.",
  "title958": "Shop của tôi",
  "title959": "Chỉ tiêu",
  "title960": "Điểm Sao Quả Tạ",
  "title961": "Sản phẩm trùng lặp",
  "title962": "Mọi nỗ lực thao túng kết quả tìm kiếm để đạt được lợi thế không công bằng sẽ bị phạt. Ví dụ: sản phẩm bị coi là trùng lặp, spam từ khóa, giá ảo, v.v.",
  "title963": "Sản phẩm nhái/vi phạm sở hữu trí tuệ",
  "title964": "Sản phẩm giả/vi phạm sở hữu trí tuệ",
  "title965": "Sản phẩm cấm",
  "title966": "Sản phẩm không được phép đăng bán trên Shopee",
  "title967": "Sản phẩm đã được niêm yết",
  "title968": "Hàng đặt trước",
  "title969": " Số lượng Hàng đặt trước",
  "title970": "Tỷ lệ Hàng đặt trước trong tổng số tất cả sản phẩm được hiển thị trên trang của Shop",
  "title971": "Số ngày tỷ lệ hàng đặt trước vượt quá chỉ tiêu",
  "title972": "Chỉ tiêu",
  "title973": "Tỷ lệ Hàng đặt trước vượt quá số ngày chỉ tiêu của người bán trong khoảng thời gian 30 ngày",
  "title974": "Quy định khác",
  "title975": "Vi phạm quy định đăng bán khác",
  "title976": "Vi phạm các quy định đăng bán khác của nền tảng có thể bị trừng phạt, ví dụ: sản phẩm bị đăng bán sai danh mục, chất lượng ảnh sản phẩm kém, v.v.",
  "title977": "Tỷ lệ đơn hàng bị hủy trong 7 ngày qua",
  "title978": "Tỷ lệ hủy đơn",
  "title979": "Tỷ lệ phần trăm đơn hàng được người mua yêu cầu Trả hàng/Hoàn tiền trong 7 ngày qua",
  "title980": " Tỷ lệ Trả hàng/Hoàn tiền",
  "title981": "Phần trăm đơn hàng bị giao trễ trong vòng 7 ngày qua.",
  "title982": "Tỷ lệ giao hàng trễ",
  "title983": "Phần trăm đơn hàng bị giao trễ trong vòng 7 ngày qua.",
  "title984": "Số ngày trung bình Người bán cần để đóng gói và giao hàng cho đơn vị vận chuyển",
  "title985": "CSKH ",
  "title986": "Mức độ hài lòng",
  "title987": "Phần trăm tin nhắn chat hoặc trả giá mà Người bán đã trả lời Người mua trong vòng 12 tiếng sau khi nhận được.",
  "title988": "Thời gian phản hồi",
  "title989": "Khoảng thời gian trung bình mà Người bán phản hồi Người mua qua tin nhắn chat",
  "title990": "Đánh giá Shop",
  "title991": "Điểm đánh giá trung bình từ Người mua cho tất cả các đơn hàng",
  "title992": "Điểm Sao Quả Tạ trong Quý-Điểm Sao Quả Tạ trong Quý (điểm phạt)",
  "title993": "Điểm Sao Quả Tạ trong Quý-Vi phạm Không thực hiện (điểm phạt)",
  "title994": "Điểm Sao Quả Tạ trong Quý-Vi Phạm Giao Hàng Trễ ",
  "title995": "Điểm Sao Quả Tạ trong Quý-",
  "title996": "Điểm Sao Quả Tạ trong Quý-Vi phạm khác (điểm phạt)",
  "title997": "Vi phạm về đăng bán-Vi phạm về đăng bán",
  "title998": "Vi phạm về đăng bán-Sản phẩm trùng lặp",
  "title999": "Vi phạm về đăng bán-Sản phẩm nhái/vi phạm sở hữu trí tuệ",
  "title1000": "Vi phạm về đăng bán-Sản phẩm cấm",
  "title1001": "Hàng đặt trước-Số lượng Hàng đặt trước",
  "title1002": "Hàng đặt trước-Số ngày tỷ lệ hàng đặt trước vượt quá chỉ tiêu",
  "title1003": "Hàng đặt trước-Vi phạm quy định đăng bán khác",
  "title1004": "Tỷ lệ đơn hàng thành công-Tỷ lệ đơn hàng không thành công",
  "title1005": "Tỷ lệ đơn hàng thành công-Tỷ lệ hủy đơn",
  "title1006": "Tỷ lệ đơn hàng thành công-Tỷ lệ Trả hàng/Hoàn tiền",
  "title1007": "Tỷ lệ đơn hàng thành công-Tỷ lệ giao hàng trễ",
  "title1008": "Tỷ lệ đơn hàng thành công-Thời gian chuẩn bị hàng",
  "title1009": "CSKH & Mức độ hài lòng-Tỷ lệ phản hồi",
  "title1010": "CSKH & Mức độ hài lòng-Thời gian phản hồi",
  "title1011": "CSKH & Mức độ hài lòng-Đánh giá Shop",
  "title1012": "Khôi phục mặc định",
  "title1013": "Đồng ý",
  "title1014": "Xuất sắc",
  "title1015": "Chưa tốt",
  "title1016": "Cần cải thiện",
  "title1017": "Tỷ giá thanh toán không được để trống",
  "title1018": "Cập nhật dữ liệu thành công",
  "title1019": "Xác nhận tái ủy quyền cửa hàng",
  "title1020": "Bạn có chắc chắn muốn xuất tình trạng cửa hàng không",
  "title1021": "Tình hình cửa hàng-",
  "title1022": "Dịch vụ đăng ký",
  "title1023": "Gói hiện tại",
  "title1024": "Phiên bản Miễn phí",
  "title1025": "Gói có hiệu lực trong 1 năm.",
  "title1026": "Việc sử dụng đơn vị đơn bắt đầu được tính từ đầu gói, cho đến khi đơn vị đơn được sử dụng hết hoặc vượt quá ngày hết hạn.",
  "title1027": "Nếu bạn cần dịch vụ triển khai ngoại tuyến hoặc hỗ trợ trình diễn trực tuyến, vui lòng liên hệ với nhóm dịch vụ khách hàng của chúng tôi.",
  "title1028": "Năm",
  "title1029": "Số tài khoản",
  "title1030": "流程表单",
  "title1031": "表单名",
  "title1032": "请输入表单名",
  "title1033": "Trạng thái hoạt động",
  "title1034": "Vui lòng nhập ghi chú",
  "title1035": "Lưu",
  "title1036": "Xem",
  "title1037": "Xóa sạch",
  "title1038": "从左侧拖入或点选组件进行表单设计",
  "title1039": "输入型组件",
  "title1040": "选择型组件",
  "title1041": "布局型组件",
  "title1042": "表单名不能为空",
  "title1043": "开启状态不能为空",
  "title1044": "Thành công",
  "title1045": "代码已复制到剪切板，可粘贴。",
  "title1046": "代码复制失败",
  "title1047": "确定要清空所有组件吗？",
  "title1048": "提示",
  "title1049": "工作流",
  "title1050": "编号",
  "title1051": "表单详情",
  "title1052": "是否确认删除工作表单的编号为",
  "title1053": "定义编号",
  "title1054": "定义名称",
  "title1055": "定义分类",
  "title1056": "表单信息",
  "title1057": "暂无表单",
  "title1058": "流程版本",
  "title1059": "未部署",
  "title1060": "激活",
  "title1061": "挂起",
  "title1062": "部署时间",
  "title1063": "定义描述",
  "title1064": "分配规则",
  "title1065": "流程图",
  "title1066": "组名",
  "title1067": "请输入组名",
  "title1068": "Vui lòng chọn trạng thái",
  "title1069": "Mô tả",
  "title1070": "Thành viên",
  "title1071": "Vui lòng nhập mô tả",
  "title1072": "Vui lòng chọn thành viên",
  "title1073": "Tên nhóm không được để trống",
  "title1074": "Mô tả không được để trống",
  "title1075": "Thành viên không được để trống",
  "title1076": "Trạng thái không được để trống",
  "title1077": "Thêm nhóm người dùng",
  "title1078": "Sửa nhóm người dùng",
  "title1079": "Bạn có chắc chắn muốn xóa nhóm người dùng có số nhóm là",
  "title1080": "Không xác định",
  "title1081": "Lưu thành công",
  "title1082": "流程标识",
  "title1083": "请输入流程标识",
  "title1084": "流程名称",
  "title1085": "请输入流程名称",
  "title1086": "流程分类",
  "title1087": "新建流程",
  "title1088": "导入流程",
  "title1089": "最新部署的流程定义",
  "title1090": "激活状态",
  "title1091": "修改流程",
  "title1092": "设计流程",
  "title1093": "发布流程",
  "title1094": "流程定义",
  "title1095": "请输入流标标识",
  "title1096": "新建后，流程标识不可修改！",
  "title1097": "流程标识不可修改！",
  "title1098": "请选择流程分类",
  "title1099": "流程描述",
  "title1100": "表单类型",
  "title1101": "表单提交路由",
  "title1102": "请输入表单提交路由",
  "title1103": "自定义表单的提交路径，使用",
  "title1104": "的路由地址，例如说：",
  "title1105": "表单查看路由",
  "title1106": "请输入表单查看路由",
  "title1107": "自定义表单的查看路径，使用",
  "title1108": "将文件拖到此处，或",
  "title1109": "Nhấn để tải lên",
  "title1110": "提示：仅允许导入“",
  "title1111": "Hoặc",
  "title1112": "格式文件！",
  "title1113": "流程标识不能为空",
  "title1114": "流程名称不能为空",
  "title1115": "业务表单不能为空",
  "title1116": "流程分类不能为空",
  "title1117": "表单提交路由不能为空",
  "title1118": "表单查看路由不能为空",
  "title1119": "新建模型",
  "title1120": "修改模型",
  "title1121": "修改模型成功",
  "title1122": "新建模型成功！",
  "title1123": "后续需要执行如下",
  "title1124": "个步骤：",
  "title1125": "点击【修改流程】按钮，配置流程的分类、表单信息",
  "title1126": "点击【设计流程】按钮，绘制流程图",
  "title1127": "点击【分配规则】按钮，设置每个用户任务的审批人",
  "title1128": "点击【发布流程】按钮，完成流程的最终发布",
  "title1129": "另外，每次流程修改后，都需要点击【发布流程】按钮，才能正式生效！！！",
  "title1130": "重要提示",
  "title1131": "是否删除该流程！！",
  "title1132": "是否部署该流程！！",
  "title1133": "部署成功",
  "title1134": "是否确认",
  "title1135": "流程名字为",
  "title1136": "导入流程成功！请点击【设计流程】按钮，进行编辑保存后，才可以进行【发布流程】",
  "title1137": "选择",
  "title1138": "申请信息【",
  "title1139": "选择其它流程",
  "title1140": "发起流程成功",
  "title1141": "审批任务【",
  "title1142": "流程名",
  "title1143": "流程发起人",
  "title1144": "审批建议",
  "title1145": "请输入审批建议",
  "title1146": "不通过",
  "title1147": "转办",
  "title1148": "委派",
  "title1149": "退回",
  "title1150": "点击查看",
  "title1151": "审批记录",
  "title1152": "任务：",
  "title1153": "审批人：",
  "title1154": "创建时间：",
  "title1155": "审批时间：",
  "title1156": "耗时：",
  "title1157": "转派审批人",
  "title1158": "新审批人",
  "title1159": "审批建议不能为空",
  "title1160": "新审批人不能为空",
  "title1161": "未传递",
  "title1162": "参数，无法查看流程信息",
  "title1163": "查询不到流程信息！",
  "title1164": "审批通过成功！",
  "title1165": "审批不通过成功！",
  "title1166": "转派任务成功！",
  "title1167": "暂不支持【委派】功能，可以使用【转派】替代！",
  "title1168": "暂不支持【退回】功能！",
  "title1169": "请输入流程名",
  "title1170": "所属流程",
  "title1171": "请输入流程定义的编号",
  "title1172": "提交时间",
  "title1173": "结果",
  "title1174": "请选择流结果",
  "title1175": "发起流程",
  "title1176": "当前审批任务",
  "title1177": "结束时间",
  "title1178": "请输入取消原因？",
  "title1179": "取消流程",
  "title1180": "取消原因不能为空",
  "title1181": "取消成功",
  "title1182": "任务编号",
  "title1183": "任务名称",
  "title1184": "审批意见",
  "title1185": "审批时间",
  "title1186": "耗时",
  "title1187": "审批",
  "title1188": "任务分配规则",
  "title1189": "任务名",
  "title1190": "任务标识",
  "title1191": "规则类型",
  "title1192": "规则范围",
  "title1193": "修改任务规则",
  "title1194": "指定角色",
  "title1195": "指定部门",
  "title1196": "请选择指定部门",
  "title1197": "指定岗位",
  "title1198": "指定用户",
  "title1199": "指定用户组",
  "title1200": "指定脚本",
  "title1201": "规则类型不能为空",
  "title1202": "指定角色不能为空",
  "title1203": "指定部门不能为空",
  "title1204": "指定岗位不能为空",
  "title1205": "指定用户不能为空",
  "title1206": "指定用户组不能为空",
  "title1207": "指定脚本不能为空",
  "title1208": "So sánh Dữ liệu Doanh số Bán hàng",
  "title1209": "So sánh Dữ liệu Đơn hàng",
  "title1210": "So sánh Dữ liệu Lưu lượng",
  "title1211": "Dữ liệu Chuyển đổi Quảng cáo",
  "title1212": "Bình luận trên Nền tảng",
  "title1213": "Vui lòng nhập Bình luận trên Nền tảng",
  "title1214": "Sản phẩm trên Nền tảng",
  "title1215": "Vui lòng nhập Sản phẩm trên Nền tảng",
  "title1216": "Vui lòng nhập Sản phẩm",
  "title1217": "Thời gian bình luận",
  "title1218": "Bình luận",
  "title1219": "Người mua",
  "title1220": "Video",
  "title1221": "Nội dung đánh giá",
  "title1222": "Phản hồi của người bán",
  "title1223": "Điểm đánh giá",
  "title1224": "Vui lòng nhập người mua",
  "title1225": "Vui lòng nhập video",
  "title1226": "Vui lòng nhập nội dung đánh giá",
  "title1227": "Vui lòng nhập phản hồi của người bán",
  "title1228": "Vui lòng nhập điểm đánh giá",
  "title1229": "Chọn thời gian đánh giá",
  "title1230": "Không được để trống",
  "title1231": "Số đơn hàng không được để trống",
  "title1232": "Thời gian đánh giá không được để trống",
  "title1233": "Thêm đánh giá sản phẩm",
  "title1234": "Chỉnh sửa đánh giá sản phẩm",
  "title1235": "Bạn có chắc chắn muốn xóa bình luận sản phẩm có số",
  "title1236": "Bạn có chắc chắn muốn xuất tất cả các mục dữ liệu bình luận sản phẩm",
  "title1237": "Bình luận Sản phẩm",
  "title1238": "Nhập Chi phí Tùy chỉnh",
  "title1239": "Hồ sơ Sửa đổi Chi phí",
  "title1240": "Nhập Chi phí theo Lô",
  "title1241": "Nhập chi phí H.Hóa",
  "title1242": "Nhập Chi phí Lịch sử",
  "title1243": "Thêm Chi phí Tùy chỉnh",
  "title1244": "Nhập Chi phí Tùy chỉnh Theo Lô",
  "title1245": "Xóa Theo Lô",
  "title1246": "Vui lòng chọn",
  "title1247": "Trạng thái bán hàng",
  "title1248": "Trạng thái Chi phí Sản phẩm",
  "title1249": "Loại Chi phí",
  "title1250": "Xuất Chi phí Sản phẩm",
  "title1251": "Đang bán",
  "title1252": "Ngừng bán",
  "title1253": "Có Chi phí Sản phẩm",
  "title1254": "Không có Chi phí Sản phẩm",
  "title1255": "Chi phí Lịch sử",
  "title1256": "Mẫu Nhập Chi phí Sản phẩm",
  "title1257": "Mẫu Nhập Chi phí Tùy chỉnh",
  "title1258": "Nhập Chi phí Tùy chỉnh",
  "title1259": "Bảng Chi phí Tùy chỉnh",
  "title1260": "Lời Nhắc Ấm Áp",
  "title1261": "Bạn có chắc chắn muốn xuất",
  "title1262": "Chi tiết Chi phí Sản phẩm -",
  "title1263": "Mở",
  "title1264": "Đầu trang",
  "title1265": "Chuyển đổi Tiền tệ",
  "title1266": "Lượt Xem Trang",
  "title1268": "Lượt Truy cập Quảng cáo",
  "title1269": "Lượt Truy cập Tự nhiên",
  "title1270": "Số Lượt Thích",
  "title1271": "Tỷ lệ Thích",
  "title1272": "Số Lượt Thêm vào Giỏ hàng",
  "title1273": "Tỷ lệ Chuyển đổi Thêm vào Giỏ hàng",
  "title1274": "Doanh số Bán hàng Quảng cáo",
  "title1275": "Doanh số Bán hàng Tự nhiên",
  "title1276": "Tổng Giá Trị Bán hàng Tự nhiên",
  "title1277": "Tỷ lệ Chuyển đổi",
  "title1278": "Chi phí Quảng cáo",
  "title1279": "Lỗi dữ liệu, vui lòng liên hệ quản trị viên",
  "title1280": "Đặt hàng thành công",
  "title1281": "Hủy Đặt hàng thành công",
  "title1282": "Tỷ lệ Đơn hàng Tự nhiên",
  "title1283": "Bạn có chắc chắn muốn xuất tất cả các bản ghi kho",
  "title1285": "Phân tích lưu lượng quảng cáo có thể thu thập và phân tích dữ liệu lưu lượng và tỷ lệ chuyển đổi sản phẩm cửa hàng, cho phép bạn nhanh chóng xem các chỉ số chính như lượt truy cập của người mua, tỷ lệ chuyển đổi, khối lượng bán hàng, doanh số bán hàng và nhiều hơn nữa, để hiểu về hiệu suất của sản phẩm trong quá trình hoạt động.",
  "title1286": "Vui lòng nhập tìm kiếm thuộc tính sản phẩm",
  "title1287": "Danh mục quảng cáo",
  "title1288": "Nhấp vào quảng cáo",
  "title1289": "Doanh thu",
  "title1290": "Detail",
  "title1291": "Detail iklan",
  "title1292": "Batalkan puncak",
  "title1293": "Nama produk",
  "title1294": "Konfirmasi ekspor semua produk",
  "title1295": "Item data",
  "title1296": "Data diagnostik",
  "title1297": "Masa lalu",
  "title1298": "Informasi toko",
  "title1299": "Grup",
  "title1301": "Doanh số hiệu quả so với tháng trước",
  "title1302": "Lời / Lỗ",
  "title1303": "Vui lòng chọn loại tìm kiếm",
  "title1304": "Vui lòng nhập nội dung tìm kiếm",
  "title1305": "Chọn thời gian",
  "title1306": "Nhận xét",
  "title1307": "Chỉ số tiếp thị",
  "title1308": "Số lượt nhận xét",
  "title1309": "Tổng điểm",
  "title1310": "Điểm bán hàng chính",
  "title1311": "Thông tin đối thủ",
  "title1312": "Bản ghi từ khóa cốt lõi",
  "title1313": "Trạng thái sản phẩm",
  "title1314": "Hạ kệ",
  "title1315": "Đưa lên kệ",
  "title1316": "Nhận xét về sản phẩm",
  "title1317": "Tên người dùng",
  "title1318": "Số đơn hàng",
  "title1319": "Chi tiết dữ liệu sản phẩm",
  "title1320": "Xem biểu đồ xu hướng",
  "title1321": "Tổng số khách truy cập",
  "title1322": "Tổng số đơn hàng",
  "title1323": "Khách truy cập quảng cáo từ khóa",
  "title1324": "Tỉ lệ nhấp vào từ khóa",
  "title1325": "Số lượng quảng cáo từ khóa",
  "title1326": "Tỉ lệ chuyển đổi từ khóa",
  "title1327": "Chi phí trung bình mỗi đơn hàng",
  "title1328": "Từ khóa",
  "title1329": "Quảng cáo từ khóa",
  "title1330": "Khách hàng quảng cáo liên kết",
  "title1331": "Đơn hàng quảng cáo liên kết",
  "title1332": "Quảng cáo liên kết",
  "title1333": "Khách truy cập tự nhiên",
  "title1334": "Tỷ lệ click tự nhiên",
  "title1335": "Đơn hàng tự nhiên",
  "title1336": "Tỷ lệ chuyển đổi tự nhiên",
  "title1337": "Biểu đồ phân tích xu hướng dữ liệu sản phẩm",
  "title1338": "Nhấp vào nhãn để mở hoặc đóng đường cong",
  "title1339": "Ghi chú điểm bán hàng",
  "title1340": "Liên kết",
  "title1341": "Bán chạy của đối thủ",
  "title1342": "Giá",
  "title1343": "Doanh số hàng tháng của đối thủ",
  "title1344": "Từ khóa địa phương",
  "title1345": "Dịch tiếng Trung",
  "title1346": "Khối lượng tìm kiếm",
  "title1347": "Vui lòng nhập điểm bán hàng",
  "title1348": "Liên kết đối thủ",
  "title1349": "Vui lòng nhập liên kết đối thủ",
  "title1350": "Vui lòng nhập bán chạy của đối thủ",
  "title1351": "Vui lòng nhập doanh số hàng tháng của đối thủ",
  "title1352": "Vui lòng nhập từ khóa địa phương",
  "title1353": "Tự động dịch từ khóa",
  "title1354": "Vui lòng nhập khối lượng tìm kiếm từ khóa",
  "title1355": "Ghi chú điểm bán hàng quan trọng",
  "title1356": "Thêm ghi chú điểm bán hàng quan trọng",
  "title1357": "Ghi chú liên kết đối thủ",
  "title1358": "Thêm liên kết đối thủ",
  "title1359": "Thêm từ khóa cốt lõi",
  "title1360": "Tên sản phẩm trực tuyến",
  "title1361": "sản phẩm",
  "title1362": "Đã ngừng kinh doanh",
  "title1363": "Đã bán",
  "title1364": "วันที่กำหนดเอง",
  "title1365": "Vui lòng nhập ghi chú điểm bán hàng",
  "title1366": "Chi phí trung bình mỗi đơn hàng (Từ khóa)",
  "title1367": "Chi phí mỗi đơn hàng (Quảng cáo Liên quan)",
  "title1368": "Tháng",
  "title1369": "Bạn có chắc chắn muốn xuất tất cả các mục dữ liệu đơn hàng không?",
  "title1370": "Hành lý nâng cấp 23-inch Mật khẩu Màu Đen Đen Đen Đen Đen Đen",
  "title1371": "Nhập từ khóa để tìm kiếm",
  "title1372": "Hiển thị vị trí",
  "title1373": "Chuyển đổi",
  "title1374": "Sản phẩm đã bán hết",
  "title1375": "Cài đặt Điều kiện",
  "title1377": "Quy tắc chẩn đoán",
  "title1378": "Vui lòng chọn thời gian",
  "title1379": "Giờ",
  "title1380": "Cài đặt chưa hoàn thành",
  "title1381": "Lớn hơn",
  "title1382": "Lớn hơn hoặc bằng",
  "title1383": "Nhỏ hơn",
  "title1384": "Nhỏ hơn hoặc bằng",
  "title1385": "Tháng Bắt đầu",
  "title1386": "Tháng Kết thúc",
  "title1387": "Dữ liệu Cơ bản",
  "title1388": "Số lượng khách hàng",
  "title1389": "Số tiền đơn hàng bị hủy",
  "title1390": "Biến đổi xu hướng",
  "title1391": "Số lượng đơn hàng bị hủy",
  "title1392": "14 ngày gần đây",
  "title1393": "เดือน",
  "title1394": "Tháng trước",
  "title1395": "6 Tháng gần đây",
  "title1396": "1 Năm gần đây",
  "title1397": "3 Tháng gần đây",
  "title1398": "6 Tháng gần đây",
  "title1399": "1 Năm gần đây",
  "title1400": "Theo Năm",
  "title1401": "So với Ngày Trước",
  "title1402": "Thêm Liên kết Sản phẩm",
  "title1403": "Thông Tin Sản Phẩm",
  "title1404": "Nền tảng",
  "title1405": "Thời Gian Phân Tích",
  "title1406": "Thời gian vận hành đã hết. Vui lòng phân tích lại.",
  "title1407": "Phân Tích Đã Hoàn Tất",
  "title1408": "Phân Tích Đang Tiến Hành",
  "title1409": "Phân Tích Lại",
  "title1410": "Báo Cáo Phân Tích",
  "title1411": "Liên Kết Sản Phẩm",
  "title1412": "Vui lòng nhập liên kết sản phẩm trực tuyến để phân tích nội dung đánh giá. Chỉ có thể thêm một liên kết sản phẩm mỗi lần.",
  "title1413": "Nhập Liên Kết Sản Phẩm",
  "title1414": "Ví dụ:",
  "title1415": "Tên Sản Phẩm",
  "title1416": "Mã Sản Phẩm",
  "title1417": "Vui lòng nhập liên kết sản phẩm",
  "title1418": "Vui lòng nhập một địa chỉ hợp lệ",
  "title1419": "Đã Thêm Thành Công",
  "title1420": "Làm Mới Thành Công",
  "title1421": "Xác Nhận Xóa?",
  "title1422": "Giá:",
  "title1423": "Tổng Doanh Số:",
  "title1424": "Doanh Số Hàng Tháng:",
  "title1425": "Đánh Giá Sao:",
  "title1426": "Tỷ Lệ Bình Luận:",
  "title1427": "Thời Gian Đặt Hàng:",
  "title1428": "Mở Liên Kết Cửa Hàng",
  "title1429": "Tổng Quan",
  "title1430": "Ưu điểm",
  "title1431": "Nhược điểm",
  "title1432": "Kỳ Vọng của Khách hàng",
  "title1433": "Động Cơ Mua Hàng",
  "title1434": "Các biến thể hiện đang bán:",
  "title1435": "các biến thể có đánh giá lịch sử:",
  "title1436": "các biến thể với danh sách đánh giá như sau:",
  "title1437": "Biến thể",
  "title1438": "Số Lượng Đánh Giá",
  "title1439": "Phần Trăm Đánh Giá",
  "title1440": "Xem Đánh Giá",
  "title1441": "Lý do",
  "title1442": "Phần Trăm Danh Mục Hiện Tại",
  "title1443": "Phần Trăm Tổng Sản Phẩm Hiện Tại",
  "title1444": "Xem Đánh Giá của Người Mua",
  "title1445": "Danh Sách Đánh Giá",
  "title1446": "Nhập Nội Dung Tìm Kiếm",
  "title1447": "Đánh Giá Sao",
  "title1448": "Loại Đánh Giá",
  "title1449": "Nội Dung Dịch",
  "title1450": "Tải Xuống Đánh Giá",
  "title1451": "Sao",
  "title1452": "Chữ",
  "title1453": "Khác",
  "title1454": "Bạn có chắc chắn muốn xuất các đánh giá sản phẩm không?",
  "title1455": "Đánh Giá Sản Phẩm",
  "title1456": "Chọn Loại Tìm Kiếm",
  "title1457": "Doanh Thu Sản Phẩm",
  "title1458": "Đánh Giá Cửa Hàng",
  "title1459": "Thời Gian Giao Hàng",
  "title1460": "Tỷ Lệ Giao Hàng Trễ",
  "title1461": "Tỷ Lệ Chưa Hoàn Thành",
  "title1462": "Thời Gian Phản Hồi",
  "title1463": "Điểm Phạt",
  "title1464": "Đánh Giá Mới Hôm Nay",
  "title1465": "林明芳-",
  "title1466": "Người dùng",
  "title1467": "Vui lòng nhập người dùng",
  "title1468": "Tên chẩn đoán",
  "title1469": "Vui lòng nhập tên chẩn đoán",
  "title1470": "Loại chẩn đoán",
  "title1471": "Vui lòng chọn loại chẩn đoán",
  "title1472": "Loại nhắc nhở",
  "title1473": "Vui lòng chọn loại nhắc nhở",
  "title1474": "Số tự tăng",
  "title1475": "Mô tả chẩn đoán",
  "title1476": "Vui lòng nhập quy tắc chẩn đoán",
  "title1477": "Tên chẩn đoán không được để trống",
  "title1478": "Loại chẩn đoán không được để trống",
  "title1479": "Loại nhắc nhở không được để trống",
  "title1480": "Thêm Chẩn đoán Dữ liệu",
  "title1481": "Sửa Chẩn đoán Dữ liệu",
  "title1482": "Bạn có chắc chắn muốn xóa chẩn đoán dữ liệu với ID",
  "title1483": "Bạn có chắc chắn muốn xuất tất cả các mục dữ liệu chẩn đoán không?",
  "title1484": "Chẩn Đoán Dữ Liệu",
  "title1485": "Bảng Xếp Hạng",
  "title1486": "Thời Gian Cập Nhật",
  "title1487": "Bảng Xếp Hạng Hằng Ngày",
  "title1488": "Bảng Xếp Hạng Hàng Tuần",
  "title1489": "Bảng Xếp Hạng Hàng Tháng",
  "title1490": "Liên Kết Trực Tuyến",
  "title1491": "Thông Tin Liên Kết",
  "title1492": "Thông Tin",
  "title1493": "Thông Tin Tài Khoản",
  "title1494": "Thông Tin Nền Tảng",
  "title1495": "Bảng Xếp Hạng Doanh Số Bán Hàng",
  "title1496": "Bảng Xếp Hạng Biên Lợi Nhuận",
  "title1497": "Bảng Xếp Hạng Tỉ Lệ Trả Hàng",
  "title1498": "Bảng Xếp Hạng Hiệu Suất",
  "title1499": "Bảng Xếp Hạng Nền Tảng",
  "title1500": "Bảng Xếp Hạng Cửa Hàng",
  "title1501": "Tổng Doanh Số",
  "title1502": "Thông Số Kỹ Thuật",
  "title1503": "Tên",
  "title1504": "Vui lòng nhập tên",
  "title1505": "Tên Mẫu",
  "title1506": "Loại Mã Vạch",
  "title1507": "Kích Thước Nhãn",
  "title1508": "Chiều Rộng",
  "title1509": "Chiều Cao",
  "title1510": "Mặc Định",
  "title1511": "Vui lòng chọn loại mã vạch",
  "title1512": "Chiều Rộng",
  "title1513": "Vui lòng nhập chiều rộng",
  "title1514": "Chiều Cao",
  "title1515": "Vui lòng nhập chiều cao",
  "title1516": "Định Nghĩa Mẫu",
  "title1517": "Tên không được để trống",
  "title1518": "Loại mã vạch không được để trống",
  "title1519": "Chiều rộng không được để trống",
  "title1520": "Chiều cao không được để trống",
  "title1521": "Mặc định không được để trống",
  "title1522": "Thêm Mẫu Nhãn",
  "title1523": "Chỉnh Sửa Mẫu Nhãn",
  "title1524": "Xác nhận xóa mẫu nhãn có ID",
  "title1525": "Xác nhận xuất tất cả các mục dữ liệu mẫu nhãn",
  "title1526": "Mẫu Nhãn",
  "title1527": "Nhập Sản Phẩm",
  "title1528": "Xem Xếp Hạng",
  "title1529": "Vui lòng nhập tên nền tảng",
  "title1530": "Tên Nền Tảng",
  "title1531": "Biểu Tượng Nền Tảng",
  "title1532": "Liên Kết Nền Tảng",
  "title1533": "Vui lòng nhập liên kết nền tảng",
  "title1534": "Tên nền tảng không được để trống",
  "title1535": "Biểu tượng nền tảng không được để trống",
  "title1536": "Liên kết nền tảng không được để trống",
  "title1537": "Thêm Nền Tảng Thương Mại Điện Tử",
  "title1538": "Chỉnh Sửa Nền Tảng Thương Mại Điện Tử",
  "title1539": "Xác Nhận Xóa",
  "title1540": "Xác nhận xuất tất cả các mục dữ liệu nền tảng thương mại điện tử",
  "title1541": "Nền Tảng Thương Mại Điện Tử",
  "title1542": "Vui lòng nhập tên danh mục",
  "title1543": "Danh Mục",
  "title1544": "Danh Mục Cha",
  "title1545": "Tên Danh Mục",
  "title1546": "Thứ Tự Hiển Thị",
  "title1547": "Cấp",
  "title1548": "Vui lòng nhập danh mục cha",
  "title1549": "Vui lòng nhập thứ tự hiển thị",
  "title1550": "Vui lòng nhập cấp",
  "title1551": "Tên danh mục không được để trống",
  "title1552": "Thứ tự hiển thị không được để trống",
  "title1553": "Cấp không được để trống",
  "title1554": "Thêm Danh Mục Sản Phẩm",
  "title1555": "Chỉnh Sửa Danh Mục Sản Phẩm",
  "title1556": "Xác nhận xóa danh mục sản phẩm",
  "title1557": "Xác nhận xuất tất cả các mục dữ liệu danh mục sản phẩm",
  "title1558": "Danh Mục Sản Phẩm",
  "title1559": "Bạn có",
  "title1560": "Cửa hàng của bạn đã hết hạn, vui lòng",
  "title1561": "คลิกเพื่อเชื่อมต่ออีกครั้ง",
  "title1562": "เตือนความจำ",
  "title1563": "Đang chờ xử lý",
  "title1564": "Sắp hết hạn",
  "title1565": "Hết hàng",
  "title1566": "ยังไม่จับคู่",
  "title1567": "ไม่ผ่านอนุมัติ",
  "title1568": "อัปเดตล้มเหลว",
  "title1569": "รอรับสินค้า",
  "title1570": "สินค้าคงคลัง",
  "title1571": "Không có hàng tồn kho",
  "title1572": "แจ้งเตือนสต็อกขั้นต่ำ",
  "title1573": " การแจ้งเตือนสต็อก ",
  "title1574": "การสั่งซื้อ",
  "title1575": "คำแนะนำการสั่งซื้อ",
  "title1576": "การวางแผนสั่งซื้อ",
  "title1577": "จำนวนร้านที่เชื่อมต่อ",
  "title1578": "ร้านของ เชื่อมต่อหมดอายุแล้ว",
  "title1579": "จำนวนคำสั่งซื้อที่คืนเงิน",
  "title1580": "Vui lòng nhập",
  "title1581": "Đối thủ",
  "title1582": "Doanh số hàng tháng",
  "title1583": "Vui lòng nhập liên kết",
  "title1584": "Vui lòng nhập giá",
  "title1585": "Vui lòng nhập doanh số hàng tháng",
  "title1586": "Thêm Liên kết Đối thủ",
  "title1587": "Sửa Liên kết Đối thủ",
  "title1588": "Bạn có chắc chắn muốn xóa liên kết đối thủ có ID",
  "title1589": "Bạn có chắc chắn muốn xuất tất cả các mục dữ liệu liên kết đối thủ không?",
  "title1590": "Sản phẩm Đơn",
  "title1591": "Sản phẩm Kết hợp",
  "title1592": "Tất cả Cửa hàng",
  "title1593": "Chỉnh sửa",
  "title1594": "Tên Sản phẩm Kết hợp",
  "title1595": "Vui lòng chọn sản phẩm",
  "title1596": "Vui lòng chọn cửa hàng",
  "title1597": "Sản phẩm không được để trống",
  "title1598": "Cửa hàng không được để trống",
  "title1599": "Thêm Cặp Sản phẩm",
  "title1600": "Bạn có chắc chắn muốn xuất tất cả các mục dữ liệu cặp sản phẩm không?",
  "title1601": "Cặp Sản phẩm",
  "title1602": "Chi tiết Cặp",
  "title1603": "Xóa",
  "title1604": "Bạn có chắc chắn muốn xóa cặp sản phẩm không?",
  "title1605": "Vui lòng nhập cửa hàng",
  "title1606": "Vui lòng nhập nền tảng",
  "title1607": "Vui lòng nhập khu vực",
  "title1608": "Gần đây",
  "title1609": "Vui lòng nhập gần đây",
  "title1610": "Tăng dần",
  "title1611": "Tiền tệ",
  "title1612": "Tỷ Giá",
  "title1613": "Số Lượng Hoàn Tiền",
  "title1614": "Lượng Đơn Hàng Trả Hàng So với Tháng Trước",
  "title1615": "Số Lượng Đơn Hủy",
  "title1616": "Tỷ Lệ Trả Hàng So với Tháng Trước",
  "title1617": "Số Lượng Đơn Hủy so với Tháng Trước",
  "title1618": "Tỷ Lệ Hủy",
  "title1619": "Tỷ Lệ Hủy So với Tháng Trước",
  "title1620": "Số Lượng Đánh Giá",
  "title1621": "Số Lượng Đánh Giá so với Tháng Trước",
  "title1622": "Lượng Đơn Hàng Hiệu Quả So với Tháng Trước",
  "title1623": "มูลค่าคำสั่งซื้อ",
  "title1624": "ผู้ซื้อชำระจริง",
  "title1625": "Doanh số hiệu quả MOM",
  "title1626": "ผู้ซื้อจ่ายค่าจัดส่ง",
  "title1627": "Chi phí tháng trước",
  "title1628": "Chi phí khác",
  "title1629": "Số tiền thanh toán",
  "title1630": "Số tiền thanh toán đánh giá",
  "title1631": "Số tiền thanh toán đánh giá",
  "title1632": "Chi phí đánh giá",
  "title1633": "So sánh chi phí đánh giá",
  "title1634": "Tỷ lệ hối đoái thanh toán đánh giá",
  "title1635": "Phí hoa hồng",
  "title1636": "Chi phí quảng cáo",
  "title1637": "So sánh số tiền trả lại",
  "title1638": "Tổng thu nhập",
  "title1639": "Tổng chi phí",
  "title1640": "Tổng chi phí",
  "title1641": "Lợi nhuận ước tính",
  "title1642": "So sánh lợi nhuận",
  "title1643": "Lợi nhuận trung bình",
  "title1644": "So sánh tỷ suất lợi nhuận",
  "title1645": "Lợi nhuận",
  "title1646": "Biên lợi nhuận chi phí MOM",
  "title1647": "Vui lòng nhập loại tiền tệ",
  "title1648": "Vui lòng nhập tỷ giá",
  "title1649": "Vui lòng nhập số lượng hoàn tiền",
  "title1650": "Vui lòng nhập số lượng đơn hàng trả hàng so với tháng trước",
  "title1651": "Vui lòng nhập số lượng hủy bỏ",
  "title1652": "Vui lòng nhập tỷ lệ trả hàng so với tháng trước",
  "title1653": "Vui lòng nhập số lượng hủy bỏ so với tháng trước",
  "title1654": "Vui lòng nhập tỷ lệ hủy",
  "title1655": "Vui lòng nhập so sánh tỷ lệ hủy",
  "title1656": "Vui lòng nhập số lượng đơn hàng đánh giá",
  "title1657": "Vui lòng nhập so sánh lượng đơn hàng đánh giá",
  "title1658": "Vui lòng nhập số lượng đơn hàng hiệu quả",
  "title1659": "Vui lòng nhập so sánh lượng đơn hàng hiệu quả",
  "title1660": "Vui lòng nhập số lượng bán hàng hiệu quả",
  "title1661": "Vui lòng nhập so sánh lượng bán hàng hiệu quả",
  "title1662": "Vui lòng nhập số tiền đơn hàng",
  "title1663": "Vui lòng nhập số tiền thanh toán thực tế của người mua",
  "title1664": "Vui lòng nhập so sánh doanh số bán hàng hiệu quả",
  "title1665": "Phí vận chuyển do người mua trả",
  "title1666": "Vui lòng nhập tổng chi phí",
  "title1667": "Vui lòng nhập tỷ lệ chi phí",
  "title1668": "Vui lòng nhập chi phí khác",
  "title1669": "Vui lòng nhập chi phí vận chuyển",
  "title1670": "Vui lòng nhập chi phí đóng gói",
  "title1671": "Vui lòng nhập số tiền thanh toán",
  "title1672": "Vui lòng nhập số tiền thanh toán đánh giá",
  "title1673": "Vui lòng nhập số tiền thanh toán đánh giá",
  "title1674": "Vui lòng nhập chi phí đánh giá",
  "title1675": "Vui lòng nhập chi phí đánh giá",
  "title1676": "Vui lòng nhập tỷ lệ chi phí đánh giá",
  "title1677": "Vui lòng nhập tỷ giá thanh toán đánh giá",
  "title1678": "Vui lòng nhập hoa hồng của nền tảng",
  "title1679": "Vui lòng nhập chi phí quảng cáo",
  "title1680": "Vui lòng nhập số tiền trả lại",
  "title1681": "Vui lòng nhập tỷ lệ trả lại",
  "title1682": "Vui lòng nhập giảm giá của người bán",
  "title1683": "Vui lòng nhập tổng doanh thu",
  "title1684": "Vui lòng nhập tổng chi phí",
  "title1685": "Vui lòng nhập tổng chi phí",
  "title1686": "Vui lòng nhập lợi nhuận ước tính",
  "title1687": "Vui lòng nhập tỷ lệ lợi nhuận",
  "title1688": "Vui lòng nhập lợi nhuận trung bình",
  "title1689": "Vui lòng nhập tỷ suất lợi nhuận",
  "title1690": "Vui lòng nhập tỷ lệ lợi nhuận",
  "title1691": "Vui lòng nhập tỷ suất lợi nhuận chi phí",
  "title1692": "Vui lòng nhập tỷ lệ lợi nhuận chi phí",
  "title1693": "Nền không được để trống",
  "title1694": "Khu vực không được để trống",
  "title1695": "Ngày không được để trống",
  "title1696": "Tiền tệ không được để trống",
  "title1697": "Tỷ giá không được để trống",
  "title1698": "Số lượng hoàn trả không được để trống",
  "title1699": "Số lượng đơn hàng trả về của MOM không được để trống",
  "title1700": "Số lượng hủy bỏ không được để trống",
  "title1701": "Tỷ lệ hoàn trả của MOM không được để trống",
  "title1702": "Số lượng hủy bỏ của MOM không được để trống",
  "title1703": "Tỷ lệ hủy bỏ không được để trống",
  "title1704": "Tỷ lệ hủy bỏ của MOM không được để trống",
  "title1705": "Số lượng đánh giá không được để trống",
  "title1706": "Số lượng đánh giá của MOM không được để trống",
  "title1707": "Số lượng đơn hàng hiệu quả không được để trống",
  "title1708": "Số lượng đơn hàng hiệu quả MOM không được để trống",
  "title1709": "Số lượng bán hàng hiệu quả không được để trống",
  "title1710": "Số lượng bán hàng hiệu quả MOM không được để trống",
  "title1711": "Số tiền đơn hàng không được để trống",
  "title1712": "Số tiền thanh toán thực tế của người mua không được để trống",
  "title1713": "Số tiền bán hàng hiệu quả MOM không được để trống",
  "title1714": "Người mua trả phí vận chuyển không được để trống",
  "title1715": "Tổng chi phí sản phẩm không được để trống",
  "title1716": "So sánh chi phí MOM không được để trống",
  "title1717": "Phí khác không được để trống",
  "title1718": "Chi phí vận chuyển không được để trống",
  "title1719": "Chi phí đóng gói không được để trống",
  "title1720": "Số tiền thanh toán không được để trống",
  "title1721": "Số tiền thanh toán đánh giá không được để trống",
  "title1722": "Số tiền thanh toán đánh giá không được để trống",
  "title1723": "Chi phí đánh giá không được để trống",
  "title1724": "Chi phí đánh giá không được để trống",
  "title1725": "Chi phí đánh giá MOM không được để trống",
  "title1726": "Tỷ giá thanh toán đánh giá không được để trống",
  "title1727": "Hoa hồng trên nền tảng không được để trống",
  "title1728": "Chi phí quảng cáo không được để trống",
  "title1729": "Số tiền hoàn lại không được để trống",
  "title1730": "So sánh số tiền hoàn lại MOM không được để trống",
  "title1731": "Giảm giá của người bán không được để trống",
  "title1732": "Tổng doanh thu không được để trống",
  "title1733": "Tổng chi phí không được để trống",
  "title1734": "Tổng chi phí không được để trống",
  "title1735": "Lợi nhuận dự kiến không được để trống",
  "title1736": "Lợi nhuận MOM không được để trống",
  "title1737": "Lợi nhuận trung bình không được để trống",
  "title1738": "Biên lợi nhuận không được để trống",
  "title1739": "Biên lợi nhuận MOM không được để trống",
  "title1740": "Biên lợi nhuận chi phí không được để trống",
  "title1741": "Biên lợi nhuận chi phí của MOM không được để trống",
  "title1742": "Thêm gần đây",
  "title1743": "Lợi nhuận trong số hàng hóa",
  "title1744": "Sửa đổi gần đây",
  "title1745": "Xác nhận xóa gần đây",
  "title1746": "Số lợi nhuận hàng hóa trong số ngày",
  "title1747": "Xác nhận xuất tất cả gần đây",
  "title1748": "Các mục dữ liệu lợi nhuận hàng hóa trong số ngày",
  "title1749": "Ngày dữ liệu",
  "title1750": "Từ khóa quảng cáo đơn",
  "title1751": "Chi phí trung bình mỗi đơn hàng (quảng cáo liên quan)",
  "title1752": "Chọn ngày dữ liệu",
  "title1753": "Vui lòng nhập tổng số khách",
  "title1754": "Vui lòng nhập tổng số đơn hàng",
  "title1755": "Vui lòng nhập khách truy cập quảng cáo từ khóa",
  "title1756": "Vui lòng nhập tỷ lệ click từ khóa",
  "title1757": "Vui lòng nhập quảng cáo từ khóa đơn",
  "title1758": "Vui lòng nhập tỷ lệ chuyển đổi từ khóa",
  "title1759": "Vui lòng nhập chi phí trung bình mỗi đơn hàng",
  "title1760": "Vui lòng nhập quảng cáo từ khóa",
  "title1761": "Vui lòng nhập khách truy cập quảng cáo liên quan",
  "title1762": "Vui lòng nhập đơn hàng quảng cáo liên quan",
  "title1763": "Vui lòng nhập quảng cáo liên quan",
  "title1764": "Vui lòng nhập chi phí trung bình mỗi đơn hàng (quảng cáo liên quan)",
  "title1765": "Vui lòng nhập khách truy cập tự nhiên",
  "title1766": "Vui lòng nhập tỷ lệ click tự nhiên",
  "title1767": "Vui lòng nhập số đơn hàng tự nhiên",
  "title1768": "Vui lòng nhập tỷ lệ chuyển đổi tự nhiên",
  "title1769": "Ngày dữ liệu không được để trống",
  "title1770": "Thêm dữ liệu sản phẩm",
  "title1771": "Sửa đổi dữ liệu sản phẩm",
  "title1772": "Xác nhận xóa số lượng dữ liệu sản phẩm là",
  "title1773": "Xác nhận xuất tất cả các mục dữ liệu sản phẩm",
  "title1774": "Vui lòng nhập bản dịch tiếng Trung",
  "title1775": "Vui lòng nhập lượng tìm kiếm",
  "title1776": "Thêm từ khóa sản phẩm",
  "title1777": "Sửa đổi từ khóa sản phẩm",
  "title1778": "Xác nhận xóa số lượng từ khóa sản phẩm là",
  "title1779": "Xác nhận xuất tất cả các mục dữ liệu từ khóa sản phẩm",
  "title1780": "Từ khóa sản phẩm",
  "title1781": "Điểm bán hàng",
  "title1782": "Nội dung điểm bán hàng",
  "title1783": "Thêm ghi chú điểm bán hàng",
  "title1784": "Sửa đổi ghi chú điểm bán hàng",
  "title1785": "Xác nhận xóa số lượng ghi chú điểm bán hàng là",
  "title1786": "Xác nhận xuất tất cả các mục dữ liệu ghi chú điểm bán hàng",
  "title1787": "Thao tác hàng loạt",
  "title1788": "Đặt trạng thái bán hàng",
  "title1789": "Chỉnh sửa nhóm",
  "title1790": "Đặt nhóm",
  "title1791": "Nhập Xuất",
  "title1792": "Nhập kết hợp mới",
  "title1793": "Xuất sản phẩm kết hợp",
  "title1794": "Nhập file ghép nối",
  "title1795": "Xuất cặp",
  "title1796": "Tìm kiếm hàng loạt",
  "title1797": "Tất cả các nhóm",
  "title1798": "Thông tin sản phẩm con",
  "title1799": "Mở rộng",
  "title1800": "Cửa hàng sản phẩm đã được ghép",
  "title1801": "Mở rộng đã ghép",
  "title1802": "Nhóm",
  "title1803": "Thời gian",
  "title1804": "Cửa hàng đã được ghép",
  "title1805": "Vui lòng nhập tên sản phẩm",
  "title1806": "Vui lòng nhập sản phẩm",
  "title1807": "Nhóm sản phẩm",
  "title1808": "Cài đặt nhóm sản phẩm",
  "title1809": "Vui lòng chọn trạng thái bán hàng",
  "title1810": "Đó có phải là sản phẩm kết hợp không?",
  "title1811": "Vui lòng chọn xem đó có phải là sản phẩm kết hợp không",
  "title1812": "Hủy bỏ lựa chọn",
  "title1813": "Đã chọn",
  "title1814": "Tìm kiếm hàng loạt",
  "title1815": "Vui lòng nhập nhiều",
  "title1816": "Tách bằng dòng",
  "title1817": "Ví dụ",
  "title1818": "Kết hợp",
  "title1819": "Nhập sản phẩm mới",
  "title1820": "Mẫu nhập sản phẩm",
  "title1821": "Nhập ghép sản phẩm",
  "title1822": "Mẫu nhập ghép sản phẩm",
  "title1823": "Sản phẩm con",
  "title1824": "Tên sản phẩm con",
  "title1825": "Tên sản phẩm không được để trống",
  "title1826": "Sự kết hợp của sản phẩm không được để trống",
  "title1827": "Trạng thái bán hàng không được để trống",
  "title1828": "Vui lòng chọn ít nhất một sản phẩm",
  "title1829": "Cài đặt thành công",
  "title1830": "Vui lòng chọn hoạt động sản phẩm",
  "title1831": "Xác nhận xóa sản phẩm? Việc xóa là không thể phục hồi và cũng sẽ xóa đồng thời các mối quan hệ kho và ghép cặp liên quan",
  "title1832": "Xác nhận xuất mối quan hệ ghép sản phẩm",
  "title1833": "Thêm SKU combo",
  "title1834": "Chỉnh sửa sản phẩm kết hợp",
  "title1835": "Đặt trạng thái bán hàng thành công",
  "title1836": "Vui lòng chọn sản phẩm con",
  "title1837": "Vui lòng nhập số lượng sản phẩm con",
  "title1838": "Xác nhận xóa sản phẩm như là",
  "title1839": "Thêm kho",
  "title1840": "Đẩy kho hàng bên thứ 3",
  "title1841": "Thiết lập kho",
  "title1842": "Đặt mức độ hoạt động",
  "title1843": "Nhập đơn mới",
  "title1844": "Nhập cập nhật sản phẩm",
  "title1845": "Xuất sản phẩm",
  "title1846": "Mức độ hoạt động",
  "title1847": "Độ nguyên vẹn của sản phẩm",
  "title1848": "Chọn tất cả",
  "title1849": "Đã chọn",
  "title1850": "Tất cả",
  "title1851": " Kho",
  "title1852": "Lợi nhuận trung bình",
  "title1853": "Dự báo bán hàng hàng ngày",
  "title1854": "Dữ liệu tồn kho (",
  "title1855": "Các cửa hàng đã được ghép",
  "title1856": "Trọng lượng sản phẩm",
  "title1857": "Kích thước sản phẩm",
  "title1858": "Log sản phẩm",
  "title1859": "Dữ liệu bán hàng trực tuyến",
  "title1860": "Chọn kho",
  "title1861": "Nhập tên kho",
  "title1862": "Tên kho",
  "title1863": "Nhập cập nhật sản phẩm",
  "title1864": "Mẫu nhập cập nhật sản phẩm",
  "title1865": "Bán chạy nhất",
  "title1866": "Các mặt hàng đang thịnh hành",
  "title1867": "Các mặt hàng thông thường",
  "title1868": "Các mặt hàng chưa bán",
  "title1869": "Vui lòng sử dụng tài khoản chính hoặc tài khoản quản trị để nhập sản phẩm và xác thực",
  "title1870": "Vui lòng Chọn kho",
  "title1871": "Đã thiết lập kho thành công",
  "title1872": "Bạn có chắc chắn muốn xóa mặt hàng này không",
  "title1873": "Việc xóa là không thể phục hồi và cũng sẽ xóa đồng thời các mối quan hệ kho và ghép cặp liên quan",
  "title1874": "Bạn có chắc chắn muốn đẩy sản phẩm không",
  "title1875": "Số như là",
  "title1876": "Đẩy thành công",
  "title1877": "Thông tin cơ bản",
  "title1878": "Chọn danh mục",
  "title1879": "Link nguồn hàng của nhà cung ứng",
  "title1880": "Nhập liên kết nguồn",
  "title1881": "chép",
  "title1882": "Link nguồn sản phẩm",
  "title1883": "Nhập liên kết nguồn",
  "title1884": "Mô tả sản phẩm",
  "title1885": "Chèn hình ảnh",
  "title1886": "Ghi chú: Mô tả sản phẩm có thể chèn tối đa 12 hình ảnh",
  "title1887": "Không có hình nào được chèn",
  "title1888": "Thuộc tính sản phẩm",
  "title1889": "Thương hiệu",
  "title1890": "Nhiều hơn",
  "title1891": "Thông tin sản phẩm",
  "title1892": "Loại sản phẩm",
  "title1893": "Phân loại đơn độc",
  "title1894": "Phân loại nhiều",
  "title1895": "Giá khuyến mãi",
  "title1896": "Chọn chương trình ",
  "title1897": " Tải lại ",
  "title1898": "Nhập số lượng tồn kho",
  "title1899": "Giá bán buôn",
  "title1900": "Thêm giá bán buôn",
  "title1901": "Phương tiện hình ảnh",
  "title1902": "Ảnh sản phẩm",
  "title1903": "Chọn hình ảnh",
  "title1904": "Định dạng hình ảnh: Vui lòng tải lên kích thước tệp không vượt quá",
  "title1905": "với định dạng",
  "title1906": "; Kích thước hình ảnh không được nhỏ hơn 100",
  "title1907": "Thêm video",
  "title1908": "Định dạng video: Vui lòng tải lên kích thước tệp không vượt quá",
  "title1909": "Thời lượng 10",
  "title1910": "tệp",
  "title1911": "Vui lòng xuất bản sản phẩm trong vòng 7 ngày sau khi tải lên video, nếu không video sẽ bị xóa tự động.",
  "title1912": "Đóng gói logistics",
  "title1913": "Phí vận chuyển",
  "title1914": "Nhập phí vận chuyển",
  "title1915": "Nhập trọng lượng sản phẩm",
  "title1916": "Chiều dài",
  "title1917": "cao",
  "title1918": "Pre-order",
  "title1919": "Tình trạng mặt hàng",
  "title1920": "Mới",
  "title1921": "Second hand",
  "title1922": "Tạo tên sản phẩm thông minh",
  "title1923": "Tuyên bố miễn trừ trách nhiệm: Tất cả nội dung được tạo ra bởi dịch vụ này đều được tạo ra tự động bởi mô hình. Chúng tôi không thể đảm bảo tính chính xác và đầy đủ của nó. Nội dung được tạo ra chỉ để tham khảo và không đại diện cho quan điểm và thái độ của công ty. 新舰 sẽ không chịu trách nhiệm về bất kỳ tổn thất trực tiếp hoặc gián tiếp nào phát sinh từ việc sử dụng dịch vụ này.",
  "title1924": "Mô hình được tạo tự động. Chúng tôi không thể đảm bảo tính chính xác và đầy đủ của nó. Nội dung được tạo ra chỉ để tham khảo, không đại diện cho quan điểm và thái độ của Xinjian. Xinjian sẽ không chịu trách nhiệm về bất kỳ tổn thất trực tiếp hoặc gián tiếp nào phát sinh từ việc sử dụng dịch vụ này.",
  "title1925": "Lịch lãm và rộng lượng",
  "title1926": "Thêm từ khóa mới",
  "title1927": "Mô tả hình ảnh trong đầu của bạn, từ khóa bao gồm thương hiệu, chất liệu, tính năng chính và các kịch bản sử dụng, v.v.",
  "title1928": "Ngôn ngữ kết quả được tạo ra",
  "title1929": "Số lần sử dụng miễn phí trong ngày hôm nay",
  "title1930": "lần, có sẵn trả tiền",
  "title1931": "Vui lòng nhấp vào 'Bắt đầu Tạo ' ở bên trái trước tiên, kết quả được tạo ra sẽ được hiển thị tự động ở đây.",
  "title1932": "Số thứ tự",
  "title1933": "Áp dụng",
  "title1934": "Tạo mô tả sản phẩm thông minh",
  "title1935": "Kết quả được tạo ra",
  "title1936": "Vui lòng chọn một danh mục",
  "title1937": "Vui lòng nhập từ khóa tìm kiếm",
  "title1938": "Chỉnh sửa danh mục hàng loạt",
  "title1939": "Đặt tất cả các mục đã chọn vào cùng một danh mục và thuộc tính.",
  "title1940": "Chỉnh sửa thuộc tính hàng loạt",
  "title1941": "Tổng cộng 3 sản phẩm đã được chọn. Vui lòng chỉnh sửa các thuộc tính tương ứng của chúng.",
  "title1942": "Chỉnh sửa hình ảnh hàng loạt",
  "title1943": "Mỗi sản phẩm hỗ trợ tối đa 9 hình ảnh sản phẩm. Kéo hình ảnh để sắp xếp lại.",
  "title1944": "Bảng kích thước",
  "title1945": "Hình ảnh sản phẩm",
  "title1946": "Kéo để sắp xếp",
  "title1947": "Thêm địa phương",
  "title1948": "Chọn hình ảnh không gian",
  "title1949": "Chọn hình ảnh thu thập",
  "title1950": "Thêm hình ảnh thu thập",
  "title1951": "Tùy chọn 1",
  "title1952": "Bánh vàng",
  "title1953": "Tùy chọn 2",
  "title1954": "Sữa hai lớp",
  "title1955": "Tùy chọn 3",
  "title1956": "Bánh xèo hàu",
  "title1957": "Tùy chọn 4",
  "title1958": "Mì râu rồng",
  "title1959": "Tùy chọn 5",
  "title1960": "Vịt quay Bắc Kinh",
  "title1961": "【Siêu cỡ】hộp cát cho mèo",
  "title1962": "Hộp: Không gian riêng tư độc quyền cho mèo, làm cho chúng khỏe mạnh và hạnh phúc hơn!",
  "title1963": "Khác",
  "title1964": "Chọn sản phẩm",
  "title1965": "Tải lên",
  "title1966": "Thêm SKU hàng hoá",
  "title1967": "Chỉnh sửa Danh mục",
  "title1968": "Chỉnh sửa Thuộc tính",
  "title1969": "Thêm Watermark",
  "title1970": "Trước khi xuất bản, hãy chắc chắn chỉnh sửa thông tin sản phẩm để tránh thông tin giống nhau với sản phẩm gốc, từ đó tránh phạt từ nền tảng.",
  "title1971": "Con",
  "title1972": "Mở rộng Tất cả Biến thể",
  "title1973": "Hẹn giờ tải lên",
  "title1974": "Đã đẩy, vui lòng kiểm tra trạng thái đẩy sau",
  "title1975": "Trang hiện tại chỉ đẩy sản phẩm",
  "title1976": "Đến các kho bên thứ ba, chỉ sản phẩm con được đẩy cho các sản phẩm gom nhóm",
  "title1977": "Nếu",
  "title1978": "Nếu mã không có trong kho bên thứ ba, sản phẩm này sẽ được tạo ra. Nếu có, cập nhật sản phẩm này.",
  "title1979": "Nhà cung cấp dịch vụ khác nhau, sản phẩm",
  "title1980": "Có thể có sự khác biệt trong các tiêu chuẩn đặt tên và các đặt tên không phù hợp sẽ dẫn đến sự thất bại trong việc đẩy.",
  "title1981": "Cập nhật thông tin",
  "title1982": "Thời gian đẩy",
  "title1983": "Thông tin sản phẩm đã được cập nhật",
  "title1984": "Đồng bộ sản phẩm",
  "title1985": "Tạo ngẫu nhiên nhanh",
  "title1986": "Tạo sản phẩm",
  "title1987": "Kết hợp sản phẩm",
  "title1988": "Tự động ghép nối",
  "title1989": "Cài đặt quy tắc ghép nối",
  "title1990": "Xóa mối quan hệ ghép nối",
  "title1991": "Đã được ghép cặp",
  "title1992": "Đã được đưa lên kệ",
  "title1993": "Thông tin sản phẩm trực tuyến",
  "title1994": "Đã ghép nối",
  "title1995": "Chưa ghép nối",
  "title1996": "Thông tin sản phẩm địa phương",
  "title1997": "Ghép đôi",
  "title1998": "Ghép đôi lại",
  "title1999": "Thêm quy tắc",
  "title2000": "Các quy tắc được thực hiện từ trên xuống dưới theo thứ tự",
  "title2001": "Nhập tên quy tắc",
  "title2002": "Bỏ qua tiền tố",
  "title2003": "Ký tự",
  "title2004": "Số chữ số",
  "title2005": "Dấu phân cách",
  "title2006": "Khi có nhiều dấu phân cách được tìm thấy, sử dụng",
  "title2007": "Lấy bên trái nhất",
  "title2008": "Lấy bên phải nhất",
  "title2009": "Bỏ qua hậu tố",
  "title2010": "Không bỏ qua",
  "title2011": "bỏ qua",
  "title2012": "bỏ qua",
  "title2013": "Thay thế ký tự",
  "title2014": "Nhập ký tự",
  "title2015": "Thay thế bằng",
  "title2016": "Nhập ký tự thay thế",
  "title2017": "Bỏ qua ký tự",
  "title2018": "Nhập ký tự bị bỏ qua, mỗi dòng một",
  "title2019": "Ví dụ",
  "title2020": "Bỏ qua khoảng trắng",
  "title2021": "Kiểm tra quy tắc",
  "title2022": "Vui lòng nhập mã sản phẩm",
  "title2023": "Kiểm tra",
  "title2024": "Kết quả kiểm tra",
  "title2025": "Kết quả kiểm tra",
  "title2026": "Dịch",
  "title2027": "Kết hợp và chỉnh sửa",
  "title2028": "Kết hợp",
  "title2029": "Và",
  "title2030": "Lưu",
  "title2031": "Lưu",
  "title2032": "Thể loại",
  "title2033": "Default Warehouse",
  "title2034": "Hoàn tất việc tạo ra",
  "title2035": "Đang đồng bộ hóa sản phẩm trực tuyến, vui lòng đợi",
  "title2036": "Xác nhận bắt đầu đồng bộ hóa sản phẩm trực tuyến",
  "title2037": "Dự kiến hoàn thành việc đồng bộ hóa sản phẩm trực tuyến trong 1-3 phút. Sau khi đồng bộ hóa hoàn tất, bạn có thể dễ dàng xem thông tin sản phẩm đã được cập nhật.",
  "title2038": "Tự động nhận dạng cặp",
  "title2039": "Quy tắc ghép cặp",
  "title2040": "Phù hợp【Sản phẩm trực tuyến",
  "title2041": "Và [Sản phẩm địa phương",
  "title2042": "Hoàn toàn giống nhau",
  "title2043": "Theo sản phẩm trực tuyến",
  "title2044": "Ghép cặp, không thể kết hợp nếu sản phẩm trực tuyến trống",
  "title2045": "Bỏ qua tiền tố và hậu tố của 【Sản phẩm trực tuyến",
  "title2046": "Giống nhau",
  "title2047": "Nhập nội dung bị bỏ qua, phân tách nhiều bằng dấu phẩy",
  "title2048": "Cắt ngắn từ 【Sản phẩm trực tuyến",
  "title2049": "Cắt ở vị trí thứ",
  "title2050": "Nhập số ký tự cần cắt",
  "title2051": "đến",
  "title2052": "ký tự",
  "title2053": "Bỏ qua các ký tự được chỉ định sau 【Sản phẩm trực tuyến",
  "title2054": "Ký tự được chỉ định",
  "title2055": "Nhập nội dung được chỉ định, phân tách nhiều bằng dấu phẩy",
  "title2056": "Chỉ định ký tự",
  "title2057": "Nhập nội dung đã chỉ định, phân tách bằng dấu phẩy",
  "title2058": "Không phân biệt chữ hoa/thường",
  "title2059": "Phạm vi sản phẩm khớp",
  "title2060": "Sản phẩm trang hiện tại",
  "title2061": "Tất cả sản phẩm lọc kết quả",
  "title2062": "Sản phẩm đã chọn",
  "title2063": "Bắt đầu",
  "title2064": "Tự động nhận dạng kết quả ghép đôi",
  "title2065": "Ghép cặp tự động thành công",
  "title2066": "Sản phẩm, xác nhận lưu",
  "title2067": "Xóa theo lô",
  "title2068": "Ghép thông tin sản phẩm địa phương",
  "title2069": "Xóa",
  "title2070": "Bước trước",
  "title2071": "Chưa được niêm yết",
  "title2072": "Ký tự cố định ở đầu",
  "title2073": "Số chữ số cố định ở đầu",
  "title2074": "Ký tự trước dấu phân cách cố định",
  "title2075": "Tên quy tắc không được trống",
  "title2076": "Xác nhận ghép cặp tự động sản phẩm đã chọn",
  "title2077": "sản phẩm",
  "title2078": "Chứa bản sao",
  "title2079": "Xác nhận hủy ghép",
  "title2080": "Hủy ghép thành công",
  "title2081": "Đang đồng bộ sản phẩm trực tuyến, vui lòng kiểm tra lại sau",
  "title2082": "Vui lòng cấu hình quy tắc ghép đôi trước",
  "title2083": "Ghép thành công",
  "title2084": "Quy tắc lưu thành công",
  "title2085": "Vui lòng sử dụng tài khoản chính hoặc tài khoản quản trị để tạo sản phẩm và cấp phép",
  "title2086": "Ghép cặp thành công",
  "title2087": "Kệ",
  "title2088": "Thêm một vị trí",
  "title2089": "Thêm hàng loạt",
  "title2090": "In  Label vị trí",
  "title2091": "Tồn kho có sẵn",
  "title2092": "Nhấn để thu gọn",
  "title2093": "Mở rộng phần còn lại",
  "title2094": "Kho",
  "title2095": "Tên vị trí",
  "title2096": "Loại:",
  "title2097": "Số kệ",
  "title2098": "Trống",
  "title2099": "Đã cất giữ",
  "title2100": "Đã đầy",
  "title2101": "Tạo mới",
  "title2102": "Cập nhật",
  "title2103": "Dọn dẹp",
  "title2104": "Tên vị trí kệ không thể để trống",
  "title2105": "Sức chứa tối đa",
  "title2106": "Vui lòng nhập dung lượng tối đa",
  "title2107": "Tiền tố",
  "title2108": "Cột",
  "title2109": "Tầng",
  "title2110": "Dấu gạch nối",
  "title2111": "Xem trước",
  "title2112": "Có thể dọn dẹp",
  "title2113": "Số kệ không thể trống",
  "title2114": "Vui lòng nhập số cột",
  "title2115": "Vui lòng nhập số tầng",
  "title2116": "Tạo vị trí",
  "title2117": "Xác nhận xóa vị trí kệ?",
  "title2118": "Sửa thông tin kệ",
  "title2119": "Xác nhận dọn dẹp số lượng bằng 0?",
  "title2120": "?",
  "title2121": "Dọn dẹp thành công",
  "title2122": "thất bại",
  "title2123": "Đồng bộ kho hàng bên thứ ba",
  "title2124": "In ấn nhãn",
  "title2125": "Sửa giá thành phẩm",
  "title2126": "Sửa giá đóng gói",
  "title2127": "Nhập giá sản phẩm",
  "title2128": "Nhập giá đóng gói",
  "title2129": "Xuất kho sản phẩm",
  "title2130": "Nhập chi phí",
  "title2131": "Tình trạng tồn kho",
  "title2132": "Tổng số hàng tồn kho",
  "title2133": "Tổng giá trị hàng tồn kho",
  "title2134": "Số lượng đóng băng",
  "title2135": "Giá trị đóng băng",
  "title2136": "Số lượng đang vận chuyển",
  "title2137": "Giá trị đang vận chuyển",
  "title2138": "Mua",
  "title2139": "Vị trí",
  "title2140": "Giá trị tồn kho",
  "title2141": "Doanh số trung bình hàng ngày",
  "title2142": " bán hàng hàng ngày(Động)",
  "title2143": "Lượng tồn an toàn",
  "title2144": "Ngày hết hàng",
  "title2145": "Số lượng mua đề xuất khi hết hàng",
  "title2146": "Số lượng mua đề xuất khi thiếu",
  "title2147": "Số lượng thiếu",
  "title2148": "Ngày mua đề xuất",
  "title2149": "Thời gian dự kiến đến hàng mới nhất",
  "title2150": "Cài đặt vị trí",
  "title2151": "Vui lòng nhập giá sản phẩm",
  "title2152": "Số lượng bán gần đây",
  "title2153": "Số lượng bán gần đây",
  "title2154": "Vui lòng nhập số lượng bán gần đây",
  "title2155": "Sửa đổi thông số tồn kho đại trà",
  "title2156": "Số ngày sản xuất",
  "title2157": "Vui lòng nhập số ngày sản xuất",
  "title2158": "Số ngày vận chuyển bằng biển",
  "title2159": "Vui lòng nhập số ngày vận chuyển bằng biển",
  "title2160": "Số ngày dự phòng",
  "title2161": "Vui lòng nhập số ngày dự phòng",
  "title2162": "Công thức tính tồn kho an toàn",
  "title2163": "(Số ngày dự phòng)",
  "title2164": "Số lượng bán trung bình hàng ngày trong 15 ngày gần đây",
  "title2165": "Nhập kho sản phẩm",
  "title2166": "Mẫu nhập kho sản phẩm",
  "title2167": "Mẫu nhập chi phí đóng gói sản phẩm",
  "title2168": "Tổng số lượng tồn kho của kho của bên thứ ba",
  "title2169": "Sai khác về tồn kho giữa hai bên",
  "title2170": "Tồn kho có sẵn được cập nhật mong đợi",
  "title2171": "Tình trạng khóa kho",
  "title2172": "Tổng số lượng đã khóa:",
  "title2173": "Đã khóa",
  "title2174": "Nhập nhiều sản phẩm",
  "title2175": "Phân tách bằng dấu xuống dòng",
  "title2176": "Tách bằng dấu phẩy hoặc xuống dòng",
  "title2177": "Sửa đổi chi phí sản phẩm hàng loạt",
  "title2178": "Dưới mức cảnh báo",
  "title2179": "Số lượng bán hàng ngày mặc định",
  "title2180": "Cập nhật thành công",
  "title2181": "Vui lòng chọn tác vụ dữ liệu",
  "title2182": "Sửa đổi chi phí đóng gói hàng loạt",
  "title2183": "Chỉnh sửa tồn kho an toàn",
  "title2184": "Tồn kho của",
  "title2185": "Xác nhận xóa sản phẩm được chọn trong kho hàng",
  "title2186": "Xác nhận xóa số lượng tồn kho của sản phẩm với mã",
  "title2187": "Xác nhận cài đặt vị trí kệ",
  "title2188": "Hoạt động thành công",
  "title2189": "Tồn kho sản phẩm -",
  "title2190": "Tải lên bảng thông tin đã hoàn tất",
  "title2191": "Xin vui lòng trước",
  "title2192": "Xuất dữ liệu biểu mẫu",
  "title2193": "Tên cột không thể thay đổi, hàng cột không thể xóa",
  "title2194": "Phần mở rộng tệp phải là",
  "title2195": "nghĩa là",
  "title2196": "định dạng), kích thước tệp không được lớn hơn",
  "title2197": "Hỗ trợ nhập tối đa",
  "title2198": "số dữ liệu",
  "title2199": "Tải lên file",
  "title2200": "Tải xuống mẫu",
  "title2201": "Nhập thành công! Bạn đã nhập thành công",
  "title2202": "số lượng dữ liệu.",
  "title2203": "Không có dữ liệu trong tệp, vui lòng kiểm tra.",
  "title2204": "Nhập không thành công! Vui lòng kiểm tra báo cáo lỗi, hoặc kiểm tra xem mẫu có sai sót trước khi nhập lại.",
  "title2205": "Tải xuống báo cáo lỗi",
  "title2206": "Nhập lại",
  "title2207": "File lỗi",
  "title2208": "Vui lòng tải lên tệp",
  "title2209": "In-Stock Value",
  "title2210": "Days Available for Sale",
  "title2211": "Value in Transit",
  "title2212": "Xem trước dữ liệu",
  "title2213": "Nhập dữ liệu",
  "title2214": "Đã nhập xong",
  "title2215": "Cài đặt tuổi tồn kho",
  "title2216": "Tổng tồn kho",
  "title2217": "Tổng chi phí tồn kho",
  "title2218": "Tuổi trung bình của hàng tồn kho",
  "title2219": "In-stock Cost",
  "title2220": "Số nhóm",
  "title2221": "Ngày tối thiểu",
  "title2222": "Ngày tối đa",
  "title2223": "Nhóm thứ nhất",
  "title2224": "Nhóm thứ hai",
  "title2225": "Nhóm thứ ba",
  "title2226": "Nhóm thứ tư",
  "title2227": "Nhóm thứ năm",
  "title2228": "ngày trở lên",
  "title2229": "Số ngày là số nguyên lớn hơn 0",
  "title2230": "Ngày tối đa của nhóm thứ nhất không được trống",
  "title2231": "Ngày tối đa của nhóm thứ hai không được trống",
  "title2232": "Ngày tối đa của nhóm thứ ba không được trống",
  "title2233": "Ngày tối đa của nhóm thứ tư không được trống",
  "title2234": "Cài đặt số ngày đặt hàng",
  "title2235": "Ngày mua hàng đề xuất",
  "title2236": "Ngày dự kiến hết hàng",
  "title2237": "Thời gian nhận hàng gần đây",
  "title2238": "Hình ảnh chính",
  "title2239": "Đặc điểm mua",
  "title2240": "Tồn kho kho nước ngoài",
  "title2241": "Trung bình hàng ngày",
  "title2242": "Số ngày quay vòng",
  "title2243": "Ngày dự kiến hết hàng",
  "title2244": "Số lượng mua đề xuất",
  "title2245": "Số lượng đang vận tải",
  "title2246": "Số lượng đến dự kiến gần đây",
  "title2247": "Chi phí sản phẩm không được để trống",
  "title2248": "Doanh số bán hàng trong 15 ngày gần đây",
  "title2249": "Tồn kho thực tế",
  "title2250": "Số lượng mua đề xuất",
  "title2251": "Tồn kho an toàn - Tồn trong quá trình vận chuyển - Tồn kho thực tế",
  "title2252": "Mua hàng trên đường",
  "title2253": "Chuyển phát trên đường",
  "title2254": "Xác nhận xuất tất cả các mục dữ liệu tồn kho sản phẩm",
  "title2255": "Tồn kho sản phẩm",
  "title2256": "Tồn kho trên nền tảng",
  "title2257": "Mở trang web",
  "title2258": "Đã xử lý",
  "title2259": "Xác nhận hoạt động đã được xử lý",
  "title2260": "Báo cáo lợi nhuận hàng tháng",
  "title2261": "Báo cáo lợi nhuận hoạt động hàng tháng",
  "title2262": "Quản lý nhận hàng",
  "title2263": "Kho cho các đơn hàng mua từ bãi phát hàng của bên thứ ba không hỗ trợ việc nhận hàng thủ công và hệ thống sẽ tự động đồng bộ kết quả nhận hàng từ bãi phát hàng của bên thứ ba",
  "title2264": "Kho giao hàng",
  "title2265": "Kho nhận hàng",
  "title2266": "Thời gian gửi hàng",
  "title2267": "Thời gian nhận hàng",
  "title2268": "Số lượng chuyển",
  "title2269": "Số lượng nhận",
  "title2270": "Mã phiếu chuyển kho",
  "title2271": "Ghi chú chuyển",
  "title2272": "Mã vận đơn",
  "title2273": "Tổng Số lượng chuyển",
  "title2274": "Trạng thái nhận hàng",
  "title2275": "Chờ nhận hàng",
  "title2276": "Nhận hàng một phần",
  "title2277": "Đã nhận hàng",
  "title2278": "Xác nhận nhận hàng",
  "title2279": "Số lượng đặt",
  "title2280": "Tên đơn đặt hàng",
  "title2281": "Ghi chú mua",
  "title2282": "Mã đơn đặt hàng",
  "title2283": "Số lượng đặt",
  "title2284": "Chỉnh sửa ghi chú",
  "title2285": "Vui lòng điền vào ghi chú",
  "title2286": "Nhận hàng chuyển kho",
  "title2287": "Mã phiếu chuyển kho：",
  "title2288": "Số lượng đã nhận",
  "title2289": "Không có vị trí ",
  "title2290": "Nhận hàng đặt hàng",
  "title2291": "Kho nhận hàng",
  "title2292": "Ngày vận chuyển",
  "title2293": "Chi phí first-mile",
  "title2294": "Chi phí thuế",
  "title2295": "Phí khác",
  "title2296": "Số lượng sản phẩm:",
  "title2297": "Tổng số:",
  "title2298": "Tổng số tiền",
  "title2299": "Thể tích",
  "title2300": "Cân nặng",
  "title2301": "Kho gửi không thể để trống",
  "title2302": "Kho nhận không thể để trống",
  "title2303": "Vui lòng chọn dự án",
  "title2304": "Thay đổi kho gửi sẽ xóa các mục đã thêm, bạn có chắc muốn cập nhật không?",
  "title2305": "Vui lòng nhập số lượng nhận",
  "title2306": "Nhận thành công",
  "title2307": "Chuyển kho",
  "title2308": "Báo cáo",
  "title2309": "Vui lòng nhập tên đơn đặt hàng",
  "title2310": "Ngày tạo",
  "title2311": "Đơn giá",
  "title2312": "Tổng số tiền mua",
  "title2313": "Số lượng nhập kho",
  "title2314": "Chọn đơn đặt hàng",
  "title2315": "Gợi ý hết hàng",
  "title2316": "Đơn đặt hàng",
  "title2317": "Thêm đơn đặt hàng",
  "title2318": "Đẩy đơn đặt hàng",
  "title2319": "Gửi theo lô",
  "title2320": "Không đặt hàng",
  "title2321": "Hoàn nguyên",
  "title2322": "Đặt quy tắc",
  "title2323": "Đề xuất hết hàng",
  "title2324": "Số ngày đặt hàng",
  "title2325": "Thời gian ước lượng từ đặt hàng đến nhập kho, số ngày",
  "title2326": "Số ngày vận chuyển",
  "title2327": "Số ngày an toàn",
  "title2328": "Ngày dự trữ được thiết lập để ngăn chặn các sản phẩm mua không kịp thời đến",
  "title2329": "Tổng doanh số trong 3 ngày",
  "title2330": "Doanh số trung bình hàng ngày trong 3 ngày",
  "title2331": "Số lượng đề xuất",
  "title2332": "Lớn hơn giữa đề xuất hết hàng và đề xuất hết hàng",
  "title2333": "Thời gian cung cấp",
  "title2334": "Thời gian cung cấp - Hàng tồn kho có sẵn - Số lượng đang vận chuyển",
  "title2335": "Số lượng kế hoạch",
  "title2336": "Tổng số lượng mua",
  "title2337": "Dự kiến ​​đến",
  "title2338": "Danh sách sản phẩm",
  "title2339": "Vui lòng nhập danh sách sản phẩm",
  "title2340": "Dự kiến ngày hàng về",
  "title2341": "Vui lòng chọn ngày",
  "title2342": "Vui lòng nhập số vận đơn",
  "title2343": "Vui lòng nhập phí vận chuyển",
  "title2344": "Chọn phương pháp phân bổ chi phí",
  "title2345": "Vui lòng nhập phí đầu",
  "title2346": "Vui lòng nhập phí thuế",
  "title2347": "Vui lòng nhập các khoản phí khác",
  "title2348": "Ý kiến đánh giá",
  "title2349": "Nhập ý kiến khi từ chối",
  "title2350": "Vui lòng nhập ý kiến đánh giá",
  "title2351": "Vui lòng nhập ghi chú",
  "title2352": "Phân bổ chi phí",
  "title2353": "Chi phí nhập kho",
  "title2354": "Nhập đơn mua hàng",
  "title2355": "Mẫu nhập đơn mua hàng",
  "title2356": "Kho không được để trống",
  "title2357": "Tên đơn mua hàng không thể trống",
  "title2358": "Danh sách sản phẩm không thể trống",
  "title2359": "Thêm đơn đặt hàng",
  "title2360": "Xem Đơn Đặt Hàng",
  "title2361": "Chỉnh Sửa Đơn Đặt Hàng",
  "title2362": "Gửi thành công",
  "title2363": "Bạn có chắc muốn hủy đơn đặt hàng?",
  "title2364": "Bạn có chắc muốn nhận và đưa vào kho không?",
  "title2365": "Bạn có chắc muốn xóa đơn đặt hàng có tên",
  "title2366": "của đơn đặt hàng?",
  "title2367": "Bạn có chắc muốn xuất tất cả các mục dữ liệu đơn đặt hàng không?",
  "title2368": "Bạn có chắc muốn xuất gợi ý mua hàng không?",
  "title2369": "Tổng Quan Trả Hàng",
  "title2370": "Báo Cáo Trả Hàng",
  "title2371": "Chi Tiết Trả Hàng",
  "title2372": "Thống kê Dữ liệu Lợi nhuận Cửa hàng",
  "title2373": "Dễ dàng phân tích và thống kê tình trạng vận hành của cửa hàng, giúp chủ cửa hàng hiểu về tình hình lợi nhuận của cửa hàng và tối ưu hóa chiến lược kinh doanh để tăng lợi nhuận.",
  "title2374": "Chọn tất cả",
  "title2375": "Vui lòng chọn nhân viên vận hành",
  "title2376": "Chọn ngày",
  "title2377": "Thu nhập đơn hàng",
  "title2378": "Chi tiết thu nhập",
  "title2379": "Báo cáo",
  "title2380": "Số tiền sản phẩm",
  "title2382": "Trợ phí nền tảng",
  "title2383": "Phí vận chuyển tài trợ bởi",
  "title2384": "Giá vốn",
  "title2385": "Chi Tiết Chi Phí",
  "title2386": "Phí Quảng Cáo",
  "title2387": "Chi Tiết Khuyến Mãi",
  "title2388": "Phí Quảng Cáo Trên Trang",
  "title2389": "Phí Quảng Cáo Ngoài Trang",
  "title2390": "Số Tiền Thanh Toán Đánh Giá",
  "title2391": "Số Tiền Hoàn Trả Đánh Giá",
  "title2392": "Phí Vận Hành",
  "title2393": "Phí Gói Trống",
  "title2394": "Số Tiền Thanh Toán Đánh Giá - Số Tiền Hoàn Trả Đánh Giá",
  "title2395": "Chi Tiết Khác",
  "title2396": "Điều Chỉnh Liên Tháng",
  "title2397": "Tổng Điều Chỉnh cho Đơn Hàng với Tình Hình Thanh Toán Liên Tháng",
  "title2398": "Điều Chỉnh Nền Tảng",
  "title2399": "Tổng phí cho điều chỉnh nền tảng",
  "title2400": "Chi Phí Cửa Hàng",
  "title2401": "Chi tiết lợi nhuận",
  "title2402": "Tỷ suất lợi nhuận",
  "title2403": "Phân Phối Tỷ Lệ Chi Phí",
  "title2404": "Phân Phối Chi Phí theo Tỷ Lệ Bán Hàng",
  "title2405": "Dữ Liệu Lợi Nhuận Cửa Hàng",
  "title2406": "Theo Thời Gian Thanh Toán",
  "title2407": "Theo Thời Gian Đặt Hàng",
  "title2408": "Vui Lòng Chọn Tháng để Cập Nhật",
  "title2409": "Báo Cáo Lợi Nhuận Hàng Tháng -",
  "title2410": "Cửa Hàng Đã Được Ủy Quyền Thành Công",
  "title2411": "Chúc mừng bạn, bạn đã thành công trong việc ủy quyền cửa hàng",
  "title2412": "Trước Khi Người Mua Ký Nhận",
  "title2413": "Đối với các đơn đặt hàng trong đó kho vận chuyển được chỉ định là kho bên thứ ba, kho nhận hàng sẽ mặc định thành kho vận chuyển khi trả hàng. Sau khi kho bên thứ ba nhận hàng thành công, hệ thống sẽ tự động đồng bộ hóa kết quả nhận hàng của kho bên thứ ba.",
  "title2414": "Đồng bộ đơn hàng",
  "title2415": "Đẩy đơn hàng trả lại",
  "title2416": "Trạng thái nhập kho",
  "title2417": "Hạn chót",
  "title2418": "Số đơn hàng sản phẩm:",
  "title2419": "Lý do trả hàng",
  "title2420": "Thời gian đơn hàng",
  "title2421": "Thời gian hậu mã",
  "title2422": "Trạng thái sàn TMĐT",
  "title2423": "Trong",
  "title2424": "Phản hồi Trong",
  "title2425": "Xin vui lòng",
  "title2426": "Phản hồi Trước",
  "title2427": "ID hàng trả:",
  "title2428": "ID hàng trả:",
  "title2429": "Vui lòng nhập số đơn hàng trả lại",
  "title2430": "Vui lòng nhập lý do",
  "title2431": "Trạng thái trả hàng",
  "title2432": "Vui lòng Chọn Tạo từ Điển",
  "title2433": "Trả về Trạng Thái Cục Bộ",
  "title2434": "Thời Gian Tạo Đơn Hàng Trở Lại",
  "title2435": "Chọn Thời Gian Tạo Đơn Hàng Trở Lại",
  "title2436": "Thời Gian Cập Nhật Đơn Hàng Trở Lại",
  "title2437": "Chọn Thời Gian Cập Nhật Đơn Hàng Trở Lại",
  "title2438": "Số đơn hàng trả lại không được để trống",
  "title2439": "Lý do không được để trống",
  "title2440": "Trạng thái trả lại không được để trống",
  "title2441": "Trạng thái trả lại không được để trống",
  "title2442": "Trạng thái địa phương trả lại không được để trống",
  "title2443": "Thời gian tạo đơn hàng trả lại không được để trống",
  "title2444": "Thời gian cập nhật đơn hàng trả lại không được để trống",
  "title2445": "Thời gian tạo đơn hàng trả lại không thể trống",
  "title2446": "Thời gian cập nhật đơn hàng trả lại không thể trống",
  "title2447": "Thêm đơn hàng trả lại",
  "title2448": "Chỉnh sửa đơn hàng trả lại",
  "title2449": "Bạn có chắc chắn muốn xóa số đơn hàng trả lại là",
  "title2450": "Bạn có chắc chắn muốn xuất tất cả các mục dữ liệu đơn hàng trả lại",
  "title2451": "Đơn hàng trả lại",
  "title2452": "Tất cả các trang",
  "title2453": "Liệu",
  "title2454": "Người xử lý",
  "title2455": "Xử lý vào kho",
  "title2456": "Xem chi tiết",
  "title2457": "Thêm ghi chú",
  "title2458": "Lý do trả hàng",
  "title2459": "Chi tiết hàng đã được lưu trữ",
  "title2460": "Xử lý lưu trữ",
  "title2461": "Có lưu trữ không",
  "title2462": "Nhập kho",
  "title2463": "Không lưu trữ tất cả",
  "title2464": "Một khi được xử lý thành kho, các đơn hàng trả lại không thể hủy bỏ để lưu trữ",
  "title2465": "Vui lòng xử lý cẩn thận",
  "title2466": "Số bưu kiệ",
  "title2467": "Đóng",
  "title2468": "闭",
  "title2469": "Số tiền bồi thường",
  "title2470": "Nhập ghi chú",
  "title2471": "Ngày ghi chú",
  "title2472": "Thông tin ghi chú",
  "title2473": "Lý do trả hàng",
  "title2474": "Vui lòng nhập số nguyên dương",
  "title2475": "Số đơn hàng hậu mãi",
  "title2476": "Số đơn hàng sản phẩm",
  "title2477": "Vui lòng chọn liệu muốn lưu trữ",
  "title2478": "Quản lý trả hàng",
  "title2479": "Nhập tên vai trò",
  "title2480": "Trạng thái vai trò",
  "title2481": "Tên vai trò",
  "title2482": "Quyền chức năng",
  "title2483": "Authority of Distribution",
  "title2484": "Quyền dữ liệu",
  "title2485": "Vui lòng nhập tên vai trò",
  "title2486": "Nhận dạng vai trò",
  "title2487": "Nhập nhận dạng vai trò",
  "title2488": "Thứ tự vai trò",
  "title2489": "Nhập thông tin ghi chú",
  "title2490": "Quyền menu",
  "title2491": "Đang tải, vui lòng chờ",
  "title2492": "Phân quyền dữ liệu",
  "title2493": "Phạm vi quyền hạn",
  "title2494": "Liên kết cha-con",
  "title2495": "Chọn nút cha, tự động chọn nút con",
  "title2496": "Tên vai trò không được để trống",
  "title2497": "Mã nhận dạng vai trò không được để trống",
  "title2498": "Thứ tự vai trò không được để trống",
  "title2499": "Bật",
  "title2500": "Tắt",
  "title2501": "Xác nhận cho vai trò?",
  "title2502": "Biệt danh cửa hàng",
  "title2503": "Thêm vai trò",
  "title2504": "Chỉnh sửa vai trò",
  "title2505": "Sửa đổi thành công",
  "title2506": "Xác nhận xóa vai trò:",
  "title2507": "Xác nhận xuất tất cả các mục dữ liệu vai trò",
  "title2508": "Dữ liệu vai trò",
  "title2509": "Nhập từ khóa",
  "title2510": "Chọn nền tảng",
  "title2511": "Đã quá hạn",
  "title2512": "Đã kết nối",
  "title2513": "Thời gian làm mới",
  "title2514": "Thời gian kết nối",
  "title2515": "Nhấp vào",
  "title2516": "Trạng thái kết nối",
  "title2517": "Sẽ mở trang ủy quyền để bạn ủy quyền",
  "title2518": "Sẽ mở cho bạn",
  "title2519": "Ủy quyền trên trang ủy quyền",
  "title2520": "Vui lòng nhập biệt danh cửa hàng",
  "title2521": "Kết nối",
  "title2522": "Bí danh cửa hàng không được để trống",
  "title2523": "Thêm Quyền cửa hàng",
  "title2524": "Chỉnh sửa cửa hàng",
  "title2525": "Làm mới quyền thành công",
  "title2526": "Làm mới quyền thất bại, vui lòng cấp lại quyền",
  "title2527": "Thêm Quyền cửa hàng mới thành công",
  "title2528": "Vui lòng cẩn thận, xóa cửa hàng sẽ xóa dữ liệu sau: Thông tin sản phẩm cửa hàng sẽ bị xóa hoàn toàn và không thể khôi phục được. Thông tin đơn hàng cửa hàng sẽ được xóa và không thể khôi phục. Thông tin cài đặt liên quan đến cửa hàng sẽ bị xóa hoàn toàn và không thể khôi phục được.",
  "title2529": "Xác nhận xuất tất cả các mục dữ liệu cửa hàng",
  "title2530": "Tạo thành công",
  "title2531": "Tỉ lệ Chuyển động",
  "title2532": "Đánh giá mới",
  "title2533": "Nhập điểm đánh giá cửa hàng",
  "title2534": "Chọn thời gian giao hàng",
  "title2535": "Nhập tỷ lệ giao hàng trễ",
  "title2536": "Nhập tỷ lệ chưa hoàn thành",
  "title2537": "Nhập tỷ lệ phản hồi",
  "title2538": "Chọn thời gian phản hồi",
  "title2539": "Nhập tỷ lệ chuyển động",
  "title2540": "Nhập số lượng đánh giá mới",
  "title2541": "Thêm dữ liệu cửa hàng",
  "title2542": "Chỉnh sửa dữ liệu cửa hàng",
  "title2543": "Xác nhận xóa dữ liệu cửa hàng có ID",
  "title2544": "Xác nhận xuất tất cả các mục dữ liệu cửa hàng",
  "title2545": "Chọn tháng chi phí",
  "title2546": "Vui lòng chọn loại chi phí",
  "title2547": "Tháng chi phí",
  "title2548": "Vui lòng nhập số tiền (dương cho chi phí, âm cho thu nhập)",
  "title2549": "Tháng chi phí không được để trống",
  "title2550": "Loại chi phí không được để trống",
  "title2551": "Số tiền không được để trống",
  "title2552": "Thêm chi phí cửa hàng",
  "title2553": "Sửa chi phí cửa hàng",
  "title2554": "Xác nhận xóa chi phí cửa hàng",
  "title2555": " Tất cả vận chuyển ",
  "title2556": "Tên Vận tải",
  "title2557": "Loại gói hàng",
  "title2558": "Chưa xử lý số tiền lỗ",
  "title2559": "Số tiền đã xử lý cho chi phí vận chuyển",
  "title2561": "Chênh lệch phí vận chuyển",
  "title2562": "Chiết khấu phí vận chuyển người bán",
  "title2563": "Phí vận chuyển hoàn trả hàng",
  "title2564": "Điều chỉnh phí vận chuyển",
  "title2565": "Trạng thái phí vận chuyển",
  "title2566": "Bình thường",
  "title2567": "Lỗ",
  "title2568": "Thặng dư",
  "title2569": " Dòng chảy",
  "title2570": "Số đơn hàng",
  "title2571": "Người mua chỉ định vận chuyển",
  "title2572": "Xử lý",
  "title2573": "Vui lòng nhập nhiều số đơn hàng, cách nhau bằng dòng",
  "title2574": "Xử lý lỗ",
  "title2575": "Sau xác nhận, trạng thái vận chuyển sẽ được thay đổi thành [Đã Xử lý Thiệt Hại], không thể khôi phục. Bạn có chắc chắn muốn tiếp tục không?",
  "title2576": "Đơn đặt hàng của nền tảng",
  "title2577": "Công ty logistics",
  "title2578": "Sản phẩm đơn lẻ ( số lượng 1)",
  "title2579": "Nhiều sản phẩm, Nhiều số lượng",
  "title2580": "Có ghi chú",
  "title2581": "Không có ghi chú",
  "title2582": "Bạn có chắc chắn muốn gửi các đơn hàng đã chọn không?",
  "title2583": "Vận chuyển thành công",
  "title2584": "Vui lòng nhập ghi chú",
  "title2585": "Ghi chú được thêm thành công",
  "title2586": "Không có quyền",
  "title2587": "Bạn có chắc chắn muốn xuất thông tin đơn hàng không?",
  "title2588": "Tất cả",
  "title2589": "Đơn hàng kiểm tra",
  "title2590": "Tạm giữ",
  "title2591": "Review",
  "title2592": "Giao hàng",
  "title2593": "Rút lại",
  "title2594": "Tạo đơn hàng thủ công",
  "title2595": "Đẩy đơn hàng",
  "title2596": "Sửa hình thức lấy hàng",
  "title2597": "Làm mới chi phí mới nhất",
  "title2598": "Tạm giữ",
  "title2599": "Hủy giữ",
  "title2600": "Mark evaluation",
  "title2601": " cancel Mark evaluation",
  "title2602": "Quan hệ ghép nối",
  "title2603": "Chuyển đến đang chờ xem xét",
  "title2604": "Tặng kèm sản phẩm",
  "title2605": "In hàng loạt",
  "title2606": "In phiếu xuất hàng",
  "title2607": "In tem vận chuyển",
  "title2608": "In tem vận chuyển&phiếu xuất hàng",
  "title2609": "Đánh dấu đã in biên lai",
  "title2610": "Đánh dấu chưa in biên lai",
  "title2611": "Đánh dấu đã in danh sách lấy hàng",
  "title2612": "Đánh dấu chưa in danh sách lấy hàng",
  "title2613": "Tải lại tồn kho",
  "title2614": "Trạng thái in",
  "title2615": "Tính đầy đủ",
  "title2616": "Tất cả các trạng thái",
  "title2617": "Đánh dấu",
  "title2618": "Xuất danh sách đơn hàng",
  "title2619": "Xuất thông tin người mua",
  "title2620": "Đơn hàng thất bại thực tế vẫn được xử lý bởi kho bãi bên thứ ba. Vui lòng liên hệ ngay với nhà cung cấp dịch vụ kho bãi bên thứ ba để xác minh trạng thái đơn hàng để tránh thiệt hại kinh tế do giao hàng.",
  "title2621": "Ghi chú của người mua",
  "title2622": "Thêm ký hiệu",
  "title2623": "Số tiền thanh toán",
  "title2624": "Doanh thu",
  "title2625": "Tổng cộng",
  "title2626": "Doanh thu thực tế",
  "title2627": "Chi phí",
  "title2628": "Tính toán",
  "title2629": "Phí đóng gói",
  "title2630": "Chiết khấu nền tảng",
  "title2631": "Người nhận",
  "title2632": "Trạng thái không xác định",
  "title2633": "Đặt hàng trước",
  "title2634": "Đánh giá",
  "title2635": "Expired Time",
  "title2636": "Theo các chính sách khác nhau của platform, thời gian hết hạn không nhất thiết có nghĩa là đơn hàng đã bị hủy bởi platform. Việc đơn hàng có bị hủy bởi platform hay không phụ thuộc vào trạng thái của platform",
  "title2637": "Cho tham khảo",
  "title2638": "Cài đặt vận chuyển",
  "title2639": "Lời giải thích về việc loại bỏ:",
  "title2640": "Đơn hàng được xóa và thêm vào danh sách đẩy thành công.",
  "title2641": "Chưa xử lý ",
  "title2642": "Thời gian đánh dấu:",
  "title2643": "Danh sách đã chọn in",
  "title2644": "Hóa đơn đã in",
  "title2645": "Chưa xác minh",
  "title2646": "Đang kiểm tra",
  "title2647": "Đã được phê duyệt",
  "title2648": "Thay đổi mối quan hệ ghép nối",
  "title2649": "Thay đổi Đơn hàng hiện tại",
  "title2650": "Thay đổi Tất cả",
  "title2651": "Mối quan hệ phù hợp (chỉ áp dụng cho các đơn hàng đang chờ xử lý dưới cửa hàng này, và cập nhật mối quan hệ phù hợp đồng thời)",
  "title2652": "Chọn loại",
  "title2653": "Tổng",
  "title2654": "Cài đặt Thời gian Đồng bộ Đơn hàng",
  "title2655": "Bắt đầu Đồng bộ hóa",
  "title2656": "Đồng bộ đơn hàng",
  "title2657": "Đồng bộ đơn hàng",
  "title2658": "Tất cả Sàn TMĐT",
  "title2659": "Vui lòng nhập tên cửa hàng",
  "title2660": "Đang Đồng bộ hóa",
  "title2661": "Đồng bộ hóa Dữ liệu Đơn hàng",
  "title2662": "Tiến độ",
  "title2663": "Xem sau",
  "title2664": "Tạo đơn hàng thủ công",
  "title2665": "Phương thức thanh toán",
  "title2666": "Đơn hàng thủ công trực tuyến",
  "title2667": "Đơn hàng thủ công ngoại tuyến",
  "title2668": "Thông tin đơn hàng",
  "title2669": "Chọn phương thức thanh toán",
  "title2670": "Tên dịch vụ vận chuyển",
  "title2671": "Nhập tên dịch vụ vận chuyển",
  "title2672": "Nhập tên dịch vụ",
  "title2673": "Nhập dịch vụ vận chuyển theo yêu cầu của người mua",
  "title2674": "Các tập tin khác",
  "title2675": "Tải lên tập tin",
  "title2676": "Người nhận hàng",
  "title2677": "Nhập người nhận",
  "title2678": "Số điện thoại di động",
  "title2679": "Nhập số điện thoại",
  "title2680": "Quốc gia",
  "title2681": "Chọn Khu vực",
  "title2682": "Tỉnh",
  "title2683": "Tiểu bang",
  "title2684": "Nhập tỉnh",
  "title2685": "Nhập thành phố",
  "title2686": "Khu vực",
  "title2687": "Huyện",
  "title2688": "Nhập quận",
  "title2689": "Mã bưu chính",
  "title2690": "Vui lòng nhập Mã bưu chính",
  "title2691": "Địa chỉ cụ thể",
  "title2692": "Vui lòng nhập địa chỉ chi tiết",
  "title2693": "Tin nhắn của khách hàng",
  "title2694": "Nhập lời nhắn của khách hàng",
  "title2695": "Chọn loại tiền tệ",
  "title2696": "Thêm sản phẩm",
  "title2697": "Tổng giá",
  "title2698": "Nhập số nguyên dương lớn hơn 0",
  "title2699": "Chưa in ",
  "title2700": "phiếu giao hàng",
  "title2701": "Đã in",
  "title2702": "phiếu xuất hàng",
  "title2703": "Không phải đặt hàng trước",
  "title2704": "Hàng hóa đơn lẻ (SL nhiều)",
  "title2705": "Đóng gói nhiều hàng hóa",
  "title2706": "Người mua thanh toán",
  "title2707": "chuyển do người bán trả",
  "title2708": "2 tháng gần đây",
  "title2709": "Ngày tuỳ chỉnh",
  "title2710": "Số đơn hàng hệ thống không thể trống",
  "title2711": "Số đơn hàng nền tảng không thể trống",
  "title2712": "Khu vực không thể trống",
  "title2713": "Thông tin người mua không thể trống",
  "title2714": "Thông tin theo dõi vận chuyển không thể trống",
  "title2715": "Đơn hàng đặt trước không thể trống",
  "title2716": "Số lượng hàng tồn không thể trống",
  "title2717": "Trạng thái hệ thống không thể trống",
  "title2718": "Đánh dấu không thể trống",
  "title2719": "Phương thức thanh toán không thể trống",
  "title2720": "Vui lòng chọn quốc gia",
  "title2721": "Vui lòng nhập tỉnh",
  "title2722": "Đã hết thời gian",
  "title2723": "Đã hủy",
  "title2724": "Chờ in",
  "title2725": "Đang vận chuyển",
  "title2726": "Đã giao thành công",
  "title2727": "Giao hàng thất bại",
  "title2728": "Đơn hàng trả lại",
  "title2729": "Trả hàng và hoàn tiền đang tiến hành",
  "title2730": "Vui lòng chọn thao tác đơn hàng",
  "title2731": "Bạn có chắc chắn muốn đồng bộ hóa đơn?",
  "title2732": "Yêu cầu đồng bộ hóa thành công, vui lòng làm mới để xem",
  "title2733": "Bạn có chắc chắn muốn làm mới chi phí mới nhất không?",
  "title2734": "Làm mới chi phí thành công",
  "title2735": "Đánh dấu đánh giá thành công",
  "title2736": "Hủy đánh giá đánh giá thành công",
  "title2737": "Lưu kho thành công",
  "title2738": "Bạn có chắc chắn muốn đánh dấu các đơn hàng được chọn để đánh giá?",
  "title2739": "Bạn có chắc chắn muốn xem xét các đơn hàng được chọn không?",
  "title2740": "Bạn có chắc chắn muốn gửi các đơn hàng được chọn không?",
  "title2741": "Đơn hàng đã chọn?",
  "title2742": "Bạn có chắc chắn muốn đẩy các đơn hàng được chọn không?",
  "title2743": "Đang được đẩy, vui lòng kiểm tra sau",
  "title2744": "Bạn có chắc chắn muốn hủy đánh dấu đánh giá cho các đơn hàng được chọn không?",
  "title2745": "Bạn có chắc chắn muốn tạm dừng các đơn hàng được chọn không?",
  "title2746": "Giữ thành công",
  "title2747": "Bạn có chắc chắn muốn hủy bỏ việc giữ cho các đơn hàng được chọn không?",
  "title2748": "Hủy giữ thành công",
  "title2749": "Đánh dấu thành công",
  "title2750": "Chuyển đơn hàng đã chọn vào chờ xem xét?",
  "title2751": "Đánh dấu thành công",
  "title2752": "Xác nhận xóa dấu hiệu:",
  "title2753": "Dấu được xóa thành công",
  "title2754": "Đồng bộ hóa thành công",
  "title2755": "Sau khi hủy phân chia, tất cả các gói con sẽ quay trở lại gói chính. Bạn có chắc chắn muốn hủy phân chia không?",
  "title2756": "Hủy bỏ phân loại thành công",
  "title2757": "Xác nhận số xét duyệt là",
  "title2758": "đơn hàng",
  "title2759": "Xác nhận rút lui các đơn hàng đã chọn",
  "title2760": "Rút lui thành công",
  "title2761": "Xác nhận xóa các đơn hàng đã chọn",
  "title2762": "Xoá thành công",
  "title2763": "Xác nhận số giữ cho",
  "title2764": "Xác nhận hủy bỏ số giữ cho",
  "title2765": "Xác nhận đánh dấu số là",
  "title2766": "đơn hàng đánh giá",
  "title2767": "Lời nhắc hệ thống",
  "title2768": "Đánh dấu đơn hàng đánh giá thành công",
  "title2769": "Xác nhận hủy bỏ số đánh dấu là",
  "title2770": "Hủy bỏ thành công đánh giá đơn hàng",
  "title2771": "Ghi chú xuất hàng",
  "title2772": "Xác nhận xóa số đơn hàng là",
  "title2773": "Xác nhận xóa các mục đã thêm",
  "title2774": "Vui lòng chọn phương thức giao nhận",
  "title2775": "Phương thức thu mua đã được lưu thành công",
  "title2776": "Vui lòng chọn sản phẩm hoặc hủy bỏ",
  "title2777": "Thêm vào thành công",
  "title2778": "Xác nhận xuất thông tin người mua",
  "title2779": "Vui lòng nhập thuộc tính đơn đặt hàng",
  "title2780": "Vui lòng chọn xem có bán trước không",
  "title2781": "Vui lòng chọn tình trạng sản phẩm",
  "title2782": "Video sản phẩm",
  "title2783": "Bảng kích thước",
  "title2784": "Bán trước",
  "title2785": "Tình trạng sản phẩm",
  "title2786": "Mô tả ngắn gọn",
  "title2787": "Mô tả chi tiết",
  "title2788": "Giá bán",
  "title2789": "Số lần quảng cáo xem",
  "title2790": "Số lượng yêu thích",
  "title2791": "Vui lòng nhập danh mục",
  "title2792": "Vui lòng nhập thương hiệu",
  "title2793": "Vui lòng nhập hình sản phẩm",
  "title2794": "Vui lòng nhập video sản phẩm",
  "title2795": "Vui lòng nhập thuộc tính",
  "title2796": "Vui lòng nhập cân nặng sản phẩm",
  "title2797": "Vui lòng nhập kích thước sản phẩm",
  "title2798": "Vui lòng nhập bảng kích thước",
  "title2799": "Vui lòng nhập mô tả ngắn gọn",
  "title2800": "Vui lòng nhập mô tả chi tiết",
  "title2801": "Vui lòng nhập giá bán",
  "title2802": "Vui lòng nhập số lần quảng cáo xem",
  "title2803": "Vui lòng nhập số lượng yêu thích",
  "title2805": "Vui lòng nhập số lượng bình luận",
  "title2806": "Danh mục không được để trống",
  "title2807": "Thương hiệu không được để trống",
  "title2808": "Trạng thái bán trước không được để trống",
  "title2809": "Tình trạng sản phẩm không được để trống",
  "title2810": "Loại không được để trống",
  "title2811": "Giá bán không được để trống",
  "title2812": "Số lần quảng cáo xem không được để trống",
  "title2813": "Số lượng yêu thích không được để trống",
  "title2815": "Điểm đánh giá không được để trống",
  "title2816": "Số lượng bình luận không được để trống",
  "title2817": "Thêm sản phẩm trực tuyến",
  "title2818": "Chỉnh sửa sản phẩm trực tuyến",
  "title2819": "Xác nhận xóa sản phẩm trực tuyến",
  "title2820": "Xác nhận xuất tất cả dữ liệu sản phẩm trực tuyến",
  "title2821": "Sản phẩm trực tuyến",
  "title2823": "Phí vận chuyển của người mua",
  "title2824": "Phí vận chuyển của người bán",
  "title2825": "Tỷ suất lợi nhuận",
  "title2826": "Danh sách đơn hàng",
  "title2827": "Thông tin vận chuyển từ người bán",
  "title2828": "Thu nhập thực tế",
  "title2829": "Lợi nhuận thực tế",
  "title2830": "Thu nhập ước tính",
  "title2832": "Tổng cộng",
  "title2833": "Xác nhận xuất bảng lợi nhuận sản phẩm",
  "title2834": "lợi nhuận sản phẩm",
  "title2835": "Vui lòng nhập sản phẩm trực tuyến",
  "title2836": "Cha",
  "title2837": "Nhập Cha",
  "title2838": "Vui lòng nhập tồn kho",
  "title2839": "Vui lòng chọn trạng thái nền tảng",
  "title2840": "Trạng thái địa phương",
  "title2841": "Vui lòng nhập trạng thái địa phương",
  "title2842": "Tồn kho không được để trống",
  "title2843": "Trạng thái nền tảng không được để trống",
  "title2844": "Trạng thái địa phương không được để trống",
  "title2845": "Xác nhận xuất tất cả sản phẩm trực tuyến",
  "title2846": "Cập nhật giá vốn",
  "title2847": "Cập nhật chi phí H.Hóa",
  "title2848": "Thêm nhãn vào đơn hàng",
  "title2849": "Xác minh thanh toán đơn hàng",
  "title2850": "Chọn loại thời gian",
  "title2851": "Tình trạng thanh toán",
  "title2852": "Tính toàn vẹn của đơn hàng",
  "title2853": "Đơn hàng bình thường",
  "title2854": "Phí vận chuyển chưa cập nhật",
  "title2855": "Chi phí chưa được cập nhật",
  "title2856": "Hiện tại trong kết quả tìm kiếm",
  "title2857": "Đơn hàng chưa thanh toán",
  "title2858": "Đặt hàng:",
  "title2859": "Thanh toán:",
  "title2860": "tag",
  "title2861": "Số tiền nhờ thu",
  "title2862": "Đã thanh toán",
  "title2863": "Chưa thanh toán",
  "title2864": "Đối soát thanh toán",
  "title2865": "Tải lên bảng thanh toán",
  "title2866": "Số lượng đơn hàng",
  "title2867": "Số tiền nhờ thu:",
  "title2868": "Số đơn hàng trên nền tảng",
  "title2869": "Sao chép số đơn hàng",
  "title2870": "Đơn hàng dư thừa",
  "title2871": "Đơn hàng dư thừa trong cửa hàng",
  "title2872": "Số tiền thanh toán của cửa hàng",
  "title2873": "Tất cả các loại",
  "title2874": "Số tiền thanh toán không nhất quán",
  "title2875": "Bắt đầu đối soát",
  "title2876": "Tải lên chi phí sản phẩm",
  "title2877": "Vui lòng chuẩn bị dữ liệu để nhập theo định dạng mẫu",
  "title2878": "Định dạng",
  "title2879": "Kích thước tệp không được vượt quá",
  "title2880": "Qua sản phẩm",
  "title2881": "Tra cứu thông tin đơn hàng, có thể sửa đổi giá thành phố của hàng hóa.",
  "title2882": "Vui lòng lưu ý, không hỗ trợ sửa đổi đơn hàng không có sản phẩm.",
  "title2883": "Theo",
  "title2884": "Nhấp đúp để nhập loạt",
  "title2885": "Chi phí một sản phẩm",
  "title2886": "Chỉnh sửa hàng loạt",
  "title2887": "Thời gian thanh toán",
  "title2888": " Chỉnh sửa hàng loạt ",
  "title2889": "Tất cả các đơn hàng",
  "title2890": "Đơn hàng bị đình chờ",
  "title2891": "Đơn hàng bị thiếu",
  "title2892": "Đơn hàng điều chỉnh qua tháng",
  "title2893": "Xác nhận hủy bỏ",
  "title2894": "Vui lòng nhập chi phí",
  "title2895": "Không phát hiện chi phí sản phẩm đã được sửa đổi, vui lòng sửa đổi chi phí sản phẩm trước khi tiếp tục",
  "title2896": "Cập nhật chi phí thành công",
  "title2897": "Mẫu đối soát",
  "title2898": "Cập nhật mẫu nhập chi phí sản phẩm",
  "title2899": "Chọn tháng",
  "title2900": "Chọn thời gian đặt hàng",
  "title2901": "Chi tiết lợi nhuận đơn hàng",
  "title2902": "Chọn sản phẩm",
  "title2903": "Chọn sản phẩm",
  "title2904": "Vui lòng nhập số phiếu nhập",
  "title2905": "Giá đơn vị phiếu nhập",
  "title2906": "ID nhập kho",
  "title2907": "Đã từ chối",
  "title2908": "Đợi chờ nộp",
  "title2909": "Kho nhập kho",
  "title2910": "Đơn giá",
  "title2911": "Nhập phiếu",
  "title2912": "Mẫu nhập phiếu",
  "title2913": "Vui lòng chọn kho",
  "title2914": "Xác nhận xóa sản phẩm đã chọn?",
  "title2915": "Tạo phiếu nhập kho",
  "title2916": "Chỉnh sửa phiếu nhập",
  "title2917": "Xem phiếu nhập",
  "title2918": "Xác nhận nhập hàng",
  "title2919": "Nhập thành công",
  "title2920": "Vui lòng nhập số lượng nhập",
  "title2921": "Xác nhận xóa bản ghi tồn kho là:",
  "title2922": "Xác nhận xuất tất cả các mục dữ liệu bản ghi tồn kho",
  "title2923": "Bản ghi tồn kho",
  "title2924": "Operation Time",
  "title2925": "Thay đổi kho",
  "title2926": "Hàng tồn đông ban đầu",
  "title2927": "Hàng tồn đông mới",
  "title2928": "Hàng tồn có sẵn ban đầu",
  "title2929": "Hàng tồn có sẵn mới",
  "title2930": "Thông tin nhập và xuất",
  "title2932": "Vui lòng chọn khoảng thời gian",
  "title2933": "Tải lên kiểm kê",
  "title2934": "Nhập thành công! Bạn đã nhập thành công 1000 mục dữ liệu.",
  "title2935": "Vui lòng nhập số phiếu xuất",
  "title2936": "Số lượng xuất kho",
  "title2937": "ID xuất kho",
  "title2938": "Hết hàng",
  "title2939": "Kho xuất kho",
  "title2940": "Nhập khẩu phiếu xuất",
  "title2941": "Mẫu nhập phiếu xuất",
  "title2942": "Tạo phiếu xuất kho",
  "title2943": "Sửa đổi phiếu xuất",
  "title2944": "Xem phiếu xuất",
  "title2945": "Xác nhận xuất kho",
  "title2946": "Xuất thành công",
  "title2947": "Vui lòng nhập số lượng xuất",
  "title2948": "Khu vực",
  "title2949": "Vui lòng nhập khu vực",
  "title2950": "Vui lòng nhập tỷ lệ trả lại",
  "title2951": "Tỷ lệ trả lại không được để trống",
  "title2952": "Lợi nhuận cửa hàng hàng ngày",
  "title2953": "Số lợi nhuận cửa hàng hàng ngày là",
  "title2954": "Dữ liệu lợi nhuận cửa hàng hàng ngày",
  "title2955": "Thêm yêu cầu kiểm kho",
  "title2956": "Loại kiểm kho",
  "title2957": "Mã kiểm kho",
  "title2958": "kiểm kho",
  "title2959": "Số",
  "title2960": "Tiến độ kiểm kho (SKU)",
  "title2961": "Kết thúc",
  "title2962": "Bắt đầu kiểm kho",
  "title2963": "Kết thúc kiểm kho",
  "title2964": "Vui lòng chọn loại kiểm kho",
  "title2965": "Hàng tồn có sẵn để kiểm kê",
  "title2966": "Nhập sản phẩm",
  "title2967": "Đang kiểm kho",
  "title2968": "Xuất số kiểm kê",
  "title2969": "Nhập",
  "title2970": "Số lượng thực tế",
  "title2971": "Kết quả kiểm kho",
  "title2972": "Mẫu nhập",
  "title2973": "Nhập kiểm kê",
  "title2974": "Mẫu nhập kiểm kê",
  "title2975": "Loại kiểm kê không được để trống",
  "title2976": "Lệch giảm",
  "title2977": "Khớp",
  "title2978": "Lệch tăng",
  "title2979": "Tạo nhiệm vụ kiểm kê mới",
  "title2980": "Sửa đổi kiểm kê",
  "title2981": "Xem kiểm kê",
  "title2982": "Kiểm kho",
  "title2983": "Xác nhận kết thúc kiểm kê",
  "title2984": "Kiểm kê hoàn thành thành công",
  "title2985": "Tạo nhiệm vụ kiểm kê mới thành công",
  "title2986": "Xác nhận xóa số",
  "title2987": "kiểm kê",
  "title2988": "Kiểm kê đã xóa thành công",
  "title2989": "Xác nhận xuất danh sách kiểm kê",
  "title2990": "Danh sách kiểm kê",
  "title2991": "Xác nhận xuất tất cả các mục dữ liệu kiểm kê kho",
  "title2992": "Tạo đơn chuyển hàng mới",
  "title2993": "Nhập đơn chuyển hàng",
  "title2994": "Xuất đơn chuyển hàng",
  "title2995": "Nhận",
  "title2996": "Xác nhận giao hàng",
  "title2997": "Chọn kho vận chuyển",
  "title2998": "Vui lòng chọn kho nhận",
  "title2999": "Phương thức phân bổ chi phí",
  "title3000": "Vui lòng chọn phương thức phân bổ chi phí",
  "title3001": "Chỉnh sửa hàng loạt",
  "title3002": "Số lượng:",
  "title3003": "Giá chuyển kho",
  "title3004": "Giá chuyển kho cuối cùng",
  "title3005": "Chỉnh sửa số lượng chuyển hàng",
  "title3006": "Bằng tồn kho có sẵn",
  "title3007": "Đặt giá trị kho hàng thống nhất",
  "title3008": "Nhập giá trị hàng tồn kho đồng nhất",
  "title3009": "Nhập đơn chuyển hàng",
  "title3010": "Mẫu nhập đơn chuyển hàng",
  "title3011": "Vui lòng chọn kho hàng",
  "title3012": "Xác nhận giao hàng",
  "title3013": "Hàng tồn kho chưa được nhận sẽ được khôi phục về kho gửi hàng, bạn có chắc chắn muốn hủy không?",
  "title3014": "Sửa đổi đơn chuyển hàng",
  "title3015": "Nhập số lượng chuyển hàng",
  "title3016": "Tồn kho không đủ để chuyển hàng",
  "title3017": "Đơn chuyển hàng",
  "title3018": "Xác nhận xuất tất cả dữ liệu chuyển hàng",
  "title3019": "Đơn chuyển hàng",
  "title3020": "Vui lòng chọn phương thức thanh toán",
  "title3021": "Tên nhà cung cấp",
  "title3022": "Người liên hệ",
  "title3023": "Số điện thoại",
  "title3024": "Phương thức thanh toán",
  "title3025": "Vui lòng nhập tên nhà cung cấp",
  "title3026": "Kênh mua hàng",
  "title3027": "Nhập kênh mua hàng",
  "title3028": "Tên Công ty",
  "title3029": "Nhập tên công ty",
  "title3030": "Địa chỉ công ty",
  "title3031": "Nhập địa chỉ công ty",
  "title3032": "Website nhà cung cấp",
  "title3033": "Nhập trang web nhà cung cấp",
  "title3034": "Tài khoản ngân hàng",
  "title3035": "Nhập tài khoản ngân hàng",
  "title3036": "Số tài khoản ngân hàng",
  "title3037": "Nhập số tài khoản thanh toán",
  "title3038": "Nhập thông tin liên hệ",
  "title3039": "Nhập số điện thoại liên hệ",
  "title3040": "Nhập thông tin WeChat",
  "title3041": "Email",
  "title3042": "Nhập địa chỉ email",
  "title3043": "Tên nhà cung cấp không được để trống",
  "title3044": "Kênh mua hàng không thể để trống",
  "title3045": "Thêm nhà cung cấp",
  "title3046": "Chỉnh sửa nhà cung cấp",
  "title3047": "Xác nhận xóa nhà cung cấp",
  "title3048": "Xác nhận xuất tất cả các mục dữ liệu nhà cung cấp",
  "title3049": "Nhà cung cấp",
  "title3050": "Nhập quyền",
  "title3051": "Số điểm bán hàng",
  "title3052": "Nhập số điểm bán hàng",
  "title3053": "Số kho",
  "title3054": "Nhập số kho",
  "title3055": "Có kích hoạt",
  "title3056": "Địa chỉ kho",
  "title3057": "Nhập địa chỉ kho",
  "title3058": "Tên kho không được để trống",
  "title3059": "Trạng thái kích hoạt không thể để trống",
  "title3060": "Số điểm bán hàng không thể để trống",
  "title3061": "Số kho không được để trống",
  "title3062": "Thêm kho cấp quyền bên thứ ba",
  "title3063": "Chỉnh sửa kho cấp quyền bên thứ ba",
  "title3064": "Xác nhận xóa kho cấp quyền bên thứ ba với số",
  "title3065": "Xác nhận xuất tất cả các mục dữ liệu kho cấp quyền bên thứ ba",
  "title3066": "Kho cấp quyền bên thứ ba",
  "title3067": "Tỷ lệ hối đoái thanh toán",
  "title3068": "Chỉnh sửa tham số đánh giá",
  "title3069": "Thêm tham số đánh giá",
  "title3070": "Nhập phí vận hành",
  "title3071": "Nhập phí gói trống",
  "title3072": "Vui lòng chọn cửa hàng",
  "title3073": "Vui lòng nhập tỷ lệ hối đoái thanh toán",
  "title3074": "Biến động tỷ lệ hối đoái thanh toán bạn đã đặt ra quan trọng, vui lòng xác minh trước khi gửi",
  "title3075": "Phí vận hành không thể để trống",
  "title3076": "Phí gói trống không thể để trống",
  "title3077": "Xác nhận xóa các tham số đánh giá",
  "title3078": "Xác nhận xuất tất cả các mục dữ liệu tham số đánh giá",
  "title3079": "Tham số đánh giá",
  "title3080": "Vui lòng tìm kiếm tên kho",
  "title3081": "Khóa",
  "title3082": "Khóa tương ứng với khóa",
  "title3083": "Tài khoản",
  "title3084": "Vui lòng nhập tài khoản",
  "title3085": "Thông tin ủy quyền",
  "title3086": "Chưa kết nối",
  "title3087": "Thêm kho",
  "title3088": "Vui lòng nhận thông tin ủy quyền từ nhà cung cấp dịch vụ bên thứ ba, ràng buộc nhiều",
  "title3089": "Điều này sẽ dẫn đến việc đẩy đơn hàng nhiều lần, gây ra đơn hàng trùng lặp và sai lạc, nên chỉ ràng buộc tài khoản hiện tại",
  "title3090": "Tên tùy chỉnh",
  "title3091": "Nhập tên tùy chỉnh kho",
  "title3092": "Tên nhà cung cấp dịch vụ bên thứ ba",
  "title3093": "Nhập",
  "title3094": "Mã khách hàng",
  "title3095": "Vui lòng nhập mã khách hàng",
  "title3096": "Tên tùy chỉnh không thể để trống",
  "title3097": "Ủy quyền nhà cung cấp dịch vụ kho bên thứ ba",
  "title3098": "Ủy quyền thành công",
  "title3099": "Xác nhận xóa thông tin cấu hình kho bên thứ ba có ID",
  "title3100": "Xác nhận xuất tất cả các mục dữ liệu thông tin cấu hình kho bên thứ ba",
  "title3101": "Thông tin cấu hình kho bên thứ ba",
  "title3102": "Tên nhà sản xuất",
  "title3103": "Vui lòng nhập tên nhà sản xuất",
  "title3104": "Số nhà sản xuất",
  "title3105": "Vui lòng nhập số nhà sản xuất",
  "title3106": "Nhà sản xuất",
  "title3107": "Chưa kích hoạt",
  "title3108": "Đã kích hoạt",
  "title3109": "Trang web chính thức",
  "title3110": "Thương hiệu",
  "title3111": "Chưa kích hoạt",
  "title3112": "Tên nhà sản xuất không được để trống",
  "title3113": "Số nhà sản xuất không được để trống",
  "title3114": "Thêm nhà sản xuất kho bên thứ ba",
  "title3115": "Chỉnh sửa nhà sản xuất kho bên thứ ba",
  "title3116": "Xác nhận chuyển đổi trạng thái nhà sản xuất kho bên thứ ba, có ID",
  "title3117": "Chuyển trạng thái thành công",
  "title3118": "Xác nhận xóa nhà sản xuất kho bên thứ ba có ID",
  "title3119": "Xác nhận xuất tất cả các mục dữ liệu nhà sản xuất kho bên thứ ba",
  "title3120": "kho hàng bên thứ 3 Nhà cung cấp dịch vụ",
  "title3121": "Quản lý vai trò ",
  "title3122": "Nhập thông tin tài khoản",
  "title3123": "Tất cả vai trò",
  "title3124": "Tên",
  "title3125": "Vai trò",
  "title3126": "Phân bố cửa hàng",
  "title3127": "Phân bổ kho",
  "title3128": "Phân bố vai trò",
  "title3129": "Vai trò đóng",
  "title3130": "Nhập tên người dùng",
  "title3131": "Nhập tên",
  "title3132": "Thêm người dùng",
  "title3133": "Mật khẩu đăng nhập",
  "title3134": "Nhập mật khẩu 6 chữ số",
  "title3135": "Tạo tài khoản con",
  "title3136": "Nhập người dùng đăng nhập",
  "title3137": "Tên thật",
  "title3138": "Xác nhận mật khẩu",
  "title3139": "Quyền vai trò",
  "title3140": "Tạo vai trò",
  "title3141": "Quyền cửa hàng",
  "title3142": "Quyền kho",
  "title3143": "Quyền kho",
  "title3144": "Xác nhận cập nhật dữ liệu người dùng hiện có",
  "title3145": "Chỉ cho phép nhập",
  "title3146": "Định dạng tệp",
  "title3147": "Chưa kết nối",
  "title3148": "Kết nối nhà cung cấp dịch vụ",
  "title3149": "Chưa kết nối",
  "title3150": "Mật khẩu đăng nhập mới",
  "title3151": "Xác nhận mật khẩu mới",
  "title3152": "Tên không được để trống",
  "title3153": "Mật khẩu đăng nhập không thể để trống",
  "title3154": "Vai trò không được để trống",
  "title3155": "Vui lòng nhập số điện thoại hợp lệ",
  "title3156": "ID người dùng",
  "title3157": "Chỉnh sửa người dùng",
  "title3158": "Sửa đổi thành công, mật khẩu mới là:",
  "title3159": "Xác nhận vai trò cho người dùng",
  "title3160": "Xác nhận sửa đổi hậu tố đăng nhập",
  "title3161": "Phân bổ vai trò thành công",
  "title3162": "Xác nhận xóa người dùng:",
  "title3163": "Xác nhận xuất tất cả các mục dữ liệu người dùng",
  "title3164": "Dữ liệu người dùng",
  "title3165": "Nhập người dùng",
  "title3166": "Mẫu nhập người dùng",
  "title3167": "Số lượng tạo thành công",
  "title3168": "Số lượng cập nhật thành công",
  "title3169": "Số lượng cập nhật không thành công",
  "title3170": "Kết quả nhập",
  "title3171": "Phân loại kho hàng",
  "title3172": "riêng",
  "title3173": "Kho dịch vụ",
  "title3174": "Kho hàng bên thứ 3",
  "title3175": "Nhà cung cấp dịch vụ chất lượng",
  "title3176": "Loại kho không được để trống",
  "title3177": "Nhà cung cấp dịch vụ không được để trống",
  "title3178": "Kho bên thứ ba không được để trống",
  "title3179": "Chỉnh sửa kho",
  "title3180": "Xác nhận xóa kho có:",
  "title3181": "Xác nhận xuất tất cả các mục dữ liệu kho",
  "title3182": "Nhật ký hệ thống",
  "title3183": "Mã số người dùng",
  "title3184": "Vui lòng nhập mã người dùng",
  "title3185": "Loại người dùng",
  "title3186": "Vui lòng chọn loại người dùng",
  "title3187": "应用名",
  "title3188": "请输入应用名",
  "title3189": "请求地址",
  "title3190": "请输入请求地址",
  "title3191": "请求时间",
  "title3192": "执行时长",
  "title3193": "请输入执行时长",
  "title3194": "结果码",
  "title3195": "请输入结果码",
  "title3196": "日志编号",
  "title3197": "请求方法名",
  "title3198": "操作结果",
  "title3199": "详细",
  "title3200": "访问日志详细",
  "title3201": "日志主键：",
  "title3202": "链路追踪：",
  "title3203": "应用名：",
  "title3204": "用户信息：",
  "title3205": "请求信息：",
  "title3206": "请求参数：",
  "title3207": "开始时间：",
  "title3208": "操作结果：",
  "title3209": "访问日志数据项",
  "title3210": "访问日志",
  "title3211": "生成类型",
  "title3212": "文件名",
  "title3213": "请输入文件名",
  "title3214": "生成类型不能为空",
  "title3215": "页面",
  "title3216": "弹窗",
  "title3217": "异常时间",
  "title3218": "处理状态",
  "title3219": "请选择处理状态",
  "title3220": "异常发生时间",
  "title3221": "异常名",
  "title3222": "已忽略",
  "title3223": "异常日志详细",
  "title3224": "异常时间：",
  "title3225": "处理时间",
  "title3226": "确认标记为",
  "title3227": "错误日志数据项",
  "title3228": "错误日志",
  "title3229": "没有与",
  "title3230": "匹配的",
  "title3231": "选择图标",
  "title3232": "请输入图标名称",
  "title3233": "文件",
  "title3234": "复制代码",
  "title3235": "选择生成类型",
  "title3236": "资源引用",
  "title3237": "格式不能识别，仅支持修改",
  "title3238": "的对象内容",
  "title3239": "请使用",
  "title3240": "错误：",
  "title3241": "导出文件",
  "title3242": "格式错误，请检查",
  "title3243": "外部资源引用",
  "title3244": "资源路径",
  "title3245": "添加其他",
  "title3246": "资源已存在",
  "title3247": "表名称",
  "title3248": "表描述",
  "title3249": "实体类名称",
  "title3250": "默认去除表名的前缀。如果存在重复，则需要手动添加前缀，避免",
  "title3251": "报",
  "title3252": "重复的问题。",
  "title3253": "作者",
  "title3254": "请输入表名称",
  "title3255": "请输入表描述",
  "title3256": "请输入实体类名称",
  "title3257": "请输入作者",
  "title3258": "字段信息",
  "title3259": "字段列名",
  "title3260": "字段描述",
  "title3261": "物理类型",
  "title3262": "插入",
  "title3263": "列表",
  "title3264": "查询",
  "title3265": "查询方式",
  "title3266": "允许空",
  "title3267": "显示类型",
  "title3268": "文本框",
  "title3269": "文本域",
  "title3270": "下拉框",
  "title3271": "单选框",
  "title3272": "复选框",
  "title3273": "日期控件",
  "title3274": "图片上传",
  "title3275": "文件上传",
  "title3276": "富文本控件",
  "title3277": "示例",
  "title3278": "生成信息",
  "title3279": "表单校验未通过，请重新检查提交内容",
  "title3280": "选项名",
  "title3281": "请输入选项名",
  "title3282": "选项值",
  "title3283": "请输入选项值",
  "title3284": "字符串",
  "title3285": "数字",
  "title3286": "生成模板",
  "title3287": "生成场景",
  "title3288": "模块名",
  "title3289": "模块名，即一级目录，例如",
  "title3290": "等等",
  "title3291": "业务名",
  "title3292": "业务名，即二级目录，例如",
  "title3293": "类名称",
  "title3294": "类名称（首字母大写），例如",
  "title3295": "类描述",
  "title3296": "用作类描述，例如",
  "title3297": "上级菜单",
  "title3298": "分配到指定菜单下，例如",
  "title3299": "系统管理",
  "title3300": "请选择系统菜单",
  "title3301": "自定义路径",
  "title3302": "填写磁盘绝对路径，若不填写，则生成到当前",
  "title3303": "项目下",
  "title3304": "最近路径快速选择",
  "title3305": "恢复默认的生成基础路径",
  "title3306": "其他信息",
  "title3307": "树编码字段",
  "title3308": "树显示的编码字段名，",
  "title3309": "树父编码字段",
  "title3310": "树显示的父编码字段名，",
  "title3311": "树名称字段",
  "title3312": "树节点的显示名称字段名，",
  "title3313": "关联信息",
  "title3314": "关联子表的表名",
  "title3315": "关联子表的表名，",
  "title3316": "子表关联的外键名",
  "title3317": "子表关联的外键名，",
  "title3318": "请选择生成模板",
  "title3319": "请选择生成场景",
  "title3320": "请输入生成模块名",
  "title3321": "请输入生成业务名",
  "title3322": "请输入生成业务包",
  "title3323": "请输入生成类名称",
  "title3324": "请输入生成类描述",
  "title3325": "暂时不考虑支持【树形】和【主子表】的代码生成。原因是：导致",
  "title3326": "模板过于复杂，不利于胖友二次开发",
  "title3327": "导入表",
  "title3328": "数据源",
  "title3329": "请选择数据源",
  "title3330": "导入成功",
  "title3331": "单元测试",
  "title3332": "实体",
  "title3333": "预览",
  "title3334": "生成代码",
  "title3335": "代码预览",
  "title3336": "确认要强制同步",
  "title3337": "表结构吗？",
  "title3338": "生成配置",
  "title3339": "是否确认删除表名称为",
  "title3340": "未知【",
  "title3341": "配置中心",
  "title3342": "参数名称",
  "title3343": "请输入参数名称",
  "title3344": "参数键名",
  "title3345": "请输入参数键名",
  "title3346": "系统内置",
  "title3347": "参数主键",
  "title3348": "参数分类",
  "title3349": "参数键值",
  "title3350": "是否可见",
  "title3351": "请输入参数分类",
  "title3352": "请输入参数键值",
  "title3353": "参数分类不能为空",
  "title3354": "参数名称不能为空",
  "title3355": "参数键名不能为空",
  "title3356": "参数键值不能为空",
  "title3357": "添加参数",
  "title3358": "修改参数",
  "title3359": "是否确认删除参数编号为",
  "title3360": "是否确认导出所有参数数据项",
  "title3361": "参数配置",
  "title3362": "组件属性",
  "title3363": "表单属性",
  "title3364": "查看组件文档",
  "title3365": "组件类型",
  "title3366": "请选择组件类型",
  "title3367": "字段名",
  "title3368": "请输入字段名（",
  "title3369": "组件名",
  "title3370": "标题",
  "title3371": "请输入标题",
  "title3372": "占位提示",
  "title3373": "请输入占位提示",
  "title3374": "开始占位",
  "title3375": "结束占位",
  "title3376": "表单栅格",
  "title3377": "栅格间隔",
  "title3378": "布局模式",
  "title3379": "水平排列",
  "title3380": "请选择水平排列",
  "title3381": "垂直排列",
  "title3382": "标签宽度",
  "title3383": "请输入标签宽度",
  "title3384": "组件宽度",
  "title3385": "请输入组件宽度",
  "title3386": "请输入默认值",
  "title3387": "至少应选",
  "title3388": "最多可选",
  "title3389": "请输入前缀",
  "title3390": "后缀",
  "title3391": "请输入后缀",
  "title3392": "前图标",
  "title3393": "请输入前图标名称",
  "title3394": "后图标",
  "title3395": "请输入后图标名称",
  "title3396": "按钮图标",
  "title3397": "请输入按钮图标名称",
  "title3398": "选项分隔符",
  "title3399": "请输入选项分隔符",
  "title3400": "最小行数",
  "title3401": "最大行数",
  "title3402": "最小值",
  "title3403": "最大值",
  "title3404": "组件高度",
  "title3405": "步长",
  "title3406": "步数",
  "title3407": "按钮位置",
  "title3408": "默认",
  "title3409": "右侧",
  "title3410": "最多输入",
  "title3411": "请输入字符长度",
  "title3412": "开启提示",
  "title3413": "请输入开启提示",
  "title3414": "关闭提示",
  "title3415": "请输入关闭提示",
  "title3416": "开启值",
  "title3417": "请输入开启值",
  "title3418": "关闭值",
  "title3419": "请输入关闭值",
  "title3420": "时间类型",
  "title3421": "请选择时间类型",
  "title3422": "文件字段名",
  "title3423": "请输入上传文件字段名",
  "title3424": "文件类型",
  "title3425": "请选择文件类型",
  "title3426": "主键编号",
  "title3427": "数据源名称",
  "title3428": "数据源连接",
  "title3429": "请输入数据源连接",
  "title3430": "数据源名称不能为空",
  "title3431": "数据源连接不能为空",
  "title3432": "添加数据源配置",
  "title3433": "修改数据源配置",
  "title3434": "是否确认删除数据源配置编号为",
  "title3435": "数据库文档",
  "title3436": "上传下载",
  "title3437": "文件路径",
  "title3438": "请输入文件路径",
  "title3439": "文件大小",
  "title3440": "文件内容",
  "title3441": "无法预览，点击",
  "title3442": "上传时间",
  "title3443": "提示：仅允许导入",
  "title3444": "是否确认删除文件编号为",
  "title3445": "配置名",
  "title3446": "请输入配置名",
  "title3447": "存储器",
  "title3448": "请选择存储器",
  "title3449": "主配置",
  "title3450": "基础路径",
  "title3451": "请输入基础路径",
  "title3452": "主机地址",
  "title3453": "请输入主机地址",
  "title3454": "主机端口",
  "title3455": "请输入主机端口",
  "title3456": "连接模式",
  "title3457": "主动模式",
  "title3458": "节点地址",
  "title3459": "请输入节点地址",
  "title3460": "存储",
  "title3461": "自定义域名",
  "title3462": "请输入自定义域名",
  "title3463": "配置名不能为空",
  "title3464": "存储器不能为空",
  "title3465": "基础路径不能为空",
  "title3466": "主机地址不能为空",
  "title3467": "主机端口不能为空",
  "title3468": "连接模式不能为空",
  "title3469": "节点地址不能为空",
  "title3470": "自定义域名不能为空",
  "title3471": "添加文件配置",
  "title3472": "修改文件配置",
  "title3473": "是否确认删除文件配置编号为",
  "title3474": "是否确认修改配置编号为",
  "title3475": "的数据项为主配置",
  "title3476": "测试通过，上传文件成功！访问地址：",
  "title3477": "数据库",
  "title3478": "多数据源（读写分离）",
  "title3479": "异步任务",
  "title3480": "消息队列",
  "title3481": "处理器的名字",
  "title3482": "请输入处理器的名字",
  "title3483": "开始执行时间",
  "title3484": "选择开始执行时间",
  "title3485": "结束执行时间",
  "title3486": "选择结束执行时间",
  "title3487": "任务状态",
  "title3488": "请选择任务状态",
  "title3489": "处理器的参数",
  "title3490": "第几次执行",
  "title3491": "执行时间",
  "title3492": "毫秒",
  "title3493": "调度日志详细",
  "title3494": "日志编号：",
  "title3495": "任务编号：",
  "title3496": "处理器的名字：",
  "title3497": "处理器的参数：",
  "title3498": "第几次执行：",
  "title3499": "执行时间：",
  "title3500": "执行时长：",
  "title3501": "任务状态：",
  "title3502": "执行结果：",
  "title3503": "是否确认导出所有定时任务日志数据项",
  "title3504": "定时任务日志",
  "title3505": "请输入任务名称",
  "title3506": "执行日志",
  "title3507": "表达式",
  "title3508": "暂停",
  "title3509": "更多",
  "title3510": "执行一次",
  "title3511": "任务详细",
  "title3512": "调度日志",
  "title3513": "请输入处理器的参数",
  "title3514": "生成表达式",
  "title3515": "重试次数",
  "title3516": "请输入重试次数。设置为",
  "title3517": "时，不进行重试",
  "title3518": "重试间隔",
  "title3519": "请输入重试间隔，单位：毫秒。设置为",
  "title3520": "时，无需间隔",
  "title3521": "监控超时时间",
  "title3522": "请输入监控超时时间，单位：毫秒",
  "title3523": "表达式生成器",
  "title3524": "任务名称：",
  "title3525": "表达式：",
  "title3526": "重试次数：",
  "title3527": "重试间隔：",
  "title3528": "监控超时时间：",
  "title3529": "后续执行时间：",
  "title3530": "任务名称不能为空",
  "title3531": "处理器的名字不能为空",
  "title3532": "表达式不能为空",
  "title3533": "重试次数不能为空",
  "title3534": "重试间隔不能为空",
  "title3535": "确认要立即执行一次",
  "title3536": "任务吗",
  "title3537": "执行成功",
  "title3538": "添加任务",
  "title3539": "修改任务",
  "title3540": "是否确认删除定时任务编号为",
  "title3541": "定时任务编号为",
  "title3542": "是否确认导出所有定时任务数据项",
  "title3543": "服务监控",
  "title3544": "接口文档",
  "title3545": "参数分组",
  "title3546": "是否敏感",
  "title3547": "请输入参数分组",
  "title3548": "参数分组不能为空",
  "title3549": "缓存",
  "title3550": "本地缓存",
  "title3551": "版本",
  "title3552": "运行模式",
  "title3553": "单机",
  "title3554": "集群",
  "title3555": "端口",
  "title3556": "客户端数",
  "title3557": "运行时间",
  "title3558": "使用内存",
  "title3559": "内存配置",
  "title3560": "是否开启",
  "title3561": "是否成功",
  "title3562": "网络入口",
  "title3563": "出口",
  "title3564": "命令统计",
  "title3565": "内存信息",
  "title3566": "超时时间",
  "title3567": "秒",
  "title3568": "键名列表",
  "title3569": "缓存键名",
  "title3570": "缓存内容",
  "title3571": "清理全部",
  "title3572": "命令",
  "title3573": "峰值",
  "title3574": "内存消耗",
  "title3575": "正在加载缓存监控数据，请稍后！",
  "title3576": "刷新键名列表成功",
  "title3577": "清理缓存键名",
  "title3578": "名字",
  "title3579": "请输入名字",
  "title3580": "名字不能为空",
  "title3581": "分类不能为空",
  "title3582": "添加字典类型",
  "title3583": "修改字典类型",
  "title3584": "是否确认删除字典类型编号为",
  "title3585": "是否确认导出所有字典类型数据项",
  "title3586": "第三方服务类型",
  "title3587": "请选择第三方服务类型",
  "title3588": "配置编码",
  "title3589": "请输入配置编码",
  "title3590": "请选择开启状态",
  "title3591": "配置内容",
  "title3592": "第三方服务类型不能为空",
  "title3593": "配置编码不能为空",
  "title3594": "配置内容不能为空",
  "title3595": "添加第三方服务配置",
  "title3596": "修改第三方服务配置",
  "title3597": "是否确认删除第三方服务配置编号为",
  "title3598": "是否确认导出所有第三方服务配置数据项",
  "title3599": "优惠券类",
  "title3600": "请选择优惠券类型",
  "title3601": "满减",
  "title3602": "折扣",
  "title3603": "随机",
  "title3604": "优惠券名称",
  "title3605": "请输入优惠券名称",
  "title3606": "请选择状态（1进行中2已结束-1已关闭）",
  "title3607": "有效日期结束时间",
  "title3608": "优惠券类型",
  "title3609": "优惠券图片",
  "title3610": "发放数量",
  "title3611": "已领取数量",
  "title3612": "已使用数量",
  "title3613": "使用门槛",
  "title3614": "优惠金额",
  "title3615": "有效期限",
  "title3616": "领取上限",
  "title3617": "优惠叠加",
  "title3618": "是否显示",
  "title3619": "订单的优惠总金额",
  "title3620": "用券总成交额",
  "title3621": "是否禁止发放",
  "title3622": "使用优惠券购买的商品数量",
  "title3623": "名称备注",
  "title3624": "请输入名称备注",
  "title3625": "请输入发放数量",
  "title3626": "适用商品类型",
  "title3627": "适用商品",
  "title3628": "请输入适用商品",
  "title3629": "满多少元使用",
  "title3630": "请输入满多少元使用",
  "title3631": "代表无限制",
  "title3632": "发放面额",
  "title3633": "请输入发放面额",
  "title3634": "时需要添加",
  "title3635": "请输入1",
  "title3636": "最多折扣金额",
  "title3637": "请输入最多折扣金额",
  "title3638": "时可选择性添加",
  "title3639": "最低金额",
  "title3640": "请输入最低金额",
  "title3641": "最大金额",
  "title3642": "请输入最大金额",
  "title3643": "过期类型",
  "title3644": "使用开始日期",
  "title3645": "过期类型1时必填",
  "title3646": "选择使用开始日期",
  "title3647": "使用结束日期",
  "title3648": "选择使用结束日期",
  "title3649": "为2或者3时需要添加",
  "title3650": "领取之日起或者次日",
  "title3651": "天内有效",
  "title3652": "请输入当",
  "title3653": "是否无限制0-否",
  "title3654": "每人最大领取个数",
  "title3655": "请输入每人最大领取个数",
  "title3656": "是否开启过期提醒0-不开启",
  "title3657": "过期前",
  "title3658": "天提醒",
  "title3659": "请输入过期前",
  "title3660": "不限制",
  "title3661": "优惠券仅原价购买商品时可用",
  "title3662": "请输入是否显示",
  "title3663": "请输入订单的优惠总金额",
  "title3664": "请输入用券总成交额",
  "title3665": "是否禁止发放0-否",
  "title3666": "请输入使用优惠券购买的商品数量",
  "title3667": "选择有效日期结束时间",
  "title3668": "随机不能为空",
  "title3669": "优惠券名称不能为空",
  "title3670": "发放数量不能为空",
  "title3671": "已领取数量不能为空",
  "title3672": "已使用数量不能为空",
  "title3673": "适用商品类型1-全部商品可用；2-指定商品可用；3-指定商品不可用不能为空",
  "title3674": "使用门槛0-无门槛",
  "title3675": "有门槛不能为空",
  "title3676": "代表无限制不能为空",
  "title3677": "时需要添加不能为空",
  "title3678": "时可选择性添加不能为空",
  "title3679": "过期类型1-时间范围过期",
  "title3680": "领取之日固定日期后过期",
  "title3681": "领取次日固定日期后过期不能为空",
  "title3682": "天内有效不能为空",
  "title3683": "是不能为空",
  "title3684": "每人最大领取个数不能为空",
  "title3685": "开启不能为空",
  "title3686": "天提醒不能为空",
  "title3687": "优惠券仅原价购买商品时可用不能为空",
  "title3688": "是否显示不能为空",
  "title3689": "订单的优惠总金额不能为空",
  "title3690": "用券总成交额不能为空",
  "title3691": "使用优惠券购买的商品数量不能为空",
  "title3692": "状态（1进行中2已结束-1已关闭）不能为空",
  "title3693": "添加优惠券模板",
  "title3694": "修改优惠券模板",
  "title3695": "是否确认删除优惠券模板编号为",
  "title3696": "是否确认导出所有优惠券模板数据项",
  "title3697": "优惠券模板",
  "title3698": "身份证号",
  "title3699": "请输入身份证号",
  "title3700": "审核状态",
  "title3701": "请选择审核状态",
  "title3702": "审核时间",
  "title3703": "选择审核时间",
  "title3704": "关联的用户编号不能为空",
  "title3705": "身份证号不能为空",
  "title3706": "审核状态不能为空",
  "title3707": "审核时间不能为空",
  "title3708": "添加会员实名认证",
  "title3709": "修改会员实名认证",
  "title3710": "是否确认删除会员实名认证编号为",
  "title3711": "是否确认导出所有会员实名认证数据项",
  "title3712": "请输入推荐人编号",
  "title3713": "邀请人手机号",
  "title3714": "请输入邀请人手机号",
  "title3715": "会员编号",
  "title3716": "邀请人编号",
  "title3717": "关系路径",
  "title3718": "请输入邀请人编号",
  "title3719": "请输入关系路径",
  "title3720": "邀请人编号不能为空",
  "title3721": "关系路径不能为空",
  "title3722": "添加用户邀请关系",
  "title3723": "修改用户邀请关系",
  "title3724": "是否确认删除用户邀请关系编号为",
  "title3725": "是否确认导出所有用户邀请关系数据项",
  "title3726": "会员手机号",
  "title3727": "请输入会员手机号",
  "title3728": "收货号码",
  "title3729": "请输入收货号码",
  "title3730": "请选择是否默认",
  "title3731": "市",
  "title3732": "地址",
  "title3733": "请输入市",
  "title3734": "请输入地址",
  "title3735": "用户编号不能为空",
  "title3736": "地址不能为空",
  "title3737": "添加收货地址",
  "title3738": "修改收货地址",
  "title3739": "是否确认删除收货地址编号为",
  "title3740": "是否确认导出所有收货地址数据项",
  "title3741": "用户昵称",
  "title3742": "请输入用户昵称",
  "title3743": "用户状态",
  "title3744": "是否实名",
  "title3745": "请输入注册",
  "title3746": "最后登录",
  "title3747": "最后登录时间",
  "title3748": "重置短信",
  "title3749": "重置实名",
  "title3750": "重置锁单",
  "title3751": "重置成功",
  "title3752": "添加会员",
  "title3753": "修改会员",
  "title3754": "是否确认导出所有会员数据项",
  "title3755": "商户名称",
  "title3756": "请输入商户名称",
  "title3757": "应用编号",
  "title3758": "支付宝配置",
  "title3759": "微信配置",
  "title3760": "所属商户",
  "title3761": "请选择所属商户",
  "title3762": "支付结果的回调地址",
  "title3763": "请输入支付结果的回调地址",
  "title3764": "退款结果的回调地址",
  "title3765": "请输入退款结果的回调地址",
  "title3766": "应用名不能为空",
  "title3767": "支付结果的回调地址不能为空",
  "title3768": "退款结果的回调地址不能为空",
  "title3769": "商户编号不能为空",
  "title3770": "添加支付应用信息",
  "title3771": "修改支付应用信息",
  "title3772": "应用吗",
  "title3773": "是否确认删除支付应用信息编号为",
  "title3774": "已取消删除",
  "title3775": "是否确认导出所有支付应用信息数据项",
  "title3776": "支付应用信息",
  "title3777": "商户号",
  "title3778": "请输入商户号",
  "title3779": "商户全称",
  "title3780": "请输入商户全称",
  "title3781": "商户简称",
  "title3782": "请输入商户简称",
  "title3783": "商户编号",
  "title3784": "商户号不能为空",
  "title3785": "商户全称不能为空",
  "title3786": "商户简称不能为空",
  "title3787": "添加支付商户信息",
  "title3788": "修改支付商户信息",
  "title3789": "商户吗",
  "title3790": "是否确认删除支付商户信息编号为",
  "title3791": "是否确认导出所有支付商户信息数据项",
  "title3792": "支付商户信息",
  "title3793": "请选择应用信息",
  "title3794": "渠道编码",
  "title3795": "请输入渠道编码",
  "title3796": "商户订单编号",
  "title3797": "请输入商户订单编号",
  "title3798": "渠道订单号",
  "title3799": "请输入渠道订单号",
  "title3800": "请选择支付状态",
  "title3801": "退款状态",
  "title3802": "请选择退款状态",
  "title3803": "回调商户状态",
  "title3804": "请选择订单回调商户状态",
  "title3805": "支付渠道",
  "title3806": "应用名称",
  "title3807": "渠道名称",
  "title3808": "支付订单",
  "title3809": "商户",
  "title3810": "支付",
  "title3811": "商品标题",
  "title3812": "手续金额",
  "title3813": "回调状态",
  "title3814": "商户订单号",
  "title3815": "支付订单号",
  "title3816": "手续费",
  "title3817": "手续费比例",
  "title3818": "回调地址",
  "title3819": "失效时间",
  "title3820": "通知时间",
  "title3821": "退款次数",
  "title3822": "支付通道异步回调内容",
  "title3823": "时间范围最大为",
  "title3824": "天！",
  "title3825": "是否确认导出所有支付订单数据项",
  "title3826": "退款类型",
  "title3827": "请选择退款类型",
  "title3828": "商户退款订单号",
  "title3829": "请输入商户退款订单号",
  "title3830": "退款回调状态",
  "title3831": "请选择通知商户退款结果的回调状态",
  "title3832": "退款",
  "title3833": "交易",
  "title3834": "渠道",
  "title3835": "元",
  "title3836": "退款原因",
  "title3837": "退款成功时间",
  "title3838": "退款订单详情",
  "title3839": "商户退款单号",
  "title3840": "交易订单号",
  "title3841": "退款失效时间",
  "title3842": "回调时间",
  "title3843": "渠道退款单号",
  "title3844": "渠道错误码",
  "title3845": "渠道错误码描述",
  "title3846": "渠道额外参数",
  "title3847": "是否确认导出所有退款订单量据项",
  "title3848": "字典名称",
  "title3849": "字典标签",
  "title3850": "请输入字典标签",
  "title3851": "数据状态",
  "title3852": "字典编码",
  "title3853": "字典键值",
  "title3854": "字典排序",
  "title3855": "颜色类型",
  "title3856": "数据标签",
  "title3857": "请输入数据标签",
  "title3858": "数据键值",
  "title3859": "请输入数据键值",
  "title3860": "显示排序",
  "title3861": "数据标签不能为空",
  "title3862": "数据键值不能为空",
  "title3863": "数据顺序不能为空",
  "title3864": "主要",
  "title3865": "危险",
  "title3866": "添加字典数据",
  "title3867": "修改字典数据",
  "title3868": "是否确认删除字典编码为",
  "title3869": "字典数据",
  "title3870": "请输入字典名称",
  "title3871": "请输入字典类型",
  "title3872": "字典状态",
  "title3873": "字典编号",
  "title3874": "字典名称不能为空",
  "title3875": "字典类型不能为空",
  "title3876": "是否确认删除字典编号为",
  "title3877": "部门名称",
  "title3878": "请输入部门名称",
  "title3879": "菜单状态",
  "title3880": "负责人",
  "title3881": "上级部门",
  "title3882": "选择上级部门",
  "title3883": "请输入负责人",
  "title3884": "部门状态",
  "title3885": "部门名称不能为空",
  "title3886": "显示排序不能为空",
  "title3887": "请输入正确的邮箱地址",
  "title3888": "未设置",
  "title3889": "添加部门",
  "title3890": "修改部门",
  "title3891": "异常处理（错误码）",
  "title3892": "错误码类型",
  "title3893": "请选择错误码类型",
  "title3894": "错误码编码",
  "title3895": "请输入错误码编码",
  "title3896": "错误码提示",
  "title3897": "请输入错误码提示",
  "title3898": "错误码编码不能为空",
  "title3899": "错误码提示不能为空",
  "title3900": "添加错误码",
  "title3901": "修改错误码",
  "title3902": "是否确认删除错误码编号为",
  "title3903": "是否确认导出所有错误码数据项",
  "title3904": "错误码",
  "title3905": "文章类型",
  "title3906": "请选择文章类型",
  "title3907": "文章标题",
  "title3908": "请输入文章标题",
  "title3909": "文章内容",
  "title3910": "文章类型不能为空",
  "title3911": "文章标题不能为空",
  "title3912": "文章内容不能为空",
  "title3913": "添加文章",
  "title3914": "修改文章",
  "title3915": "是否确认删除文章编号为",
  "title3916": "是否确认导出所有文章数据项",
  "title3917": "Vui lòng nhập ký hiệu tiền tệ",
  "title3918": "Tên đơn vị tiền tệ",
  "title3919": "Trang hiện tại cung cấp chức năng sửa đổi 'Tỷ giá Lịch sử', sẽ ảnh hưởng đến tất cả các đơn hàng 'chưa thanh toán' trong ngày được sửa đổi và có tác động đến báo cáo tài chính và tính toán lợi nhuận. Nếu bạn cần điều chỉnh tỷ giá của các đơn hàng 'đã thanh toán,' vui lòng truy cập Phân Tích Lợi Nhuận -",
  "title3920": "Báo cáo lợi nhuận hàng tháng",
  "title3921": "để sửa đổi trên trang",
  "title3922": "Ký hiệu tiền tệ",
  "title3923": "Tỷ giá chính thức",
  "title3924": "Tỷ giá lấy từ tỷ giá chính thức, mỗi ngày 0",
  "title3925": "Cập nhật tỷ giá",
  "title3926": "Loại tỷ giá thực hiện",
  "title3927": "Tỷ giá cố định: Tỷ giá cố định do người dùng định nghĩa",
  "title3928": "Tỷ giá chiết khấu: Tỷ giá được tính bằng cách nhân tỷ giá chính thức với tỷ lệ giảm giá trong ngày đó",
  "title3929": "Ví dụ, Đô la Mỹ",
  "title3930": "Tỷ giá là 7",
  "title3931": "Và bạn đã thiết lập tỷ lệ giảm giá là 98",
  "title3932": "Sau đó tỷ giá thực sự",
  "title3933": "Tỷ lệ giảm giá tỷ giá",
  "title3934": "Tỷ giá cố định",
  "title3935": "Tỷ giá thực hiện",
  "title3936": "Tỷ giá lịch sử",
  "title3937": "Nhật ký",
  "title3938": "Thêm mẫu",
  "title3939": "Nhập tên mẫu",
  "title3940": "Tên Mẫu",
  "title3941": "Chọn ít nhất một mẫu",
  "title3942": "Xóa mẫu đã chọn?",
  "title3943": "Xóa?",
  "title3944": "请输入登录地址",
  "title3945": "请输入用户名称",
  "title3946": "访问编号",
  "title3947": "日志类型",
  "title3948": "登录日期",
  "title3949": "是否确认导出所有操作日志数据项",
  "title3950": "菜单路由",
  "title3951": "请输入菜单名称",
  "title3952": "菜单名称",
  "title3953": "分组名称",
  "title3954": "图标",
  "title3955": "权限标识",
  "title3956": "组件路径",
  "title3957": "选择上级菜单",
  "title3958": "菜单类型",
  "title3959": "菜单图标",
  "title3960": "点击选择图标",
  "title3961": "请输入分组名称",
  "title3962": "路由地址",
  "title3963": "访问的路由地址，如：",
  "title3964": "如需外网地址时，则以",
  "title3965": "请输入路由地址",
  "title3966": "请输入组件路径",
  "title3967": "方法上的权限字符，如：",
  "title3968": "权限字符",
  "title3969": "请权限标识",
  "title3970": "选择停用时，路由将不会出现在侧边栏，也不能被访问",
  "title3971": "显示状态",
  "title3972": "选择隐藏时，路由将不会出现在侧边栏，但仍然可以访问",
  "title3973": "显示",
  "title3974": "隐藏",
  "title3975": "选择缓存时，则会被",
  "title3976": "缓存，需要匹配组件的",
  "title3977": "和路由地址保持一致",
  "title3978": "是否缓存",
  "title3979": "不缓存",
  "title3980": "菜单名称不能为空",
  "title3981": "菜单顺序不能为空",
  "title3982": "路由地址不能为空",
  "title3983": "主类目",
  "title3984": "添加菜单",
  "title3985": "修改菜单",
  "title3986": "前端必须以",
  "title3987": "前端不能以",
  "title3988": "公告标题",
  "title3989": "请输入公告标题",
  "title3990": "操作人员",
  "title3991": "请输入操作人员",
  "title3992": "公告类型",
  "title3993": "内容类型",
  "title3994": "创建者",
  "title3995": "内容",
  "title3996": "公告标题不能为空",
  "title3997": "公告类型不能为空",
  "title3998": "添加公告",
  "title3999": "修改公告",
  "title4000": "是否确认删除公告编号为",
  "title4001": "系统公告",
  "title4002": "平台公告",
  "title4003": "东南亚资讯",
  "title4004": "全部已读",
  "title4005": "全部标记已读成功",
  "title4006": "发布时间：",
  "title4007": "确认支付",
  "title4008": "确认该订单完成支付？",
  "title4009": "岗位编码",
  "title4010": "请输入岗位编码",
  "title4011": "岗位名称",
  "title4012": "请输入岗位名称",
  "title4013": "岗位状态",
  "title4014": "岗位编号",
  "title4015": "岗位排序",
  "title4016": "请输入编码名称",
  "title4017": "岗位顺序",
  "title4018": "岗位名称不能为空",
  "title4019": "岗位编码不能为空",
  "title4020": "岗位顺序不能为空",
  "title4021": "添加岗位",
  "title4022": "修改岗位",
  "title4023": "是否确认删除岗位编号为",
  "title4024": "是否确认导出所有岗位数据项",
  "title4025": "岗位数据",
  "title4026": "操作模块",
  "title4027": "请输入操作模块",
  "title4028": "操作名",
  "title4029": "请输入操作名",
  "title4030": "操作类型",
  "title4031": "操作状态",
  "title4032": "操作人",
  "title4033": "操作日期",
  "title4034": "操作信息：",
  "title4035": "方法名：",
  "title4036": "方法参数：",
  "title4037": "操作日志",
  "title4038": "请输入地区父",
  "title4039": "地区名称",
  "title4040": "请输入地区名称",
  "title4041": "地区类型",
  "title4042": "请选择地区类型",
  "title4043": "地区父",
  "title4044": "地区名称不能为空",
  "title4045": "地区类型不能为空",
  "title4046": "添加地区",
  "title4047": "修改地区",
  "title4048": "是否确认删除地区编号为",
  "title4049": "是否确认导出所有地区数据项",
  "title4050": "角色编号",
  "title4051": "Loại vai trò",
  "title4052": "Chỉnh sửa vai trò",
  "title4053": "Do vì",
  "title4054": "Hoa hồng của nền tảng chỉ được thanh toán khi đơn hàng hoàn thành, bạn cần đặt tỷ lệ hoa hồng ước lượng cho ước lượng lợi nhuận trên các đơn hàng chưa thanh toán",
  "title4055": "Công thức tính hoa hồng: (Số tiền sản phẩm - Giảm giá của nền tảng - Giảm giá của người bán)",
  "title4056": "Tỷ lệ hoa hồng cửa hàng",
  "title4057": "Chú ý: Mỗi khi điều chỉnh tỷ lệ hoa hồng, nó sẽ được tính lại khi có đơn hàng chưa thanh toán được đồng bộ hóa; tỷ lệ hoa hồng mặc định là 3",
  "title4058": "Tỷ lệ hoa hồng của nền tảng",
  "title4059": "敏感词",
  "title4060": "请输入敏感词",
  "title4061": "请选择标签",
  "title4062": "请选择启用状态",
  "title4063": "请选择文章标签",
  "title4064": "检测敏感词",
  "title4065": "文本",
  "title4066": "请输入测试文本",
  "title4067": "检",
  "title4068": "测",
  "title4069": "敏感词不能为空",
  "title4070": "标签不能为空",
  "title4071": "测试文本不能为空",
  "title4072": "添加敏感词",
  "title4073": "检测文本是否含有敏感词",
  "title4074": "修改敏感词",
  "title4075": "不包含敏感词！",
  "title4076": "包含敏感词：",
  "title4077": "是否确认删除敏感词编号为",
  "title4078": "是否确认导出所有敏感词数据项",
  "title4079": "短信配置",
  "title4080": "短信签名",
  "title4081": "请输入短信签名",
  "title4082": "启用状态",
  "title4083": "短信",
  "title4084": "的账号",
  "title4085": "的密钥",
  "title4086": "短信发送回调",
  "title4087": "请选择渠道编码",
  "title4088": "请输入短信",
  "title4089": "请输入短信发送回调",
  "title4090": "短信签名不能为空",
  "title4091": "渠道编码不能为空",
  "title4092": "启用状态不能为空",
  "title4093": "的账号不能为空",
  "title4094": "添加短信渠道",
  "title4095": "修改短信渠道",
  "title4096": "是否确认删除短信渠道编号为",
  "title4097": "短信渠道",
  "title4098": "请选择短信渠道",
  "title4099": "模板编号",
  "title4100": "请输入模板编号",
  "title4101": "发送状态",
  "title4102": "请选择发送状态",
  "title4103": "发送时间",
  "title4104": "接收状态",
  "title4105": "请选择接收状态",
  "title4106": "接收时间",
  "title4107": "短信内容",
  "title4108": "短信类型",
  "title4109": "短信日志详细",
  "title4110": "短信渠道：",
  "title4111": "短信模板：",
  "title4112": "的模板编号：",
  "title4113": "短信内容：",
  "title4114": "短信参数：",
  "title4115": "发送状态：",
  "title4116": "发送时间：",
  "title4117": "发送结果：",
  "title4118": "短信编号：",
  "title4119": "请求编号：",
  "title4120": "接收状态：",
  "title4121": "接收时间：",
  "title4122": "接收结果：",
  "title4123": "是否确认导出所有短信日志数据项",
  "title4124": "短信日志",
  "title4125": "找不到签名：",
  "title4126": "请输入标签内容",
  "title4127": "标签内容",
  "title4128": "颜色",
  "title4129": "标签排序",
  "title4130": "请输入标签排序",
  "title4131": "请输入颜色",
  "title4132": "标签排序不能为空",
  "title4133": "标签内容不能为空",
  "title4134": "添加标签",
  "title4135": "修改标签",
  "title4136": "是否确认删除标签",
  "title4137": "是否确认导出所有标签数据项",
  "title4138": "多租户",
  "title4139": "租户名",
  "title4140": "请输入租户名",
  "title4141": "联系手机",
  "title4142": "请输入联系手机",
  "title4143": "租户状态",
  "title4144": "请选择租户状态",
  "title4145": "租户编号",
  "title4146": "租户套餐",
  "title4147": "系统租户",
  "title4148": "子账号数",
  "title4149": "过期时间",
  "title4150": "绑定域名",
  "title4151": "请选择租户套餐",
  "title4152": "用户密码",
  "title4153": "请输入用户密码",
  "title4154": "默认入口",
  "title4155": "请选择默认入口",
  "title4156": "请选择过期时间",
  "title4157": "请输入绑定域名",
  "title4158": "租户名不能为空",
  "title4159": "租户套餐不能为空",
  "title4160": "联系人不能为空",
  "title4161": "租户状态不能为空",
  "title4162": "过期时间不能为空",
  "title4163": "绑定域名不能为空",
  "title4164": "添加租户",
  "title4165": "修改租户",
  "title4166": "是否确认删除租户编号为",
  "title4167": "是否确认导出所有租户数据项",
  "title4168": "未知套餐",
  "title4169": "套餐名",
  "title4170": "请输入套餐名",
  "title4171": "套餐编号",
  "title4172": "受限页面",
  "title4173": "勾选的菜单将会被限制，在这些页面将会有弹框弹出显示",
  "title4174": "套餐名不能为空",
  "title4175": "关联的菜单编号不能为空",
  "title4176": "添加租户套餐",
  "title4177": "修改租户套餐",
  "title4178": "是否确认删除租户套餐编号为",
  "title4179": "请选择短信类型",
  "title4180": "模板编码",
  "title4181": "请输入模板编码",
  "title4182": "的模板编号",
  "title4183": "模板内容",
  "title4184": "短信渠道编号",
  "title4185": "请选择短信渠道编号",
  "title4186": "请输入模板内容",
  "title4187": "测试发送短信",
  "title4188": "短信类型不能为空",
  "title4189": "模板编码不能为空",
  "title4190": "模板名称不能为空",
  "title4191": "模板内容不能为空",
  "title4192": "的模板编号不能为空",
  "title4193": "短信渠道编号不能为空",
  "title4194": "手机不能为空",
  "title4195": "添加短信模板",
  "title4196": "修改短信模板",
  "title4197": "是否确认删除短信模板编号为",
  "title4198": "是否确认导出所有短信模板数据项",
  "title4199": "短信模板",
  "title4200": "提交发送成功！发送结果，见发送日志编号：",
  "title4201": "用户体系",
  "title4202": "三方登陆",
  "title4203": "部门",
  "title4204": "归属部门",
  "title4205": "请选择归属部门",
  "title4206": "用户性别",
  "title4207": "岗位",
  "title4208": "用户名称不能为空",
  "title4209": "用户昵称不能为空",
  "title4210": "用户密码不能为空",
  "title4211": "的新密码",
  "title4212": "报表设计器",
  "title4213": "Chào mừng bạn sử dụng",
  "title4214": "Dialog tự do",
  "title4215": "Tiên tiến",
  "title4216": "Mô hình thông minh",
  "title4217": "Khiến cho giao tiếp của bạn trở nên thông minh, hiệu quả và thuận tiện hơn!",
  "title4218": "Làm thế nào để hỏi hiệu quả hơn?",
  "title4219": "Bạn có thể thử hỏi như thế này:",
  "title4220": "Lưu lại",
  "title4221": "Tất cả nội dung được tạo bởi dịch vụ được tạo bởi một mô hình AI, và sự chính xác và đầy đủ của nội dung được tạo không thể đảm bảo, và không đại diện cho quan điểm hoặc quan điểm của chúng tôi",
  "title4222": "Đặt vai trò",
  "title4223": "Gán danh tính chuyên nghiệp",
  "title4224": "Ví dụ: Bạn là một chuyên gia tiếp thị có kinh nghiệm",
  "title4225": "Mô tả câu hỏi",
  "title4226": "Nhập câu hỏi của bạn cùng với thông tin nền, yêu cầu cụ thể, mục tiêu công việc, v.v.;",
  "title4227": "Định dạng xuất ra",
  "title4228": "Thông báo",
  "title4229": "Theo định dạng nào bạn cần và xuất nội dung?",
  "title4230": "Tạo cơ sở tri thức thông minh",
  "title4231": "Chỉnh sửa cơ sở tri thức thông minh",
  "title4232": "Tên cơ sở tri thức thông minh",
  "title4233": "Vui lòng nhập tên cơ sở tri thức",
  "title4234": "Chọn nền tảng",
  "title4235": "Giới thiệu cơ sở tri thức",
  "title4236": "Vui lòng nhập giới thiệu",
  "title4237": "Tạo mẫu",
  "title4238": "Lưu mẫu",
  "title4239": "Đã sử dụng",
  "title4240": "Tên tài liệu",
  "title4241": "Kích thước",
  "title4242": "Vui lòng chọn tệp",
  "title4243": "Kích thước tệp tải lên không được vượt quá",
  "title4244": "Vui lòng nhập phần giới thiệu cơ sở tri thức",
  "title4245": "Danh sách tỷ giá",
  "title4246": "Biểu đồ xu hướng tỷ giá",
  "title4247": "Tỷ giá chính thức hàng tháng",
  "title4248": "Tỷ giá thực hiện hàng tháng",
  "title4249": "Hoàn thành ngay khi tạo",
  "title4250": "Nhập yêu cầu bản sao của bạn trên trang 'Nhập Nội dung' để ngay lập tức tạo ra bản sao chất lượng cao độc quyền",
  "title4251": "Cập nhật lợi nhuận",
  "title4252": "Thiết lập tỷ giá thanh toán",
  "title4253": "Thêm chi phí tùy chỉnh",
  "title4254": "Xuất báo cáo",
  "title4255": "Bảng cân đối thanh toán",
  "title4256": "Chuyển đổi tiền tệ chỉ được hỗ trợ khi chọn một cửa hàng duy nhất, không phải nhiều cửa hàng",
  "title4257": "Số lượng đơn đặt hàng thanh toán",
  "title4258": "Tổng số tiền thanh toán đơn hàng",
  "title4259": "Chi phí sản phẩm",
  "title4260": "Số tiền thanh toán đánh giá - số tiền hoàn trả đánh giá",
  "title4261": "Phí tùy chỉnh",
  "title4262": "Khám phá thêm",
  "title4263": "Bảng tổng thu nhập đơn hàng",
  "title4264": "Bảng cân đối của nền tảng",
  "title4265": "Phí",
  "title4266": "Số tiền thanh toán của người mua",
  "title4267": "Tổng chi phí",
  "title4269": "Giảm giá bán hàng",
  "title4270": "Số tiền thanh toán đơn hàng hiệu quả",
  "title4271": "Số tiền điều chỉnh qua các tháng",
  "title4272": "Số lượng đơn đặt hàng bị tạm ngưng",
  "title4273": "Số lượng đơn đặt hàng đánh giá",
  "title4274": "Số tiền thanh toán đơn đặt hàng bị tạm ngưng",
  "title4275": "Số tiền thanh toán đơn đặt hàng đánh giá",
  "title4276": "Số tiền thanh toán đánh giá",
  "title4277": "Doanh thu - Chi phí sản phẩm - Phí tùy chỉnh - Tổn thất đánh giá",
  "title4278": "Doanh thu nền tảng",
  "title4279": "Phí nền tảng",
  "title4280": "Hoa hồng, phí dịch vụ, phí giao dịch",
  "title4281": "Chi phí khác trong cửa hàng",
  "title4282": "Phí đánh giá",
  "title4283": "Số lượng đơn đặt hàng đánh giá",
  "title4284": "Dự án",
  "title4285": "Thu nhập và chi phí tùy chỉnh",
  "title4286": "Chi phí chi tiết",
  "title4287": "Chi phí là bao nhiêu phần trăm của tổng chi phí",
  "title4288": "Chi phí là bao nhiêu phần trăm doanh số hiệu quả",
  "title4289": "Chi phí quảng cáo chi tiết",
  "title4290": "Thu nhập và chi phí khác",
  "title4291": "Chi phí sản phẩm trực tuyến",
  "title4292": "Tiền hoàn",
  "title4293": "Chọn dữ liệu cần xuất bằng cách lọc tiêu chí, tất cả dữ liệu được chọn sẽ được xuất khi tạo báo cáo",
  "title4294": "Tổng hợp dữ liệu",
  "title4295": "Tổng số khách truy cập duy nhất đã ghé thăm cửa hàng và trang chi tiết sản phẩm của bạn trong khoảng thời gian đã chọn",
  "title4296": "Tổng số đơn hàng đã xác nhận",
  "title4297": "Tổng số tiền của các đơn hàng đã xác nhận",
  "title4298": "Doanh số đánh giá",
  "title4299": "Số tiền đánh giá",
  "title4300": "Số tiền bán hàng mỗi đơn đặt hàng",
  "title4301": "Tỷ lệ chuyển đổi cửa hàng",
  "title4302": "Loại bỏ đánh giá + đặt hàng giữ + hủy + doanh số chưa thanh toán",
  "title4303": "Chi phí quảng cáo tìm kiếm",
  "title4304": "Chuyển đổi quảng cáo tìm kiếm",
  "title4305": "Số tiền bán hàng quảng cáo tìm kiếm",
  "title4306": "Tỷ lệ nhấp vào quảng cáo tìm kiếm",
  "title4307": "Tỷ lệ chuyển đổi quảng cáo tìm kiếm",
  "title4308": "Quảng cáo tìm kiếm",
  "title4309": "Chi phí quảng cáo liên kết",
  "title4310": "Chuyển đổi quảng cáo liên kết",
  "title4311": "Số tiền bán hàng quảng cáo liên kết",
  "title4312": "Tỷ lệ nhấp vào quảng cáo liên kết",
  "title4313": "Tỷ lệ chuyển đổi quảng cáo liên kết",
  "title4314": "Chi phí quảng cáo khuyến mãi",
  "title4315": "Chuyển đổi quảng cáo khuyến mãi",
  "title4316": "Số tiền bán hàng quảng cáo khuyến mãi",
  "title4317": "Tỷ lệ nhấp vào quảng cáo khuyến mãi",
  "title4318": "Tỷ lệ chuyển đổi quảng cáo khuyến mãi",
  "title4319": "Quảng cáo khuyến mãi",
  "title4320": "Chi phí vận chuyển ước tính",
  "title4321": "Đầu ra",
  "title4322": "Doanh số ròng - Chi phí quảng cáo tìm kiếm - Chi phí quảng cáo liên kết - Chi phí quảng cáo khuyến mãi - Chi phí sản phẩm - Thiệt hại đánh giá - Chi phí vận chuyển ước tính - Phí nền - Số tiền trả lại",
  "title4324": "Số lượng",
  "title4325": "Tổng số hàng đã xác nhận",
  "title4326": "Số lượng đã bán",
  "title4327": "Số lượng đơn hàng thực tế",
  "title4328": "Tổng số đơn hàng - Số đơn hàng đánh giá",
  "title4329": "Giá trị trung bình mỗi đơn hàng",
  "title4330": "Số lượng người mua đã xác nhận",
  "title4331": "Sửa mật khẩu",
  "title4332": "Mật khẩu cũ",
  "title4333": "Nhập mật khẩu cũ",
  "title4334": "Thay đổi số điện thoại yêu cầu xác minh số điện thoại gốc, và mã xác minh qua tin nhắn SMS sẽ được gửi đến điện thoại của bạn",
  "title4335": "Trên điện thoại",
  "title4336": "结束时间：",
  "title4337": "请假类型：",
  "title4338": "原因：",
  "title4339": "参数，无法查看",
  "title4340": "请假信息",
  "title4341": "请假类型",
  "title4342": "请选择请假类型",
  "title4343": "申请时间",
  "title4344": "发起请假",
  "title4345": "申请编号",
  "title4346": "开始时间",
  "title4347": "取消请假",
  "title4348": "审批进度",
  "title4349": "选择开始时间",
  "title4350": "选择结束时间",
  "title4351": "提",
  "title4352": "交",
  "title4353": "开始时间不能为空",
  "title4354": "结束时间不能为空",
  "title4355": "请假类型不能为空",
  "title4356": "请假原因不能为空",
  "title4357": "发起成功",
  "title4358": "Số lần nhấp vào quảng cáo",
  "title4359": "Hiện đã hỗ trợ",
  "title4360": "Phân tích dữ liệu quảng cáo hiện được hỗ trợ. Bạn có thể dễ dàng lấy được dữ liệu liên quan thông qua plugin. Vui lòng xem hướng dẫn sử dụng để biết hướng dẫn cụ thể!",
  "title4361": "Theo xu hướng",
  "title4362": "Theo giá trị",
  "title4363": "Số lần nhấp tự nhiên",
  "title4364": "Lượt hiển thị quảng cáo",
  "title4365": "Tỷ lệ doanh số quảng cáo",
  "title4366": "Tỷ lệ chuyển đổi quảng cáo",
  "title4367": "Chọn tối đa hai chỉ số",
  "title4368": "Chỉnh sửa chi phí sản phẩm",
  "title4369": "Thêm chi phí mới",
  "title4370": "Thêm chi phí lịch sử",
  "title4371": "Thêm chi phí lịch sử",
  "title4372": "Chỉnh sửa chi phí sản phẩm là cập nhật chi phí hiện tại của sản phẩm, mà không ảnh hưởng đến các đơn hàng trước đó. Các đơn hàng trong tương lai sẽ được tính bằng chi phí mới nhất.",
  "title4373": "Số tiền chi phí",
  "title4374": "Sau khi sửa đổi chi phí của sản phẩm cho một ngày cụ thể trong quá khứ, hệ thống sẽ tự động cập nhật chi phí của sản phẩm trong tất cả các đơn hàng trong ngày đó thành giá trị mới và tính toán lại dữ liệu báo cáo liên quan.",
  "title4375": "Hướng dẫn cài đặt chi phí",
  "title4376": "Phương pháp tính chi phí cho một sản phẩm",
  "title4377": "Chi phí của một sản phẩm",
  "title4378": "Số lượng còn lại từ lịch sử",
  "title4379": "Chi phí mua hàng theo đơn vị trong quá khứ",
  "title4380": "Tổng chi phí mua hàng của nhiều lô vào ngày tương ứng",
  "title4381": "Tổng số sản phẩm mua trong nhiều lô",
  "title4382": "Ví dụ minh họa",
  "title4383": "Cuối tháng còn hàng từ bán ra",
  "title4384": "số sản phẩm, giá mua",
  "title4385": "là đồng",
  "title4386": "Đã nhập hàng trong tháng",
  "title4387": "Tính toán giá mua cho tháng Sáu như sau",
  "title4388": "Purchase Price",
  "title4389": "Nếu còn lại 10 sản phẩm sau khi bán hàng trong tháng Sáu, với giá mua là 17 đồng, giá mua cho tháng Bảy, nếu không sử dụng chức năng mua hàng, sẽ tham chiếu đến giá mua cuối cùng, tức là 17 đồng",
  "title4390": "Có xung đột ngày trong cùng kho, vui lòng chọn lại",
  "title4391": "Xung đột ngày trong cùng kho, vui lòng chọn lại",
  "title4392": "Chỉnh sửa chi phí tùy chỉnh",
  "title4393": "Thêm chi phí tùy chỉnh",
  "title4394": "Vui lòng chọn ít nhất một dữ liệu",
  "title4395": "Loại thay đổi",
  "title4396": "Nội dung sửa đổi",
  "title4397": "Đến",
  "title4398": "Chi phí sản phẩm:",
  "title4399": "Tài khoản hoạt động",
  "title4400": "Hồ sơ sửa đổi chi phí sản phẩm",
  "title4401": "Số tiền mặc định",
  "title4402": "Sửa đổi số tiền",
  "title4403": "Lịch sử hồ sơ sửa đổi chi phí",
  "title4404": "Phạm vi ngày chi phí",
  "title4405": "Số tiền chi phí lịch sử",
  "title4406": "Tải lên",
  "title4407": "Vui lòng chuẩn bị dữ liệu để nhập theo định dạng của mẫu dữ liệu",
  "title4408": "Xung đột ngày trong cùng kho, vui lòng chọn lại",
  "title4409": "Chỉnh sửa chi phí tùy chỉnh",
  "title4410": "Thêm chi phí tùy chỉnh",
  "title4411": "Vui lòng chọn ít nhất một dữ liệu",
  "title4412": "Loại thay đổi",
  "title4413": "Nội dung sửa đổi",
  "title4414": "Đến",
  "title4415": "Chi phí sản phẩm:",
  "title4416": "Tài khoản hoạt động",
  "title4417": "Hồ sơ sửa đổi chi phí sản phẩm",
  "title4418": "Số tiền mặc định",
  "title4419": "Tiền hoàn",
  "title4420": "Sửa đổi số tiền",
  "title4421": "Lịch sử hồ sơ sửa đổi chi phí",
  "title4422": "Phạm vi ngày chi phí",
  "title4423": "Số tiền chi phí lịch sử",
  "title4424": "Tải lên",
  "title4425": "Vui lòng chuẩn bị dữ liệu để nhập theo định dạng của mẫu dữ liệu",
  "title4426": "Hình ảnh đã được lưu",
  "title4427": "cái",
  "title4428": "Tăng dung lượng",
  "title4429": "Sử dụng dung lượng",
  "title4430": "Đã sử dụng",
  "title4431": "Tổng cộng",
  "title4432": "Đã liên kết",
  "title4433": "Phân tích Sản phẩm",
  "title4434": "Tổng chi phí hàng hóa cho các đơn đặt hàng hợp lệ",
  "title4435": "Số lượng đơn trả hàng",
  "title4436": "Tổng chi phí quảng cáo",
  "title4437": "Bảng xếp hạng doanh số hợp lệ",
  "title4438": "Xu hướng bán hàng",
  "title4439": "Sửa đổi hoạt động",
  "title4440": "Phạm vi doanh số từ",
  "title4441": "đến",
  "title4442": "Được xác định là",
  "title4443": "Và số lượng tồn kho nằm trong",
  "title4444": "Vui lòng thiết lập phạm vi bán hàng",
  "title4445": "Vui lòng nhập kho từ nhỏ đến lớn",
  "title4446": "Vui lòng nhập phạm vi từ nhỏ đến lớn",
  "title4447": "Chọn hình ảnh trực tuyến (",
  "title4448": "Vui lòng nhập địa chỉ hình ảnh trực tuyến, một địa chỉ mỗi dòng",
  "title4449": "Thông tin đối thủ",
  "title4450": "Liên kết đối thủ",
  "title4451": "Địa chỉ xác minh",
  "title4452": "Tiêu đề đối thủ",
  "title4453": "Ghi chú sản phẩm",
  "title4454": "Xác minh thất bại",
  "title4455": "Mẫu nhập vị trí kệ",
  "title4456": "Chọn hình ảnh địa phương",
  "title4457": "Chọn hình ảnh trực tuyến",
  "title4458": "Không có hình ảnh",
  "title4459": "Vui lòng chọn tệp hình ảnh cục bộ hoặc chọn hình ảnh trực tuyến để thêm, bạn có thể tải lên tối đa 8 hình ảnh",
  "title4460": "Định dạng hình ảnh",
  "title4461": "Kích thước hình ảnh không vượt quá",
  "title4462": "Kích thước hình ảnh không được nhỏ hơn",
  "title4463": "Thông tin kho",
  "title4464": "Số lượng tồn kho",
  "title4465": "Tần suất nhập hàng",
  "title4466": "Thông tin giá",
  "title4467": "Giá mua",
  "title4468": "cung cấp Thông tin",
  "title4469": "Chọn nhà cung cấp",
  "title4470": "Liên kết nhà cung cấp",
  "title4471": "Vấn đề hậu mãi",
  "title4472": "ID hàng trả:",
  "title4473": "Lý do hủy bỏ",
  "title4474": "Phương pháp xử lý",
  "title4475": "Quản lý trả hàng",
  "title4476": "Xử lý trả hàng",
  "title4477": "Mô tả hoạt động",
  "title4478": "Thời gian mua hàng",
  "title4479": "Số lượng mua",
  "title4480": "Thời gian ra đơn",
  "title4481": "Thời gian giao hàng",
  "title4482": "Số lượng nhập xuất kho",
  "title4483": "Lý do nhập xuất kho",
  "title4484": "Nhật ký sửa đổi",
  "title4485": "Giới hạn đã chọn hiện tại",
  "title4486": "tệp, được chọn lần này",
  "title4487": "tệp đã chọn tổng cộng",
  "title4488": "tệp",
  "title4489": "Đã chọn",
  "title4490": "Chọn vị trí kệ",
  "title4491": "Thêm vào kế hoạch mua hàng",
  "title4492": "Sau khi thêm kế hoạch mua hàng, vui lòng đi đến 'Mua hàng - '",
  "title4493": "trang để xem kế hoạch mua hàng.",
  "title4494": "Số lượng in",
  "title4495": "Mã vạch",
  "title4496": "Mã QR",
  "title4497": "Giấy nhãn",
  "title4498": "Một cột",
  "title4499": "Hai cột",
  "title4500": "Ba cột",
  "title4501": "In",
  "title4502": "In",
  "title4503": "Vui lòng chọn giấy nhãn",
  "title4504": "Vui lòng nhập số lượng",
  "title4505": "Không thể xóa cuối cùng!",
  "title4506": "Cài đặt nhóm",
  "title4507": "Nhập từ khóa để lọc",
  "title4508": "Xoá nhóm",
  "title4509": "Xóa cả các nhóm con của nó và điều chỉnh phân nhóm dữ liệu đã áp dụng thành không nhóm.",
  "title4510": "Dữ liệu đề xuất nhập hàng sẽ tính toán dữ liệu đề xuất dựa trên thời gian phục hồi, vui lòng điền theo sử dụng thực tế",
  "title4511": "Thêm doanh số hàng ngày",
  "title4512": "Quy tắc cung cấp lại",
  "title4513": "Loại bỏ dữ liệu hết hàng ra khỏi lịch sử bán hàng",
  "title4514": "Quy tắc doanh số hàng ngày",
  "title4515": "Doanh số động",
  "title4516": "Doanh số cố định",
  "title4517": "Doanh số phần trăm",
  "title4518": "Khoảng thời gian",
  "title4519": "Tất cả thời gian",
  "title4520": " bán hàng hàng ngày",
  "title4521": "Tính toán",
  "title4522": "Trung bình hàng ngày",
  "title4523": "Thời gian ra đơn",
  "title4524": "Thời gian yêu cầu của người mua",
  "title4525": "Lý do trả hàng",
  "title4526": " Trạng thái trả hàng ",
  "title4527": "Chưa hoàn thành",
  "title4528": "Đã hoàn thành",
  "title4529": "Chưa Nhận Hàng",
  "title4530": "Thiếu hàng",
  "title4531": "Nhận sai hàng",
  "title4532": "Hàng bị hỏng",
  "title4533": "Sản phẩm có khuyết điểm",
  "title4534": "Hàng hết hạn",
  "title4535": "Hàng giả mạo",
  "title4536": "Hàng không đúng mô tả",
  "title4537": "Hàng không phù hợp",
  "title4538": "Thay đổi quyết định",
  "title4539": "Gói bí ẩn",
  "title4540": "Bao bì bị hỏng",
  "title4541": "Yêu cầu trả hàng",
  "title4542": "Đơn đặt hàng trùng lặp",
  "title4543": "Đơn hàng sai",
  "title4544": "Không muốn nữa",
  "title4545": "Hủy từ nền tảng",
  "title4546": "Phí vận chuyển cao",
  "title4547": "Không có phương thức thanh toán mong muốn",
  "title4548": "Thay đổi phương thức thanh toán",
  "title4549": "Tìm thấy giá rẻ hơn",
  "title4550": "Giá sai",
  "title4551": "Người mua không thanh toán",
  "title4552": "Chiết khấu không như mong đợi",
  "title4553": "Thay đổi chiết khấu",
  "title4554": "Thông tin vận chuyển không chính xác",
  "title4555": "Địa chỉ của người mua không thể tiếp cận",
  "title4556": "Hàng không được giao đúng hạn",
  "title4557": "Hàng không được gửi đúng hạn",
  "title4558": "Hàng có vấn đề về chất lượng",
  "title4559": "Thiếu phụ kiện hoặc quà tặng",
  "title4560": "Hàng không như mong đợi",
  "title4561": "Đây là một số lý do trả hàng phổ biến",
  "title4562": "Số lượng trả lại",
  "title4563": "Số tỷ lệ",
  "title4564": "Số lượng tỷ lệ lý do trả hàng",
  "title4565": "Hằng ngày",
  "title4566": "Chu kỳ thanh toán",
  "title4567": "Trạng thái thanh toán",
  "title4568": "Lịch sử thanh toán",
  "title4569": "Số tiền chuyển khoản",
  "title4570": "Ngày chuyển khoản",
  "title4571": "Trạng thái chuyển khoản",
  "title4572": "Số cuối của ngân hàng",
  "title4573": "Mã chuyển khoản ngân hàng",
  "title4574": "Số tiền chưa thanh toán",
  "title4575": "Số tiền đã thanh toán",
  "title4576": "Số tiền chuyển khoản thất bại",
  "title4577": "Tỷ lệ chi phí",
  "title4578": "Chọn nhiều khu vực",
  "title4579": "Phân tích so sánh",
  "title4580": "Xuất đơn hàng",
  "title4581": "Nhân dân tệ (CNY)",
  "title4582": "Tiền tệ địa phương",
  "title4583": "Thêm mẫu tùy chỉnh",
  "title4584": "Đang xuất, vui lòng đợi!",
  "title4585": "Đang xử lý dữ liệu xuất, vui lòng không đóng trang hiện tại. Nó sẽ tự động tải xuống khi hoàn thành. Đóng trang sẽ hủy bỏ việc tải xuống.",
  "title4586": "Chọn mẫu",
  "title4587": "Mẫu tiêu chuẩn",
  "title4588": "Chọn nhãn",
  "title4589": "Đơn hàng, chỉ sửa cửa hàng được chọn trong các đơn hàng",
  "title4590": "Mối quan hệ phù hợp",
  "title4591": "Sản phẩm đã phù hợp",
  "title4592": "Chọn nhiều nền tảng",
  "title4593": "Chính",
  "title4594": "Chia",
  "title4595": "Trạng thái đơn hàng",
  "title4596": "Thông tin cơ bản",
  "title4597": "Số tiền sản phẩm",
  "title4598": "Đặc điểm kỹ thuật",
  "title4599": "Bí danh",
  "title4600": "Địa chỉ 1",
  "title4601": "Điện thoại di động",
  "title4602": "Thị trấn",
  "title4603": "Địa chỉ 2",
  "title4604": "Điện thoại hóa đơn.",
  "title4605": "Số nhà",
  "title4606": "Địa chỉ 3",
  "title4607": "Thông tin vận chuyển",
  "title4608": "Người mua chỉ định vận chuyển",
  "title4609": "Phương pháp nhận hàng",
  "title4610": "Ghi chú của tôi",
  "title4611": "Thông tin tài chính",
  "title4612": "Đơn vị tiền tệ hiện tại",
  "title4613": "Trao đổi",
  "title4614": "Tỷ giá cố định",
  "title4615": "Tính toán thử nghiệm",
  "title4616": "Doanh thu ước tính",
  "title4617": "Chi phí ước tính",
  "title4618": "Chi phí vận chuyển (thử)",
  "title4619": "Tổng chi phí",
  "title4621": "Tổng lợi nhuận",
  "title4622": "Thu nhập",
  "title4623": "Không có gói hàng đặt hàng",
  "title4624": "Gói hàng",
  "title4625": "Không có thông tin gói hàng",
  "title4626": "Không có thông tin",
  "title4627": "Chia đơn hàng",
  "title4628": "Hủy chia đơn hàng",
  "title4629": "các gói",
  "title4630": "các sản phẩm",
  "title4631": "Nhà kho:",
  "title4632": "Chia các gói",
  "title4633": "Gói hàng chính",
  "title4634": "Di chuyển đến",
  "title4635": "Số gói hàng",
  "title4636": "Đơn giá sản phẩm",
  "title4637": "Chọn ít nhất một sản phẩm",
  "title4638": "Gói hàng chính phải có ít nhất một sản phẩm",
  "title4639": "Tên thương hiệu",
  "title4640": "Vui lòng nhập tên thương hiệu",
  "title4641": "Số thương hiệu",
  "title4642": "Hình ảnh thương hiệu",
  "title4643": "Hình ảnh danh mục",
  "title4644": "Sắp xếp thương hiệu",
  "title4645": "Mô tả thương hiệu",
  "title4646": "Vui lòng nhập sắp xếp thương hiệu",
  "title4647": "Tên thương hiệu không được để trống",
  "title4648": "Hình ảnh thương hiệu không được để trống",
  "title4649": "Sắp xếp thương hiệu không được để trống",
  "title4650": "Thêm thương hiệu",
  "title4651": "Sửa đổi thương hiệu",
  "title4652": "Bạn có chắc chắn muốn xóa số thứ tự thương hiệu này không",
  "title4653": "Hình thu nhỏ",
  "title4654": "Hình thu nhỏ",
  "title4655": "Liên kết chuyển hướng",
  "title4656": "Vui lòng nhập liên kết chuyển hướng",
  "title4657": "Vui lòng nhập sắp xếp",
  "title4658": "Tiêu đề không được để trống",
  "title4659": "Địa chỉ hình ảnh không được để trống",
  "title4660": "Địa chỉ chuyển hướng không được để trống",
  "title4661": "Sắp xếp không được để trống",
  "title4662": "Sắp xếp danh mục",
  "title4663": "Danh mục cha",
  "title4664": "Gợi ý",
  "title4665": "Độ phân giải hình ảnh",
  "title4666": "Mô tả danh mục",
  "title4667": "Vui lòng nhập mô tả danh mục",
  "title4668": "Vui lòng chọn danh mục cha",
  "title4669": "Tên danh mục không được để trống",
  "title4670": "Hình ảnh danh mục không được để trống",
  "title4671": "Sắp xếp danh mục không được để trống",
  "title4672": "Danh mục cấp cao",
  "title4673": "Thêm danh mục sản phẩm",
  "title4674": "Sửa đổi danh mục sản phẩm",
  "title4675": "Bạn có chắc chắn muốn xóa số thứ tự danh mục sản phẩm này không",
  "title4676": "Tên đặc điểm",
  "title4677": "Vui lòng nhập tên đặc điểm",
  "title4678": "Giá trị thuộc tính",
  "title4679": "Giá trị thuộc tính không được để trống",
  "title4680": "Thêm đặc điểm",
  "title4681": "Sửa đổi đặc điểm",
  "title4682": "Bạn có chắc chắn muốn xóa tên đặc điểm này không",
  "title4683": "Bạn có chắc chắn muốn xuất tất cả các mục dữ liệu tên đặc điểm không",
  "title4684": "Mã sản phẩm商品编码；Tên sản phẩm商品名称；",
  "title4685": "Doanh số sản phẩm, số tiền sản phẩm",
  "title4686": "Vui lòng chọn trạng thái đặt hàng",
  "title4687": "Giao diện tham khảo;",
  "title4688": "Trường: Thông tin sản phẩm, giá, tồn kho, doanh số bán hàng, sắp xếp, thời gian tạo, trạng thái, thao tác;",
  "title4689": "Tất cả, đang bán, trong kho, trong cảnh báo",
  "title4690": "Địa chỉ hình ảnh chính của sản phẩm",
  "title4691": "Trường sắp xếp",
  "title4692": "Clicks",
  "title4693": "Phạm vi giá",
  "title4694": "Sửa sản phẩm",
  "title4695": "Cài đặt cơ bản",
  "title4696": "Điểm bán hàng sản phẩm",
  "title4697": "Vui lòng nhập điểm bán hàng sản phẩm",
  "title4698": "Hình ảnh chính của sản phẩm",
  "title4699": "Thương hiệu sản phẩm",
  "title4700": "Vui lòng chọn thương hiệu sản phẩm",
  "title4701": "Danh mục SKU hàng hóa",
  "title4702": "Đăng bán ngay",
  "title4703": "Đưa vào kho",
  "title4704": "Giá và tồn kho",
  "title4705": "Kích hoạt biến thể",
  "title4706": "Biến thể đơn",
  "title4707": "Nhiều biến thể",
  "title4708": "Biến thể:",
  "title4709": "Thêm mục biến thể",
  "title4710": "Chi tiết biến thể",
  "title4711": "Hình ảnh biến thể",
  "title4712": "Giá thị trường",
  "title4713": "Giá bán",
  "title4714": "Giá thành",
  "title4715": "Cảnh báo tồn kho",
  "title4716": "Mã vạch",
  "title4717": "Vô hiệu hóa",
  "title4718": "Doanh số ảo",
  "title4719": "Vui lòng nhập doanh số ảo",
  "title4720": "Chi tiết sản phẩm",
  "title4721": "Cài đặt nâng cao",
  "title4722": "Vui lòng nhập trường sắp xếp",
  "title4723": "Hiển thị tồn kho",
  "title4724": "Xác nhận",
  "title4725": "Trạng thái sản phẩm không thể trống",
  "title4726": "Địa chỉ hình ảnh carousel sản phẩm không thể trống",
  "title4727": "Doanh số ảo không thể trống",
  "title4728": "渠道费率",
  "title4729": "请输入渠道费率",
  "title4730": "开放平台",
  "title4731": "请输入开放平台",
  "title4732": "渠道状态",
  "title4733": "网关地址",
  "title4734": "算法类型",
  "title4735": "公钥类型",
  "title4736": "商户私钥",
  "title4737": "请输入商户私钥",
  "title4738": "支付宝公钥字符串",
  "title4739": "请输入支付宝公钥字符串",
  "title4740": "商户公钥应用证书",
  "title4741": "请上传商户公钥应用证书",
  "title4742": "支付宝公钥证书",
  "title4743": "请上传支付宝公钥证书",
  "title4744": "根证书",
  "title4745": "请上传根证书",
  "title4746": "请输入开放平台上创建的应用的",
  "title4747": "渠道状态不能为空",
  "title4748": "请传入网关地址",
  "title4749": "请传入签名算法类型",
  "title4750": "公钥类型不能为空",
  "title4751": "请上传指定根证书",
  "title4752": "编辑支付渠道",
  "title4753": "创建支付渠道",
  "title4754": "请上传指定格式",
  "title4755": "文件大小超过",
  "title4756": "公众号",
  "title4757": "请输入公众号",
  "title4758": "商户密钥",
  "title4759": "请输入商户密钥",
  "title4760": "证书",
  "title4761": "请上传",
  "title4762": "请传入商户号",
  "title4763": "版本不能为空",
  "title4764": "密钥值",
  "title4765": "Vui lòng nhập tỷ giá thực thi",
  "title4766": "Sự biến động của tỷ giá thực hiện mà bạn thiết lập lớn, vui lòng kiểm tra và gửi lại",
  "title4767": "Biểu tượng tiền tệ:",
  "title4768": "Tên tiền tệ:",
  "title4769": "Chi tiết hoạt động",
  "title4770": "Mẫu tùy chỉnh",
  "title4771": "Vui lòng chọn ít nhất một tham số",
  "title4772": "Thiết lập tỷ giá thanh toán chỉ ảnh hưởng đến đơn hàng 'thanh toán đã nhận' và ảnh hưởng đến báo cáo tài chính và tính toán lợi nhuận.",
  "title4773": "Sau khi sửa đổi tỷ giá, dự kiến sẽ có hiệu lực trong 10 đến 15 phút. Vui lòng kiên nhẫn đợi.",
  "title4774": "Chọn tháng",
  "title4775": "Tỷ giá thanh toán",
  "title4776": "Đang cập nhật...",
  "title4777": "Sửa đổi tỷ giá",
  "title4778": "Tỷ giá hối đoái thời gian thực",
  "title4779": "Vui lòng nhập tỷ giá thanh toán",
  "title4780": "Sự biến động của tỷ giá trả về bạn đặt là đáng kể. Vui lòng kiểm tra lại trước khi gửi.",
  "title4781": "Chỉnh sửa tỷ giá",
  "title4782": "Chiết khấu tỷ giá",
  "title4783": "Mẹo:",
  "title4784": "Làm thế nào để sử dụng chiết khấu tỷ giá?",
  "title4785": "Khi chọn chiết khấu tỷ giá, tỷ giá sẽ được thực hiện theo chiết khấu. Nó thực sự được áp dụng nơi tỷ giá được tính cho các đơn hàng và thông tin khác.",
  "title4786": "Chiết khấu tỷ giá được tính như thế nào?",
  "title4787": "Chiết khấu tỷ giá thường được tính bằng cách chia tỷ giá trả lại cho tỷ giá chính thức.",
  "title4788": "Ví dụ, nếu tỷ giá chính thức là 7 và tỷ giá trả lại là 7,",
  "title4789": "Và tỷ lệ thanh toán là 7",
  "title4790": "sau đó tỷ lệ giảm giá tỷ giá",
  "title4791": "được làm tròn đến bốn chữ số thập phân, tức là 99.",
  "title4792": "Nhập 99 vào ô đầu vào.",
  "title4793": "Hoàn tất.",
  "title4794": "Vui lòng nhập tỷ giá cố định",
  "title4795": "Sự biến động của tỷ giá bạn đặt là đáng kể. Vui lòng kiểm tra lại trước khi gửi.",
  "title4796": "Vui lòng nhập tỷ lệ",
  "title4797": "Sửa đổi tỷ giá thực thi",
  "title4798": "Sửa đổi tỷ giá thực thi lịch sử, dự kiến ​​sẽ có hiệu lực trong 10 đến 15 phút. Xin hãy kiên nhẫn.",
  "title4799": "Chiết khấu tỷ giá:",
  "title4800": "Vui lòng chọn ngày",
  "title4801": "Không thể chọn hôm nay",
  "title4802": "客户端编号",
  "title4803": "客户端密钥",
  "title4804": "应用图标",
  "title4805": "访问令牌的有效期",
  "title4806": "刷新令牌的有效期",
  "title4807": "授权类型",
  "title4808": "请输入客户端编号",
  "title4809": "请输入客户端密钥",
  "title4810": "应用描述",
  "title4811": "单位：秒",
  "title4812": "请输入授权类型",
  "title4813": "授权范围",
  "title4814": "请输入授权范围",
  "title4815": "自动授权范围",
  "title4816": "可重定向的",
  "title4817": "请输入可重定向的",
  "title4818": "权限",
  "title4819": "请输入权限",
  "title4820": "资源",
  "title4821": "请输入资源",
  "title4822": "附加信息",
  "title4823": "请输入附加信息，",
  "title4824": "格式数据",
  "title4825": "客户端编号不能为空",
  "title4826": "客户端密钥不能为空",
  "title4827": "应用图标不能为空",
  "title4828": "访问令牌的有效期不能为空",
  "title4829": "刷新令牌的有效期不能为空",
  "title4830": "授权类型不能为空",
  "title4831": "客户端",
  "title4832": "是否确认删除客户端编号为",
  "title4833": "访问令牌",
  "title4834": "刷新令牌",
  "title4835": "强退",
  "title4836": "是否确认强退令牌为",
  "title4837": "强退成功",
  "title4838": "Nhập mật khẩu cũ",
  "title4839": "Mật khẩu mới",
  "title4840": "Mật khẩu mới không thể để trống",
  "title4841": "Hãy xác nhận mật khẩu",
  "title4842": "Hai mật khẩu đã nhập không nhất trí",
  "title4843": "Mật khẩu cũ không được để trống",
  "title4844": "Mật khẩu mới không được để trống",
  "title4845": "Chiều dài trong",
  "title4846": "Mật khẩu không thể để trống",
  "title4847": "Dấu vân tay",
  "title4848": "Mạng xã hội",
  "title4849": "Đã được kết nối",
  "title4850": "Mở ràng buộc",
  "title4851": "Không được kết nối",
  "title4852": "Kết nối",
  "title4853": "Kết nối thành công",
  "title4854": "Mở ràng buộc thành công",
  "title4855": "Chỉnh sửa hồ sơ",
  "title4856": "Hình đại diện",
  "title4857": "Thay đổi hình đại diện",
  "title4858": "Nam",
  "title4859": "Nữ",
  "title4860": "Xác minh di động",
  "title4861": "Điện thoại di động của bạn",
  "title4862": "Mã xác minh",
  "title4863": "Nhấn vào để tải lên hình đại diện",
  "title4864": "Chỉnh sửa hình đại diện",
  "title4865": "Lỗi định dạng tệp, vui lòng tải lên loại hình ảnh",
  "title4866": "tệp có hậu tố.",
  "title4867": "การดึงสินค้า",
  "title4868": "Quá trình thu thập dữ liệu đang diễn ra, vui lòng không đóng trang hiện tại. Nếu đóng trang, hoạt động thu thập sản phẩm sẽ bị hủy.",
  "title4869": "Sản phẩm đang được thu thập, vui lòng đợi",
  "title4870": "Thu thập thành công, vui lòng kiểm tra kết quả trong danh sách thu thập",
  "title4871": "Mô tả dài",
  "title4872": "Mô tả ngắn",
  "title4873": "Dựa trên hạn chế của mỗi nền tảng về số lượng hình ảnh sản phẩm, hình ảnh ưu tiên cao hơn sẽ được chọn làm hình ảnh sản phẩm.",
  "title4874": "Tải lên cục bộ",
  "title4875": "Địa chỉ trực tuyến",
  "title4876": "Đỏ",
  "title4877": "Vàng",
  "title4878": "Xanh",
  "title4879": "Trang hiện tại",
  "title4880": "Chỉ số cốt lõi",
  "title4881": "Tình hình tồn kho",
  "title4882": "Xu hướng chi phí và doanh số",
  "title4883": "Tình hình bán hàng",
  "title4884": "Chi tiết chi phí",
  "title4885": "Tình hình đánh giá",
  "title4886": "Phân phối dữ liệu",
  "title4887": "Chi tiết chi phí tùy chỉnh",
  "title4888": "Không có chi phí",
  "title4889": "Chiếm đóng khu vực này",
  "title4890": "Chiếm đóng tất cả cửa hàng",
  "title4891": "Sắp xếp chỉ số",
  "title4892": "Kéo chỉ số để sắp xếp",
  "title4893": "Tổng lợi nhuận, không bao gồm đơn hàng bị hủy, được đánh giá và đặt hàng",
  "title4894": "Tổng số tiền được đặt hàng bởi người mua, bao gồm cả trợ cấp từ nền tảng",
  "title4895": "Tổng doanh số dưới các đơn hàng hiệu quả, không bao gồm các đơn hàng bị hủy, được đánh giá và đặt hàng, bao gồm cả trợ cấp từ nền tảng",
  "title4896": "Tổng số lượng đơn hàng được đặt bởi người mua.",
  "title4897": "Tổng số đơn đã bán, bao gồm đơn hủy và đơn hoàn tiền",
  "title4898": "Xem theo biểu đồ",
  "title4899": "Xem theo bảng",
  "title4900": "Hiển thị tối đa 15 mục",
  "title4901": "Tổng chi phí",
  "title4902": "Biểu đồ phức hợp",
  "title4903": "Biểu đồ so sánh",
  "title4904": "Đánh giá tích cực",
  "title4905": "Đánh giá Trung/Giảm",
  "title4906": "Nhận xét đang được thu thập, vui lòng kiểm tra lại sau",
  "title4907": "Phân tích bình luận",
  "title4908": "Ngày nhận xét",
  "title4909": "So sánh chi phí và doanh số",
  "title4910": "Biểu đồ xu hướng",
  "title4911": "Số lượng bán hàng trung bình hàng ngày",
  "title4912": "Tổng số lượng bán hàng mỗi ngày",
  "title4913": "Ngày bán dự kiến",
  "title4914": "Hàng tồn trong quá trình vận chuyển",
  "title4915": "Tuổi của hàng tồn",
  "title4916": "Giá trị tồn kho tổng cộng",
  "title4917": "Tổng số lượng tồn kho có sẵn",
  "title4918": "Tổng số lượng tồn kho đóng băng",
  "title4919": "Tổng số hàng đang vận chuyển",
  "title4920": "Số ngày bán trung bình",
  "title4921": "Thứ",
  "title4922": "Số ngày tối đa cho nhóm không được để trống",
  "title4923": "Giá trị phải lớn hơn nhóm trước đó",
  "title4924": "Tiền hoàn",
  "title4925": "Thanh toán WeChat",
  "title4926": "Thông tin cửa hàng",
  "title4927": "Số tiền phải thu ước tính",
  "title4928": "Phí xử lý ước tính",
  "title5000": "Phù hợp",
  "title5001": "Chữ cái phải chứa chữ in hoa",
  "title5002": "Chữ cái, số và ký tự phải chứa ít nhất 2 loại",
  "title5003": "Mật khẩu không thể giống với tên người dùng",
  "title5004": "Thoát",
  "title5005": "Chúc mừng bạn đã đăng ký thành công! Để mang lại trải nghiệm tốt hơn, vui lòng điền thông tin sau.",
  "title5006": "Vui lòng chọn khu vực bán hàng và xác nhận loại tiền chính. Khi khu vực bán hàng và loại tiền chính của tài khoản được xác nhận, chúng sẽ không thể được thay đổi lại. Vui lòng kiểm tra kỹ trước khi gửi.",
  "title5007": "Chọn khu vực bán hàng",
  "title5008": "Tiền tệ tài khoản",
  "title5010": "liên hệ với chúng tôi",
  "title5011": "Thêm dịch vụ chăm sóc khách hàng riêng, giải quyết vấn đề một cách kịp thời",
  "title5012": "Cung cấp",
  "title5013": "Đối với",
  "title5014": "thuyết trình vận hành sản phẩm",
  "title5015": "Cho bạn và đội của bạn nắm bắt và sử dụng nhanh chóng",
  "title5016": "Quét mã để nhận dịch vụ chuyên nghiệp",
  "title5017": "Vui lòng chọn khu vực bán hàng",
  "title5018": "Taiwan",
  "title5019": "Indonesia",
  "title5020": "Malaysia",
  "title5021": "Filipina",
  "title5022": "Singapura",
  "title5023": "Thailand",
  "title5024": "Vietnam",
  "title5025": "Bạn có chắc chắn muốn đăng xuất và thoát khỏi hệ thống không?",
  "title5026": "Gợi ý",
  "title5027": "Kịch bản",
  "title5028": "Bản sao mô tả tương ứng",
  "title5029": "Có bắt buộc",
  "title5030": "Giá trị mặc định",
  "title5031": "Mẫu",
  "title5032": "Số mẫu là",
  "title5033": "Các mục dữ liệu mẫu",
  "title5034": "Danh mục mẫu",
  "title5035": "Số danh mục mẫu là",
  "title5036": "Các mục dữ liệu danh mục mẫu",
  "title5037": "Ngày",
  "title5038": "Cái",
  "title5039": "Tổng cộng",
  "title5040": "Số lần phân tích nhận xét",
  "title5041": "Điểm",
  "title5042": "Thời gian hiệu lực",
  "title5043": "Vui lòng hoàn thành thanh toán trên trang bật lên mới, và nhấp vào nút dưới sau khi thanh toán hoàn thành.",
  "title5044": "Lời nhắc thân thiện",
  "title5045": "Thanh toán hoàn tất",
  "title5046": "Chúc mừng, gói đã mua thành công",
  "title5047": "Gói mua thất bại, thanh toán không thành công",
  "title5048": "Tìm kiếm người dùng",
  "title5049": "Để tiếp tục, làm theo các bước sau:",
  "title5050": "Tham số, không thể xem thông tin quy trình",
  "title5051": "Không thể để trống",
  "title5052": "Khách ghé thăm hữu cơ",
  "title5053": "Doanh số hữu cơ",
  "title5054": "Doanh số bán hàng hữu cơ",
  "title5055": "Mục dữ liệu",
  "title5056": "Sản phẩm",
  "title5057": "Sản phẩm",
  "title5058": "Giờ",
  "title5059": "Giá",
  "title5060": "Vui lòng nhập tên sản phẩm",
  "title5061": "Tên sản phẩm",
  "title5062": "Cao,",
  "title5063": "ngày không được để trống",
  "title5064": "Lợi nhuận trong số hàng hóa",
  "title5065": "Số lợi nhuận hàng hóa trong số ngày",
  "title5066": "Các mục dữ liệu lợi nhuận hàng hóa trong số ngày",
  "title5067": "Xóa không thể phục hồi, đồng thời cũng sẽ xóa hàng tồn kho và mối quan hệ ghép cặp tương ứng",
  "title5068": "Số là",
  "title5069": "Hộp: Không gian riêng tư độc quyền cho mèo, giúp chúng sống khỏe mạnh và hạnh phúc hơn!",
  "title5070": "Làm thế nào để sử dụng?",
  "title5071": "Thông tin hỗ trợ mối quan hệ phù hợp",
  "title5072": "Đồng bộ hóa sản phẩm trực tuyến",
  "title5073": "Cập nhật sản phẩm từ cửa hàng được ủy quyền đến địa phương.",
  "title5074": "Cập nhật vào cục bộ.",
  "title5075": "Tạo kết quả nhanh chóng",
  "title5076": "Loại bỏ quá trình tạo sản phẩm cục bộ thủ công.",
  "title5077": "Quá trình rườm rà.",
  "title5078": "Tạo sản phẩm",
  "title5079": "Sản phẩm được đồng bộ nhanh chóng sau khi đồng bộ",
  "title5080": "và tự động thiết lập mối quan hệ ghép đôi",
  "title5081": "Hợp nhất sản phẩm",
  "title5082": "Chọn các sản phẩm cần được hợp nhất",
  "title5083": "Hợp nhất thành một sản phẩm địa phương",
  "title5084": "Kết hợp tự động",
  "title5085": "Đạt được trực tuyến và ngoại tuyến thông qua quy tắc nhận dạng tự động",
  "title5086": "mối quan hệ ghép đôi tự động",
  "title5087": "Hủy ghép cặp",
  "title5088": "Hủy bỏ sản phẩm",
  "title5089": "với sản phẩm địa phương",
  "title5090": "mối quan hệ ghép đôi",
  "title5091": "Lời nhắc thân thiện:",
  "title5092": "Hiện chỉ hỗ trợ tạo ra đơn",
  "title5093": "Tôi đã hiểu",
  "title5094": "Sản phẩm không thể trống",
  "title5095": "Tạo vị trí",
  "title5096": "Thêm một vị trí",
  "title5097": "Thêm hàng loạt",
  "title5098": "Nhập & Tạo vị trí kệ",
  "title5099": "In nhãn kệ",
  "title5100": "Thông số kệ",
  "title5101": "Tối đa 100 cột",
  "title5102": "Số cột",
  "title5103": "Tối đa 100 tầng",
  "title5104": "Tầng",
  "title5105": "Dấu gạch nối",
  "title5106": "Xem trước",
  "title5107": "Phía dưới kệ",
  "title5108": "Vui lòng nhập số lượng",
  "title5109": "Vui lòng nhập số nguyên dương từ 1-100",
  "title5110": "Tất cả ",
  "title5111": "Trống",
  "title5112": "Đã cất giữ",
  "title5113": "Đã đầy",
  "title5114": "Có thể dọn dẹp",
  "title5115": "Vui lòng chọn mục",
  "title5116": "?",
  "title5117": "Thất bại",
  "title5118": "Doanh số trung bình hàng ngày",
  "title5119": "Số ngày vận chuyển biển",
  "title5120": "Ngày dự trữ bổ sung",
  "title5121": "Doanh thu bình quân hàng ngày trong 15 ngày gần đây",
  "title5122": "Chuyển phát trên đường",
  "title5123": "Quét nhập kho",
  "title5124": "In nhãn",
  "title5125": "Số ngày vận chuyển",
  "title5126": "Số lượng hết hàng",
  "title5127": "Lợi nhuận cửa hàng mỗi ngày",
  "title5128": "Số lợi nhuận cửa hàng hàng ngày là",
  "title5129": "Dữ liệu lợi nhuận cửa hàng hàng ngày",
  "title5130": "Quét hoạt động",
  "title5131": "Quét giao hàng",
  "title5132": "Số tiền hàng hóa",
  "title5135": "phiếu giao hàng",
  "title5136": "Phiếu xuất hàng",
  "title5137": "Tiểu bang",
  "title5138": "Quận",
  "title5139": "Số lượng thành công mới",
  "title5140": "Số lượng thất bại mới",
  "title5141": "错误日志数据项",
  "title5142": "访问日志数据项",
  "title5143": "匹配的",
  "title5144": "的对象内容",
  "title5145": "资源路径",
  "title5146": "重复的问题。",
  "title5147": "模板过于复杂，不利于胖友二次开发",
  "title5148": "折扣",
  "title5149": "随机",
  "title5150": "为",
  "title5151": "时需要添加",
  "title5152": "时可选择性添加",
  "title5153": "领取之日起或者次日",
  "title5154": "天内有效",
  "title5155": "优惠券仅原价购买商品时可用",
  "title5156": "满减",
  "title5157": "随机不能为空",
  "title5158": "有门槛不能为空",
  "title5159": "代表无限制不能为空",
  "title5160": "当",
  "title5161": "时需要添加不能为空",
  "title5162": "时可选择性添加不能为空",
  "title5163": "领取之日固定日期后过期",
  "title5164": "领取次日固定日期后过期不能为空",
  "title5165": "为2或者3时需要添加",
  "title5166": "天内有效不能为空",
  "title5167": "是不能为空",
  "title5168": "开启不能为空",
  "title5169": "天提醒不能为空",
  "title5170": "不限制",
  "title5171": "优惠券仅原价购买商品时可用不能为空",
  "title5172": "天！",
  "title5173": "开头",
  "title5174": "的账号不能为空",
  "title5175": "的模板编号不能为空",
  "title5176": "赋予专业身份",
  "title5177": "Ví dụ: Bạn là một chuyên gia tiếp thị hàng đầu",
  "title5178": "Định dạng cần thiết và nội dung đầu ra",
  "title5179": "Chi phí đánh giá",
  "title5180": "Thông tin hỗ trợ báo cáo hàng tháng lợi nhuận",
  "title5181": "Kiểm tra đơn hàng:",
  "title5182": "Kiểm tra xem đơn hàng có khớp không hoặc chi phí được duy trì, trong",
  "title5183": "Phân tích lợi nhuận - Chi tiết lợi nhuận đơn hàng",
  "title5184": "Bảo dưỡng các đơn hàng được lọc cho 【không có chi phí sản phẩm】",
  "title5185": "Đánh dấu đánh giá và giữ đơn hàng",
  "title5186": "Trong quản lý đơn hàng, đánh dấu đơn hàng cần đánh giá hoặc giữ tùy theo",
  "title5187": "Quản lý đơn hàng - Xử lý đơn hàng",
  "title5188": "Thiết lập tỷ giá thanh toán",
  "title5189": "Điều chỉnh theo cài đặt tỷ giá hối đoái mới nhất để đảm bảo tính toán chính xác của số tiền thanh toán",
  "title5190": "Phân tích lợi nhuận - Báo cáo lợi nhuận hàng tháng - Cài đặt tỷ giá thanh toán",
  "title5191": "Lỗi thiết lập tỷ giá đánh giá",
  "title5192": "Khi cài đặt tỷ giá thanh toán đánh giá không chính xác, trong",
  "title5193": "Cài đặt - Tham số đánh giá",
  "title5194": "Thực hiện thay đổi bên trong, sau khi hoàn tất sửa đổi, trong",
  "title5195": "Tìm đơn hàng tương ứng và chọn 【Thao tác hàng loạt - Làm mới chi phí mới nhất】",
  "title5196": "Khi bất kỳ nội dung nào ở trên được sửa đổi, bạn cần nhấn vào nút 【Cập nhật Lợi nhuận】để cập nhật dữ liệu lợi nhuận.",
  "title5197": "Hệ thống sẽ tự động cập nhật dữ liệu vào ngày 1 hàng tháng. Nếu bạn cần tính toán trước, hãy nhấp vào nút 【Cập nhật Lợi nhuận】để cập nhật dữ liệu.",
  "title5198": "Phí bưu kiện trống",
  "title5199": "Xác minh số điện thoại ban đầu",
  "title5200": "Xác minh",
  "title5201": "Số điện thoại mới",
  "title5202": "Số điện thoại",
  "title5203": "Mã xác minh sai, vui lòng nhập lại",
  "title5204": "Tham số, không thể xem",
  "title5205": "Thông tin nghỉ phép",
  "title5206": "Ngày",
  "title5207": "tập tin, lần này đã chọn",
  "title5208": "tập tin, tổng cộng đã chọn",
  "title5209": "tập tin",
  "title5210": "Cũng xóa các nhóm con của nó và điều chỉnh nhóm dữ liệu áp dụng thành chưa được nhóm",
  "title5211": "Đơn hàng lỗi",
  "title5212": "Kho nhận sẽ tự động chọn kho mặc định, không phải là kho xuất hàng của một trong các đơn hàng. Quét một gói hai lần có thể thực hiện hoạt động nhập kho. Giới hạn tối đa cho quét một lần là xử lý 500 gói trả lại.",
  "title5213": "Có nhập kho không?",
  "title5215": "Tất cả không nhập kho",
  "title5216": "Kho nhận hàng",
  "title5217": "Quét hoặc nhập số đơn hàng",
  "title5218": "Mã vận đơn",
  "title5219": "Nhập số vận đơn hàng loạt",
  "title5220": "Kết quả quét",
  "title5221": "Vui lòng nhập số đơn hàng trả lại",
  "title6000": "Vui lòng nhập số vận đơn hàng trả lại",
  "title6001": "Quét hoặc nhập số đơn hàng trả lại",
  "title6002": "Mã vận đơn trả hàng",
  "title6003": "Vui lòng quét hoặc nhập số đơn hàng trả lại trước",
  "title5222": "Không có dữ liệu quét",
  "title5223": "Nền tảng",
  "title5224": "ID hàng trả:",
  "title5225": "Mã vận đơn trả hàng",
  "title5226": "Sản phẩm",
  "title5228": "Vị trí nhận",
  "title5230": "Thao tác",
  "title5231": "Xóa",
  "title5232": "Lời nhắc thân thiện: Khi đã xử lý vào kho, đơn hàng trả không thể hủy bỏ cho kho",
  "title5233": "Vui lòng vận hành cẩn thận",
  "title5234": "Đóng",
  "title5235": "Đơn hàng này đã có trong danh sách quét",
  "title5236": "Lưu trữ thành công",
  "title5237": "Sau khi quét để giao hàng, gói sẽ tự động chuyển đến danh sách 'Đang chờ lấy'",
  "title5238": "Xác nhận giao hàng",
  "title5240": "Quét thành công",
  "title5241": "Xóa và tiếp tục quét",
  "title5242": "Đang quét",
  "title5243": "Mã vận đơn",
  "title5244": "Phương thức vận chuyển:",
  "title5245": "Vui lòng kết nối máy quét để quét gói",
  "title5246": "Thông tin",
  "title5247": "Số lượng",
  "title5248": "Lịch sử quét",
  "title5249": "Đã quét：",
  "title5250": "Gửi hàng thành công",
  "title5251": "Số đơn hàng",
  "title5252": "Tổng",
  "title5253": "Trạng thái vận chuyển",
  "title5254": "Gửi hàng thành công",
  "title5255": "Quét một lần chỉ ra vận chuyển một lần, quét hai lần chỉ ra hiển thị thông tin đơn hàng lần đầu tiên và quét hai lần chỉ ra vận chuyển.",
  "title5256": "Số lần quét",
  "title5257": "Quét một lần",
  "title5258": "Quét hai lần",
  "title5259": "Lỗi quét, vui lòng nhấp vào ô nhập bằng chuột để làm nó màu xanh",
  "title5260": "Lời nhắc hệ thống",
  "title5261": "Xác nhận xóa tất cả bản ghi quét",
  "title5262": "Chi tiết số tiền",
  "title5263": "Tiền tệ hiện tại",
  "title5264": "Chuyển đổi",
  "title5265": "Tỷ giá hối đoái hiện tại",
  "title5266": "Người mua đã thanh toán ",
  "title5267": "Tổng cộng sau giảm giá",
  "title5268": "Tổng cộng trước giảm giá",
  "title5269": "Giảm giá hàng của người bán",
  "title5270": "Giảm giá sản phẩm",
  "title5271": "Phí vận chuyển sau giảm giá",
  "title5272": "Phí vận chuyển trước giảm giá",
  "title5273": "Giảm giá phí vận chuyển của người bán",
  "title5274": "Giảm giá phí vận chuyển",
  "title5275": "Thuế",
  "title5276": "Jumlah Pembayaran Platform",
  "title5278": "Tiểu cả sau giảm giá của người bán",
  "title5279": "Tổng phần hoàn lại sau giảm giá của người bán",
  "title5280": "Tổng chi phí",
  "title5281": "Phí thanh toán",
  "title5282": "Phí hoa hồng",
  "title5283": "Phí vận chuyển người bán",
  "title5284": "Phí vận chuyển của người dùng",
  "title5285": "Phí vận chuyển thực tế",
  "title5286": "Phụ cấp vận chuyển",
  "title5287": "Phí vận chuyển trả hàng thực tế",
  "title5288": "Phí vận chuyển hoàn lại cho khách hàng",
  "title5289": "Hoa hồng người ảnh hưởng",
  "title5290": "Phí dịch vụ",
  "title5292": "Số tiền thanh toán ước tính",
  "title5293": "Số tiền nhờ thu",
  "title5296": "Chi phí đóng gói",
  "title5297": "Chi phí vận chuyển ước tính",
  "title5298": "Phí xử lý ước tính",
  "title5301": "Lời / Lỗ",
  "title5302": "Tỷ suất lợi nhuận",
  "title5303": "Phí vận chuyển",
  "title5304": "Chi phí gốc vận chuyển",
  "title5305": "Phiếu giảm giá",
  "title5306": "Phiếu giảm giá người bán",
  "title5307": "Số tiền sản phẩm",
  "title5308": "Chi phí logistics xuyên biên giới (thực tế)",
  "title5311": "Hoàn lại phí vận chuyển Shopee",
  "title5312": "Số tiền phiếu giảm giá của người bán",
  "title5313": "Phí và Chi phí",
  "title5314": "Chi phí hoa hồng tiếp thị liên kết",
  "title5315": "Phí vận chuyển ban đầu",
  "title5316": "Chiết khấu vận chuyển của nền tảng",
  "title5317": "Giảm giá của người bán",
  "title5318": "Phí điều chỉnh",
  "title5319": "Thông tin Tài chính",
  "title5320": "Số kiện hàng",
  "title5321": "Chứng chỉ",
  "title5322": "Giá trị khóa",
  "title5323": "Thành công",
  "title5324": "Địa chỉ không thể trống",
  "title5325": "Khách hàng",
  "title5326": "Đến",
  "title5327": "ký tự",
  "title5328": "Ví dụ",
  "title5329": "tệp có hậu tố",
  "title5330": "Điều chỉnh qua tháng",
  "title5332": "Số ngày tối đa của nhóm không được rỗng",
  "title5333": "Gần đây",
  "title5334": "Tổng doanh số trong những ngày gần đây",
  "title5335": "Vui lòng nhập nội dung đặt hàng",
  "title10000": "Trang chủ",
  "title10001": "Giờ, Ký Tự Đại Diện Được Phép",
  "title10002": "Chu Kỳ Bắt Đầu Từ",
  "title10003": "Giờ",
  "title10004": "Từ",
  "title10005": "Bắt đầu từ Giờ, Mỗi",
  "title10006": "Thực Hiện Mỗi Giờ Một Lần",
  "title10007": "Chỉ định",
  "title10008": "Cho phép chọn nhiều",
  "title10009": "Phút, Ký Tự Đại Diện Được Phép",
  "title10010": "Phút",
  "title10011": "Bắt đầu từ Phút, Mỗi",
  "title10012": "Thực Hiện Mỗi Phút Một Lần",
  "title10013": "Giây",
  "title10014": "Ngày",
  "title10015": "Tháng",
  "title10016": "Tuần",
  "title10017": "Năm",
  "title10018": "Biểu thức thời gian",
  "title10019": "Biểu thức",
  "title10021": "Đặt lại",
  "title10022": "Đóng",
  "title10023": "Từ thành phần",
  "title10024": "Đã thay đổi",
  "title10025": "Sẵn sàng phục hồi",
  "title10026": "Một",
  "title10027": "Hai",
  "title10028": "Ba",
  "title10029": "Bốn",
  "title10030": "Năm",
  "title10031": "Sáu",
  "title10032": "5 Lần Chạy Gần Đây",
  "title10033": "Trong Kết Quả Tính Toán",
  "title10034": "Không Có Kết Quả Đáp Ứng Điều Kiện!",
  "title10035": "Chỉ trên trong 100 năm gần đây",
  "title10036": "Kết Quả!",
  "title10037": "Ngày, Ký Tự Đại Diện Được Phép",
  "title10038": "Không Xác Định",
  "title10039": "Bắt đầu từ, Mỗi",
  "title10040": "Thực Hiện Mỗi Ngày Một Lần",
  "title10041": "Mỗi Tháng",
  "title10042": "Ngày Làm Việc Gần Nhất Của Tháng",
  "title10043": "Ngày Cuối Cùng của Tháng Này",
  "title10044": "Giây, Ký Tự Đại Diện Được Phép",
  "title10045": "Bắt đầu từ Giây, Mỗi",
  "title10046": "Thực Hiện Mỗi Giây Một Lần",
  "title10047": "Tuần, Ký Tự Đại Diện Được Phép",
  "title10048": "Chu Kỳ Bắt Đầu Từ Tuần",
  "title10049": "Thứ",
  "title10050": "Ngày của Tuần",
  "title10051": "Ngày Cuối Cùng Của Tháng",
  "title10052": "Thứ Hai",
  "title10053": "Thứ Ba",
  "title10054": "Thứ Tư",
  "title10055": "Thứ Năm",
  "title10056": "Thứ Sáu",
  "title10057": "Thứ Bảy",
  "title10058": "Chủ Nhật",
  "title10059": "Không điền, ký tự đại diện được phép",
  "title10060": "Mỗi năm",
  "title10061": "Bắt đầu từ năm, mỗi",
  "title10062": "Năm thực hiện một lần",
  "title10063": "Tháng, ký tự đại diện được phép",
  "title10064": "Bắt đầu từ tháng, mỗi",
  "title10065": "Thực hiện hàng tháng một lần",
  "title10066": "Địa chỉ tài liệu",
  "title10067": "Vui lòng nhập nội dung",
  "title10068": "Kích thước tệp tải lên không được vượt quá",
  "title10069": "Không thể chèn hình ảnh",
  "title10070": "Vui lòng nhập địa chỉ liên kết",
  "title10071": "Lưu",
  "title10072": "Vui lòng nhập địa chỉ video",
  "title10073": "Văn bản",
  "title10074": "Tiêu đề 1",
  "title10075": "Tiêu đề 2",
  "title10076": "Tiêu đề 3",
  "title10077": "Tiêu đề 4",
  "title10078": "Tiêu đề 5",
  "title10079": "Tiêu đề 6",
  "title10080": "Phông chữ tiêu chuẩn",
  "title10081": "Phông chữ serif",
  "title10082": "Phông chữ cố định",
  "title10083": "Văn bản một dòng",
  "title10084": "Số điện thoại không đúng định dạng",
  "title10085": "Vui lòng nhập số điện thoại",
  "title10086": "Chọn tập tin",
  "title10087": "Vui lòng tải lên",
  "title10088": "Kích thước không vượt quá",
  "title10089": "Định dạng là",
  "title10090": "của tập tin",
  "title256": "Xóa",
  "title10092": "Định dạng tập tin không đúng",
  "title10093": "Định dạng tập tin",
  "title10094": "Đang tải lên tập tin, vui lòng đợi",
  "title10095": "Số lượng tập tin tải lên không được vượt quá",
  "title10096": "Cái",
  "title10097": "Không thể tải lên hình ảnh, vui lòng thử lại",
  "title10098": "Vui lòng nhập",
  "title10099": "Văn bản nhiều dòng",
  "title10100": "Mật khẩu",
  "title10101": "Bộ đếm",
  "title10102": "Bộ chỉnh sửa",
  "title10103": "Lựa chọn thả xuống",
  "title10104": "Lựa chọn một",
  "title10105": "Lựa chọn hai",
  "title10106": "Vui lòng chọn",
  "title10107": "Lựa chọn phân cấp",
  "title10108": "Lựa chọn 1",
  "title10109": "Lựa chọn 1-1",
  "title10110": "Nhóm nút radio",
  "title10111": "Nhóm hộp kiểm",
  "title10112": "Công tắc",
  "title10113": "Thanh trượt",
  "title10114": "Chọn thời gian",
  "title10115": "Khoảng thời gian",
  "title10116": "Đến",
  "title10117": "Thời gian bắt đầu",
  "title10118": "Thời gian kết thúc",
  "title10119": "Lựa chọn ngày",
  "title10120": "Phạm vi ngày",
  "title10121": "Ngày bắt đầu",
  "title10122": "Ngày kết thúc",
  "title10123": "Đánh giá",
  "title10124": "Lựa chọn màu",
  "title10125": "Tải lên",
  "title10126": "Nhấp để tải lên",
  "title10127": "Dung lượng hàng",
  "title10128": "Nút",
  "title10129": "Nút chính",
  "title10130": "Bảng",
  "title10131": "Đang phát triển",
  "title10133": "Địa chỉ",
  "title10135": "Hoạt động",
  "title10136": "Vui lòng nhập tên biểu tượng",
  "title10137": "Vui lòng chọn ít nhất một",
  "title10138": "Không được để trống",
  "title10139": "Kích thước tệp vượt quá",
  "title10140": "Nên chọn",
  "title10141": "Loại tệp",
  "title10142": " Tìm kiếm ",
  "title10143": "Gửi đi",
  "title10144": "Chỉ có thể tải lên tối đa",
  "title10145": "của",
  "title10146": "tập tin",
  "title10147": "Xem trước",
  "title10148": "Tệp định dạng hình ảnh",
  "title10149": "Kích thước ảnh đại diện tải lên không vượt quá",
  "title10150": "Đang tải ảnh, vui lòng đợi",
  "title10151": "Hôm nay",
  "title10152": "Ngày hôm qua",
  "title10153": "7 ngày",
  "title10154": "14 ngày qua",
  "title10155": "30 ngày qua",
  "title10156": "Tháng này",
  "title10157": "Tháng trước",
  "title10158": "6 tháng qua",
  "title10159": "Năm ngoái",
  "title10160": "Trạng thái",
  "title10161": "Đang xử lý, vui lòng không đóng cửa sổ hiện tại",
  "title10162": "Đã hoàn thành",
  "title10163": "Đang xử lý dữ liệu",
  "title10164": "Xuất",
  "title10165": "Không đóng cửa sổ hiện tại khi xuất dữ liệu, bạn có thể tải xuống báo cáo sau khi hoàn tất việc xuất",
  "title10166": "Tải về",
  "title10167": "Đóng",
  "title10168": "Sẵn sàng",
  "title10169": "Đang xuất",
  "title10170": "Xử lý hoàn tất, có thể tải về",
  "title10171": "Tiêu đề",
  "title10172": "Đồng bộ",
  "title10173": "Không đóng cửa sổ hiện tại trong khi đồng bộ dữ liệu. Bạn có thể đóng cửa sổ sau khi đồng bộ hoàn tất.",
  "title10174": "Sử dụng sau khi nâng cấp thành người dùng trả phí.",
  "title10175": "Hẹn lịch đào tạo",
  "title10176": "Xem trợ giúp",
  "title10177": "Nâng cấp ngay bây giờ",
  "title10178": "Không khớp với:",
  "title10179": "Phù hợp với:",
  "title10180": "Trình duyệt của bạn không hỗ trợ toàn màn hình",
  "title10181": "Ẩn Tìm kiếm",
  "title10182": "Hiển thị Tìm kiếm",
  "title10183": "Làm mới",
  "title10184": "Hiển thị Cột",
  "title10185": "Hiển thị",
  "title10186": "Ẩn",
  "title10187": "Gộp và Tiếp tục",
  "title10188": "Thêm",
  "title10189": "Hướng dẫn Khởi tạo",
  "title10190": "Quản lý cửa hàng dễ dàng, cải thiện hiệu suất và giảm chi phí kinh doanh.",
  "title10191": "Hướng dẫn Vận hành",
  "title10192": "Ủy quyền Ba Kho",
  "title10194": "Vui lòng nhập tên khác của cửa hàng",
  "title10195": " Kho",
  "title10196": "Chọn kho",
  "title10197": "Khi bạn đăng ký tài khoản Xinzhan, hệ thống sẽ tự động tạo ra một kho cho bạn và đặt nó làm kho mặc định. Nếu bạn cần sửa đổi, vui lòng đi đến [Kho-Quản lý Kho-Danh sách Kho] để thiết lập.",
  "title10198": "Phân quyền cửa hàng",
  "title10199": "Thêm sản phẩm",
  "title10200": "Bảo trì thông tin sản phẩm cục bộ trong hệ thống cho việc thống kê lợi nhuận, chi phí",
  "title10201": "Ghép nối hàng hóa",
  "title10202": "Kết hợp sản phẩm trực tuyến, kết hợp sản phẩm cục bộ với các nền tảng trực tuyến",
  "title10203": "Kết hợp",
  "title10204": "Cặp sản phẩm trực tuyến",
  "title10205": "Quản lý kho",
  "title10206": "Khởi tạo hàng tồn để mua, bổ sung, gửi hàng sau này và các hoạt động khác",
  "title10207": "Thêm kho",
  "title10208": "Quản lý nhân sự",
  "title10209": "Cấu hình quyền tương ứng cho các vai trò như vận hành, mua hàng, tài chính và giám đốc tại công ty của bạn",
  "title10210": "Thiết lập tham số",
  "title10211": "Cài đặt hiển thị tiền tệ cho hệ thống của bạn theo tỷ giá hối đoái quốc gia, đánh giá cài đặt tỷ giá đơn hàng",
  "title10212": "Cài đặt tỷ giá",
  "title10213": "Không nhắc nhở",
  "title10214": "Bật hướng dẫn",
  "title10215": "Quay lại",
  "title10216": "Bước tiếp theo",
  "title10217": "Tên gian hàng không được để trống",
  "title10218": "Kho không được để trống",
  "title10219": "Hướng dẫn cho người mới",
  "title10220": "Chào mừng",
  "title10221": "Ủy quyền cửa hàng",
  "title10222": "Thêm ủy quyền cửa hàng",
  "title10223": "Ủy quyền cửa hàng của bạn cho hệ thống để đồng bộ hóa đơn cửa hàng, sản phẩm trực tuyến, vv.",
  "title10224": "Cửa hàng có thể được ủy quyền thông qua [Hệ thống-Quản lý Ủy quyền-Quản lý Cửa hàng]. Thêm cửa hàng mới được ủy quyền.",
  "title10225": "Trang ủy quyền bên thứ ba là trang chính thức của cửa hàng. Tài khoản và mật khẩu của người bán chỉ được sử dụng cho việc ủy quyền cửa hàng, Xinzhan sẽ không lưu mật khẩu của bạn.",
  "title10226": "Sản phẩm",
  "title10227": "Có thể thông qua [Hàng tồn kho-Sản phẩm",
  "title10228": "Sản phẩm",
  "title10229": "Tạo, thêm vào bằng tay, nhập bảng tính để tạo sản phẩm",
  "title10230": "Có thể tạo đơn lẻ",
  "title10231": "Sản phẩm và kết hợp",
  "title10232": "Sản phẩm, cần phải điền đúng định dạng khi tạo sản phẩm",
  "title10233": "Với trực tuyến",
  "title10234": "Kết hợp, được sử dụng để tự động trừ tồn kho trong hệ thống, và thống kê dữ liệu liên quan",
  "title10235": "Cặp sản phẩm có thể được thực hiện qua [Hàng tồn kho-Sản phẩm",
  "title10236": "Thiết lập cặp sản phẩm trực tuyến",
  "title10237": "Kho Riêng: Khi đăng ký tài khoản Xinzhan, hệ thống sẽ tự động tạo một kho cho bạn và đặt nó làm kho mặc định. Nếu cần chỉnh sửa, vui lòng vào [Tồn kho-Quản lý Kho-Danh sách Kho] để thiết lập.",
  "title10238": "Kho Bên Thứ Ba: Ủy quyền cho các kho bên thứ ba. Trong [Tồn kho-Quản lý Kho-Danh sách Kho], tạo một kho mới và chọn kho bên thứ ba đã được ủy quyền trong quá trình tạo.",
  "title10239": "Sau khi ủy quyền cho các kho bên thứ ba, đơn hàng có thể được đẩy vào các kho bên thứ ba và hỗ trợ đồng bộ hóa tồn kho sản phẩm từ các kho bên thứ ba. Đơn đặt hàng cũng hỗ trợ đẩy tự động cho việc xử lý nhập kho.",
  "title10240": "Cài đặt Nhân viên",
  "title10241": "Quản lý Nhân viên có thể được thiết lập thông qua [Hệ thống-Quản lý Tài khoản-Quản lý Tài khoản Con]. Thiết lập tài khoản con có thể phân bổ các quyền vai trò khác nhau, chỉ định quyền cửa hàng, quyền kho, v.v.",
  "title10242": "Cài đặt vai trò có thể tạo thông qua [Hệ thống-Quản lý Tài khoản-Quản lý Vai trò].",
  "title10243": "Cài đặt tỷ giá có thể được điều chỉnh thông qua [Hệ thống-Cài đặt-Cài đặt Tỷ giá]. Hệ thống mặc định lấy tỷ giá hối đoái thời gian thực và bạn có thể tùy chỉnh tỷ giá. Sau khi thiết lập tỷ giá, đơn hàng sẽ hiển thị giá theo cài đặt mới nhất.",
  "title10244": "Các tham số đánh giá có thể được thiết lập thông qua [Hệ thống-Cài đặt-Tham số Đánh giá]. Sau khi thiết lập, chi phí đơn hàng đánh giá sẽ được tính theo các tham số đã thiết lập.",
  "title10245": "Không có quyền",
  "title10246": "Vui lòng thêm sản phẩm trước",
  "title10247": "Vui lòng hoàn thành việc ủy quyền cửa hàng trước",
  "title10248": "Vui lòng hoàn tất xác minh bảo mật",
  "title10249": "Kéo thanh trượt bên dưới để hoàn tất câu đố",
  "title10250": "中文",
  "title10251": "Thành viên hiện tại",
  "title10252": "Mua",
  "title10253": "Thông tin cơ bản",
  "title10254": "Gói của tôi",
  "title10255": "Đơn hàng của tôi",
  "title10256": "Thoát khỏi vai trò chơi",
  "title10257": "Đăng xuất",
  "title10258": "林珍",
  "title10259": "Vui lòng nhập mật khẩu đăng nhập",
  "title10260": "Đăng nhập bằng tài khoản khác",
  "title10261": "Bản quyền thuộc về",
  "title10262": "Min",
  "title10263": "Số dự phòng 2023007214-2",
  "title10264": "Bạn có chắc muốn đăng xuất và thoát khỏi hệ thống không?",
  "title10265": "Bạn có chắc chắn muốn thoát khỏi vai trò chơi không?",
  "title10266": "Gợi ý",
  "title10267": "消息启动事件",
  "title10268": "定时启动事件",
  "title10269": "条件启动事件",
  "title10270": "信号启动事件",
  "title10271": "错误启动事件",
  "title10272": "升级启动事件",
  "title10273": "补偿启动事件",
  "title10274": "非中断",
  "title10275": "测试按钮1",
  "title10276": "测试按钮1】点击事件里可以访问当前表单：",
  "title10277": "所以",
  "title10278": "可以拿到当前表单的",
  "title10279": "组件",
  "title10280": "表单的",
  "title10281": "提交数据：",
  "title10282": "在这里输入文字",
  "title10283": "配置文档参阅：",
  "title10284": "验证成功",
  "title10285": "验证失败",
  "title10286": "请依次点击【",
  "title10287": "向右滑动完成验证",
  "title10288": "主题风格设置",
  "title10289": "图标",
  "title10290": "主题颜色",
  "title10291": "系统布局配置",
  "title10292": "开启",
  "title10293": "固定",
  "title10294": "动态标题",
  "title10295": "保存配置",
  "title10296": "重置配置",
  "title10297": "正在保存到本地，请稍候",
  "title10298": "正在清除设置缓存并刷新，请稍候",
  "title10299": "xinjian",
  "title10300": "刷新页面",
  "title10301": "关闭当前",
  "title10302": "关闭其他",
  "title10303": "关闭左侧",
  "title10304": "关闭右侧",
  "title10305": "全部关闭",
  "title10306": "测试任务",
  "title10307": "打开文件",
  "title10308": "下载为",
  "title10309": "下载文件",
  "title10310": "退出模拟",
  "title10311": "开启模拟",
  "title10312": "模拟",
  "title10313": "向左对齐",
  "title10314": "向右对齐",
  "title10315": "向上对齐",
  "title10316": "向下对齐",
  "title10317": "水平居中",
  "title10318": "垂直居中",
  "title10319": "缩小视图",
  "title10320": "放大视图",
  "title10321": "重置视图并居中",
  "title10322": "撤销",
  "title10323": "恢复",
  "title10324": "重新绘制",
  "title10325": "保存模型",
  "title10326": "业务流程",
  "title10327": "请按住",
  "title10328": "键选择多个元素对齐",
  "title10329": "自动对齐可能造成图形变形，是否继续？",
  "title10330": "警告",
  "title10331": "保存模型失败，请重试！",
  "title10332": "发起人：",
  "title10333": "部门：",
  "title10334": "创建时间：",
  "title10335": "审批人：",
  "title10336": "结果：",
  "title10337": "结束时间：",
  "title10338": "审批建议：",
  "title10339": "常规",
  "title10340": "消息与信号",
  "title10341": "流转条件",
  "title10342": "表单",
  "title10343": "友情提示：使用",
  "title10344": "流程表单",
  "title10345": "替代，提供更好的表单设计功能",
  "title10346": "任务",
  "title10347": "多实例",
  "title10348": "执行监听器",
  "title10349": "任务监听器",
  "title10350": "扩展属性",
  "title10351": "Khác",
  "title10352": "Cấp danh tính chuyên nghiệp",
  "title10353": "Ví dụ: Bạn là một chuyên gia tiếp thị hàng đầu",
  "title10354": "Cần định dạng như thế nào và nội dung đầu ra",
  "title10355": "Làm thế nào để sử dụng?",
  "title10356": "Chi phí đánh giá",
  "title10357": "Thông tin trợ giúp báo cáo lợi nhuận hàng tháng",
  "title10358": "Kiểm tra đơn hàng:",
  "title10359": "Kiểm tra xem đơn hàng đã được ghép cặp hay chi phí được duy trì, trong",
  "title10361": "Bảo trì đơn hàng với bộ lọc 【Không có chi phí sản phẩm】",
  "title10362": "Đánh dấu đánh giá và giữ đơn hàng:",
  "title10363": "Trong quản lý đơn hàng, đánh dấu đơn hàng cần đánh giá hoặc giữ theo cách tương ứng",
  "title10364": "Quản lý đơn hàng - Xử lý đơn hàng",
  "title10365": "Đặt tỷ giá hoàn trả:",
  "title10366": "Điều chỉnh dựa trên cài đặt tỷ giá hối đoái mới nhất để đảm bảo tính toán chính xác của số tiền hoàn trả",
  "title10367": "Phân tích Lợi Nhuận - Báo cáo Lợi Nhuận Hằng Tháng - Cài Đặt Tỷ Giá Hoàn Trả",
  "title10368": "Lỗi cài đặt tỷ giá đánh giá",
  "title10369": "Khi cài đặt tỷ giá thanh toán đánh giá không chính xác, trong",
  "title10370": "Cài đặt - Tham số đánh giá",
  "title10371": "Thực hiện thay đổi bên trong, sau khi sửa đổi, trong",
  "title10372": "Tìm đơn hàng tương ứng và chọn 【Thao tác Dàn trang - Làm mới Chi phí Mới nhất】.",
  "title10373": "Lời nhắc thân thiện:",
  "title10374": "Khi bất kỳ nội dung nào ở trên được sửa đổi, bạn cần nhấn vào nút 【Cập nhật Lợi nhuận】để cập nhật dữ liệu lợi nhuận.",
  "title10375": "Hệ thống sẽ tự động cập nhật dữ liệu vào ngày 1 của mỗi tháng. Nếu bạn cần tính toán trước, hãy nhấp vào nút 【Cập nhật Lợi nhuận】để cập nhật dữ liệu.",
  "title10376": "Không hiển thị lại",
  "title10377": "Tôi biết rồi",
  "title10378": "Chữ cái phải bao gồm chữ in hoa",
  "title10379": "Chữ cái, số và ký tự phải bao gồm ít nhất 2 loại",
  "title10380": "ngày",
  "title10381": "tập tin đã chọn lần này:",
  "title10382": "tập tin đã chọn tổng cộng:",
  "title10383": "tập tin",
  "title10384": "Cũng xóa các nhóm con của nó và điều chỉnh nhóm dữ liệu được áp dụng thành không nhóm.",
  "title10385": "Đơn hàng không chính xác",
  "title10386": "Quét nhập kho",
  "title10387": "Kho nhận sẽ tự động chọn kho mặc định, không phải là kho xuất hàng từ một trong các đơn hàng. Quét một gói một lần có thể thực hiện thao tác nhập kho. Giới hạn tối đa cho quét một lần là xử lý 500 gói trả lại.",
  "title10388": "Nó có trong kho không?",
  "title10390": "Không có gì được lưu trữ",
  "title10391": "Kho nhận hàng",
  "title10392": "Quét hoặc nhập số đơn hàng",
  "title10393": "Mã vận đơn",
  "title10394": "Nhập số vận đơn hàng loạt",
  "title10395": "Kết quả quét",
  "title10396": "Không có dữ liệu quét có sẵn",
  "title10397": "Vui lòng kết nối máy quét để quét gói",
  "title10398": "Nền tảng",
  "title10399": "ID hàng trả:",
  "title10400": "Mã vận đơn trả hàng",
  "title10401": "Sản phẩm",
  "title10403": "Vị trí nhận",
  "title10405": "Loại bỏ",
  "title10407": "Quét một lần có nghĩa là quét một lần để giao hàng, quét hai lần có nghĩa là hiển thị thông tin đơn hàng lần đầu tiên và quét để giao hàng lần thứ hai.",
  "title10408": "Số lần quét",
  "title10409": "Chi tiết số tiền",
  "title10410": "Đơn vị tiền tệ hiện tại",
  "title10411": "Chuyển đổi",
  "title10412": "Tỷ giá hiện tại",
  "title10413": "Người mua đã thanh toán ",
  "title10414": "Tổng số tiền sau giảm giá",
  "title10415": "Tổng số tiền trước giảm giá",
  "title10416": "Giảm giá sản phẩm của người bán",
  "title10417": "Giảm giá sản phẩm",
  "title10418": "Phí vận chuyển sau giảm giá",
  "title10419": "Phí vận chuyển trước giảm giá",
  "title10420": "Giảm giá phí vận chuyển của người bán",
  "title10421": "Giảm giá phí vận chuyển",
  "title10422": "Thuế",
  "title10423": "Jumlah Pembayaran Platform",
  "title10425": "Tổng phụ sau khi giảm giá của người bán",
  "title10426": "Tổng phụ sau hoàn trả giảm giá của người bán",
  "title10427": "Tổng chi phí",
  "title10428": "Phí thanh toán",
  "title10429": "Phí hoa hồng",
  "title10430": "Phí vận chuyển người bán",
  "title10431": "Phí vận chuyển người mua",
  "title10432": "Phí vận chuyển thực tế",
  "title10433": "Tiền trợ cấp vận chuyển",
  "title10434": "Phí vận chuyển trả hàng thực tế",
  "title10435": "Phí vận chuyển hoàn tiền của khách hàng",
  "title10436": "Hoa hồng người ảnh hưởng",
  "title10437": "Phí dịch vụ",
  "title10438": "Số tiền hoàn trả ước tính",
  "title10439": "Số tiền nhờ thu",
  "title10441": "Phí đóng gói",
  "title10442": "Chi phí vận chuyển ước tính",
  "title10443": "Phí xử lý ước tính",
  "title10444": "Đi tới Cài đặt",
  "title10446": "Lời / Lỗ",
  "title10447": "Tỷ suất lợi nhuận",
  "title10448": "Số tiền sản phẩm",
  "title10449": "Phí vận chuyển",
  "title10450": "Chi phí gửi ban đầu",
  "title10451": "Phiếu giảm giá",
  "title10452": "Phiếu giảm giá của người bán",
  "title10453": "Số tiền sản phẩm",
  "title10454": "Chi phí vận chuyển qua biên giới (thực tế)",
  "title10457": "Hoàn trả phí vận chuyển Shopee",
  "title10458": "Số tiền giảm giá của người bán",
  "title10459": "Chi phí và lệ phí",
  "title10460": "Chi phí hoa hồng tiếp thị liên kết",
  "title10461": "Chi phí gửi ban đầu",
  "title10462": "Chiết khấu vận chuyển của nền tảng",
  "title10463": "Giảm giá",
  "title10464": "Giảm giá của người bán",
  "title10465": "Phí điều chỉnh",
  "title10466": "Thông tin tài chính",
  "title10467": "Quét giao hàng",
  "title10468": "Sau khi quét để giao hàng, bưu kiện sẽ tự động chuyển đến danh sách [Để thu thập]",
  "title10469": "Xác nhận giao hàng",
  "title10470": "Quét thành công",
  "title10471": "Xóa và tiếp tục quét",
  "title10472": "Đang quét",
  "title10473": "Mã vận đơn",
  "title10474": "Phương thức vận chuyển:",
  "title10475": "Thông tin",
  "title10476": "Số lượng",
  "title10477": "Lịch sử quét",
  "title10478": "Đã quét",
  "title10479": "Đã gửi thành công:",
  "title10480": "Số đơn hàng",
  "title10481": "Tổng",
  "title10482": "Trạng thái giao hàng",
  "title10483": "Giao hàng thành công",
  "title10484": "Quét một lần",
  "title10485": "Quét hai lần",
  "title10486": "Lỗi quét, vui lòng nhấp vào ô nhập với chuột trước, ô nhập sẽ chuyển màu xanh",
  "title10487": "Lời nhắc hệ thống",
  "title10488": "Bạn có chắc chắn muốn xóa tất cả các bản ghi quét không?",
  "title10489": "Thành công",
  "title10490": "Chứng chỉ",
  "title10491": "Giá trị khóa",
  "title10492": "Số gói hàng",
  "title10493": "如何实现实现会签、或签？",
  "title10494": "流程标识",
  "title10495": "请输入流标标识",
  "title10496": "流程名称",
  "title10497": "请输入流程名称",
  "title10498": "流程标识不能为空",
  "title10499": "流程名称不能为空",
  "title10500": "不满足",
  "title10501": "规则，所以不进行赋值",
  "title10502": "满足",
  "title10503": "规则，所以进行赋值",
  "title10504": "表单标识",
  "title10505": "业务标识",
  "title10506": "无",
  "title10507": "表单字段",
  "title10508": "序号",
  "title10509": "字段名称",
  "title10510": "字段类型",
  "title10511": "默认值",
  "title1593": "编辑",
  "title10513": "添加字段",
  "title10514": "字段配置",
  "title10515": "字段",
  "title10516": "类型",
  "title10517": "请选择字段类型",
  "title10518": "类型名称",
  "title10519": "时间格式",
  "title10520": "枚举值列表：",
  "title10521": "添加枚举值",
  "title10522": "枚举值编号",
  "title10523": "枚举值名称",
  "title10524": "约束条件列表：",
  "title10525": "添加约束",
  "title10526": "约束名称",
  "title10527": "约束配置",
  "title10528": "字段属性列表：",
  "title10529": "添加属性",
  "title10530": "属性编号",
  "title10531": "属性值",
  "title10532": "取",
  "title10534": "保",
  "title10535": "存",
  "title10536": "编号",
  "title10537": "配置",
  "title10538": "值",
  "title10539": "确 定",
  "title10540": "",
  "title10541": "长整型",
  "title10542": "字符串",
  "title10543": "布尔类",
  "title10544": "日期类",
  "title10545": "枚举类",
  "title10546": "自定义类型",
  "title10547": "属性配置",
  "title10548": "枚举值配置",
  "title10549": "约束条件配置",
  "title10550": "流转类型",
  "title10551": "普通流转路径",
  "title10552": "默认流转路径",
  "title10553": "条件流转路径",
  "title10554": "条件格式",
  "title10555": "脚本",
  "title10556": "脚本语言",
  "title10557": "脚本类型",
  "title10558": "内联脚本",
  "title10559": "外部脚本",
  "title10560": "资源地址",
  "title10561": "事件类型",
  "title10562": "监听器类型",
  "title10563": "添加监听器",
  "title10564": "类",
  "title10565": "代理表达式",
  "title10566": "脚本格式",
  "title10567": "请填写脚本格式",
  "title10568": "请选择脚本类型",
  "title10569": "脚本内容",
  "title10570": "请填写脚本内容",
  "title10571": "请填写资源地址",
  "title10572": "定时器类型",
  "title10573": "持续时长",
  "title10574": "循环",
  "title10575": "定时器",
  "title10576": "注入字段：",
  "title10577": "字段值",
  "title10578": "字段名称：",
  "title10579": "字段类型：",
  "title10580": "字段值：",
  "title10581": "表达式：",
  "title10582": "回路特性",
  "title10583": "并行多重事件",
  "title10584": "时序多重事件",
  "title10585": "循环事件",
  "title10586": "循环基数",
  "title10587": "集合",
  "title10588": "元素变量",
  "title10589": "完成条件",
  "title10590": "异步状态",
  "title10591": "异步前",
  "title10592": "异步后",
  "title10593": "排除",
  "title10594": "重试周期",
  "title10595": "确认移除该字段吗？",
  "title10596": "认",
  "title10597": "操作取消",
  "title10598": "确认移除该监听器吗？",
  "title10599": "事件",
  "title10600": "监听器",
  "title10601": "请填写定时器配置",
  "title10602": "元素文档：",
  "title10603": "创建",
  "title10604": "指派",
  "title10605": "完成",
  "title10606": "更新",
  "title10607": "超时",
  "title10608": "属性名",
  "title10609": "属性名：",
  "title10610": "属性值：",
  "title10611": "确认移除该属性吗？",
  "title10612": "消息列表",
  "title10613": "创建新消息",
  "title10614": "消息",
  "title10615": "消息名称",
  "title10616": "信号列表",
  "title10617": "创建新信号",
  "title10618": "信号",
  "title10619": "信号名称",
  "title10620": "创建消息",
  "title10621": "创建信号",
  "title10622": "该消息已存在，请修改",
  "title10623": "后重新保存",
  "title10624": "该信号已存在，请修改",
  "title10625": "异步延续",
  "title10626": "Điều chỉnh qua các tháng",
  "title10627": "Phí gói trống",
  "title10629": "追加结束事件",
  "title10630": "追加网关",
  "title10631": "追加任务",
  "title10632": "追加中间抛出事件",
  "title10633": "边界事件",
  "title10634": "激活全局连接工具",
  "title10635": "添加",
  "title10636": "在上面添加道",
  "title10637": "分割成两个道",
  "title10638": "分割成三个道",
  "title10639": "在下面添加道",
  "title10640": "追加补偿活动",
  "title10641": "修改类型",
  "title10642": "使用关联连接",
  "title10643": "使用顺序",
  "title10644": "消息流或者关联连接",
  "title10645": "使用数据输入关联连接",
  "title10646": "激活抓手工具",
  "title10647": "激活套索工具",
  "title10648": "激活创建",
  "title10649": "删除空间工具",
  "title10650": "创建扩展子过程",
  "title10651": "创建中间抛出事件",
  "title10652": "创建池",
  "title10653": "参与者",
  "title10654": "数据对象参考",
  "title10655": "数据存储参考",
  "title10656": "即席",
  "title10657": "发送任务",
  "title10658": "接收任务",
  "title10659": "用户任务",
  "title10660": "手工任务",
  "title10661": "业务规则任务",
  "title10662": "服务任务",
  "title10663": "脚本任务",
  "title10664": "调用活动",
  "title10665": "子流程（折叠的）",
  "title10666": "子流程（展开的）",
  "title10667": "开始事件",
  "title10668": "中间事件",
  "title10669": "结束事件",
  "title10670": "创建开始事件",
  "title10671": "创建结束事件",
  "title10672": "创建任务",
  "title10673": "创建用户任务",
  "title10674": "创建网关",
  "title10675": "创建数据对象",
  "title10676": "创建数据存储",
  "title10677": "创建分组",
  "title10678": "创建中间",
  "title10679": "消息开始事件",
  "title10680": "定时开始事件",
  "title10681": "条件开始事件",
  "title10682": "信号开始事件",
  "title10683": "错误开始事件",
  "title10684": "升级开始事件",
  "title10685": "补偿开始事件",
  "title10686": "消息开始事件（非中断）",
  "title10687": "定时开始事件（非中断）",
  "title10688": "条件开始事件（非中断）",
  "title10689": "信号开始事件（非中断）",
  "title10690": "升级开始事件（非中断）",
  "title10691": "消息中间捕获事件",
  "title10692": "消息中间抛出事件",
  "title10693": "定时中间捕获事件",
  "title10694": "升级中间抛出事件",
  "title10695": "条件中间捕获事件",
  "title10696": "链接中间捕获事件",
  "title10697": "链接中间抛出事件",
  "title10698": "补偿中间抛出事件",
  "title10699": "信号中间捕获事件",
  "title10700": "信号中间抛出事件",
  "title10701": "消息结束事件",
  "title10702": "定时结束事件",
  "title10703": "错误结束事件",
  "title10704": "取消结束事件",
  "title10705": "补偿结束事件",
  "title10706": "信号结束事件",
  "title10707": "终止结束事件",
  "title10708": "消息边界事件",
  "title10709": "消息边界事件（非中断）",
  "title10710": "定时边界事件",
  "title10711": "定时边界事件（非中断）",
  "title10712": "升级边界事件",
  "title10713": "升级边界事件（非中断）",
  "title10714": "条件边界事件",
  "title10715": "条件边界事件（非中断）",
  "title10716": "错误边界事件",
  "title10717": "取消边界事件",
  "title10718": "信号边界事件",
  "title10719": "信号边界事件（非中断）",
  "title10720": "补偿边界事件",
  "title10721": "互斥网关",
  "title10722": "并行网关",
  "title10723": "相容网关",
  "title10724": "复杂网关",
  "title10725": "事件网关",
  "title10726": "转运",
  "title10727": "子流程",
  "title10728": "事件子流程",
  "title10729": "折叠池",
  "title10730": "展开池",
  "title10731": "在",
  "title10732": "里，",
  "title10733": "没有父类",
  "title10734": "没有指定的形状类型",
  "title10735": "流元素必须是池",
  "title10736": "参与者的子类",
  "title10737": "子道大于",
  "title10738": "元素不能为空",
  "title10739": "流程图不符合",
  "title10740": "规范",
  "title10741": "没有可展示的流程图",
  "title10742": "没有可展示的流程",
  "title10743": "协作",
  "title10744": "由",
  "title10745": "引用的",
  "title10746": "元素仍未绘制",
  "title10747": "已被渲染",
  "title10748": "导入",
  "title10749": "失败",
  "title10750": "详情",
  "title10751": "创建者",
  "title10752": "持续异步",
  "title10753": "工作配置",
  "title10754": "工作优先级",
  "title10755": "重试时间周期",
  "title10756": "文档",
  "title10757": "元素文档",
  "title10758": "历史配置",
  "title10759": "历史的生存时间",
  "title10760": "业务",
  "title10761": "校验",
  "title10762": "属性",
  "title10763": "执行监听",
  "title10764": "必须提供一个值",
  "title10765": "资源",
  "title10766": "字段注入",
  "title10767": "扩展",
  "title10768": "输入",
  "title10769": "输出",
  "title10770": "输入参数",
  "title10771": "输出参数",
  "title10772": "参数",
  "title10773": "定时器定义类型",
  "title10774": "定时器定义",
  "title10775": "持续",
  "title10776": "升级",
  "title10777": "错误",
  "title10778": "链接名称",
  "title10779": "条件名称",
  "title10780": "变量名称",
  "title10781": "变量事件",
  "title10782": "多个变量事件以逗号隔开",
  "title10783": "等待完成",
  "title10784": "活动参考",
  "title10785": "版本标签",
  "title10786": "可执行文件",
  "title10787": "扩展任务配置",
  "title10788": "任务优先级",
  "title10789": "外部",
  "title10790": "连接器",
  "title10791": "必须配置连接器",
  "title10792": "连接器编号",
  "title10793": "实现方式",
  "title10794": "结果变量",
  "title10795": "主题",
  "title10796": "配置连接器",
  "title10797": "代理人",
  "title10798": "候选用户",
  "title10799": "候选组",
  "title10800": "到期时间",
  "title10801": "跟踪日期",
  "title10802": "优先级",
  "title10803": "跟踪日期必须符合",
  "title10804": "表达式，如：",
  "title10805": "或者一个",
  "title10806": "标准日期，如：2015-06-26",
  "title10807": "变量",
  "title10808": "候选人起动器配置",
  "title10809": "候选人起动器组",
  "title10810": "这映射到流程定义键。",
  "title10811": "候选人起动器的用户",
  "title10812": "指定多个用户作为逗号分隔的列表。",
  "title10813": "启动",
  "title10814": "指定多个组作为逗号分隔的列表。",
  "title10815": "消息实例",
  "title10816": "外部资源",
  "title10817": "跟踪时间",
  "title10818": "友情提示：任务的分配规则，使用",
  "title10819": "流程模型",
  "title10820": "下的【分配规则】替代，提供指定角色、部门负责人、部门成员、岗位、工作组、自定义脚本等",
  "title10821": "种维护的任务分配维度，更加灵活！",
  "title10822": "Gần đây",
  "title10823": "Tổng doanh số trong những ngày gần đây",
  "title10824": "Số ngày tối đa cho nhóm không thể trống",
  "title10825": "Số tiền hoàn lại",
  "title10826": "Vui lòng nhập tên nhà cung cấp dịch vụ bên thứ ba",
  "title10827": "Đẩy hàng loạt",
  "title10828": "Đẩy các mặt hàng được chọn",
  "title10829": "Đẩy tất cả các mặt hàng",

  "magellan_600001": "Hàng的家庭",
  "magellan_600024": "Bếp và Kitchenware",
  "magellan_600154": "Văn và Soft Furnishings",
  "magellan_600942": "Điện gia dụng",
  "magellan_601152": "Thời trang nữ và Quần áo lót",
  "magellan_601303": "Thời trang Hồi giáo",
  "magellan_601352": "Giày dép",
  "magellan_601450": "Trang điểm & Chăm sóc cá nhân",
  "magellan_601739": "Điện thoại & Thiết bị điện tử",
  "magellan_601755": "Máy tính & Thiết bị văn phòng",
  "magellan_602118": "Dụng cụ cho thú cưng",
  "magellan_602284": "Trẻ em & Phụ nữ mang thai",
  "magellan_603014": "Thể thao & Ngoài trời",
  "magellan_604206": "Đồ chơi & Sở thích",
  "magellan_604453": "Phụ kiện",
  "magellan_604579": "Công cụ & Thiết bị",
  "magellan_604968": "Cải tạo nhà cửa",
  "magellan_605196": "Ô tô & Xe máy",
  "magellan_605248": "Phụ kiện thời trang",
  "magellan_700437": "Thực phẩm & Đồ uống",
  "magellan_700645": "Sức khỏe",
  "magellan_801928": "Sách & Truyền thông",
  "magellan_802184": "Thời trang trẻ em",
  "magellan_824328": "Thời trang nam & Quần áo lót",
  "magellan_824584": "Vali & Túi xách",
  "magellan_834312": "Sản phẩm ảo",
  "magellan_856720": "Hàng đã sử dụng",
  "magellan_951432": "Bộ sưu tập",
  "magellan_953224": "Trang sức & Sản phẩm liên quan",

  // 2024.3.5 物流功能新增翻译
  "可按物流方式分别设置揽收方式": "Có thể thiết lập cách lấy hàng riêng biệt theo từng phương thức vận chuyển.",
  "物流方式": "Phương thức vận chuyển",
  "SKU编号": "SKUSố",
  "物流名称": "Tên dịch vụ vận chuyển",
  "状态": "Trạng thái",
  "操作": "Hoạt động",
  "设置": "Cài đặt",
  "揽货方式": "Phương thức nhận hàng",
  "快递取件": "Giao hàng nhanh",
  "自行寄件": "Tự gửi hàng",
  "请选择揽收方式": "Vui lòng chọn phương thức nhận hàng",
  "全部": "Tất cả",
  "印尼": "Indonesia",
  "马来西亚": "Malaysia",
  "菲律宾": "Philippines",
  "泰国": "Thái Lan",
  "越南": "Việt Nam",
  "中国台湾":"Đài Loan",
  "新加坡":"Singapore",
  "线上物流": "Vận chuyển trực tuyến",
  "操作成功！": "Thành công!",
  "打印拣货单(含汇总)":"In ấn Chọn hàng(bao gồm tổng kết)",
  "商品SKU": "SKU Sản phẩm",
  "产品SKU": "SKU Cửa hàng",
  "包裹数量": "Số Lượng Bưu Kiện",
  "SKU数量": "Số Lượng SKU",
  "货品总数": "Tổng Số Sản Phẩm",
  "打印时间": "Thời Gian In",
  "货品信息": "Thông Tin Sản Phẩm",
  "变种名称": "Tên Biến Thể",
  "货架位": "Vị Trí Kệ",
  "数量": "Số Lượng",
  "包裹信息": "Thông Tin Bưu Kiện",
  "包裹号": "Số Hiệu Bưu Kiện",
  "总计": "Tổng",
  "打印":"In",
  "拣货单":"Danh sách chọn hàng",
  "包裹数量":"Số lượng gói",
  "货品总数":"Tổng số sản phẩm",
  "打单时间":"Thời gian in",
  "货品信息":"Thông tin sản phẩm",
  "买家信息":"Thông tin người mua",
  "备注信息":"Ghi chú",
  "总数":"Tổng",
  "订单号":"Số đơn hàng",
  "运单号":"Số vận đơn",
  "物流方式":"Phương thức vận chuyển",
  "店铺名称":"Tên cửa hàng",
  "货品总数":"Tổng số sản phẩm",
  "打印面单和配货单":"In hóa đơn và danh sách đóng gói",
  "稳定出单的商品，销量下滑":"Đơn đặt hàng ổn định, doanh số giảm",
  "爆款商品":"Sản phẩm bán chạy",
  "出单较为稳定，销量暴涨的潜力商品":"Sản phẩm tiềm năng với đơn hàng ổn định và doanh số tăng vọt",
  "稳定出单的商品，销量持续上涨":"Sản phẩm với đơn hàng ổn định và doanh số tăng liên tục",
  "上架时间长，销量较低":"Thời gian trên kệ lâu, doanh số thấp",
  "高退货率":"Tỷ lệ trả lại cao",
  "高差评率":"Tỷ lệ đánh giá tiêu cực cao",
  "低回复率":"Tỷ lệ phản hồi thấp",
  "取消率过高":"Tỷ lệ hủy cao",
  "样品费用":"Chi phí mẫu",
  "空包费用":"Chi phí bao trống",
  "测评佣金":"Hoa hồng đánh giá",
  "测评付款佣金/手续费":"Hoa hồng thanh toán đánh giá / phí",
  "修改自定义费用":"Sửa đổi phí tùy chỉnh",
  "小件包裹":"Gói nhỏ",
  "测试刷单":"Chải thử",
  "漏单":"Đơn đặt hàng bỏ lỡ",
  "预售":"Bán trước",
  "海运":"Vận chuyển biển",
  "不发":"Chưa gửi",
  "补寄":"Gửi lại",
  "已采":"Đã mua",
  "未分组":"Chưa nhóm",
  "测评佣金":"Hoa hồng đánh giá",
  "测评付款佣金/手续费":"Hoa hồng thanh toán đánh giá/phí",
  "提现支出":"Chi phí rút tiền",
  "线下售后退款":"Hoàn tiền sau bán hàng ngoại tuyến",
  "线下补发运费":"Phí gửi lại ngoại tuyến",
  "线下补发成本":"Chi phí gửi lại ngoại tuyến",
  "退件处理费":"Phí xử lý trả hàng",
  "活动包裹费用":"Phí gói hoạt động",
  "其他杂费":"Các chi phí khác",
  "仓库费用":"Chi phí kho",
  "未推送":"Chưa đẩy",
  "推送中":"Đang đẩy",
  "推送失败":"Đẩy thất bại",
  "待盘点":"Hàng chờ kiểm kê",
  "按仓库":"Theo kho",
  "按商品SKU":"Theo SKU sản phẩm",
  "调拨计划":"Kế hoạch phân bổ",
  "不分摊":"Không phân chia",
  "按价格分摊":"Phân chia theo giá",
  "按数量分摊":"Phân chia theo số lượng",
  "按体积分摊":"Phân chia theo thể tích",
  "待出库":"Hàng chờ giao",
  "已出库":"Xuất hàng",
  "采购入库":"Biên nhận mua hàng",
  "销售出库":"Vấn đề bán hàng",
  "手动入库":"Nhận hàng bằng tay",
  "手动出库":"Phát hàng bằng tay",
  "回滚出库":"Lùi lại vấn đề",
  "导入入库":"Nhập hàng",
  "盘点入库":"Nhận hàng tồn kho",
  "盘点出库":"Vấn đề tồn kho",
  "调拨入库":"Nhận hàng được phân bổ",
  "调拨出库":"Vấn đề phân bổ",
  "取消调拨入库":"Hủy nhận hàng được phân bổ",
  "三方仓同步入库":"Nhận hàng đồng bộ kho của bên thứ ba",
  "三方仓同步出库":"Vấn đề đồng bộ kho của bên thứ ba",
  "退货入库":"Nhận hàng trả lại",
  "到付":"Thanh toán khi nhận hàng",
  "现结":"Thanh toán ngay",
  "分期付款":"Thanh toán trả góp",
  "周结":"Thanh toán hàng tuần",
  "月结":"Thanh toán hàng tháng",
  "到期支付":"Thanh toán khi đến hạn",
  "未扣除广告费后的利润":"Lợi nhuận sau khi khấu trừ chi phí quảng cáo",
  "排除测评搁置取消未付款的订单量":"Loại bỏ đánh giá + đặt hàng + hủy bỏ + số lượng đơn hàng chưa thanh toán",
  "排除测评搁置取消未付款的销量":"Loại bỏ đánh giá + đặt hàng + hủy bỏ + doanh số bán hàng chưa thanh toán",
  "有效销售额释义":"Số tiền bán hàng hiệu quả = Thanh toán của người mua + Tiền trợ cấp của nền tảng + Tiền trợ cấp vận chuyển (loại trừ số tiền bán hàng chưa thanh toán)",
  "每次最多可选取50条数据进行打印。":"Mỗi lần tối đa có thể chọn 50 bản ghi để in",
  "导出已选商品": "Xuất Sản phẩm đã chọn",
  "导出全部页商品": "Xuất Tất cả các Trang Sản phẩm",
  "导出SKU配对关系": "Xuất Quan hệ SKU",
  "显示下架商品":"Hiển thị sản phẩm không bán",
  "扫描或输入包裹号": "Quét hoặc nhập số gói / số vận đơn",
  "拆单成功": "Tách đơn thành công",
  "已确定订单销售额除以已确定订单订单数": "Tổng doanh số của các đơn hàng đã xác nhận chia cho tổng số đơn hàng đã xác nhận",
  "访客数": "Khách ghé thăm",
  "订单数": "Đơn hàng",
  "销售额": "Doanh số",
  "环比":"So với tháng trước",
  "本地状态是退货退款中和退货订单或退款金额大于0的退款金额":"Trạng thái cục bộ là trả hàng và hoàn tiền hoặc số tiền hoàn lại lớn hơn 0.",
  "退款金额=本地状态是退货退款中和退货订单或退款金额大于0的退款金额":"Số tiền hoàn lại bằng số tiền hoàn lại khi trạng thái cục bộ là trả hàng và hoàn tiền hoặc số tiền hoàn lại lớn hơn 0.",
  "退货订单量=本地状态是退货退款中和退货订单或退款金额大于0的订单数量":"Số lượng đơn hàng trả hàng bằng số lượng đơn hàng khi trạng thái cục bộ là trả hàng và hoàn tiền hoặc số tiền hoàn lại lớn hơn 0.",
  "有效订单量=排除测评+搁置+取消+未付款的订单量":"Số lượng đơn hàng hợp lệ bằng số lượng đơn hàng ngoại trừ đánh giá, giữ lại, hủy bỏ và chưa thanh toán.",
  "有效销售额=排除测评+搁置+取消+未付款的销售额":"Số tiền bán hợp lệ bằng số tiền bán không bao gồm đánh giá, giữ lại, hủy bỏ và chưa thanh toán.",
  "销售额=已下单的总销售额（未取消订单销售额+取消订单销售额 ）":"Số tiền bán bằng tổng số tiền bán của các đơn hàng đã đặt (số tiền bán của các đơn hàng chưa bị hủy + số tiền bán của các đơn hàng đã bị hủy).",
  "订单量=已下单的订单数量":"Số lượng đơn hàng bằng số lượng đơn hàng đã đặt.",
  "共{cont}条":"Tổng cộng {cont} mục",
  "发现新版本，是否更新":"Bạn có muốn cập nhật phiên bản mới không?",
  "高级版":"Phiên bản tiên tiến",
  "您有62个店铺授权过期，请点击重新授权":"Bạn có 62 sự cho phép của cửa hàng đã hết hạn, vui lòng nhấp để cấp lại quyền",
  "亏损商品":"Sản phẩm gây thua lỗ",
  "利润":"Lợi nhuận",
  "利润偏低":"Lợi nhuận thấp",
  "利润率":"Biên lợi nhuận",
  "销量暴跌":"Doanh số bán hàng giảm đột ngột",
  "稳定出单的商品，销量下滑":"Sản phẩm đặt hàng ổn định, doanh số bán hàng giảm",
  "销量环比":"So sánh Doanh số bán hàng",
  "出单较为稳定，销量暴涨的潜力商品":"Sản phẩm có đơn đặt hàng tương đối ổn định, tiềm năng tăng mạnh về doanh số bán hàng",
  "稳定出单的商品，销量持续上涨":"Sản phẩm có đơn đặt hàng ổn định, doanh số bán hàng tiếp tục tăng",
  "销量环比":"So sánh Doanh số bán hàng (Tháng trước - Tháng này)",
  "上架时间长，销量较低":"Thời gian trên kệ lâu, doanh số bán hàng thấp",
  "销量":"Doanh số bán hàng",
  "高退货率":"Tỉ lệ Trả hàng cao",
  "退货率":"Tỷ lệ Trả hàng",
  "高差评率":"Tỷ lệ Phản hồi Tiêu cực Cao",
  "差评率":"Tỷ lệ Phản hồi Tiêu cực",
  "低回复率":"Tỷ lệ Phản hồi Thấp",
  "回复率":"Tỷ lệ Phản hồi",
  "回应时间":"Thời gian Phản hồi",
  "回应时间>1小时":"Thời gian Phản hồi >1 Giờ",
  "逾期发货率过高":"Tỷ lệ Giao hàng Trễ Quá cao",
  "逾期发货率":"Tỷ lệ Giao hàng Trễ",
  "取消率过高":"Tỷ lệ Hủy cao",
  "取消率":"Tỷ lệ Hủy",
  "联系我们":"Liên hệ chúng tôi",
  "修改自定义费用":"Sửa phí tùy chỉnh",
  "快递取件":"Lấy Nhanh",
  "自行取件":"Tự Lấy",
  "未分组":"Chưa phân loại",
  "更新时间":"Cập Nhật Thời Gian",
  "默认仓库":"Kho Mặc định",
  "商品SKU推送失败:2000035000: 授权失败":"SKU sản phẩm gửi thất bại:2000035000: Lỗi ủy quyền",
  "商品SKU推送失败:2000035000: 授权失败,请检查appKey和appSecret是否正确":"SKU sản phẩm gửi thất bại: 2000035000: Lỗi ủy quyền, vui lòng kiểm tra xem appKey và appSecret có đúng không",
  "调拨计划":"Kế hoạch cấp phát",
  "在途中":"Trong chuyến đi",
  "部分收货":"Đã nhận một phần",
  "已完成":"Đã hoàn thành",
  "已取消":"Đã hủy",
  "待出库":"Chờ xuất kho",
  "已出库":"Đã xuất kho",
  "待出库":"Đang chờ xuất kho",
  "已入库":"Đã nhập kho",
  "订单 销售出库1":"Xuất hàng đơn hàng bán 1",
  "联系我们":"Liên hệ với chúng tôi",
  "采购订单推送失败:2000035000: 授权失败":"Đơn đặt hàng mua không thành công: 2000035000: Tự động thất bại",
  "采购订单推送失败:2000035000: 客户SKU 不存在。":"Đơn đặt hàng mua không thành công: 2000035000: SKU của khách hàng không tồn tại",
  "采购订单推送失败:2000035000: Item not exist: sku = Testing Product K, name = null;":"Đơn đặt hàng mua không thành công: 2000035000: Sản phẩm không tồn tại: sku = Testing Product K, name = null",
  "AI模板管理":"Quản lý mẫu AI",
  "AI模板分类管理":"Quản lý Danh mục Mẫu AI",
  "修改":"Chỉnh sửa",
  "自由会话":"Phiên Tự do",
  "模板会话":"Phiên Mẫu",
  "shopee listing写作与优化6。":"Viết và Tối ưu hóa Shopee Listing 6",
  "根据提供的关键词、类目、商品卖点信息，为你生成地道流畅的语言表达，提升商品曝光量；使用客观和中立的语言，避免夸张和绝对化的词汇。":"Tạo ra các biểu hiện ngôn ngữ chân thực và trôi chảy dựa trên các từ khóa, danh mục và điểm bán hàng của sản phẩm được cung cấp, để tăng cường sự phơi bày của sản phẩm; Sử dụng ngôn ngữ khách quan và trung lập, tránh các từ ngữ phóng đại và tuyệt đối.",
  "商品特性的条数":"Số Lượng Đặc Điểm Sản Phẩm",
  "商品关键词":"Từ Khóa Sản Phẩm",
  "商品品牌":"Thương Hiệu Sản Phẩm",
  "商品类目":"Danh Mục Sản Phẩm",
  "商品卖点":"Điểm Bán Hàng Sản Phẩm",
  "我的收藏":"Yêu Thích Của Tôi",
  "知识库":"Cơ Sở Kiến Thức",
  "发现新版本，点击更新完成升级。体验新版本":"Khám phá phiên bản mới, nhấn để cập nhật và hoàn tất nâng cấp.",
  "系统消息":"Thông Báo Hệ Thống",
  "今日不再提醒":"Không nhắc nhở nữa trong ngày hôm nay",
  "去授权或删除":"Đi để ủy quyền hoặc xóa",
  "为避免使用及数据分析受影响，请尽快处理过期的店铺数据，您可以":"Để tránh ảnh hưởng đến việc sử dụng và phân tích dữ liệu, vui lòng xử lý dữ liệu cửa hàng đã hết hạn càng sớm càng tốt. Bạn có thể",
  "已过期的店铺":"Cửa hàng đã hết hạn",
  "最多置顶5个":"Tối đa 5",
  "综合分析":"Phân tích toàn diện",
  "受限页面":"Trang bị hạn chế",
  "已下单的订单数量 （包含取消和退款订单）":"Số lượng đơn hàng đã đặt (bao gồm cả đơn hàng đã hủy và hoàn tiền)",
  "已下单的用户数量（按买家姓名/邮箱去重）":"Số lượng khách hàng đã đặt hàng (loại bỏ bằng tên/email người mua)",
  "付款客户数":"Số lượng khách hàng thanh toán",
  "退款客户数":"Số lượng khách hàng yêu cầu hoàn tiền",
  "已退款的用户数（包含部分退款）":"Số lượng người dùng đã hoàn tiền (bao gồm hoàn tiền một phần)",
  "已下单的用户数量（按买家姓名/邮箱去重）":"",
  "已取消订单数量":"Số lượng khách hàng duy nhất đã đặt hàng (loại bỏ bằng tên/email người mua)",
  "仅统计已付款的订单销售数量":"Số lượng bán hàng chỉ tính cho các đơn hàng đã thanh toán",
  "销售额/客户数":"Doanh số/Khách hàng",
  "退款金额":"Số tiền hoàn trả",
  "店铺销售详情":"Chi tiết doanh số cửa hàng",
  "店铺详情":"Chi tiết cửa hàng",
  "商品销量统计":"Thống kê doanh số sản phẩm",
  "非追加产品主包裹至少保留一个":"Các sản phẩm không phải là sản phẩm bổ sung ít nhất một gói chính",

  "批量添加标记":"Thêm nhãn một lượt",
  "添加标记":"Tambah Tag",
  "选择标记":"Chọn Tag",
  "shopeeCoinsRedeemed":"Shopee Coins Redeemed"
}
