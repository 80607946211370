
 
import store from '@/store'

export default {
  inserted(el, binding, vnode) {
    const kolConfig = store.getters && store.getters.kolConfig;
    if (!kolConfig.kolSeaRuleButton) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  }
}
