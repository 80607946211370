<template>
  <div id="tags-view-container" class="tags-view-container" :class="{morep:showScrollBtn}">
    <div v-if="showScrollBtn" class="arrow-box left">
      <div class="arrow el-icon-caret-left" @click="moveTag(100)"></div>
    </div>
    <div v-if="showScrollBtn" class="arrow-box right">
      <div class="arrow el-icon-caret-right" @click="moveTag(-100)"></div>
    </div>
    <scroll-pane ref="scrollPane" class="tags-view-wrapper" :visitedViews="visitedViews" @scroll="handleScroll">
      <div class="scroll-box">
        <router-link
          v-for="tag in visitedViews"
          ref="tag"
          :key="tag.path"
          :class="isActive(tag)?'active':''"
          :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
          tag="span"
          class="tags-view-item"
          @click.middle.native="!isAffix(tag)?closeSelectedTag(tag):''"
          @contextmenu.prevent.native="openMenu(tag,$event)"
        >
          {{ $t(`menu.${tag.title}`) }}
          <span v-if="!isAffix(tag)" class="el-icon-close" @click.prevent.stop="closeSelectedTag(tag)" />
        </router-link>
      </div>
    </scroll-pane>
    <ul v-show="visible" :style="{left:left+'px',top:top+'px'}" class="contextmenu">
      <li @click="refreshSelectedTag(selectedTag)"><i class="el-icon-refresh-right"></i> {{ $t('title10300') }}</li>
      <li v-if="!isAffix(selectedTag)" @click="closeSelectedTag(selectedTag)"><i class="el-icon-close"></i> {{ $t('title10301') }}</li>
      <li @click="closeOthersTags"><i class="el-icon-circle-close"></i> {{ $t('title10302') }}</li>
      <li v-if="!isFirstView()" @click="closeLeftTags"><i class="el-icon-back"></i> {{ $t('title10303') }}</li>
      <li v-if="!isLastView()" @click="closeRightTags"><i class="el-icon-right"></i> {{ $t('title10304') }}</li>
      <li @click="closeAllTags(selectedTag)"><i class="el-icon-circle-close"></i> {{ $t('title10305') }}</li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ScrollPane from './ScrollPane'
import path from 'path'

export default {
  components: { ScrollPane },
  data() {
    return {
      showScrollBtn:false,
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {},
      affixTags: []
    }
  },
  computed: {
    ...mapGetters([
      'sidebar'
    ]),
    visitedViews() {
      return this.$store.state.tagsView.visitedViews
    },
    routes() {
      return this.$store.state.permission.routes
    },
    theme() {
      return this.$store.state.settings.theme;
    }
  },
  watch: {
    $route() {
      this.cutScrollBtn();
      this.addTags()
      this.moveToCurrentTag()
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    }
  },
  mounted() {
    this.initTags()
    this.addTags()
    window.addEventListener('resize',this.cutScrollBtn)
  },
  unmounted() {
    window.removeEventListener('resize',this.cutScrollBtn)
  },
  methods: {
    cutScrollBtn(){
      // const $container = this.$refs.scrollContainer.$el
      // const $containerWidth = $container.offsetWidth
      // const $scrollWrapper = this.scrollWrapper
      // const tagList = this.$refs.tag
      setTimeout(()=>{
        const box=document.querySelector('.tags-view-wrapper');
        const scroDom=box.querySelector('.scroll-box');
        if(box.offsetWidth<scroDom.getBoundingClientRect().width){
          this.showScrollBtn=true;
        }else{
          this.showScrollBtn=false
        }
      },30)

    },
    isActive(route) {
      return route.path === this.$route.path
    },
    activeStyle(tag) {
      if (!this.isActive(tag)) return {};
      return {
        "background-color":'#F4F5F7'|| this.theme,
        "border-color":'transparent'|| this.theme
      };
    },
    isAffix(tag) {
      return tag.meta && tag.meta.affix
    },
    isFirstView() {
      try {
        return this.selectedTag.fullPath === this.visitedViews[1].fullPath || this.selectedTag.fullPath === '/index/home'
      } catch (err) {
        return false
      }
    },
    isLastView() {
      try {
        return this.selectedTag.fullPath === this.visitedViews[this.visitedViews.length - 1].fullPath
      } catch (err) {
        return false
      }
    },
    filterAffixTags(routes, basePath = '/') {
      let tags = []
      routes.forEach(route => {
        if (route.meta && route.meta.affix) {
          const tagPath = path.resolve(basePath, route.path)
          tags.push({
            fullPath: tagPath,
            path: tagPath,
            name: route.name,
            meta: { ...route.meta }
          })
        }
        if (route.children) {
          const tempTags = this.filterAffixTags(route.children, route.path)
          if (tempTags.length >= 1) {
            tags = [...tags, ...tempTags]
          }
        }
      })
      return tags
    },
    initTags() {
      const affixTags = this.affixTags = this.filterAffixTags(this.routes)
      for (const tag of affixTags) {
        // Must have tag name
        if (tag.name) {
          this.$store.dispatch('tagsView/addVisitedView', tag)
        }
      }
    },
    addTags() {
      const { name } = this.$route
      if (name) {
        this.$store.dispatch('tagsView/addView', this.$route)
      }
      return false
    },
    moveTag(left){
      this.$refs.scrollPane.moveTo(left)
    },
    moveToCurrentTag() {
      const tags = this.$refs.tag
      this.$nextTick(() => {
        for (const tag of tags) {
          if (tag.to.path === this.$route.path) {
            this.$refs.scrollPane.moveToTarget(tag)
            // when query is different then update
            if (tag.to.fullPath !== this.$route.fullPath) {
              this.$store.dispatch('tagsView/updateVisitedView', this.$route)
            }
            break
          }
        }
      })
    },
    refreshSelectedTag(view) {
      this.$tab.refreshPage(view);
    },
    closeSelectedTag(view) {
      this.$tab.closePage(view).then(({ visitedViews }) => {
        this.cutScrollBtn();
        if (this.isActive(view)) {
          this.toLastView(visitedViews, view)
        }
      })
    },
    closeRightTags() {
      this.$tab.closeRightPage(this.selectedTag).then(visitedViews => {
        if (!visitedViews.find(i => i.fullPath === this.$route.fullPath)) {
          this.toLastView(visitedViews)
        }
      })
    },
    closeLeftTags() {
      this.$tab.closeLeftPage(this.selectedTag).then(visitedViews => {
        if (!visitedViews.find(i => i.fullPath === this.$route.fullPath)) {
          this.toLastView(visitedViews)
        }
      })
    },
    closeOthersTags() {
      this.$router.push(this.selectedTag).catch(()=>{});
      this.$tab.closeOtherPage(this.selectedTag).then(() => {
        this.moveToCurrentTag()
      })
    },
    closeAllTags(view) {
      this.$tab.closeAllPage().then(({ visitedViews }) => {
        if (this.affixTags.some(tag => tag.path === this.$route.path)) {
          return
        }
        this.toLastView(visitedViews, view)
      })
    },
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0]
      if (latestView) {
        this.$router.push(latestView.fullPath)
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === 'Dashboard') {
          // to reload home page
          this.$router.replace({ path: '/redirect' + view.fullPath })
        } else {
          this.$router.push('/index/home')
        }
      }
    },
    openMenu(tag, e) {
      const menuMinWidth = 105
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      const offsetWidth = this.$el.offsetWidth // container width
      const maxLeft = offsetWidth - menuMinWidth // left boundary
      const left = e.clientX - offsetLeft + 15 // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft
      } else {
        this.left = left
      }

      this.top = e.clientY
      this.visible = true
      this.selectedTag = tag
    },
    closeMenu() {
      this.visible = false
    },
    handleScroll() {
      this.closeMenu()
    }
  }
}
</script>

<style lang="scss" scoped>
.tags-view-container {
  max-width: 100%;
  position:relative;
  line-height: 32px;
  &.morep{
    padding:0 48px;
  }
  .arrow-box{
    color:#333;
    position:absolute;
    height:66px;
    padding-top:26px;
    font-size:16px;
    z-index:10;
    bottom:0;
    &.left{
      left:0px;
      box-shadow: 10px 0px 10px -5px rgba(7,11,20,0.1);
    }
    &.right{
      right:0px;
      box-shadow: -10px 0px 10px -5px rgba(7,11,20,0.1);
    }
    .arrow{
      cursor: pointer;
      border-radius: 10px;
      width:32px;
      height:32px;
      line-height: 32px;
      text-align: center;
      bottom:4px;
      &:hover{
        background: #F6F7FB;
      }
      &.el-icon-caret-left{
        left:0px;
        margin-right:10px;
      }
      &.el-icon-caret-right{
        left:auto;
        right:0;
        margin-left:10px;
      }
    }
  }

  .tags-view-wrapper {
    overflow: hidden;
    .scroll-box{
      display:inline-block;
      white-space: nowrap;
    }
    .el-scrollbar__bar{
      display:none;
    }
    .tags-view-item {
      display: inline-block;
      position: relative;
      cursor: pointer;
      color: #333;
      font-size: 12px;
      padding-left:12px;
      padding-right:5px;
      margin-left:10px;
      &:first-child{
        margin-left:0;
        padding-right:12px;
      }
      .el-icon-close {
        width: 16px;
        height: 16px;
        margin-left:5px;
        border-radius: 50%;
      }
      &:after{
        content:'';
        width: 1px;
        height: 16px;
        background: #F6F7FB;
        position:absolute;
        right:0;
        top:8px
      }
      &:last-child{
        &::after{
          width:0;
        }
      }
      &.active {
        background: #F6F7FB;
        color:#1377FF;
        position:relative;
        border-radius:10px 10px 0 0;
        &::before {
          content:'';
          position:absolute;
          left:-8px;
          bottom:0;
          width:8px;
          height:8px;
          background: radial-gradient(circle at 0% 0%,transparent 8px,#F6F7FB 0);
        }
        &::after {
          content:'';
          position:absolute;
          top:auto;
          right:-8px;
          bottom:0;
          width:8px;
          height:8px;
          background: radial-gradient(circle at 100% 0%,transparent 8px,#F6F7FB 0);
        }
      }
    }
  }
  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
}
</style>
