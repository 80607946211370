<template>
  <div @click="toggleClick">
    <i></i>
  </div>
</template>

<script>
export default {
  name: 'Hamburger',
  methods: {
    toggleClick() {
      this.$emit('toggleClick')
    }
  }
}
</script>
