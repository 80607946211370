var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _vm.showHelp.show
        ? _c("my-help", {
            attrs: { info: _vm.showHelp.value },
            model: {
              value: _vm.showHelp.show,
              callback: function ($$v) {
                _vm.$set(_vm.showHelp, "show", $$v)
              },
              expression: "showHelp.show",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }