export default {
  menu: {
    '单量套餐': "แพ็กเกจ",
    '首页': "หน้าแรก",
    "调拨": "โอนสินค้า",
    "店铺利润报表": "รายงานกำไรร้านค้า",
    "利润月报": "รายงานกำไรเดือนละ",
    "入库": "เข้าคลัง",
    "业绩利润报表": "รายงานกำไรและประสิทธิภาพ",
    "Shopee店铺数据表": "Shopeeตารางข้อมูลร้านค้า",
    "Shopee商品数据表": "Shopeeตารางข้อมูลสินค้า",
    '订单地区分布': 'การกระจายที่อยู่ของคำสั่งซื้อ',
    '店铺分析': "รายงานร้านค้า",
    '店铺销售日历': "สถิติการสั่งซื้อ",
    '店铺销售分析': "การวิเคราะห์ยอดขายของร้าน",
    '店铺利润分析': "การวิเคราะห์กำไรของร้าน",
    '店铺周期性对比': "การเปรียบเทียบยอดขายเป็นระยะเวลา",
    '账户健康状况': "Store Health",
    '客户分析': "การวิเคราะห์ลูกค้า",
    "广告分析": "วิเคราะห์โฆษณา",
    '商品分析': "การวิเคราะห์ผลิตภัณฑ์",
    '商品数据分析': "การวิเคราะห์ข้อมูลสินค้า",
    '评论分析': "ข้อมูลรีวิว",
    '订单统计': "สถิติการสั่งซื้อ",
    "退货退款": "การจัดการคืนสินค้า",
    '分析报告': "รายงานการวิเคราะห์",
    '数据诊断': "ข้อมูลข้อเท็จจริง",
    '物流设置': 'การตั้งค่าการจัดส่ง',
    '订单分析': "รายงานร้านค้า",
    '退货报告': "รายงานการคืนสินค้า",
    '利润分析': "การวิเคราะห์กำไร",
    '订单利润明细': "รายละเอียดกำไรจากคำสั่งซื้อ",
    '广告概览': "ภาพรวมโฆษณา",
    '广告业绩': "ประสิทธิภาพโฆษณา",
    '广告采集': "การรวบรวมโฆษณา",
    '商品配置': "การกำหนดค่าสินค้า",
    '本地SKU': "SKU ท้องถิ่น",
    '商品配对': "การจับคู่สินค้า",
    '报表中心': "ศูนย์รายงาน",
    '经营看板': "แดชบอร์ดธุรกิจ",
    '单量套餐': "แพ็กเกจ",

    '运营待办': "รายการทำแบบดำเนินการ",
    '订单管理': "การจัดการคำสั่งซื้อ",
    '订单处理': "การจัดการคำสั่งซื้อ",
    '退货管理': "การจัดการคืนสินค้า",
    '线上订单': "คำสั่งซื้อด้วยตนเอง",
    '线下订单': "คำสั่งซื้อขายปลีก",
    '运费管理': "การจัดการค่าขนส่ง",
    '商品管理': "การจัดการสินค้า",
    '商品SKU': "SKU Merchant",
    'SKU匹配关系': "การจับคู่ SKU",
    '库龄': "อายุสินค้าในคลัง",
    '成本中心': "ศูนย์ค่าใช้จ่าย",
    '仓库管理': "การจัดการคลังสินค้า",
    '货架位': "ตำแหน่ง",
    '商品推送': "การโปรโมทสินค้า",
    '店铺库存预警': " การแจ้งเตือนสต็อก ",
    '库存列表': "รายการสินค้าคงคลัง",
    '仓库列表': "คลังสินค้า",
    '仓库盘点': "การนับสต็อก",
    '出库': "สินค้าหมด",
    '出入库记录': "การเคลื่อนไหวสต็อก",
    '采购管理': "การจัดการสั่งซื้อ",
    '采购单': "ใบจัดซื้อ",
    '采购建议': "คำแนะนำการสั่งซื้อ",
    '供应商列表': "รายการ Supplier",
    '收货管理': "จัดการการรับสินค้า",

    '站点': '站点',
    '短信渠道': '短信渠道',
    '短信模板': '短信模板',
    '短信日志': '短信日志',
    '文件列表': '文件列表',
    '文件配置': '文件配置',
    'Banner管理': 'Banner管理',
    '令牌管理': '令牌管理',
    '应用管理': '应用管理',
    '通知公告': '通知公告',
    '文章管理': '文章管理',
    '第三方服务配置': '第三方服务配置',
    '业务参数配置': '业务参数配置',
    '支付': "การชำระเงิน",
    '商户信息': '商户信息',
    '应用信息': '应用信息',
    '支付订单': '支付订单',
    '退款订单量': "จำนวนคำสั่งซื้อที่คืนเงิน",
    '监控': '监控',
    'API:日志': "API:日志",
    '访问日志': '访问日志',
    '错误日志': '错误日志',
    '审计日志': '审计日志',
    'MySQL:监控': "MySQL:监控",
    'Redis:监控': "Redis:监控",
    'Java:监控': "Java:监控",
    '监控平台': '监控平台',
    'DEV': 'DEV',
    '代码生成': '代码生成',
    '数据源配置': '数据源配置',
    '表单构建': '表单构建',
    '系统接口': '系统接口',
    '数据库文档': '数据库文档',
    '设置': "การตั้งค่า",
    '租户': '租户',
    '租户列表': '租户列表',
    '租户套餐': '租户套餐',
    '操作日志': "บันทึกการดำเนินการ",
    '账号管理': "ตั้งค่าบัญชีย่อย",
    '角色管理': "บทบาทการทำงาน ",
    '子账号管理': "บัญชีย่อย",
    '授权管理': "การจัดการการเชื่อมต่อ",
    '账号管理': "ตั้งค่าบัญชีย่อย",
    '店铺管理': "เชื่อมต่อร้านค้า",
    '仓库授权': "การเชื่อมต่อผู้ให้บริการ",
    '设置': "การตั้งค่า",
    '预估利润设置': "การตั้งค่ากำไรโดยประมาณ",
    '测评参数': "พารามิเตอร์การประเมิน",
    '汇率设置': "การตั้งค่าอัตราแลกเปลี่ยน",
    '标签管理': "การจัดการเครื่องหมาย",
    '订单导出模板': "แม่แบบสำหรับการส่งออกคำสั่งซื้อ",
    '下载中心': '下载中心',
    '报表下载': "ดาวน์โหลดรายงาน",
    '超级管理员': "ผู้ดูแลระดับสูง",
    '用户管理': "การจัดการผู้ใช้งาน",
    '系统角色管理': '系统角色管理',
    '菜单管理': '菜单管理',
    '字典管理': '字典管理',
    '定时任务': '定时任务',
    '公告中心': '公告中心',
    '租户订单': '租户订单',
    '三方仓': "คลังสินค้าบุคคลที่สาม",
    '三方仓厂商管理': "ผู้ให้บริการคลังสินค้า",
    '三方仓授权仓库管理': "คลังสินค้าที่ได้รับการเชื่อมต่อ",
    '其他': "อื่นๆ",
    '部门管理': '部门管理',
    '岗位管理': '岗位管理',
    '配置管理': '配置管理',
    '错误码管理': '错误码管理',
    '敏感词管理': '敏感词管理',
    '地区管理': '地区管理',
    '电商平台管理': '电商平台管理',
    '个人中心': "ข้อมูลส่วนตัว",
    '字典数据': '字典数据',
    //:AI
    '模板管理': "การจัดการเทมเพลต",
    'AI模板管理': 'AI模板管理',
    'AI模板分类管理': 'AI模板分类管理',
    '会话管理': "การจัดการเซสชัน",
    '创作训练': "การฝึกสร้างสรรค์",
    '授权结果': "ผลการอนุญาต",
    '授权失败': "การอนุญาตล้มเหลว",

    //  2024.3.5 物流功能新增翻译
    "物流管理": "การจัดการโลจิสติกส์",
    "物流设置": "การตั้งค่าการขนส่ง"
  },
  title: {
    '项目': "โครงการ",
    '总数据': "สรุปข้อมูล",
    '件数': "จำนวน",
    '销售': "จำนวนการขาย",
    '测评销量': "จำนวนยอดขายทดสอบ",
    '测评金额': "จำนวนเงินที่ประเมิน",
    '平均客单价': "ราคาเฉลี่ยต่อการสั่งซื้อ",
    '支出': "ค่าใช้จ่าย",
    '搜索广告费': "ค่าโฆษณาการค้นหา",
    '搜索广告转化': "การแปลงโฆษณาการค้นหา",
    '搜索广告点击率': "อัตราการคลิกโฆษณาการค้นหา",
    '搜索广告转化率': "อัตราการแปลงโฆษณาการค้นหา",
    '搜索广告ROI': "อัตราผลตอบแทนโฆษณาการค้นหา",
    '关联广告费': "ค่าโฆษณาแอฟิลิเอท",
    '关联广告转化': "การแปลงโฆษณาบุคคลที่เกี่ยวข้อง",
    '关联广告销售额': "ยอดขายโฆษณาความสัมพันธ์",
    '关联广告点击率': "อัตราการคลิกโฆษณาบุคคลที่เกี่ยวข้อง",
    '关联广告转化率': "อัตราการแปลงโฆษณาบุคคลที่เกี่ยวข้อง",
    '关联广告ROI': "อัตราผลตอบแทนโฆษณาบุคคลที่เกี่ยวข้อง",
    '推广广告费': "ค่าโฆษณาส่งเสริม",
    '推广广告转化': "การแปลงโฆษณาส่งเสริม",
    '推广广告销售额': "ยอดขายโฆษณาส่งเสริม",
    '推广广告点击率': "อัตราการคลิกโฆษณาส่งเสริม",
    '推广广告转化率': "อัตราการแปลงโฆษณาส่งเสริม",
    '推广广告ROI': "อัตราผลตอบแทนโฆษณาส่งเสริม",
    '测评损耗': "การสูญเสียการประเมิน",
    '商品成本': "ต้นทุนสินค้า",
    '退货金额': "การคืนเงิน",
    '预估运费': "ค่าขนส่งโดยประมาณ",
    '产出': "ผลลัพธ์",
    '利润': "ได้กำไร/ขาดทุน",
    '销售利润率': "อัตรากำไร",
  },
  order: {
    '不在待打单状态': "ไม่อยู่ในสถานะรอดำเนินการ",
    '请扫描或输入订单号或运单号': "กรุณาสแกนหรือป้อนหมายเลขคำสั่งซื้อหรือหมายเลขติดตาม",
    '发货记录': "บันทึกการจัดส่ง",
    'Free': "ค่าบริการจัดส่งฟรี",
    'Item': "การชำระเงินสินค้า",
    'Payment': "ค่าธรรมเนียมการทำธุรกรรม",
    'Commission': "ค่าคอมมิชชั่น",
    'Promotional': "คูปองของผู้ขาย",
    'Lazcoin': "ส่วนลด Lazcoin",
    'LazFlash': "ส่วนลดเพิ่มเติม/สนับสนุนสำหรับ LazFlash ทุกวันต่ำกว่า $9.99",
  },
  user: {
    "请输入新手机号码": "โปรดใส่หมายเลขโทรศัพท์ใหม่",
    "输入旧密码": "โปรดใส่รหัสผ่านเก่า",
    "请先使用主账号绑定手机号码": "โปรดผูกหมายเลขโทรศัพท์ของคุณกับบัญชีหลักก่อน",
    "销售地区": "ภูมิภาคการขาย",
    "时区": "เขตเวลา",
    "您的套餐还剩天，为避免服务中断，请尽快续订。立即购买": "แพ็กเกจของคุณเหลือ {days} วัน หากต้องการหลีกเลี่ยงการหยุดบริการ โปรดต่ออายุใหม่โดยเร็ว คลิกที่นี่เพื่อซื้อทันที",
  },
  "亏损商品": "สินค้าขาดทุน",
  "缺货": "สินค้าหมด",
  "推送成功": "อัปเดตสำเร็จ",
  "在途中": "โปรโมชันที่จองไว้",
  "调拨收货": "การโอนรับสินค้า",
  "采购收货": "การรับสินค้าสั่งซื้อ",
  "默认日销量": "ปริมาณขายรายวันเริ่มต้น",
  "部分收货": "รับสินค้าบางส่วน",
  "采购计划": "แผนการซื้อ",
  "已完成": "เสร็จสิ้น",
  "盘点中": "กำลังนับอยู่",
  "已取消": "ยกเลิก",
  "是": "ใช่",
  "否": "ไม่",
  "站外广告费": "ค่าโฆษณานอกเว็บไซต์",
  "站内广告费": "ค่าโฆษณาในไซต์",
  "台湾": "จีน ไต้หวัน",
  "平台调整": "การปรับเปลี่ยนของแพลตฟอร์ม",
  "空包费用": "ค่าซองว่าง",
  "商品缺件": "สินค้าขาดตอน",
  "改变主意": "เปลี่ยนใจ",
  "低销商品": "สินค้าขายอย่างช้า",
  "利润偏低": "กำไรต่ำ",
  "商品和描述不一致": "สินค้าไม่ตรงกับคำอธิบาย",
  "退货暴增": "การส่งคืนเพิ่มขึ้นอย่างรวดเร็ว",
  "销量暴跌": "ยอดขายลดลงอย่างรวดเร็ว",
  "销量暴涨": "ยอดขายเพิ่มขึ้นอย่างรวดเร็ว",
  "低回应时间": "เวลาตอบรับต่ำ",
  "逾期发货率过高": "อัตราค่าจ่ายที่ล่าช้าสูง",
  "商品损坏": "สินค้าเสีย",
  "其他": "อื่นๆ",
  "商品发错了": "ได้รับสินค้าผิด",
  "商品存在瑕疵": "Sản phẩm có khuyết điểm",
  "印尼": "Indonesia",
  "马来西亚": "Malaysia",
  "菲律宾": "Philippines",
  "新加坡": "Singapore",
  "泰国": "Thái Lan",
  "越南": "Việt Nam",
  "确定批量设置仓库":"ยืนยันการตั้งค่าคลังสินค้าแบบกลุ่ม",
  "仓库盘点时请勿关闭当前窗口，盘点完成后可关闭窗口":"กรุณาอย่าปิดหน้าต่างปัจจุบันในระหว่างการตรวจสอบสินค้าในคลังสินค้า คุณสามารถปิดหน้าต่างหลังจากการตรวจสอบสินค้าเสร็จสิ้นได้",
  "创建盘点任务时请勿关闭当前窗口，创建完成后可关闭窗口":"กรุณาอย่าปิดหน้าต่างปัจจุบันขณะสร้างงานตรวจสอบสินค้า คุณสามารถปิดหน้าต่างหลังจากการสร้างเสร็จสิ้นได้",
  "正在导入数据":"กำลังนำเข้าข้อมูล",
  "设置全部页商品仓库":"ตั้งค่าคลังสินค้าสำหรับทุกหน้าสินค้า",
  "设置已选商品仓库":"ตั้งค่าคลังสินค้าสำหรับสินค้าที่เลือก",
  "印度尼西亚":"อินโดนีเซีย",
  "当前账号下无可新增子账号的额度，请升级版本或删除无用的子账号空出名额后再次尝试。":"ไม่มีโควต้าที่ใช้งานได้สำหรับการเพิ่มบัญชีย่อยภายใต้บัญชีปัจจุบัน โปรดอัปเกรดเวอร์ชันของคุณหรือลบบัญชีย่อยที่ไม่ได้ใช้งานเพื่อทำให้มีพื้นที่และลองอีกครั้ง",
  "佣金计算公式":"สูตรคำนวณคอมมิชชั่น",
  "由于Lazada、TikTok平台佣金在订单完成时才结算，此处需要设置一个预估的佣金比例，用于订单未回款时利润的估算。":"เนื่องจากแพลตฟอร์ม Lazada และ TikTok จะชำระค่าคอมมิชชั่นเมื่อการสั่งซื้อเสร็จสิ้นเท่านั้น ดังนั้นคุณจำเป็นต้องตั้งอัตราค่าคอมมิชชั่นโดยประมาณที่นี่เพื่อประมาณผลกำไรเมื่อการสั่งซื้อยังไม่ได้รับการชำระเงินกลับ",
  "物流管理":"การจัดการโลจิสติกส์",
  "运费正常":"ค่าขนส่งปกติ",
  "运费亏损":"ค่าขนส่งขาดทุน",
  "运费盈余":"ค่าขนส่งเหลือซื้อ",
  "可按物流方式分别设置揽收方式":"วิธีรับสินค้าตามวิธีการขนส่ง",
  "待处理": "รอดำเนินการ",
  "待打单": "กำลังดำเนินการ",
  "待揽收": "big seller",
  "运输中": "อยู่ระหว่างการขนส่ง",
  "已妥投": "จัดส่งแล้ว",
  "运输失败": "การจัดส่งล้มเหลว",
  "未审核": "ยังไม่ได้ตรวจสอบ",
  "审核中": "อยู่ระหว่างตรวจสอบ",
  "审核失败": "big seller",
  "缺货": "สินค้าหมด",
  "title0": "เลือกร้านค้า",
  "title1": "เวลาปัจจุบัน:",
  "title2": "จีน",
  "title3": "คำแนะนำการตั้งค่าเริ่มต้น",
  "title4": "พับ",
  "title5": "ช่วยเหลือ",
  "title6": "ข้อมูลการขายสด",
  "title7": "เวลาอัปเดต",
  "title8": "ชื่อร้านค้า",
  "title9": "ภูมิภาคของแพลตฟอร์ม",
  "title10": "เมื่อวาน",
  "title11": "การวิเคราะห์โฆษณาแบบเรียลไทม์",
  "title12": "ตัวชี้วัดรวม",
  "title13": "ช่วงเวลาปัจจุบัน:",
  "title14": "รอบวงจรเดือนต่อเดือน:",
  "title15": "ค่าความแตกต่างระหว่างเดือน:",
  "title16": "อัตราส่วนลูกโซ่",
  "title17": "การจัดอันดับการขายสินค้า",
  "title18": "อันดับการขายของ",
  "title19": "อันดับกำไร",
  "title20": "อันดับสินค้าที่คืน",
  "title21": "อันดับ",
  "title22": "การวิเคราะห์",
  "title23": "สินค้า",
  "title24": "ยอดขายที่มีผล",
  "title25": "จำนวนการขายที่มีผล",
  "title26": "ได้กำไร/ขาดทุน",
  "title27": "อัตรากำไร",
  "title28": "จำนวนสินค้าที่คืน",
  "title29": "อัตราการคืนสินค้า",
  "title30": "การวิเคราะห์หลังการขาย",
  "title31": "ย้อนหลัง 7 วัน",
  "title32": "การคืนเงิน",
  "title33": "จำนวนคำสั่งซื้อที่คืนเงิน",
  "title34": "จำนวนคำสั่งซื้อที่ยกเลิก",
  "title35": "อัตราการยกเลิกใบสั่งซื้อ",
  "title36": "การเตือนอัจฉริยะ",
  "title37": "ยอดขายเพิ่มขึ้นอย่างรวดเร็ว",
  "title38": "ยอดขายลดลงอย่างรวดเร็ว",
  "title39": "สินค้าขาดทุน",
  "title40": "สินค้าขายอย่างช้า",
  "title41": "กำไรต่ำ",
  "title42": "การส่งคืนเพิ่มขึ้นอย่างรวดเร็ว",
  "title43": "การเตือนสถานะร้าน",
  "title44": "รีวิวกลางหรือลบล่าง",
  "title45": "โปรโมชั่นสิ้นสุด",
  "title46": "การลบผลิตภัณฑ์ที่ละเมิด",
  "title47": "เวลาตอบรับต่ำ",
  "title48": "อัตราค่าจ่ายที่ล่าช้าสูง",
  "title49": "สถานะร้าน",
  "title50": "ร้านที่ดีที่สุด",
  "title51": "ร้านที่ดี",
  "title52": "ร้านค้าต้องปรับปรุง",
  "title53": "ต่ำกว่ามาตรฐาน",
  "title54": "ศูนย์ประกาศ",
  "title55": "ระบบ",
  "title56": "การอัปグ레이ด ",
  "title57": "ข้อเตือน",
  "title58": "福建象岸科技有限公司",
  "title59": "闽",
  "title60": "备2023007214号",
  "title61": "อาทิตย์",
  "title62": "จันทร์",
  "title63": "อังคาร",
  "title64": "พุธ",
  "title65": "พฤหัสบดี",
  "title66": "ศุกร์",
  "title67": "เสาร์",
  "title68": "เชื่อมต่อร้านค้า",
  "title69": "ให้สิทธิ์ร้านของคุณเข้าสู่ระบบเพื่อการปรับปรุงรายการสินค้า, รายการสั่งซื้อ, และอื่น ๆ ที่ราบเรียบแบบสามารถทำได้",
  "title70": "ยกเลิกการให้สิทธิ",
  "title71": "การจัดการสินค้า",
  "title72": "รักษาข้อมูลสินค้าท้องถิ่นในระบบเพื่อการวิเคราะห์กำไร, ต้นทุนและข้อมูลอื่น ๆ",
  "title73": "ไปที่การตั้งค่า",
  "title74": "การจับคู่สินค้า",
  "title75": "จับคู่สินค้าท้องถิ่นกับแพลตฟอร์มออนไลน์เพื่อผสานเข้าด้วยกันโดยไม่มีช่องว่าง",
  "title76": "จับคู่",
  "title77": "การจัดการคลังสินค้า",
  "title78": "เริ่มต้นสต็อกเพื่อการจัดซื้อ, เติมสินค้า, การจัดส่ง, ฯลฯ ต่อไป",
  "title79": "ตั้งค่าบัญชีย่อย",
  "title80": "กำหนดสิทธิและการดำเนินงานสำหรับบทบาทต่าง ๆ ในระบบของบริษัทของคุณ",
  "title81": "การติดตั้งปลั๊กอิน",
  "title82": "ช่วยในการรับข้อมูลที่ไม่ได้มาจาก API, ข้อมูลเกี่ยวกับการโฆษณาและข้อมูลเกี่ยวกับผลการทำงานของสินค้า",
  "title83": "ไปที่การติดตั้ง",
  "title84": "ข้อมูลการเงิน",
  "title85": "วิเคราะห์ข้อมูลดำเนินการเช่นร้านค้า สินค้า รายการสั่งซื้อ และสร้างและดาวน์โหลดข้อมูลรายงานโดยอัตโนมัติ",
  "title86": "รายละเอียด",
  "title88": "คำสั่งซื้อที่มีผล",
  "title89": "การขายโฆษณา",
  "title90": "ค่าโฆษณา",
  "title91": "คําสั่งแทรก",
  "title92": "อัตราการคลิกโฆษณา",
  "title93": "วันนี้",
  "title94": "วันเดียวกันในสัปดาห์ที่แล้ว",
  "title95": "ตามคำสั่งซื้อ",
  "title96": "7 วัน",
  "title97": "30 วัน",
  "title98": "เลือกอย่างน้อยหนึ่งตัวบ่งชี้",
  "title99": "领取阿里云通用云产品1888优惠券",
  "title100": "领取腾讯云通用云产品2860优惠券",
  "title101": "阿里云服务器折扣区",
  "title102": "点我进入",
  "title103": "腾讯云服务器秒杀区",
  "title104": "云产品通用红包，可叠加官网常规优惠使用。",
  "title105": "仅限新用户",
  "title106": "芋道后台管理框架",
  "title107": "一直想做一款后台管理系统，看了很多优秀的开源项目但是发现没有合适自己的。于是利用空闲休息时间开始自己写一套后台系统。如此有了芋道管理系统。，她可以用于所有的",
  "title108": "应用程序，如网站管理后台，网站会员中心，",
  "title109": "等等，当然，您也可以对她进行深度定制，以做出更强系统。所有前端后台代码封装过后十分精简易上手，出错概率低。同时支持移动客户端访问。系统会陆续更新一些实用功能。",
  "title110": "当前版本",
  "title111": "免费开源",
  "title112": "访问码云",
  "title113": "访问主页",
  "title114": "技术选型",
  "title115": "后端技术",
  "title116": "前端技术",
  "title117": "联系信息",
  "title118": "官网：",
  "title119": "群：",
  "title120": "满937441",
  "title121": "满887144332",
  "title122": "满180251782",
  "title123": "微信：",
  "title124": "芋道",
  "title125": "支付宝：",
  "title126": "支付宝信息",
  "title127": "更新日志",
  "title128": "新增缓存监控功能",
  "title129": "支持主题风格配置",
  "title130": "修复多级菜单之间切换无法缓存的问题",
  "title131": "多级菜单自动配置组件",
  "title132": "代码生成预览支持高亮显示",
  "title133": "支持",
  "title134": "请求映射",
  "title135": "参数",
  "title136": "删除用户和角色解绑关联",
  "title137": "去除用户手机邮箱部门必填验证",
  "title138": "支持注解",
  "title139": "对齐方式",
  "title140": "支持导入",
  "title141": "型数据",
  "title142": "优化头像样式，鼠标移入悬停遮罩",
  "title143": "代码生成预览提供滚动机制",
  "title144": "代码生成删除多余的数字",
  "title145": "类型",
  "title146": "修正转换字符串的目标字符集属性",
  "title147": "回显数据字典防止空值报错",
  "title148": "日志记录增加过滤多文件场景",
  "title149": "修改缓存",
  "title150": "方法可能导致嵌套的问题",
  "title151": "移除前端一些多余的依赖",
  "title152": "防止安全扫描",
  "title153": "出现的风险提示",
  "title154": "修改",
  "title155": "为",
  "title156": "到最新版本2",
  "title157": "到最新版本4",
  "title158": "到最新版本",
  "title159": "到最新版本1",
  "title160": "到最新版本0",
  "title161": "到最新版本3",
  "title162": "到版本4",
  "title163": "到最新版本6",
  "title164": "到最新版本5",
  "title165": "到最新版本7",
  "title166": "到最新版本10",
  "title167": "其他细节优化",
  "title168": "阻止任意文件下载漏洞",
  "title169": "代码生成支持上传控件",
  "title170": "新增图片上传组件",
  "title171": "调整默认首页",
  "title172": "配置支持分隔符",
  "title173": "权限信息调整",
  "title174": "调整",
  "title175": "默认时间",
  "title176": "解决代码生成没有",
  "title177": "类型的问题",
  "title178": "到最新版1",
  "title179": "版本到2",
  "title180": "提升安全性",
  "title181": "到版本0",
  "title182": "避免",
  "title183": "图标乱码",
  "title184": "代码生成支持同步数据库",
  "title185": "代码生成支持富文本控件",
  "title186": "代码生成页面时不忽略",
  "title187": "属性",
  "title188": "代码生成添加",
  "title189": "必填选项",
  "title190": "导出类型",
  "title191": "支持精度浮点类型",
  "title192": "ส่งออก",
  "title193": "优化获取值，防止",
  "title194": "方法不规范",
  "title195": "注解支持自动统计数据总和",
  "title196": "注解支持设置",
  "title197": "精度",
  "title198": "舍入规则",
  "title199": "เมนู",
  "title200": "数据权限新增（展开",
  "title201": "ย่อ",
  "title202": "เลือกทั้งหมด",
  "title203": "ไม่เลือกทั้งหมด",
  "title204": "父子联动）",
  "title205": "允许用户分配到部门父节点",
  "title206": "菜单新增是否缓存",
  "title207": "表格操作列间距调整",
  "title208": "限制系统内置参数不允许删除",
  "title209": "富文本组件优化，支持自定义高度",
  "title210": "图片冲突问题",
  "title211": "富文本工具栏样式对齐",
  "title212": "นำเข้า",
  "title213": "整形值校验优化",
  "title214": "修复页签关闭所有时固定标签路由不刷新问题",
  "title215": "表单构建布局型组件新增按钮",
  "title216": "左侧菜单文字过长显示省略号",
  "title217": "修正根节点为子部门时，树状结构显示问题",
  "title218": "修正调用目标字符串最大长度",
  "title219": "修正菜单提示信息错误",
  "title220": "修正定时任务执行一次权限标识",
  "title221": "修正数据库字符串类型",
  "title222": "优化递归子节点",
  "title223": "优化数据权限判断",
  "title224": "表格工具栏右侧添加刷新",
  "title225": "显隐查询组件",
  "title226": "后端支持",
  "title227": "跨域请求",
  "title228": "代码生成支持选择上级菜单",
  "title229": "代码生成支持自定义路径",
  "title230": "代码生成支持复选框",
  "title231": "导出导入支持",
  "title232": "字典类型",
  "title233": "支持分割字符串组内容",
  "title234": "验证码类型支持（数组计算、字符验证）",
  "title235": "版本到4",
  "title236": "表单类型为",
  "title237": "设置整形默认值",
  "title238": "代码生成器默认",
  "title239": "路径与默认",
  "title240": "路径不一致",
  "title241": "优化防重复提交拦截器",
  "title242": "优化上级菜单不能选择自己",
  "title243": "修复角色的权限分配后，未实时生效问题",
  "title244": "修复在线用户日志记录类型",
  "title245": "修复富文本空格和缩进保存后不生效问题",
  "title246": "修复在线用户判断逻辑",
  "title247": "唯一限制条件只返回单条数据",
  "title248": "添加获取当前的环境配置方法",
  "title249": "超时登录后页面跳转到首页",
  "title250": "全局异常状态汉化拦截处理",
  "title251": "过滤器改为将",
  "title252": "转义",
  "title253": "检查字符支持小数点",
  "title254": "降级改成异常提醒",
  "title255": "单应用调整为多模块项目",
  "title256": "ลบ",
  "title257": "提高编译速度。",
  "title258": "新增菜单默认主类目",
  "title259": "编码文件名修改为",
  "title260": "วิธี",
  "title261": "定时任务",
  "title262": "表达式验证",
  "title263": "角色权限修改时已有权限未自动勾选异常修复",
  "title264": "防止切换权限用户后登录出现404",
  "title265": "导出排序",
  "title266": "创建用户不允许选择超级管理员角色",
  "title267": "修复代码生成导入表结构出现异常页面不提醒问题",
  "title268": "修复代码生成点击多次表修改数据不变化的问题",
  "title269": "修复头像上传成功二次打开无法改变裁剪框大小和位置问题",
  "title270": "修复布局为",
  "title271": "者",
  "title272": "用户表单显示错位问题",
  "title273": "修复热部署导致的强换异常问题",
  "title274": "修改用户管理复选框宽度，防止部分浏览器出现省略号",
  "title275": "工具，清除",
  "title276": "特殊字符，防止",
  "title277": "注入攻击",
  "title278": "生成",
  "title279": "如果是浮点型",
  "title280": "统一用",
  "title281": "定时任务调整",
  "title282": "防止部署出现错位",
  "title283": "调整表头固定列默认样式",
  "title284": "代码生成模板调整，字段为",
  "title285": "并且必填则加空串条件",
  "title286": "代码生成字典",
  "title287": "使用",
  "title288": "修复",
  "title289": "不可",
  "title290": "为0的问题",
  "title291": "查询返回增加",
  "title292": "升序排序",
  "title293": "修正岗位导出权限注解",
  "title294": "禁止加密密文返回前端",
  "title295": "修复代码生成页面中的查询条件创建时间未生效的问题",
  "title296": "修复首页搜索菜单外链无法点击跳转问题",
  "title297": "修复菜单管理选择图标，",
  "title298": "删除时不过滤数据",
  "title299": "用户管理部门分支节点不可检查",
  "title300": "显示计数",
  "title301": "数据范围过滤属性调整",
  "title302": "修复高危安全漏洞",
  "title303": "启动默认打开浏览器",
  "title304": "使用默认",
  "title305": "报错优化",
  "title306": "当",
  "title307": "滚动关闭右键菜单",
  "title308": "字典管理添加缓存读取",
  "title309": "参数管理支持缓存操作",
  "title310": "支持一级菜单（和主页同级）在",
  "title311": "区域显示",
  "title312": "限制外链地址必须以",
  "title313": "开头",
  "title314": "主题颜色与",
  "title315": "全局",
  "title316": "同步",
  "title317": "修改数据源类型优先级，先根据方法，再根据类",
  "title318": "支持是否需要设置",
  "title319": "属性，自定义返回码消息。",
  "title320": "请求前缀加入配置。",
  "title321": "登录地点设置内容过长则隐藏显示",
  "title322": "修复定时任务执行一次按钮后不提示消息问题",
  "title323": "修改上级部门（选择项排除本身和下级）",
  "title324": "通用",
  "title325": "发送方法增加参数",
  "title326": "编码类型",
  "title327": "更换",
  "title328": "地址查询接口",
  "title329": "修复页签变量",
  "title330": "添加校验部门包含未停用的子部门",
  "title331": "修改定时任务详情下次执行时间日期显示错误",
  "title332": "角色管理查询设置默认排序字段",
  "title333": "添加",
  "title334": "参数控制是否启用",
  "title335": "只对",
  "title336": "类型请求构建可重复读取",
  "title337": "的",
  "title338": "修改代码生成字典字段",
  "title339": "类型没有自动选中问题",
  "title340": "用户名取值修正",
  "title341": "表格树模板去掉多余的",
  "title342": "代码生成序号修正",
  "title343": "全屏情况下不调整上外边距",
  "title344": "代码生成",
  "title345": "字段添加默认格式",
  "title346": "用户管理角色选择权限控制",
  "title347": "修复路由懒加载报错问题",
  "title348": "模板",
  "title349": "添加菜单状态",
  "title350": "设置用户名称不能修改",
  "title351": "属性，防止",
  "title352": "遮罩",
  "title353": "菜单区分状态和显示隐藏功能",
  "title354": "修复安全加固",
  "title355": "修复代码生成如果选择字典类型缺失逗号问题",
  "title356": "登录请求",
  "title357": "更换为",
  "title358": "防止暴露",
  "title359": "日志返回时间格式处理",
  "title360": "控制允许拖动的元素",
  "title361": "布局设置点击扩大范围",
  "title362": "代码生成列属性排序查询",
  "title363": "代码生成列支持拖动排序",
  "title364": "修复时间格式不支持",
  "title365": "问题",
  "title366": "表单构建添加父级",
  "title367": "防止冲突",
  "title368": "定时任务并发属性修正",
  "title369": "角色禁用",
  "title370": "菜单隐藏不查询权限",
  "title371": "系统监控新增定时任务功能",
  "title372": "添加一个打包",
  "title373": "工程",
  "title374": "修复页签鼠标滚轮按下的时候，可以关闭不可关闭的",
  "title375": "修复点击退出登录有时会无提示问题",
  "title376": "修复防重复提交注解无效问题",
  "title377": "修复通知公告批量删除异常问题",
  "title378": "添加菜单时路由地址必填限制",
  "title379": "代码生成字段描述可编辑",
  "title380": "修复用户修改个人信息导致缓存不过期问题",
  "title381": "个人信息创建时间获取正确属性值",
  "title382": "操作日志详细显示正确类型",
  "title383": "导入表单击行数据时选中对应的复选框",
  "title384": "批量替换表前缀逻辑调整",
  "title385": "固定重定向路径表达式",
  "title386": "操作日志排序调整",
  "title387": "切换侧边栏或者缩放窗口显示",
  "title388": "新增表单构建",
  "title389": "代码生成支持树表结构",
  "title390": "新增用户导入",
  "title391": "修复动态加载路由页面刷新问题",
  "title392": "修复地址开关无效问题",
  "title393": "汉化错误提示页面",
  "title394": "代码生成已知问题修改",
  "title395": "修复多数据源下配置关闭出现异常处理",
  "title396": "过滤器，用于去除",
  "title397": "漏洞隐患",
  "title398": "修复上传头像控制台出现异常",
  "title399": "修改用户管理分页不正确的问题",
  "title400": "修复验证码记录提示错误",
  "title401": "缺少",
  "title402": "引用",
  "title403": "修复表格时间为空出现的异常",
  "title404": "日期反序列化时区配置",
  "title405": "调整根据用户权限加载菜单数据树形结构",
  "title406": "调整成功登录不恢复按钮，防止多次点击",
  "title407": "修改用户个人资料同步缓存信息",
  "title408": "修复页面同时出现",
  "title409": "和",
  "title410": "不显示处理",
  "title411": "修复在角色管理页修改菜单权限偶尔未选中问题",
  "title412": "配置文件新增",
  "title413": "密码属性",
  "title414": "การตั้งค่า",
  "title415": "全局的配置文件",
  "title416": "新增代码生成",
  "title417": "เพิ่ม",
  "title418": "注解，防止重复提交",
  "title419": "新增菜单主目录添加",
  "title420": "删除操作",
  "title421": "日志记录过滤特殊对象，防止转换异常",
  "title422": "修改代码生成路由脚本错误",
  "title423": "用户上传头像实时同步缓存，无需重新登录",
  "title424": "调整切换页签后不重新加载数据",
  "title425": "实现参数的前端加密",
  "title426": "系统退出删除用户缓存记录",
  "title427": "新增在线用户管理",
  "title428": "新增按钮组功能实现（批量删除、导出、清空）",
  "title429": "新增查询条件重置按钮",
  "title430": "การกำหนดค่า",
  "title431": "新增后端参数校验",
  "title432": "修复字典管理页面的日期查询异常",
  "title433": "修改时间函数命名防止冲突",
  "title434": "去除菜单上级校验，默认为顶级",
  "title435": "修复用户密码无法修改问题",
  "title436": "修复菜单类型为按钮时不显示权限标识",
  "title437": "芋道前后端分离系统正式发布",
  "title438": "捐赠支持",
  "title439": "你可以请作者喝杯咖啡表示鼓励",
  "title440": "ยินดีต้อนรับสู่ xinjian",
  "title441": "ลงทะเบียน",
  "title442": "ลืมรหัสผ่าน",
  "title443": "ผู้เช่า",
  "title444": "ป้อนหมายเลขโทรศัพท์",
  "title445": "ชื่อผู้ใช้",
  "title446": "รหัสผ่าน",
  "title447": "กำลังเข้าสู่ระบบ",
  "title448": "录",
  "title449": "中",
  "title450": "เข้าสู่ระบบอัตโนมัติในหลายวัน",
  "title451": "ลืมรหัสผ่าน ?",
  "title452": "ยังไม่มีบัญชีหรือไม่?",
  "title453": "สมัคร",
  "title454": "โปรดป้อนหมายเลขโทรศัพท์",
  "title455": "โปรดป้อนรหัสผ่าน",
  "title456": "โปรดป้อนรหัสยืนยันกราฟิก",
  "title457": "รับรหัสยืนยัน",
  "title458": "ส่งอีกครั้งหลังจากวินาที",
  "title459": "อ่านและยอมรับ",
  "title460": "เงื่อนไขการให้บริการ",
  "title461": "มีบัญชีอยู่แล้ว？",
  "title462": "ไปที่หน้าเข้าสู่ระบบ",
  "title463": "โปรดป้อนรหัสยืนยันกราฟิก",
  "title464": "ส่ง",
  "title465": "ไม่ต้องกู้คืน",
  "title466": "โปรดป้อนรหัสผ่าน",
  "title467": "กรุณาใส่รหัสผ่านอีกครั้ง",
  "title468": "รีเซ็ตรหัสผ่าน",
  "title469": "เสร็จสิ้น",
  "title470": "เริ่มต้นใช้",
  "title471": "กลับไปที่ขั้นตอนก่อนหน้า",
  "title472": "เข้าสู่ระบบ",
  "title473": "ยอมรับและดำเนินต่อ",
  "title474": "ตัวอักษร",
  "title475": "ตัวอักษรจะต้องมีตัวพิมพ์ใหญ่",
  "title476": "ต้องมีอย่างน้อย 2 ประเภทของตัวอักษร เลขและสัญลักษณ์อย่างน้อย 2 ประเภทต้องอยู่ในรหัสผ่าน",
  "title477": "รหัสผ่านต้องไม่เหมือนกับชื่อผู้ใช้",
  "title478": "โปรดป้อนรหัสผ่านอีกครั้ง",
  "title479": "รหัสผ่านที่ป้อนไม่ตรงกัน",
  "title480": "การค้าออนไลน์ข้ามชาติในเอเชียตะวันออกเฉียงใต้",
  "title481": "การดำเนินงานและการวิเคราะห์ข้อมูลที่ปรับให้ดีและแม่นยำ",
  "title482": "โปรดป้อนรหัสป้องกันโรบอท",
  "title483": "โปรดกรอกหมายเลขโทรศัพท์",
  "title484": "หมายเลขโทรศัพท์ควรขึ้นต้นด้วย 1 และประกอบด้วยตัวเลข 11 หลัก",
  "title485": "ผู้เช่าต้องไม่ว่างเปล่า",
  "title486": "อินโดนีเซีย",
  "title487": "ฟิลิปปินส์",
  "title488": "ไทย",
  "title489": "เวียดนาม",
  "title490": "มาเลเซีย",
  "title491": "สิงคโปร์",
  "title492": "จีน ไต้หวัน",
  "title493": "บัญชีไม่มีอยู่",
  "title494": "กรุณาอ่านและยอมรับข้อตกลง",
  "title495": "รับรหัสยืนยันสำเร็จ",
  "title496": "การเปลี่ยนแปลงสำเร็จ โปรดเข้าสู่ระบบใหม่",
  "title497": "ลงทะเบียนสำเร็จ โปรดเข้าสู่ระบบ",
  "title498": "Xinjian",
  "title499": "三方授权（",
  "title500": "此第三方应用请求获得以下权限：",
  "title501": "同意授权",
  "title502": "授权",
  "title503": "权",
  "title504": "拒绝",
  "title505": "自动授权未通过！",
  "title506": "访问你的个人信息",
  "title507": "修改你的个人信息",
  "title508": "ผูกบัญชี",
  "title509": "บัญชี",
  "title510": "จำรหัสผ่าน",
  "title511": "ชื่อผู้ใช้ต้องไม่ว่างเปล่า",
  "title512": "รหัสผ่านต้องไม่ว่างเปล่า",
  "title513": "ฐานความรู้",
  "title514": "จำนวนผู้เยี่ยมชมสินค้า",
  "title515": "ข้อความ",
  "title516": "จำนวนเงิน",
  "title517": "คำสั่งซื้อ",
  "title518": "กลับ",
  "title519": "ข้อผิดพลาด",
  "title520": "คุณไม่มีสิทธิ์ในการเข้าถึง!",
  "title521": "ขอโทษคุณไม่มีสิทธิ์ในการเข้าถึง โปรดหลีกเลี่ยงการดำเนินการที่ผิดกฎหมาย! คุณสามารถกลับไปที่หน้าหลักได้",
  "title522": "กลับไปที่หน้าหลัก",
  "title523": "ฟังก์ชั่นนี้ต้องเปิดใช้งาน",
  "title524": "แพ็คเกจ",
  "title525": "เพื่อใช้งาน โปรดดำเนินไปที่",
  "title526": "ดูเพิ่มเติม",
  "title527": "หรือ",
  "title528": "ติดต่อฝ่ายบริการลูกค้า",
  "title529": "ขออภัย หน้าที่คุณกำลังหาไม่มีอยู่ กรุณาตรวจสอบ",
  "title530": "แล้วลองกดปุ่มรีเฟรชบนเบราว์เซอร์ของคุณหรือลองค้นหาเนื้อหาอื่นในแอปพลิเคชันของเรา",
  "title531": "กลับสู่หน้า",
  "title532": "หน้าเว็บไม่พบ！",
  "title533": "รายการโปรดของฉัน",
  "title534": "คำถามและคำตอบ",
  "title535": "答",
  "title536": "คัดลอก",
  "title537": "ยกเลิกรายการโปรด",
  "title538": "การสนทนาอิสระ",
  "title539": "การสนทนาแม่แบบ",
  "title540": "การสนทนาเอกสาร",
  "title541": "คัดลอกสำเร็จ",
  "title542": "ยกเลิกรายการโปรดสำเร็จ",
  "title543": "การสนทนาใหม่",
  "title544": "ล้างทุกเซสชัน",
  "title545": "การสนทนาใหม่",
  "title546": "กรุณาแก้ไขใหม่อีกครั้ง",
  "title547": "การแก้ไขสำเร็จ",
  "title548": "ยืนยันการลบการสนทนานี้",
  "title549": "คำเตือน",
  "title550": "การลบสำเร็จ！",
  "title551": "ลบทุกเซสชันหรือไม่",
  "title552": "Cơ sở kiến thức thông minh",
  "title553": "ปฏิสัมพันธ์กับเอกสาร เร็วๆ ได้ในการสกัด ระบุ และสรุปข้อมูลที่คุณต้องการ",
  "title554": "สร้างฐานความรู้ใหม่",
  "title555": "สร้างของคุณเอง",
  "title556": "ตั้งค่าฐานความรู้เป็นแม่แบบ แยกวิเคราะห์และเข้าใจเอกสารทางเทคนิคที่ซับซ้อน บันทึกคำถามที่ถามบ่อย หลีกเลี่ยงการถามที่ซ้ำซาก และเพิ่มประสิทธิภาพในการทำงาน",
  "title557": "แก้ไขเทมเพลต",
  "title558": "ลบเทมเพลต",
  "title559": "ยืนยันลบเทมเพลตนี้?",
  "title560": "การลบสำเร็จ",
  "title561": "หมวดหมู่",
  "title562": "โปรดใส่หมวดหมู่",
  "title563": "ประเภทเทมเพลต",
  "title564": "โปรดเลือกประเภทเทมเพลต",
  "title565": "ชื่อเทมเพลต",
  "title566": "โปรดใส่ชื่อเทมเพลต",
  "title567": "ค้นหา",
  "title568": "รีเซ็ต",
  "title569": "คีย์หลัก",
  "title570": "ผู้ สร้ าง",
  "title571": "คำอธิบายเทมเพลต",
  "title572": "เนื้อหาคำถาม",
  "title573": "ตัวแปรคำถาม",
  "title574": "เวลาสร้าง",
  "title575": "ดำเนินการ",
  "title576": "โปรดป้อนผู้สร้าง",
  "title577": "โปรดป้อนคำอธิบายเทมเพลต",
  "title578": "โปรดป้อนเนื้อหาคำถาม",
  "title579": "ตัวแปรคำถาม",
  "title580": "โปรดป้อนตัวแปรคำถาม โดยใช้",
  "title581": "ตารางถูกจัดเก็บ แต่ละออบเจกต์ในตารางประกอบด้วยฟิลด์ต่อไปนี้: ชื่อตัวแปร",
  "title582": "สำเนาคำอธิบายที่สอดคล้อง",
  "title583": "บังคับ",
  "title584": "ค่าเริ่มต้น",
  "title585": "ยืนยัน",
  "title586": "定",
  "title587": "ยกเลิก",
  "title588": "",
  "title589": "การแก้ไขเรียบร้อยแล้ว",
  "title590": "เพิ่มสำเร็จแล้ว",
  "title591": "ยืนยันการลบ",
  "title592": "หมายเลขเทมเพลตคือ",
  "title593": "รายการข้อมูลของ",
  "title594": "ยืนยันการส่งออกทั้งหมด",
  "title595": "รายการข้อมูลเทมเพลต",
  "title596": "แต่เฉพาะ",
  "title597": "แก้ไขฐานความรู้",
  "title598": "林珍",
  "title599": "แก้ไขคำถาม",
  "title600": "ปิด",
  "title601": "ยินดีต้อนรับสู่ของฉัน",
  "title602": "频道！今天的主题是如何在生活中实现健康的生活方式。",
  "title603": "首先，一个健康的生活方式需要有一个良好的饮食习惯。我们应该尽量食用新鲜、天然的食物，避免摄入过多的加工食品和糖分。另外，我们也需要保持适当的饮食量，不要过量进食或过度节食。",
  "title604": "其次，适量的运动也是保持健康的重要一环。我们可以选择自己喜欢的运动方式，比如跑步、瑜伽、游泳等等。无论是室内还是户外运动，每天坚持30分钟以上的运动可以帮助我们保持身体健康，同时也有助于缓解压力和焦虑感。",
  "title605": "除了饮食和运动之外，我们还需要注意睡眠质量的保证。一个良好的睡眠环境可以帮助我们更好地入睡和保持深度睡眠状态。同时，规律的作息时间也是非常重要的，它可以帮助我们的身体建立一个健康的生物钟。",
  "title606": "最后，我们还可以通过一些放松的方式来缓解生活中的压力和疲劳感。比如听音乐、读书、冥想等等。这些活动可以帮助我们放松身心，让我们更加愉悦地度过每一天。",
  "title607": "以上就是我对如何在生活中实现健康生活方式的一些",
  "title608": "กำลังตอบ โปรดรอสักครู่",
  "title609": "โปรดป้อนเนื้อหาคำถามของคุณ (ขึ้นบรรทัด:",
  "title610": "กด Enter เพื่อส่ง",
  "title611": "ใช้จุด 1)",
  "title612": "ส่ง",
  "title613": "ช่วยฉันเขียนชีวิต",
  "title614": "สคริปต์",
  "title615": "โปรดอธิบายปัญหา",
  "title616": "รูปภาพ",
  "title617": "โปรดใส่รูปภาพ",
  "title618": "ชื่อหมวดหมู่",
  "title619": "โปรดใส่ชื่อหมวดหมู่",
  "title620": "ระดับหมวดหมู่",
  "title621": "โปรดใส่ระดับหมวดหมู่",
  "title622": "ผู้ปกครอง",
  "title623": "โปรดใส่ผู้ปกครอง",
  "title624": "วันเริ่มต้น",
  "title625": "วันที่สิ้นสุด",
  "title626": "หมวดหมู่เทมเพลต",
  "title627": "หมายเลขหมวดหมู่เทมเพลตคือ",
  "title628": "รายการข้อมูลหมวดหมู่เทมเพลตคือ",
  "title629": "ไม่แน่ใจว่าจะเขียนอะไร? ลองแทรกตัวอย่าง",
  "title630": "เคลียร์ข้อมูล",
  "title631": "เริ่มสร้าง",
  "title632": "โปรดป้อนเนื้อหา",
  "title633": "ทั้งหมด",
  "title634": "บุ๊กมาร์คสำเร็จ",
  "title635": "สถานะการจัดทำโฆษณา",
  "title636": "สถานะการเก็บรวบรวม",
  "title637": "ความสมบูรณ์ของการเก็บรวบรวม",
  "title638": "เวลาการเก็บรวบรวมครั้งล่าสุด",
  "title639": "กรุณาอ้างอิงที่ 'ความสมบูรณ์ ในการเก็ บข้ อมูล' เพื่อกำหนดว่าข้อมูลโฆษณาของเดือนที่ผ่านมาได้ถูกสะสมเรียบร้อยแล้วหรือไม่ หากข้อมูลไม่สมบูรณ์ คุณสามารถตรวจสอบบันทึกการเก็บรวบรวมเพื่อข้อมูลเฉพาะ",
  "title640": "ดูคู่มือการดำเนินงาน",
  "title641": "ชื่อร้านค้า",
  "title642": "ยังไม่ได้รับ",
  "title643": "หยุดการโฆษณาชั่วคราว",
  "title644": "กำลังออกอากาศ",
  "title645": "กำลังรวบรวม",
  "title646": "กำลังปล่อย",
  "title647": "หยุดชั่วคราว",
  "title648": "ออฟไลน์",
  "title649": "เปิด",
  "title650": "ปิด",
  "title651": "ระยะเวลารวบรวม",
  "title652": "บันทึกรวบรวม",
  "title653": "โปรดทราบว่าระบบไม่ได้เก็บข้อมูลโฆษณาทั้งหมด หากต้องการข้อมูลที่สมบูรณ์โปรดเปิดใช้งานปลั๊กอินโฆษณาและดำเนินการเก็บรวบรวมใหม่",
  "title654": "ประสิทธิภาพสินค้า",
  "title655": "ข้อมูลโฆษณา",
  "title656": "วันที่",
  "title657": "สมบูรณ์",
  "title658": "ไม่สมบูรณ์",
  "title659": "ได้รับ",
  "title660": "ไม่มีโฆษณา",
  "title661": "เวลาสถิติ",
  "title662": "วิเคราะห์โฆษณา",
  "title663": " เรียงตาม",
  "title664": "ภูมิภาค",
  "title665": "แผนภูมิแนวโน้ม",
  "title666": "ค่าใช้จ่ายโฆษณาร้านค้า",
  "title667": "ยอดขายโฆษณาสินค้า",
  "title668": "สินค้า",
  "title669": "ประสิทธิภาพของแคมเปญโฆษณา",
  "title670": "การโฆษณา",
  "title671": "คลิกโฆษณา",
  "title672": "วัน",
  "title673": "สัปดาห์",
  "title674": "เดือน",
  "title675": "บราซิล",
  "title676": "เม็กซิโก",
  "title677": "อัตราการคลิก",
  "title678": "การดูโฆษณา",
  "title679": "จำนวนการดูโฆษณา",
  "title680": "วันนี้",
  "title681": "เมื่อวาน",
  "title682": "7 วันที่ผ่านมา",
  "title683": "15 วันที่ผ่านมา",
  "title684": "30 วัน7 วันที่ผ่านมา",
  "title685": "จำนวนคลิกโฆษณา",
  "title686": "เมื่อเทียบกับเมื่อวาน",
  "title687": "เมื่อเปรียบเทียบกับวันก่อนหน้า",
  "title688": "เปรียบเทียบกับก่อน",
  "title689": "วัน",
  "title690": "ประสิทธิภาพ",
  "title691": "ข้อมูลร้านค้า",
  "title692": "ข้อมูลสินค้า",
  "title693": "ข้อมูลการดำเนินงาน",
  "title694": "รายงานกำไรร้านค้า",
  "title695": "กำไรทางสถิติของร้าน เพื่อวิเคราะห์การดำเนินงานของร้าน",
  "title696": "รายงานกำไรและประสิทธิภาพ",
  "title697": "รายงานผลการดำเนินงานทางสถิติสำหรับพนักงานดำเนินงาน",
  "title698": "ตารางข้อมูลสินค้า",
  "title699": "ตัวชี้วัดหลักทางสถิติสำหรับเข้าใจประสิทธิภาพการดำเนินงานโดยรวมของสินค้า",
  "title700": "ตารางข้อมูลร้านค้า",
  "title701": "วิเคราะห์ข้อมูลการดำเนินงานของร้านเพื่อเข้าใจภาพรวมของประสิทธิภาพของร้าน",
  "title702": "เลือกภูมิภาค",
  "title703": "ช่วงเวลาปัจจุบัน",
  "title704": "ช่วงเวลาเปรียบเทียบ",
  "title705": "การเปรียบเทียบข้อมูลการขาย",
  "title706": "การเปรียบเทียบข้อมูลต้นทุน",
  "title707": "ข้อมูลรายละเอียดเปรียบเทียบ",
  "title708": "ข้อมูลส่งออกล่าสุด",
  "title709": "ชื่อรายงาน",
  "title710": "กำลังประมวลผล",
  "title711": "กำลังประมวลผล",
  "title712": "ข้อมูลการขาย",
  "title713": "ข้อมูลการขาย",
  "title714": "ข้อมูลต้นทุน",
  "title715": "หน่วย",
  "title716": "ต้นทุนสินค้า",
  "title717": "ค่าโฆษณา",
  "title718": "ค่าบรรจุภัณฑ์",
  "title719": "ค่าใช้จ่ายในการประเมิน",
  "title720": "ส่วนลดของผู้ขาย",
  "title721": "ค่าใช้จ่ายในการขนส่ง",
  "title722": "ค่าบริการของแพลตฟอร์ม",
  "title723": "ค่าบริการอื่น ๆ ของแพลทฟอร์ม",
  "title724": "เลือกอย่างน้อยหนึ่งร้าน",
  "title725": "รายบุคคล",
  "title726": "เปรียบเทียบระยะเวลาร้าน-",
  "title727": "ยืนยันการส่งออกภาพรวมของโครงสร้างต้นทุน",
  "title728": "ภาพรวมของโครงสร้างต้นทุน",
  "title729": "ยืนยันคำสั่งซื้อ",
  "title730": "รายละเอียดการสั่งซื้อ",
  "title731": "บริการสั่งซื้อ",
  "title732": "เดี่ยว",
  "title733": "ข้อมูลรีวิว",
  "title734": "ครั้ง",
  "title735": "จุด",
  "title736": "ซื้อ",
  "title737": "จำนวน",
  "title738": "ระยะเวลา",
  "title739": "ยอดรวมแพ็คเกจ",
  "title740": "จำนวนแพ็คเกจ",
  "title741": "ระยะเวลาแพ็คเกจ",
  "title742": "ใช้ได้ถึงวันที่",
  "title743": "หน่วย",
  "title744": "ฉันได้อ่านและยอมรับ",
  "title745": "ยอดเงินที่ต้องชำระ",
  "title746": "ฉันได้อ่านและยอมรับ",
  "title747": "เงื่อนไขการให้บริการของผู้ใช้",
  "title748": "ส่งคำสั่งซื้อ",
  "title749": "ชำระทันที",
  "title750": "ยอดเงินที่ต้องชำระ",
  "title751": "เวลาที่เหลือสำหรับการชำระเงิน",
  "title752": "คำสั่งที่ไม่ได้ชำระเงินจะถูกยกเลิกโดยอัตโนมัติหากหมดเวลา",
  "title753": "เปิดโทรศัพท์ของคุณ",
  "title754": "Alipay",
  "title755": "WeChat",
  "title756": "สแกนเพื่อดำเนินการชำระเงินต่อ",
  "title757": "วิธีการชำระเงิน: การโอนเงินผ่านธนาคาร (อย่าปิดหน้านี้ก่อนที่จะโอน คลิก 'การชำระเงินเสร็ จสมบูรณ์' หลังจากโอนเงินสำเร็จ)",
  "title758": "ชื่อบริษัทผู้รับเงิน:",
  "title759": "เลขบัญชีธนาคารผู้รับเงิน:",
  "title760": "ชื่อธนาคาร:",
  "title761": "ธนาคารก่อสร้างจีน บริษัท จำกัด สาขา Fuzhou Shida",
  "title762": "การชำระเงินเสร็จสมบูรณ์",
  "title763": "การชำระเงินสำเร็จ",
  "title764": "คุณสามารถดูได้ที่ [ศูนย์ส่วนตัว] - [คำสั่งของฉัน]",
  "title765": "เวลาที่คาดว่าจะเข้าบัญชีคือ 5-10 นาที โปรดรอสักครู่",
  "title766": "คุณสามารถดูได้ที่ [ศูนย์ส่วนตัว] - [คำสั่งของฉัน]",
  "title767": "หมายเลขคำสั่งซื้อ",
  "title768": "การชำระเงินผ่าน Alipay",
  "title769": "โอนเงินผ่านธนาคาร",
  "title770": "ภาพรวมการวิเคราะห์ผู้ใช้",
  "title771": "อัตราการซื้อซ้ำ",
  "title772": "เปรียบเทียบกับช่วงก่อนหน้า",
  "title773": "ประสิทธิภาพการขาย",
  "title774": "คำสั่งซื้อที่มีผล",
  "title776": "ยอดขายเฉลี่ยต่อลูกค้า",
  "title777": "ผู้ใช้ใหม่",
  "title778": "ผู้ใช้ที่กลับมาใช้บริการอีกครั้ง",
  "title779": "รายชื่อผู้ใช้",
  "title780": "ป้อนข้อมูลการค้นหา",
  "title781": "จำนวนเงินที่ผู้ซื้อจ่าย",
  "title782": "รายชื่อลูกค้า",
  "title783": "ข้อมูลผู้ซื้อ",
  "title784": "เวลาสั่งซื้อสุดท้าย",
  "title785": "บันทึกการซื้อ",
  "title786": "ผู้ใช้ [",
  "title787": "บันทึกสินค้า",
  "title788": "ข้อมูลสินค้า",
  "title790": "บันทึกคำสั่งซื้อ",
  "订单号": "หมายเลขคำสั่งซื้อ",
  "title792": "ประเภทของสินค้า",
  "title793": " เวลาสั่งซื้อ",
  "title794": "ผ่าน",
  "title795": "ค้นหาผู้ใช้",
  "title796": "ใช่",
  "title797": "ไม่",
  "title798": "15 วันที่ผ่านมา",
  "title799": "ผู้ใช้ทั้งหมด",
  "title800": "จำนวนคำสั่งซื้อที่ซ้ำ",
  "title801": "ย้อนหลัง 7 วัน",
  "title802": "วันที่",
  "title803": "ยืนยันการส่งออกรายการข้อมูลทั้งหมดหรือไม่?",
  "title804": "ข้อมูลการขายร้านค้า",
  "title805": "ข้อมูลรวม",
  "title806": "เปรียบเทียบเวลา",
  "title807": "เปรียบเทียบตัวชี้วัด",
  "title808": "ช่วงเวลาปัจจุบัน",
  "title809": "รอบเวลาต่อรอบเวลา",
  "title810": "ความแตกต่างระหว่างรอบต่อรอบ",
  "title811": "ต่อชั่วโมง",
  "title812": "สถิติตามเวลาหมายถึงการวิเคราะห์ประสิทธิภาพของข้อมูลประจำวัน 24 ชั่วโมงของร้าน",
  "title813": "โครงสร้างต้นทุนสินค้า",
  "title814": "ความเปรียบเทียบระหว่างต้นทุนและยอดขายที่มีประสิทธิภาพ",
  "title815": "รายงานสินค้าคงคลัง",
  "title816": "คลังสินค้าทั้งหมด",
  "title817": "คลังสินค้าบุคคลที่สาม",
  "title818": "คลังสินค้าตัวเอง",
  "title819": "มูลค่าสินค้าคงคลัง",
  "title820": "สถานะคลังสินค้า",
  "title821": "ระยะเวลา",
  "title822": "สัดส่วน",
  "title823": "การกระจายตำแหน่งของคำสั่งซื้อ",
  "title824": "กรุณาเลือกพื้นที่",
  "title825": "การคืนสินค้า",
  "title826": "อำเภอ",
  "title827": "คำสั่งซื้อที่มีผล",
  "title828": "จำนวนคำสั่งซื้อที่ส่งคืน",
  "title829": "สัดส่วนของจำนวนคำสั่งซื้อที่ถูกต้อง",
  "title830": "สัดส่วนของจำนวนคำสั่งซื้อที่ถูกส่งคืน",
  "title831": "สต็อกพร้อมขาย",
  "title832": "ล็อคแล้ว",
  "title833": "โปรโมชันที่จองไว้",
  "title834": "เกิน",
  "title835": "ตามร้านค้า",
  "title836": "ตามเว็บไซต์",
  "title837": "ตามภูมิภาค",
  "title838": "ยอดขายทั้งหมด",
  "title839": "ยอดขายรวม",
  "title840": "คำสั่งซื้อทั้งหมด",
  "title841": "ความสูญเสียจากการประเมิน",
  "title842": "จำนวนทั้งหมด",
  "title843": "มูลค่าสินค้าคงคลังทั้งหมด",
  "title844": "ต้นทุนสินค้าทั้งหมด",
  "title845": "ข้อมูลบัญชี",
  "title846": "ข้อมูลพื้นฐาน",
  "title847": "บันทึกการเข้าสู่ระบบ",
  "title848": "ข้อมูลการสั่งซื้อ",
  "title849": "แพ็กเกจของฉัน",
  "title850": "คำสั่งซื้อของฉัน",
  "title851": "บัญชีของฉัน",
  "title852": "บัญชี",
  "title853": "รหัสผ่าน",
  "title854": "ตั้งรหัสผ่าน",
  "title855": "ชื่อ",
  "title856": "โทรศัพท์",
  "title857": "เปลี่ยนโทรศัพท์",
  "title858": "เวลาเข้าสู่ระบบ",
  "title859": "เข้าสู่ระบบบัญชี",
  "title860": "บทบาทที่กำหนด",
  "title861": "โทรศัพท์",
  "title862": "ที่อยู่ในการเข้าสู่ระบบ",
  "title863": "โควต้าแพ็กเกจของคุณได้ถูกใช้ไปแล้ว โปรดซื้อแพ็กเกจใหม่เพื่อดำเนินการต่อ",
  "title864": "ซื้อทันที",
  "title865": "สถานะแพ็กเกจ",
  "title866": "รุ่นปัจจุบัน",
  "title867": "ระยะเวลาที่ใช้ได้",
  "title868": "จำนวนคงเหลือ:",
  "title869": "ซื้อแพ็กเกจ",
  "title870": "สิทธิ์พื้นฐาน",
  "title871": "จำนวนร้านค้า",
  "title872": "บัญชีย่อย",
  "title873": "คะแนน",
  "title874": "ชื่อแพ็กเกจ",
  "title875": "จำนวนคงเหลือของคำสั่งซื้อ",
  "title876": "เวลาที่เหลืออยู่",
  "title877": "เวลาที่สั่งซื้อ",
  "title878": "สถานะ",
  "title879": "หมายเหตุ",
  "title880": "สิทธิ์แพ็กเกจเติมน้ำมัน",
  "title881": "ประเภทแพ็กเกจเติมน้ำมัน",
  "title882": "สิ่งที่ซื้อ",
  "title883": "จำนวนแพ็กเกจเติมน้ำมัน",
  "title884": "จำนวนที่เหลือ",
  "title885": "จำนวนวันที่เหลือ",
  "title886": "ประเภทการสั่งซื้อ",
  "title887": "โปรดใส่หมายเลขคำสั่งซื้อ",
  "title888": "เวลาธุรกรรม",
  "title889": "เนื้อหาการสั่งซื้อ",
  "title890": "แพ็กเกจเติมน้ำมัน",
  "title891": "สถานะการชำระเงิน",
  "title892": "ชำระเงินทันที",
  "title893": "คำสั่งซื้อที่ยกเลิก",
  "title894": "โปรดติดต่อฝ่ายบริการลูกค้า",
  "title895": "เบอร์โทร",
  "title896": "เวลาชำระ",
  "title897": "การสั่งซื้อ",
  "title898": "ยังไม่ชำระ",
  "title899": "การชำระเงินสำเร็จ",
  "title900": "การปิดการชำระเงิน",
  "title901": "ยืนยันการยกเลิกคำสั่งซื้อ?",
  "title902": "ยกเลิกคำสั่งซื้อสำเร็จ",
  "title903": "อัปโหลดสำเร็จ",
  "title904": "เลือกสถานะ",
  "title905": "ตัวชี้วัด Store Health",
  "title906": "ร้านที่ไม่ดำเนินงานได้ดี",
  "title907": "คะแนนเฉลี่ยในไตรมาสนี้",
  "title908": "คะแนนโทษเฉลี่ยในไตรมาสนี้สำหรับร้านที่เลือกไว้ คะแนนที่สะสมไว้จะถูกเก็บไว้จนถึงสิ้นไตรมาส",
  "title909": "จะรีเซ็ตคะแนนในวันจันทร์แรกของแต่ละไตรมาส (มกราคม, เมษายน, กรกฎาคม, ตุลาคม) นำเมาส์ไปยังคะแนนเพื่อดูค่าเฉลี่ยของตัวชี้วัดที่สำคัญ",
  "title910": "ตัวชี้วัด",
  "title911": "ค่าเฉลี่ย",
  "title912": "คะแนน",
  "title913": "การละเมิดกฎการจัดสินค้า",
  "title914": "สินค้าที่ถูกลบหรือแบน",
  "title915": "จำนวนสินค้าที่ขายล่วงหน้า",
  "title916": "การละเมิดอื่น ๆ",
  "title917": "อัตราส่วนการสำเร็จของคำสั่งซื้อ",
  "title918": "อัตราส่วนคำสั่งซื้อที่ไม่สำเร็จ",
  "title919": "อัตราการส่งสินค้าช้า",
  "title920": "เวลาการจัดเตรียมพัสดุ",
  "title921": "การบริการลูกค้า & ความพึงพอใจของลูกค้า",
  "title922": "การบริการลูกค้า & ความพึงพอใจของลูกค้า",
  "title923": "ความคิดเห็นของผู้ซื้อ",
  "title924": "ค่าเฉลี่ยของตัวชี้วัดสำคัญ",
  "title925": "การแจ้งเตือนร้านค้า",
  "title926": "คะแนนความประพฤติปัจจุบัน",
  "title927": "ยอดเยี่ยม ร้านค้าของคุณไม่มีการแจ้งเตือน",
  "title928": "สถานะบัญชีร้านค้า",
  "title929": "การอนุญาตร้านค้าหมดอายุ โปรด",
  "title930": "อนุญาตอีกครั้ง",
  "title10930": "การเชื่อมต่อร้านค้าหมดอายุแล้ว โปรดเชื่อมต่ออีกครั้ง",
  "title931": "คะแนนความประพฤติในไตรมาสนี้",
  "title932": "ระดับคะแนนการแจ้งเตือนล่วงหน้า",
  "title933": "เมื่อทริกเกอร์คะแนนความประพฤติเป็น 1 การแจ้งเตือนล่วงหน้าจะถูกทริกเกอร์ขึ้นมา ซึ่งหมายถึง จะทริกเกอร์การแจ้งเตือนล่วงหน้าเมื่อคะแนนอยู่ 2 5 8 11 14",
  "title934": "ไม่มีการเตือน",
  "title935": "ระดับ",
  "title936": "การกระจายคะแนนเตือนโทษร้านค้า",
  "title937": "ไม่มีการเตือน",
  "title938": "ร้านของคุณกำลังอยู่ในสภาพที่ดีกับการลงโทษที่ไม่มี",
  "title939": "คะแนนความประพฤติ",
  "title940": "ระดับคะแนนความประพฤติ",
  "title941": "จำนวนวันเหลือคะแนนความประพฤติ",
  "title942": "ระดับคะแนนความประพฤติร้านค้า",
  "title943": "สถานการณ์ของร้านค้า",
  "title944": "อัปเดตข้อมูล",
  "title945": "การตั้งค่ารายการ",
  "title946": "ระดับการประเมินผลการดำเนินงานโดยรวมของร้านค้า ยอดเยี่ยม ดี ควรปรับปรุง ต่ำกว่ามาตรฐาน",
  "title947": "คะแนนความประพฤติในไตรมาสนี้ (คะแนน)",
  "title948": "คะแนนความประพฤติจะรีเซ็ตทุกวันจันทร์แรกของเดือนมกราคม เมษายน กรกฎาคม และตุลาคม โดยคะแนนทั้งหมดที่คุณได้รับจะคงอยู่ตลอดจนกว่าจะสิ้นสุดแต่ละช่วง",
  "title949": "คำสั่งซื้อที่ไม่สำเร็จ (คะแนน)",
  "title950": "คะแนนความประพฤติสำหรับอัตราคำสั่งซื้อที่ไม่สำเร็จเนื่องจากคำสั่งซื้อที่ยกเลิกหรือขอคืนเงินคืนสินค้า",
  "title951": "การจัดส่งล่าช้า (คะแนน)",
  "title952": "คะแนนความประพฤติสำหรับการจัดส่งล่าช้า",
  "title953": "คะแนนความประพฤติสำหรับการละเมิดนโยบายการลงขายสินค้า",
  "title954": "การละเมิดนโยบายอื่น ๆ (คะแนน)",
  "title955": "คะแนนความประพฤติสำหรับการละเมิดนโยบายอื่น ๆ",
  "title956": "สินค้าที่ถูกลบหรือแบน",
  "title957": "สินค้าจะถูกลบถ้าหากมีการละเมิดนโยบายการลงขายสินค้าของ Shopee",
  "title958": "ร้านค้าของฉัน",
  "title959": "เป้าหมาย",
  "title960": "คะแนนความประพฤติ",
  "title961": " สินค้าสแปม",
  "title962": "หากผู้ขายมีพฤติกรรมที่ไม่เป็นธรรมในการเปลี่ยนผลการค้นหาจะได้รับการลงโทษ เช่น การลงสินค้าซ้ำ สแปม Keyword ตั้งราคาให้เข้าใจผิด เป็นต้น",
  "title963": "สินค้าละเมิดทรัพย์สินทางปัญญา",
  "title964": "สินค้าละเมิดทรัพย์สินทางปัญญา",
  "title965": "สินค้าต้องห้าม",
  "title966": "สินค้าที่ไม่ได้รับอนุญาตให้ลงขายบน Shopee",
  "title967": "สินค้าที่ลงขาย",
  "title968": "สินค้าพรีออเดอร์",
  "title969": "อัตราส่วนสินค้าพรีออเดอร์",
  "title970": "อัตราส่วนของสินค้าพรีออเดอร์ที่เผยแพร่จากสินค้าที่เผยแพร่ทั้งหมด",
  "title971": "จำนวนวันที่ร้านของคุณมีอัตราส่วนสินค้าพรีออเดอร์เกินกว่าที่กำหนด",
  "title972": "เป้าหมาย",
  "title973": "จำนวนวันสำหรับ % ของสินค้าพรีออเดอร์ของผู้ขายที่เกินเป้าหมายใน 30 วันที่ผ่านมา",
  "title974": "การละเมิดอื่น ๆ",
  "title975": "สินค้าละเมิดอื่น ๆ",
  "title976": "หากละเมิดกฎการลงสินค้าที่กำหนดโดยแพลตฟอร์มอาจถูกลงโทษได้ เช่น ระบุหมวดหมู่ไม่ถูกต้อง รูปภาพมีคุณภาพต่ำ",
  "title977": "เปอร์เซ็นต์ของจำนวนคำสั่งซื้อที่ถูกยกเลิกโดยผู้ขายใน 7 วันที่ผ่านมา",
  "title978": " อัตราการยกเลิก",
  "title979": "เปอร์เซ็นต์ของจำนวนคำสั่งซื้อที่ถูกผู้ซื้อยื่นคำร้องขอคืนเงิน/คืนสินค้าใน 7 วันที่ผ่านมา",
  "title980": "อัตราการคืนเงิน/คืนสินค้า",
  "title981": "เปอร์เซ็นต์ของคำสั่งซื้อ (จากคำสั่งซื้อทั้งหมด) ที่จัดส่งช้าโดยผู้ขายใน 7 วันที่ผ่านมา",
  "title982": "อัตราการจัดส่งช้า",
  "title983": "ปอร์เซ็นต์ของคำสั่งซื้อ (จากคำสั่งซื้อทั้งหมด) ที่จัดส่งช้าโดยผู้ขายใน 7 วันที่ผ่านมา",
  "title984": "ระยะเวลาเฉลี่ยที่ใช้ในการเตรียมสินค้าและส่งสินค้าของผู้ขาย",
  "title985": "การบริการลูกค้า ",
  "title986": "ความพึงพอใจของลูกค้า",
  "title987": "เปอร์เซ็นของแชทใหม่และการต่อรองราคาสินค้าที่ผู้ซื้อยื่อข้อเสนอมาที่ผู้ขายตอบสนองภายใน 12 ชั่วโมง",
  "title988": "อัตราการตอบกลับ",
  "title989": "ระยะเวลาเฉลี่ยโดยประมานที่ผู้ขายตอบสนองกลับไปยังผู้ซื้อ",
  "title990": "อัตราความคิดเห็นโดยรวม",
  "title991": "ค่าเฉลี่ยของการให้คะแนนคำสั่งซื้อทั้งหมดจากผู้ซื้อ",
  "title992": "คะแนนความประพฤติในไตรมาสนี้-คะแนนความประพฤติในไตรมาสนี้ (คะแนน)",
  "title993": "คะแนนความประพฤติในไตรมาสนี้-คำสั่งซื้อที่ไม่สำเร็จ (คะแนน)",
  "title994": "คะแนนความประพฤติในไตรมาสนี้-การจัดส่งล่าช้า (คะแนน)",
  "title995": "คะแนนความประพฤติในไตรมาสนี้",
  "title996": "คะแนนความประพฤติในไตรมาสนี้-การละเมิดนโยบายอื่น ๆ (คะแนน)",
  "title997": "สินค้าที่ถูกลบหรือแบน-สินค้าที่ถูกลบหรือแบน",
  "title998": "สินค้าที่ถูกลบหรือแบน-สินค้าสแปม",
  "title999": "สินค้าที่ถูกลบหรือแบน-สินค้าละเมิดทรัพย์สินทางปัญญา",
  "title1000": "สินค้าที่ถูกลบหรือแบน-สินค้าต้องห้าม",
  "title1001": "สินค้าพรีออเดอร์-อัตราส่วนสินค้าพรีออเดอร์",
  "title1002": "สินค้าพรีออเดอร์-จำนวนวันที่ร้านของคุณมีอัตราส่วนสินค้าพรีออเดอร์เกินกว่าที่กำหนด",
  "title1003": "สินค้าพรีออเดอร์-สินค้าละเมิดอื่น ๆ",
  "title1004": "อัตราส่วนการเสร็จสิ้นคำสั่งซื้อ - อัตราส่วนการเสร็จสิ้นคำสั่งซื้อ",
  "title1005": "อัตราส่วนการเสร็จสิ้นคำสั่งซื้อ - อัตราการยกเลิกคำสั่งซื้อ",
  "title1006": "อัตราส่วนการเสร็จสิ้นคำสั่งซื้อ - สินค้าที่ส่งคืน",
  "title1007": "อัตราส่วนการเสร็จสิ้นคำสั่งซื้อ - อัตราการส่งสินค้าช้า",
  "title1008": "อัตราส่วนการเสร็จสิ้นคำสั่งซื้อ - เวลาเตรียมการ",
  "title1009": "การบริการลูกค้า  ความพึงพอใจของลูกค้า-อัตราการตอบกลับ",
  "title1010": "การบริการลูกค้า & ความพึงพอใจของลูกค้า-เวลาในการตอบกลับ",
  "title1011": "การบริการลูกค้า & ความพึงพอใจของลูกค้า-อัตราความคิดเห็นโดยรวม",
  "title1012": "กู้คืนค่าเริ่มต้น",
  "title1013": "ยืนยัน",
  "title1014": "ยอดเยี่ยม",
  "title1015": "ดี",
  "title1016": "ควรปรับปรุง",
  "title1017": "อัตราแลกเปลี่ยนการชำระเงินไม่สามารถเว้นว่างได้",
  "title1018": "อัปเดตข้อมูลเรียบร้อย",
  "title1019": "ยืนยันที่จะให้สิทธิ์ร้านค้าอีกครั้งหรือไม่",
  "title1020": "คุณแน่ใจหรือไม่ว่าต้องการส่งออกสถานการณ์ร้านค้า",
  "title1021": "สถานการณ์ร้านค้า-",
  "title1022": "แพ็กเกจ",
  "title1023": "แพ็กเกจปัจจุบัน",
  "title1024": "เวอร์ชันฟรี",
  "title1025": "แพคเกจมีอายุการใช้งาน 1 ปี",
  "title1026": "การใช้หน่วยเดี่ยวเริ่มคำนวณตั้งแต่เริ่มต้นของแพคเกจ จนถึงการใช้หน่วยเดี่ยวหมดหรือเกินวันหมดอายุ",
  "title1027": "หากคุณต้องการบริการการปฏิบัติงานแบบออฟไลน์หรือการช่วยเสริมสำหรับการสาธิตออนไลน์โปรดติดต่อทีมบริการลูกค้าของเรา",
  "title1028": "ปี",
  "title1029": "จำนวนบัญชี",
  "title1030": "流程表单",
  "title1031": "表单名",
  "title1032": "请输入表单名",
  "title1033": "สถานะเปิดใช้งาน",
  "title1034": "โปรดใส่หมายเหตุ",
  "title1035": "บันทึก",
  "title1036": "ดู",
  "title1037": "ล้าง",
  "title1038": "从左侧拖入或点选组件进行表单设计",
  "title1039": "输入型组件",
  "title1040": "选择型组件",
  "title1041": "布局型组件",
  "title1042": "表单名不能为空",
  "title1043": "开启状态不能为空",
  "title1044": "สำเร็จ",
  "title1045": "代码已复制到剪切板，可粘贴。",
  "title1046": "代码复制失败",
  "title1047": "确定要清空所有组件吗？",
  "title1048": "提示",
  "title1049": "工作流",
  "title1050": "编号",
  "title1051": "表单详情",
  "title1052": "是否确认删除工作表单的编号为",
  "title1053": "定义编号",
  "title1054": "定义名称",
  "title1055": "定义分类",
  "title1056": "表单信息",
  "title1057": "暂无表单",
  "title1058": "流程版本",
  "title1059": "未部署",
  "title1060": "激活",
  "title1061": "挂起",
  "title1062": "部署时间",
  "title1063": "定义描述",
  "title1064": "分配规则",
  "title1065": "流程图",
  "title1066": "组名",
  "title1067": "请输入组名",
  "title1068": "โปรดเลือกสถานะ",
  "title1069": "คำอธิบาย",
  "title1070": "สมาชิก",
  "title1071": "โปรดใส่คำอธิบาย",
  "title1072": "โปรดเลือกสมาชิก",
  "title1073": "ชื่อกลุ่มต้องไม่ว่างเปล่า",
  "title1074": "คำอธิบายต้องไม่ว่างเปล่า",
  "title1075": "สมาชิกต้องไม่ว่างเปล่า",
  "title1076": "สถานะต้องไม่ว่างเปล่า",
  "title1077": "เพิ่มกลุ่มผู้ใช้",
  "title1078": "แก้ไขกลุ่มผู้ใช้",
  "title1079": "คุณแน่ใจหรือไม่ว่าต้องการลบกลุ่มผู้ใช้ที่มีหมายเลขกลุ่มเป็น",
  "title1080": "ไม่ทราบ",
  "title1081": "บันทึกสำเร็จ",
  "title1082": "流程标识",
  "title1083": "请输入流程标识",
  "title1084": "流程名称",
  "title1085": "请输入流程名称",
  "title1086": "流程分类",
  "title1087": "新建流程",
  "title1088": "导入流程",
  "title1089": "最新部署的流程定义",
  "title1090": "激活状态",
  "title1091": "修改流程",
  "title1092": "设计流程",
  "title1093": "发布流程",
  "title1094": "流程定义",
  "title1095": "请输入流标标识",
  "title1096": "新建后，流程标识不可修改！",
  "title1097": "流程标识不可修改！",
  "title1098": "请选择流程分类",
  "title1099": "流程描述",
  "title1100": "表单类型",
  "title1101": "表单提交路由",
  "title1102": "请输入表单提交路由",
  "title1103": "自定义表单的提交路径，使用",
  "title1104": "的路由地址，例如说：",
  "title1105": "表单查看路由",
  "title1106": "请输入表单查看路由",
  "title1107": "自定义表单的查看路径，使用",
  "title1108": "将文件拖到此处，或",
  "title1109": "คลิกเพื่ออัปโหลด",
  "title1110": "提示：仅允许导入“",
  "title1111": "หรือ",
  "title1112": "格式文件！",
  "title1113": "流程标识不能为空",
  "title1114": "流程名称不能为空",
  "title1115": "业务表单不能为空",
  "title1116": "流程分类不能为空",
  "title1117": "表单提交路由不能为空",
  "title1118": "表单查看路由不能为空",
  "title1119": "新建模型",
  "title1120": "修改模型",
  "title1121": "修改模型成功",
  "title1122": "新建模型成功！",
  "title1123": "后续需要执行如下",
  "title1124": "个步骤：",
  "title1125": "点击【修改流程】按钮，配置流程的分类、表单信息",
  "title1126": "点击【设计流程】按钮，绘制流程图",
  "title1127": "点击【分配规则】按钮，设置每个用户任务的审批人",
  "title1128": "点击【发布流程】按钮，完成流程的最终发布",
  "title1129": "另外，每次流程修改后，都需要点击【发布流程】按钮，才能正式生效！！！",
  "title1130": "重要提示",
  "title1131": "是否删除该流程！！",
  "title1132": "是否部署该流程！！",
  "title1133": "部署成功",
  "title1134": "是否确认",
  "title1135": "流程名字为",
  "title1136": "导入流程成功！请点击【设计流程】按钮，进行编辑保存后，才可以进行【发布流程】",
  "title1137": "选择",
  "title1138": "申请信息【",
  "title1139": "选择其它流程",
  "title1140": "发起流程成功",
  "title1141": "审批任务【",
  "title1142": "流程名",
  "title1143": "流程发起人",
  "title1144": "审批建议",
  "title1145": "请输入审批建议",
  "title1146": "不通过",
  "title1147": "转办",
  "title1148": "委派",
  "title1149": "退回",
  "title1150": "点击查看",
  "title1151": "审批记录",
  "title1152": "任务：",
  "title1153": "审批人：",
  "title1154": "创建时间：",
  "title1155": "审批时间：",
  "title1156": "耗时：",
  "title1157": "转派审批人",
  "title1158": "新审批人",
  "title1159": "审批建议不能为空",
  "title1160": "新审批人不能为空",
  "title1161": "未传递",
  "title1162": "参数，无法查看流程信息",
  "title1163": "查询不到流程信息！",
  "title1164": "审批通过成功！",
  "title1165": "审批不通过成功！",
  "title1166": "转派任务成功！",
  "title1167": "暂不支持【委派】功能，可以使用【转派】替代！",
  "title1168": "暂不支持【退回】功能！",
  "title1169": "请输入流程名",
  "title1170": "所属流程",
  "title1171": "请输入流程定义的编号",
  "title1172": "提交时间",
  "title1173": "结果",
  "title1174": "请选择流结果",
  "title1175": "发起流程",
  "title1176": "当前审批任务",
  "title1177": "结束时间",
  "title1178": "请输入取消原因？",
  "title1179": "取消流程",
  "title1180": "取消原因不能为空",
  "title1181": "取消成功",
  "title1182": "任务编号",
  "title1183": "任务名称",
  "title1184": "审批意见",
  "title1185": "审批时间",
  "title1186": "耗时",
  "title1187": "审批",
  "title1188": "任务分配规则",
  "title1189": "任务名",
  "title1190": "任务标识",
  "title1191": "规则类型",
  "title1192": "规则范围",
  "title1193": "修改任务规则",
  "title1194": "指定角色",
  "title1195": "指定部门",
  "title1196": "请选择指定部门",
  "title1197": "指定岗位",
  "title1198": "指定用户",
  "title1199": "指定用户组",
  "title1200": "指定脚本",
  "title1201": "规则类型不能为空",
  "title1202": "指定角色不能为空",
  "title1203": "指定部门不能为空",
  "title1204": "指定岗位不能为空",
  "title1205": "指定用户不能为空",
  "title1206": "指定用户组不能为空",
  "title1207": "指定脚本不能为空",
  "title1208": "การเปรียบเทียบข้อมูลยอดขาย",
  "title1209": "การเปรียบเทียบข้อมูลคำสั่งซื้อ",
  "title1210": "การเปรียบเทียบข้อมูลการไหลของข้อมูล",
  "title1211": "ข้อมูลการแปลงโฆษณา",
  "title1212": "ความคิดเห็นของแพลตฟอร์ม",
  "title1213": "โปรดใส่ความคิดเห็นของแพลตฟอร์ม",
  "title1214": "สินค้าของแพลตฟอร์ม",
  "title1215": "โปรดใส่สินค้าของแพลตฟอร์ม",
  "title1216": "โปรดใส่สินค้า",
  "title1217": "เวลาของความคิดเห็น",
  "title1218": "ความคิดเห็น",
  "title1219": "ผู้ซื้อ",
  "title1220": "วิดีโอ",
  "title1221": "เนื้อหาของรีวิว",
  "title1222": "การตอบกลับของผู้ขาย",
  "title1223": "คะแนน",
  "title1224": "โปรดใส่ผู้ซื้อ",
  "title1225": "โปรดใส่วิดีโอ",
  "title1226": "โปรดใส่เนื้อหาของรีวิว",
  "title1227": "โปรดใส่การตอบกลับของผู้ขาย",
  "title1228": "โปรดใส่คะแนน",
  "title1229": "เลือกเวลาของรีวิว",
  "title1230": "ต้องไม่เว้นว่าง",
  "title1231": "หมายเลขคำสั่งซื้อต้องไม่ว่างเปล่า",
  "title1232": "เวลาที่รีวิวต้องไม่ว่างเปล่า",
  "title1233": "เพิ่มรีวิวสินค้า",
  "title1234": "แก้ไขรีวิวสินค้า",
  "title1235": "คุณแน่ใจหรือไม่ว่าต้องการลบความคิดเห็นสินค้าที่มีหมายเลข",
  "title1236": "คุณแน่ใจหรือไม่ว่าต้องการส่งออกรายการข้อมูลความคิดเห็นสินค้าทั้งหมด",
  "title1237": "ความคิดเห็นของสินค้า",
  "title1238": "ป้อนต้นทุนที่กำหนดเอง",
  "title1239": "บันทึกการแก้ไขต้นทุน",
  "title1240": "นำเข้าต้นทุนแบบจำนวนมาก",
  "title1241": "นำเข้าต้นทุนสินค้า",
  "title1242": "นำเข้าค่าใช้จ่ายในอดีต",
  "title1243": "เพิ่มค่าที่กำหนดเอง",
  "title1244": "นำเข้าค่าใช้จ่ายที่กำหนดเองเป็นกลุ่ม",
  "title1245": "ลบเป็นกลุ่ม",
  "title1246": "โปรดเลือก ",
  "title1247": "สถานะการขาย",
  "title1248": "สถานะค่าใช้จ่ายของสินค้า",
  "title1249": "ประเภทค่าใช้จ่าย",
  "title1250": "ส่งออกค่าใช้จ่ายของสินค้า",
  "title1251": "ขายอยู่",
  "title1252": "ไม่แสดง",
  "title1253": "มีค่าใช้จ่ายของสินค้า",
  "title1254": "ไม่มีค่าใช้จ่ายของสินค้า",
  "title1255": "ค่าใช้จ่ายในอดีต",
  "title1256": "แม่แบบนำเข้าค่าใช้จ่ายของสินค้า",
  "title1257": "แม่แบบนำเข้าค่าใช้จ่ายที่กำหนดเอง",
  "title1258": "นำเข้าค่าใช้จ่ายที่กำหนดเอง",
  "title1259": "ตารางค่าใช้จ่ายที่กำหนดเอง",
  "title1260": "คำแนะนำอบอุ่น",
  "title1261": "คุณแน่ใจหรือไม่ว่าต้องการส่งออก",
  "title1262": "รายละเอียดค่าใช้จ่ายของสินค้า -",
  "title1263": "เปิด",
  "title1264": "ทำให้เหนือ",
  "title1265": "เปลี่ยนสกุลเงิน",
  "title1266": "จำนวนการเข้าชมหน้า",
  "title1268": "ผู้เข้าชมโฆษณา",
  "title1269": "ผู้เข้าชมช่วยเหลือ",
  "title1270": "จำนวนคำชม",
  "title1271": "อัตราส่วนคำชม",
  "title1272": "จำนวนที่เพิ่มในตะกร้า",
  "title1273": "อัตราส่วนการแปลงเป็นตะกร้า",
  "title1274": "ยอดขายโฆษณา",
  "title1275": "ยอดขายช่วยเหลือ",
  "title1276": "ยอดขายช่วยเหลือ",
  "title1277": "อัตราส่วนการแปลง",
  "title1278": "ค่าโฆษณา",
  "title1279": "ข้อมูลผิดพลาด โปรดติดต่อผู้ดูแลระบบ",
  "title1280": "ติดท็อปสำเร็จ",
  "title1281": "ยกเลิกติดท็อปสำเร็จ",
  "title1282": "สัดส่วนคำสั่งซื้อช่วยเหลือ",
  "title1283": "คุณแน่ใจหรือไม่ว่าต้องการส่งออกบันทึกที่เข้าและออกคลังทั้งหมด",
  "title1285": "การวิเคราะห์การเคลื่อนไหวของโฆษณาสามารถรวบรวมและวิเคราะห์ข้อมูลการเคลื่อนไหวและอัตราการแปลงของสินค้าในร้าน เพื่อช่วยให้คุณสามารถดูแลข้อมูลหลัก เช่น จำนวนผู้เยี่ยมชมร้าน อัตราการแปลง ปริมาณการขาย รายได้จากการขาย และอื่น ๆ เพื่อเข้าใจเกี่ยวกับประสิทธิภาพของสินค้าในการดำเนินงาน",
  "title1286": "โปรดใส่คุณสมบัติการค้นหาสินค้า",
  "title1287": "หมวดหมู่โฆษณา",
  "title1288": "คลิกโฆษณา",
  "title1289": "ยอดขาย",
  "title1290": "Chi tiết",
  "title1291": "Chi tiết quảng cáo",
  "title1292": "Hủy đặt hàng trên đầu",
  "title1293": "Tên sản phẩm",
  "title1294": "Xác nhận xuất tất cả sản phẩm",
  "title1295": "Mục dữ liệu",
  "title1296": "Dữ liệu chẩn đoán",
  "title1297": "Quá khứ",
  "title1298": "Thông tin cửa hàng",
  "title1299": "Nhóm",
  "title1301": "ยอดขายมาตราส่วนจากรุปรายไปเดือนใหม่",
  "title1302": "ได้กำไร/ขาดทุน",
  "title1303": "โปรดเลือกประเภทการค้นหา",
  "title1304": "โปรดป้อนเนื้อหาที่ต้องการค้นหา",
  "title1305": "เลือกเวลา",
  "title1306": "รีวิว",
  "title1307": "ตัวชี้วัดการตลาด",
  "title1308": "จำนวนความคิดเห็น:",
  "title1309": "คะแนนรวม:",
  "title1310": "จุดขายหลัก",
  "title1311": "ข้อมูลคู่แข่ง",
  "title1312": "บันทึกคำหลัก",
  "title1313": "สถานะสินค้า",
  "title1314": "เอาออก",
  "title1315": "ใส่บน",
  "title1316": "รีวิวสินค้า",
  "title1317": "ชื่อผู้ใช้",
  "title1318": "หมายเลขสั่งซื้อ:",
  "title1319": "รายละเอียดสินค้า",
  "title1320": "ดูแผนภูมิแนวโน้ม",
  "title1321": "ผู้เยี่ยมชมทั้งหมด",
  "title1322": "จำนวนรวมของการสั่งซื้อ",
  "title1323": "ผู้เยี่ยมชมโฆษณาคำหลัก",
  "title1324": "อัตราการคลิกคำหลัก",
  "title1325": "ปริมาณโฆษณาคำหลัก",
  "title1326": "อัตราการแปลงคำหลัก",
  "title1327": "ค่าใช้จ่ายเฉลี่ยต่อรายการ",
  "title1328": "คำสำคัญ",
  "title1329": "โฆษณาคำสำคัญ",
  "title1330": "ผู้เยี่ยมชมโฆษณาที่เกี่ยวข้อง",
  "title1331": "คำสั่งโฆษณาที่เกี่ยวข้อง",
  "title1332": "โฆษณาที่เกี่ยวข้อง",
  "title1333": "ผู้เยี่ยมชมธรรมชาติ",
  "title1334": "อัตราการคลิกธรรมชาติ",
  "title1335": "คำสั่งธรรมชาติ",
  "title1336": "อัตราการแปลงธรรมชาติ",
  "title1337": "แผนภูมิการวิเคราะห์แนวโน้มข้อมูลสินค้า",
  "title1338": "คลิกที่ป้ายเพื่อเปิดหรือปิดเส้น曲สี",
  "title1339": "หมายเหตุจุดขาย",
  "title1340": "ลิงก์",
  "title1341": "การขายดีของคู่แข่ง",
  "title1342": "ราคา",
  "title1343": "ปริมาณขายรายเดือนของคู่แข่ง",
  "title1344": "คำหลักท้องถิ่น",
  "title1345": "การแปลจีน",
  "title1346": "ปริมาณการค้นหา",
  "title1347": "โปรดใส่จุดขาย",
  "title1348": "ลิงก์ของคู่แข่ง",
  "title1349": "โปรดใส่ลิงก์ของคู่แข่ง",
  "title1350": "โปรดใส่การขายดีของคู่แข่ง",
  "title1351": "โปรดใส่ปริมาณขายรายเดือนของคู่แข่ง",
  "title1352": "โปรดใส่คำหลักท้องถิ่น",
  "title1353": "การแปลอัตโนมัติของคำสำคัญ",
  "title1354": "โปรดป้อนปริมาณการค้นหาคำสำคัญ",
  "title1355": "บันทึกจุดขายหลัก",
  "title1356": "เพิ่มบันทึกจุดขายหลัก",
  "title1357": "บันทึกลิงก์คู่แข่ง",
  "title1358": "เพิ่มลิงก์คู่แข่ง",
  "title1359": "เพิ่มคำหลัก",
  "title1360": "ชื่อสินค้าออนไลน์",
  "title1361": "สินค้า",
  "title1362": "ถอนจากชั้น",
  "title1363": "วางขาย",
  "title1364": "bigseller: 自定义日期",
  "title1365": "โปรดใส่หมายเหตุจุดขาย",
  "title1366": "ต้นทุนเฉลี่ยต่อคำสั่ง (คำสำคัญ)",
  "title1367": "ค่าใช้จ่ายต่อคำสั่ง (โฆษณาที่เกี่ยวข้อง)",
  "title1368": "เดือน",
  "title1369": "คุณแน่ใจหรือไม่ว่าต้องการส่งออกรายการข้อมูลคำสั่งซื้อทั้งหมด",
  "title1370": "กระเป๋าเดินทางขนาด 23 นิ้ว รหัสผ่านสีดำ สีดำ สีดำ สีดำ สีดำ สีดำ",
  "title1371": "ป้อนคำหลักเพื่อค้นหา",
  "title1372": "แสดงตำแหน่ง",
  "title1373": "การแปลง",
  "title1374": "สินค้าขายออก",
  "title1375": "การตั้งค่าเงื่อนไข",
  "title1377": "กฎวินิจฉัย",
  "title1378": "โปรดเลือกเวลา",
  "title1379": "ชั่วโมง",
  "title1380": "การตั้งค่าไม่สมบูรณ์",
  "title1381": "มากกว่า",
  "title1382": "มากกว่าหรือเท่ากับ",
  "title1383": "น้อยกว่า",
  "title1384": "น้อยกว่าหรือเท่ากับ",
  "title1385": "เริ่มต้นเดือน",
  "title1386": "จบเดือน",
  "title1387": "ข้อมูลพื้นฐาน",
  "title1388": "จำนวนลูกค้า",
  "title1389": "จำนวนเงินการยกเลิกคำสั่งซื้อ",
  "title1390": "แนวโน้มการเปลี่ยนแปลง",
  "title1391": "จำนวนคำสั่งยกเลิก",
  "title1392": "14 วันล่าสุด",
  "title1393": "bigseller",
  "title1394": "เดือนที่แล้ว",
  "title1395": "6 เดือนที่ผ่านมา",
  "title1396": "1 ปีที่ผ่านมา",
  "title1397": "3 เดือนที่ผ่านมา",
  "title1398": "6 เดือนที่ผ่านมา",
  "title1399": "1 ปีที่ผ่านมา",
  "title1400": "ตามปี",
  "title1401": "เปรียบเทียบกับวันก่อนหน้า",
  "title1402": "เพิ่มลิงก์สินค้า",
  "title1403": "ข้อมูลสินค้า",
  "title1404": "เวที",
  "title1405": "เวลาวิเคราะห์",
  "title1406": "เกินเวลาดำเนินการ กรุณาวิเคราะห์ใหม่",
  "title1407": "การวิเคราะห์เสร็จสิ้น",
  "title1408": "การวิเคราะห์กำลังดำเนิน",
  "title1409": "วิเคราะห์ใหม่",
  "title1410": "รายงานการวิเคราะห์",
  "title1411": "ลิงก์สินค้า",
  "title1412": "โปรดป้อนลิงก์สินค้าออนไลน์สำหรับการวิเคราะห์เนื้อหารีวิว สามารถเพิ่มลิงก์สินค้าได้เพียงหนึ่งลิงก์ต่อครั้งเท่านั้น",
  "title1413": "ป้อนลิงก์สินค้า",
  "title1414": "เช่น:",
  "title1415": "ชื่อสินค้า",
  "title1416": "รหัสสินค้า",
  "title1417": "โปรดป้อนลิงก์สินค้า",
  "title1418": "โปรดป้อนที่อยู่ที่ถูกต้อง",
  "title1419": "เพิ่มสำเร็จ",
  "title1420": "รีเฟรชสำเร็จ",
  "title1421": "ยืนยันการลบ?",
  "title1422": "ราคา:",
  "title1423": "ยอดขายทั้งหมด:",
  "title1424": "ยอดขายรายเดือน:",
  "title1425": "การจัดอันดับดาว:",
  "title1426": "อัตราการรักษารีวิว:",
  "title1427": "เวลาวางขาย:",
  "title1428": "เปิดลิงก์ร้านค้า",
  "title1429": "ภาพรวม",
  "title1430": "ข้อดี",
  "title1431": "ข้อเสีย",
  "title1432": "ความคาดหวังของลูกค้า",
  "title1433": "แรงจูงใจในการซื้อ",
  "title1434": "ขณะนี้มีตัวแปรที่ขายอยู่:",
  "title1435": "ตัวแปรที่มีรีวิวประวัติ:",
  "title1436": "ตัวแปรที่มีรายการรีวิวดังต่อไปนี้:",
  "title1437": "ตัวแปร",
  "title1438": "จำนวนรีวิว",
  "title1439": "เปอร์เซ็นต์รีวิว",
  "title1440": "ดูความคิดเห็น",
  "title1441": "เหตุผล",
  "title1442": "เปอร์เซ็นต์หมวดหมู่ปัจจุบัน",
  "title1443": "เปอร์เซ็นต์ความคิดเห็นรวมสินค้าปัจจุบัน",
  "title1444": "ดูความคิดเห็นของผู้ซื้อ",
  "title1445": "รายการความคิดเห็น",
  "title1446": "ป้อนเนื้อหาการค้นหา",
  "title1447": "คะแนนดาว",
  "title1448": "ประเภทความคิดเห็น",
  "title1449": "เนื้อหาที่แปล",
  "title1450": "ดาวน์โหลดความคิดเห็น",
  "title1451": "ดาว",
  "title1452": "ข้อความ",
  "title1453": "อื่น ๆ",
  "title1454": "คุณแน่ใจหรือไม่ว่าต้องการส่งออกรีวิวสินค้า",
  "title1455": "ความคิดเห็นสินค้า",
  "title1456": "เลือกประเภทการค้นหา",
  "title1457": "ยอดขายสินค้า",
  "title1458": "คะแนนร้านค้า",
  "title1459": "เวลาการจัดส่ง",
  "title1460": "อัตราการส่งสินค้าเกินกำหนด",
  "title1461": "อัตราที่ยังไม่เสร็จสมบูรณ์",
  "title1462": "เวลาการตอบสนอง",
  "title1463": "คะแนนโทษ",
  "title1464": "รีวิวใหม่วันนี้",
  "title1465": "林明芳-",
  "title1466": "ผู้ใช้",
  "title1467": "โปรดใส่ชื่อผู้ใช้",
  "title1468": "ชื่อการวินิจฉัย",
  "title1469": "โปรดใส่ชื่อการวินิจฉัย",
  "title1470": "ประเภทการวินิจฉัย",
  "title1471": "โปรดเลือกประเภทการวินิจฉัย",
  "title1472": "ประเภทการแจ้งเตือน",
  "title1473": "โปรดเลือกประเภทการแจ้งเตือน",
  "title1474": "หมายเลขเพิ่มขึ้น",
  "title1475": "คำอธิบายการวินิจฉัย",
  "title1476": "โปรดป้อนกฎการวินิจฉัย",
  "title1477": "ชื่อการวินิจฉัยต้องไม่ว่างเปล่า",
  "title1478": "ประเภทการวินิจฉัยต้องไม่ว่างเปล่า",
  "title1479": "ประเภทการแจ้งเตือนต้องไม่ว่างเปล่า",
  "title1480": "เพิ่มการวินิจฉัยข้อมูล",
  "title1481": "แก้ไขการวินิจฉัยข้อมูล",
  "title1482": "คุณแน่ใจหรือไม่ว่าต้องการลบการวินิจฉัยข้อมูลที่มี ID",
  "title1483": "คุณแน่ใจหรือไม่ว่าต้องการส่งออกรายการข้อมูลการวินิจฉัยทั้ง",
  "title1484": "การวินิจฉัยข้อมูล",
  "title1485": "การจัดอันดับ",
  "title1486": "เวลาอัปเดต",
  "title1487": "การจัดอันดับรายวัน",
  "title1488": "การจัดอันดับรายสัปดาห์",
  "title1489": "การจัดอันดับรายเดือน",
  "title1490": "ลิงก์ออนไลน์",
  "title1491": "ข้อมูลลิงก์",
  "title1492": "ข้อมูล",
  "title1493": "ข้อมูลบัญชี",
  "title1494": "ข้อมูลแพลตฟอร์ม",
  "title1495": "การจัดอันดับรายได้จากการขาย",
  "title1496": "การจัดอันดับกำไรจากการขาย",
  "title1497": "การจัดอันดับอัตราการคืนสินค้า",
  "title1498": "การจัดอันดับผลงาน",
  "title1499": "การจัดอันดับแพลตฟอร์ม",
  "title1500": "การจัดอันดับร้านค้า",
  "title1501": "ยอดขายทั้งหมด",
  "title1502": "ข้อมูลสินค้า",
  "title1503": "ชื่อ",
  "title1504": "โปรดใส่ชื่อ",
  "title1505": "ชื่อเทมเพลต",
  "title1506": "ประเภทบาร์โค้ด",
  "title1507": "ขนาดฉลาก",
  "title1508": "ความกว้าง",
  "title1509": "ความสูง",
  "title1510": "ค่าเริ่มต้น",
  "title1511": "โปรดเลือกประเภทบาร์โค้ด",
  "title1512": "ความกว้าง",
  "title1513": "โปรดใส่ความกว้าง",
  "title1514": "ความสูง",
  "title1515": "โปรดใส่ความสูง",
  "title1516": "นิยามเทมเพลต",
  "title1517": "ชื่อต้องไม่ว่าง",
  "title1518": "ประเภทบาร์โค้ดต้องไม่ว่าง",
  "title1519": "ความกว้างต้องไม่ว่าง",
  "title1520": "ความสูงต้องไม่ว่าง",
  "title1521": "ค่าเริ่มต้นต้องไม่ว่าง",
  "title1522": "เพิ่มเทมเพลตป้าย",
  "title1523": "แก้ไขเทมเพลตป้าย",
  "title1524": "ยืนยันการลบเทมเพลตป้ายที่มี ID",
  "title1525": "ยืนยันการส่งออกรายการข้อมูลเทมเพลตป้ายทั้งหมด",
  "title1526": "เทมเพลตป้าย",
  "title1527": "ป้อนสินค้า",
  "title1528": "ดูอันดับ",
  "title1529": "โปรดป้อนชื่อแพลตฟอร์ม",
  "title1530": "ชื่อแพลตฟอร์ม",
  "title1531": "ไอคอนแพลตฟอร์ม",
  "title1532": "ลิงก์แพลตฟอร์ม",
  "title1533": "โปรดป้อนลิงก์แพลตฟอร์ม",
  "title1534": "ชื่อแพลตฟอร์มต้องไม่ว่าง",
  "title1535": "ไอคอนแพลตฟอร์มต้องไม่ว่าง",
  "title1536": "ลิงก์แพลตฟอร์มต้องไม่ว่าง",
  "title1537": "เพิ่มแพลตฟอร์มอีคอมเมิร์ซ",
  "title1538": "แก้ไขแพลตฟอร์มอีคอมเมิร์ซ",
  "title1539": "ยืนยันการลบ",
  "title1540": "ยืนยันการส่งออกรายการข้อมูลแพลตฟอร์มอีคอมเมิร์ซทั้งหมด",
  "title1541": "แพลตฟอร์มอีคอมเมิร์ซ",
  "title1542": "โปรดป้อนชื่อหมวดหมู่",
  "title1543": "หมวดหมู่",
  "title1544": "หมวดหมู่หลัก",
  "title1545": "ชื่อหมวดหมู่",
  "title1546": "ลำดับแสดงผล",
  "title1547": "ระดับ",
  "title1548": "โปรดป้อนหมวดหมู่หลัก",
  "title1549": "โปรดป้อนลำดับการแสดงผล",
  "title1550": "โปรดป้อนระดับ",
  "title1551": "ชื่อหมวดหมู่ต้องไม่ว่าง",
  "title1552": "ลำดับแสดงผลต้องไม่ว่าง",
  "title1553": "ระดับต้องไม่ว่าง",
  "title1554": "เพิ่มหมวดหมู่สินค้า",
  "title1555": "แก้ไขหมวดหมู่สินค้า",
  "title1556": "ยืนยันการลบหมวดหมู่สินค้า",
  "title1557": "ยืนยันการส่งออกรายการข้อมูลหมวดหมู่สินค้าทั้งหมด",
  "title1558": "หมวดหมู่สินค้า",
  "title1559": "คุณมี",
  "title1560": "ร้านของคุณหมดอายุการอนุญาต โปรด",
  "title1561": "big seller",
  "title1562": "easyboss",
  "title1563": "รอดำเนินการ",
  "title1564": "เร็วๆ นี้จะหมดอายุ",
  "title1565": "สินค้าหมด",
  "title1566": "easyboss",
  "title1567": "big seller",
  "title1568": "big seller",
  "title1569": "big seller",
  "title1570": "big seller",
  "title1571": "ไม่มีสินค้าคงคลัง",
  "title1572": "bigseller:低于安全库存",
  "title1573": "bigseller:重复 警戒库存",
  "title1574": "bigsell",
  "title1575": "重复",
  "title1576": "แผนการซื้อ",
  "title1577": "big seller",
  "title1578": "big seller",
  "title1579": "重复",
  "title1580": "กรุณาใส่",
  "title1581": "คู่แข่ง",
  "title1582": "ยอดขายรายเดือน",
  "title1583": "กรุณาใส่ลิงก์",
  "title1584": "กรุณาใส่ราคา",
  "title1585": "กรุณาใส่ยอดขายรายเดือน",
  "title1586": "เพิ่มลิงก์คู่แข่ง",
  "title1587": "แก้ไขลิงก์คู่แข่ง",
  "title1588": "คุณแน่ใจหรือไม่ว่าต้องการลบลิงก์คู่แข่งที่มี ID",
  "title1589": "คุณแน่ใจหรือไม่ว่าต้องการส่งออกรายการข้อมูลลิงก์คู่แข่งทั้งหมด?",
  "title1590": "สินค้าเดียว",
  "title1591": "สินค้ารวม",
  "title1592": "ร้านค้าทั้งหมด",
  "title1593": "แก้ไข",
  "title1594": "ชื่อสินค้าที่รวมกัน",
  "title1595": "โปรดเลือกสินค้า",
  "title1596": "โปรดเลือกร้านค้า",
  "title1597": "สินค้าต้องไม่ว่างเปล่า",
  "title1598": "ร้านค้าต้องไม่ว่างเปล่า",
  "title1599": "เพิ่มคู่สินค้า",
  "title1600": "คุณแน่ใจหรือไม่ว่าต้องการส่งออกรายการข้อมูลคู่สินค้าทั้งหมด?",
  "title1601": "คู่สินค้า",
  "title1602": "รายละเอียดคู่",
  "title1603": "ลบ",
  "title1604": "คุณแน่ใจหรือไม่ว่าต้องการลบคู่สินค้า?",
  "title1605": "โปรดป้อนร้านค้า",
  "title1606": "โปรดป้อนแพลตฟอร์ม",
  "title1607": "โปรดป้อนภูมิภาค",
  "title1608": "ล่าสุด",
  "title1609": "โปรดป้อนล่าสุด",
  "title1610": "เพิ่มขึ้น",
  "title1611": "สกุลเงิน",
  "title1612": "อัตราแลกเปลี่ยน",
  "title1613": "จำนวนการคืนเงิน",
  "title1614": "ปริมาณคำสั่งการคืนสินค้าเดือนต่อเดือน",
  "title1615": "จำนวนคำสั่งยกเลิก",
  "title1616": "อัตราส่วนการคืนสินค้าเดือนต่อเดือน",
  "title1617": "จำนวนคำสั่งยกเลิกเดือนต่อเดือน",
  "title1618": "อัตราส่วนการยกเลิก",
  "title1619": "อัตราส่วนการยกเลิกเดือนต่อเดือน",
  "title1620": "จำนวนการประเมิน",
  "title1621": "จำนวนการประเมินเดือนต่อเดือน",
  "title1622": "ปริมาณคำสั่งการสั่งซื้อเดือนต่อเดือน",
  "title1623": "bigseller",
  "title1624": "easyboss",
  "title1625": "ยอดขายเปรียบเทียบต่อเดือน",
  "title1626": "重复",
  "title1627": "ต้นทุนเปรียบเทียบต่อเดือน",
  "title1628": "ค่าใช้จ่ายอื่น ๆ",
  "title1629": "จำนวนเงินที่ต้องชำระ",
  "title1630": "จำนวนเงินที่ต้องชำระการประเมิน",
  "title1631": "จำนวนเงินที่ต้องจ่ายการประเมิน",
  "title1632": "ค่าใช้จ่ายการประเมิน",
  "title1633": "การเปรียบเทียบค่าใช้จ่ายการประเมิน",
  "title1634": "อัตราแลกเปลี่ยนการชำระเงินการประเมิน",
  "title1635": "ค่าคอมมิชชั่น",
  "title1636": "ค่าใช้จ่ายในการโฆษณา",
  "title1637": "การเปรียบเทียบจำนวนเงินคืน",
  "title1638": "รายได้ทั้งหมด",
  "title1639": "รายจ่ายทั้งหมด",
  "title1640": "ต้นทุนทั้งหมด",
  "title1641": "กำไรโดยประมาณ",
  "title1642": "การเปรียบเทียบกำไร",
  "title1643": "กำไรเฉลี่ย",
  "title1644": "การเปรียบเทียบอัตรากำไร",
  "title1645": "อัตรากำไรจากต้นทุน",
  "title1646": "อัตรากำไรค่าใช้จ่ายเปรียบเทียบต่อเดือน",
  "title1647": "โปรดใส่สกุลเงิน",
  "title1648": "โปรดใส่อัตราแลกเปลี่ยน",
  "title1649": "โปรดใส่จำนวนการคืนเงิน",
  "title1650": "โปรดใส่ปริมาณคำสั่งส่งคืน MoM",
  "title1651": "โปรดใส่จำนวนการยกเลิก",
  "title1652": "โปรดใส่อัตราส่วนการคืนเงิน MoM",
  "title1653": "โปรดใส่ปริมาณการยกเลิก ",
  "title1654": "โปรดป้อนอัตราการยกเลิก",
  "title1655": "โปรดป้อนการเปรียบเทียบอัตราการยกเลิก",
  "title1656": "โปรดป้อนปริมาณคำสั่งการประเมิน",
  "title1657": "โปรดป้อนการเปรียบเทียบปริมาณคำสั่งการประเมิน",
  "title1658": "โปรดป้อนปริมาณคำสั่งการที่มีผลเห็น",
  "title1659": "โปรดป้อนการเปรียบเทียบปริมาณคำสั่งการที่มีผลเห็น",
  "title1660": "โปรดป้อนปริมาณขายที่ถูกต้อง",
  "title1661": "โปรดป้อนการเปรียบเทียบปริมาณขายที่ถูกต้อง",
  "title1662": "โปรดป้อนจำนวนเงินของคำสั่ง",
  "title1663": "โปรดป้อนจำนวนเงินที่จ่ายจริงโดยผู้ซื้อ",
  "title1664": "โปรดป้อนการเปรียบเทียบยอดขายที่ถูกต้อง",
  "title1665": "ผู้ซื้อจ่ายค่าจัดส่ง",
  "title1666": "โปรดป้อนค่าใช้จ่ายทั้งหมดสินค้า",
  "title1667": "โปรดป้อนอัตราส่วนค่าใช้จ่าย",
  "title1668": "โปรดป้อนค่าใช้จ่ายอื่นๆ",
  "title1669": "โปรดป้อนค่าใช้จ่ายการขนส่ง",
  "title1670": "โปรดป้อนค่าใช้จ่ายการบรรจุ",
  "title1671": "โปรดป้อนจำนวนเงินที่ต้องชำระ",
  "title1672": "โปรดป้อนจำนวนเงินที่ต้องชำระการประเมิน",
  "title1673": "โปรดป้อนจำนวนเงินที่ต้องชำระการประเมิน",
  "title1674": "โปรดป้อนค่าใช้จ่ายการประเมิน",
  "title1675": "โปรดป้อนค่าใช้จ่ายการประเมิน",
  "title1676": "โปรดป้อนอัตราส่วนค่าใช้จ่ายการประเมิน",
  "title1677": "โปรดป้อนอัตราแลกเปลี่ยนการชำระเงินการประเมิน",
  "title1678": "โปรดป้อนคอมมิชชันของแพลตฟอร์ม",
  "title1679": "โปรดป้อนค่าใช้จ่ายโฆษณา",
  "title1680": "โปรดป้อนจำนวนเงินคืน",
  "title1681": "โปรดป้อนอัตราส่วนการคืนเงิน",
  "title1682": "โปรดป้อนส่วนลดของผู้ขาย",
  "title1683": "โปรดป้อนรายได้ทั้งหมด",
  "title1684": "โปรดป้อนค่าใช้จ่ายทั้งหมด",
  "title1685": "โปรดป้อนค่าใช้จ่ายทั้งหมด",
  "title1686": "โปรดป้อนกำไรโดยประมาณ",
  "title1687": "โปรดป้อนอัตราส่วนกำไร",
  "title1688": "โปรดป้อนกำไรเฉลี่ย",
  "title1689": "โปรดป้อนอัตรากำไร",
  "title1690": "โปรดป้อนอัตราส่วนกำไรของกำไร",
  "title1691": "โปรดป้อนอัตรากำไรค่าใช้จ่าย",
  "title1692": "โปรดป้อนอัตราส่วนกำไรของค่าใช้จ่าย",
  "title1693": "โปรดใส่แพลตฟอร์ม",
  "title1694": "โปรดใส่ภูมิภาค",
  "title1695": "วันต้องไม่ว่างเปล่า",
  "title1696": "สกุลเงินต้องไม่ว่างเปล่า",
  "title1697": "อัตราแลกเปลี่ยนต้องไม่ว่างเปล่า",
  "title1698": "จำนวนคืนต้องไม่ว่างเปล่า",
  "title1699": "ปริมาณคำสั่งการส่งคืนของ MOM ต้องไม่ว่างเปล่า",
  "title1700": "จำนวนการยกเลิกต้องไม่ว่างเปล่า",
  "title1701": "อัตราส่วนการคืนของ MOM ต้องไม่ว่างเปล่า",
  "title1702": "ปริมาณการยกเลิกของ MOM ต้องไม่ว่างเปล่า",
  "title1703": "อัตราการยกเลิกต้องไม่ว่างเปล่า",
  "title1704": "อัตราการยกเลิกของ MOM ต้องไม่ว่างเปล่า",
  "title1705": "จำนวนการประเมินต้องไม่ว่างเปล่า",
  "title1706": "ปริมาณการประเมินของ MOM ต้องไม่ว่างเปล่า",
  "title1707": "ปริมาณคำสั่งที่มีประสิทธิภาพต้องไม่ว่างเปล่า",
  "title1708": "ปริมาณคำสั่งที่มีประสิทธิภาพของ MOM ต้องไม่ว่างเปล่า",
  "title1709": "ปริมาณ bán hàng hiệu quảต้องไม่ว่างเปล่า",
  "title1710": "ปริมาณ bán hàng hiệu quả của MOM ต้องไม่ว่างเปล่า",
  "title1711": "จำนวนคำสั่งต้องไม่ว่างเปล่า",
  "title1712": "จำนวนเงินที่ชำระจริงของผู้ซื้อต้องไม่ว่างเปล่า",
  "title1713": "ปริมาณ bán hàng hiệu quả của MOM ต้องไม่ว่างเปล่า",
  "title1714": "ผู้ซื้อจ่ายค่าขนส่งต้องไม่ว่างเปล่า",
  "title1715": "ค่าใช้จ่ายสินค้าทั้งหมดต้องไม่ว่างเปล่า",
  "title1716": "การเปรียบเทียบต้นทุนของ MOM ต้องไม่ว่างเปล่า",
  "title1717": "ค่าใช้จ่ายอื่นต้องไม่ว่างเปล่า",
  "title1718": "ค่าขนส่งต้องไม่ว่างเปล่า",
  "title1719": "ค่าใช้จ่ายบรรจุต้องไม่ว่างเปล่า",
  "title1720": "จำนวนเงินต้องไม่ว่างเปล่า",
  "title1721": "จำนวนเงินต่อการตรวจสอบต้องไม่ว่างเปล่า",
  "title1722": "จำนวนเงินที่ชำระตรวจสอบต้องไม่ว่างเปล่า",
  "title1723": "ค่า đánh giáต้องไม่ว่างเปล่า",
  "title1724": "ค่าใช้จ่ายตรวจสอบต้องไม่ว่างเปล่า",
  "title1725": "ค่าใช้จ่ายตรวจสอบของ MOM ต้องไม่ว่างเปล่า",
  "title1726": "อัตราการแลกเปลี่ยนการชำระเงินตรวจสอบต้องไม่ว่างเปล่า",
  "title1727": "ค่าคอมมิชชันของแพลตฟอร์มต้องไม่ว่างเปล่า",
  "title1728": "ค่าโฆษณาต้องไม่ว่างเปล่า",
  "title1729": "จำนวนเงินคืนต้องไม่ว่างเปล่า",
  "title1730": "การเปรียบเทียบจำนวนคืนของ MOM ต้องไม่ว่างเปล่า",
  "title1731": "ส่วนลดของผู้ขายต้องไม่ว่างเปล่า",
  "title1732": "รายได้ทั้งหมดต้องไม่ว่างเปล่า",
  "title1733": "ค่าใช้จ่ายทั้งหมดต้องไม่ว่างเปล่า",
  "title1734": "ต้นทุนทั้งหมดต้องไม่ว่างเปล่า",
  "title1735": "กำไรโดยประมาณต้องไม่ว่างเปล่า",
  "title1736": "กำไรของ MOM ต้องไม่ว่างเปล่า",
  "title1737": "กำไรเฉลี่ยต้องไม่ว่างเปล่า",
  "title1738": "ของ Margin ต้องไม่ว่างเปล่า",
  "title1739": "ของ Margin MOM ต้องไม่ว่างเปล่า",
  "title1740": "ของ Margin ต้นทุนต้องไม่ว่างเปล่า",
  "title1741": "ของ Margin ต้นทุนของ MOM ต้องไม่ว่างเปล่า",
  "title1742": "เพิ่มเร็วๆ นี้",
  "title1743": "กำไรในวันสินค้า",
  "title1744": "แก้ไขเร็วๆ นี้",
  "title1745": "ยืนยันการลบเร็วๆ นี้",
  "title1746": "หมายเลขกำไรสินค้าในวัน",
  "title1747": "ยืนยันการส่งออกทั้งหมดเร็วๆ นี้",
  "title1748": "รายการข้อมูลกำไรสินค้าในวัน",
  "title1749": "วันข้อมูล",
  "title1750": "คำโฆษณาคำค้นเดียว",
  "title1751": "ค่าใช้จ่ายเฉลี่ยต่อรายการ (โฆษณาที่เกี่ยวข้อง)",
  "title1752": "เลือกวันที่ข้อมูล",
  "title1753": "โปรดป้อนผู้เยี่ยมชมทั้งหมด",
  "title1754": "โปรดป้อนจำนวนสั่งซื้อทั้งหมด",
  "title1755": "โปรดป้อนผู้เยี่ยมชมโฆษณาคำค้น",
  "title1756": "โปรดป้อนอัตราการคลิกคำค้น",
  "title1757": "โปรดป้อนโฆษณาคำค้นเดี่ยว",
  "title1758": "โปรดป้อนอัตราการแปลงคำค้น",
  "title1759": "โปรดป้อนค่าใช้จ่ายเฉลี่ยต่อรายการ",
  "title1760": "โปรดป้อนโฆษณาคำค้น",
  "title1761": "โปรดป้อนผู้เยี่ยมชมโฆษณาที่เกี่ยวข้อง",
  "title1762": "โปรดป้อนโฆษณาที่เกี่ยวข้องเดี่ยว",
  "title1763": "โปรดป้อนโฆษณาที่เกี่ยวข้อง",
  "title1764": "โปรดป้อนค่าใช้จ่ายเฉลี่ยต่อรายการ (โฆษณาที่เกี่ยวข้อง)",
  "title1765": "โปรดป้อนผู้เยี่ยมชมธรรมชาติ",
  "title1766": "โปรดป้อนอัตราการคลิกธรรมชาติ",
  "title1767": "โปรดป้อนจำนวนสั่งซื้อธรรมชาติ",
  "title1768": "โปรดป้อนอัตราการแปลงธรรมชาติ",
  "title1769": "วันข้อมูลต้องไม่ว่างเปล่า",
  "title1770": "เพิ่มข้อมูลสินค้า",
  "title1771": "แก้ไขข้อมูลสินค้า",
  "title1772": "ยืนยันการลบหมายเลขข้อมูลสินค้าเป็น",
  "title1773": "ยืนยันการส่งออกทุกสินค้าข้อมูล",
  "title1774": "โปรดป้อนการแปลจีน",
  "title1775": "โปรดป้อนปริมาณการค้นหา",
  "title1776": "เพิ่มคำหลักสินค้า",
  "title1777": "แก้ไขคำหลักสินค้า",
  "title1778": "ยืนยันการลบหมายเลขคำหลักสินค้าเป็น",
  "title1779": "ยืนยันการส่งออกทุกคำหลักของสินค้า",
  "title1780": "คำหลักสินค้า",
  "title1781": "จุดขาย",
  "title1782": "เนื้อหาจุดขาย",
  "title1783": "เพิ่มหมายเหตุจุดขาย",
  "title1784": "แก้ไขหมายเหตุจุดขาย",
  "title1785": "ยืนยันการลบหมายเหตุจุดขายเป็น",
  "title1786": "ยืนยันการส่งออกทุกจุดขายหมายเหตุข้อมูล",
  "title1787": "ดำเนินการเป็นชุด",
  "title1788": "ตั้งสถานะการขาย",
  "title1789": "แก้ไขกลุ่ม",
  "title1790": "ตั้งค่ากลุ่ม",
  "title1791": "นำเข้าส ส่งออก",
  "title1792": "นำเข้าการรวมใหม่",
  "title1793": "ส่งออกผลิตภัณฑ์รวม",
  "title1794": "นำเข้าการจับคู่ ",
  "title1795": "ส่งออกการจับคู่",
  "title1796": "ค้นหาเป็นชุด",
  "title1797": "กลุ่มทั้งหมด",
  "title1798": "ข้อมูลสินค้าย่อย",
  "title1799": "ขยาย",
  "title1800": "ร้านค้าที่จับคู่แล้ว",
  "title1801": "ขยายที่จับคู่",
  "title1802": "กลุ่ม",
  "title1803": "เวลา",
  "title1804": "ร้านค้าที่จับคู่",
  "title1805": "โปรดใส่ชื่อสินค้า",
  "title1806": "โปรดใส่สินค้า",
  "title1807": "กลุ่มสินค้า",
  "title1808": "การตั้งค่ากลุ่มสินค้า",
  "title1809": "โปรดเลือกสถานะการขาย",
  "title1810": "สินค้ารวมไหม",
  "title1811": "โปรดเลือกว่าสินค้าเป็นชิ้นเดียวกันหรือไม่",
  "title1812": "ยกเลิกการเลือก",
  "title1813": "เลือกแล้ว",
  "title1814": "ค้นหาเป็นชุด",
  "title1815": "กรุณาใส่หลาย",
  "title1816": "แบ่งด้วยการขึ้นบรรทัด",
  "title1817": "เช่น",
  "title1818": "การผสาน",
  "title1819": "นำเข้าสินค้าใหม่",
  "title1820": "แม่แบบการนำเข้าสินค้า",
  "title1821": "การนำเข้าการจับคู่สินค้า",
  "title1822": "แม่แบบการนำเข้าการจับคู่สินค้า",
  "title1823": "สินค้าย่อย",
  "title1824": "ชื่อสินค้าย่อย",
  "title1825": "ชื่อสินค้าต้องไม่ว่างเปล่า",
  "title1826": "การรวมสินค้าต้องไม่ว่างเปล่า",
  "title1827": "สถานะการขายต้องไม่ว่างเปล่า",
  "title1828": "โปรดเลือกสินค้าอย่างน้อยหนึ่งรายการ",
  "title1829": "ตั้งค่าสำเร็จ",
  "title1830": "โปรดเลือกการดำเนินการสินค้า",
  "title1831": "ยืนยันการลบส",
  "title1832": "ยืนยันการส่งออกคู่สินค้า",
  "title1833": "เพิ่ม Bundle SKU",
  "title1834": "แก้ไขสินค้ารวม",
  "title1835": "ตั้งค่าสถานะการขายสำเร็จ",
  "title1836": "โปรดเลือกสินค้าย่อย",
  "title1837": "โปรดใส่จำนวนสินค้าย่อย",
  "title1838": "ยืนยันการลบสินค้าเป็น",
  "title1839": "เพิ่มสินค้า",
  "title1840": "อัปเดตไปยังคลังสินค้าบุคคลที่สาม",
  "title1841": "ตั้งค่าคลังสินค้า",
  "title1842": "ตั้งระดับกิจกรรม",
  "title1843": "นำเข้าคำสั่งใหม่",
  "title1844": "นำเข้าการอัพเดทสินค้า",
  "title1845": "ส่งออกสินค้า",
  "title1846": "ระดับกิจกรรม",
  "title1847": "ความสมบูรณ์ของสินค้า",
  "title1848": "เลือกทั้งหมด",
  "title1849": "เลือกแล้ว",
  "title1850": "ทั้งหมด",
  "title1851": " คลังสินค้า",
  "title1852": "อัตรากำไรเฉลี่ย",
  "title1853": "การคาดการณ์ยอดขายรายวัน",
  "title1854": "ข้อมูลสินค้าคงคลัง (",
  "title1855": "ร้านที่จับคู่แล้ว",
  "title1856": "น้ำหนักสินค้า",
  "title1857": "ขนาดสินค้า",
  "title1858": "บันทึกสินค้า",
  "title1859": "ข้อมูลการขายออนไลน์",
  "title1860": "เลือกคลังสินค้า",
  "title1861": "โปรดใส่ชื่อคลังสินค้า",
  "title1862": "ชื่อคลังสินค้า",
  "title1863": "นำเข้าการอัพเดทสินค้า",
  "title1864": "แม่แบบการนำเข้าอัพเดตสินค้า",
  "title1865": "สินค้าขายดีมาก",
  "title1866": "สินค้ายอดนิยม",
  "title1867": "สินค้าปกติ",
  "title1868": "สินค้าไม่ขาย",
  "title1869": "โปรดใช้บัญชีหลักหรือบัญชีผู้ดูแลระบบสำหรับการนำเข้าสินค้าและการอนุญาต",
  "title1870": "กรุณาเลือกคลังสินค้า",
  "title1871": "การตั้งค่าคลังสินค้าเสร็จสมบูรณ์",
  "title1872": "คุณแน่ใจหรือไม่ว่าต้องการลบสินค้า",
  "title1873": "การลบไม่สามารถกู้คืนได้และจะลบสินค้าที่เกี่ยวข้องและความสัมพันธ์การจับคู่โดยทันที",
  "title1874": "คุณแน่ใจหรือไม่ว่าต้องการดำเนินการส่งสินค้า",
  "title1875": "หมายเลขเป็น",
  "title1876": "อัปเดตสำเร็จ",
  "title1877": "ข้อมูลทั่วไป",
  "title1878": "เลือกหมวดหมู่",
  "title1879": "ลิงก์แหล่งที่มาของซัพพลายเออร์",
  "title1880": "ป้อนลิงค์แหล่งที่มา",
  "title1881": "คัดลอก",
  "title1882": "ลิงก์แหล่งสินค้า",
  "title1883": "ป้อนลิงค์ที่มา",
  "title1884": "คำอธิบายสินค้า",
  "title1885": "แทรกรูปภาพ",
  "title1886": "หมายเหตุ: คำอธิบายสินค้าสามารถแทรกรูปภาพได้สูงสุด 12 ภาพ",
  "title1887": "ไม่มีรูปภาพถูกแทรก",
  "title1888": "คุณสมบัติของสินค้า",
  "title1889": "ยี่ห้อ",
  "title1890": "ยี่ห้ออื่นๆ",
  "title1891": "ข้อมูลสินค้า",
  "title1892": "ประเภทสินค้า",
  "title1893": "สินค้าตัวเลือกเดียว",
  "title1894": "สินค้าหลายตัวเลือก",
  "title1895": "ราคาพิเศษ",
  "title1896": "เลือกโปรโมชัน ",
  "title1897": " รีเฟรช ",
  "title1898": "ป้อนจำนวนสินค้าในสต็อก",
  "title1899": "ขายส่ง",
  "title1900": "เพิ่มช่วงขายส่ง",
  "title1901": "สื่อภาพ",
  "title1902": "ภาพสินค้า",
  "title1903": "เลือกรูปภาพ",
  "title1904": "รูปแบบภาพ: โปรดอัปโหลดขนาดไฟล์ไม่เกิน",
  "title1905": "ด้วยรูปแบบ",
  "title1906": "; ขนาดรูปภาพต้องไม่น้อยกว่า 100",
  "title1907": "เพิ่มวิดีโอ",
  "title1908": "รูปแบบวิดีโอ: โปรดอัปโหลดขนาดไฟล์ไม่เกิน",
  "title1909": "ระยะเวลา 10",
  "title1910": "ไฟล์",
  "title1911": "โปรดเผยแพร่ผลิตภัณฑ์ภายใน 7 วันหลังจากอัปโหลดวิดีโอ มิฉะนั้นวิดีโอจะถูกลบโดยอัตโนมัติ",
  "title1912": "การขนส่งและการจัดการของ",
  "title1913": "ค่าขนส่ง",
  "title1914": "ป้อนค่าจัดส่ง",
  "title1915": "ป้อนน้ำหนักสินค้า",
  "title1916": "ความยาว",
  "title1917": "สูง",
  "title1918": "พรีออเดอร์",
  "title1919": "สถานะสินค้า",
  "title1920": "ใหม่",
  "title1921": "มือสอง",
  "title1922": "สร้างชื่อสินค้าอัจฉริยะ",
  "title1923": "คำประกาศไม่รับผิดชอบ: ทุกเนื้อหาที่สร้างขึ้นโดยบริการนี้ถูกสร้างโดยอัตโนมัติโดยโมเดล เราไม่สามารถรับประกันความถูกต้องและความสมบูรณ์ของมันได้ เนื้อหาที่สร้างขึ้นเป็นไปเพื่อการอ้างอิงเท่านั้นและไม่แสดงให้เห็นถึงมุมมองและทัศนคติของบริษัท สินเฟินจะไม่รับผิดชอบใดๆ ที่เกิดขึ้นโดยตรงหรือทางอ้อม ๆ จากการใช้บริการนี้",
  "title1924": "โมเดลที่สร้างขึ้นโดยอัตโนมัติ เราไม่สามารถรับประกันความแม่นยำและความสมบูรณ์ของมัน ข้อมูลที่สร้างขึ้นเพื่อการอ้างอิงเท่านั้น และไม่แสดงให้เห็นให้อ่านความเห็นและทัศนคติของ Xinjian Xinjian จะไม่รับผิดชอบต่อความสูญเสียโดยตรงหรือโดยอ้อมที่เกิดขึ้นจากการใช้บริการนี้",
  "title1925": "โอสถจักรสไตล์",
  "title1926": "เพิ่มคำหลักใหม่",
  "title1927": "อธิบายภาพในใจของคุณ คำหลักรวมถึงแบรนด์ วัสดุ คุณลักษณะหลัก และสถานการณ์การใช้งาน เป็นต้น",
  "title1928": "ภาษาของผลลัพธ์ที่สร้าง",
  "title1929": "จำนวนครั้งที่ใช้ฟรีในวันนี้",
  "title1930": "ครั้ง โดยมีค่าใช้จ่าย",
  "title1931": "โปรดคลิก 'เริ่ มการสร้ าง' ทางด้านซ้ายก่อนที่จะแสดงผลลัพธ์ที่สร้างอัตโนมัติที่นี่",
  "title1932": "หมายเลขลำดับ",
  "title1933": "ใช้",
  "title1934": "สร้างคำอธิบายสินค้าอัจฉริยะ",
  "title1935": "ผลลัพธ์ที่สร้าง",
  "title1936": "โปรดเลือกหมวดหมู่",
  "title1937": "โปรดป้อนคำหลักในการค้นหา",
  "title1938": "แก้ไขหมวดหมู่เป็นชุด",
  "title1939": "ตั้งค่ารายการที่เลือกทั้งหมดให้เป็นหมวดหมู่และคุณสมบัติเดียวกัน",
  "title1940": "แก้ไขคุณสมบัติเป็นชุด",
  "title1941": "มีทั้งหมด 3 ผลิตภัณฑ์ที่เลือกแล้ว โปรดแก้ไขคุณสมบัติของแต่ละตัวบ่งชื่อได้",
  "title1942": "แก้ไขรูปภาพเป็นกลุ่ม",
  "title1943": "แต่ละผลิตภัณฑ์สนับสนุนภาพสูงสุดถึง 9 ภาพผลิตภัณฑ์ ลากภาพเพื่อจัดเรียง",
  "title1944": "ตารางขนาด",
  "title1945": "ภาพสินค้า",
  "title1946": "ลากสำหรับการเรียงลำดับ",
  "title1947": "เพิ่มในท้องถิ่น",
  "title1948": "เลือกรูปภาพพื้นที่",
  "title1949": "เลือกรูปภาพคอลเลคชัน",
  "title1950": "เพิ่มรูปภาพคอลเลคชัน",
  "title1951": "ตัวเลือก 1",
  "title1952": "เค้กทอง",
  "title1953": "ตัวเลือก 2",
  "title1954": "นมผิวคู่",
  "title1955": "ตัวเลือก 3",
  "title1956": "ออสเตอร์ออมเลต",
  "title1957": "ตัวเลือก 4",
  "title1958": "เส้นหนังมังกร",
  "title1959": "ตัวเลือก 5",
  "title1960": "เปกิ่งเป็ดย่าง",
  "title1961": "【ขนาดใหญ่】กล่องทรายแมว",
  "title1962": "กล่อง: พื้นที่ส่วนตัวพิเศษสำหรับแมว ทำให้พวกเขาแข็งแรงและมีความสุขมากขึ้น!",
  "title1963": "อื่น ๆ",
  "title1964": "เลือกสินค้า",
  "title1965": "เผยแพร่",
  "title1966": "เพิ่ม SKU Merchant",
  "title1967": "แก้ไขหมวดหมู่",
  "title1968": "แก้ไขแอตทริบิวต์",
  "title1969": "เพิ่มลายน้ำ",
  "title1970": "ก่อนการเผยแพร่ โปรดตรวจสอบและแก้ไขข้อมูลสินค้าเพื่อป้องกันข้อมูลที่เหมือนกันกับสินค้าเดิมและป้องกันการลงโทษจากแพลตฟอร์ม",
  "title1971": "เด็ก",
  "title1972": "ขยายตัวเลือกทั้งหมด",
  "title1973": "เผยแพร่ตามเวลากำหนด",
  "title1974": "ได้ถูกดึงเสร็จแล้ว โปรดตรวจสอบสถานะการดึงภายหลัง",
  "title1975": "หน้าปัจจุบันเฉพาะสินค้า",
  "title1976": "ไปที่คลังสินค้าของบุคคลที่สาม สินค้าที่รวมกันเพียงผลิตภัณฑ์ย่อยเท่านั้นที่ถูกดึง",
  "title1977": "หาก",
  "title1978": "หากรหัสไม่อยู่ในคลังสินค้าของบุคคลที่สาม สินค้านี้จะถูกสร้างขึ้น หากมีอยู่ อัปเดตสินค้านี้",
  "title1979": "ผู้ให้บริการที่แตกต่างกัน ผลิตภัณฑ์",
  "title1980": "อาจมีความแตกต่างในมาตรฐานการตั้งชื่อ และการตั้งชื่อที่ไม่ตรงตามจะทำให้การดึงล้มเหลว",
  "title1981": "อัปเดตข้อมูล",
  "title1982": "เวลาดึง",
  "title1983": "ข้อมูลสินค้าได้รับการอัปเดตแล้ว",
  "title1984": "ซิงค์สินค้า",
  "title1985": "สร้างการจับคู่อย่างรวดเร็ว",
  "title1986": "สร้างสินค้า",
  "title1987": "ผสานสินค้า",
  "title1988": "จับคู่อัตโนมัติ",
  "title1989": "ตั้งค่ากฎการจับคู่",
  "title1990": "ลบการจับคู่",
  "title1991": "ได้รับการจับคู่หรือไม่",
  "title1992": "มีจำหน่ายหรือไม่",
  "title1993": "ข้อมูลสินค้าออนไลน์",
  "title1994": "จับคู่แล้ว",
  "title1995": "ยังไม่จับคู่",
  "title1996": "ข้อมูลสินค้าท้องถิ่น",
  "title1997": "จับคู่",
  "title1998": "จับคู่อีกครั้ง",
  "title1999": "เพิ่มกฎ",
  "title2000": "กฎถูกดำเนินการจากบนลงล่างตามลำดับ",
  "title2001": "ป้อนชื่อกฎ",
  "title2002": "ละเว้นคำนำหน้า",
  "title2003": "ตัวแรก",
  "title2004": "จำนวนหลัก",
  "title2005": "ตัวคั่น",
  "title2006": "เมื่อพบหลายตัวคั่นใช้",
  "title2007": "ใช้ทางซ้ายสุด",
  "title2008": "ใช้ทางขวาสุด",
  "title2009": "ละเว้นคำต่อท้าย",
  "title2010": "อย่าละเลย",
  "title2011": "จุดเริ่มต้น",
  "title2012": "สุดท้าย",
  "title2013": "แทนที่ตัวอักษร",
  "title2014": "ป้อนอักขระ",
  "title2015": "แทนที่",
  "title2016": "ป้อนอักขระสำหรับการแทนที่",
  "title2017": "ละเลยตัวอักษร",
  "title2018": "ป้อนอักขระที่ถูกละเลย หนึ่งบรรทัดต่อหนึ่ง",
  "title2019": "ตัวอย่าง",
  "title2020": "ละเลยช่องว่าง",
  "title2021": "การทดสอบกฎหมาย",
  "title2022": "โปรดป้อนรหัสสินค้า",
  "title2023": "ทดสอบ",
  "title2024": "ผลการทดสอบ",
  "title2025": "ผลการทดสอบ",
  "title2026": "การแปล",
  "title2027": "รวมและแก้ไข",
  "title2028": "รวม",
  "title2029": "และ",
  "title2030": "บันทึก",
  "title2031": "บันทึก",
  "title2032": "หมวดหมู่",
  "title2033": "Default Warehouse",
  "title2034": "การสร้างเสร็จสมบูรณ์",
  "title2035": "กำลังซิงโครไนสินค้าออนไลน์ โปรดรอสักครู่",
  "title2036": "ยืนยันเริ่มซิงค์สินค้าออนไลน์",
  "title2037": "คาดว่าจะสำเร็จในการซิงโครไนสินค้าออนไลน์ใน 1-3 นาที หลังจากซิงโครไนสำเร็จ คุณสามารถดูข้อมูลสินค้าที่อัพเดตได้อย่างสะดวก",
  "title2038": "ระบุคู่โดยอัตโนมัติ",
  "title2039": "กฎการจับคู่",
  "title2040": "ตรง【สินค้าออนไลน์",
  "title2041": "และ [สินค้าท้องถิ่น",
  "title2042": "เหมือนกันทั้งหมด",
  "title2043": "ตามสินค้าออนไลน์",
  "title2044": "การจับคู่ไม่สามารถทำได้หากสินค้าออนไลน์ว่างเปล่า",
  "title2045": "ละเว้นคำหน้าและคำหลังของ【สินค้าออนไลน์",
  "title2046": "เหมือนกัน",
  "title2047": "ป้อนเนื้อหาที่ต้องการละเว้น ใช้เครื่องหมายจุลละเว้นหลายรายการ",
  "title2048": "ตัดทอนจาก【สินค้าออนไลน์",
  "title2049": "ตัด",
  "title2050": "ป้อนจำนวนตัวอักษรที่ต้องการตัด",
  "title2051": "ถึง",
  "title2052": "ตัวอักษร",
  "title2053": "ละเว้นตำแหน่งอักขระที่ระบุหลัง【สินค้าออนไลน์",
  "title2054": "ตัวอักษรที่ระบุ",
  "title2055": "ป้อนเนื้อหาที่ระบุ ใช้",
  "title2056": "ระบุตัวอักษร",
  "title2057": "ป้อนเนื้อหาที่ระบุไว้, คั่นด้วยจุลภาค",
  "title2058": "ไม่สนใจตัวเล็ก-ใหญ่",
  "title2059": "ช่วงสินค้าที่ตรงกัน",
  "title2060": "สินค้าในหน้าปัจจุบัน",
  "title2061": "ผลลัพธ์กรองทั้งหมดสินค้า",
  "title2062": "สินค้าที่เลือกไว้",
  "title2063": "เริ่ม",
  "title2064": "ระบุผลลัพธ์การจับคู่โดยอัตโนมัติ",
  "title2065": "การจับคู่อัตโนมัติสำเร็จ",
  "title2066": "สินค้า, ยืนยันการบันทึก",
  "title2067": "ลบเป็นกลุ่ม",
  "title2068": "จับคู่ข้อมูลสินค้าท้องถิ่น",
  "title2069": "ลบ",
  "title2070": "ขั้นตอนก่อนหน้า",
  "title2071": "ยังไม่ลง",
  "title2072": "ตัวอักษรคงที่ที่ต้นหน้า",
  "title2073": "จำนวนหลักคงที่ที่ต้นหน้า",
  "title2074": "ตัวอักษรก่อนเครื่องหมายแยก",
  "title2075": "ชื่อกฎข้อบกวนต้องไม่ว่างเปล่า",
  "title2076": "ยืนยันการจับคู่สินค้าที่เลือกโดยอัตโนมัติ",
  "title2077": "รายการสินค้า",
  "title2078": "มีรายการซ้ำ",
  "title2079": "ยืนยันยกเลิกการจับคู่",
  "title2080": "การยกเลิกการจับคู่สำเร็จ",
  "title2081": "กำลังซิงโครไนซ์สินค้าออนไลน์ กรุณาตรวจสอบในภายหลัง",
  "title2082": "โปรดกำหนดกฎจับคู่ก่อน",
  "title2083": "การจับคู่สำเร็จ",
  "title2084": "กฎถูกบันทึกเรียบร้อย",
  "title2085": "โปรดใช้บัญชีหลักหรือบัญชีผู้ดูแลเพื่อสร้างสินค้าและอำนาจ",
  "title2086": "การจับคู่สำเร็จ",
  "title2087": "ชั้น",
  "title2088": "เพิ่มเป็นเดี่ยว",
  "title2089": "เพิ่มเป็นชุด",
  "title2090": "พิมพ์ Label ตำแหน่ง",
  "title2091": "สต็อกที่มีอยู่",
  "title2092": "คลิกเพื่อยุบ",
  "title2093": "ขยายส่วนที่เหลือ",
  "title2094": "คลังสินค้า",
  "title2095": "ชื่อตำแหน่ง",
  "title2096": "ประเภท:",
  "title2097": "เลขชั้น",
  "title2098": "ว่าง",
  "title2099": "ว่างบางส่วน",
  "title2100": "เต็ม",
  "title2101": "สร้าง",
  "title2102": "อัพเดต",
  "title2103": "ทำความสะอาด",
  "title2104": "ต้องระบุชื่อตำแหน่ง",
  "title2105": "ความจุสูงสุด",
  "title2106": "โปรดป้อนความจุสูงสุด",
  "title2107": "คำนำหน้า",
  "title2108": "แถว",
  "title2109": "ชั้น",
  "title2110": "เครื่องหมายวรรคตอน (-)",
  "title2111": "ดูตัวอย่าง",
  "title2112": "ล้างได้",
  "title2113": "เลขชั้นไม่สามารถว่าง",
  "title2114": "โปรดป้อนเลขแถว",
  "title2115": "โปรดป้อนเลขชั้น",
  "title2116": "เพิ่มตำแหน่ง",
  "title2117": "ยืนยันลบตำแหน่งชั้นหรือไม่?",
  "title2118": "แก้ไขข้อมูลชั้น",
  "title2119": "ยืนยันความสะอาดปริมาณเป็น 0?",
  "title2120": "?",
  "title2121": "การทำความสะอาดเรียบร้อย",
  "title2122": "ล้มเหลว",
  "title2123": "ซิงค์สินค้าคลังของบุคคลที่สาม",
  "title2124": "พิมพ์ฉลากราคา",
  "title2125": "แก้ไขต้นทุนสินค้า",
  "title2126": "แก้ไขค่าบรรจุสินค้า",
  "title2127": "นำเข้าต้นทุนสินค้า",
  "title2128": "นำเข้าค่าบรรจุสินค้า",
  "title2129": "ส่งออกสินค้าคงคลัง",
  "title2130": "นำเข้าต้นทุน",
  "title2131": "สถานะสินค้าคงคลัง",
  "title2132": "จำนวนทั้งหมดของสต็อก",
  "title2133": "มูลค่ารวมของสต็อก",
  "title2134": "ปริมาณที่ถูกแช่แข็ง",
  "title2135": "มูลค่าที่ถูกแช่แข็ง",
  "title2136": "ปริมาณที่กำลังจัดส่ง",
  "title2137": "มูลค่าที่กำลังจัดส่ง",
  "title2138": "การจัดหา",
  "title2139": "ตำแหน่ง",
  "title2140": "มูลค่าสินค้าคงคลัง",
  "title2141": "ยอดขายเฉลี่ยต่อวัน",
  "title2142": "รณ์ยอดขายรายวัน(แบบไดนามิก)",
  "title2143": "ปริมาณสินค้าอยู่ในคลัง",
  "title2144": "วันที่ของหมด",
  "title2145": "จำนวนสินค้าที่แนะนำเมื่อสินค้าหมด",
  "title2146": "จำนวนสินค้าที่แนะนำเมื่อสินค้าขาด",
  "title2147": "จำนวนสินค้าขาด",
  "title2148": "วันที่แนะนำให้ซื้อ",
  "title2149": "เวลาโดยประมาณของการมาถึงของชุดล่าสุด",
  "title2150": "ตั้งค่าตำแหน่ง",
  "title2151": "โปรดป้อนต้นทุนสินค้า",
  "title2152": "แก้ไขปริมาณขายเมื่อเร็ว",
  "title2153": "ปริมาณขายเมื่อเร็ว",
  "title2154": "โปรดป้อนปริมาณขายเมื่อเร็ว",
  "title2155": "การแก้ไขพารามิเตอร์สินค้าคงคลังอย่างจำนวนมาก",
  "title2156": "วันที่ผลิต",
  "title2157": "โปรดป้อนจำนวนวันการผลิต",
  "title2158": "วันที่ส่งทางทะ,",
  "title2159": "โปรดป้อนจำนวนวันการขนส่งทางเรือ",
  "title2160": "วันสต็อกพิเศษ",
  "title2161": "โปรดป้อนวันสต็อกพิเศษ",
  "title2162": "สูตรคำนวณสต็อกปลาฉลาม",
  "title2163": "(วันสต็อกพิเศษ)",
  "title2164": "ปริมาณการขายเฉลี่ยรายวันใน 15 วันที่ผ่านมา",
  "title2165": "นำเข้าสินค้าคงคลัง",
  "title2166": "แม่แบบนำเข้าสินค้าคงคลัง",
  "title2167": "แม่แบบนำเข้าค่าบรรจุสินค้า",
  "title2168": "สินค้าคงคลังทั้งหมดในคลังของบุคคลที่สาม",
  "title2169": "ความแตกต่างในสินค้าคงคลังระหว่างฝ่ายทั้งสอง",
  "title2170": "สินค้าคงคลังที่ใช้ได้ที่คาดหวังอัพเดต",
  "title2171": "สถานะล็อคสินค้า",
  "title2172": "จำนวน",
  "title2173": "ล็อค",
  "title2174": "ป้อนสินค้าหลายรายการ",
  "title2175": "ใช้ขึ้นบรรทัดเพื่อแบ่ง",
  "title2176": "แยกด้วยเว้นบรรทัดหรือจุลภาค",
  "title2177": "ปรับเปลี่ยนต้นทุนสินค้าเป็นชุด",
  "title2178": "ต่ำกว่าระดับคำเตือน",
  "title2179": "ปริมาณขายรายวันเริ่มต้น",
  "title2180": "อัปเดตสำเร็จ",
  "title2181": "โปรดเลือกการดำเนินการข้อมูล",
  "title2182": "ปรับเปลี่ยนค่าบรรจุเป็นชุด",
  "title2183": "ปรับเปลี่ยนสต็อกปลาฉลาม",
  "title2184": "สินค้าในคลัง",
  "title2185": "ยืนยันการลบสินค้าที่เลือกในคลังสินค้า",
  "title2186": "ยืนยันการลบสินค้าที่มีหมายเลขคลังสินค้า",
  "title2187": "ยืนยันการตั้งตำแหน่งชั้นวางของ",
  "title2188": "ดำเนินการสำเร็จ",
  "title2189": "สินค้าคงคลัง -",
  "title2190": "อัปโหลดตารางข้อมูลที่กรอกเสร็จสมบูรณ์",
  "title2191": "กรุณาก่อน",
  "title2192": "ส่งออกข้อมูลแบบฟอร์ม",
  "title2193": "ชื่อหัวเรื่องไม่สามารถเปลี่ยนแปลงได้, แถวหัวเรื่องไม่สามารถลบได้",
  "title2194": "นามสกุลไฟล์ต้องเป็น",
  "title2195": "นั่นคือ",
  "title2196": "รูปแบบ), ขนาดไฟล์ต้องไม่ใหญ่กว่า",
  "title2197": "รองรับการนำเข้าสูงสุด",
  "title2198": "รายการข้อมูล",
  "title2199": "อัปโหลดไฟล์",
  "title2200": "ดาวน์โหลดเทมเพลต",
  "title2201": "การนำเข้าสำเร็จ! คุณได้นำเข",
  "title2202": "รายการข้อมูล",
  "title2203": "ไม่มีข้อมูลในไฟล์ โปรดตรวจสอบ",
  "title2204": "การนำเข้าล้มเหลว! โปรดตรวจสอบรายงานข้อผิดพลาดหรือตรวจสอบว่าแม่แบบไม่ถูกต้องก่อนนำเข้าใหม่",
  "title2205": "ดาวน์โหลดรายงานข้อผิดพลาด",
  "title2206": "นำเข้าอีกครั้ง",
  "title2207": "ไฟล์ผิดพลาด",
  "title2208": "โปรดอัปโหลดไฟล์",
  "title2209": "In-Stock Value",
  "title2210": "วันที่สามารถขายได้",
  "title2211": "Value in Transit",
  "title2212": "ดูข้อมูลล่วงหน้า",
  "title2213": "นำเข้าข้อมูล",
  "title2214": "การนำเข้าเสร็จสมบูรณ์",
  "title2215": "การตั้งค่าอายุคลังสินค้า",
  "title2216": "สินค้าคงคลังทั้งหมด",
  "title2217": "ต้นทุนสินค้าคงคลังทั้งหมด",
  "title2218": "อายุคลังสินค้าเฉลี่ย",
  "title2219": "In-stock Cost",
  "title2220": "จำนวนกลุ่ม",
  "title2221": "จำนวนวันขั้นต่ำ",
  "title2222": "จำนวนวันสูงสุด",
  "title2223": "กลุ่มแรก",
  "title2224": "กลุ่มที่สอง",
  "title2225": "กลุ่มที่สาม",
  "title2226": "กลุ่มที่สี่",
  "title2227": "กลุ่มที่ห้า",
  "title2228": "วันหรือมากกว่า",
  "title2229": "จำนวนวันเป็นจำนวนเต็มมากกว่า 0",
  "title2230": "จำนวนวันสูงสุดของกลุ่มแรกต้องไม่เว้นว่าง",
  "title2231": "จำนวนวันสูงสุดของกลุ่มที่สองต้องไม่เว้นว่าง",
  "title2232": "จำนวนวันสูงสุดของกลุ่มที่สามต้องไม่เว้นว่าง",
  "title2233": "จำนวนวันสูงสุดของกลุ่มที่สี่ต้องไม่เว้นว่าง",
  "title2234": "ตั้งค่าจำนวนวันการสั่งซื้อ",
  "title2235": "วันที่แนะนำให้ซื้อ",
  "title2236": "วันที่คาดว่าจะหมดสต็อก",
  "title2237": "เวลาการจัดส่งล่าสุด",
  "title2238": "ภาพหลัก",
  "title2239": "โครงการการจัดซื้อ",
  "title2240": "สต็อกคลังสินค้าต่างประเทศ",
  "title2241": "ค่าเฉลี่ยรายวัน",
  "title2242": "จำนวนวันหมุนเวียน",
  "title2243": "วันที่คาดว่าจะหมดสต็อก",
  "title2244": "ปริมาณการซื้อแนะนำ",
  "title2245": "จำนวนระหว่างทาง",
  "title2246": "ปริมาณการเข้าสู่คลังที่ประมาณล",
  "title2247": "ต้นทุนสินค้าต้องไม่ว่างเปล่า",
  "title2248": "ยอดขายในช่วง 15 วันที่ผ่านมา",
  "title2249": "สินค้าคงคลังจริง",
  "title2250": "ปริมาณการซื้อแนะนำ",
  "title2251": "สต็อกปลอดภัย - สต็อกในการขนส่ง - สต็อกจริง",
  "title2252": "การสั่งซื้อระหว่างทาง",
  "title2253": "การโอนส่งระหว่างทาง",
  "title2254": "ยืนยันการส่งออกรายการข้อมูลคลังสินค้าทั้งหมด",
  "title2255": "สินค้าคงคลัง",
  "title2256": "สินค้าคงคลังบนแพลตฟอร์ม",
  "title2257": "เปิดหน้าเว็บ",
  "title2258": " ดำเนินการแล้ว",
  "title2259": "ยืนยันการดำเนินการไปแล้ว",
  "title2260": "รายงานกำไรร้านเดือนละ",
  "title2261": "รายงานกำไรการดำเนินการรายเดือน",
  "title2262": "จัดการการรับสินค้า",
  "title2263": "คลังสำหรับคำสั่งซื้อสำหรับคลังสินค้าของบริษัทขนส่งชั้นสามไม่รองรับการรับสินค้าด้วยมือและระบบจะซิงค์ผลการรับสินค้าจากคลังสินค้าของบริษัทขนส่งชั้นสามโดยอัตโนมัติ",
  "title2264": "คลังสินค้าจัดส่ง",
  "title2265": "คลังสินค้าที่รับ",
  "title2266": "เวลาจัดส่ง",
  "title2267": "เวลารับสินค้า",
  "title2268": "จำนวนโอนสินค้า",
  "title2269": "จำนวนรับสินค้า",
  "title2270": "หมายเลขใบโอนย้าย",
  "title2271": "หมายเหตุการโอนย้าย",
  "title2272": "หมายเลขแทร็คกิ้ง",
  "title2273": "จำนวนการโอนทั้งหมด",
  "title2274": "สถานะรั บสินค้ า",
  "title2275": "รอรับสินค้า",
  "title2276": "รับสินค้าบางส่วน",
  "title2277": "รับสินค้าครบแล้ว",
  "title2278": "ยืนยันรับสินค้า",
  "title2279": "จำนวนการสั่งซื้อ",
  "title2280": "ชื่อใบสั่งซื้อ",
  "title2281": "หมายเหตุการซื้อ",
  "title2282": "หมายเลขใบสั่งซื้อ",
  "title2283": "จำนวนการสั่ งซื้ อ",
  "title2284": "แก้ไขหมายเหตุ",
  "title2285": "โปรดกรอกข้อมูลหมายเหตุ",
  "title2286": "การโอนรับสินค้า",
  "title2287": "หมายเลขใบโอนย้าย：",
  "title2288": "จำนวนรั บสินค้ าแล้ ว",
  "title2289": "ไม่มีตำแหน่ง",
  "title2290": "การรับสินค้าสั่งซื้อ",
  "title2291": "คลังสินค้าที่รับ",
  "title2292": "วันที่จัดส่ง",
  "title2293": "ค่า First-mile",
  "title2294": "ค่าภาษี",
  "title2295": "ค่าใช้จ่ายอื่น",
  "title2296": "จำนวนสินค้า:",
  "title2297": "รวม:",
  "title2298": "รวม",
  "title2299": "ปริมาณ",
  "title2300": "น้ำหนัก",
  "title2301": "คลังสินค้าส่งไม่สามารถเว้นว่างได้",
  "title2302": "คลังสินค้ารับไม่สามารถเว้นว่างได้",
  "title2303": "โปรดเลือกโครงการ",
  "title2304": "เปลี่ยนคลังสินค้าส่งจะล้างรายการที่เพิ่มไว้ คุณแน่ใจหรือว่าต้องการอัปเดต",
  "title2305": "โปรดใส่จำนวนการรับ",
  "title2306": "ได้รับสำเร็จ",
  "title2307": "โอนสินค้า",
  "title2308": "รายงาน",
  "title2309": "โปรดป้อนชื่อใบสั่งซื้อ",
  "title2310": "วันที่สร้าง",
  "title2311": "ราคาต่อหน่วย",
  "title2312": "จำนวนเงินทั้งหมดที่ซื้อ",
  "title2313": "จำนวน Stock-In",
  "title2314": "เลือก",
  "title2315": "คำแนะนำสินค้าหมด",
  "title2316": "ใบจัดซื้อ",
  "title2317": "สร้างใบสั่งซื้อ",
  "title2318": "การส่งข้อมูลออกใบสั่งซื้อ",
  "title2319": "ส่งในชุด",
  "title2320": "ยังไม่ต้องทำการจัดซื้อ",
  "title2321": "เปลี่ยนกลับ",
  "title2322": "กฎการตั้งค่า",
  "title2323": "คำแนะนำสินค้าหมดสต็อก",
  "title2324": "จำนวนวันการสั่งซื้อ",
  "title2325": "เวลาโดยประมาณจากการสั่งซื้อไปยังเก็บคลังสินค้า, วัน",
  "title2326": "วันขนส่ง",
  "title2327": "วันปลอดภัย ",
  "title2328": "วันการสั่งซื้อที่ต้องเตรียมไว้เพื่อป้องกันไม่ให้สินค้าที่สั่งมามาถึงช้า",
  "title2329": "ยอดขายรวมใน 3 วัน",
  "title2330": "ยอดขายเฉลี่ยต่อวันใน 3 วัน",
  "title2331": "จำนวนที่แนะนำ",
  "title2332": "สิ่งที่ใหญ่กว่าของคำแนะนำสินค้าหมดสต็อกและคำแนะนำสินค้าหมด",
  "title2333": "ระยะเวลาสต็อก",
  "title2334": "เวลาเตรียมสต็อก - สินค้ามีจำหน่าย - ปริมาณในการขนส่ง",
  "title2335": "จำนวนการวางแผน",
  "title2336": "จำนวนจัดซื้อทั้งหมด",
  "title2337": "การมาถึงที่คาดว่า",
  "title2338": "รายการสินค้า",
  "title2339": "โปรดป้อนรายการสินค้า",
  "title2340": "เวลาที่จะมาถึงโดยประมาณ",
  "title2341": "กรุณาเลือกวันที่",
  "title2342": "โปรดป้อนหมายเลขใบขนส่ง",
  "title2343": "โปรดป้อนค่าจัดส่ง",
  "title2344": "เลือกวิธีแบ่งทุน",
  "title2345": "โปรดระบุค่าใช้จ่ายหัว",
  "title2346": "โปรดระบุค่าภาษี",
  "title2347": "โปรดระบุค่าธรรมเนียมอื่นๆ",
  "title2348": "ความคิดเห็นการตรวจสอบ",
  "title2349": "ความคิดเห็นจำเป็นเมื่อปฏิเสธ",
  "title2350": "โปรดใส่ความคิดเห็นการตรวจสอบ",
  "title2351": "โปรดใส่ข้อมูลหมายเหตุ",
  "title2352": "ค่าใช้จ่ายที่แบ่งสรร",
  "title2353": "ค่าใช้จ่ายในการเก็บของ",
  "title2354": "นำเข้าใบสั่งซื้อ",
  "title2355": "แม่แบบการนำเข้าใบสั่งซื้อ",
  "title2356": "คลังสินค้าต้องไม่ว่างเปล่า",
  "title2357": "ชื่อใบสั่งซื้อต้องไม่ว่างเปล่า",
  "title2358": "รายการสินค้าต้องไม่ว่างเปล่า",
  "title2359": " สร้างใบสั่งซื้อ",
  "title2360": "ดูใบสั่งซื้อ",
  "title2361": "แก้ไขใบสั่งซื้อ",
  "title2362": "การส่งสำเร็จ",
  "title2363": "คุณแน่ใจหรือว่าต้องการยกเลิกใบสั่งซื้อ",
  "title2364": "คุณแน่ใจหรือว่าต้องการรับและนำเข้าคลัง",
  "title2365": "คุณแน่ใจหรือว่าต้องการลบใบสั่งซื้อที่มีชื่อ",
  "title2366": "ของใบสั่งซื้อ?",
  "title2367": "คุณแน่ใจหรือว่าต้องการส่งออกข้อมูลใบสั่งซื้อทั้งหมด?",
  "title2368": "คุณแน่ใจหรือว่าต้องการส่งออกคำแนะนำในการซื้อ?",
  "title2369": "ภาพรวมการคืนสินค้า",
  "title2370": "รายงานการคืนสินค้า",
  "title2371": "รายละเอียดการคืนสินค้า",
  "title2372": "สถิติข้อมูลกำไรร้าน",
  "title2373": "วิเคราะห์และสถิติง่าย ๆ สถานะการดำเนินงานของร้านค้า ช่วยเจ้าของร้านเข้าใจสถานการณ์กำไรของร้าน และปรับปรุงกลยุทธ์ธุรกิจเพื่อเพิ่มกำไร",
  "title2374": "เลือกทั้งหมด",
  "title2375": "โปรดเลือกบุคคลดำเนินงาน",
  "title2376": "เลือกวันที่",
  "title2377": "รายได้จากการสั่งซื้อ",
  "title2378": "รายละเอียดรายได้",
  "title2379": "รายงาน",
  "title2380": "จำนวนเงินสินค้า",
  "title2382": "ส่วนลดทางแพลตฟอร์ม",
  "title2383": "สนับสนุนค่าจัดส่งจาก",
  "title2384": "ต้นทุน",
  "title2385": "รายละเอียดค่าใช้จ่าย",
  "title2386": "ค่าโฆษณา",
  "title2387": "รายละเอียดโปรโมชั่น",
  "title2388": "ค่าโฆษณาในไซต์",
  "title2389": "ค่าโฆษณานอกเว็บไซต์",
  "title2390": "จำนวนการชำระเงินในการประเมิน",
  "title2391": "จำนวนเงินคืนในการประเมิน",
  "title2392": "ค่าใช้จ่ายในการดำเนินงาน",
  "title2393": "ค่าซองว่าง",
  "title2394": "จำนวนการชำระเงินในการประเมิน - จำนวนเงินคืนในการประเมิน",
  "title2395": "รายละเอียดอื่น ๆ",
  "title2396": "การปรับเปลี่ยนระหว่างเดือน",
  "title2397": "การปรับปรุงรวมสำหรับคำสั่งที่มีสถานการณ์การชำระเงินระหว่างเดือน",
  "title2398": "การปรับเปลี่ยนของแพลตฟอร์ม",
  "title2399": "ค่าธรรมเนียมรวมสำหรับการปรับแพลตฟอร์ม",
  "title2400": "ค่าใช้จ่ายของร้าน",
  "title2401": "รายละเอียดกำไร",
  "title2402": "อัตรากำไร",
  "title2403": "การกระจายความสัมพันธ์ของค่าใช้จ่าย",
  "title2404": "การกระจายของค่าใช้จ่ายเป็นเปอร์เซ็นต์ของยอดขาย",
  "title2405": "ข้อมูลกำไรของร้าน",
  "title2406": "ตามเวลาการชำระเงิน",
  "title2407": "ตามเวลาการสั่งซื้อ",
  "title2408": "โปรดเลือกเดือนที่ต้องการอัปเดต",
  "title2409": "รายงานรายได้รายเดือน -",
  "title2410": "ร้านค้าได้รับการอนุญาตเรียบร้อยแล้ว",
  "title2411": "ขอแสดงความยินดีคุณได้ทำการอนุญาตร้านค้าเรียบร้อยแล้ว",
  "title2412": "ก่อนที่ผู้ซื้อจะรับ",
  "title2413": "สำหรับคำสั่งซื้อที่กำหนดให้โกดังจัดส่งเป็นโกดังบุคคลที่สาม โกดังรับจะถูกกำหนดเป็นโกดังจัดส่งตามค่าเริ่มต้นเมื่อคืนสินค้า หลังจากที่โกดังบุคคลที่สามได้รับสินค้าคืนเรียบร้อยแล้ว ระบบจะทำการซิงโครไนซ์ผลการรับสินค้าของโกดังบุคคลที่สามโดยอัตโนมัติ",
  "title2414": "ซิงค์คำสั่งซื้อ",
  "title2415": "ผลัดส่งใบสั่งซื้อคืน",
  "title2416": "สถานะ Stock-In",
  "title2417": "เวลาครบกำหนด",
  "title2418": "หมายเลขใบ",
  "title2419": "เหตุผลในการคืนสินค้า",
  "title2420": "เวลาใบสั่งซื้อ",
  "title2421": "เวลาหลังการขาย",
  "title2422": "สถานะแพลตฟอร์ม",
  "title2423": "ใน",
  "title2424": "ตอบกลับใน",
  "title2425": "กรุณาใน",
  "title2426": "ก่อนตอบ",
  "title2427": "หมายเลขคืนสินค้า",
  "title2428": "หมายเลขคืนสินค้า",
  "title2429": "โปรดป้อนหมายเลขการส่งคืนใบสั่งซื้อ",
  "title2430": "โปรดป้อนเหตุผล",
  "title2431": "สถานะการคืนสินค้ า",
  "title2432": "โปรดเลือกการสร้างพจนานุกรม",
  "title2433": "ส่งกลับสถานะท้องถิ่น",
  "title2434": "เวลาสร้างคำสั่งคืนกลับ",
  "title2435": "เลือกเวลาสร้างคำสั่งคืนกลับ",
  "title2436": "เวลาอัปเดตคำสั่งคืนกลับ",
  "title2437": "เลือกเวลาอัปเดตคำสั่งคืนกลับ",
  "title2438": "เลขคำสั่งซื้อการคืนไม่สามารถเว้นว่างได้",
  "title2439": "เหตุผลไม่สามารถเว้นว่างได้",
  "title2440": "สถานะการคืนไม่สามารถเว้นว่างได้",
  "title2441": "สถานะการคืนไม่สามารถเว้นว่างได้",
  "title2442": "สถานะภาคท้องถิ่นของการคืนไม่สามารถเว้นว่างได้",
  "title2443": "เวลาสร้างคำสั่งซื้อการคืนไม่สามารถเว้นว่างได้",
  "title2444": "เวลาอัปเดตคำสั่งซื้อการคืนไม่สามารถเว้นว่างได้",
  "title2445": "เวลาการสร้างคำสั่งคืนต้องไม่ว่างเปล่า",
  "title2446": "เวลาการอัปเดตคำสั่งคืนต้องไม่ว่างเปล่า",
  "title2447": "เพิ่มคำสั่งคืน",
  "title2448": "แก้ไขคำสั่งคืน",
  "title2449": "คุณแน่ใจหรือไม่ว่าจะลบหมายเลขคำสั่งคืนเป็น",
  "title2450": "คุณแน่ใจหรือไม่ว่าต้องการส่งออกข้อมูลคำสั่งคืนทั้งหมด",
  "title2451": "คำสั่งคืน",
  "title2452": "ทุกไซต์",
  "title2453": "ที่จะ",
  "title2454": "ผู้ดำเนินการ",
  "title2455": "การประมวลผลในคลัง",
  "title2456": "ดูรายละเอียด",
  "title2457": "เพิ่มคำอธิบาย",
  "title2458": "เหตุผลในการคืนสินค้า",
  "title2459": "รายละเอียดของสินค้าที่เก็บไว้",
  "title2460": "การประมวลผลการจัดเก็บ",
  "title2461": "ว่าจะเก็บข้อมูลหรือไม่",
  "title2462": "เข้าคลัง",
  "title2463": "ไม่บันทึกทั้งหมด",
  "title2464": "เมื่อประมวลผลเข้าคลัง ใบสั่งซื้อคืนจะไม่สามารถยกเลิกการเก็บได้",
  "title2465": "โปรดดำเนินการอย่างระมัดระวัง",
  "title2466": "หมายเลขพัสดุ",
  "title2467": "ปิด",
  "title2468": "闭",
  "title2469": "จำนวนเงินชดเชย",
  "title2470": "ป้อนคำอธิบาย",
  "title2471": "วันที่คำอธิบาย",
  "title2472": "ข้อมูลคำอธิบาย",
  "title2473": "เหตุผลในการคืนสินค้า",
  "title2474": "โปรดป้อนจำนวนเต็มบวก",
  "title2475": "หมายเลขการสั่งซื้อหลังการขาย",
  "title2476": "หมายเลขใบสั่งซื้อสินค้า",
  "title2477": "โปรดเลือกว่าจะทำการเก็บไว้หรือไม่",
  "title2478": "การจัดการคืนสินค้า",
  "title2479": "ป้อนชื่อบทบาท",
  "title2480": "สถานะบทบาท",
  "title2481": "ชื่อบทบาทหน้าที่",
  "title2482": "สิทธิ์ทางฟังก์ชัน",
  "title2483": "การกำหนดสิทธิ์",
  "title2484": "สิทธิ์ข้อมูล",
  "title2485": "โปรดป้อนชื่อบทบาทหน้าที่",
  "title2486": "รหัสบทบาท",
  "title2487": "โปรดระบุรหัสบทบาท",
  "title2488": "ลำดับบทบาท",
  "title2489": "ใส่ข้อมูลหมายเหตุ",
  "title2490": "สิทธิ์เมนู",
  "title2491": "กำลังโหลด โปรดรอ",
  "title2492": "กำหนดสิทธิ์ข้อมูล",
  "title2493": "ขอบเขตสิทธิ์",
  "title2494": "การเชื่อมโยงผู้ปกครองลูก",
  "title2495": "เลือกโหนดหลัก โหนดย่อยอัตโนมัติ",
  "title2496": "ชื่อบทบาทไม่สามารถเว้นว่างได้",
  "title2497": "รหัสบทบาทไม่สามารถเว้นว่างได้",
  "title2498": "ลำดับบทบาทไม่สามารถเว้นว่างได้",
  "title2499": "เปิดใช้งาน",
  "title2500": "ปิดการใช้งาน",
  "title2501": "ยืนยันว่าต้องการบทบาทไหม",
  "title2502": "ชื่อร้านค้าแทนกัน",
  "title2503": "เพิ่ม Role",
  "title2504": "แก้ไขบทบาท",
  "title2505": "แก้ไขเรียบร้อย",
  "title2506": "ยืนยันการลบบทบาท:",
  "title2507": "ยืนยันการส่งออกรายการข้อมูลบทบาททั้งหมด",
  "title2508": "ข้อมูลบทบาท",
  "title2509": "โปรดใส่คำหลัก",
  "title2510": "โปรดเลือกแพลตฟอร์ม",
  "title2511": "หมดอายุ",
  "title2512": "เชื่อมต่อแล้ว",
  "title2513": "เวลารีเฟรช",
  "title2514": "เวลาเชื่อมต่อ",
  "title2515": "คลิกที่ 'ให้ สิทธิ' หลังจากนั้น",
  "title2516": "สถานะการเชื่อมต่อ",
  "title2517": "จะเปิดหน้าผู้ให้สิทธิสำหรับคุณที่ให้สิทธิ",
  "title2518": "จะเปิดให้คุณ",
  "title2519": "อนุญาตในหน้าการให้สิทธิ",
  "title2520": "โปรดป้อนชื่อร้านค้า",
  "title2521": "เชื่อมต่อ",
  "title2522": "ชื่อร้านค้าไม่สามารถเว้นว่างได้",
  "title2523": "เพิ่มการให้สิทธิร้านค้า",
  "title2524": "แก้ไขร้านค้า",
  "title2525": "รีเฟรชสิทธิ์เรียบร้อยแล้ว",
  "title2526": "รีเฟรชสิทธิ์ล้มเหลว โปรดให้สิทธิ์อีกครั้ง",
  "title2527": "เพิ่มการให้สิทธิ์ร้านค้าใหม่เรียบร้อยแล้ว",
  "title2528": "โปรดระมัดระวัง เมื่อลบร้านค้าจะลบข้อมูลต่อไปนี้: ข้อมูลสินค้าร้านค้าจะถูกลบอย่างสมบูรณ์และไม่สามารถกู้คืนได้ ข้อมูลคำสั่งซื้อร้านค้าจะถูกลบและไม่สามารถกู้คืนได้ ข้อมูลการตั้งค่าที่เกี่ยวข้องกับร้านค้าจะถูกลบอย่างสมบูรณ์และไม่สามารถกู้คืนได้",
  "title2529": "ยืนยันการส่งออกรายการข้อมูลร้านค้าทั้งหมด",
  "title2530": "สร้างเรียบร้อยแล้ว",
  "title2531": "อัตราการหมุนเวียน",
  "title2532": "จำนวนรีวิวใหม่",
  "title2533": "โปรดป้อนคะแนนร้านค้า",
  "title2534": "เลือกเวลาจัดส่ง",
  "title2535": "โปรดป้อนอัตราการส่งสินค้าหลังกำหนดเวลา",
  "title2536": "โปรดป้อนอัตราการไม่สมบูรณ์",
  "title2537": "โปรดป้อนอัตราการตอบกลับ",
  "title2538": "เลือกเวลารอตอบ",
  "title2539": "โปรดป้อนอัตราการหมุนเวียน",
  "title2540": "โปรดป้อนจำนวนรีวิวใหม่",
  "title2541": "เพิ่มข้อมูลร้านค้า",
  "title2542": "แก้ไขข้อมูลร้านค้า",
  "title2543": "ยืนยันการลบข้อมูลร้านค้าด้วยรหัส",
  "title2544": "ยืนยันการส่งออกรายการข้อมูลร้านค้าทั้งหมด",
  "title2545": "เลือกเดือนค่าใช้จ่าย",
  "title2546": "โปรดเลือกประเภทค่าใช้จ่าย",
  "title2547": "เดือนค่าใช้จ่าย",
  "title2548": "โปรดป้อนจำนวน (บวกสำหรับค่าใช้จ่าย, ลบสำหรับรายได้)",
  "title2549": "เดือนค่าใช้จ่ายต้องไม่ว่าง",
  "title2550": "ประเภทค่าใช้จ่ายต้องไม่ว่าง",
  "title2551": "จำนวนเงินต้องไม่ว่าง",
  "title2552": "เพิ่มค่าใช้จ่ายร้านค้า",
  "title2553": "แก้ไขค่าใช้จ่ายร้านค้า",
  "title2554": "ยืนยันการลบค่าใช้จ่ายของร้าน:",
  "title2555": " โลจิสติกส์ทั้งหมด ",
  "title2556": "ชื่อโลจิสติกส์",
  "title2557": "ประเภทพัสดุ",
  "title2558": "จำนวนเงินที่ขาดทุนและยังไม่ดำเนินการ",
  "title2559": "จำนวนเงินที่ถูกประมวลผลสำหรับค่าจัดส่ง",
  "title2561": "ราคาห่างกัน",
  "title2562": "ส่วนลดค่าจัดส่งโดยผู้ขาย",
  "title2563": "ค่าจัดส่งที่ส่งคืน",
  "title2564": "การปรับค่าจัดส่ง",
  "title2565": "สถานะค่าจัดส่ง",
  "title2566": "ปกติ",
  "title2567": "ขาดทุน",
  "title2568": "กำไรเกิน",
  "title2569": "ขนส่ง",
  "title2570": "หมายเลขคำสั่งซื้อ",
  "title2571": "โลจิสติกส์ที่ผู้ซื้อกำหนด:",
  "title2572": "การประมวลผล",
  "title2573": "โปรดป้อนหลายหมายเลขคำสั่งซื้อ โดยแบ่งด้วยการเปลี่ยนบรรทัด",
  "title2574": "การดำเนินการขาดทุน",
  "title2575": "หลังจากยืนยัน สถานะการขนส่งจะถูกเปลี่ยนเป็น [การสูญเสียได้ถูกประมวล], ไม่สามารถย้อนกลับได้ คุณแน่ใจหรือไม่ว่าต้องการดำเนินการต่อหรือไม่?",
  "title2576": "คำสั่งซื้อแพลตฟอร์ม",
  "title2577": "บริษัทขนส่ง",
  "title2578": "สินค้าเดียว (1 ชิ้น)",
  "title2579": "หลายรายการ หลายจำนวน",
  "title2580": "มีหมายเหตุ",
  "title2581": "ไม่มีหมายเหตุ",
  "title2582": "คุณแน่ใจหรือว่าต้องการจัดส่งคำสั่งซื้อที่เลือก",
  "title2583": "การจัดส่งสำเร็จ",
  "title2584": "กรุณาใส่หมายเหตุ",
  "title2585": "เพิ่มหมายเหตุสำเร็จ",
  "title2586": "ไม่มีสิทธิ์",
  "title2587": "คุณแน่ใจว่าต้องการส่งออกข้อมูลคำสั่งซื้อไหม",
  "title2588": "ทั้งหมด",
  "title2589": "คำสั่งซื้อการทดสอบ",
  "title2590": "คำสั่งซื้อที่เป็นโมฆะ",
  "title2591": "ตรวจสอบ",
  "title2592": "การจัดส่ง",
  "title2593": "เรียกคืน",
  "title2594": "สร้างคำสั่งซื้อด้วยตนเอง",
  "title2595": "การถ่ายโอนคำสั่งซื้อ",
  "title2596": "เปลี่ยนวิธีการจัดส่งสินค้า",
  "title2597": "รีเฟรชต้นทุนล่าสุด",
  "title2598": "คำสั่งซื้อเป็นโมฆะ",
  "title2599": "ยกเลิกการพัก",
  "title2600": "ทำเครื่องหมายออเดอร์เฟค",
  "title2601": "ยกเลิกทำเครื่องหมายออเดอร์เฟค",
  "title2602": "การจับคู่",
  "title2603": "ย้ายไปรอตรวจสอบ",
  "title2604": "เพิ่มของแถม",
  "title2605": "พิมพ์เป็นชุด",
  "title2606": "พิมพ์ Pick List",
  "title2607": "พิมพ์ใบปะหน้าพัสดุ",
  "title2608": "พิมพ์ใบปะหน้าพัสดุ&Pick List",
  "title2609": "ทำเครื่องหมายว่าพร้อมส่ง",
  "title2610": "ทำเครื่องหมายว่ายังไม่พร้อมส่ง",
  "title2611": "ทำเครื่องหมายว่าพร้อมส่งรายการหยิบ",
  "title2612": "ทำเครื่องหมายว่ายังไม่พร้อมส่งรายการหยิบ",
  "title2613": "รีเฟรชสต็อก",
  "title2614": "สถานะการพิมพ์",
  "title2615": "ความสมบูรณ์",
  "title2616": "สถานะทั้งหมด",
  "title2617": "ทำเครื่องหมาย",
  "title2618": "ส่งออกรายการสั่งซื้อ",
  "title2619": "ส่งออกข้อมูลผู้ซื้อ",
  "title2620": "การสั่งล้มเหลวจริงๆ นั้นแท้จริงยังถูกจัดการโดยคลังสินค้าของฝ่ายที่สาม โปรดติดต่อผู้ให้บริการคลังสินค้าของฝ่ายที่สามโดยเร็วที่สุดเพื่อ",
  "title2621": "หมายเหตุของผู้ซื้อ",
  "title2622": "เพิ่มเครื่องหมาย",
  "title2623": "จำนวนเงินที่ต้องชำระ",
  "title2624": "รายได้",
  "title2625": "รวม",
  "title2626": "รายได้จริง",
  "title2627": "ค่าใช้จ่าย",
  "title2628": "การคำนวณ",
  "title2629": "ค่าบริการห่อของ",
  "title2630": "ส่วนลดแพลตฟอร์ม",
  "title2631": "ผู้รับ",
  "title2632": "สถานะไม่ทราบ",
  "title2633": "การสั่งจองล่วงหน้า",
  "title2634": "การประเมิน",
  "title2635": "เวลาหมดอายุ",
  "title2636": "ตามนโยบายของแพลตฟอร์มที่แตกต่างกัน เวลาหมดอายุไม่จำเป็นต้องหมายถึงคำสั่งถูกยกเลิกโดยแพลตฟอร์ม ว่าคำสั่งถูกยกเลิกโดยแพลตฟอร์มหรือไม่ขึ้นอยู่กับสถานะของแพลตฟอร์ม",
  "title2637": "เพื่ออ้างอิง",
  "title2638": "การตั้งค่าการจัดส่ง",
  "title2639": "คำอธิบายการลบ:",
  "title2640": "ลบคำสั่งและเพิ่มลงในรายการผ่านสำเร็จ",
  "title2641": "ยังไม่ดำเนินการ",
  "title2642": "เวลาที่ถูกทำเครื่องหมาย:",
  "title2643": "รายการที่เลือกได้ถูกพิมพ์",
  "title2644": "ใบกำกับภาษีที่ถูกพิมพ์",
  "title2645": "ยังไม่ได้ตรวจสอบ",
  "title2646": "อยู่ระหว่างตรวจสอบ",
  "title2647": "ผ่านการตรวจสอบ",
  "title2648": "แก้ไขการจับคู่",
  "title2649": "เปลี่ยนคำสั่งปัจจุบัน",
  "title2650": "เปลี่ยนทั้งหมด",
  "title2651": "ความสัมพันธ์ที่เข้ากันได้ (มีผลเฉพาะสำหรับคำสั่งที่รอดำเนินการภายใต้ร้านนี้ และอัปเดตความสัมพันธ์ที่เข้ากันได้พร้อมกัน)",
  "title2652": "เลือกประเภท",
  "title2653": "รวม",
  "title2654": "การตั้งค่าเวลาการซิงค์คำสั่งซื้อ",
  "title2655": "เริ่มการซิงโครไนเซอร์",
  "title2656": "ซิงค์คำสั่งซื้อ ",
  "title2657": "ซิงค์คำสั่งซื้อ ",
  "title2658": "ทุกแพลตฟอร์ม",
  "title2659": "โปรดป้อนชื่อร้านค้า",
  "title2660": "การซิงโครไนส์กำลัง",
  "title2661": "กำลังซิงโครไนส์ข้อมูลคำสั่งซื้อ",
  "title2662": "ความคืบหน้า",
  "title2663": "ดูภายหลัง",
  "title2664": "สร้างคำสั่งซื้อด้วยตนเอง",
  "title2665": "วิธีการชำระเงิน",
  "title2666": "คำสั่งซื้อแบบออนไลน์",
  "title2667": "คำสั่งซื้อแบบออฟไลน์",
  "title2668": "ข้อมูลคำสั่งซื้อ",
  "title2669": "เลือกวิธีการชำระเงิน",
  "title2670": "ชื่อบริษัทขนส่ง",
  "title2671": "กรุณาใส่",
  "title2672": "กรุณาใส่ชื่อบริษัทขนส่ง",
  "title2673": "กรุณาระบุบริษัทขนส่งตามที่ผู้ซื้อแจ้ง",
  "title2674": "ไฟล์แนบอื่นๆ",
  "title2675": "อัปโหลดไฟล์",
  "title2676": "ชื่อผู้รับ",
  "title2677": "กรุณาใส่ชื่อผู้รับ",
  "title2678": "เบอร์โทรศัพท์มือถือ",
  "title2679": "กรุณาใส่เบอร์โทรศัพท์",
  "title2680": "ประเทศ",
  "title2681": "เลือกภูมิภาค",
  "title2682": "จังหวัด",
  "title2683": "รัฐ",
  "title2684": "กรุณาใส่จังหวัด",
  "title2685": "กรุณาใส่เมือง",
  "title2686": "ภูมิภาค",
  "title2687": "อำเภอ",
  "title2688": "กรุณาใส่เขต",
  "title2689": "รหัสไปรษณีย์",
  "title2690": "รหัสไปรษณีย์",
  "title2691": "รายละเอียดที่อยู่",
  "title2692": "โปรดกรอกที่อยู่ละเอียด",
  "title2693": "ข้อความของผู้ซื้อ",
  "title2694": "โปรดใส่ข้อความจากผู้ซื้อ",
  "title2695": "เลือกสกุลเงิน",
  "title2696": "เพิ่มสินค้า",
  "title2697": "ราคารวม",
  "title2698": "กรุณาใส่จำนวนเต็มบวกมากกว่า 0",
  "title2699": "ยังไม่พิมพ์",
  "title2700": "ใบปะหน้าพัสดุ",
  "title2701": "พิมพ์แล้ว",
  "title2702": "pick list",
  "title2703": "ไม่ใช่การสั่งจองล่วงหน้า",
  "title2704": "สินค้าเดี่ยวหลายชิ้น",
  "title2705": "สินค้าหลายชิ้นห่อผสมรวมกัน",
  "title2706": "ผู้ซื้อจ่ายเงิน",
  "title2707": "ผู้ขายจ่าย",
  "title2708": "2 เดือนที่ผ่านมา",
  "title2709": "วันที่กำหนดเอง",
  "title2710": "เลขคำสั่งซื้อระบบต้องไม่ว่างเปล่า",
  "title2711": "เลขคำสั่งซื้อแพลตฟอร์มต้องไม่ว่างเปล่า",
  "title2712": "พื้นที่ต้องไม่ว่างเปล่า",
  "title2713": "ข้อมูลผู้ซื้อต้องไม่ว่างเปล่า",
  "title2714": "ข้อมูลติดตามขนส่งต้องไม่ว่างเปล่า",
  "title2715": "การสั่งจองล่วงหน้าต้องไม่ว่างเปล่า",
  "title2716": "การสั่งซื้อไม่มีสินค้าต้องไม่ว่างเปล่า",
  "title2717": "สถานะระบบต้องไม่ว่างเปล่า",
  "title2718": "การทำเครื่องหมายต้องไม่ว่างเปล่า",
  "title2719": "วิธีการชำระเงินต้องไม่ว่างเปล่า",
  "title2720": "โปรดเลือกประเทศ",
  "title2721": "โปรดป้อนจังหวัด",
  "title2722": "หมดเวลา",
  "title2723": "ยกเลิก",
  "title2724": "กำลังดำเนินการ",
  "title2725": "อยู่ระหว่างการขนส่ง",
  "title2726": "จัดส่งแล้ว",
  "title2727": "การจัดส่งล้มเหลว",
  "title2728": "คำสั่งคืน",
  "title2729": "การคืนสินค้าและการคืนเงินกำลังดำเนินการ",
  "title2730": "โปรดเลือกการดำเนินการคำสั่ง",
  "title2731": "คุณแน่ใจหรือว่าต้องการซิงโครไนส์คำสั่ง",
  "title2732": "คำขอการซิงโครไนซ์สำเร็จโปรดรีเฟรชเพื่อดู",
  "title2733": "คุณแน่ใจหรือไม่ว่าต้องการรีเฟรชต้นทุนล่าสุด",
  "title2734": "การรีเฟรชค่าสำเร็จ",
  "title2735": "การทำเครื่องหมายการประเมินสำเร็จ",
  "title2736": "การยกเลิกการทำเครื่องหมายการประเมินสำเร็จ",
  "title2737": "การบันทึกคลังสำเร็จ",
  "title2738": "คุณแน่ใจหรือว่าต้องการทำเครื่องหมายคำสั่งที่เลือกสำหรับการประเมิน",
  "title2739": "คุณแน่ใจหรือว่าต้องการตรวจสอบคำสั่งที่เลือก",
  "title2740": "คุณแน่ใจหรือว่าต้องการจัดส่งคำสั่งที่เลือก",
  "title2741": "คำสั่งที่เลือก?",
  "title2742": "คุณแน่ใจหรือไม่ว่าต้องการดันคำสั่งที่เลือก",
  "title2743": "กำลังถูกผลักไป โปรดตรวจสอบภายหลัง",
  "title2744": "คุณแน่ใจหรือว่าต้องการยกเลิกการทำเครื่องหมายการประเมินสำหรับคำสั่งที่เลือก",
  "title2745": "คุณแน่ใจหรือไม่ว่าต้องการระงับคำสั่งที่เลือก",
  "title2746": "การระงับเสร็จสมบูรณ์",
  "title2747": "คุณแน่ใจหรือไม่ว่าต้องการยกเลิกการระงับสำหรับคำสั่งที่เลือก",
  "title2748": "การยกเลิกการระงับเสร็จสมบูรณ์",
  "title2749": "การทำเครื่องหมายสำเร็จ",
  "title2750": "จะย้ายคำสั่งที่เลือกไปยังการตรว",
  "title2751": "เพิ่มเครื่องหมายสำเร็จ",
  "title2752": "ยืนยันการลบเครื่องหมาย:",
  "title2753": "ลบเครื่องหมายสำเร็จ",
  "title2754": "การประสานข้อมูลเรียบร้อย",
  "title2755": "หลังจากยกเลิกการแยก ถุงทั้งหมดจะถูกย้อนกลับไปที่ถุงหลัก คุณแน่ใจว่าต้องการยกเลิกการแยกหรือไม่?",
  "title2756": "ยกเลิกการแยกสำเร็จ",
  "title2757": "ยืนยันหมายเลขการตรวจสอบสำหรับ",
  "title2758": "คำสั่ง",
  "title2759": "ยืนยันการถอนคำสั่งที่เลือก",
  "title2760": "การถอนสำเร็จ",
  "title2761": "ยืนยันการลบคำสั่งที่เลือก",
  "title2762": "การเอาออกสำเร็จ",
  "title2763": "ยืนยันหมายเลขการพักลา",
  "title2764": "ยืนยันยกเลิกหมายเลขการพ",
  "title2765": "ยืนยันหมายเลขเป็น",
  "title2766": "คำสั่งที่จะตรวจสอบ",
  "title2767": "ข้อความแจ้งเตือนของระบบ",
  "title2768": "การทำเครื่องหมายคำสั่งทดสอบเรียบร้อย",
  "title2769": "ยืนยันยกเลิกการทำเครื่องหมายเป็น",
  "title2770": "ยกเลิกคำสั่งทดสอบที่ถูกทำเครื่องหมายไว้",
  "title2771": "หมายเหตุสำหรับ Pick Orders",
  "title2772": "ยืนยันการลบหมายเลขคำสั่งเป็น",
  "title2773": "ยืนยันการลบรายการที่เพิ่มเข้าไป",
  "title2774": "โปรดเลือกวิธีในการรับสินค้า",
  "title2775": "วิธีการรับสินค้าถูกบันทึกเรียบร้อยแล้ว",
  "title2776": "โปรดเลือกสินค้าหรือยกเลิก",
  "title2777": "เพิ่มสำเร็จ",
  "title2778": "ยืนยันการส่งออกข้อมูลผู้ซื้อ",
  "title2779": "โปรดใส่คุณสมบัติของใบสั่งซื้อ",
  "title2780": "โปรดเลือกว่าจะขายก่อนหรือไม่",
  "title2781": "โปรดเลือกสถานะสินค้า",
  "title2782": "วิดีโอสินค้า",
  "title2783": "ตารางขนาด",
  "title2784": "ขายก่อน",
  "title2785": "สภาพสินค้า",
  "title2786": "คำอธิบายสั้น",
  "title2787": "คำอธิบายละเอียด",
  "title2788": "ราคาขาย",
  "title2789": "จำนวนการดูโฆษณา",
  "title2790": "จำนวนคนที่ชื่นชอบ",
  "title2791": "โปรดใส่หมวดหมู่",
  "title2792": "โปรดใส่แบรนด์",
  "title2793": "โปรดใส่รูปสินค้า",
  "title2794": "โปรดใส่วิดีโอสินค้า",
  "title2795": "โปรดใส่คุณสมบัติ",
  "title2796": "โปรดใส่น้ำหนักสินค้า",
  "title2797": "โปรดใส่ขนาดสินค้า",
  "title2798": "โปรดใส่ตารางขนาด",
  "title2799": "โปรดใส่คำอธิบายสั้น",
  "title2800": "โปรดใส่คำอธิบายละเอียด",
  "title2801": "โปรดใส่ราคาขาย",
  "title2802": "โปรดใส่จำนวนการดูโฆษณา",
  "title2803": "โปรดใส่จำนวนคนที่ชื่นชอบ",
  "title2805": "โปรดใส่จำนวนความคิดเห็น",
  "title2806": "หมวดหมู่ต้องไม่ว่างเปล่า",
  "title2807": "แบรนด์ต้องไม่ว่างเปล่า",
  "title2808": "สถานะขายก่อนต้องไม่ว่างเปล่า",
  "title2809": "สภาพของสินค้าต้องไม่ว่างเปล่า",
  "title2810": "ประเภทต้องไม่ว่างเปล่า",
  "title2811": "ราคาขายต้องไม่ว่างเปล่า",
  "title2812": "จำนวนการดูโฆษณาต้องไม่ว่างเปล่า",
  "title2813": "จำนวนคนที่ชื่นชอบต้องไม่ว่างเปล่า",
  "title2815": "คะแนนต้องไม่ว่างเปล่า",
  "title2816": "จำนวนความคิดเห็นต้องไม่ว่างเปล่า",
  "title2817": "เพิ่มสินค้าออนไลน์",
  "title2818": "แก้ไขสินค้าออนไลน์",
  "title2819": "ยืนยันการลบสินค้าออนไลน์",
  "title2820": "ยืนยันการส่งออกข้อมูลสินค้าออนไลน์ทั้งหมด",
  "title2821": "สินค้าออนไลน์",
  "title2823": "ค่าขนส่งของผู้ซื้อ",
  "title2824": "ค่าขนส่งของผู้ขาย",
  "title2825": "อัตรากำไรขั้ว",
  "title2826": "รายการสั่งซื้อ",
  "title2827": "ข้อมูลการขนส่งของผู้ขาย",
  "title2828": "รายได้จริง",
  "title2829": "กำไรจริง",
  "title2830": "รายได้โดยประมาณ",
  "title2832": "รวม",
  "title2833": "ยืนยันการส่งออกตารางกำไรสินค้า",
  "title2834": "กำไรสินค้า",
  "title2835": "โปรดใส่สินค้าออนไลน์",
  "title2836": "พ่อ",
  "title2837": "กรุณาใส่ชื่อพ่อ",
  "title2838": "โปรดใส่สต็อก",
  "title2839": "โปรดเลือกสถานะแพลตฟอร์ม",
  "title2840": "สถานะท้องถิ่น",
  "title2841": "โปรดใส่สถานะท้องถิ่น",
  "title2842": "สต็อกต้องไม่ว่างเปล่า",
  "title2843": "สถานะแพลตฟอร์มต้องไม่ว่างเปล่า",
  "title2844": "สถานะท้องถิ่นต้องไม่ว่างเปล่า",
  "title2845": "ยืนยันการส่งออกสินค้าออนไลน์ทั้งหมด",
  "title2846": "อัปเดตต้นทุน",
  "title2847": "อัปเดตต้นทุนสินค้า",
  "title2848": "เพิ่มป้ายชื่อในคำสั่งซื้อ",
  "title2849": "การตรวจสอบการชำระเงินใบสั่งซื้อ",
  "title2850": "เลือกประเภทเวลา",
  "title2851": "สถานะการชำระเงิน",
  "title2852": "ความสมบูรณ์ของรายการสั่งซื้อ",
  "title2853": "คำสั่งซื้อปกติ",
  "title2854": "ค่าขนส่งที่ไม่ได้รับการอัปเดต",
  "title2855": "ต้นทุนที่ยังไม่ได้รับการอัพเดท",
  "title2856": "ในผลลัพธ์การค้นหาปัจจุบัน",
  "title2857": "คำสั่งซื้อที่ยังไม่ได้ชำระเงิน",
  "title2858": "สั่งซื้อ:",
  "title2859": "การชำระเงิน:",
  "title2860": "แท็ก",
  "title2861": "จำนวนเงินที่ได้รับ",
  "title2862": "ชำระแล้ว",
  "title2863": "ยังไม่ได้ชำระเงิน",
  "title2864": "การตรวจสอบการชำระเงิน",
  "title2865": "อัปโหลดตารางการชำระเงิน",
  "title2866": "จำนวนคำสั่งซื้อ",
  "title2867": "จำนวนเงินที่ได้รับ:",
  "title2868": "จำนวนคำสั่งซื้อบนแพลตฟอร์ม",
  "title2869": "คัดลอกหมายเลขสั่งซื้อ",
  "title2870": "คำสั่งซื้อเกินจำนวน",
  "title2871": "คำสั่งซื้อเกินจำนวนในร้าน",
  "title2872": "จำนวนเงินที่โรงเก็บเงิน",
  "title2873": "ทุกประเภท",
  "title2874": "จำนวนเงินการชำระไม่สอดคล้อง",
  "title2875": "เริ่มต้นการตรวจสอบ",
  "title2876": "อัปโหลดต้นทุนสินค้า",
  "title2877": "โปรดเตรียมข้อมูลสำหรับการนำเข้าตามรูปแบบเทมเพลต",
  "title2878": "รูปแบบ",
  "title2879": "ขนาดไฟล์ต้องไม่เกิน",
  "title2880": "ผ่านสินค้า",
  "title2881": "สอบถามข้อมูลคำสั่งซื้อ สามารถแก้ไขราคาทุนสินค้าได้",
  "title2882": "โปรดทราบว่าไม่สนับสนุนการแก้ไขคำสั่งซื้อที่ไม่มีสินค้า",
  "title2883": "ตาม",
  "title2884": "ดับเบิลคลิกสำหรับการป้อนชุด",
  "title2885": "ค่าใช้จ่ายสินค้าเดียว",
  "title2886": "แก้ไขเป็นชุด",
  "title2887": "เวลาชำระเงิน",
  "title2888": " แก้ไขเป็นชุดในกล่องร่าง ",
  "title2889": "คำสั่งซื้อทั้งหมด",
  "title2890": "คำสั่งซื้อถูกพัก",
  "title2891": "คำสั่งซื้อหายไป",
  "title2892": "คำสั่งสอนใจห่างกันเวลา",
  "title2893": "ยืนยันยกเลิก",
  "title2894": "โปรดใส่ต้นทุน",
  "title2895": "ไม่พบต้นทุนสินค้าที่แก้ไขแล้วโปรดแก้ไขต้นทุนสินค้าก่อนดำเนินการต่อ",
  "title2896": "อัปเดตต้นทุนสำเร็จ",
  "title2897": "แม่แบบการตรวจสอบ",
  "title2898": "อัปเดตต้นทุนสินค้าแม่แบบนำเข้า",
  "title2899": "โปรดเลือกเดือน",
  "title2900": "โปรดเลือกเวลาสั่งซื้อ",
  "title2901": "รายละเอียดกำไรจากการสั่งซื้อ",
  "title2902": "เลือกสินค้า",
  "title2903": "โปรดเลือกผลิตภัณฑ์",
  "title2904": "โปรดใส่หมายเลขใบสำคัญรับเข้า",
  "title2905": "ราคาหน่วยใบสำคัญรับเข้า",
  "title2906": "NO. Stock-In",
  "title2907": "ถูกปฏิเสธ",
  "title2908": "รอยื่น",
  "title2909": "คลังสินค้า Stock-In",
  "title2910": "หน่วยราคา",
  "title2911": "นำเข้าใบสำคัญ",
  "title2912": "แม่แบบนำเข้าใบสำคัญ",
  "title2913": "โปรดเลือกคลังสินค้า",
  "title2914": "ยืนยันการลบรายการที่เลือก?",
  "title2915": "เพิ่มรายการ Stock-In",
  "title2916": "แก้ไขใบสำคัญ",
  "title2917": "ดูใบสำคัญ",
  "title2918": "ยืนยันการนำเข้า",
  "title2919": "นำเข้าสำเร็จ",
  "title2920": "โปรดป้อนปริมาณการรับหน้า",
  "title2921": "ยืนยันการลบบันทึกสินค้าเป็น:",
  "title2922": "ยืนยันการส่งออกข้อมูลรายการคงคลังทั้งหมด",
  "title2923": "บันทึกสินค้า",
  "title2924": "เวลาในการดำเนินการ",
  "title2925": "การเปลี่ยนแปลงสินค้า",
  "title2926": "สินค้าที่ถูกแช่แข็งเดิม",
  "title2927": "สินค้าที่ถูกแช่แข็งใหม่",
  "title2928": "สินค้าที่มีอยู่เดิม",
  "title2929": "สินค้าที่มีอยู่ใหม่",
  "title2930": "ข้อมูลการนำเข้าและการส่ง",
  "title2932": "โปรดเลือกช่วงเวลา",
  "title2933": "อัพโหลดการตรวจนับ",
  "title2934": "นำเข้าสำเร็จ! คุณได้นำเข้าข้อมูล 1000 รายการเรียบร้อยแล้ว.",
  "title2935": "โปรดใส่หมายเลขใบส่งสินค้า",
  "title2936": "จำนวนที่ออกจากคลัง",
  "title2937": "NO. Stock-Out",
  "title2938": "สินค้าหมด",
  "title2939": "คลังสินค้า Stock-Out",
  "title2940": "นำเข้าใบส่งสินค้า",
  "title2941": "แม่แบบนำเข้าใบส่งสินค้า",
  "title2942": "เพิ่มรายการ Stock-Out",
  "title2943": "แก้ไขใบส่งสินค้า",
  "title2944": "ดูใบส่งสินค้า",
  "title2945": "ยืนยันการส่งออก",
  "title2946": "ส่งออกสำเร็จ",
  "title2947": "โปรดป้อนปริมาณส่งออก",
  "title2948": "พื้นที่",
  "title2949": "โปรดใส่พื้นที่",
  "title2950": "โปรดใส่อัตราการคืนสินค้า",
  "title2951": "อัตราการคืนสินค้าต้องไม่ว่าง",
  "title2952": "กำไรร้านประจำวัน",
  "title2953": "หมายเลขกำไรร้านประจำวันคือ",
  "title2954": "รายการกำไรร้านประจำวัน",
  "title2955": "เพิ่มงานการนับ",
  "title2956": "ประเภทการนับ",
  "title2957": "หมายเลขการนับ",
  "title2958": "การนับสต็อก",
  "title2959": "จำนวน",
  "title2960": "ความคืบหน้าของการนับ (SKU)",
  "title2961": "จบ",
  "title2962": "เริ่มการนับ",
  "title2963": "เสร็จสิ้นการนับ",
  "title2964": "โปรดเลือกประเภทการตรวจนับ",
  "title2965": "สินค้าในสต็อกที่พร้อมใช้งาน",
  "title2966": "นำเข้าสินค้า",
  "title2967": "กำลังนับอยู่",
  "title2968": "ส่งออกจำนวนสินค้า",
  "title2969": "นำเข้าการนับ",
  "title2970": "จำนวนการนับ",
  "title2971": "ผลลัพธ์การนับ",
  "title2972": "นำเข้าแม่แบบ",
  "title2973": "นำเข้าการตรวจนับ",
  "title2974": "แม่แบบนำเข้าการตรวจนับ",
  "title2975": "ประเภทการตรวจนับต้องไม่ว่าง",
  "title2976": "สต็อกเกิน",
  "title2977": "สต็อกปกติ",
  "title2978": "สต็อกขาด",
  "title2979": "สร้างงานตรวจนับใหม่",
  "title2980": "แก้ไขการตรวจนับ",
  "title2981": "ดูการตรวจนับ",
  "title2982": "การนับสต็อก",
  "title2983": "ยืนยันการจบการตรวจนับ",
  "title2984": "การตรวจนับเสร็จสมบูรณ์",
  "title2985": "การสร้างการตรวจนับใหม่เสร็จสมบูรณ์",
  "title2986": "ยืนยันลบหมายเลขเป็น",
  "title2987": "การตรวจนับ",
  "title2988": "การตรวจนับถูกลบสำเร็จ",
  "title2989": "ยืนยันการส่งออกรายการตรวจนับ",
  "title2990": "รายการ",
  "title2991": "ยืนยันการส่งออกรายการข้อมูลการตรวจคลังทั้งหมด",
  "title2992": "สร้างใบสั่งโอนใหม่",
  "title2993": "นำเข้าใบสั่งโอน",
  "title2994": "ส่งออกใบสั่งโอน",
  "title2995": "รับ",
  "title2996": "ยืนยันการโอนส่ง",
  "title2997": "เลือกคลังสินค้าที่จะจัดส่ง",
  "title2998": "โปรดเลือกคลังที่รับ",
  "title2999": "วิธีแบ่งสรรค่าใช้จ่าย",
  "title3000": "โปรดเลือกวิธีแบ่งอัตราค่าใช้จ่าย",
  "title3001": "แก้ไขจำนวน",
  "title3002": "จำนวน：",
  "title3003": "ราคาการโอน ",
  "title3004": "ราคาการโอนสุดท้าย",
  "title3005": "แก้ไขจำนวนโอนชุม",
  "title3006": "เท่ากับสินค้าในสต็อกที่ใช้งานได้",
  "title3007": "ตั้งค่าค่าสินค้าเท่ากัน",
  "title3008": "ป้อนค่าสินค้าเท่ากัน",
  "title3009": "นำเข้าใบสั่งโอน",
  "title3010": "แม่แบบนำเข้าใบสั่งโอน",
  "title3011": "โปรดเลือกคลังส่งสินค้า",
  "title3012": "ยืนยันการโอนส่ง",
  "title3013": "สินค้าที่ยังไม่ได้รับจะถูกคืนไปยังคลังส่งสินค้า คุณแน่ใจว่าต้องการยกเลิกหรือไม่",
  "title3014": "แก้ไขใบสั่งโอน",
  "title3015": "กรุณาใส่จำนวนโอน",
  "title3016": "สินค้าในสต็อกไม่เพียงพอสำหรับการโอน",
  "title3017": "ใบสั่งโอน",
  "title3018": "ยืนยันการส่งออกข้อมูลทั้งหมดใบสั่งโอน",
  "title3019": "ใบสั่งโอน",
  "title3020": "โปรดเลือกวิธีชำระเงิน",
  "title3021": "ชื่อ Supplier",
  "title3022": "ผู้ติดต่อ",
  "title3023": "โทรศัพท์",
  "title3024": "วิธีการชำระเงิน",
  "title3025": "โปรดป้อนชื่อ Supplier",
  "title3026": "ช่องทางการซื้อซื้อ",
  "title3027": "กรุณาใส่ช่องทางการซื้อ",
  "title3028": "ชื่อบริษัท",
  "title3029": "กรุณาใส่ชื่อบริษัท",
  "title3030": "ที่อยู่ของบริษัท",
  "title3031": "กรุณาใส่ที่อยู่บริษัท",
  "title3032": "เว็บไซต์ Supplier",
  "title3033": "กรุณาใส่เว็บไซต์ผู้ผลิต",
  "title3034": "บัญชีธนาคาร",
  "title3035": "กรุณาใส่บัญชีธนาคาร",
  "title3036": "เลขบัญชีธนาคาร",
  "title3037": "โปรดใส่หมายเลขบัญชีการชำระเงิน",
  "title3038": "โปรดใส่ข้อมูลผู้ติดต่อ",
  "title3039": "โปรดใส่หมายเลขโทรศัพท์ติดต่อ",
  "title3040": "โปรดใส่ข้อมูล WeChat",
  "title3041": "อีเมล",
  "title3042": "โปรดใส่ที่อยู่อีเมล",
  "title3043": "ชื่อผู้ผลิตต้องไม่ว่างเปล่า",
  "title3044": "ช่องทางการซื้อซื้อต้องไม่ว่างเปล่า",
  "title3045": "เพิ่ม Supplier",
  "title3046": "แก้ไขผู้ผลิต",
  "title3047": "ยืนยันการลบผู้ผลิต",
  "title3048": "ยืนยันการส่งออกรายการข้อมูลผู้ผลิตทั้งหมด",
  "title3049": "Supplier",
  "title3050": "โปรดใส่การอนุญาต",
  "title3051": "หมายเลขไซต์",
  "title3052": "กรุณาใส่หมายเลขไซต์",
  "title3053": "เลขที่คลังสินค้า",
  "title3054": "กรุณาใส่เลขที่คลังสินค้า",
  "title3055": "ได้ใช้งานหรือไม่",
  "title3056": "ที่อยู่คลังสินค้า",
  "title3057": "โปรดใส่ที่อยู่คลังสินค้า",
  "title3058": "ชื่อคลังสินค้าต้องไม่ว่างเปล่า",
  "title3059": "สถานะการเปิดใช้งานต้องไม่ว่างเปล่า",
  "title3060": "หมายเลขไซต์ต้องไม่ว่างเปล่า",
  "title3061": "หมายเลขคลังสินค้าต้องไม่ว่างเปล่า",
  "title3062": "เพิ่มคลังสินค้าที่ได้รับอนุญาตจากบุคคลที่สาม",
  "title3063": "แก้ไขคลังสินค้าที่ได้รับอนุญาตจากบุคคลที่สาม",
  "title3064": "ยืนยันการลบคลังสินค้าที่ได้รับอนุญาตจากบุคคลที่สามโดยมีหมายเลข",
  "title3065": "ยืนยันการส่งออกรายการข้อมูลคลังสินค้าที่ได้รับอนุญาตจากบุคคลที่สามทั้งหมด",
  "title3066": "คลังสินค้าที่ได้รับอนุญาตจากบุคคลที่สาม",
  "title3067": "อัตราแลกเปลี่ยนการชำระเงิน",
  "title3068": "แก้ไขพารามิเตอร์การประเมิน",
  "title3069": "เพิ่มพารามิเตอร์ในการประเมิน",
  "title3070": "โปรดใส่ค่าธุรกิจ",
  "title3071": "โปรดใส่ค่าบรรจุว่าง",
  "title3072": "โปรดเลือกร้านค้า",
  "title3073": "โปรดใส่อัตราแลกเปลี่ยนการชำระเงิน",
  "title3074": "การเปลี่ยนแปลงของอัตราแลกเปลี่ยนการชำระเงินที่คุณตั้งค่ามีความสำคัญมาก โปรดตรวจสอบก่อนส่ง",
  "title3075": "ค่าธุรกิจต้องไม่ว่างเปล่า",
  "title3076": "ค่าบรรจุว่างต้องไม่ว่างเปล่า",
  "title3077": "ยืนยันการลบพารามิเตอร์การประเมิน",
  "title3078": "ยืนยันการส่งออกรายการข้อมูลพารามิเตอร์การประเมิน",
  "title3079": "พารามิเตอร์การประเมิน",
  "title3080": "โปรดค้นหาชื่อคลังสินค้า",
  "title3081": "กุญแจ",
  "title3082": "คีย์ที่สอดคล้องกับคีย์",
  "title3083": "บัญชี",
  "title3084": "โปรดใส่บัญชี",
  "title3085": "ข้อมูลการให้สิทธิ์",
  "title3086": "ยังไม่เชื่อมต่อ",
  "title3087": "เพิ่มคลังสินค้า",
  "title3088": "โปรดรับข้อมูลการให้สิทธิ์จากผู้ให้บริการบุคคลที่สาม ผูกหลายคน",
  "title3089": "ฉันจะทำให้คำสั่งซื้อถูกทำซ้ำหลายครั้ง ส่งผลให้มีความผิดพลาดในการส่งคำสั่งซื้อ คำแนะนำให้ผูกบัญชีปัจจุบันเท่านั้น",
  "title3090": "ชื่อที่กำหนดเอง",
  "title3091": "ป้อนชื่อที่กำหนดเองสำหรับคลัง",
  "title3092": "ชื่อผู้ให้บริการบุคคลที่สาม",
  "title3093": "ป้อน",
  "title3094": "รหัสลูกค้า",
  "title3095": "โปรดใส่รหัสลูกค้า",
  "title3096": "ชื่อที่กำหนดเองต้องไม่ว่างเปล่า",
  "title3097": "ให้สิทธิ์ผู้ให้บริการโกดัทบุคคล",
  "title3098": "การให้สิทธิ์สำเร็จ",
  "title3099": "ยืนยันการลบข้อมูลการกำหนดค่าคลังของบริษัทที่สามที่มีรหัส",
  "title3100": "ยืนยันการส่งออกรายการข้อมูลการกำหนดค่าคลังของบริษัทที่สามทั้งหมด",
  "title3101": "ข้อมูลการกำหนดค่าคลังของบริษัทที่สาม",
  "title3102": "ชื่อผู้ผลิต",
  "title3103": "โปรดใส่ชื่อผู้ผลิต",
  "title3104": "หมายเลขผู้ผลิต",
  "title3105": "โปรดใส่หมายเลขผู้ผลิต",
  "title3106": "ผู้ผลิต",
  "title3107": "ยังไม่เปิดใช้งาน",
  "title3108": "เปิดแล้ว",
  "title3109": "เว็บไซต์อย่างเป็นทางการ",
  "title3110": "เครื่องหมายการค้า",
  "title3111": "ยังไม่เปิดใช้งาน",
  "title3112": "ชื่อผู้ผลิตต้องไม่ว่างเปล่า",
  "title3113": "หมายเลขผู้ผลิตต้องไม่ว่างเปล่า",
  "title3114": "เพิ่มผู้ผลิตคลังของบริษัทที่สาม",
  "title3115": "แก้ไขผู้ผลิตคลังของบริษัทที่สาม",
  "title3116": "ยืนยันการสลับสถานะผู้ผลิตคลังของบริษัทที่สาม ด้วยรหัส",
  "title3117": "เปลี่ยนสถานะเรียบร้อย",
  "title3118": "ยืนยันการลบผู้ผลิตคลังของบริษัทที่สาม ด้วยรหัส",
  "title3119": "ยืนยันการส่งออกรายการข้อมูลผู้ผลิตคลังบริษัทที่สามทั้งหมด",
  "title3120": "ผู้ให้บริการงสินค้าบุคคลที่สาม",
  "title3121": "บทบาทการทำงาน ",
  "title3122": "ป้อนข้อมูลบัญชี",
  "title3123": "บทบาททั้งหมด",
  "title3124": "ชื่อ",
  "title3125": "Roles",
  "title3126": "กำหนดร้านค้า",
  "title3127": "กำหนดคลังสินค้า",
  "title3128": "กำหนดบทบาท",
  "title3129": "เล่นบทบาท",
  "title3130": "ป้อนชื่อผู้ใช้",
  "title3131": "ป้อนชื่อ",
  "title3132": "เพิ่มผู้ใช้",
  "title3133": "รหัสผ่านเข้าสู่ระบบ",
  "title3134": "ป้อนรหัสผ่าน 6 ตัว",
  "title3135": "สร้างบัญชีย่อย",
  "title3136": "ป้อนผู้ใช้เข้าสู่ระบบ",
  "title3137": "ชื่อจริง",
  "title3138": "ยืนยันรหัสผ่าน",
  "title3139": "สิทธิ์บทบาท",
  "title3140": "สร้างบทบาท",
  "title3141": "สิทธิ์ร้านค้า",
  "title3142": "สิทธิ์คลังสินค้า",
  "title3143": "สิทธิ์คลัง",
  "title3144": "ยืนยันการอัปเดตข้อมูลผู้ใช้ที่มีอยู่",
  "title3145": "อนุญาตให้นำเข้าเท่านั้น",
  "title3146": "ไฟล์รูปแบบ",
  "title3147": "ยังไม่เชื่อมต่อ",
  "title3148": "การเชื่อมต่อผู้ให้บริการ",
  "title3149": "ยังไม่เชื่อมต่อ",
  "title3150": "รหัสผ่านเข้าสู่ระบบใหม่",
  "title3151": "ยืนยันรหัสผ่านใหม่",
  "title3152": "ชื่อต้องไม่ว่าง",
  "title3153": "รหัสผ่านเข้าสู่ระบบต้องไม่ว่าง",
  "title3154": "บทบาทต้องไม่ว่าง",
  "title3155": "โปรดใส่หมายเลขโทรศัพท์ที่ถูกต้อง",
  "title3156": "ไอดีผู้ใช้",
  "title3157": "แก้ไขผู้ใช้",
  "title3158": "การปรับเปลี่ยนสำเร็จ รหัสผ่านใหม่คือ:",
  "title3159": "ยืนยันการเล่นบทบาทสำหรับผู้ใช้",
  "title3160": "ยืนยันการแก้ไขโดเมนเข้าสู่ระบบ",
  "title3161": "การกำหนดบทบาทสำเร็จ",
  "title3162": "ยืนยันการลบผู้ใช้:",
  "title3163": "ยืนยันการส่งออกทุกรายการข้อมูลผู้ใช้",
  "title3164": "ข้อมูลผู้ใช้",
  "title3165": "การนำเข้าผู้ใช้",
  "title3166": "แม่แบบนำเข้าผู้ใช้",
  "title3167": "จำนวนการสร้างสำเร็จ",
  "title3168": "จำนวนการอัปเดตสำเร็จ",
  "title3169": "จำนวนการอัปเดตล้มเหลว",
  "title3170": "ผลการนำเข้า",
  "title3171": "คุณสมบัติคลังสินค้า",
  "title3172": "คลังสินค้า",
  "title3173": "คลังบริการ",
  "title3174": "คลังสินค้าบุคคลที่สาม",
  "title3175": "ผู้ให้บริการที่มีคุณภาพ",
  "title3176": "ประเภทคลังไม่สามารถเว้นว่างได้",
  "title3177": "ผู้ให้บริการต้องไม่ว่างเปล่า",
  "title3178": "คลังบริษัทที่สามต้องไม่ว่างเปล่า",
  "title3179": "แก้ไขคลัง",
  "title3180": "ยืนยันการลบคลัง:",
  "title3181": "ยืนยันการส่งออกรายการข้อมูลคลังทั้งหมด",
  "title3182": "บันทึกระบบ",
  "title3183": "รหัสผู้ใช้",
  "title3184": "โปรดใส่รหัสผู้ใช้",
  "title3185": "ประเภทผู้ใช้",
  "title3186": "โปรดเลือกประเภทผู้ใช้",
  "title3187": "应用名",
  "title3188": "请输入应用名",
  "title3189": "请求地址",
  "title3190": "请输入请求地址",
  "title3191": "请求时间",
  "title3192": "执行时长",
  "title3193": "请输入执行时长",
  "title3194": "结果码",
  "title3195": "请输入结果码",
  "title3196": "日志编号",
  "title3197": "请求方法名",
  "title3198": "操作结果",
  "title3199": "详细",
  "title3200": "访问日志详细",
  "title3201": "日志主键：",
  "title3202": "链路追踪：",
  "title3203": "应用名：",
  "title3204": "用户信息：",
  "title3205": "请求信息：",
  "title3206": "请求参数：",
  "title3207": "开始时间：",
  "title3208": "操作结果：",
  "title3209": "访问日志数据项",
  "title3210": "访问日志",
  "title3211": "生成类型",
  "title3212": "文件名",
  "title3213": "请输入文件名",
  "title3214": "生成类型不能为空",
  "title3215": "页面",
  "title3216": "弹窗",
  "title3217": "异常时间",
  "title3218": "处理状态",
  "title3219": "请选择处理状态",
  "title3220": "异常发生时间",
  "title3221": "异常名",
  "title3222": "已忽略",
  "title3223": "异常日志详细",
  "title3224": "异常时间：",
  "title3225": "处理时间",
  "title3226": "确认标记为",
  "title3227": "错误日志数据项",
  "title3228": "错误日志",
  "title3229": "没有与",
  "title3230": "匹配的",
  "title3231": "选择图标",
  "title3232": "请输入图标名称",
  "title3233": "文件",
  "title3234": "复制代码",
  "title3235": "选择生成类型",
  "title3236": "资源引用",
  "title3237": "格式不能识别，仅支持修改",
  "title3238": "的对象内容",
  "title3239": "请使用",
  "title3240": "错误：",
  "title3241": "导出文件",
  "title3242": "格式错误，请检查",
  "title3243": "外部资源引用",
  "title3244": "资源路径",
  "title3245": "添加其他",
  "title3246": "资源已存在",
  "title3247": "表名称",
  "title3248": "表描述",
  "title3249": "实体类名称",
  "title3250": "默认去除表名的前缀。如果存在重复，则需要手动添加前缀，避免",
  "title3251": "报",
  "title3252": "重复的问题。",
  "title3253": "作者",
  "title3254": "请输入表名称",
  "title3255": "请输入表描述",
  "title3256": "请输入实体类名称",
  "title3257": "请输入作者",
  "title3258": "字段信息",
  "title3259": "字段列名",
  "title3260": "字段描述",
  "title3261": "物理类型",
  "title3262": "插入",
  "title3263": "列表",
  "title3264": "查询",
  "title3265": "查询方式",
  "title3266": "允许空",
  "title3267": "显示类型",
  "title3268": "文本框",
  "title3269": "文本域",
  "title3270": "下拉框",
  "title3271": "单选框",
  "title3272": "复选框",
  "title3273": "日期控件",
  "title3274": "图片上传",
  "title3275": "文件上传",
  "title3276": "富文本控件",
  "title3277": "示例",
  "title3278": "生成信息",
  "title3279": "表单校验未通过，请重新检查提交内容",
  "title3280": "选项名",
  "title3281": "请输入选项名",
  "title3282": "选项值",
  "title3283": "请输入选项值",
  "title3284": "字符串",
  "title3285": "数字",
  "title3286": "生成模板",
  "title3287": "生成场景",
  "title3288": "模块名",
  "title3289": "模块名，即一级目录，例如",
  "title3290": "等等",
  "title3291": "业务名",
  "title3292": "业务名，即二级目录，例如",
  "title3293": "类名称",
  "title3294": "类名称（首字母大写），例如",
  "title3295": "类描述",
  "title3296": "用作类描述，例如",
  "title3297": "上级菜单",
  "title3298": "分配到指定菜单下，例如",
  "title3299": "系统管理",
  "title3300": "请选择系统菜单",
  "title3301": "自定义路径",
  "title3302": "填写磁盘绝对路径，若不填写，则生成到当前",
  "title3303": "项目下",
  "title3304": "最近路径快速选择",
  "title3305": "恢复默认的生成基础路径",
  "title3306": "其他信息",
  "title3307": "树编码字段",
  "title3308": "树显示的编码字段名，",
  "title3309": "树父编码字段",
  "title3310": "树显示的父编码字段名，",
  "title3311": "树名称字段",
  "title3312": "树节点的显示名称字段名，",
  "title3313": "关联信息",
  "title3314": "关联子表的表名",
  "title3315": "关联子表的表名，",
  "title3316": "子表关联的外键名",
  "title3317": "子表关联的外键名，",
  "title3318": "请选择生成模板",
  "title3319": "请选择生成场景",
  "title3320": "请输入生成模块名",
  "title3321": "请输入生成业务名",
  "title3322": "请输入生成业务包",
  "title3323": "请输入生成类名称",
  "title3324": "请输入生成类描述",
  "title3325": "暂时不考虑支持【树形】和【主子表】的代码生成。原因是：导致",
  "title3326": "模板过于复杂，不利于胖友二次开发",
  "title3327": "导入表",
  "title3328": "数据源",
  "title3329": "请选择数据源",
  "title3330": "导入成功",
  "title3331": "单元测试",
  "title3332": "实体",
  "title3333": "预览",
  "title3334": "生成代码",
  "title3335": "代码预览",
  "title3336": "确认要强制同步",
  "title3337": "表结构吗？",
  "title3338": "生成配置",
  "title3339": "是否确认删除表名称为",
  "title3340": "未知【",
  "title3341": "配置中心",
  "title3342": "参数名称",
  "title3343": "请输入参数名称",
  "title3344": "参数键名",
  "title3345": "请输入参数键名",
  "title3346": "系统内置",
  "title3347": "参数主键",
  "title3348": "参数分类",
  "title3349": "参数键值",
  "title3350": "是否可见",
  "title3351": "请输入参数分类",
  "title3352": "请输入参数键值",
  "title3353": "参数分类不能为空",
  "title3354": "参数名称不能为空",
  "title3355": "参数键名不能为空",
  "title3356": "参数键值不能为空",
  "title3357": "添加参数",
  "title3358": "修改参数",
  "title3359": "是否确认删除参数编号为",
  "title3360": "是否确认导出所有参数数据项",
  "title3361": "参数配置",
  "title3362": "组件属性",
  "title3363": "表单属性",
  "title3364": "查看组件文档",
  "title3365": "组件类型",
  "title3366": "请选择组件类型",
  "title3367": "字段名",
  "title3368": "请输入字段名（",
  "title3369": "组件名",
  "title3370": "标题",
  "title3371": "请输入标题",
  "title3372": "占位提示",
  "title3373": "请输入占位提示",
  "title3374": "开始占位",
  "title3375": "结束占位",
  "title3376": "表单栅格",
  "title3377": "栅格间隔",
  "title3378": "布局模式",
  "title3379": "水平排列",
  "title3380": "请选择水平排列",
  "title3381": "垂直排列",
  "title3382": "标签宽度",
  "title3383": "请输入标签宽度",
  "title3384": "组件宽度",
  "title3385": "请输入组件宽度",
  "title3386": "请输入默认值",
  "title3387": "至少应选",
  "title3388": "最多可选",
  "title3389": "请输入前缀",
  "title3390": "后缀",
  "title3391": "请输入后缀",
  "title3392": "前图标",
  "title3393": "请输入前图标名称",
  "title3394": "后图标",
  "title3395": "请输入后图标名称",
  "title3396": "按钮图标",
  "title3397": "请输入按钮图标名称",
  "title3398": "选项分隔符",
  "title3399": "请输入选项分隔符",
  "title3400": "最小行数",
  "title3401": "最大行数",
  "title3402": "最小值",
  "title3403": "最大值",
  "title3404": "组件高度",
  "title3405": "步长",
  "title3406": "步数",
  "title3407": "按钮位置",
  "title3408": "默认",
  "title3409": "右侧",
  "title3410": "最多输入",
  "title3411": "请输入字符长度",
  "title3412": "开启提示",
  "title3413": "请输入开启提示",
  "title3414": "关闭提示",
  "title3415": "请输入关闭提示",
  "title3416": "开启值",
  "title3417": "请输入开启值",
  "title3418": "关闭值",
  "title3419": "请输入关闭值",
  "title3420": "时间类型",
  "title3421": "请选择时间类型",
  "title3422": "文件字段名",
  "title3423": "请输入上传文件字段名",
  "title3424": "文件类型",
  "title3425": "请选择文件类型",
  "title3426": "主键编号",
  "title3427": "数据源名称",
  "title3428": "数据源连接",
  "title3429": "请输入数据源连接",
  "title3430": "数据源名称不能为空",
  "title3431": "数据源连接不能为空",
  "title3432": "添加数据源配置",
  "title3433": "修改数据源配置",
  "title3434": "是否确认删除数据源配置编号为",
  "title3435": "数据库文档",
  "title3436": "上传下载",
  "title3437": "文件路径",
  "title3438": "请输入文件路径",
  "title3439": "文件大小",
  "title3440": "文件内容",
  "title3441": "无法预览，点击",
  "title3442": "上传时间",
  "title3443": "提示：仅允许导入",
  "title3444": "是否确认删除文件编号为",
  "title3445": "配置名",
  "title3446": "请输入配置名",
  "title3447": "存储器",
  "title3448": "请选择存储器",
  "title3449": "主配置",
  "title3450": "基础路径",
  "title3451": "请输入基础路径",
  "title3452": "主机地址",
  "title3453": "请输入主机地址",
  "title3454": "主机端口",
  "title3455": "请输入主机端口",
  "title3456": "连接模式",
  "title3457": "主动模式",
  "title3458": "节点地址",
  "title3459": "请输入节点地址",
  "title3460": "存储",
  "title3461": "自定义域名",
  "title3462": "请输入自定义域名",
  "title3463": "配置名不能为空",
  "title3464": "存储器不能为空",
  "title3465": "基础路径不能为空",
  "title3466": "主机地址不能为空",
  "title3467": "主机端口不能为空",
  "title3468": "连接模式不能为空",
  "title3469": "节点地址不能为空",
  "title3470": "自定义域名不能为空",
  "title3471": "添加文件配置",
  "title3472": "修改文件配置",
  "title3473": "是否确认删除文件配置编号为",
  "title3474": "是否确认修改配置编号为",
  "title3475": "的数据项为主配置",
  "title3476": "测试通过，上传文件成功！访问地址：",
  "title3477": "数据库",
  "title3478": "多数据源（读写分离）",
  "title3479": "异步任务",
  "title3480": "消息队列",
  "title3481": "处理器的名字",
  "title3482": "请输入处理器的名字",
  "title3483": "开始执行时间",
  "title3484": "选择开始执行时间",
  "title3485": "结束执行时间",
  "title3486": "选择结束执行时间",
  "title3487": "任务状态",
  "title3488": "请选择任务状态",
  "title3489": "处理器的参数",
  "title3490": "第几次执行",
  "title3491": "执行时间",
  "title3492": "毫秒",
  "title3493": "调度日志详细",
  "title3494": "日志编号：",
  "title3495": "任务编号：",
  "title3496": "处理器的名字：",
  "title3497": "处理器的参数：",
  "title3498": "第几次执行：",
  "title3499": "执行时间：",
  "title3500": "执行时长：",
  "title3501": "任务状态：",
  "title3502": "执行结果：",
  "title3503": "是否确认导出所有定时任务日志数据项",
  "title3504": "定时任务日志",
  "title3505": "请输入任务名称",
  "title3506": "执行日志",
  "title3507": "表达式",
  "title3508": "暂停",
  "title3509": "更多",
  "title3510": "执行一次",
  "title3511": "任务详细",
  "title3512": "调度日志",
  "title3513": "请输入处理器的参数",
  "title3514": "生成表达式",
  "title3515": "重试次数",
  "title3516": "请输入重试次数。设置为",
  "title3517": "时，不进行重试",
  "title3518": "重试间隔",
  "title3519": "请输入重试间隔，单位：毫秒。设置为",
  "title3520": "时，无需间隔",
  "title3521": "监控超时时间",
  "title3522": "请输入监控超时时间，单位：毫秒",
  "title3523": "表达式生成器",
  "title3524": "任务名称：",
  "title3525": "表达式：",
  "title3526": "重试次数：",
  "title3527": "重试间隔：",
  "title3528": "监控超时时间：",
  "title3529": "后续执行时间：",
  "title3530": "任务名称不能为空",
  "title3531": "处理器的名字不能为空",
  "title3532": "表达式不能为空",
  "title3533": "重试次数不能为空",
  "title3534": "重试间隔不能为空",
  "title3535": "确认要立即执行一次",
  "title3536": "任务吗",
  "title3537": "执行成功",
  "title3538": "添加任务",
  "title3539": "修改任务",
  "title3540": "是否确认删除定时任务编号为",
  "title3541": "定时任务编号为",
  "title3542": "是否确认导出所有定时任务数据项",
  "title3543": "服务监控",
  "title3544": "接口文档",
  "title3545": "参数分组",
  "title3546": "是否敏感",
  "title3547": "请输入参数分组",
  "title3548": "参数分组不能为空",
  "title3549": "缓存",
  "title3550": "本地缓存",
  "title3551": "版本",
  "title3552": "运行模式",
  "title3553": "单机",
  "title3554": "集群",
  "title3555": "端口",
  "title3556": "客户端数",
  "title3557": "运行时间",
  "title3558": "使用内存",
  "title3559": "内存配置",
  "title3560": "是否开启",
  "title3561": "是否成功",
  "title3562": "网络入口",
  "title3563": "出口",
  "title3564": "命令统计",
  "title3565": "内存信息",
  "title3566": "超时时间",
  "title3567": "秒",
  "title3568": "键名列表",
  "title3569": "缓存键名",
  "title3570": "缓存内容",
  "title3571": "清理全部",
  "title3572": "命令",
  "title3573": "峰值",
  "title3574": "内存消耗",
  "title3575": "正在加载缓存监控数据，请稍后！",
  "title3576": "刷新键名列表成功",
  "title3577": "清理缓存键名",
  "title3578": "名字",
  "title3579": "请输入名字",
  "title3580": "名字不能为空",
  "title3581": "分类不能为空",
  "title3582": "添加字典类型",
  "title3583": "修改字典类型",
  "title3584": "是否确认删除字典类型编号为",
  "title3585": "是否确认导出所有字典类型数据项",
  "title3586": "第三方服务类型",
  "title3587": "请选择第三方服务类型",
  "title3588": "配置编码",
  "title3589": "请输入配置编码",
  "title3590": "请选择开启状态",
  "title3591": "配置内容",
  "title3592": "第三方服务类型不能为空",
  "title3593": "配置编码不能为空",
  "title3594": "配置内容不能为空",
  "title3595": "添加第三方服务配置",
  "title3596": "修改第三方服务配置",
  "title3597": "是否确认删除第三方服务配置编号为",
  "title3598": "是否确认导出所有第三方服务配置数据项",
  "title3599": "优惠券类",
  "title3600": "请选择优惠券类型",
  "title3601": "满减",
  "title3602": "折扣",
  "title3603": "随机",
  "title3604": "优惠券名称",
  "title3605": "请输入优惠券名称",
  "title3606": "请选择状态（1进行中2已结束-1已关闭）",
  "title3607": "有效日期结束时间",
  "title3608": "优惠券类型",
  "title3609": "优惠券图片",
  "title3610": "发放数量",
  "title3611": "已领取数量",
  "title3612": "已使用数量",
  "title3613": "使用门槛",
  "title3614": "优惠金额",
  "title3615": "有效期限",
  "title3616": "领取上限",
  "title3617": "优惠叠加",
  "title3618": "是否显示",
  "title3619": "订单的优惠总金额",
  "title3620": "用券总成交额",
  "title3621": "是否禁止发放",
  "title3622": "使用优惠券购买的商品数量",
  "title3623": "名称备注",
  "title3624": "请输入名称备注",
  "title3625": "请输入发放数量",
  "title3626": "适用商品类型",
  "title3627": "适用商品",
  "title3628": "请输入适用商品",
  "title3629": "满多少元使用",
  "title3630": "请输入满多少元使用",
  "title3631": "代表无限制",
  "title3632": "发放面额",
  "title3633": "请输入发放面额",
  "title3634": "时需要添加",
  "title3635": "请输入1",
  "title3636": "最多折扣金额",
  "title3637": "请输入最多折扣金额",
  "title3638": "时可选择性添加",
  "title3639": "最低金额",
  "title3640": "请输入最低金额",
  "title3641": "最大金额",
  "title3642": "请输入最大金额",
  "title3643": "过期类型",
  "title3644": "使用开始日期",
  "title3645": "过期类型1时必填",
  "title3646": "选择使用开始日期",
  "title3647": "使用结束日期",
  "title3648": "选择使用结束日期",
  "title3649": "为2或者3时需要添加",
  "title3650": "领取之日起或者次日",
  "title3651": "天内有效",
  "title3652": "请输入当",
  "title3653": "是否无限制0-否",
  "title3654": "每人最大领取个数",
  "title3655": "请输入每人最大领取个数",
  "title3656": "是否开启过期提醒0-不开启",
  "title3657": "过期前",
  "title3658": "天提醒",
  "title3659": "请输入过期前",
  "title3660": "不限制",
  "title3661": "优惠券仅原价购买商品时可用",
  "title3662": "请输入是否显示",
  "title3663": "请输入订单的优惠总金额",
  "title3664": "请输入用券总成交额",
  "title3665": "是否禁止发放0-否",
  "title3666": "请输入使用优惠券购买的商品数量",
  "title3667": "选择有效日期结束时间",
  "title3668": "随机不能为空",
  "title3669": "优惠券名称不能为空",
  "title3670": "发放数量不能为空",
  "title3671": "已领取数量不能为空",
  "title3672": "已使用数量不能为空",
  "title3673": "适用商品类型1-全部商品可用；2-指定商品可用；3-指定商品不可用不能为空",
  "title3674": "使用门槛0-无门槛",
  "title3675": "有门槛不能为空",
  "title3676": "代表无限制不能为空",
  "title3677": "时需要添加不能为空",
  "title3678": "时可选择性添加不能为空",
  "title3679": "过期类型1-时间范围过期",
  "title3680": "领取之日固定日期后过期",
  "title3681": "领取次日固定日期后过期不能为空",
  "title3682": "天内有效不能为空",
  "title3683": "是不能为空",
  "title3684": "每人最大领取个数不能为空",
  "title3685": "开启不能为空",
  "title3686": "天提醒不能为空",
  "title3687": "优惠券仅原价购买商品时可用不能为空",
  "title3688": "是否显示不能为空",
  "title3689": "订单的优惠总金额不能为空",
  "title3690": "用券总成交额不能为空",
  "title3691": "使用优惠券购买的商品数量不能为空",
  "title3692": "状态（1进行中2已结束-1已关闭）不能为空",
  "title3693": "添加优惠券模板",
  "title3694": "修改优惠券模板",
  "title3695": "是否确认删除优惠券模板编号为",
  "title3696": "是否确认导出所有优惠券模板数据项",
  "title3697": "优惠券模板",
  "title3698": "身份证号",
  "title3699": "请输入身份证号",
  "title3700": "审核状态",
  "title3701": "请选择审核状态",
  "title3702": "审核时间",
  "title3703": "选择审核时间",
  "title3704": "关联的用户编号不能为空",
  "title3705": "身份证号不能为空",
  "title3706": "审核状态不能为空",
  "title3707": "审核时间不能为空",
  "title3708": "添加会员实名认证",
  "title3709": "修改会员实名认证",
  "title3710": "是否确认删除会员实名认证编号为",
  "title3711": "是否确认导出所有会员实名认证数据项",
  "title3712": "请输入推荐人编号",
  "title3713": "邀请人手机号",
  "title3714": "请输入邀请人手机号",
  "title3715": "会员编号",
  "title3716": "邀请人编号",
  "title3717": "关系路径",
  "title3718": "请输入邀请人编号",
  "title3719": "请输入关系路径",
  "title3720": "邀请人编号不能为空",
  "title3721": "关系路径不能为空",
  "title3722": "添加用户邀请关系",
  "title3723": "修改用户邀请关系",
  "title3724": "是否确认删除用户邀请关系编号为",
  "title3725": "是否确认导出所有用户邀请关系数据项",
  "title3726": "会员手机号",
  "title3727": "请输入会员手机号",
  "title3728": "收货号码",
  "title3729": "请输入收货号码",
  "title3730": "请选择是否默认",
  "title3731": "市",
  "title3732": "地址",
  "title3733": "请输入市",
  "title3734": "请输入地址",
  "title3735": "用户编号不能为空",
  "title3736": "地址不能为空",
  "title3737": "添加收货地址",
  "title3738": "修改收货地址",
  "title3739": "是否确认删除收货地址编号为",
  "title3740": "是否确认导出所有收货地址数据项",
  "title3741": "用户昵称",
  "title3742": "请输入用户昵称",
  "title3743": "用户状态",
  "title3744": "是否实名",
  "title3745": "请输入注册",
  "title3746": "最后登录",
  "title3747": "最后登录时间",
  "title3748": "重置短信",
  "title3749": "重置实名",
  "title3750": "重置锁单",
  "title3751": "重置成功",
  "title3752": "添加会员",
  "title3753": "修改会员",
  "title3754": "是否确认导出所有会员数据项",
  "title3755": "商户名称",
  "title3756": "请输入商户名称",
  "title3757": "应用编号",
  "title3758": "支付宝配置",
  "title3759": "微信配置",
  "title3760": "所属商户",
  "title3761": "请选择所属商户",
  "title3762": "支付结果的回调地址",
  "title3763": "请输入支付结果的回调地址",
  "title3764": "退款结果的回调地址",
  "title3765": "请输入退款结果的回调地址",
  "title3766": "应用名不能为空",
  "title3767": "支付结果的回调地址不能为空",
  "title3768": "退款结果的回调地址不能为空",
  "title3769": "商户编号不能为空",
  "title3770": "添加支付应用信息",
  "title3771": "修改支付应用信息",
  "title3772": "应用吗",
  "title3773": "是否确认删除支付应用信息编号为",
  "title3774": "已取消删除",
  "title3775": "是否确认导出所有支付应用信息数据项",
  "title3776": "支付应用信息",
  "title3777": "商户号",
  "title3778": "请输入商户号",
  "title3779": "商户全称",
  "title3780": "请输入商户全称",
  "title3781": "商户简称",
  "title3782": "请输入商户简称",
  "title3783": "商户编号",
  "title3784": "商户号不能为空",
  "title3785": "商户全称不能为空",
  "title3786": "商户简称不能为空",
  "title3787": "添加支付商户信息",
  "title3788": "修改支付商户信息",
  "title3789": "商户吗",
  "title3790": "是否确认删除支付商户信息编号为",
  "title3791": "是否确认导出所有支付商户信息数据项",
  "title3792": "支付商户信息",
  "title3793": "请选择应用信息",
  "title3794": "渠道编码",
  "title3795": "请输入渠道编码",
  "title3796": "商户订单编号",
  "title3797": "请输入商户订单编号",
  "title3798": "渠道订单号",
  "title3799": "请输入渠道订单号",
  "title3800": "请选择支付状态",
  "title3801": "退款状态",
  "title3802": "请选择退款状态",
  "title3803": "回调商户状态",
  "title3804": "请选择订单回调商户状态",
  "title3805": "支付渠道",
  "title3806": "应用名称",
  "title3807": "渠道名称",
  "title3808": "支付订单",
  "title3809": "商户",
  "title3810": "支付",
  "title3811": "商品标题",
  "title3812": "手续金额",
  "title3813": "回调状态",
  "title3814": "商户订单号",
  "title3815": "支付订单号",
  "title3816": "手续费",
  "title3817": "手续费比例",
  "title3818": "回调地址",
  "title3819": "失效时间",
  "title3820": "通知时间",
  "title3821": "退款次数",
  "title3822": "支付通道异步回调内容",
  "title3823": "时间范围最大为",
  "title3824": "天！",
  "title3825": "是否确认导出所有支付订单数据项",
  "title3826": "退款类型",
  "title3827": "请选择退款类型",
  "title3828": "商户退款订单号",
  "title3829": "请输入商户退款订单号",
  "title3830": "退款回调状态",
  "title3831": "请选择通知商户退款结果的回调状态",
  "title3832": "退款",
  "title3833": "交易",
  "title3834": "渠道",
  "title3835": "元",
  "title3836": "退款原因",
  "title3837": "退款成功时间",
  "title3838": "退款订单详情",
  "title3839": "商户退款单号",
  "title3840": "交易订单号",
  "title3841": "退款失效时间",
  "title3842": "回调时间",
  "title3843": "渠道退款单号",
  "title3844": "渠道错误码",
  "title3845": "渠道错误码描述",
  "title3846": "渠道额外参数",
  "title3847": "是否确认导出所有退款订单量据项",
  "title3848": "字典名称",
  "title3849": "字典标签",
  "title3850": "请输入字典标签",
  "title3851": "数据状态",
  "title3852": "字典编码",
  "title3853": "字典键值",
  "title3854": "字典排序",
  "title3855": "颜色类型",
  "title3856": "数据标签",
  "title3857": "请输入数据标签",
  "title3858": "数据键值",
  "title3859": "请输入数据键值",
  "title3860": "显示排序",
  "title3861": "数据标签不能为空",
  "title3862": "数据键值不能为空",
  "title3863": "数据顺序不能为空",
  "title3864": "主要",
  "title3865": "危险",
  "title3866": "添加字典数据",
  "title3867": "修改字典数据",
  "title3868": "是否确认删除字典编码为",
  "title3869": "字典数据",
  "title3870": "请输入字典名称",
  "title3871": "请输入字典类型",
  "title3872": "字典状态",
  "title3873": "字典编号",
  "title3874": "字典名称不能为空",
  "title3875": "字典类型不能为空",
  "title3876": "是否确认删除字典编号为",
  "title3877": "部门名称",
  "title3878": "请输入部门名称",
  "title3879": "菜单状态",
  "title3880": "负责人",
  "title3881": "上级部门",
  "title3882": "选择上级部门",
  "title3883": "请输入负责人",
  "title3884": "部门状态",
  "title3885": "部门名称不能为空",
  "title3886": "显示排序不能为空",
  "title3887": "请输入正确的邮箱地址",
  "title3888": "未设置",
  "title3889": "添加部门",
  "title3890": "修改部门",
  "title3891": "异常处理（错误码）",
  "title3892": "错误码类型",
  "title3893": "请选择错误码类型",
  "title3894": "错误码编码",
  "title3895": "请输入错误码编码",
  "title3896": "错误码提示",
  "title3897": "请输入错误码提示",
  "title3898": "错误码编码不能为空",
  "title3899": "错误码提示不能为空",
  "title3900": "添加错误码",
  "title3901": "修改错误码",
  "title3902": "是否确认删除错误码编号为",
  "title3903": "是否确认导出所有错误码数据项",
  "title3904": "错误码",
  "title3905": "文章类型",
  "title3906": "请选择文章类型",
  "title3907": "文章标题",
  "title3908": "请输入文章标题",
  "title3909": "文章内容",
  "title3910": "文章类型不能为空",
  "title3911": "文章标题不能为空",
  "title3912": "文章内容不能为空",
  "title3913": "添加文章",
  "title3914": "修改文章",
  "title3915": "是否确认删除文章编号为",
  "title3916": "是否确认导出所有文章数据项",
  "title3917": "โปรดใส่สัญลักษณ์สกุลเงิน",
  "title3918": "ชื่อสกุลเงิน",
  "title3919": "หน้าปัจจุบันมีฟังก์ชั่นการแก้ไขอัตราแลกเปลี่ยน 'ประวั ติศาสตร์' ซึ่งจะส่งผลต่อการสั่งซื้อทั้งหมด 'ยั งไม่ ชำระ' ภายในวันที่แก้ไขและมีผลกระทบต่องบการเงินและการคำนวณกำไร หากคุณต้องการแก้ไขอัตราแลกเปลี่ยนของการสั่งซื้อ 'ชำระแล้ ว' โปรดไปที่การวิเคราะห์กำไร -",
  "title3920": "รายงานกำไรเดือนละ",
  "title3921": "สำหรับการปรับแก้ในหน้า",
  "title3922": "สัญลักษณ์สกุลเงิน",
  "title3923": "อัตราแลกเปลี่ยนทางการของรัฐ",
  "title3924": "อัตราแลกเปลี่ยนมาจากอัตราทางการทุกวัน 0",
  "title3925": "อัปเดตอัตราแลกเปลี่ยน",
  "title3926": "ประเภทอัตราแลกเปลี่ยนการดำเนินการ",
  "title3927": "อัตราแลกเปลี่ยนคงที่: อัตราแลกเปลี่ยนที่กำหนดโดยผู้ใช้",
  "title3928": "อัตราแลกเปลี่ยนส่วนลด: อัตราที่ถูกคำนวณโดยการคูณจากอัตราทางการด้วยเปอร์เซ็นต์ส่วนลดในวันนั้น",
  "title3929": "ตัวอย่าง เช่น ดอลลาร์สหรัฐอเมริกา",
  "title3930": "อัตราแลกเปลี่ยนคือ 7",
  "title3931": "และคุณได้ตั้งอัตราส่วนลดเป็น 98",
  "title3932": "จากนั้นอัตราแลกเปลี่ยนการดำเนินการตามจริง",
  "title3933": "อัตราแลกเปลี่ยนส่วนลด:",
  "title3934": "อัตราแลกเปลี่ยนคงที่:",
  "title3935": "อัตราแลกเปลี่ยนการดำเนินการ",
  "title3936": "อัตราแลกเปลี่ยนประวัติศาสตร์",
  "title3937": "บันทึก",
  "title3938": "เพิ่มเทมเพลต",
  "title3939": "โปรดใส่ชื่อเทมเพลต",
  "title3940": "ชื่อเทมเพลต",
  "title3941": "ต้องเลือกอย่างน้อยหนึ่งแม่แบบ",
  "title3942": "ลบแม่แบบที่เลือกไว้หรือไม่",
  "title3943": "ลบ?",
  "title3944": "请输入登录地址",
  "title3945": "请输入用户名称",
  "title3946": "访问编号",
  "title3947": "日志类型",
  "title3948": "登录日期",
  "title3949": "是否确认导出所有操作日志数据项",
  "title3950": "菜单路由",
  "title3951": "请输入菜单名称",
  "title3952": "菜单名称",
  "title3953": "分组名称",
  "title3954": "图标",
  "title3955": "权限标识",
  "title3956": "组件路径",
  "title3957": "选择上级菜单",
  "title3958": "菜单类型",
  "title3959": "菜单图标",
  "title3960": "点击选择图标",
  "title3961": "请输入分组名称",
  "title3962": "路由地址",
  "title3963": "访问的路由地址，如：",
  "title3964": "如需外网地址时，则以",
  "title3965": "请输入路由地址",
  "title3966": "请输入组件路径",
  "title3967": "方法上的权限字符，如：",
  "title3968": "权限字符",
  "title3969": "请权限标识",
  "title3970": "选择停用时，路由将不会出现在侧边栏，也不能被访问",
  "title3971": "显示状态",
  "title3972": "选择隐藏时，路由将不会出现在侧边栏，但仍然可以访问",
  "title3973": "显示",
  "title3974": "隐藏",
  "title3975": "选择缓存时，则会被",
  "title3976": "缓存，需要匹配组件的",
  "title3977": "和路由地址保持一致",
  "title3978": "是否缓存",
  "title3979": "不缓存",
  "title3980": "菜单名称不能为空",
  "title3981": "菜单顺序不能为空",
  "title3982": "路由地址不能为空",
  "title3983": "主类目",
  "title3984": "添加菜单",
  "title3985": "修改菜单",
  "title3986": "前端必须以",
  "title3987": "前端不能以",
  "title3988": "公告标题",
  "title3989": "请输入公告标题",
  "title3990": "操作人员",
  "title3991": "请输入操作人员",
  "title3992": "公告类型",
  "title3993": "内容类型",
  "title3994": "创建者",
  "title3995": "内容",
  "title3996": "公告标题不能为空",
  "title3997": "公告类型不能为空",
  "title3998": "添加公告",
  "title3999": "修改公告",
  "title4000": "是否确认删除公告编号为",
  "title4001": "系统公告",
  "title4002": "平台公告",
  "title4003": "东南亚资讯",
  "title4004": "全部已读",
  "title4005": "全部标记已读成功",
  "title4006": "发布时间：",
  "title4007": "确认支付",
  "title4008": "确认该订单完成支付？",
  "title4009": "岗位编码",
  "title4010": "请输入岗位编码",
  "title4011": "岗位名称",
  "title4012": "请输入岗位名称",
  "title4013": "岗位状态",
  "title4014": "岗位编号",
  "title4015": "岗位排序",
  "title4016": "请输入编码名称",
  "title4017": "岗位顺序",
  "title4018": "岗位名称不能为空",
  "title4019": "岗位编码不能为空",
  "title4020": "岗位顺序不能为空",
  "title4021": "添加岗位",
  "title4022": "修改岗位",
  "title4023": "是否确认删除岗位编号为",
  "title4024": "是否确认导出所有岗位数据项",
  "title4025": "岗位数据",
  "title4026": "操作模块",
  "title4027": "请输入操作模块",
  "title4028": "操作名",
  "title4029": "请输入操作名",
  "title4030": "操作类型",
  "title4031": "操作状态",
  "title4032": "操作人",
  "title4033": "操作日期",
  "title4034": "操作信息：",
  "title4035": "方法名：",
  "title4036": "方法参数：",
  "title4037": "操作日志",
  "title4038": "请输入地区父",
  "title4039": "地区名称",
  "title4040": "请输入地区名称",
  "title4041": "地区类型",
  "title4042": "请选择地区类型",
  "title4043": "地区父",
  "title4044": "地区名称不能为空",
  "title4045": "地区类型不能为空",
  "title4046": "添加地区",
  "title4047": "修改地区",
  "title4048": "是否确认删除地区编号为",
  "title4049": "是否确认导出所有地区数据项",
  "title4050": "角色编号",
  "title4051": "ประเภทบทบาท",
  "title4052": "แก้ไขบทบาท",
  "title4053": "เนื่องจาก",
  "title4054": "คอมมิชชั่นของแพลตฟอร์มถูกชำระเมื่อมีการสั่งซื้อเสร็จเรียบร้อยเท่านั้นที่นี่คุณต้องตั้งอัตราคอมมิชชันโดยประมาณสำหรับการประมาณกำไรในการสั่งซื้อที่ยังไม่ชำระ",
  "title4055": "สูตรคำนวณคอมมิชชัน: (จำนวนสินค้า - ส่วนลดของแพลตฟอร์ม - ส่วนลดของผู้ขาย)",
  "title4056": "เปอร์เซ็นต์คอมมิชชันร้านค้า",
  "title4057": "หมายเหตุ: ทุกครั้งที่ค่าคอมมิชันถูกแก้ไข จะถูกคำนวณใหม่เมื่อมีการซิงค์ออเดอร์ที่ยังไม่ชำระเงิน อัตราค่าคอมมิชันเริ่มต้นคือ 3",
  "title4058": "อัตราค่าคอมมิชันของแพลตฟอร์ม",
  "title4059": "敏感词",
  "title4060": "请输入敏感词",
  "title4061": "请选择标签",
  "title4062": "请选择启用状态",
  "title4063": "请选择文章标签",
  "title4064": "检测敏感词",
  "title4065": "文本",
  "title4066": "请输入测试文本",
  "title4067": "检",
  "title4068": "测",
  "title4069": "敏感词不能为空",
  "title4070": "标签不能为空",
  "title4071": "测试文本不能为空",
  "title4072": "添加敏感词",
  "title4073": "检测文本是否含有敏感词",
  "title4074": "修改敏感词",
  "title4075": "不包含敏感词！",
  "title4076": "包含敏感词：",
  "title4077": "是否确认删除敏感词编号为",
  "title4078": "是否确认导出所有敏感词数据项",
  "title4079": "短信配置",
  "title4080": "短信签名",
  "title4081": "请输入短信签名",
  "title4082": "启用状态",
  "title4083": "短信",
  "title4084": "的账号",
  "title4085": "的密钥",
  "title4086": "短信发送回调",
  "title4087": "请选择渠道编码",
  "title4088": "请输入短信",
  "title4089": "请输入短信发送回调",
  "title4090": "短信签名不能为空",
  "title4091": "渠道编码不能为空",
  "title4092": "启用状态不能为空",
  "title4093": "的账号不能为空",
  "title4094": "添加短信渠道",
  "title4095": "修改短信渠道",
  "title4096": "是否确认删除短信渠道编号为",
  "title4097": "短信渠道",
  "title4098": "请选择短信渠道",
  "title4099": "模板编号",
  "title4100": "请输入模板编号",
  "title4101": "发送状态",
  "title4102": "请选择发送状态",
  "title4103": "发送时间",
  "title4104": "接收状态",
  "title4105": "请选择接收状态",
  "title4106": "接收时间",
  "title4107": "短信内容",
  "title4108": "短信类型",
  "title4109": "短信日志详细",
  "title4110": "短信渠道：",
  "title4111": "短信模板：",
  "title4112": "的模板编号：",
  "title4113": "短信内容：",
  "title4114": "短信参数：",
  "title4115": "发送状态：",
  "title4116": "发送时间：",
  "title4117": "发送结果：",
  "title4118": "短信编号：",
  "title4119": "请求编号：",
  "title4120": "接收状态：",
  "title4121": "接收时间：",
  "title4122": "接收结果：",
  "title4123": "是否确认导出所有短信日志数据项",
  "title4124": "短信日志",
  "title4125": "找不到签名：",
  "title4126": "请输入标签内容",
  "title4127": "标签内容",
  "title4128": "颜色",
  "title4129": "标签排序",
  "title4130": "请输入标签排序",
  "title4131": "请输入颜色",
  "title4132": "标签排序不能为空",
  "title4133": "标签内容不能为空",
  "title4134": "添加标签",
  "title4135": "修改标签",
  "title4136": "是否确认删除标签",
  "title4137": "是否确认导出所有标签数据项",
  "title4138": "多租户",
  "title4139": "租户名",
  "title4140": "请输入租户名",
  "title4141": "联系手机",
  "title4142": "请输入联系手机",
  "title4143": "租户状态",
  "title4144": "请选择租户状态",
  "title4145": "租户编号",
  "title4146": "租户套餐",
  "title4147": "系统租户",
  "title4148": "子账号数",
  "title4149": "过期时间",
  "title4150": "绑定域名",
  "title4151": "请选择租户套餐",
  "title4152": "用户密码",
  "title4153": "请输入用户密码",
  "title4154": "默认入口",
  "title4155": "请选择默认入口",
  "title4156": "请选择过期时间",
  "title4157": "请输入绑定域名",
  "title4158": "租户名不能为空",
  "title4159": "租户套餐不能为空",
  "title4160": "联系人不能为空",
  "title4161": "租户状态不能为空",
  "title4162": "过期时间不能为空",
  "title4163": "绑定域名不能为空",
  "title4164": "添加租户",
  "title4165": "修改租户",
  "title4166": "是否确认删除租户编号为",
  "title4167": "是否确认导出所有租户数据项",
  "title4168": "未知套餐",
  "title4169": "套餐名",
  "title4170": "请输入套餐名",
  "title4171": "套餐编号",
  "title4172": "受限页面",
  "title4173": "勾选的菜单将会被限制，在这些页面将会有弹框弹出显示",
  "title4174": "套餐名不能为空",
  "title4175": "关联的菜单编号不能为空",
  "title4176": "添加租户套餐",
  "title4177": "修改租户套餐",
  "title4178": "是否确认删除租户套餐编号为",
  "title4179": "请选择短信类型",
  "title4180": "模板编码",
  "title4181": "请输入模板编码",
  "title4182": "的模板编号",
  "title4183": "模板内容",
  "title4184": "短信渠道编号",
  "title4185": "请选择短信渠道编号",
  "title4186": "请输入模板内容",
  "title4187": "测试发送短信",
  "title4188": "短信类型不能为空",
  "title4189": "模板编码不能为空",
  "title4190": "模板名称不能为空",
  "title4191": "模板内容不能为空",
  "title4192": "的模板编号不能为空",
  "title4193": "短信渠道编号不能为空",
  "title4194": "手机不能为空",
  "title4195": "添加短信模板",
  "title4196": "修改短信模板",
  "title4197": "是否确认删除短信模板编号为",
  "title4198": "是否确认导出所有短信模板数据项",
  "title4199": "短信模板",
  "title4200": "提交发送成功！发送结果，见发送日志编号：",
  "title4201": "用户体系",
  "title4202": "三方登陆",
  "title4203": "部门",
  "title4204": "归属部门",
  "title4205": "请选择归属部门",
  "title4206": "用户性别",
  "title4207": "岗位",
  "title4208": "用户名称不能为空",
  "title4209": "用户昵称不能为空",
  "title4210": "用户密码不能为空",
  "title4211": "的新密码",
  "title4212": "报表设计器",
  "title4213": "ยินดีต้อนรับในการใช้",
  "title4214": "สนทนาอิสระ",
  "title4215": "ขั้นสูง",
  "title4216": "โมเดลอัจฉริยะ",
  "title4217": "ทำให้การสื่อสารของคุณเชื่อมต่อได้ดียิ่งขึ้น, มีประสิทธิภาพมากขึ้น และสะดวกมากขึ้น!",
  "title4218": "วิธีการถามอย่างมีประสิทธิภาพมากขึ้น?",
  "title4219": "คุณสามารถลองถามดังนี้:",
  "title4220": "บันทึก",
  "title4221": "ทุกเนื้อหาที่สร้างขึ้นโดยบริการถูกสร้างโดยโมเดล AI และความแม่นยำและความสมบูรณ์ของเนื้อหาที่สร้างไม่สามารถรับประกันได้ และไม่แทนท่านทัศนคติหรือมุมมองของเรา",
  "title4222": "ตั้งบทบาท",
  "title4223": "กำหนดตัวตนอาชีพ",
  "title4224": "เช่น: คุณเป็นผู้เชี่ยวชาญที่มีประสบการณ์ในการตลาด",
  "title4225": "ลิสต์คำถาม",
  "title4226": "ใส่คำถามของคุณพร้อมกับข้อมูลพื้นฐาน, ความต้องการเฉพาะ, จุดมุ่งหมายของงาน, ฯลฯ;",
  "title4227": "รูปแบบการแสดงผล",
  "title4228": "บอก",
  "title4229": "ต้องการในรูปแบบอะไรและส่งผลและเนื้อหาออกมาอย่างไร?",
  "title4230": "สร้างฐานความรู้อัจฉริยะ",
  "title4231": "แก้ไขฐานความรู้อัจฉริยะ",
  "title4232": "ชื่อฐานความรู้อัจฉริยะ",
  "title4233": "โปรดป้อนชื่อฐานความรู้",
  "title4234": "เลือกแพลตฟอร์ม",
  "title4235": "แนะนำฐานความรู้",
  "title4236": "โปรดป้อนข้อมูลที่สั้น",
  "title4237": "สร้างเทมเพลต",
  "title4238": "บันทึกเทมเพลต",
  "title4239": "ได้ใช้แล้ว",
  "title4240": "ชื่อเอกสาร",
  "title4241": "ขนาด",
  "title4242": "โปรดเลือกไฟล์",
  "title4243": "ขนาดไฟล์ที่อัพโหลดต้องไม่เกิน",
  "title4244": "โปรดป้อนการแนะนำฐานความรู้",
  "title4245": "รายการอัตราแลกเปลี่ยน",
  "title4246": "กราฟแนวโน้มอัตราแลกเปลี่ยน",
  "title4247": "อัตราแลกเปลี่ยนอย่างเป็นทางการรายเดือน",
  "title4248": "อัตราการดำเนินงานเฉลี่ยรายเดือน",
  "title4249": "เสร็จสิ้นทันที",
  "title4250": "ใส่ความต้องการของคุณในหน้า 'ใส่ เนื้ อหา' เพื่อสร้างสำเนาคุณภาพสูงทันที",
  "title4251": "อัปเดตกำไร",
  "title4252": "การตั้งค่าอัตราการชำระเงิน",
  "title4253": "เพิ่มค่าใช้จ่ายที่กำหนดเอ",
  "title4254": "ส่งออกรายงาน",
  "title4255": "ใบแสดงบัญชีการชำระเงิน",
  "title4256": "ระบบรองรับการสลับสกุลเงินเมื่อเลือกร้านค้าเดียวเท่านั้น ไม่รองรับการเปลี่ยนร้านค้าหลายร้านค้า",
  "title4257": "จำนวนคำสั่งชำระเงิน",
  "title4258": "ยอดเงินทั้งหมดจากการชำระเงินของคำสั่งซื้อ",
  "title4259": "ค่าใช้จ่ายสินค้า",
  "title4260": "จำนวนเงินชำระการประเมิน - จำนวนเงินกลับการประเมิน",
  "title4261": "ค่าธรรมเนียมที่กำหนดเอง",
  "title4262": "ดูเพิ่มเติม",
  "title4263": "ตารางสรุปรายได้จากคำสั่งซื้อ",
  "title4264": "ใบแสดงบัญชีของแพลตฟอร์ม",
  "title4265": "ค่าใช้จ่าย",
  "title4266": "จำนวนเงินชำระของผู้ซื้อ",
  "title4267": "ค่าใช้จ่ายทั้งหมด",
  "title4269": "ส่วนลดของผู้ขาย",
  "title4270": "จำนวนเงินชำระคำสั่งซื้อที่มีประสิทธิภาพ",
  "title4271": "จำนวนการปรับตามเดือน",
  "title4272": "จำนวนคำสั่งหยุดชั่วคราว",
  "title4273": "จำนวนคำสั่งประเมิน",
  "title4274": "จำนวนเงินชำระคำสั่งซื้อที่ถูกหยุดชั่วคราว",
  "title4275": "จำนวนเงินชำระคำสั่งตรวจสอบ",
  "title4276": "จำนวนเงินชำระการประเมิน",
  "title4277": "รายได้ - ค่าใช้จ่ายสินค้า - ค่าบริการที่กำหนดเอง - ความสูญเสียการประเมิน",
  "title4278": "รายได้ของแพลตฟอร์ม",
  "title4279": "ค่าใช้จ่ายทางแพลตฟอร์ม",
  "title4280": "ค่าคอมมิชชั่น, ค่าบริการ, ค่าธุรกรรม",
  "title4281": "ค่าใช้จ่ายอื่นในร้าน",
  "title4282": "ค่าใช้จ่ายการประเมิน",
  "title4283": "จำนวนคำสั่งตรวจสอบ",
  "title4284": "โครงการ",
  "title4285": "รายได้และค่าใช้จ่ายที่กำหนดเอง",
  "title4286": "ค่าใช้จ่ายที่ชี้แจง",
  "title4287": "ค่าใช้จ่ายเป็นเปอร์เซ็นต์ของค่าใช้จ่ายทั้งหมด",
  "title4288": "ค่าใช้จ่ายเป็นเปอร์เซ็นต์ของยอดขายที่มีประสิทธิภาพ",
  "title4289": "ค่าใช้จ่ายโปรโมชั่นที่ชี้แจง",
  "title4290": "รายได้และค่าใช้จ่ายอื่นๆ",
  "title4291": "ค่าทุนสินค้าออนไลน์",
  "title4292": "การคืนเงิน",
  "title4293": "เลือกข้อมูลที่ต้องการส่งออกโดยกรองเกณฑ์ โปรดเลือกทั้งหมดเมื่อสร้างรายงาน",
  "title4294": "สรุปข้อมูล",
  "title4295": "จำนวนผู้เยี่ยมชมที่ไม่ซ้ำกันทั้งหมดที่เข้าชมร้านของคุณและหน้ารายละเอียดสินค้าในช่วงเวลาที่เลือก",
  "title4296": "จำนวนรวมของคำสั่งซื้อที่ได้รับการยืนยัน",
  "title4297": "ยอดรวมของคำสั่งซื้อที่ได้รับการยืนยัน",
  "title4298": "ปริมาณขายทดสอบ",
  "title4299": "จำนวนการประเมิน",
  "title4300": "ยอดขายต่อคำสั่งซื้อ",
  "title4301": "อัตราการแปลงร้าน",
  "title4302": "ไม่รวมรีวิว + พัก + ยกเลิก + ยังไม่ชำระเงิน การขาย",
  "title4303": "ค่าโฆษณาการค้นหา",
  "title4304": "การแปลงโฆษณาการค้นหา",
  "title4305": "ยอดขายโฆษณาการค้นหา",
  "title4306": "อัตราคลิกโฆษณาการค้นหา",
  "title4307": "อัตราการแปลงโฆษณาการค้นหา",
  "title4308": "โฆษณาการค้นหา",
  "title4309": "ค่าโฆษณาพันธมิตร",
  "title4310": "การแปลงโฆษณาพันธมิตร",
  "title4311": "ยอดขายโฆษณาพันธมิตร",
  "title4312": "อัตราคลิกโฆษณาพันธมิตร",
  "title4313": "อัตราการแปลง",
  "title4314": "ค่าโฆษณาโปรโมชั่น",
  "title4315": "การแปลงโฆษณาโปรโมชัน",
  "title4316": "ยอดขายโฆษณาโปรโมชัน",
  "title4317": "อัตราการคลิกโฆษณาโปรโมชัน",
  "title4318": "อัตราการแปลงโฆษณาโปรโมชัน",
  "title4319": "โฆษณาโปรโมชัน",
  "title4320": "ค่าขนส่งโดยประมาณ",
  "title4321": "ผลผลิต",
  "title4322": "ยอดขายสุทธิ - ค่าโฆษณาการค้นหา - ค่าโฆษณาพันธมิตร - ค่าโฆษณาโปรโมชั่น - ค่าสินค้า - ความเสียหายจากการประเมิน - ค่าขนส่งโดยประมาณ - ค่าบริการแพลตฟอร์ม - ยอดยกเลิก",
  "title4324": "ปริมาณ",
  "title4325": "จำนวนรายการที่ยืนยันแล้ว",
  "title4326": "จำนวนการขาย",
  "title4327": "ปริมาณการสั่งซื้อจริง",
  "title4328": "จำนวนคำสั่งรวม - จำนวนคำสั่งการประเมิน",
  "title4329": "มูลค่าเฉลี่ยต่อคำสั่ง",
  "title4330": "จำนวนผู้ซื้อที่ยืนยันแล้ว",
  "title4331": "เปลี่ยนรหัสผ่าน",
  "title4332": "รหัสผ่านเก่า",
  "title4333": "ป้อนรหัสผ่านเก่า",
  "title4334": "การเปลี่ยนหมายเลขโทรศัพท์ต้องใช้การยืนยันหมายเลขโทรศัพท์เดิม และรหัสยืนยันผ่าน SMS จะถูกส่งไปยังโทรศัพท์ของคุณ",
  "title4335": "บนโทรศัพท์",
  "title4336": "结束时间：",
  "title4337": "请假类型：",
  "title4338": "原因：",
  "title4339": "参数，无法查看",
  "title4340": "请假信息",
  "title4341": "请假类型",
  "title4342": "请选择请假类型",
  "title4343": "申请时间",
  "title4344": "发起请假",
  "title4345": "申请编号",
  "title4346": "开始时间",
  "title4347": "取消请假",
  "title4348": "审批进度",
  "title4349": "选择开始时间",
  "title4350": "选择结束时间",
  "title4351": "提",
  "title4352": "交",
  "title4353": "开始时间不能为空",
  "title4354": "结束时间不能为空",
  "title4355": "请假类型不能为空",
  "title4356": "请假原因不能为空",
  "title4357": "发起成功",
  "title4358": "คลิกโฆษณา",
  "title4359": "ได้รับการสนับสนุนแล้ว",
  "title4360": "การวิเคราะห์ข้อมูลโฆษณาได้รับการสนับสนุนแล้ว คุณสามารถรับข้อมูลที่เกี่ยวข้องได้อย่างง่ายดายผ่านปลัักอิน กรุณาอ่านคู่มือผู้ใช้สำหรับคำแนะนำเฉพาะเจาะจง!",
  "title4361": "ตามแนวโน้ม",
  "title4362": "ตามค่า",
  "title4363": "คลิกอินทรีย์",
  "title4364": "การแสดงโฆษณา",
  "title4365": "ร้อยละยอดขายโฆษณา",
  "title4366": "อัตราการแปลงโฆษณา",
  "title4367": "เลือกได้สูงสุดสองตัวบ่งชี้",
  "title4368": "แก้ไขต้นทุนสินค้า",
  "title4369": "เพิ่มค่าใช้จ่ายใหม่",
  "title4370": "เพิ่มค่าใช้จ่ายประวัติ",
  "title4371": "เพิ่มค่าใช้จ่ายประวัติ",
  "title4372": "การแก้ไขต้นทุนสินค้าเป็นการอัปเดตต้นทุนปัจจุบันของสินค้าโดยไม่ส่งผลต่อคำสั่งในอดีต คำสั่งในอนาคตจะถูกคำนวณโดยใช้ต้นทุนล่าสุด",
  "title4373": "จำนวนเงินต้นทุน",
  "title4374": "หลังจากการปรับเปลี่ยนค่าทุนของสินค้าสำหรับวันในอดีตที่ระบุ เพื่อวันนั้น ระบบจะอัปเดตค่าทุนของสินค้าในรายการทั้งหมดในวันนั้นเป็นค่าใหม่และคำนวณข้อมูลรายงานที่เกี่ยวข้องใหม่",
  "title4375": "คำชี้แจงการตั้งค่าต้นทุน",
  "title4376": "วิธีการคำนวณต้นทุนสำหรับสินค้าเดียว",
  "title4377": "ต้นทุนของสินค้าเดี่ยว",
  "title4378": "สินค้าคงค้างในอดีต",
  "title4379": "ต้นทุนการซื้อต่อหน่วยในอดีต",
  "title4380": "ค่าซื้อรวมของหลายชุดในวันที่สอดคล้องกัน",
  "title4381": "จำนวนรวมของสินค้าที่ซื้อในหลายชุด",
  "title4382": "ตัวอย่างคำอธิบาย",
  "title4383": "สิ้นเดือนมียอดขายที่เหลือ",
  "title4384": "ชิ้นสินค้า, ราคาซื้อ",
  "title4385": "บาท",
  "title4386": "เติมสินค้าในเดือน",
  "title4387": "การคำนวณราคาซื้อสำหรับเดือนมิถุนายนดังนี้",
  "title4388": "ราคาจัดซื้อต่อหน่วย",
  "title4389": "หากยังเหลือสินค้า 10 ชิ้นหลังจากขายในเดือนมิถุนายน, โดยราคาซื้อเป็น 17 หยวน, ราคาซื้อสำหรับเดือนกรกฎาคมหากไม่ได้ใช้ฟังก์ชันการซื้อจะอ้างถึงราคาซื้อล่าสุดซึ่งเท่ากับ 17 หยวน",
  "title4390": "มีข้อขัดแย้งวันที่ในคลังเดียวกัน โปรดเลือกใหม่",
  "title4391": "มีข้อขัดแย้งวันที่ในคลังเดียวกัน โปรดเลือกใหม่",
  "title4392": "แก้ไขค่าใช้จ่ายที่กำหนดเอง",
  "title4393": "เพิ่มค่าใช้จ่ายที่กำหนดเอง",
  "title4394": "โปรดเลือกข้อมูลอย่างน้อยหนึ่งชุด",
  "title4395": "ประเภทการแก้ไข",
  "title4396": "การแก้ไขเนื้อหา",
  "title4397": "ถึง",
  "title4398": "ต้นทุนสินค้า:",
  "title4399": "บัญชีการดำเนินการ",
  "title4400": "บันทึกการปรับแก้ต้นทุนสินค้า",
  "title4401": "จำนวนต้นทุนเริ่มต้น",
  "title4402": "จำนวนการปรับแก้ต้นทุน",
  "title4403": "บันทึกการปรับราคาทุนในอดีต",
  "title4404": "ช่วงเวลาต้นทุน",
  "title4405": "จำนวนต้นทุนในอดีต",
  "title4406": "อัปโหลด",
  "title4407": "โปรดเตรียมข้อมูลสำ",
  "title4408": "มีข้อขัดแย้งวันที่ในคลังเดียวกัน โปรดเลือกใหม่",
  "title4409": "แก้ไขค่าใช้จ่ายที่กำหนดเอง",
  "title4410": "เพิ่มค่าใช้จ่ายที่กำหนดเอง",
  "title4411": "โปรดเลือกข้อมูลอย่างน้อยหนึ่งชุด",
  "title4412": "ประเภทการแก้ไข",
  "title4413": "การแก้ไขเนื้อหา",
  "title4414": "ถึง",
  "title4415": "ต้นทุนสินค้า:",
  "title4416": "บัญชีการดำเนินการ",
  "title4417": "บันทึกการปรับแก้ต้นทุนสินค้า",
  "title4418": "จำนวนต้นทุนเริ่มต้น",
  "title4419": "การคืนเงิน",
  "title4420": "จำนวนการปรับแก้ต้นทุน",
  "title4421": "บันทึกการปรับราคาทุนในอดีต",
  "title4422": "ช่วงเวลาต้นทุน",
  "title4423": "จำนวนต้นทุนในอดีต",
  "title4424": "อัปโหลด",
  "title4425": "โปรดเตรียมข้อมูลสำ",
  "title4426": "ภาพถูกบันทึกไว้",
  "title4427": "ชิ้น",
  "title4428": "เพิ่มความจุ",
  "title4429": "การใช้งานประสิทธิภาพ",
  "title4430": "ใช้แล้ว",
  "title4431": "ทั้งหมด",
  "title4432": "ได้ทำการเชื่อมโยงไปแล้ว",
  "title4433": "การวิเคราะห์ผลิตภัณฑ์",
  "title4434": "ต้นทุนรวมของสินค้าสำหรับคำสั่งซื้อที่ถูกต้อง",
  "title4435": "จำนวนคำสั่งซื้อสำหรับการส่งคืน",
  "title4436": "ค่าโฆษณาทั้งหมด",
  "title4437": "การจัดอันดับประสิทธิภาพของยอดขายที่ถูกต้อง",
  "title4438": "แนวโน้มการขาย",
  "title4439": "การแก้ไขความกระจ่าง",
  "title4440": "ช่วงยอดขายจาก",
  "title4441": "ไป",
  "title4442": "กำหนดเป็น",
  "title4443": "และจำนวนสินค้าคงคลังอยู่ใน",
  "title4444": "โปรดตั้งช่วงของการขาย",
  "title4445": "โปรดใส่สต็อกจากน้อยไปมาก",
  "title4446": "โปรดใส่ช่วงอย่างเรียงลำดับ",
  "title4447": "เลือกรูปภาพออนไลน์ (",
  "title4448": "โปรดใส่ที่อยู่รูปภาพออนไลน์ หนึ่งที่อยู่ต่อบรรทัด",
  "title4449": "ข้อมูลคู่แข่ง",
  "title4450": "ลิงก์คู่แข่ง",
  "title4451": "ที่อยู่ยืนยัน",
  "title4452": "ชื่อสินค้าคู่แข่ง",
  "title4453": "หมายเหตุสินค้า",
  "title4454": "การยืนยันล้มเหลว",
  "title4455": "แม่แบบนำเข้าตำแหน่งชั้น",
  "title4456": "เลือกรูปภาพในท้องถิ่น",
  "title4457": "เลือกรูปภาพออนไลน์",
  "title4458": "ไม่มีรูปภาพ",
  "title4459": "โปรดเลือกไฟล์รูปภาพในเครื่องหรือเลือกรูปภาพออนไลน์เพื่อเพิ่ม คุณสามารถอัปโหลดได้สูงสุด 8 รูปภาพ",
  "title4460": "รูปแบบของรูปภาพ",
  "title4461": "ขนาดรูปภาพต้องไม่เกิน",
  "title4462": "ขนาดรูปภาพต้องไม่น้อยกว่า",
  "title4463": "ข้อมูลคลังสินค้า",
  "title4464": "จำนวนสินค้าคงคลัง",
  "title4465": "ความถี่ในการสั่งซื้อสินค้า",
  "title4466": "ข้อมูลราคา",
  "title4467": "ราคาซื้อ",
  "title4468": "ข้อมูล Supplier",
  "title4469": "เลือกผู้จัดจำหน่าย",
  "title4470": "ลิงก์ผู้จัดจำหน่าย",
  "title4471": "ปัญหาหลังการขาย",
  "title4472": "หมายเลขคืนสินค้า",
  "title4473": "เหตุผลที่ยกเลิก",
  "title4474": "วิธีการจัดการ",
  "title4475": "การจัดการคืนสินค้า",
  "title4476": "กำลังดำเนินการคืนสินค้า",
  "title4477": "คำอธิบายการดำเนินการ",
  "title4478": "เวลาที่ซื้อ",
  "title4479": "จำนวนที่ซื้อ",
  "title4480": "เวลาสั่งซื้อ",
  "title4481": "เวลาการจัดส่ง",
  "title4482": "จำนวนการเคลื่อนไหวสต็อก",
  "title4483": "เหตุผลการเคลื่อนไหวสต็อก",
  "title4484": "บันทึกการแก้ไข",
  "title4485": "ขณะนี้ จำกัดการเลือก",
  "title4486": "ไฟล์, ตอนนี้เลือกแล้ว",
  "title4487": "ไฟล์ทั้งหมดที่เลือก",
  "title4488": "ไฟล์",
  "title4489": "เลือกแล้ว",
  "title4490": "เลือกตำแหน่งชั้น",
  "title4491": "เพิ่มในแผนซื้อ",
  "title4492": "หลังจากเพิ่มแผนซื้อโปรดไปที่ 'ซื้ อ -'",
  "title4493": "หน้าเพื่อดูแผนการซื้อ",
  "title4494": "จำนวนการพิมพ์",
  "title4495": "บาร์โค้ด",
  "title4496": "QR โค้ด",
  "title4497": "กระดาษป้าย",
  "title4498": "หนึ่งคอลัมน์",
  "title4499": "สองคอลัมน์",
  "title4500": "สามคอลัมน์",
  "title4501": "พิมพ์",
  "title4502": "พิมพ์",
  "title4503": "โปรดเลือกกระดาษป้าย",
  "title4504": "โปรดป้อนจำนวน",
  "title4505": "ไม่สามารถลบตัวสุดท้ายได้!",
  "title4506": "การตั้งค่ากลุ่ม",
  "title4507": "ป้อนคีย์เวิร์ดเพื่อกรอง",
  "title4508": "ลบกลุ่ม",
  "title4509": "ลบกลุ่มย่อยของ nóและปรับกลุ่มข้อมูลที่ใช้เป็นไม่ได้จัดกลุ่ม",
  "title4510": "ข้อมูลแนะนำการเติมจะคำนวณข้อมูลแนะนำตามระยะเวลาการเติมสินค้าโปรดกรอกตามการใช้จริง",
  "title4511": "เพิ่มยอดขายรายวัน",
  "title4512": "กฎการเติมสินค้า",
  "title4513": "ไม่รวมข้อมูลสินค้าหมดจากการขายในอดีต",
  "title4514": "กฎการขายรายวัน",
  "title4515": "ยอดขายแบบไดนามิก",
  "title4516": "ยอดขายคงที่",
  "title4517": "ยอดขายเปอร์เซ็นต์",
  "title4518": "ช่วงเวลา",
  "title4519": "ทั้งหมดเวลา",
  "title4520": "รณ์ยอดขายรายวัน",
  "title4521": "คำนวณ",
  "title4522": "โดยประมาณรายวัน",
  "title4523": "เวลาสั่งซื้อ",
  "title4524": "เวลาของผู้ซื้อ",
  "title4525": "เหตุผลในการคืนสินค้า",
  "title4526": " สถานะการคืนสินค้า ",
  "title4527": "ยังไม่เสร็จ",
  "title4528": "เสร็จสิ้น",
  "title4529": "ยังไม่ได้รับสินค้า",
  "title4530": "สินค้าขาดตอน",
  "title4531": "ได้รับสินค้าผิด",
  "title4532": "สินค้าเสีย",
  "title4533": "สินค้ามีข้อบกพร่อง",
  "title4534": "สินค้าหมดอายุ",
  "title4535": "สินค้าปลอม",
  "title4536": "สินค้าไม่ตรงกับคำอธิบาย",
  "title4537": "สินค้าไม่เหมาะสม",
  "title4538": "เปลี่ยนใจ",
  "title4539": "แพ็คเกจน่าสงสัย",
  "title4540": "บรรจุภัณฑ์เสีย",
  "title4541": "ขอคืนสินค้า",
  "title4542": "การสั่งซื้อที่ซ้ำกัน",
  "title4543": "การสั่งซื้อไม่ถูกต้อง",
  "title4544": "ไม่ต้องการอีก",
  "title4545": "ยกเลิกจากแพลตฟอร์ม",
  "title4546": "ค่าขนส่งสูง",
  "title4547": "ไม่มีวิธีการชำระเงินที่ต้องการ",
  "title4548": "เปลี่ยนวิธีการชำระเงิน",
  "title4549": "พบราคาที่ถูกกว่า",
  "title4550": "ราคาไม่ถูกต้อง",
  "title4551": "ผู้ซื้อไม่ชำระเงิน",
  "title4552": "ส่วนลดไม่เหมือนที่คาดหวัง",
  "title4553": "เปลี่ยนส่วนลด",
  "title4554": "ข้อมูลการจัดส่งไม่ถูกต้อง",
  "title4555": "ที่อยู่ของผู้ซื้อไม่สามารถจัดส่งได้",
  "title4556": "สินค้าไม่ถึงตามกำหนด",
  "title4557": "สินค้าไม่ได้ส่งตรงเวลา",
  "title4558": "สินค้ามีปัญหาเกี่ยวกับคุณภาพ",
  "title4559": "ขาดอุปกรณ์หรือของขวัญ",
  "title4560": "สินค้าไม่ตรงตามที่คาดหวัง",
  "title4561": "นี่คือเหตุผลการคืนที่พบบ่อย",
  "title4562": "จำนวนสินค้าที่ส่งคืน",
  "title4563": "จำนวนสัดส่วน",
  "title4564": "จำนวนสัดส่วนเหตุผลการส่งคืน",
  "title4565": "ต่อวัน",
  "title4566": "รอบการชำระเงิน",
  "title4567": "สถานะการชำระเงิน",
  "title4568": "บันทึกการชำระเงิน",
  "title4569": "จำนวนเงินที่โอน",
  "title4570": "วันที่โอน",
  "title4571": "สถานะการโอน",
  "title4572": "หมายเลขท้ายของธนาคาร",
  "title4573": "รหัสการโอนเงินของธนาคาร",
  "title4574": "จำนวนเงินที่ยังไม่ได้ชำระเงิน",
  "title4575": "จำนวนเงินที่ชำระแล้ว",
  "title4576": "จำนวนเงินที่โอนล้มเหลว",
  "title4577": "อัตราส่วนต้นทุน",
  "title4578": "เลือกภูมิภาคหลายแห่ง",
  "title4579": "การวิเคราะห์เปรียบเทียบ",
  "title4580": "ส่งออกคำสั่งซื้อ",
  "title4581": "หยวนจีน (CNY)",
  "title4582": "เงินตราท้องถิ่น",
  "title4583": "เพิ่มเทมเพลตที่กำหนดเอง",
  "title4584": "กำลังส่งออก กรุณารอสักครู่!",
  "title4585": "กำลังประมวลผลข้อมูลสำหรับการส่งออก โปรดอย่าปิดหน้าต่างปัจจุบัน จะดาวน์โหลดโดยอัตโนมัติเมื่อเสร็จสิ้น การปิดหน้าจะยกเลิกการดาวน์โหลด",
  "title4586": "เลือกเทมเพลต",
  "title4587": "เทมเพลตมาตรฐาน",
  "title4588": "เลือกแท็ก",
  "title4589": "คำสั่งซื้อ เพียงแก้ร้านที่เลือกในคำสั่งซื้อ",
  "title4590": "ความสัมพันธ์ตรงกัน",
  "title4591": "สินค้าที่ตรงกัน",
  "title4592": "เลือกหลายแพลตฟอร์ม",
  "title4593": "หลัก",
  "title4594": "แยก",
  "title4595": "สถานะคำสั่งซื้อ",
  "title4596": "ข้อมูลพื้นฐาน",
  "title4597": "จำนวนสินค้า",
  "title4598": "ข้อกำหนด",
  "title4599": "ชื่อเล่น",
  "title4600": "ที่อยู่ 1",
  "title4601": "โทรศัพท์มือถือ",
  "title4602": "เมือง",
  "title4603": "ที่อยู่ 2",
  "title4604": "เบอร์โทร",
  "title4605": "หมายเลขบ้าน",
  "title4606": "ที่อยู่ 3",
  "title4607": "ข้อมูลโลจิสติกส์",
  "title4608": "โลจิสติกส์ที่ผู้ซื้อกำหนด",
  "title4609": "วิธีการเก็บสินค้า",
  "title4610": "หมายเหตุของฉัน",
  "title4611": "ข้อมูลการเงิน",
  "title4612": "สกุลเงินปัจจุบัน",
  "title4613": "แลกเปลี่ยน",
  "title4614": "อัตราแลกเปลี่ยนคงที่",
  "title4615": "การคำนวณทดลอง",
  "title4616": "รายได้โดยประมาณ",
  "title4617": "ค่าใช้จ่ายโดยประมาณ",
  "title4618": "ค่าใช้จ่ายในการขนส่ง (การทดลอง)",
  "title4619": "ค่าใช้จ่ายรวม",
  "title4621": "กำไรรวม",
  "title4622": "รายได้",
  "title4623": "ไม่มีพัสดุใบสั่งซื้อ",
  "title4624": "พัสดุ",
  "title4625": "ไม่มีข้อมูลพัสดุ",
  "title4626": "ไม่มีข้อมูล",
  "title4627": "แยกคำสั่ง",
  "title4628": "ยกเลิกการแยกคำสั่ง",
  "title4629": "กล่อง",
  "title4630": "สินค้า",
  "title4631": "คลังสินค้า",
  "title4632": "แยกกล่อง",
  "title4633": "กล่องหลัก",
  "title4634": "ย้ายไปยัง",
  "title4635": "เลขพัสดุ",
  "title4636": "ราคาต่อหน่วยสินค้า",
  "title4637": "เลือกอย่างน้อยหนึ่งรายการ",
  "title4638": "กล่องหลักต้องมีสินค้าอย่างน้อยหนึ่งรายการ",
  "title4639": "ชื่อแบรนด์",
  "title4640": "โปรดใส่ชื่อแบรนด์",
  "title4641": "หมายเลขแบรนด์",
  "title4642": "ภาพแบรนด์",
  "title4643": "ภาพหมวด",
  "title4644": "การจัดเรียงแบรนด์",
  "title4645": "คำอธิบายแบรนด์",
  "title4646": "โปรดป้อนการเรียงลำดับแบรนด์",
  "title4647": "ชื่อแบรนด์ต้องไม่ว่างเปล่า",
  "title4648": "ภาพแบรนด์ต้องไม่ว่างเปล่า",
  "title4649": "การจัดเรียงแบรนด์ต้องไม่ว่างเปล่า",
  "title4650": "เพิ่มแบรนด์",
  "title4651": "แก้ไขแบรนด์",
  "title4652": "คุณแน่ใจหรือไม่ว่าต้องการลบหมายเลขแบรนด์นี้",
  "title4653": "ภาพขนาดย่อ",
  "title4654": "ภาพขนาดย่อ",
  "title4655": "ลิงก์เปลี่ยนทิศทาง",
  "title4656": "โปรดป้อนลิงก์เปลี่ยนทิศทาง",
  "title4657": "โปรดป้อนการเรียงลำดับ",
  "title4658": "หัวเรื่องต้องไม่ว่างเปล่า",
  "title4659": "ที่อยู่รูปภาพต้องไม่ว่างเปล่า",
  "title4660": "ที่อยู่เปลี่ยนทิศทางต้องไม่ว่างเปล่า",
  "title4661": "การจัดเรียงต้องไม่ว่างเปล่า",
  "title4662": "การจัดเรียงหมวดหมู่",
  "title4663": "หมวดหมู่หลัก",
  "title4664": "แนะนำ",
  "title4665": "ความละเอียดของรูปภาพ",
  "title4666": "คำอธิบายหมวดหมู่",
  "title4667": "โปรดป้อนคำอธิบายหมวดหมู่",
  "title4668": "โปรดเลือกหมวดหมู่หลัก",
  "title4669": "ชื่อหมวดหมู่ต้องไม่ว่างเปล่า",
  "title4670": "ภาพหมวดหมู่ต้องไม่ว่างเปล่า",
  "title4671": "การจัดเรียงหมวดหมู่ต้องไม่ว่างเปล่า",
  "title4672": "หมวดหมู่ระดับบน",
  "title4673": "เพิ่มหมวดหมู่สินค้า",
  "title4674": "แก้ไขหมวดหมู่สินค้า",
  "title4675": "คุณแน่ใจหรือไม่ว่าต้องการลบหมายเลขหมวดหมู่สินค้านี้",
  "title4676": "ชื่อข้อกำหนด",
  "title4677": "โปรดป้อนชื่อข้อกำหนด",
  "title4678": "ค่าคุณสมบัติ",
  "title4679": "ค่าคุณสมบัติต้องไม่ว่างเปล่า",
  "title4680": "เพิ่มข้อกำหนด",
  "title4681": "แก้ไขข้อกำหนด",
  "title4682": "คุณแน่ใจหรือไม่ว่าต้องการลบชื่อข้อกำหนดนี้",
  "title4683": "คุณแน่ใจหรือไม่ว่าต้องการส่งออกชื่อข้อกำหนดทั้งหมดทุกรายการหรือไม่",
  "title4684": "รหัสสินค้า商品编码；ชื่อสินค้า商品名称；",
  "title4685": "ยอดขายสินค้า、ยอดขายสินค้า",
  "title4686": "กรุณาเลือกสถานะการวางขาย",
  "title4687": "อินเตอร์เฟซอ้างอิง;",
  "title4688": "ฟิลด์: ข้อมูลผลิตภัณฑ์, ราคา, สินค้าคงคลัง, ยอดขาย, เรียงลำดับ, เวลาสร้าง, สถานะ, การดำเนินการ;",
  "title4689": "ทั้งหมด, ในการขาย, ในคลังสินค้า, ในการเตือน",
  "title4690": "ที่อยู่รูปภาพหลักของสินค้า",
  "title4691": "ฟิลด์เรียงลำดับ",
  "title4692": "คลิก",
  "title4693": "ช่วงราคา",
  "title4694": "แก้ไขสินค้า",
  "title4695": "การตั้งค่าพื้นฐาน",
  "title4696": "จุดขายสินค้า",
  "title4697": "โปรดป้อนจุดขายสินค้า",
  "title4698": "รูปภาพหลักของสินค้า",
  "title4699": "ยี่ห้อสินค้า",
  "title4700": "โปรดเลือกแบรนด์สินค้า",
  "title4701": "หมวดหมู่ SKU Merchant",
  "title4702": "ลงขายทันที",
  "title4703": "ใส่ในคลัง",
  "title4704": "ราคาและสต็อก",
  "title4705": "เปิดใช้งานคุณลักษณะหลายรูปแบบ",
  "title4706": "ตัวแปรเดียว",
  "title4707": "ตัวแปรหลายรูปแบบ",
  "title4708": "ตัวแปร:",
  "title4709": "เพิ่มรายการตัวแปร",
  "title4710": "รายละเอียดตัวแปร",
  "title4711": "รูปภาพตัวแปร",
  "title4712": "ราคาตลาด",
  "title4713": "ราคาขาย",
  "title4714": "ราคาทุน",
  "title4715": "สต็อกคำเตือน",
  "title4716": "บาร์โค้ด",
  "title4717": "ปิดการใช้งาน",
  "title4718": "ยอดขายเสมือน",
  "title4719": "โปรดป้อนยอดขายเสมือน",
  "title4720": "รายละเอียดสินค้า",
  "title4721": "การตั้งค่าขั้นสูง",
  "title4722": "โปรดป้อนฟิลด์การเรียงลำดับ",
  "title4723": "แสดงสต็อก",
  "title4724": "ยืนยัน",
  "title4725": "สถานะสินค้าต้องไม่ว่างเปล่า",
  "title4726": "ที่อยู่รูปภาพ carousel สินค้าต้องไม่ว่างเปล่า",
  "title4727": "ยอดขายเสมือนต้องไม่ว่างเปล่า",
  "title4728": "渠道费率",
  "title4729": "请输入渠道费率",
  "title4730": "开放平台",
  "title4731": "请输入开放平台",
  "title4732": "渠道状态",
  "title4733": "网关地址",
  "title4734": "算法类型",
  "title4735": "公钥类型",
  "title4736": "商户私钥",
  "title4737": "请输入商户私钥",
  "title4738": "支付宝公钥字符串",
  "title4739": "请输入支付宝公钥字符串",
  "title4740": "商户公钥应用证书",
  "title4741": "请上传商户公钥应用证书",
  "title4742": "支付宝公钥证书",
  "title4743": "请上传支付宝公钥证书",
  "title4744": "根证书",
  "title4745": "请上传根证书",
  "title4746": "请输入开放平台上创建的应用的",
  "title4747": "渠道状态不能为空",
  "title4748": "请传入网关地址",
  "title4749": "请传入签名算法类型",
  "title4750": "公钥类型不能为空",
  "title4751": "请上传指定根证书",
  "title4752": "编辑支付渠道",
  "title4753": "创建支付渠道",
  "title4754": "请上传指定格式",
  "title4755": "文件大小超过",
  "title4756": "公众号",
  "title4757": "请输入公众号",
  "title4758": "商户密钥",
  "title4759": "请输入商户密钥",
  "title4760": "证书",
  "title4761": "请上传",
  "title4762": "请传入商户号",
  "title4763": "版本不能为空",
  "title4764": "密钥值",
  "title4765": "โปรดป้อนอัตราแลกเปลี่ยนการดำเนินงาน",
  "title4766": "การเปลี่ยนแปลงของอัตราแลกเปลี่ยนการดำเนินงานที่คุณตั้งค่ามาก โปรดตรวจสอบและส่งใหม่",
  "title4767": "สัญลักษณ์สกุลเงิน:",
  "title4768": "ชื่อสกุลเงิน:",
  "title4769": "รายละเอียดการดำเนินการ",
  "title4770": "แม่แบบที่กำหนดเอง",
  "title4771": "กรุณาเลือกอย่างน้อยหนึ่งพารามิเตอร์",
  "title4772": "การตั้งค่าอัตราแลกเปลี่ยนการชำระเงินมีผลต่อคำสั่ง 'การชำระเงินที่ ได้ รั บ' เท่านั้นและมีผลต่อรายงานทางการเงินและการคำนวณกำไร",
  "title4773": "หลังจากปรับแก้อัตราแลกเปลี่ยนแล้ว คาดว่าจะเริ่มใช้ใน 10 ถึง 15 นาที โปรดรออย่างอดทน",
  "title4774": "เลือกเดือน",
  "title4775": "อัตราแลกเปลี่ยนการชำระเงิน",
  "title4776": "กำลังอัปเดต...",
  "title4777": "แก้ไขอัตราแลกเปลี่ยน",
  "title4778": "อัตราแลกเปลี่ยนสดๆระหว่างประเทศ",
  "title4779": "โปรดป้อนอัตราแลกเปลี่ยนการชำระเงิน",
  "title4780": "ความผันผวนของอัตราแลกเปลี่ยนการชำระเงินที่คุณตั้งค่ามีความสำคัญ โปรดตรวจสอบให้แน่ใจก่อนส่ง",
  "title4781": "แก้ไขอัตราแลกเปลี่ยน",
  "title4782": "ส่วนลดอัตราแลกเปลี่ยน",
  "title4783": "เคล็ดลับ:",
  "title4784": "วิธีใช้อัตราแลกเปลี่ยนส่วนลด",
  "title4785": "เมื่อเลือกส่วนลดอัตราแลกเปลี่ยน อัตราแลกเปลี่ยนจะถูกดำเนินการตามส่วนลด จริงๆ ถูกใช้ที่อัตราแลกเปลี่ยนถูกคำนวณสำหรับคำสั่งซื้อและข้อมูลอื่นๆ",
  "title4786": "ส่วนลดอัตราแลกเปลี่ยนคำนวณอย่างไร？",
  "title4787": "ส่วนลดอัตราแลกเปลี่ยน通常คำนวณโดยการหารอัตราแลกเปลี่ยนการชำระเงินด้วยอัตราแลกเปลี่ยนทางการ",
  "title4788": "ตัวอย่างเช่น หากอัตราแลกเปลี่ยนทางการคือ 7 และอัตราแลกเปลี่ยนการชำระเงินคือ 7,",
  "title4789": "และอัตราการชำระเงินคือ 7",
  "title4790": "แล้วอัตราส่วนส่วนลดอัตราแลกเปลี่ยน",
  "title4791": "ถูกปัดเป็นทศนิยมที่สี่ตำแหน่งหลังจุดทศนิยมซึ่งเท่ากับ 99",
  "title4792": "ป้อน 99 ในกล่องข้อมูล",
  "title4793": "เรียบร้อยแล้ว",
  "title4794": "โปรดป้อนอัตราแลกเปลี่ยนคงที่",
  "title4795": "ความผันผวนของอัตราแลกเปลี่ยนที่คุณตั้งค่ามีความสำคัญ โปรดตรวจสอบให้แน่ใจก่อนส่ง",
  "title4796": "โปรดป้อนสัดส่วน",
  "title4797": "แก้ไขอัตราแลกเปลี่ยนการดำเนินการ",
  "title4798": "การแก้ไขอัตราแลกเปลี่ยนการดำเนินการประวัติศาสตร์คาดว่าจะเริ่มมีผลใน 10 ถึง 15 นาที โปรดอดทนรอ",
  "title4799": "ส่วนลดอัตราแลกเปลี่ยน:",
  "title4800": "โปรดเลือกวันที่",
  "title4801": "ไม่สามารถเลือกวันนี้ได้",
  "title4802": "客户端编号",
  "title4803": "客户端密钥",
  "title4804": "应用图标",
  "title4805": "访问令牌的有效期",
  "title4806": "刷新令牌的有效期",
  "title4807": "授权类型",
  "title4808": "请输入客户端编号",
  "title4809": "请输入客户端密钥",
  "title4810": "应用描述",
  "title4811": "单位：秒",
  "title4812": "请输入授权类型",
  "title4813": "授权范围",
  "title4814": "请输入授权范围",
  "title4815": "自动授权范围",
  "title4816": "可重定向的",
  "title4817": "请输入可重定向的",
  "title4818": "权限",
  "title4819": "请输入权限",
  "title4820": "资源",
  "title4821": "请输入资源",
  "title4822": "附加信息",
  "title4823": "请输入附加信息，",
  "title4824": "格式数据",
  "title4825": "客户端编号不能为空",
  "title4826": "客户端密钥不能为空",
  "title4827": "应用图标不能为空",
  "title4828": "访问令牌的有效期不能为空",
  "title4829": "刷新令牌的有效期不能为空",
  "title4830": "授权类型不能为空",
  "title4831": "客户端",
  "title4832": "是否确认删除客户端编号为",
  "title4833": "访问令牌",
  "title4834": "刷新令牌",
  "title4835": "强退",
  "title4836": "是否确认强退令牌为",
  "title4837": "强退成功",
  "title4838": "โปรดใส่รหัสผ่านเก่า",
  "title4839": "รหัสผ่านใหม่",
  "title4840": "กรุณาใส่รหัสผ่านใหม่",
  "title4841": "โปรดยืนยันรหัสผ่าน",
  "title4842": "รหัสผ่านสองครั้งที่ป้อนไม่สอดคล้องกัน",
  "title4843": "รหัสผ่านเดิมต้องไม่ว่างเปล่า",
  "title4844": "รหัสผ่านใหม่ต้องไม่ว่างเปล่า",
  "title4845": "ความยาวใน",
  "title4846": "กรุณาใส่รหัสผ่าน",
  "title4847": "ลายนิ้วมือ",
  "title4848": "เว็บไซต์โซเชียล",
  "title4849": "ถูกผูกไว้",
  "title4850": "ยกเลิกการผูก",
  "title4851": "ไม่ถูกผูก",
  "title4852": "ผูก",
  "title4853": "ผูกสำเร็จ",
  "title4854": "ยกเลิกการผูกเรียบร้อย",
  "title4855": "การแก้ไขข้อมูล",
  "title4856": "รูปโปรไฟล์",
  "title4857": "เปลี่ยนรูปโปรไฟล์",
  "title4858": "ชาย",
  "title4859": "หญิง",
  "title4860": "การตรวจสอบโทรศัพท์",
  "title4861": "โทรศัพท์ของคุณที่ผูกไว้",
  "title4862": "รหัสยืนยัน",
  "title4863": "คลิกเพื่ออัปโหลดรูปโปรไฟล์",
  "title4864": "แก้ไขรูปโปรไฟล์",
  "title4865": "ข้อผิดพลาดในรูปแบบไฟล์ โปรดอัปโหลดประเภทรูปภาพ",
  "title4866": "ไฟล์ที่มีนามสกุล",
  "title4867": "easyboss",
  "title4868": "การเก็บข้อมูลกำลังดำเนิน โปรดอย่าปิดหน้าต่างปัจจุบัน หากปิดหน้าต่าง การดำเนินการเก็บสินค้าจะถูกยกเลิก",
  "title4869": "สินค้ากำลังถูกเก็บ โปรดรอ",
  "title4870": "การเก็บสำเร็จ โปรดตรวจสอบผลลัพธ์ในรายการเก็บ",
  "title4871": "คำอธิบายยาว",
  "title4872": "คำอธิบายสั้น",
  "title4873": "ตามคำจำกัดของแต่ละแพลตฟอร์มในจำนวนรูปภาพผลิตภัณฑ์ จะเลือกรูปที่มีลำดับสูงกว่าเป็นรูปภาพสินค้า",
  "title4874": "อัปโหลดในเครื่อง",
  "title4875": "ที่อยู่ออนไลน์",
  "title4876": "สีแดง",
  "title4877": "สีเหลือง",
  "title4878": "สีฟ้า",
  "title4879": "หน้าปัจจุบัน",
  "title4880": "ตัวชี้วัดหลัก",
  "title4881": "สถานการณ์คลังสินค้า",
  "title4882": "แนวโน้มต้นทุนและยอดขาย",
  "title4883": "สถานการณ์การขาย",
  "title4884": "รายละเอียดค่าใช้จ่าย",
  "title4885": "สถานการณ์การประเมิน",
  "title4886": "การกระจายข้อมูล",
  "title4887": "รายละเอียดค่าใช้จ่ายที่กำหนดเอง",
  "title4888": "ไม่มีค่าใช้จ่าย",
  "title4889": "เป็นของพื้นที่นี้",
  "title4890": "เป็นของร้านทั้งหมด",
  "title4891": "เรียงลำดับตัวชี้วัด",
  "title4892": "ลากตัวชี้วัดเพื่อจัดเรียง",
  "title4893": "กำไรทั้งหมด, ไม่รวมคำสั่งที่ยกเลิก, ประเมิน, และหยุดชั่วคราว",
  "title4894": "ยอดรวมที่สั่งซื้อโดยผู้ซื้อ, รวมถึงการสนับสนุนจากแพลตฟอร์ม",
  "title4895": "ยอดขายทั้งหมดภายใต้คำสั่งที่ถูกต้อง, ไม่รวมคำสั่งที่ถูกยกเลิก, ได้รับการประเมิน, และหยุดชั่วคราว, รวมถึงการสนับสนุนจากแพลตฟอร์ม",
  "title4896": "จำนวนรวมของคำสั่งที่ผู้ซื้อได้สั่งซื้อ",
  "title4897": "คำสั่งซื้อทั้งหมด รวมถึงคำสั่งซื้อที่ยกเลิกและคำสั่งซื้อที่คืนเงิน",
  "title4898": "ดูตามแผนภูมิ",
  "title4899": "ดูตามตาราง",
  "title4900": "แสดงข้อมูลได้สูงสุด 15 รายการ",
  "title4901": "ค่าใช้จ่ายทั้งหมด",
  "title4902": "แผนภูมิผสม",
  "title4903": "แผนภูมิเปรียบเทียบ",
  "title4904": "ความคิดเห็นที่ดี",
  "title4905": "ความคิดเห็นเฉยๆ/ลบ",
  "title4906": "ความคิดเห็นกำลังถูกเก็บรวบรวม โปรดกลับมาตรวจสอบในภายหลัง",
  "title4907": "วิเคราะห์ความคิดเห็น",
  "title4908": "วันที่ความคิดเห็น",
  "title4909": "การเปรียบเทียบต้นทุนกับยอดขาย",
  "title4910": "แผนภูมิแนวโน้ม",
  "title4911": "ยอดขายเฉลี่ยต่อวัน",
  "title4912": "ยอดขายรวมต่อวัน",
  "title4913": "วันขายโดยประมาณ",
  "title4914": "สินค้าในการขนส่ง",
  "title4915": "อายุสินค้า",
  "title4916": "มูลค่าสินค้าคงคลังทั้งหมด",
  "title4917": "สินค้าคงคลังทั้งหมดที่ใช้ได้",
  "title4918": "สินค้าคงคลังทั้งหมดที่ถูกล็อก",
  "title4919": "ทั้งหมดอยู่ในการขนส่ง",
  "title4920": "วันขายเฉลี่ย",
  "title4921": "ที่",
  "title4922": "จำนวนวันสูงสุดของกลุ่มต้องไม่ว่าง",
  "title4923": "ค่าต้องมากกว่ากลุ่มก่อนหน้า",
  "title4924": "การคืนเงิน",
  "title4925": "การชำระเงินผ่าน WeChat",
  "title4926": "ข้อมูลร้านค้า",
  "title4927": "ยอดเงินที่คาดหวังที่จะได้รับ",
  "title4928": "ค่าธรรมเนียมการจัดการที่คาดหวัง",
  "title5000": "การจับคู่",
  "title5001": "ต้องมีตัวอักษรตัวใหญ่",
  "title5002": "ตัวอักษร เลข และอักขระต้องมีอย่างน้อย 2 ประเภท",
  "title5003": "รหัสผ่านต้องไม่เหมือนกับชื่อผู้ใช้",
  "title5004": "ออก",
  "title5005": "ยินดีด้วยที่ลงทะเบียนเรียบร้อย! เพื่อให้ได้รับประสบการณ์ที่ดีขึ้น โปรดกรอกข้อมูลต่อไปนี้",
  "title5006": "โปรดเลือกพื้นที่การขายและยืนยันสกุลเงินหลัก หลังจากที่พื้นที่การขายและสกุลเงินหลักของบัญชีได้รับการยืนยันแล้ว พวกเขาจะไม่สามารถเปลี่ยนแปลงได้อีก โปรดตรวจสอบอย่างรอบคอบก่อนส่ง",
  "title5007": "เลือกพื้นที่การขาย",
  "title5008": "สกุลเงินบัญชี",
  "title5010": "แชทกับเรา",
  "title5011": "เพิ่มบริการลูกค้าที่พิเศษ และแก้ปัญหาที่เกิดขึ้นทันที",
  "title5012": "ให้",
  "title5013": "ต่อ",
  "title5014": "การสาธิตการดำเนินการสินค้า",
  "title5015": "ให้คุณและทีมของคุณเข้าใจและใช้ได้เร็ว",
  "title5016": "สแกนโค้ดเพื่อรับบริการมืออาชีพ",
  "title5017": "โปรดเลือกพื้นที่ขาย",
  "title5018": "Đài Loan",
  "title5019": "Indonesia",
  "title5020": "Malaysia",
  "title5021": "Philippines",
  "title5022": "Singapore",
  "title5023": "Thái Lan",
  "title5024": "Việt Nam",
  "title5025": "คุณแน่ใจหรือไม่ว่าต้องการออกและออกจากระบบหรือไม่？",
  "title5026": "คำแนะนำ",
  "title5027": "สคริปต์",
  "title5028": "คัดลอกคำอธิบายที่สอดคล้อง",
  "title5029": "ต้องการ",
  "title5030": "ค่าเริ่มต้น",
  "title5031": "แม่แบบ",
  "title5032": "หมายเลขของแม่แบบคือ",
  "title5033": "รายการข้อมูลของแม่แบบ",
  "title5034": "หมวดหมู่ของแม่แบบ",
  "title5035": "หมายเลขหมวดหมู่ของแม่แบบคือ",
  "title5036": "รายการข้อมูลของหมวดหมู่แม่แบบ",
  "title5037": "วัน",
  "title5038": "ชิ้น",
  "title5039": "รวม",
  "title5040": "จำนวนครั้งของการวิเคราะห์ความคิดเห็น",
  "title5041": "คะแนน",
  "title5042": "ระยะเวลาที่ถูกต้อง",
  "title5043": "โปรดทำการชำระเงินในหน้าป๊อปอัพใหม่ และคลิกที่ปุ่มด้านล่างหลังจากการชำระเงิน",
  "title5044": "ข้อแนะนำที่เป็นมิตร",
  "title5045": "การชำระเงินเสร็จสิ้น",
  "title5046": "ขอแสดงความยินดีกับการซื้อแพ็คเกจสำเร็จ",
  "title5047": "การซื้อแพ็คเกจล้มเหลว การชำระเงินไม่สำเร็จ",
  "title5048": "ค้นหาผู้ใช้",
  "title5049": "เพื่อดำเนินการต่อ กรุณาทำตามขั้นตอนดังต่อไปนี้:",
  "title5050": "พารามิเตอร์ ไม่สามารถดูข้อมูลกระบวนการได้",
  "title5051": "ต้องไม่ว่าง",
  "title5052": "ผู้เยี่ยมชมอินเตอร์เน็ตธรรมชาติ",
  "title5053": "ยอดขายอินเตอร์เน็ตธรรมชาติ",
  "title5054": "รายได้จากการขายอินเตอร์เน็ตธรรมชาติ",
  "title5055": "รายการข้อมูล",
  "title5056": "สินค้า",
  "title5057": "สินค้า",
  "title5058": "ชั่วโมง",
  "title5059": "ราคา",
  "title5060": "โปรดใส่ชื่อสินค้า",
  "title5061": "ชื่อสินค้า",
  "title5062": "สูง，",
  "title5063": "วันต้องไม่ว่างเปล่า",
  "title5064": "กำไรในวันสินค้า",
  "title5065": "หมายเลขกำไรสินค้าในวัน",
  "title5066": "รายการข้อมูลกำไรสินค้าในวัน",
  "title5067": "การลบที่ไม่สามารถกู้คืนได้จะลบสินค้าคงคลังและความสัมพันธ์การจับคู่ที่เกี่ยวข้อง",
  "title5068": "หมายเลขเป็น",
  "title5069": "กล่อง: พื้นที่ส่วนตัวที่ได้รับการแต่งตั้งเฉพาะสำหรับแมว ทำให้พวกเขามีชีวิตที่สุขภาพดีกว่าและมีความสุขมากขึ้น!",
  "title5070": "วิธีการใช้?",
  "title5071": "ข้อมูลช่วยเหลือความสัมพันธ์ที่ตรงกัน",
  "title5072": "ซิงโครไนส์ผลิตภัณฑ์ออนไลน์",
  "title5073": "อัปเดตสินค้าจากร้านที่ได้รับการอนุญาตไปยังท้องถิ่น",
  "title5074": "อัปเดตไปที่ท้องถิ่น",
  "title5075": "สร้างการจับคู่อย่างรวดเร็ว",
  "title5076": "ลบกระบวนการสร้างผลิตภัณฑ์ในท้องถิ่นด้วยการใช้มือออก",
  "title5077": "กระบวนการที่ยุ่งยาก",
  "title5078": "สร้างสินค้า",
  "title5079": "ซิงค์ผลิตภัณฑ์อย่างรวดเร็วหลังจากการซิงโครไนส์",
  "title5080": "และสร้างความสัมพันธ์การจับคู่โดยอัตโนมัติ",
  "title5081": "รวมสินค้า",
  "title5082": "เลือกผลิตภัณฑ์ที่ต้องการรวม",
  "title5083": "รวมเป็นหนึ่งสินค้าท้องถิ่นเดียว",
  "title5084": "การจับคู่อัตโนมัติ",
  "title5085": "ประสบความสำเร็จในการออนไลน์และออฟไลน์ผ่านกฎการรู้จำอัตโนมัติ",
  "title5086": "ความสัมพันธ์การจับคู่อัตโนมัติ",
  "title5087": "ยกเลิกการจับคู่",
  "title5088": "ยกเลิกสินค้า",
  "title5089": "กับสินค้าท้องถิ่น",
  "title5090": "ความสัมพันธ์การจับคู่",
  "title5091": "คำเตือนเพื่อให้เป็นมิตร:",
  "title5092": "ในปัจจุบันรองรับเฉพาะการสร้างเดียว",
  "title5093": "ฉันเข้าใจ",
  "title5094": "สินค้าต้องไม่ว่างเปล่า",
  "title5095": "เพิ่มตำแหน่ง",
  "title5096": "เพิ่มเป็นเดี่ยว",
  "title5097": "เพิ่มเป็นชุด",
  "title5098": "นำเข้า & สร้างตำแหน่ง",
  "title5099": "พิมพ์ฉลากชั้น",
  "title5100": "ขนาดชั้น",
  "title5101": "สูงสุด 100 คอลัมน์",
  "title5102": "จำนวนคอลัมน์",
  "title5103": "สูงสุด 100 ชั้น",
  "title5104": "ชั้น",
  "title5105": "เครื่องหมายวรรคตอน (-)",
  "title5106": "ดูตัวอย่าง",
  "title5107": "ด้านล่างของชั้น",
  "title5108": "โปรดใส่จำนวน",
  "title5109": "โปรดใส่จำนวนเต็มบวกระหว่าง 1-100",
  "title5110": "ทั้งหมด ",
  "title5111": "ว่าง",
  "title5112": "ว่างบางส่วน",
  "title5113": "เต็ม",
  "title5114": "ล้างได้",
  "title5115": "โปรดเลือกรายการ",
  "title5116": "?",
  "title5117": "ล้มเหลว",
  "title5118": "ยอดขายเฉลี่ยต่อวัน",
  "title5119": "วันขนส่งทางเรือ",
  "title5120": "วันเตรียมพร้อมสำรองเพิ่มเติม",
  "title5121": "ยอดขายเฉลี่ยรายวันใน 15 วันที่ผ่านมา",
  "title5122": "การจัดส่งอยู่ระหว่างทาง",
  "title5123": "สแกนเพื่อ",
  "title5124": "พิมพ์ฉลาก",
  "title5125": "วันขนส่ง",
  "title5126": "จำนวนสินค้าหมด",
  "title5127": "กำไรร้านต่อวัน",
  "title5128": "หมายเลขกำไรร้านประจำวันคือ",
  "title5129": "รายการกำไรร้านประจำวัน",
  "title5130": "สแกนการดำเนินงาน",
  "title5131": "สแกนและจัดส่ง",
  "title5132": "จำนวนสินค้า",
  "title5135": "ใบปะหน้าพัสดุ",
  "title5136": "Pick List",
  "title5137": "รัฐ",
  "title5138": "อำเภอ",
  "title5139": "จำนวนที่ประสบความสำเร็จใหม่",
  "title5140": "จำนวนล้มเหลวใหม่",
  "title5141": "错误日志数据项",
  "title5142": "访问日志数据项",
  "title5143": "匹配的",
  "title5144": "的对象内容",
  "title5145": "资源路径",
  "title5146": "重复的问题。",
  "title5147": "模板过于复杂，不利于胖友二次开发",
  "title5148": "折扣",
  "title5149": "随机",
  "title5150": "为",
  "title5151": "时需要添加",
  "title5152": "时可选择性添加",
  "title5153": "领取之日起或者次日",
  "title5154": "天内有效",
  "title5155": "优惠券仅原价购买商品时可用",
  "title5156": "满减",
  "title5157": "随机不能为空",
  "title5158": "有门槛不能为空",
  "title5159": "代表无限制不能为空",
  "title5160": "当",
  "title5161": "时需要添加不能为空",
  "title5162": "时可选择性添加不能为空",
  "title5163": "领取之日固定日期后过期",
  "title5164": "领取次日固定日期后过期不能为空",
  "title5165": "为2或者3时需要添加",
  "title5166": "天内有效不能为空",
  "title5167": "是不能为空",
  "title5168": "开启不能为空",
  "title5169": "天提醒不能为空",
  "title5170": "不限制",
  "title5171": "优惠券仅原价购买商品时可用不能为空",
  "title5172": "天！",
  "title5173": "开头",
  "title5174": "的账号不能为空",
  "title5175": "的模板编号不能为空",
  "title5176": "赋予专业身份",
  "title5177": "เช่น: คุณเป็นผู้เชี่ยวชาญทางการตลาดระดับสูง",
  "title5178": "ต้องการรูปแบบอะไรและเนื้อหาเอาออก",
  "title5179": "ต้นทุนการประเมิน",
  "title5180": "ข้อมูลช่วยเหลือเกี่ยวกับรายงานกำไรรายเดือน",
  "title5181": "ตรวจสอบคำสั่งซื้อ:",
  "title5182": "ตรวจสอบว่าคำสั่งซื้อตรงกันหรือ chi phí được duy trì, trong",
  "title5183": "การวิเคราะห์กำไร - รายละเอียดกำไรในคำสั่งซื้อ】",
  "title5184": "การบำรุงรักษาคำสั่งที่คัดเลือกสำหรับการไม่มีต้นทุนสินค้า】",
  "title5185": "ทำเครื่องหมายการประเมินและถือคำสั่ง",
  "title5186": "ในการจัดการคำสั่ง, ทำเครื่องหมายในรายการที่ต้องการประเมินหรือถือตามนั้น",
  "title5187": "การจัดการคำสั่ง - การประมวลผลคำสั่ง】",
  "title5188": "ตั้งค่าอัตราแลกเปลี่ยนการชำระเงิน",
  "title5189": "ปรับตามการตั้งค่าอัตราแลกเปลี่ยนล่าสุดเพื่อให้มั่นใจในการคำนวณจำนวนการชำระเงินที่ถูกต้อง",
  "title5190": "การวิเคราะห์กำไร - รายง",
  "title5191": "ข้อผิดพลาดในการตั้งค่าอัตราแลกเปลี่ยนการประเมิน",
  "title5192": "เมื่อตั้งค่าอัตราแลกเปลี่ยนการชำระเงินการประเมินไม่ถูกต้อง",
  "title5193": "การตั้งค่า - พารามิเตอร์การประเมิน",
  "title5194": "ทำการเปลี่ยนแปลงด้านในหลังจากการปรับปรุงเสร็จสมบูรณ์ใน",
  "title5195": "ค้นหาคำสั่งที่เกี่ยวข้องและเลือก 【การดำเนินการเป็นชุด - รีเฟรชต้นทุนล่าสุด】",
  "title5196": "เมื่อมีการแก้ไขเนื้อหาใดๆ ที่กล่าวถึงข้างต้น คุณจะต้องคลิกที่ปุ่ม 【อัปเดตกำไร】เพื่ออัปเดตข้อมูลกำไร",
  "title5197": "ระบบจะอัปเดตข้อมูลโดยอัตโนมัติทุกวันที่ 1 ของทุกเดือน หากคุณต้องการคำนวณล่วงหน้า ให้คลิกที่ปุ่ม 【อัปเดตกำไร】เพื่ออัปเดตข้อมูล",
  "title5198": "ค่าธรรมเนียมหีบว่าง",
  "title5199": "ยืนยันหมายเลขโทรศัพท์เดิม",
  "title5200": "การตรวจสอบ",
  "title5201": "เบอร์โทรศัพท์ใหม่",
  "title5202": "เบอร์โทรศัพท์",
  "title5203": "รหัสยืนยันไม่ถูกต้อง โปรดป้อนใหม่",
  "title5204": "พารามิเตอร์ ไม่สามารถดูได้",
  "title5205": "ข้อมูลการลา",
  "title5206": "วัน",
  "title5207": "ไฟล์ ได้เลือกครั้งนี้",
  "title5208": "ไฟล์ ทั้งหมดที่เลือก",
  "title5209": "ไฟล์",
  "title5210": "ลบกลุ่มย่อยและแก้ไขการจัดกลุ่มข้อมูลที่ใช้เป็นไม่จัดกลุ่ม",
  "title5211": "คำสั่งผิด",
  "title5212": "โกดังรับสินค้าจะเลือกคลังค่าเริ่มต้นโดยอัตโนมัติ ไม่ใช่คลังสินค้าขาออกของหนึ่งในคำสั่ง การสแกนหนึ่งหีบสองครั้งสามารถดำเนินการทำการนำเข้า ขีดจำกัดสูงสุดสำหรับการสแกนครั้งนั้นคือการจัดการ 500 หีบสินค้าคืน",
  "title5213": "ว่าต้องการเข้าคลังหรือไม่",
  "title5215": "ทั้งหมดไม่ได้เข้าคลัง",
  "title5216": "คลังสินค้าที่รับ",
  "title5217": "สแกนหรือป้อนหมายเลขคำสั่ง",
  "title5218": "หมายเลขแทร็คกิ้ง",
  "title5219": "นำเข้าหมายเลขใบส่งของเป็นกลุ่ม",
  "title5220": "ผลการสแกน",
  "title5221": "โปรดป้อนหมายเลขคำสั่งคืน",
  "title6000": "โปรดป้อนหมายเลขใบส่งของคืน",
  "title6001": "สแกนหรือป้อนหมายเลขคำสั่งคืน",
  "title6002": "หมายเลขแทร็คกิ้งคืนสินค้า",
  "title6003": "โปรดสแกนหรือป้อนหมายเลขคำสั่งคืนก่อน",
  "title5222": "ไม่มีข้อมูลการสแกน",
  "title5223": "แพลตฟอร์ม",
  "title5224": "หมายเลขคืนสินค้า",
  "title5225": "หมายเลขแทร็คกิ้งคืนสินค้า",
  "title5226": "ผลิตภัณฑ์",
  "title5228": " ตำแหน่ งรั บสินค้ า",
  "title5230": "ดำเนินการ",
  "title5231": "ลบ",
  "title5232": "คำแนะนำที่เป็นมิตร: หลังจากประมวลผลเข้าสู่การเก็บรักษา คำสั่งคืนไม่สามารถยกเลิกเก็บได้",
  "title5233": "โปรดดำเนินการอย่างระมัดระวัง",
  "title5234": "ปิด",
  "title5235": "คำสั่งนี้มีอยู่ในรายการสแกนแล้ว",
  "title5236": "เก็บข้อมูลสำเร็จ",
  "title5237": "หลังจากสแกนสำหรับการจัดส่ง แพ็คเกจจะย้ายไปยังรายการ 'รอรั บ' โดยอัตโนมัติ",
  "title5238": "ยืนยันการส่งของ",
  "title5240": "สแกนสำเร็จ",
  "title5241": "ล้างและดำเนินการสแกนต่อไป",
  "title5242": "การสแกนปัจจุบัน",
  "title5243": "หมายเลขแทร็คกิ้ง",
  "title5244": "วิธีการจัดส่ง:",
  "title5245": "โปรดเชื่อมต่อสแกนเนอร์เพื่อสแกนหีบ",
  "title5246": "ข้อมูล",
  "title5247": "จำนวน",
  "title5248": "ประวัติการสแกน",
  "title5249": "สแกนแล้ว：",
  "title5250": "ส่งสำเร็จ",
  "title5251": "หมายเลขการสั่งซื้อ",
  "title5252": "รวม",
  "title5253": "สถานะการจัดส่ง",
  "title5254": "การส่งสำเร็จ",
  "title5255": "การสแกนหนึ่งครั้งแสดงถึงการจัดส่งครั้งเดียว การสแกนสองครั้งแสดงถึงการแสดงข้อมูลใบสั่งซื้อครั้งแรกและการสแกนสองครั้งแสดงถึงการจัดส่ง",
  "title5256": "จำนวนการสแกน",
  "title5257": "สแกนครั้งเดียว",
  "title5258": "สแกนสองครั้ง",
  "title5259": "ข้อผิดพลาดในการสแกน กรุณาคลิกที่กล่องข้อมูลด้วยเมาส์เพื่อเปลี่ยนสีเป็นสีน้ำเงิน",
  "title5260": "จิราภาพของระบบ",
  "title5261": "ยืนยันการล้างรายการสแกนทั้งหมด",
  "title5262": "รายละเอียดยอดเงิน",
  "title5263": "สกุลเงินปัจจุบัน",
  "title5264": "สลับ",
  "title5265": "อัตราแล",
  "title5266": "ผู้ซื้อชำระจริง",
  "title5267": "ยอดรวมหลังหักส่วนลด",
  "title5268": "ยอดรวมก่อนหักส่วนลด",
  "title5269": "ส่วนลดสินค้าของผู้ขาย",
  "title5270": "ส่วนลดสินค้า",
  "title5271": "ค่าขนส่งหลังหักส่วนลด",
  "title5272": "ค่าขนส่งก่อนหักส่วนลด",
  "title5273": "ส่วนลดค่าขนส่งของผู้ขาย",
  "title5274": "ส่วนลดค่าขนส่ง",
  "title5275": "ภาษี",
  "title5276": "จำนวนเงินที่ได้รับจากแพลตฟอร์ม",
  "title5278": "ยอดรวมหลังจากส่วนลดของผู้ขาย",
  "title5279": "ยอดรวมคืนหลังจากส่วนลดของผู้ขาย",
  "title5280": "ค่าใช้จ่ายรวม",
  "title5281": "ค่าธรรมเนียมการทำธุรกรรม",
  "title5282": "ค่าคอมมิชชั่น",
  "title5283": "ค่าขนส่งของผู้ขาย",
  "title5284": "ค่าขนส่งของผู้ใช้",
  "title5285": "ค่าขนส่งจริง",
  "title5286": "การสนับสนุนค่าขนส่ง",
  "title5287": "ค่าขนส่งการคืนสินค้าที่แท้จริง",
  "title5288": "ค่าขนส่งที่คืนให้ลูกค้า",
  "title5289": "คอมมิชชั่นผู้มีอิทธิพล",
  "title5290": "ค่าบริการ",
  "title5292": "จำนวนการชำระเงินโดยประมาณ",
  "title5293": "จำนวนเงินที่ได้รับ",
  "title5296": "ค่าบรรจุ",
  "title5297": "ค่าขนส่งโดยประมาณ",
  "title5298": "ค่าธรรมเนียมโดยประมาณ",
  "title5301": "ได้กำไร/ขาดทุน",
  "title5302": "อัตรากำไร",
  "title5303": "ค่าจัดส่ง",
  "title5304": "ค่าขนส่งเดิม",
  "title5305": "บัตรกำนัล",
  "title5306": "บัตรกำนัลของผู้ขาย",
  "title5307": "จำนวนสินค้า",
  "title5308": "ค่าขนส่งข้ามชายและพันธุ์ (จริง)",
  "title5311": "การคืนเงินค่าจัดส่ง Shopee",
  "title5312": "จำนวนคูปองของผู้ขาย",
  "title5313": "ค่าธรรมเนียมและค่าใช้จ่าย",
  "title5314": "ค่าคอมมิชชั่นการตลาดพันธมิตร",
  "title5315": "ค่าขนส่งเดิม",
  "title5316": "ส่วนลดค่าขนส่งของแพลตฟอร์ม",
  "title5317": "ส่วนลดของผู้ขาย",
  "title5318": "ค่าปรับ",
  "title5319": "ข้อมูลการเงิน",
  "title5320": "เลขพัสดุ",
  "title5321": "ใบรับรอง",
  "title5322": "ค่าคีย์",
  "title5323": "สำเร็จ",
  "title5324": "ที่อยู่ต้องไม่ว่างเปล่า",
  "title5325": "ลูกค้า",
  "title5326": "ถึง",
  "title5327": "ตัวอักษร",
  "title5328": "เช่น",
  "title5329": "ไฟล์ที่มีนามสกุล",
  "title5330": "การปรับค่าที่มาจากเดือนอื่น",
  "title5332": "วันสูงสุดของกลุ่มไม่สามารถเว้นว่างได้",
  "title5333": "เร็วๆ นี้",
  "title5334": "ยอดขายรวมในวันที่ผ่านมา",
  "title5335": "โปรดป้อนเนื้อหาการสั่งซื้อ",
  "title10000": "หน้าแรก",
  "title10001": "ชั่วโมง, เบิร์นที่อนุญาต",
  "title10002": "ระยะเวลาเริ่มต้นจาก",
  "title10003": "ชั่วโมง",
  "title10004": "จาก",
  "title10005": "เริ่มต้นที่ชั่วโมง, ทุก",
  "title10006": "ปฏิบัติทุกชั่วโมงครั้งหนึ่ง",
  "title10007": "ระบุ",
  "title10008": "อนุญาตให้เลือกหลายรายการ",
  "title10009": "นาที, เบิร์นที่อนุญาต",
  "title10010": "นาที",
  "title10011": "เริ่มต้นที่นาที, ทุก",
  "title10012": "ปฏิบัติทุกนาทีครั้งหนึ่ง",
  "title10013": "วินาที",
  "title10014": "วัน",
  "title10015": "เดือน",
  "title10016": "สัปดาห์",
  "title10017": "ปี",
  "title10018": "สมการเวลา",
  "title10019": "สูตร",
  "title10021": "รีเซ็ต",
  "title10022": "ปิด",
  "title10023": "จากส่วนประกอบ",
  "title10024": "เปลี่ยนแปลง",
  "title10025": "พร้อมที่จะกู้คืน",
  "title10026": "หนึ่ง",
  "title10027": "สอง",
  "title10028": "สาม",
  "title10029": "สี่",
  "title10030": "ห้า",
  "title10031": "หก",
  "title10032": "เวลาการทำงานล่าสุด 5 ครั้ง",
  "title10033": "ในผลลัพธ์ที่คำนวณไว้",
  "title10034": "ไม่มีผลลัพธ์ที่ตรงกับเงื่อนไข!",
  "title10035": "เฉพาะด้านบนใน 100 ปีที่ผ่านมา",
  "title10036": "ผลลัพธ์!",
  "title10037": "วัน ตัวอย่าง แบบเบิร์นที่อนุญาต",
  "title10038": "ไม่ระบุ",
  "title10039": "เริ่มต้นที่, ทุก",
  "title10040": "ปฏิบัติทุกวันหนึ่งครั้ง",
  "title10041": "ทุกเดือน",
  "title10042": "วันทำงานที่ใกล้ที่สุดของเดือน",
  "title10043": "วันสุดท้ายของเดือนนี้",
  "title10044": "วินาที ตัวอย่าง แบบเบิร์นที่อนุญาต",
  "title10045": "เริ่มต้นที่วินาที, ทุก",
  "title10046": "ปฏิบัติทุกวินาทีหนึ่งครั้ง",
  "title10047": "สัปดาห์ ตัวอย่าง แบบเบิร์นที่อนุญาต",
  "title10048": "ระยะเวลาเริ่มต้นจากสัปดาห์",
  "title10049": "อัน",
  "title10050": "วันของสัปดาห์",
  "title10051": "วันทำงานสุดท้ายของเดือนนี้",
  "title10052": "วันจันทร์",
  "title10053": "วันอังคาร",
  "title10054": "วันพุธ",
  "title10055": "วันพฤหัสบดี",
  "title10056": "วันศุกร์",
  "title10057": "วันเสาร์",
  "title10058": "วันอาทิตย์",
  "title10059": "ไม่ต้องกรอก สัญลักษณ์ที่อนุญาต",
  "title10060": "ต่อปี",
  "title10061": "เริ่มต้นจากปี ทุก",
  "title10062": "ปีดำเนินการครั้งละ",
  "title10063": "เดือน สัญลักษณ์ที่อนุญาต",
  "title10064": "เริ่มต้นเดือน ท",
  "title10065": "การดำเนินการรายเดือนครั้งละ",
  "title10066": "ที่อยู่เอกสาร",
  "title10067": "โปรดป้อนเนื้อหา",
  "title10068": "ขนาดไฟล์ที่อัปโหลดต้องไม่เกิน",
  "title10069": "การแทรกรูปภาพล้มเหลว",
  "title10070": "โปรดป้อนที่อยู่ลิงก์",
  "title10071": "บันทึก",
  "title10072": "โปรดใส่ที่อยู่วิดีโอ",
  "title10073": "ข้อความ",
  "title10074": "หัวข้อ 1",
  "title10075": "หัวข้อ 2",
  "title10076": "หัวข้อ 3",
  "title10077": "หัวข้อ 4",
  "title10078": "หัวข้อ 5",
  "title10079": "หัวข้อ 6",
  "title10080": "ฟอนต์มาตรฐาน",
  "title10081": "ฟอนต์เซอร์เฟซ",
  "title10082": "ฟอนต์พื้นฐาน",
  "title10083": "ข้อความบรรทัดเดียว",
  "title10084": "รูปแบบหมายเลขโทรศัพท์ไม่ถูกต้อง",
  "title10085": "กรุณาใส่หมายเลขโทรศัพท์",
  "title10086": "เลือกไฟล์",
  "title10087": "กรุณาอัปโหลด",
  "title10088": "ขนาดไม่เกิน",
  "title10089": "รูปแบบคือ",
  "title10090": "ของไฟล์",
  "title256": "ลบ",
  "title10092": "รูปแบบไฟล์ไม่ถูกต้อง",
  "title10093": "รูปแบบของไฟล์",
  "title10094": "กำลังอัปโหลดไฟล์ โปรดรอ",
  "title10095": "จำนวนไฟล์ที่อัปโหลดไม่สามารถเกินได้",
  "title10096": "ชิ้น",
  "title10097": "การอัปโหลดภาพล้มเหลว โปรดลองอีกครั้ง",
  "title10098": "โปรดป้อน",
  "title10099": "ข้อความหลายบรรทัด",
  "title10100": "รหัสผ่าน",
  "title10101": "เคาน์เตอร์",
  "title10102": "ตัวแก้ไข",
  "title10103": "เลือกลงมา",
  "title10104": "ตัวเลือกหนึ่ง",
  "title10105": "ตัวเลือกที่สอง",
  "title10106": "โปรดเลือก",
  "title10107": "เลือกที่ไปต่อ",
  "title10108": "ตัวเลือก 1",
  "title10109": "ตัวเลือก 1-1",
  "title10110": "กลุ่มปุ่มวิทยุ",
  "title10111": "กลุ่มกล่องทำเครื่องหมาย",
  "title10112": "สวิตช์",
  "title10113": "สไลด์",
  "title10114": "เลือกเวลา",
  "title10115": "ช่วงเวลา",
  "title10116": "ถึง",
  "title10117": "เวลาเริ่มต้น",
  "title10118": "เวลาสิ้นสุด",
  "title10119": "การเลือกวันที่",
  "title10120": "ช่วงวันที่",
  "title10121": "วันที่เริ่มต้น",
  "title10122": "วันที่สิ้นสุด",
  "title10123": "การให้คะแนน",
  "title10124": "การเลือกสี",
  "title10125": "อัปโหลด",
  "title10126": "คลิกเพื่ออัปโหลด",
  "title10127": "คอนเทนเนอร์แถว",
  "title10128": "ปุ่ม",
  "title10129": "ปุ่มหลัก",
  "title10130": "ตาราง",
  "title10131": "กำลังพัฒนา",
  "title10133": "ที่อยู่",
  "title10135": "การดำเนินการ",
  "title10136": "โปรดป้อนชื่อไอคอน",
  "title10137": "โปรดเลือกอย่างน้อยหนึ่ง",
  "title10138": "ต้องไม่ว่าง",
  "title10139": "ขนาดไฟล์เกิน",
  "title10140": "ควรเลือก",
  "title10141": "ประเภทของไฟล์",
  "title10142": " ค้นหา ",
  "title10143": "ส่ง",
  "title10144": "สามารถอัปโหลดได้ไม่เกิน",
  "title10145": "ของ",
  "title10146": "ไฟล์",
  "title10147": "ดูตัวอย่าง",
  "title10148": "ไฟล์รูปภาพ",
  "title10149": "ขนาดรูปภาพโปรไฟล์ที่อัปโหลดต้องไม่เกิน",
  "title10150": "กำลังอัปโหลดรูปภาพ โปรดรอสักครู่",
  "title10151": "วันนี้",
  "title10152": "เมื่อวาน",
  "title10153": "7 วัน",
  "title10154": "14 วันล่าสุด",
  "title10155": "30 วันล่าสุด",
  "title10156": "เดือนนี้",
  "title10157": "เดือนที่แล้ว",
  "title10158": "6 เดือนล่าสุด",
  "title10159": "ปีที่แล้ว",
  "title10160": "สถานะ",
  "title10161": "กำลังประมวลผล โปรดอย่าปิดหน้าต่างปัจจุบัน",
  "title10162": "เสร็จสิ้น",
  "title10163": "กำลังประมวลผลข้อมูล",
  "title10164": "ส่งออก",
  "title10165": "อย่าปิดหน้าต่างปัจจุบันขณะส่งออกข้อมูล คุณสามารถดาวน์โหลดรายงานหลังจากการส่งออกเสร็จสมบูรณ์",
  "title10166": "ดาวน์โหลด",
  "title10167": "ปิด",
  "title10168": "พร้อมส่งออก",
  "title10169": "การส่งออก",
  "title10170": "การประมวลผลเสร็จสมบูรณ์ สามารถดาวน์โหลดได้",
  "title10171": "หัวเรื่อง",
  "title10172": "ซิงค์",
  "title10173": "กรุณาอย่าปิดหน้าต่างปัจจุบันขณะซิงโครไนซ์ข้อมูล คุณสามารถปิดหน้าต่างหลังจากการซิงโครไนซ์เสร็จสิ้น",
  "title10174": "ใช้หลังจากอัปเกรดเป็นผู้ใช้ที่จ่ายเงิน",
  "title10175": "นัดอบรม",
  "title10176": "ดูความช่วยเหลือ",
  "title10177": "อัปเกรดทันที",
  "title10178": "ไม่มีความตรงกับ:",
  "title10179": "การจับคู่:",
  "title10180": "เบราว์เซอร์ของคุณไม่รองรับเต็มจอ",
  "title10181": "ซ่อนการค้นหา",
  "title10182": "แสดงการค้นหา",
  "title10183": "รีเฟรช",
  "title10184": "แสดงคอลัมน์",
  "title10185": "แสดง",
  "title10186": "ซ่อน",
  "title10187": "ผสานและดำเนินต่อ",
  "title10188": "เพิ่มเติม",
  "title10189": "คู่มือเริ่มต้น",
  "title10190": "จัดการร้านค้าได้อย่างสะดวก ปรับปรุงประสิทธิภาพ และลดค่าใช้จ่ายทางธุรกิจ",
  "title10191": "คู่มือการทำงาน",
  "title10192": "อนุญาตสามคลัง",
  "title10194": "โปรดใส่ชื่อร้านค้าแทน",
  "title10195": " คลังสินค้า",
  "title10196": "เลือกคลังสินค้า",
  "title10197": "เมื่อคุณลงทะเบียนบัญชี Xinzhan ระบบจะสร้างคลังสำหรับคุณโดยอัตโนมัติและตั้งค่าให้เป็นคลังเริ่มต้นหากคุณต้องการแก้ไขโปรดไปที่ [สินค้าคงคลัง-การจัดการคลังคงคลัง-รายการคลัง] เพื่อตั้งค่า",
  "title10198": "ให้สิทธิร้านค้า",
  "title10199": "เพิ่มสินค้า",
  "title10200": "บำรุงรักษาข้อมูลผลิตภัณฑ์ท้องถิ่นในระบบสำหรับการสถิติกำไร, ต้นทุน",
  "title10201": "การจับคู่สินค้า",
  "title10202": "เทียบคู่สินค้าออนไลน์ จับคู่สินค้าท้องถิ่นกับแพลตฟอร์มออนไลน์",
  "title10203": "การจับคู่",
  "title10204": "การจับคู่สินค้าออนไลน์",
  "title10205": "การจัดการคลังสินค้า",
  "title10206": "เริ่มต้นสินค้าคงคลัง เพื่อการสั่งซื้อ การเติมสินค้า การจัดส่ง และกิจกรรมอื่นๆ",
  "title10207": "เพิ่มคลังสินค้า",
  "title10208": "การจัดการบุคคล",
  "title10209": "กำหนดสิทธิที่สอดคล้องกันสำหรับบทบาท เช่น การดำเนินงาน จัดซื้อ การเงิน และผู้จัดการในบริ",
  "title10210": "การตั้งค่าพารามิเตอร์",
  "title10211": "ตั้งค่าการแสดงผลเงิน สำหรับระบบของคุณตามอัตราแลกเปลี่ยนประเทศ ประเมินการตั้งค่าอัตราแลกเปลี่ยนใบสั่งซื้อ",
  "title10212": "การตั้งค่าอัตราแลกเปลี่ยน",
  "title10213": "ไม่ต้องแจ้ง",
  "title10214": "เปิดการนำทาง",
  "title10215": "กลับ",
  "title10216": "ขั้นตอนต่อไป",
  "title10217": "ชื่อร้านไม่สามารถเว้นว่างได้",
  "title10218": "คลังสินค้าต้องไม่ว่างเปล่า",
  "title10219": "คำแนะนำสำหรับผู้เริ่มต้น",
  "title10220": "ยินดีต้อนรับ",
  "title10221": "การให้สิทธิ์ร้านค้า",
  "title10222": "เพิ่มการให้สิทธิ์ร้านค้า",
  "title10223": "ให้สิทธิ์ร้านค้าของคุณให้กับระบบเพื่อการซิงโครไนส์ใบสั่งซื้อร้านค้า ผลิตภัณฑ์ออนไลน์ ฯลฯ",
  "title10224": "ร้านค้าสามารถรับอนุญาตผ่าน [ระบบ-การจัดการอนุญาต-การจัดการร้านค้า] เพิ่มการอนุญาตร้านค้าใหม่",
  "title10225": "หน้าการอนุญาตของบุคคลที่สามคือหน้าหลักของร้านค้า ชื่อบัญชีและรหัสผ่านของผู้ขายใช้สำหรับการอนุญาตเฉพาะร้านค้า Xinzhan จะไม่เก็บรหัสผ่านของคุณ",
  "title10226": "สินค้า",
  "title10227": "สามารถผ่าน [คลังสินค้า-สินค้า",
  "title10228": "สินค้า",
  "title10229": "สร้าง เพิ่มเข้าไปด้วยตนเอง นำเข้าสเปรดชีตเพื่อสร้างสินค้า",
  "title10230": "สามารถสร้างรายการเดียว",
  "title10231": "สินค้าและคอมโบ",
  "title10232": "สินค้า ต้องกรอกให้ถูกต้องตามรูปแบบเมื่อสร้างสินค้า",
  "title10233": "กับออนไลน์",
  "title10234": "จับคู่ ใช้ในการหักรายการคงคลังโดยอัตโนมัติในระบบ และสถิติข้อมูลที่เกี่ยวข้อง",
  "title10235": "การจับคู่สินค้าสามารถทำได้ผ่าน[คลังสินค้า-สินค้า",
  "title10236": "การตั้งค่าการจับคู่สินค้าออนไลน์",
  "title10237": "โกดังของตัวเอง】เมื่อคุณลงทะเบียนบัญชี Xinzhan ระบบจะสร้างคลังสินค้าให้คุณโดยอัตโนมัติและตั้งค่าให้เป็นคลังสินค้าเริ่มต้น หากต้องการแก้ไขโปรดไปที่ [สินค้าคงคลัง-การจัดการคลังสินค้า-รายการคลังสินค้า] เพื่อตั้งค่า",
  "title10238": "โกดังของบุคคลที่สาม】อนุญาตให้โกดังของบุคคลที่สาม ใน [สินค้าคงคลัง-การจัดการคลังสินค้า-รายการคลังสินค้า] สร้างคลังใหม่และเลือกโกดังของบุคคลที่สามที่ได้รับอนุญาตขณะการสร้าง",
  "title10239": "หลังจากอนุญาตให้โกดังของบุคคลที่สาม สามารถดันคำสั่งไปยังโกดังของบุคคลที่สามและรองรับการซิงค์สินค้าคงคลังจากโกดังของบุคคลที่สาม คำสั่งซื้อยังรองรับการดันอัตโนมัติสำหรับการประมวลผลการนำเข้า",
  "title10240": "การตั้งค่าบุคลากร",
  "title10241": "การจัดการบุคลากรสามารถตั้งค่าผ่าน [ระบบ-การจัดการบัญชี-การจัดการบัญชีย่อย] การตั้งค่าบัญชีย่อยสามารถจัดสรรสิทธิ์บทบาทที่แตกต่างกัน กำหนดสิทธิ์ร้านค้า สิทธิ์คลังสินค้า เป็นต้น",
  "title10242": "การตั้งค่าบทบาทสามารถสร้างผ่านทาง [ระบบ-การจัดการบัญชี-การจัดการบทบาท]",
  "title10243": "การตั้งค่าอัตราแลกเปลี่ยนสามารถแก้ไขผ่านทาง [ระบบ-การตั้งค่า-การตั้งค่าอัตราแลกเปลี่ยน] ระบบตั้งค่าเริ่มต้นเป็นอัตราแลกเปลี่ยนแบบเรียลไทม์และคุณสามารถปรับแต่งอัตราแลกเปลี่ยนได้ หลังจากตั้งค่าอัตราแลกเปลี่ยน รายการจะแสดงราคาตามการตั้งค่าล่าสุด",
  "title10244": "พารามิเตอร์การประเมินสามารถตั้งค่าผ่าน [ระบบ-การตั้งค่า-พารามิเตอร์การประเมิน] หลังจากการตั้งค่า เงินค่าใช้บริการของคำสั่งประเมินจะถูกคำนวณตามพารามิเตอร์ที่ตั้ง",
  "title10245": "ไม่มีสิทธิ์",
  "title10246": "โปรดเพิ่มสินค้าก่อน",
  "title10247": "โปรดเสร็จสิทธิ์ร้านก่อน",
  "title10248": "โปรดทำการยืนยันความปลอดภัย",
  "title10249": "ลากแถบเลื่อนด้านล่างเพื่อดำเนินการเสร็จสิ่งประสงค์",
  "title10250": "中文",
  "title10251": "สมาชิกปัจจุบัน",
  "title10252": "ซื้อ",
  "title10253": "ข้อมูลพื้นฐาน",
  "title10254": "แพ็คเกจของฉัน",
  "title10255": "คำสั่งซื้อของฉัน",
  "title10256": "ออกจากบทบาทการเล่น",
  "title10257": "ออกจากระบบ",
  "title10258": "林珍",
  "title10259": "โปรดใส่รหัสผ่านเข้าสู่ระบบ",
  "title10260": "เข้าสู่ระบบด้วยบัญชีอื่น",
  "title10261": "ลิขสิทธิ์ทั้งหมด",
  "title10262": "ตำบล",
  "title10263": "หมายเลขสำรอง 2023007214-2",
  "title10264": "คุณแน่ใจหรือไม่ว่าต้องการออกและออกจากระบบหรือไม่?",
  "title10265": "คุณแน่ใจหรือไม่ว่าต้องการออกจากบทบาทการเล่นหรือไม่?",
  "title10266": "ที่คุณเห็น",
  "title10267": "消息启动事件",
  "title10268": "定时启动事件",
  "title10269": "条件启动事件",
  "title10270": "信号启动事件",
  "title10271": "错误启动事件",
  "title10272": "升级启动事件",
  "title10273": "补偿启动事件",
  "title10274": "非中断",
  "title10275": "测试按钮1",
  "title10276": "测试按钮1】点击事件里可以访问当前表单：",
  "title10277": "所以",
  "title10278": "可以拿到当前表单的",
  "title10279": "组件",
  "title10280": "表单的",
  "title10281": "提交数据：",
  "title10282": "在这里输入文字",
  "title10283": "配置文档参阅：",
  "title10284": "验证成功",
  "title10285": "验证失败",
  "title10286": "请依次点击【",
  "title10287": "向右滑动完成验证",
  "title10288": "主题风格设置",
  "title10289": "图标",
  "title10290": "主题颜色",
  "title10291": "系统布局配置",
  "title10292": "开启",
  "title10293": "固定",
  "title10294": "动态标题",
  "title10295": "保存配置",
  "title10296": "重置配置",
  "title10297": "正在保存到本地，请稍候",
  "title10298": "正在清除设置缓存并刷新，请稍候",
  "title10299": "xinjian",
  "title10300": "刷新页面",
  "title10301": "关闭当前",
  "title10302": "关闭其他",
  "title10303": "关闭左侧",
  "title10304": "关闭右侧",
  "title10305": "全部关闭",
  "title10306": "测试任务",
  "title10307": "打开文件",
  "title10308": "下载为",
  "title10309": "下载文件",
  "title10310": "退出模拟",
  "title10311": "开启模拟",
  "title10312": "模拟",
  "title10313": "向左对齐",
  "title10314": "向右对齐",
  "title10315": "向上对齐",
  "title10316": "向下对齐",
  "title10317": "水平居中",
  "title10318": "垂直居中",
  "title10319": "缩小视图",
  "title10320": "放大视图",
  "title10321": "重置视图并居中",
  "title10322": "撤销",
  "title10323": "恢复",
  "title10324": "重新绘制",
  "title10325": "保存模型",
  "title10326": "业务流程",
  "title10327": "请按住",
  "title10328": "键选择多个元素对齐",
  "title10329": "自动对齐可能造成图形变形，是否继续？",
  "title10330": "警告",
  "title10331": "保存模型失败，请重试！",
  "title10332": "发起人：",
  "title10333": "部门：",
  "title10334": "创建时间：",
  "title10335": "审批人：",
  "title10336": "结果：",
  "title10337": "结束时间：",
  "title10338": "审批建议：",
  "title10339": "常规",
  "title10340": "消息与信号",
  "title10341": "流转条件",
  "title10342": "表单",
  "title10343": "友情提示：使用",
  "title10344": "流程表单",
  "title10345": "替代，提供更好的表单设计功能",
  "title10346": "任务",
  "title10347": "多实例",
  "title10348": "执行监听器",
  "title10349": "任务监听器",
  "title10350": "扩展属性",
  "title10351": "อื่นๆ",
  "title10352": "ให้ตัวตนอาชีพ",
  "title10353": "เช่น: คุณเป็นโปรแกรมการตลาดระดับอาวุโส",
  "title10354": "ต้องการรูปแบบและเผยแพร่เนื้อหาอย่างไร",
  "title10355": "วิธีใช้งานอย่างไร?",
  "title10356": "ค่าใช้จ่ายในการประเมิน",
  "title10357": "ข้อมูลช",
  "title10358": "ตรวจสอบคำสั่งซื้อ:",
  "title10359": "ตรวจสอบว่าคำสั่งซื้อถูกจับคู่หรือค่าใช้จ่ายถูกพัฒนาไหม ใน",
  "title10361": "บำรุงคำสั่งซื้อด้วยตัวกรอง【ไม่มีค่าใช้จ่ายสินค้า】",
  "title10362": "ทำเครื่องหมายการประเมินและค้างคงคำสั่ง:",
  "title10363": "ในการจัดการคำสั่งซื้อ, ทำเครื่องหมายคำสั่งซื้อที่ต้องการประเมินหรือถือตามได้",
  "title10364": "การจัดการคำสั่งซื้อ - การประมวลผลคำสั่งซื้อ",
  "title10365": "ตั้งอัตราแลกเปลี่ยนการชำระเงิน:",
  "title10366": "ปรับตามการตั้งค่าอัตราแลกเปลี่ยนล่าสุดเพื่อให้แน่ใจว่าการคำนวณจำนวนการชำระเงินถูกต้อง",
  "title10367": "การวิเคราะห์กำไร - รายงานกำไรรายเดือน - การตั้งค่าอัตราแลกเปลี่ยนการชำระเงิน",
  "title10368": "ข้อผิดพลาดในการตั้งค่าอัตราแลกเปลี่ยนการประเมิน:",
  "title10369": "เมื่อการตั้งค่าอัตราแลกเปลี่ยนการชำระเงินประเมินไม่ถูกต้อง, ใน",
  "title10370": "การตั้งค่า - พารามิเตอร์การประเมิน",
  "title10371": "ทำการเปลี่ยนแปลงด้านใน หลังจากการปรับเปลี่ยนเสร็จใน",
  "title10372": "ค้นหาคำสั่งซื้อที่เกี่ยวข้องและเลือก 【การดำเนินการชุด - รีเฟรชต้นทุนล่าสุด】",
  "title10373": "เพื่อระลึก:",
  "title10374": "เมื่อมีการแก้ไขเนื้อหาใดบนตัวเลือก คุณจำเป็นต้องคลิกที่ปุ่ม 【อัปเดตกำไร】 เพื่ออัปเดตข้อมูลกำไร",
  "title10375": "ระบบจะอัปเดตข้อมูลโดยอัตโนมัติทุกวันที่ 1 ของทุกเดือน หากคุณต้องการคำนวณล่วงหน้า คลิกที่ปุ่ม 【อัปเดตกำไร】เพื่ออัปเดตข้อมูล",
  "title10376": "ไม่ต้องแสดง",
  "title10377": "ฉันเข้าใ",
  "title10378": "ตัวอักษรต้องมีตัวใหญ่",
  "title10379": "ตัวอักษร, ตัวเลข และอักขระต้องรวมอย่างน้อย 2 ประเภท",
  "title10380": "วัน",
  "title10381": "ไฟล์ที่เลือกในครั้งนี้:",
  "title10382": "ไฟล์ที่เลือกทั้งหมด:",
  "title10383": "ไฟล์",
  "title10384": "ลบกลุ่มย่อยของมันและปรับการจัดกลุ่มของข้อมูลที่ใช้เป็นไม่จัดกลุ่ม",
  "title10385": "สั่งซื้อไม่ถูกต้อง",
  "title10386": "สแกนเพื่อ",
  "title10387": "โกดังรับสินค้าจะเลือกโกดังเริ่มต้นโดยอัตโนมัติไม่ใช่โกดังจัดส่งจากหนึ่งในคำสั่",
  "title10388": "มีสินค้าในสต็อกหรือไม่",
  "title10390": "ไม่มีสินค้าที่จะจัดเก็บ",
  "title10391": "คลังสินค้าที่รับ",
  "title10392": "สแกนหรือป้อนหมายเลขใบสั่งซื้อ",
  "title10393": "หมายเลขแทร็คกิ้ง",
  "title10394": "นำเข้าหมายเลขใบส่งของทั้งหมด",
  "title10395": "ผลการสแกน",
  "title10396": "ไม่มีข้อมูลการสแกนสำหรับแสดง",
  "title10397": "กรุณาเชื่อมต่อสแกนเนอร์เพื่อสแกนพัสดุ",
  "title10398": "แพลตฟอร์ม",
  "title10399": "หมายเลขคืนสินค้า",
  "title10400": "หมายเลขแทร็คกิ้งคืนสินค้า",
  "title10401": "ผลิตภัณฑ์",
  "title10403": "ตำแหน่ งรั บสินค้ า",
  "title10405": "เอาออก",
  "title10407": "การสแกนหนึ่งครั้งหมายถึงการสแกนหนึ่งครั้งเพื่อจัดส่ง การสแกนสองครั้งหมายถึงการแสดงข้อมูลคำสั่งครั้งแรกและสแกนเพื่อจัดส่งครั้งที่สอง",
  "title10408": "จำนวนครั้งที่สแกน",
  "title10409": "รายละเอียดจำนวนเงิน",
  "title10410": "สกุลเงินปัจจุบัน",
  "title10411": "เปลี่ยน",
  "title10412": "อัตราแลกเปลี่ยนปัจจุบัน",
  "title10413": "ผู้ซื้อชำระจริง",
  "title10414": "ยอดรวมหลังหักส่วนลด",
  "title10415": "ยอดรวมก่อนหักส่วนลด",
  "title10416": "ส่วนลดสินค้าของผู้ขาย",
  "title10417": "ส่วนลดสินค้า",
  "title10418": "ค่าขนส่งหลังหักส่วนลด",
  "title10419": "ค่าขนส่งก่อนหักส่วนลด",
  "title10420": "ส่วนลดค่าขนส่งของผู้ขาย",
  "title10421": "ส่วนลดค่าขนส่ง",
  "title10422": "ภาษี",
  "title10423": "จำนวนเงินที่ได้รับจากแพลตฟอร์ม",
  "title10425": "ยอดรวมหลังส่วนลดของผู้ขาย",
  "title10426": "ยอดรวมหลังส่วนลดของผู้ขายที่ถูกคืนเงิน",
  "title10427": "ค่าใช้จ่ายทั้งหมด",
  "title10428": "ค่าธรรมเนียมการทำธุรกรรม",
  "title10429": "ค่าคอมมิชชั่น",
  "title10430": "ค่าขนส่งของผู้ขาย",
  "title10431": "ค่าขนส่งของผู้ซื้อ",
  "title10432": "ค่าขนส่งจริง",
  "title10433": "การชดเชยค่าขนส่ง",
  "title10434": "ค่าขนส่งการส่งคืนจริง",
  "title10435": "ค่าขนส่งการคืนเงินของลูกค้า",
  "title10436": "ค่าคอมมิชชั่นนักแนะนำ",
  "title10437": "ค่าบริการ",
  "title10438": "จำนวนเงินที่คาดว่าจะได้รับคืน",
  "title10439": "จำนวนเงินที่ได้รับ",
  "title10441": "ค่าบริการหีบห่อ",
  "title10442": "ค่าขนส่งโดยประมาณ",
  "title10443": "ค่าธรรมเนียมการจัดการโดยประมาณ",
  "title10444": "ไปที่การตั้งค่า",
  "title10446": "ได้กำไร/ขาดทุน",
  "title10447": "อัตรากำไร",
  "title10448": "จำนวนสินค้า",
  "title10449": "ค่าขนส่ง",
  "title10450": "ค่าขนส่งเดิม",
  "title10451": "คูปอง",
  "title10452": "คูปองของผู้ขาย",
  "title10453": "จำนวนสินค้า",
  "title10454": "ค่าขนส่งแยกต่างประเทศ (จริง)",
  "title10457": "การคืนเงินค่าขนส่ง Shopee",
  "title10458": "จำนวนคูปองของผู้ขาย",
  "title10459": "ค่าใช้จ่ายและค่าธรรมเนียม",
  "title10460": "ค่าใช้จ่ายคอมมิชชันการตลาดในสหภาพ",
  "title10461": "ค่าขนส่งเดิม",
  "title10462": "ส่วนลดการขนส่งของแพลตฟอร์ม",
  "title10463": "ส่วนลด",
  "title10464": "ส่วนลดของผู้ขาย",
  "title10465": "ค่าปรับ",
  "title10466": "ข้อมูลการเงิน",
  "title10467": "สแกนและจัดส่ง",
  "title10468": "หลังจากสแกนเพื่อการจัดส่ง พัสดุจะถูกย้ายไปยังรายการ [ให้รับ] โดยอัตโนมัติ",
  "title10469": "ยืนยันการจัดส่ง",
  "title10470": "สแกนสำเร็จ",
  "title10471": "เคลียร์และดำเนินการสแกนต่อไป",
  "title10472": "การสแกนปัจจุบัน",
  "title10473": "หมายเลขแทร็คกิ้ง",
  "title10474": "วิธีการจัดส่ง:",
  "title10475": "ข้อมูล",
  "title10476": "จำนวน",
  "title10477": "ประวัติการสแกน",
  "title10478": "สแกนแล้ว",
  "title10479": "สำเร็จ:",
  "title10480": "หมายเลขการสั่งซื้อ",
  "title10481": "รวม",
  "title10482": "สถานะการจัดส่ง",
  "title10483": "การส่งสินค้าสำเร็จ",
  "title10484": "สแกนครั้งเดียว",
  "title10485": "สแกนสองครั้ง",
  "title10486": "ข้อผิดพลาดในการสแกน โปรดคลิกที่กล่องข้อมูลด้วยเมาส์ก่อน กล่องข้อมูลจะเปลี่ยนเป็นสีน้ำเงิน",
  "title10487": "คำแนะนำของระบบ",
  "title10488": "คุณแน่ใจหรือไม่ว่าต้องการล้างบันทึกการสแกนทั้งหมดหรือไม่？",
  "title10489": "สำเร็จ",
  "title10490": "ใบรับรอง",
  "title10491": "ค่าคีย์",
  "title10492": "หมายเลขพัสดุ",
  "title10493": "如何实现实现会签、或签？",
  "title10494": "流程标识",
  "title10495": "请输入流标标识",
  "title10496": "流程名称",
  "title10497": "请输入流程名称",
  "title10498": "流程标识不能为空",
  "title10499": "流程名称不能为空",
  "title10500": "不满足",
  "title10501": "规则，所以不进行赋值",
  "title10502": "满足",
  "title10503": "规则，所以进行赋值",
  "title10504": "表单标识",
  "title10505": "业务标识",
  "title10506": "无",
  "title10507": "表单字段",
  "title10508": "序号",
  "title10509": "字段名称",
  "title10510": "字段类型",
  "title10511": "默认值",
  "title1593": "编辑",
  "title10513": "添加字段",
  "title10514": "字段配置",
  "title10515": "字段",
  "title10516": "类型",
  "title10517": "请选择字段类型",
  "title10518": "类型名称",
  "title10519": "时间格式",
  "title10520": "枚举值列表：",
  "title10521": "添加枚举值",
  "title10522": "枚举值编号",
  "title10523": "枚举值名称",
  "title10524": "约束条件列表：",
  "title10525": "添加约束",
  "title10526": "约束名称",
  "title10527": "约束配置",
  "title10528": "字段属性列表：",
  "title10529": "添加属性",
  "title10530": "属性编号",
  "title10531": "属性值",
  "title10532": "取",
  "title10534": "保",
  "title10535": "存",
  "title10536": "编号",
  "title10537": "配置",
  "title10538": "值",
  "title10539": "确 定",
  "title10540": "",
  "title10541": "长整型",
  "title10542": "字符串",
  "title10543": "布尔类",
  "title10544": "日期类",
  "title10545": "枚举类",
  "title10546": "自定义类型",
  "title10547": "属性配置",
  "title10548": "枚举值配置",
  "title10549": "约束条件配置",
  "title10550": "流转类型",
  "title10551": "普通流转路径",
  "title10552": "默认流转路径",
  "title10553": "条件流转路径",
  "title10554": "条件格式",
  "title10555": "脚本",
  "title10556": "脚本语言",
  "title10557": "脚本类型",
  "title10558": "内联脚本",
  "title10559": "外部脚本",
  "title10560": "资源地址",
  "title10561": "事件类型",
  "title10562": "监听器类型",
  "title10563": "添加监听器",
  "title10564": "类",
  "title10565": "代理表达式",
  "title10566": "脚本格式",
  "title10567": "请填写脚本格式",
  "title10568": "请选择脚本类型",
  "title10569": "脚本内容",
  "title10570": "请填写脚本内容",
  "title10571": "请填写资源地址",
  "title10572": "定时器类型",
  "title10573": "持续时长",
  "title10574": "循环",
  "title10575": "定时器",
  "title10576": "注入字段：",
  "title10577": "字段值",
  "title10578": "字段名称：",
  "title10579": "字段类型：",
  "title10580": "字段值：",
  "title10581": "表达式：",
  "title10582": "回路特性",
  "title10583": "并行多重事件",
  "title10584": "时序多重事件",
  "title10585": "循环事件",
  "title10586": "循环基数",
  "title10587": "集合",
  "title10588": "元素变量",
  "title10589": "完成条件",
  "title10590": "异步状态",
  "title10591": "异步前",
  "title10592": "异步后",
  "title10593": "排除",
  "title10594": "重试周期",
  "title10595": "确认移除该字段吗？",
  "title10596": "认",
  "title10597": "操作取消",
  "title10598": "确认移除该监听器吗？",
  "title10599": "事件",
  "title10600": "监听器",
  "title10601": "请填写定时器配置",
  "title10602": "元素文档：",
  "title10603": "创建",
  "title10604": "指派",
  "title10605": "完成",
  "title10606": "更新",
  "title10607": "超时",
  "title10608": "属性名",
  "title10609": "属性名：",
  "title10610": "属性值：",
  "title10611": "确认移除该属性吗？",
  "title10612": "消息列表",
  "title10613": "创建新消息",
  "title10614": "消息",
  "title10615": "消息名称",
  "title10616": "信号列表",
  "title10617": "创建新信号",
  "title10618": "信号",
  "title10619": "信号名称",
  "title10620": "创建消息",
  "title10621": "创建信号",
  "title10622": "该消息已存在，请修改",
  "title10623": "后重新保存",
  "title10624": "该信号已存在，请修改",
  "title10625": "异步延续",
  "title10626": "การปรับครอบเดือน",
  "title10627": "ค่าธรรมเนียมกล่องว่าง",
  "title10629": "追加结束事件",
  "title10630": "追加网关",
  "title10631": "追加任务",
  "title10632": "追加中间抛出事件",
  "title10633": "边界事件",
  "title10634": "激活全局连接工具",
  "title10635": "添加",
  "title10636": "在上面添加道",
  "title10637": "分割成两个道",
  "title10638": "分割成三个道",
  "title10639": "在下面添加道",
  "title10640": "追加补偿活动",
  "title10641": "修改类型",
  "title10642": "使用关联连接",
  "title10643": "使用顺序",
  "title10644": "消息流或者关联连接",
  "title10645": "使用数据输入关联连接",
  "title10646": "激活抓手工具",
  "title10647": "激活套索工具",
  "title10648": "激活创建",
  "title10649": "删除空间工具",
  "title10650": "创建扩展子过程",
  "title10651": "创建中间抛出事件",
  "title10652": "创建池",
  "title10653": "参与者",
  "title10654": "数据对象参考",
  "title10655": "数据存储参考",
  "title10656": "即席",
  "title10657": "发送任务",
  "title10658": "接收任务",
  "title10659": "用户任务",
  "title10660": "手工任务",
  "title10661": "业务规则任务",
  "title10662": "服务任务",
  "title10663": "脚本任务",
  "title10664": "调用活动",
  "title10665": "子流程（折叠的）",
  "title10666": "子流程（展开的）",
  "title10667": "开始事件",
  "title10668": "中间事件",
  "title10669": "结束事件",
  "title10670": "创建开始事件",
  "title10671": "创建结束事件",
  "title10672": "创建任务",
  "title10673": "创建用户任务",
  "title10674": "创建网关",
  "title10675": "创建数据对象",
  "title10676": "创建数据存储",
  "title10677": "创建分组",
  "title10678": "创建中间",
  "title10679": "消息开始事件",
  "title10680": "定时开始事件",
  "title10681": "条件开始事件",
  "title10682": "信号开始事件",
  "title10683": "错误开始事件",
  "title10684": "升级开始事件",
  "title10685": "补偿开始事件",
  "title10686": "消息开始事件（非中断）",
  "title10687": "定时开始事件（非中断）",
  "title10688": "条件开始事件（非中断）",
  "title10689": "信号开始事件（非中断）",
  "title10690": "升级开始事件（非中断）",
  "title10691": "消息中间捕获事件",
  "title10692": "消息中间抛出事件",
  "title10693": "定时中间捕获事件",
  "title10694": "升级中间抛出事件",
  "title10695": "条件中间捕获事件",
  "title10696": "链接中间捕获事件",
  "title10697": "链接中间抛出事件",
  "title10698": "补偿中间抛出事件",
  "title10699": "信号中间捕获事件",
  "title10700": "信号中间抛出事件",
  "title10701": "消息结束事件",
  "title10702": "定时结束事件",
  "title10703": "错误结束事件",
  "title10704": "取消结束事件",
  "title10705": "补偿结束事件",
  "title10706": "信号结束事件",
  "title10707": "终止结束事件",
  "title10708": "消息边界事件",
  "title10709": "消息边界事件（非中断）",
  "title10710": "定时边界事件",
  "title10711": "定时边界事件（非中断）",
  "title10712": "升级边界事件",
  "title10713": "升级边界事件（非中断）",
  "title10714": "条件边界事件",
  "title10715": "条件边界事件（非中断）",
  "title10716": "错误边界事件",
  "title10717": "取消边界事件",
  "title10718": "信号边界事件",
  "title10719": "信号边界事件（非中断）",
  "title10720": "补偿边界事件",
  "title10721": "互斥网关",
  "title10722": "并行网关",
  "title10723": "相容网关",
  "title10724": "复杂网关",
  "title10725": "事件网关",
  "title10726": "转运",
  "title10727": "子流程",
  "title10728": "事件子流程",
  "title10729": "折叠池",
  "title10730": "展开池",
  "title10731": "在",
  "title10732": "里，",
  "title10733": "没有父类",
  "title10734": "没有指定的形状类型",
  "title10735": "流元素必须是池",
  "title10736": "参与者的子类",
  "title10737": "子道大于",
  "title10738": "元素不能为空",
  "title10739": "流程图不符合",
  "title10740": "规范",
  "title10741": "没有可展示的流程图",
  "title10742": "没有可展示的流程",
  "title10743": "协作",
  "title10744": "由",
  "title10745": "引用的",
  "title10746": "元素仍未绘制",
  "title10747": "已被渲染",
  "title10748": "导入",
  "title10749": "失败",
  "title10750": "详情",
  "title10751": "创建者",
  "title10752": "持续异步",
  "title10753": "工作配置",
  "title10754": "工作优先级",
  "title10755": "重试时间周期",
  "title10756": "文档",
  "title10757": "元素文档",
  "title10758": "历史配置",
  "title10759": "历史的生存时间",
  "title10760": "业务",
  "title10761": "校验",
  "title10762": "属性",
  "title10763": "执行监听",
  "title10764": "必须提供一个值",
  "title10765": "资源",
  "title10766": "字段注入",
  "title10767": "扩展",
  "title10768": "输入",
  "title10769": "输出",
  "title10770": "输入参数",
  "title10771": "输出参数",
  "title10772": "参数",
  "title10773": "定时器定义类型",
  "title10774": "定时器定义",
  "title10775": "持续",
  "title10776": "升级",
  "title10777": "错误",
  "title10778": "链接名称",
  "title10779": "条件名称",
  "title10780": "变量名称",
  "title10781": "变量事件",
  "title10782": "多个变量事件以逗号隔开",
  "title10783": "等待完成",
  "title10784": "活动参考",
  "title10785": "版本标签",
  "title10786": "可执行文件",
  "title10787": "扩展任务配置",
  "title10788": "任务优先级",
  "title10789": "外部",
  "title10790": "连接器",
  "title10791": "必须配置连接器",
  "title10792": "连接器编号",
  "title10793": "实现方式",
  "title10794": "结果变量",
  "title10795": "主题",
  "title10796": "配置连接器",
  "title10797": "代理人",
  "title10798": "候选用户",
  "title10799": "候选组",
  "title10800": "到期时间",
  "title10801": "跟踪日期",
  "title10802": "优先级",
  "title10803": "跟踪日期必须符合",
  "title10804": "表达式，如：",
  "title10805": "或者一个",
  "title10806": "标准日期，如：2015-06-26",
  "title10807": "变量",
  "title10808": "候选人起动器配置",
  "title10809": "候选人起动器组",
  "title10810": "这映射到流程定义键。",
  "title10811": "候选人起动器的用户",
  "title10812": "指定多个用户作为逗号分隔的列表。",
  "title10813": "启动",
  "title10814": "指定多个组作为逗号分隔的列表。",
  "title10815": "消息实例",
  "title10816": "外部资源",
  "title10817": "跟踪时间",
  "title10818": "友情提示：任务的分配规则，使用",
  "title10819": "流程模型",
  "title10820": "下的【分配规则】替代，提供指定角色、部门负责人、部门成员、岗位、工作组、自定义脚本等",
  "title10821": "种维护的任务分配维度，更加灵活！",
  "title10822": "ล่าสุด",
  "title10823": "ยอดขายรวมในวันที่ผ่านมา",
  "title10824": "วันสูงสุดสำหรับการจัดกลุ่มต้องไม่ว่าง",
  "title10825": "จำนวนเงินคืน",
  "title10826": "โปรดป้อนชื่อผู้ให้บริการบุคคลที่สาม",
  "title10827": "การส่งแบบมวล",
  "title10828": "ดึงสินค้าที่เลือกไป",
  "title10829": "ดึงสินค้าทั้งหมด",

  "magellan_600001": "อุปกรณ์ของบ้าน",
  "magellan_600024": "เครื่องใช้ครัว",
  "magellan_600154": "ปักษิณาและตกแต่งนุ้ฐาน",
  "magellan_600942": "เครื่องใช้ไฟฟ้าของครัว",
  "magellan_601152": "เครื่องแบบหญิงและเสื้อผ้าใน",
  "magellan_601303": "แฟชั่นมุสลิม",
  "magellan_601352": "รองเท้า",
  "magellan_601450": "ความงามและการดูแลตัวเอง",
  "magellan_601739": "โทรศัพท์และอิเล็กทรอนิกส์",
  "magellan_601755": "คอมพิวเตอร์และอุปกรณ์สำนักงาน",
  "magellan_602118": "อุปกรณ์สำหรับสัตว์เลี้ยง",
  "magellan_602284": "เด็กและการตั้งครรภ์",
  "magellan_603014": "กีฬาและกลางแจ้ง",
  "magellan_604206": "ของเล่นและ业余兴趣",
  "magellan_604453": "เฟอร์นิเจอร์",
  "magellan_604579": "เครื่องมือและฮาร์ดแวร์",
  "magellan_604968": "การปรับปรุงบ้าน",
  "magellan_605196": "ยานพาหนะและมอเตอร์ไซค์",
  "magellan_605248": "เครื่องประดับแฟชั่น",
  "magellan_700437": "อาหารและเครื่องดื่ม",
  "magellan_700645": "สุขภาพ",
  "magellan_801928": "หนังสือ, แมกเครน์ และเสียง",
  "magellan_802184": "แฟชั่นเด็ก",
  "magellan_824328": "เครื่องแบบชายและเสื้อผ้าใน",
  "magellan_824584": "กระเป๋าและกระเป๋าเดินทาง",
  "magellan_834312": "สินค้าเสมือน",
  "magellan_856720": "สินค้ามือสอง",
  "magellan_951432": "ของที่เก็บครอง",
  "magellan_953224": "เครื่องประดับและผลิตภัณฑ์ที่ได้มา",
  // 2024.3.5 物流功能新增翻译
  "可按物流方式分别设置揽收方式": "สามารถตั้งค่าวิธีการรับโดยแยกตามวิธีการขนส่งได้",
  "物流方式": "วิธีการขนส่ง",
  "SKU编号": "SKUหมายเลข",
  "物流名称": "ชื่อการขนส่ง",
  "状态": "สถานะ",
  "操作": "ดำเนินการ",
  "设置": "การตั้งค่า",
  "揽货方式": "วิธีการรับของ",
  "快递取件": "การรับส่งของด่วน",
  "自行寄件": "การส่งสินค้าด้วยตนเอง",
  "请选择揽收方式": "โปรดเลือกวิธีการรับของ",
  "全部": "ทั้งหมด",
  "印尼": "อินโดนีเซีย",
  "马来西亚": "มาเลเซีย",
  "菲律宾": "ฟิลิปปินส์",
  "泰国": "ประเทศไทย",
  "越南": "เวียดนาม",
  "中国台湾":"ไทเปรียบไลน์",
  "新加坡":"ซิมบัก",
  "线上物流": "การขนส่งออนไลน์",
  "操作成功！": "ดำเนินการเสร็จสิ้นแล้ว!",
  "打印拣货单(含汇总)": "พิมพ์การเลือกสินค้า(พร้อมสรุป)",
  "商品SKU": "SKU สินค้า",
  "产品SKU": "SKU ร้านค้า",
  "包裹数量": "จำนวนพัสดุ",
  "SKU数量": "จำนวน SKU",
  "货品总数": "จำนวนสินค้าทั้งหมด",
  "打印时间": "เวลาการพิมพ์",
  "货品信息": "ข้อมูลสินค้า",
  "变种名称": "ชื่อตัวแปร",
  "货架位": "ตำแหน่งชั้นวาง",
  "数量": "จำนวน",
  "包裹信息": "ข้อมูลพัสดุ",
  "包裹号": "หมายเลขพัสดุ",
  "总计": "รวมทั้งหมด",

  "打印":"พิมพ์",
  "拣货单":"รายการเลือกสินค้า",
  "包裹数量":"จำนวนพัสดุ",
  "货品总数":"รวมสินค้า",
  "打单时间":"เวลาการพิมพ์",
  "货品信息":"ข้อมูลสินค้า",
  "买家信息":"ข้อมูลผู้ซื้อ",
  "备注信息":"หมายเหตุ",
  "总数":"รวมทั้งหมด",
  "订单号":"หมายเลขออร์เดอร์",
  "运单号":"หมายเลขส่งของ",
  "物流方式":"วิธีการขนส่ง",
  "店铺名称":"ชื่อร้าน",
  "货品总数":"จำนวนสินค้าทั้งหมด",
  "打印面单和配货单":"พิมพ์ใบพัสดุและรายการแพ็คคิ่ง",
  "稳定出单的商品，销量下滑":"คำสั่งที่เสถียร การขายลดลง",
  "爆款商品":"สินค้าขายดี",
  "出单较为稳定，销量暴涨的潜力商品":"สินค้าที่มีศักยภาพ กับคำสั่งที่เสถียรและการขายกระโดดขึ้น",
  "稳定出单的商品，销量持续上涨":"สินค้าที่มีคำสั่งที่เสถียรและการขายเพิ่มขึ้นอย่างต่อเนื่อง",
  "上架时间长，销量较低":"เวลาข้างบนชั้นนาน การขายต่ำ",
  "高退货率":"อัตราการคืนสูง",
  "高差评率":"อัตราการรีวิวลบสูง",
  "低回复率":"อัตราการตอบสนองต่ำ",
  "取消率过高":"อัตราการยกเลิกสูง",
  "样品费用":"ค่าตัวอย่าง",
  "空包费用":"ค่าบรรจุภัณฑ์ว่างเปล่า",
  "测评佣金":"ค่าคอมมิชชันการประเมิน",
  "测评付款佣金/手续费":"ค่าคอมมิชชันการจ่ายการประเมิน / ค่าธรรมเนียม",
  "修改自定义费用":"ปรับปรุงค่ากำหนดเอง",
  "小件包裹":"บรรจุภัณฑ์ขนาดเล็ก",
  "测试刷单":"การทดสอบการเบริ๊ช",
  "漏单":"คำสั่งที่ขาดไป",
  "预售":"การขายล่วงหน้า",
  "海运":"ขนส่งทางเรือ",
  "不发":"ไม่ได้ส่ง",
  "补寄":"ส่งอีกครั้ง",
  "已采":"สั่งซื้อแล้ว",
  "未分组":"ไม่มีกลุ่ม",
  "测评佣金":"ค่าคอมมิชชันการประเมิน",
  "测评付款佣金/手续费":"ค่าคอมมิชชัน/ค่าธรรมเนียมการชำระเงินในการประเมิน",
  "提现支出":"ค่าใช้จ่ายการถอนเงิน",
  "线下售后退款":"การคืนเงินหลังการขายแบบออฟไลน์",
  "线下补发运费":"ค่าส่งสินค้าใหม่ออฟไลน์",
  "线下补发成本":"ค่าใช้จ่ายในการส่งสินค้าใหม่ออฟไลน์",
  "退件处理费":"ค่าประมวลผลการคืนสินค้า",
  "活动包裹费用":"ค่าบริการแพคเกจกิจกรรม",
  "其他杂费":"ค่าใช้จ่ายเบ็ดเตล็ดอื่นๆ",
  "仓库费用":"ค่าใช้จ่ายคลังสินค้า",
  "未推送":"ยังไม่ผลักดัน",
  "推送中":"การผลักดัน",
  "推送失败":"การผลักดันล้มเหลว",
  "待盘点":"คลังสินค้าที่รอการตรวจสอบ",
  "按仓库":"ตามคลังสินค้า",
  "按商品SKU":"ตาม SKU สินค้า",
  "调拨计划":"แผนการจัดสรร",
  "不分摊":"ไม่แบ่ง",
  "按价格分摊":"แบ่งตามราคา",
  "按数量分摊":"แบ่งตามจำนวน",
  "按体积分摊":"แบ่งตามปริมาตร",
  "待出库":"คลังสินค้าที่รอการจัดส่งออก",
  "已出库":"การส่งออก",
  "采购入库":"ใบรับสินค้า",
  "销售出库":"ปัญหาการขาย",
  "手动入库":"การรับด้วยมือ",
  "手动出库":"การออกบิลด้วยมือ",
  "回滚出库":"การย้อนกลับ",
  "导入入库":"การนำเข้า",
  "盘点入库":"การรับคลังสินค้า",
  "盘点出库":"ปัญหาสินค้าคงคลัง",
  "调拨入库":"การรับสินค้าที่ได้รับการจัดสรร",
  "调拨出库":"ปัญหาการจัดสรร",
  "取消调拨入库":"ยกเลิกการรับสินค้าที่ได้รับการจัดสรร",
  "三方仓同步入库":"การรับสินค้าจากคลังสินค้าบุคคลที่สาม",
  "三方仓同步出库":"ปัญหาการส่งสินค้าจากคลังสินค้าบุคคลที่สาม",
  "退货入库":"การรับสินค้าที่คืน",
  "到付":"การจ่ายเงินปลายทาง",
  "现结":"จ่ายตอนนี้",
  "分期付款":"การชำระเงินแบบผ่อนชำระ",
  "周结":"การตั้งบัญชีรายสัปดาห์",
  "月结":"การตั้งบัญชีรายเดือน",
  "到期支付":"การจ่ายเงินเมื่อครบกำหนด",
  "未扣除广告费后的利润":"กำไรสุทธิหลังหักค่าโฆษณา",
  "排除测评搁置取消未付款的订单量":"ยกเว้นการประเมิน + การจัดเก็บ + การยกเลิก + ปริมาณการสั่งซื้อที่ยังไม่ได้ชำระเงิน",
  "排除测评搁置取消未付款的销量":"ยกเว้นการประเมิน + การจัดเก็บ + การยกเลิก + ปริมาณการขายที่ยังไม่ได้ชำระเงิน",
  "有效销售额释义":"ยอดขายที่มีประสิทธิภาพ = การชำระเงินของผู้ซื้อ + การสนับสนุนจากแพลตฟอร์ม + การสนับสนุนค่าขนส่ง (ไม่รวมยอดขายที่ยังไม่ได้ชำระเงิน)",
  "每次最多可选取50条数据进行打印。":"สามารถเลือกได้สูงสุด 50 บันทึกทุกครั้งสำหรับการพิมพ์",
  "导出已选商品": "ส่งออกสินค้าที่เลือก",
  "导出全部页商品": "ส่งออกสินค้าในหน้าทั้งหมด",
  "导出SKU配对关系": "ส่งออกคู่สินค้า SKU",
  "显示下架商品":"แสดงสินค้าที่ไม่ขายออกไป",
  "扫描或输入包裹号": "สแกนหรือป้อนหมายเลขพัสดุ / หมายเลขใบส่งของ",
  "拆单成功": "แยกออกสำเร็จ",
  "已确定订单销售额除以已确定订单订单数": "ยอดขายรวมของคำสั่งซื้อที่ยืนยันแบ่งด้วยจำนวนคำสั่งซื้อที่ยืนยันทั้งหมด",
  "访客数": "ผู้เยี่ยมชม",
  "订单数": "คำสั่งซื้อ",
  "销售额": "ยอดขาย",
  "环比":"เดือนต่อเดือน",
  "本地状态是退货退款中和退货订单或退款金额大于0的退款金额":"สถานะท้องถิ่นคือการส่งคืนและการคืนเงินหรือจำนวนเงินคืนมากกว่า 0.",
  "退款金额=本地状态是退货退款中和退货订单或退款金额大于0的退款金额":"จำนวนเงินคืนเท่ากับจำนวนเงินคืนเมื่อสถานะท้องถิ่นคือการส่งคืนและการคืนเงินหรือจำนวนเงินคืนมากกว่า 0.",
  "退货订单量=本地状态是退货退款中和退货订单或退款金额大于0的订单数量":"ปริมาณการสั่งซื้อส่งคืนเท่ากับปริมาณการสั่งซื้อเมื่อสถานะท้องถิ่นคือการส่งคืนและการคืนเงินหรือจำนวนเงินคืนมากกว่า 0.",
  "有效订单量=排除测评+搁置+取消+未付款的订单量":"ปริมาณการสั่งซื้อที่ถูกต้องเท่ากับปริมาณการสั่งซื้อที่ยกเว้นการประเมินค่า การพัก การยกเลิก และการไม่ชำระเงิน",
  "有效销售额=排除测评+搁置+取消+未付款的销售额":"จำนวนเงินขายที่ถูกต้องเท่ากับจำนวนเงินขายที่ยกเว้นการประเมิน การพัก การยกเลิก และการไม่ชำระเงิน",
  "销售额=已下单的总销售额（未取消订单销售额+取消订单销售额 ）":"จำนวนเงินขายเท่ากับจำนวนเงินขายรวมของคำสั่งที่สั่งซื้อ (จำนวนเงินขายของคำสั่งที่ยกเลิก + จำนวนเงินขายของคำสั่งที่ยกเลิก)",
  "订单量=已下单的订单数量":"ปริมาณการสั่งซื้อเท่ากับปริมาณการสั่งซื้อที่สั่งซื้อ",
  "共{cont}条":"รวม {cont} รายการ",
  "发现新版本，是否更新":"พบเวอร์ชันใหม่ อัปเดตตอนนี้หรือไม่?",
  "高级版":"เวอร์ชันขั้นสูง",
  "您有62个店铺授权过期，请点击重新授权":"คุณมีสิทธิ์ในร้านค้า 62 รายการที่หมดอายุ โปรดคลิกเพื่อรับอนุญาตใหม่",
  "亏损商品":"สินค้าที่เสียเงิน",
  "利润":"กำไร",
  "利润偏低":"กำไรต่ำ",
  "利润率":"อัตรากำไร",
  "销量暴跌":"ปริมาณการขายลดลงอย่างมาก",
  "稳定出单的商品，销量下滑":"สินค้าที่สั่งซื้อสม่ำเสมอ ปริมาณการขายลดลง",
  "销量环比":"เปรียบเทียบปริมาณการขาย",
  "出单较为稳定，销量暴涨的潜力商品":"สินค้าที่มีคำสั่งที่เรียบร้อย ซึ่งมีโอกาสเพิ่มขึ้นอย่างชัดเจนในปริมาณการขาย",
  "稳定出单的商品，销量持续上涨":"สินค้าที่มีคำสั่งที่เรียบร้อย ปริมาณการขายกำลังเพิ่มขึ้น",
  "销量环比":"เปรียบเทียบปริมาณการขาย (เดือนที่แล้ว - เดือนนี้)",
  "上架时间长，销量较低":"เวลาบนชั้นนาน ปริมาณการขายต่ำ",
  "销量":"ปริมาณการขาย",
  "高退货率":"อัตราการส่งคืนสูง",
  "退货率":"อัตราการส่งคืน",
  "高差评率":"อัตราการตอบกลับลบสูง",
  "差评率":"อัตราการตอบกลับลบ",
  "低回复率":"อัตราการตอบกลับต่ำ",
  "回复率":"อัตราการตอบกลับ",
  "回应时间":"เวลาการตอบสนอง",
  "回应时间>1小时":"เวลาการตอบสนอง >1 ชั่วโมง",
  "逾期发货率过高":"อัตราการส่งสินค้าล่าช้ามาก",
  "逾期发货率":"อัตราการส่ง",
  "取消率过高":"อัตราการยกเลิกสูง",
  "取消率":"อัตราการยกเลิก",
  "联系我们":"ติดต่อเรา",
  "修改自定义费用":"แก้ไขค่าที่กำหนดเอง",
  "快递取件":"รับแบบด่วน",
  "自行取件":"รับด้วยตนเอง",
  "未分组":"ไม่มีกลุ่ม",
  "更新时间":"เวลาปรับปรุง",
  "默认仓库":"คลังค่าเริ่มต้น",
  "商品SKU推送失败:2000035000: 授权失败":"SKU สินค้าผิดพลาด:2000035000: การอนุญาตล้มเหลว",
  "商品SKU推送失败:2000035000: 授权失败,请检查appKey和appSecret是否正确":"สินค้าSKU ส่งล้มเหลว: 2000035000: การอนุญาตล้มเหลว โปรดตรวจสอบว่า appKey และ appSecret ถูกต้องหรือไม่",
  "调拨计划":"แผนจัดสรร",
  "在途中":"อยู่ระหว่างการเดินทาง",
  "部分收货":"ได้รับบางส่วน",
  "已完成":"เสร็จสมบูรณ์",
  "已取消":"ยกเลิก",
  "待出库":"รอการออกจากคลัง",
  "已出库":"จัดส่งแล้ว",
  "待出库":"รอการจัดส่ง",
  "已入库":"ได้รับแล้ว",
  "订单 销售出库1":"การส่งออกออร์เดอร์ขาย 1",
  "联系我们":"ติดต่อเรา",
  "采购订单推送失败:2000035000: 授权失败":"ส่งคำสั่งซื้อล้มเหลว: 2000035000: การอนุญาตล้มเหลว",
  "采购订单推送失败:2000035000: 客户SKU 不存在。":"ส่งคำสั่งซื้อล้มเหลว: 2000035000: SKU ของลูกค้าไม่มีอยู่",
  "采购订单推送失败:2000035000: Item not exist: sku = Testing Product K, name = null;":"ส่งคำสั่งซื้อล้มเหลว: 2000035000: สินค้าไม่มีอยู่: sku = Testing Product K, ชื่อ = null",
  "AI模板管理":"การจัดการเทมเพลต AI",
  "AI模板分类管理":"การจัดการหมวดหมู่เทมเพลต AI",
  "修改":"แก้ไข",
  "自由会话":"การสนทนาเสรี",
  "模板会话":"การสนทนาแม่แบบ",
  "shopee listing写作与优化6。":"เขียนและปรับปรุงรายการ Shopee 6",
  "根据提供的关键词、类目、商品卖点信息，为你生成地道流畅的语言表达，提升商品曝光量；使用客观和中立的语言，避免夸张和绝对化的词汇。":"สร้างประโยคภาษาที่แท้จริงและเรียบง่ายสำหรับคุณโดยใช้คำสำคัญที่ให้ไว้, หมวดหมู่, และจุดขายสินค้าที่ให้ไว้, เพื่อเสริมสร้างการแสดงผลสินค้า; ใช้ภาษาที่เป็นธรรมและเป็นกลาง, เพื่อหลีกเลี่ยงคำโฆษณาและคำสมบูรณ์บริบูรณ์",
  "商品特性的条数":"จำนวนคุณสมบัติของสินค้า",
  "商品关键词":"คำสำคัญสินค้า",
  "商品品牌":"แบรนด์สินค้า",
  "商品类目":"หมวดหมู่สินค้า",
  "商品卖点":"จุดขายสินค้า",
  "我的收藏":"รายการโปรดของฉัน",
  "知识库":"ฐานความรู้",
  "发现新版本，点击更新完成升级。体验新版本":"ค้นพบเวอร์ชันใหม่ คลิกเพื่ออัปเดตและเสร็จสิ้นการอัปเกรด",
  "系统消息":"ข้อความระบบ",
  "今日不再提醒":"ไม่ต้องเตือนในวันนี้",
  "去授权或删除":"ไปที่การให้สิทธิ์หรือลบ",
  "为避免使用及数据分析受影响，请尽快处理过期的店铺数据，您可以":"เพื่อหลีกเลี่ยงผลกระทบต่อการใช้งานและการวิเคราะห์ข้อมูล กรุณาดำเนินการจัดการข้อมูลร้านค้าที่หมดอายุโดยเร็วที่สุด คุณสามารถ",
  "已过期的店铺":"ร้านค้าที่หมดอายุ",
  "最多置顶5个":"สูงสุด 5",
  "综合分析":"การวิเคราะห์องค์ประกอบ",
  "受限页面":"หน้าจำกัด",
  "已下单的订单数量 （包含取消和退款订单）":"จำนวนคำสั่งซื้อที่สั่งซื้อแล้ว (รวมถึงคำสั่งซื้อที่ยกเลิกและคืนเงิน)",
  "已下单的用户数量（按买家姓名/邮箱去重）":"Số lượng khách hàng đã đặt hàng (loại bỏ bằng tên/email người mua)",
  "付款客户数":"จำนวนลูกค้าที่ชำระเงิน",
  "退款客户数":"จำนวนลูกค้าที่ขอคืนเงิน",
  "已退款的用户数（包含部分退款）":"จำนวนผู้ใช้ที่ได้รับเงินคืน (รวมถึงการคืนบางส่วน)",
  "已下单的用户数量（按买家姓名/邮箱去重）":"",
  "已取消订单数量":"จำนวนลูกค้าที่สั่งซื้อ (ไม่นับซ้ำตามชื่อ/อีเมลล์ของผู้ซื้อ)",
  "仅统计已付款的订单销售数量":"ปริมาณการขายเฉพาะสำหรับคำสั่งซื้อที่ชำระเงินแล้ว",
  "销售额/客户数":"ยอดขาย/จำนวนลูกค้า",
  "退款金额":"ยอดเงินคืน",
  "店铺销售详情":"รายละเอียดยอดขายร้าน",
  "店铺详情":"รายละเอียดร้านค้า",
  "商品销量统计":"สถิติยอดขายสินค้า",
  "非追加产品主包裹至少保留一个":"สินค้าที่ไม่ได้เพิ่มอย่างน้อยหนึ่งแพ็คหลัก",

  "批量添加标记":"เพิ่มแท็กเป็นกลุ่ม",
  "添加标记":"เพิ่มแท็ก",
  "选择标记":"เลือกแท็ก",
  "shopeeCoinsRedeemed":"Shopee Coins Redeemed"
}
