<template>
  <span>
    <svg-icon :icon-class="icon" :class-name="icon" />
    <span slot='title' class="name">{{ $t(`menu.${title}`) }}</span>
  </span>
</template>
<script>
export default {
  name: 'MenuItem',
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
