import Vue from 'vue'
import Router from 'vue-router'
/* Layout */
import Layout from '@/layout'
const originalPush = Router.prototype.replace
Router.prototype.replace = function replace(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    noCache: true                // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'   // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: (resolve) => require(['@/views/redirect'], resolve)
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true
  },
  {
    path: '/setArea',
    name: 'setArea',
    component: (resolve) => require(['@/views/setArea'], resolve),
    hidden: true
  },
  {
    path: '/printPickList',
    name: 'printPickList',
    component: (resolve) => require(['@/views/erp/shopOrder/printPickList'], resolve),
    hidden: true
  },
  {
    path: '/printPicklistSummary',
    name: 'printPicklistSummary',
    component: (resolve) => require(['@/views/erp/shopOrder/printPicklistSummary'], resolve),
    hidden: true
  },
  {
    path: '/printSheetList',
    name: 'printSheetList',
    component: (resolve) => require(['@/views/erp/shopOrder/printSheetList'], resolve),
    hidden: true
  },
  {
    path: '/printOrderList',
    name: 'printOrderList',
    component: (resolve) => require(['@/views/erp/shopOrder/printOrderList'], resolve),
    hidden: true
  },
  {
    path: '/system/printSetting',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [
      {
        path: 'index',
        component: (resolve) => require(['@/views/erp/printSetting'], resolve),
        name: 'printSetting',
        meta: {title: '打印设置', icon: 'goods'}
      },
      {
        path: 'customTemplate',
        component: (resolve) => require(['@/views/erp/printSetting/customTemplate'], resolve),
        name: 'customTemplate',
        meta: {title: '自定义面单', icon: 'goods'}
      },
    ]
  },
  {
    path: '/sso',
    component: (resolve) => require(['@/views/sso'], resolve),
    hidden: true
  },
  {
    path: '/social-login',
    component: (resolve) => require(['@/views/socialLogin'], resolve),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/error/404'], resolve),
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/error/401'], resolve),
    hidden: true
  },{
    path: '/',
    component: Layout,
    redirect: '/index/home',
    // hidden: true,
    meta:{
      title:'首页',
      icon:'home'
    }
  },{
    path: '/index',
    component: Layout,
    redirect: '/index/home',
    meta:{
      title:'首页',
      icon:'home'
    },
    children: [{
        path: 'home',
        component: (resolve) => require(['@/views/index'], resolve),
        name: '首页',
        meta:{title:'首页',icon:'homex', affix: true}
      },{
        path: 'noaccess',
        name: 'noaccess',
        meta: {title: '受限页面'},
        component: (resolve) => require(['@/views/error/noaccess'], resolve),
        hidden: true
      },{
        path: 'ad-no-auth',
        name: '未授权',
        meta: {title: '未授权'},
        component: (resolve) => require(['@/views/error/ad-no-auth'], resolve),
        hidden: true
      },{
        path: 'thali',
        component: (resolve) => require(['@/views/bi/thali/price'], resolve),
        name: '套餐',
        meta:{title:'套餐',icon:'homex'}
      // },{
      //   path: 'scan_package',
      //   component: (resolve) => require(['@/views/erp/shopOrder/scanPackage'], resolve),
      //   name: '扫描包装',
      //   meta:{title:'扫描包装',icon:'homex'}
      },
    ]
  }, {
    path: '/ai',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
        path: 'talk',
        component: (resolve) => require(['@/views/ai/knowledge/talk'], resolve),
        name: 'talk',
        meta: {title: '创作会话', icon: 'goods'}
      },
    ]
  }, {
    path: '/product-analyse',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
        path: 'detail/:type/:id',
        component: (resolve) => require(['@/views/erp/goodsAnalysis/components/detail'], resolve),
        name: 'goodsAnalysisDetail',
        meta: {title: '商品分析详情', icon: 'goods'}
      }
    ]
  }, {
    path: '/shop-sale',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
        path: 'detail/:id',
        component: (resolve) => require(['@/views/erp/data/diskFace'], resolve),
        name: 'ShopSaleDetail',
        meta: {title: '店铺销售详情',icon:'user'}
      }
    ]
  }, {
    path: '/ad',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
        path: 'detail/:id',
        component: (resolve) => require(['@/views/bi/ad/shopSale'], resolve),
        name: 'AdShopSale',
        meta: {title: '广告列表',icon:'user'}
      }
    ]
  },{
    path: '/supplier',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
        path: 'list/:id',
        component: (resolve) => require(['@/views/erp/supplier/detail'], resolve),
        name: 'SupplierDetail',
        meta: {title: '供应商关联商品',icon:'user'}
      }
    ]
  }, {
    path: '/notice',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
        path: 'list',
        component: (resolve) => require(['@/views/system/notice/list'], resolve),
        name: 'noticeList',
        meta: {title: '公告中心', icon: 'user'}
      }
    ]
  }, {
    path: '/user',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
        path: 'package',
        component: (resolve) => require(['@/views/bi/user/order-package'], resolve),
        name: 'Profile',
        meta: {title: '单量套餐', icon: 'user'}
      }
    ]
  }, {
    path: '/shop',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
        path: 'auth',
        component: (resolve) => require(['@/views/erp/shop/auth'], resolve),
        name: 'auth',
        meta: {title: '授权结果',icon:'none'}
      },{
        path: 'auth-fail',
        component: (resolve) => require(['@/views/erp/shop/auth-fail'], resolve),
        name: 'auth-fail',
        meta: {title: '授权失败',icon:'none'}
      },
    ]
  }, {
  //   path: '/data',
  //   component: Layout,
  //   hidden: true,
  //   redirect: 'noredirect',
  //   children: [{
  //       path: 'remark-report',
  //       component: (resolve) => require(['@/views/erp/data/remarkReport'], resolve),
  //       name: 'health',
  //       meta: {title: '账户健康',icon:'none'}
  //     }
  //   ]
  // }, {
  //   path: '/goods',
  //   component: Layout,
  //   hidden: true,
  //   redirect: 'noredirect',
  //   children: [{
  //       path: 'create',
  //       component: (resolve) => require(['@/views/erp/productSku/createPublish'], resolve),
  //       name: 'create',
  //       meta: {title: '商品刊登',icon:'none'}
  //     }
  //   ]
  // }, {
    path: '/dict',
    component: Layout,
    hidden: true,
    children: [{
        path: 'type/data/:dictId(\\d+)',
        component: (resolve) => require(['@/views/system/dict/data'], resolve),
        name: 'Data',
        meta: {title: '字典数据', icon: '', activeMenu: '/system/dict'}
      }
    ]
  }, {
    path: '/job',
    component: Layout,
    hidden: true,
    children: [{
        path: 'log',
        component: (resolve) => require(['@/views/infra/job/log'], resolve),
        name: 'JobLog',
        meta: {title: '调度日志', activeMenu: '/infra/job'}
      }
    ]
  }, {
    path: '/codegen',
    component: Layout,
    hidden: true,
    children: [{
        path: 'edit/:tableId(\\d+)',
        component: (resolve) => require(['@/views/infra/codegen/editTable'], resolve),
        name: 'GenEdit',
        meta: {title: '修改生成配置', activeMenu: '/infra/codegen'}
      }
    ]
  }, {
    path: '/bpm',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
        path: 'oa/leave/create',
        component: (resolve) => require(['@/views/bpm/oa/leave/create'], resolve),
        name: '发起 OA 请假',
        meta: {title: '发起 OA 请假', icon: 'form', activeMenu: '/bpm/oa/leave'}
      }, {
        path: 'oa/leave/detail',
        component: (resolve) => require(['@/views/bpm/oa/leave/detail'], resolve),
        name: '查看 OA 请假',
        meta: {title: '查看 OA 请假', icon: 'view', activeMenu: '/bpm/oa/leave'}
      }
    ]
  }, {
    path: '/bpm',
    component: Layout,
    hidden: true,
    children: [{
        path: 'manager/form/edit',
        component: (resolve) => require(['@/views/bpm/form/formEditor'], resolve),
        name: '流程表单-编辑',
        meta: {title: '流程表单-编辑', activeMenu: '/bpm/manager/form'}
      }, {
        path: 'manager/definition',
        component: (resolve) => require(['@/views/bpm/definition/index'], resolve),
        name: '流程定义',
        meta: {title: '流程定义', activeMenu: '/bpm/manager/model'}
      }, {
        path: 'manager/model/design',
        component: (resolve) => require(['@/views/bpm/model/modelEditor'], resolve),
        name: '设计流程',
        meta: {title: '设计流程', activeMenu: '/bpm/manager/model'}
      }, {
        path: 'process-instance/create',
        component: (resolve) => require(['@/views/bpm/processInstance/create'], resolve),
        name: '发起流程',
        meta: {title: '发起流程', activeMenu: '/bpm/task/my'}
      }, {
        path: 'process-instance/detail',
        component: (resolve) => require(['@/views/bpm/processInstance/detail'], resolve),
        name: '流程详情',
        meta: {title: '流程详情', activeMenu: '/bpm/task/my'}
      }
    ]
  },
  {
    path: '/report-center',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [
      {
        path: 'detail/shop',
        component: (resolve) => require(['@/views/bi/reportCenter/components/shopData'], resolve),
        name: 'reportDetailShop',
        meta: {title: '店铺利润报表', icon: 'goods', keepAlive: true}
      },
      {
        path: 'detail/performance',
        component: (resolve) => require(['@/views/bi/reportCenter/components/performanceData'], resolve),
        name: 'reportDetailperformance',
        meta: {title: '业绩利润报表', icon: 'goods', keepAlive: true}
      },
      {
        path: 'detail/shopeeGoods',
        component: (resolve) => require(['@/views/bi/reportCenter/components/shopeeGoodsData'], resolve),
        name: 'reportDetailShopeeGoods',
        meta: {title: 'Shopee商品数据表', icon: 'goods', keepAlive: true}
      },
      {
        path: 'detail/shopeeShop',
        component: (resolve) => require(['@/views/bi/reportCenter/components/shopeeShopData'], resolve),
        name: 'reportDetailShopeeShop',
        meta: {title: 'Shopee店铺数据表', icon: 'goods', keepAlive: true}
      }
    ]
  },
  {
    path: '/product-sku',
    component: Layout,
    hidden: true,
    children:[
      {
        path: 'InventoryReport_detail',
        component: (resolve) => require(['@/views/erp/InventoryReport/detail'], resolve),
        name: 'InventoryReportDetail',
        meta: {title: '进销存详情', icon: '', keepAlive: true}
      },
    ]
  },
  {
    path: '/master',
    component: Layout,
    hidden: true,
    children:[
      {
        path: 'masterDetail/:id/:uid',
        component: (resolve) => require(['@/views/crm/masterManagement/masterDetail'], resolve),
        name: 'masterDetail',
        meta: {title: '达人详情', icon: '', keepAlive: true}
      },
    ]
  },
  {
    path: '/system',
    component: Layout,
    hidden: true,
    children:[
      {
        path: 'costom-fee',
        component: (resolve) => require(['@/views/erp/costCenter/costomFee'], resolve),
        name: 'costom-fee',
        meta: {title: '自定义费用', icon: '', keepAlive: true}
      },
    ]
  },
  {
    path: '/crm',
    component: Layout,
    hidden: true,
    children:[
      {
        path: 'shop-detail',
        component: (resolve) => require(['@/views/crm/cooperate/shopDetail'], resolve),
        name: 'shopDetail',
        meta: {title: '店铺详情', icon: ''}
      },
      {
        path: 'cooperation-detail',
        component: (resolve) => require(['@/views/crm/cooperate/cooperationDetail'], resolve),
        name: 'cooperationDetail',
        meta: {title: '合作单详情', icon: ''}
      },
      {
        path:'goods/detail/:id',
        component:(resolve) => require(['@/views/crm/shop&goods/goodsDetail'], resolve),
        name: 'goodsDetail',
        meta: {title: '商品分析详情', icon: ''}
      },
      {
        path: 'cooperation-detail-simple',
        component: (resolve) => require(['@/views/crm/CRM/cooperate/cooperationDetail'], resolve),
        name: 'cooperationDetailSimple',
        meta: {title: '合作单详情', icon: ''}
      },
      {
        path:'productDetail/:id',
        component:(resolve) => require(['@/views/crm/hot/productHot/productDetail'], resolve),
        name: 'productDetail',
        meta: {title: '商品详情', icon: ''}
      },
      {
        path: 'favorite/detail',
        component: (resolve) => require(['@/views/crm/emailInvitation/favorite/favorites_details.vue'], resolve),
        name: 'favoriteDetail',
        meta: {title: '收藏夹详情', icon: 'goods'}
      }
    ]
  }
]

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default new Router({
  base: process.env.VUE_APP_APP_NAME ? process.env.VUE_APP_APP_NAME : "/",
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({y: 0}),
  routes: constantRoutes
})
