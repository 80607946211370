// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".app-wrapper:after {\n  content: \"\";\n  display: table;\n  clear: both;\n}\n.app-wrapper.hideSidebar .sidebar-container {\n  width: 52px;\n  overflow: hidden;\n}\n.app-wrapper.hideSidebar .main-container {\n  margin-left: 52px;\n}\n.app-wrapper .el-breadcrumb {\n  display: inline-block;\n  font-size: 12px;\n  line-height: 50px;\n}\n.app-wrapper .el-breadcrumb .el-breadcrumb__inner,\n.app-wrapper .el-breadcrumb .el-breadcrumb__inner a {\n  font-weight: 400 !important;\n  color: #727272;\n}\n.app-wrapper.mobile.openSidebar {\n  position: fixed;\n  top: 0;\n}\n.drawer-bg {\n  background: #000;\n  opacity: 0.3;\n  width: 100%;\n  top: 0;\n  height: 100%;\n  position: absolute;\n  z-index: 999;\n}\n.fixed-header {\n  position: fixed;\n  top: 0;\n  right: 0;\n  z-index: 9;\n  width: calc(100% - 242px);\n  -webkit-transition: width 0.28s;\n  transition: width 0.28s;\n}\n.hideSidebar .fixed-header {\n  width: calc(100% - 52px);\n}\n.sidebarHide .fixed-header {\n  width: calc(100%);\n}\n.mobile .fixed-header {\n  width: 100%;\n}", ""]);
// Exports
exports.locals = {
	"menuColor": "rgba(255, 255, 255, 0.65)",
	"menuLightColor": "rgba(0, 0, 0, 0.7)",
	"menuColorActive": "#fff",
	"menuBackground": "#001529",
	"menuLightBackground": "#ffffff",
	"subMenuBackground": "#000c17",
	"subMenuHover": "#001528",
	"sideBarWidth": "242px",
	"logoTitleColor": "#ffffff",
	"logoLightTitleColor": "#001529"
};
module.exports = exports;
