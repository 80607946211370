
 
import store from '@/store'

export default {
  inserted(el, binding, vnode) {
    const kolAuth = store.getters && store.getters.kolAuth;
    if (!kolAuth.moveAuthButton) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  }
}
