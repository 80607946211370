
import kolConfig from '@/api/crm/kolConfig'
import kolAuth from '@/api/crm/kolAuth'

const state = {
  kolConfig: {},
  kolAuth: {},
  missionCenter:null,
}

const mutations = {
  SET_KOL_CONFIG: (state, kolConfig) => {
    state.kolConfig = kolConfig
  },
  SET_KOL_AUTH: (state, kolAuth) => {
    state.kolAuth = kolAuth
  }
}

const actions = {
  getConfig({ commit }) {
    kolConfig.getConfig().then(res => {
      commit('SET_KOL_CONFIG', res.data)
    })
  },
  getKolAuth({ commit }) {
    kolAuth.getKolAuth().then(res => {
      commit('SET_KOL_AUTH', res.data)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
