var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "navbar", staticClass: "navbar" }, [
    _c(
      "div",
      { staticClass: "nav-left" },
      [_vm.needTagsView ? _c("tags-view") : _vm._e()],
      1
    ),
    _c(
      "div",
      { staticClass: "right-menu" },
      [
        _vm.device !== "mobile"
          ? [
              false
                ? _c(
                    "div",
                    {
                      staticClass: "right-menu-item",
                      on: {
                        click: function ($event) {
                          _vm.showClockScreen = !_vm.showClockScreen
                        },
                      },
                    },
                    [_c("div", { staticClass: "icon icon1" })]
                  )
                : _vm._e(),
              false
                ? _c(
                    "el-dropdown",
                    {
                      staticClass: "avatar-container",
                      attrs: { disabled: "" },
                      on: { command: _vm.changeLang },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "right-menu-item",
                          staticStyle: { "font-weight": "500" },
                        },
                        [_vm._v(_vm._s(_vm.language.toUpperCase()))]
                      ),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c("el-dropdown-item", { attrs: { command: "en" } }, [
                            _vm._v("English"),
                          ]),
                          _c(
                            "el-dropdown-item",
                            { attrs: { divided: "", command: "id" } },
                            [_vm._v("Indonesia")]
                          ),
                          _c(
                            "el-dropdown-item",
                            { attrs: { divided: "", command: "vn" } },
                            [_vm._v("Tiếng Việt")]
                          ),
                          _c(
                            "el-dropdown-item",
                            { attrs: { divided: "", command: "th" } },
                            [_vm._v("ไทย")]
                          ),
                          _c(
                            "el-dropdown-item",
                            { attrs: { divided: "", command: "cn" } },
                            [_vm._v("中文")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-popover",
                {
                  attrs: {
                    "popper-class": "concat-popover",
                    placement: "bottom-end",
                    offset: 45,
                    trigger: "hover",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "166px",
                        height: "196px",
                        display: "flex",
                        "align-items": "center",
                        "flex-direction": "column",
                        "line-height": "1",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "600",
                            "font-size": "14px",
                            color: "#333",
                            margin: "20px 0 15px 0",
                          },
                        },
                        [_vm._v("实时掌握店铺销售情况")]
                      ),
                      _c("img", {
                        staticStyle: { width: "100px", height: "100px" },
                        attrs: { src: require("@/assets/images/miniCode.png") },
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "400",
                            "font-size": "12px",
                            color: "#333333",
                            margin: "15px 0 20px 0",
                          },
                        },
                        [_vm._v("微信小程序")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "flex-row",
                      staticStyle: {
                        background: "#F6F7FB",
                        padding: "11px 15px",
                        "margin-left": "10px",
                        "border-radius": "19px",
                        cursor: "pointer",
                      },
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "16px",
                          height: "16px",
                          "margin-right": "4px",
                        },
                        attrs: { src: require("@/assets/images/mini.png") },
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "400",
                            "font-size": "12px",
                            color: "#666666",
                          },
                        },
                        [_vm._v("小程序")]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "right-menu-item",
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/notice/list")
                    },
                  },
                },
                [
                  _c("el-badge", { attrs: { "is-dot": _vm.unreadCnt > 0 } }, [
                    _c("div", { staticClass: "icon icon2" }),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "right-menu-item",
                  on: { click: _vm.handleHelpClick },
                },
                [_c("div", { staticClass: "icon icon3" })]
              ),
              false
                ? _c("div", { staticClass: "right-menu-item" }, [
                    _c("div", { staticClass: "icon icon4" }),
                  ])
                : _vm._e(),
            ]
          : _vm._e(),
        _c(
          "el-dropdown",
          { staticClass: "avatar-container" },
          [
            _c("div", { staticClass: "avatar-wrapper" }, [
              _c("div", { staticClass: "right-menu-item" }, [
                _c("div", { staticClass: "user-nickname" }, [
                  _vm._v(_vm._s((_vm.nickname || "").slice(-2))),
                ]),
              ]),
              _c("i", { staticClass: "el-icon-caret-bottom" }),
            ]),
            _c(
              "el-dropdown-menu",
              {
                staticClass: "nav-user-dropdown",
                attrs: { slot: "dropdown" },
                slot: "dropdown",
              },
              [
                _c(
                  "div",
                  { staticClass: "user-type" },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("title10251")) +
                          ":" +
                          _vm._s(_vm.myPackage.packageName)
                      ),
                    ]),
                    _c(
                      "el-button",
                      {
                        staticClass: "shandian",
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.jump("/user/package")
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("title10252")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "router-link",
                  { attrs: { to: "/system/user-info?state=0" } },
                  [
                    _c("el-dropdown-item", [
                      _vm._v(_vm._s(_vm.$t("title10253"))),
                    ]),
                  ],
                  1
                ),
                _c(
                  "router-link",
                  { attrs: { to: "/system/user-info?state=2" } },
                  [
                    _c("el-dropdown-item", [
                      _vm._v(_vm._s(_vm.$t("title10254"))),
                    ]),
                  ],
                  1
                ),
                _c(
                  "router-link",
                  { attrs: { to: "/system/user-info?state=3" } },
                  [
                    _c("el-dropdown-item", [
                      _vm._v(_vm._s(_vm.$t("title10255"))),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-dropdown-item",
                  {
                    nativeOn: {
                      click: function ($event) {
                        return _vm.logout($event)
                      },
                    },
                  },
                  [
                    _vm.isRunas()
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("title10256")))])
                      : _c("span", [_vm._v(_vm._s(_vm.$t("title10257")))]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showClockScreen,
            expression: "showClockScreen",
          },
        ],
        staticClass: "clock-screen",
      },
      [
        _c("div", { staticClass: "cover" }, [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("div", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.$t("title10258"))),
              ]),
              _c("div", { staticClass: "user" }, [_vm._v("sun***")]),
              _c(
                "el-form",
                { staticClass: "myForm", attrs: { size: "small" } },
                [
                  _c(
                    "div",
                    { staticClass: "inline" },
                    [
                      _c(
                        "el-form-item",
                        { staticClass: "join" },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "join-left-right",
                              attrs: {
                                type: _vm.password ? "password" : "text",
                                placeholder: _vm.$t("title10259"),
                              },
                              model: {
                                value: _vm.clockPw,
                                callback: function ($$v) {
                                  _vm.clockPw = $$v
                                },
                                expression: "clockPw",
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "lock input-icon",
                                attrs: { slot: "prefix" },
                                slot: "prefix",
                              }),
                              _c("i", {
                                class: [
                                  "input-icon",
                                  _vm.password ? "open" : "close",
                                ],
                                attrs: { slot: "suffix" },
                                on: {
                                  click: function ($event) {
                                    _vm.password = !_vm.password
                                  },
                                },
                                slot: "suffix",
                              }),
                            ]
                          ),
                          _c("el-button", {
                            staticClass: "join-left",
                            attrs: { type: "primary" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "other",
                  on: {
                    click: function ($event) {
                      _vm.showClockScreen = !_vm.showClockScreen
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("title10260")))]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "bottom" }, [
            _c("div", [
              _vm._v(
                _vm._s(_vm.$t("title10261")) +
                  " © " +
                  _vm._s(new Date().getFullYear()) +
                  " www.xinjianerp.com. All Rights Reserved."
              ),
            ]),
            _c("div", [
              _vm._v(
                _vm._s(_vm.$t("title10262")) +
                  "ICP" +
                  _vm._s(_vm.$t("title10263"))
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }