
export default {
  mounted(){
    setTimeout(()=>{
      const table=document.querySelector('.noborder-resize');
      if(!table){
        return;
      }
      const headCells=table.querySelectorAll('.el-table__header .cell');
      const line=table.querySelector('.el-table__column-resize-proxy');
      headCells.forEach(cell=>{
        cell.addEventListener('mousedown',(e)=>{
          if(e.target.className.indexOf('resize-label')===-1){
          line.style.borderColor='transparent';
          }else{
            line.style.borderColor='#dfe6ec';
          }
        })
      })
    },200)
  },
  methods: {
    headerDragend(newWidth, oldWidth, column, event){
      if(event.target.className.indexOf('resize-label')===-1){
        column.width=oldWidth
      }
    },
    headerClick(column, event){
      if(event.target.className.indexOf('resize-label')===-1){
        document.querySelector('.el-table__column-resize-proxy').style.display='none';
      }
      event.target.addEventListener('mousemove',(e)=>{
        if(event.target.className.indexOf('resize-label')===-1){
          document.querySelector('.el-table__column-resize-proxy').style.display='none';
        }
      })
    }
  }
}
