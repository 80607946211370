var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.item.hidden
    ? _c(
        "div",
        [
          _vm.hasOneShowingChild(_vm.item.children, _vm.item) &&
          (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) &&
          !_vm.item.alwaysShow
            ? [
                _vm.onlyOneChild.meta
                  ? _c(
                      "app-link",
                      { attrs: { to: _vm.resolvePath(_vm.onlyOneChild.path) } },
                      [
                        _c(
                          "el-menu-item",
                          {
                            staticClass: "sidebar-nav",
                            attrs: {
                              index: _vm.resolvePath(_vm.onlyOneChild.path),
                            },
                          },
                          [
                            _c("item", {
                              attrs: { icon: _vm.onlyOneChild.meta.icon },
                            }),
                            _c("div", { staticClass: "title-text" }, [
                              _vm._v(_vm._s(_vm.onlyOneChild.meta.title)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _c(
                "div",
                {
                  staticClass: "sidebar-nav",
                  class: {
                    active: _vm.$route.path.indexOf(_vm.item.path) === 0,
                  },
                  on: {
                    mouseleave: _vm.leaveDom,
                    mouseenter: function ($event) {
                      return _vm.emitChildren(_vm.item.children)
                    },
                    click: function ($event) {
                      return _vm.changeShow(_vm.item)
                    },
                  },
                },
                [
                  _vm.item.meta
                    ? _c("item", {
                        attrs: {
                          icon: _vm.item.meta && _vm.item.meta.icon,
                          title: _vm.item.meta.title,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
          false
            ? _c(
                "el-submenu",
                {
                  ref: "subMenu",
                  attrs: {
                    "show-timeout": 0,
                    "hide-timeout": 0,
                    "popper-class": "sidebar-fixed-sub",
                    index: _vm.resolvePath(_vm.item.path),
                    "popper-append-to-body": "",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "left-menu",
                      staticStyle: { height: "100%" },
                      on: { mouseleave: _vm.closeSub },
                    },
                    [
                      _c("div", { staticClass: "w-logo" }),
                      _c(
                        "div",
                        { staticClass: "navs" },
                        _vm._l(_vm.item.children, function (child, index) {
                          return _c("sidebar-item", {
                            key: child.path + index,
                            staticClass: "nest-menu",
                            attrs: {
                              "is-nest": true,
                              item: child,
                              "base-path": _vm.resolvePath(child.path),
                            },
                          })
                        }),
                        1
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }