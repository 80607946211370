var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drawer-container" }, [
    _c(
      "div",
      [
        _c("div", { staticClass: "setting-drawer-content" }, [
          _c("div", { staticClass: "setting-drawer-title" }, [
            _c("h3", { staticClass: "drawer-title" }, [
              _vm._v(_vm._s(_vm.$t("title10288"))),
            ]),
          ]),
          _c("div", { staticClass: "setting-drawer-block-checbox" }, [
            _c(
              "div",
              {
                staticClass: "setting-drawer-block-checbox-item",
                on: {
                  click: function ($event) {
                    return _vm.handleTheme("theme-dark")
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/dark.svg"),
                    alt: "dark",
                  },
                }),
                _vm.sideTheme === "theme-dark"
                  ? _c(
                      "div",
                      {
                        staticClass: "setting-drawer-block-checbox-selectIcon",
                        staticStyle: { display: "block" },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "anticon anticon-check",
                            attrs: {
                              "aria-label": _vm.$t("title10289") + ": check",
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  viewBox: "64 64 896 896",
                                  "data-icon": "check",
                                  width: "1em",
                                  height: "1em",
                                  fill: _vm.theme,
                                  "aria-hidden": "true",
                                  focusable: "false",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "setting-drawer-block-checbox-item",
                on: {
                  click: function ($event) {
                    return _vm.handleTheme("theme-light")
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/light.svg"),
                    alt: "light",
                  },
                }),
                _vm.sideTheme === "theme-light"
                  ? _c(
                      "div",
                      {
                        staticClass: "setting-drawer-block-checbox-selectIcon",
                        staticStyle: { display: "block" },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "anticon anticon-check",
                            attrs: {
                              "aria-label": _vm.$t("title10289") + ": check",
                            },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  viewBox: "64 64 896 896",
                                  "data-icon": "check",
                                  width: "1em",
                                  height: "1em",
                                  fill: _vm.theme,
                                  "aria-hidden": "true",
                                  focusable: "false",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "drawer-item" },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("title10290")))]),
              _c("theme-picker", {
                staticStyle: {
                  float: "right",
                  height: "26px",
                  margin: "-3px 8px 0 0",
                },
                on: { change: _vm.themeChange },
              }),
            ],
            1
          ),
        ]),
        _c("el-divider"),
        _c("h3", { staticClass: "drawer-title" }, [
          _vm._v(_vm._s(_vm.$t("title10291"))),
        ]),
        _c(
          "div",
          { staticClass: "drawer-item" },
          [
            _c("span", [_vm._v(_vm._s(_vm.$t("title10292")) + " TopNav")]),
            _c("el-switch", {
              staticClass: "drawer-switch",
              model: {
                value: _vm.topNav,
                callback: function ($$v) {
                  _vm.topNav = $$v
                },
                expression: "topNav",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "drawer-item" },
          [
            _c("span", [_vm._v(_vm._s(_vm.$t("title10292")) + " Tags-Views")]),
            _c("el-switch", {
              staticClass: "drawer-switch",
              model: {
                value: _vm.tagsView,
                callback: function ($$v) {
                  _vm.tagsView = $$v
                },
                expression: "tagsView",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "drawer-item" },
          [
            _c("span", [_vm._v(_vm._s(_vm.$t("title10293")) + " Header")]),
            _c("el-switch", {
              staticClass: "drawer-switch",
              model: {
                value: _vm.fixedHeader,
                callback: function ($$v) {
                  _vm.fixedHeader = $$v
                },
                expression: "fixedHeader",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "drawer-item" },
          [
            _c("span", [_vm._v(_vm._s(_vm.$t("title10185")) + " Logo")]),
            _c("el-switch", {
              staticClass: "drawer-switch",
              model: {
                value: _vm.sidebarLogo,
                callback: function ($$v) {
                  _vm.sidebarLogo = $$v
                },
                expression: "sidebarLogo",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "drawer-item" },
          [
            _c("span", [_vm._v(_vm._s(_vm.$t("title10294")))]),
            _c("el-switch", {
              staticClass: "drawer-switch",
              model: {
                value: _vm.dynamicTitle,
                callback: function ($$v) {
                  _vm.dynamicTitle = $$v
                },
                expression: "dynamicTitle",
              },
            }),
          ],
          1
        ),
        _c("el-divider"),
        _c(
          "el-button",
          {
            attrs: {
              size: "small",
              type: "primary",
              plain: "",
              icon: "el-icon-document-add",
            },
            on: { click: _vm.saveSetting },
          },
          [_vm._v(_vm._s(_vm.$t("title10295")))]
        ),
        _c(
          "el-button",
          {
            attrs: { size: "small", plain: "", icon: "el-icon-refresh" },
            on: { click: _vm.resetSetting },
          },
          [_vm._v(_vm._s(_vm.$t("title10296")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }