import request from '@/utils/request'

export default {
  getConfig(params) {
    return request({
      url: '/crm/kol-config/get-config',
      method: 'get',
      params
    })
  },
  update(data) {
    return request({
      url: '/crm/kol-config/update',
      method: 'put',
      data
    })
  },
}