var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("svg-icon", {
        attrs: { "icon-class": _vm.icon, "class-name": _vm.icon },
      }),
      _c(
        "span",
        { staticClass: "name", attrs: { slot: "title" }, slot: "title" },
        [_vm._v(_vm._s(_vm.$t("menu." + _vm.title)))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }