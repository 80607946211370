let currentVersion //当前版本
let version //新版本

const getVersion=async()=>{
  return fetch('/version.json?timestep='+Date.now()).then(res=>res.json());
}

const checkUpdate=async()=>{
  const currentVersion=localStorage.getItem('version')||'';
  version=(await getVersion()).version;
  // console.log('checkUpdate',currentVersion,version)
  if(!currentVersion){
    localStorage.setItem('version',version)
  }
  let needRefresh=false;
  if(version!==currentVersion){
    needRefresh=true
  };
  return needRefresh
}
export  {checkUpdate,getVersion}
