import request from '@/utils/request'

// 综合指标数据
export const comprehensiveData = (params) => {
  return request({
    url: '/erp/index/get-comprehensive-data',
    method: 'get',
    params
  })
}
// 获得首页数据
export const homeGetData = () => {
  return request({
    url: '/erp/index/get',
    method: 'get'
  })
}
// 获得广告实时分析数据
export const adRealTimeData = (params) => {
  return request({
    url: '/erp/index/get-ad-realTime-data',
    method: 'get',
    params
  })
}
// 获得授权过期店铺数量
export const expiredShopCnt = () => {
  return request({
    url: '/erp/index/get-expired-shop-cnt',
    method: 'get'
  })
}
// 获得小时实时销售数据
export const hourSaleData = (query) => {
  return request({
    url: '/erp/index/get-hour-sale-data',
    method: 'get',
    params:query
  })
}
// 获得广告数据
export const adData = () => {
  return request({
    url: '/erp/index/get-ad-data',
    method: 'get'
  })
}
// 获得广告数据更新时间
export const adDateUpdateTime = () => {
  return request({
    url: '/erp/ad/data/get-update-time',
    method: 'get'
  })
}

// 广告概况按店铺
export const adDataList = (query) => {
  return request({
    url: '/erp/ad/data/shop-list',
    method: 'get',
    params:query,
  })
}
// 广告概况按日期汇总数据
export const summaryByDate = (query) => {
  return request({
    url: '/erp/ad/data/summary-by-date',
    method: 'get',
    params:query
  })
}
// 广告概况、列表-汇总数据
export const adDetailsummary = (query) => {
  return request({
    url: '/erp/ad/data/adDetail/summary',
    method: 'get',
    params:query
  })
}
// 广告概况、列表-汇总数据
export const adDataSummary = (query) => {
  return request({
    url: '/erp/ad/data/summary',
    method: 'get',
    params:query
  })
}
// 广告详情分页接口
export const adDetailPage = (query) => {
  return request({
    url: '/erp/ad/data/adDetailPage',
    method: 'get',
    params:query
  })
}
// 获得近N天店铺利润分析
export const profitDaySummary = (day) => {
  return request({
    url: '/erp/shop-profit-day/summary/'+day,
    method: 'get'
  })
}
// 首页商品排行榜
export const homeSkuRank = (params) => {
  return request({
    url: '/erp/data-rank/local-sku-rank',
    method: 'get',
    params
  })
}
// 首页链接排行榜
export const homeLinkRank = (params) => {
  return request({
    url: '/erp/data-rank/link-rank',
    method: 'get',
    params
  })
}
// 首页规格排行榜
export const homeSubRank = (params) => {
  return request({
    url: '/erp/data-rank/sub-sku-rank',
    method: 'get',
    params
  })
}
// 获得订单城市分布排名
export const dayCityRank = (query) => {
  return request({
    url: '/erp/order-distribution-day/city-rank',
    method: 'get',
    params:query
  })
}


// 地区列表
export const regionList = () => {
  return request({
    url: '/system/region/list',
    method: 'get'
  })
}
// 用户分析总览
export const userAnalysis = (query) => {
  return request({
    url: '/erp/user-analysis/data',
    method: 'get',
    params:query
  })
}
// 用户列表
export const userAnalysisList = (query) => {
  return request({
    url: '/erp/user-analysis-list/list',
    method: 'get',
    params:query
  })
}
// 导出复购数据
export const exportUserOrder = (query) => {
  return request({
    url: '/erp/user-analysis-list/export-user-order-excel',
    method: 'get',
    params:query,
  })
}
// 销售表现
export const salePerformance = (query) => {
  return request({
    url: '/erp/user-analysis/sale-performance',
    method: 'get',
    params:query
  })
}
// 订单记录
export const orderHistory = (query) => {
  return request({
    url: '/erp/user-analysis-list/order-history',
    method: 'get',
    params:query
  })
}
// 商品记录
export const productHistory = (query) => {
  return request({
    url: '/erp/user-analysis-list/product-history',
    method: 'get',
    params:query
  })
}


//店铺周期性对比- 成本组成概况
export const costReport = (query) => {
  return request({
    url: '/erp/shop-compare-period/cost-report',
    method: 'get',
    params:query
  })
}
//店铺周期性对比- 导出成本组成概况
export const exportCostExcel = (query) => {
  return request({
    url: '/erp/shop-compare-period/export-cost-excel',
    method: 'get',
    params:query,
    responseType: 'blob',
    timeout: 600000
  })
}
//店铺周期性对比- 店铺销售报告
export const saleReport = (query) => {
  return request({
    url: '/erp/shop-compare-period/sale-report',
    method: 'get',
    params:query
  })
}
//店铺周期性对比- 导出店铺销售报告 Excel
export const exportSaleExcel = (query) => {
  return request({
    url: '/erp/shop-compare-period/export-sale-excel',
    method: 'get',
    params:query
  })
}
//店铺周期性对比- 销售TOP5
export const saleTopFive = (query) => {
  return request({
    url: '/erp/shop-compare-period/sale-top-five',
    method: 'get',
    params:query
  })
}


//店铺预警列表
export const shopHealthAlertList = (query) => {
  return request({
    url: '/erp/shop-health/alert-list',
    method: 'get',
    params:query
  })
}
//目前处分
export const shopHealthPenalty = (query) => {
  return request({
    url: '/erp/shop-health/penalty',
    method: 'get',
    params:query
  })
}
//店铺情况
export const shopHealthSituation = (query) => {
  return request({
    url: '/erp/shop-health/situation',
    method: 'get',
    params:query
  })
}
//健康状况-更新数据
export const healthUpdateData = () => {
  return request({
    url: '/erp/shop-health/updateData',
    method: 'get'
  })
}
//导出店铺情况
export const exportSituation = (query) => {
  return request({
    url: '/erp/shop-health/export-situation',
    method: 'get',
    params:query,
    responseType: 'blob',
    timeout: 600000
  })
}

//店铺健康状况
export const shopHealthTop = (query) => {
  return request({
    url: '/erp/shop-health/top-data',
    method: 'get',
    params:query
  })
}

//广告管理-顶部汇总数据和列表
export const adDataTop = (query) => {
  return request({
    url: '/erp/ad/data/data',
    method: 'get',
    params:query
  })
}
//广告管理-广告活动效果TOP10
export const adActivity = (query) => {
  return request({
    url: '/erp/ad/data/adActivity',
    method: 'get',
    params:query
  })
}
//广告管理-商品广告销售额TOP10
export const getProductCostList = (query) => {
  return request({
    url: '/erp/ad/data/productCostList',
    method: 'get',
    params:query
  })
}
//广告管理-店铺广告花费TOP10
export const getShopCostList = (query) => {
  return request({
    url: '/erp/ad/data/shopCostList',
    method: 'get',
    params:query
  })
}
//获得租户套餐
export const tenantPackage = () => {
  return request({
    url: '/erp/tenant/get',
    method: 'get'
  })
}
//获得租户购买套餐订单记录 列表
export const packageOrderList = (query) => {
  return request({
    url: '/erp/user-pay-order/page',
    method: 'get',
    params:query
  })
}

//获得支付渠道分页
export const channelList = (query) => {
  return request({
    url: '/pay/channel/page',
    method: 'get',
    params:query
  })
}

//获得租户套餐列表
export const tenantPackageList = (query) => {
  return request({
    url: '/erp/user-pay-order/getTenantPackageList',
    method: 'get',
    params:query
  })
}
//获得套餐支付价目列表
export const payPackageList = (query) => {
  return request({
    url: '/erp/user-pay-order/getPackageList',
    method: 'get',
    params:query
  })
}
//获取套餐需要支付的金额
export const getPayAmount = (query) => {
  return request({
    url: '/erp/user-pay-order/getPayAmount',
    method: 'get',
    params:query
  })
}
//创建支付订单
export const orderCreate = (data) => {
  return request({
    url: '/erp/user-pay-order/createPackage',
    method: 'post',
    data:data
  })
}
//创建支付订单
export const orderCreate_2 = (data) => {
  return request({
    url: '/erp/user-pay-order/hand-createPackage',
    method: 'post',
    data:data
  })
}
//加油包支付预下单订单
export const createCheeringPackage = (data) => {
  return request({
    url: '/erp/user-pay-order/createCheeringPackage',
    method: 'post',
    data:data
  })
}

//获取我的套餐
export const getMyPackage = () => {
  return request({
    url: '/erp/user/get-my-package',
    method: 'get'
  })
}
//获取我的套餐列表
export const getMyPackageList = () => {
  return request({
    url: '/erp/user/get-my-package-list',
    method: 'get'
  })
}
export const getMyPackageList_2 = (params) => {
  return request({
    url: '/erp/user/get-package-list',
    method: 'get',
    params
  })
}
export const upDateMyPackage_2 = (data) => {
  return request({
    url: '/system/user-pay-package/update',
    method: 'put',
    data
  })
}
//获取套餐列表
export const getSimplePackageList = () => {
  return request({
    url: '/system/tenant-package/get-simple-list',
    method: 'get'
  })
}
//获取加油包的价格列表
export const getCheeringPackageList = () => {
  return request({
    url: '/erp/user-pay-order/getCheeringPackageList',
    method: 'get'
  })
}
//完成对公转账
export const finishTransfer = (data) => {
  return request({
    url: '/erp/user-pay-order/finishTransfer',
    method: 'post',
    data
  })
}
//取消订单
export const cancelOrder = (data) => {
  return request({
    url: '/erp/user-pay-order/cancel',
    method: 'post',
    data
  })
}
//订单详情
export const orderDetail = (id) => {
  return request({
    url: '/erp/user-pay-order/get?id='+id,
    method: 'get',
  })
}
//订单支付状态
export const payNotify = (query) => {
  return request({
    url: '/erp/user-pay-order/get-pay-status',
    method: 'get',
    params:query
  })
}
//获得订单监控统计数据
export const diagnosisOrderSummary = (query) => {
  return request({
    url: '/erp/diagnosis-v2/order/summary',
    method: 'get',
    params:query
  })
}
//获得订单监控分页
export const diagnosisOrderPage = (query) => {
  return request({
    url: '/erp/diagnosis-v2/order/page',
    method: 'get',
    params:query
  })
}
//订单监控导出
export const diagnosisOrderExport = (query) => {
  return request({
    url: '/erp/diagnosis-v2/export-order-excel',
    method: 'get',
    params:query,
    responseType: 'blob',
    timeout: 600000
  })
}
//获得运营人员
export const kpiGetUser = (query) => {
  return request({
    url: '/erp/kpi-config/get-user',
    method: 'get',
  })
}
//获得业绩目标设置
export const kpiGetList = (data) => {
  return request({
    url: '/erp/kpi-config/get-list',
    method: 'post',
    data
  })
}
//获得业绩目标设置
export const kpiGet = (data) => {
  return request({
    url: '/erp/kpi-config/get-kpi',
    method: 'post',
    data
  })
}
//创建业绩目标设置
export const kpiCreate = (data) => {
  return request({
    url: '/erp/kpi-config/create',
    method: 'post',
    data
  })
}
//设置运营人员
export const setCheckedKpiUser = (data) => {
  return request({
    url: '/erp/kpi-config/set-user',
    method: 'post',
    data
  })
}
//设置运营人员
export const getCheckedKpiUser = () => {
  return request({
    url: '/erp/kpi-config/get-user-set',
    method: 'get',
  })
}
