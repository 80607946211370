var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "my-dialog",
    {
      attrs: { title: _vm.$t("title11006"), width: "860px" },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c("div", { staticClass: "flex-row" }, [
        _c(
          "div",
          { staticClass: "select-view" },
          [
            _c("div", { staticClass: "warning" }, [
              _vm._v(_vm._s(_vm.$t("title11007"))),
            ]),
            _c(
              "el-checkbox",
              {
                staticStyle: { "margin-bottom": "20px" },
                attrs: { indeterminate: _vm.isIndeterminate },
                on: { change: _vm.handleCheckAllChange },
                model: {
                  value: _vm.checkAll,
                  callback: function ($$v) {
                    _vm.checkAll = $$v
                  },
                  expression: "checkAll",
                },
              },
              [_vm._v("全选")]
            ),
            _c(
              "el-checkbox-group",
              {
                model: {
                  value: _vm.selectArr,
                  callback: function ($$v) {
                    _vm.selectArr = $$v
                  },
                  expression: "selectArr",
                },
              },
              _vm._l(_vm.selectOption, function (item) {
                return _c(
                  "el-col",
                  {
                    key: item.code,
                    staticStyle: { padding: "0", "margin-bottom": "20px" },
                    attrs: { span: 8 },
                  },
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { disabled: item.disabled, label: item.code },
                        on: {
                          change: function (val) {
                            return _vm.selectItem(item, val)
                          },
                        },
                      },
                      [_vm._v(_vm._s(item.name))]
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "sort_view_outside" }, [
          _c(
            "div",
            { ref: "sort_view", staticClass: "sort_view flex-col" },
            [
              _c(
                "div",
                { staticStyle: { "margin-bottom": "16px", color: "#999" } },
                [_vm._v(_vm._s(_vm.$t("title11008")))]
              ),
              _vm._l(_vm.fixedArr, function (item) {
                return _c(
                  "div",
                  {
                    key: "fixeditem_" + item.code,
                    staticClass: "sortItem flex-row jc-between",
                  },
                  [
                    _c("div", { staticClass: "flex-row align-center" }, [
                      _c("img", {
                        staticStyle: {
                          width: "16px",
                          height: "16px",
                          margin: "0 10px",
                        },
                        attrs: { src: require("@/assets/images/sortIcon.png") },
                      }),
                      _c("span", [_vm._v(_vm._s(item.name))]),
                    ]),
                    _c("div", { staticClass: "flex-row align-center" }, [
                      _c("img", {
                        staticClass: "sortIcon",
                        attrs: {
                          src: require("@/assets/images/sort_fixed.png"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.unfixed(item.code)
                          },
                        },
                      }),
                    ]),
                  ]
                )
              }),
              _vm._l(_vm.sortOption, function (item, index) {
                return _c(
                  "div",
                  {
                    key: "item_" + item.code,
                    staticClass: "sortItem flex-row jc-between draggable",
                  },
                  [
                    _c("div", { staticClass: "flex-row align-center" }, [
                      _c("img", {
                        staticStyle: {
                          width: "16px",
                          height: "16px",
                          margin: "0 10px",
                        },
                        attrs: { src: require("@/assets/images/sortIcon.png") },
                      }),
                      _c("span", [_vm._v(_vm._s(item.name))]),
                    ]),
                    _c("div", { staticClass: "flex-row align-center" }, [
                      !item.disabled
                        ? _c("img", {
                            staticClass: "sortIcon",
                            attrs: {
                              src: require("@/assets/images/sort_delete.png"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(item.code)
                              },
                            },
                          })
                        : _vm._e(),
                      _c("img", {
                        staticClass: "sortIcon",
                        attrs: {
                          src: require("@/assets/images/sort_beTop.png"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.rise(item.code, index)
                          },
                        },
                      }),
                      _c("img", {
                        staticClass: "sortIcon",
                        attrs: {
                          src: require("@/assets/images/sort_beFixed.png"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.beFixed(item.code)
                          },
                        },
                      }),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v(_vm._s(_vm.$t("title587")))]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v(_vm._s(_vm.$t("title1013")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }