
 
import store from '@/store'

export default {
  inserted(el, binding, vnode) {

    const currentRoute = vnode.context.$route;
    // 检查当前路由的路径是否以 '/admin-ui/crm/' 开头
    if (currentRoute.path.startsWith('/crm/')) {
        const kolAuth = store.getters.kolAuth;
        // 如果没有权限执行删除操作
        if (!kolAuth.exportAuthButton) {
          el.parentNode && el.parentNode.removeChild(el);
        }
    }
  }
}
