var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pagination-container", class: { hidden: _vm.hidden } },
    [
      _vm.simple
        ? _c("el-pagination", {
            attrs: {
              total: _vm.total,
              "page-size": _vm.pageSize,
              "current-page": _vm.currentPage,
              background: _vm.background,
              layout: "total, prev, pager, next",
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              "current-change": _vm.handleCurrentChange,
            },
          })
        : _c(
            "el-pagination",
            _vm._b(
              {
                attrs: {
                  background: _vm.background,
                  "current-page": _vm.currentPage,
                  "page-size": _vm.pageSize,
                  layout: _vm.layout,
                  "page-sizes": _vm.pageSizes,
                  "pager-count": _vm.pagerCount,
                  total: _vm.total,
                },
                on: {
                  "update:currentPage": function ($event) {
                    _vm.currentPage = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.currentPage = $event
                  },
                  "update:pageSize": function ($event) {
                    _vm.pageSize = $event
                  },
                  "update:page-size": function ($event) {
                    _vm.pageSize = $event
                  },
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              },
              "el-pagination",
              _vm.$attrs,
              false
            )
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }