<template>
  <div class="tableCharts"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name:'',
  props:{
    rows:{
        type:Array,
        default:[]
    },
    ids:{
        type:Number,
        default: null
    }
  },
  data() {
    return {
      xData:[],
      yData:[],
      timeId: null
    }
  },
  mounted() {
    this.timeId = setTimeout(() => {
      this.xData = this.rows.map(i => i.report_date)
      this.yData = this.rows.map(i => i.sale_volume)
      this.setLineChart(this.yData, this.xData, this.ids)
    }, 300)
  },
  beforeDestroy() {
    clearTimeout(this.timeId)
  },
  methods: {
    setLineChart(yData, xData, index){
      var chartDom = document.getElementsByClassName('tableCharts')[index];
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          show: false, // 隐藏 x 轴
          data: xData
        },
        yAxis: {
          type: 'value',
          show: false, // 隐藏 y 轴
          axisLabel: {
              formatter: function (value) {
                return value;
              }
          }
        },
        series: [
          {
            data: yData,
            type: 'line',
            smooth: true,
            itemStyle: {
              color: '#F5222D', // 设置圆点颜色
            },
            lineStyle: {
                width: 0.5,
                color: '#F5222D'
            },
            emphasis: {
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: '#FAD1D6' }, // 顶部颜色
                    { offset: 1, color: '#FFFFFF' }  // 底部颜色
                ])
              },
              itemStyle: {
                color: '#F5222D',
                borderColor: '#F5222D', // 设置圆点外边框颜色
                borderWidth: 2// 设置圆点外边框的粗细
              },
            },
            showSymbol:false,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: '#FAD1D6' }, // 顶部颜色
                    { offset: 1, color: '#FFFFFF' }  // 底部颜色
                ])
            },
            symbolSize: 0 // 设置圆点大小
          }
        ],
        grid:{ // 让图表占满容器
          top:"5",
          left:"5",
          right:"5",
          bottom:"0",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none' // 不显示坐标轴指示器
          },
          z: 9999,
          appendToBody: true, // 确保 tooltip 被添加到 body 元素
          formatter: (params) => {
            let html = `<div class="flex-col echarts-tooltip">
                <span style="color:#999;font-size:12px;margin-right:30px">${params[0].name}</span>
                <span style="color:#999;font-size:12px;">销量：${params[0].value}</span>
            </div>`;
            return html;
          },
        },
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
        myChart.setOption(option);
      });
    },
  },
}
</script>
<style lang='scss' scoped>
.tableCharts{
    width: 150px;
    height: 60px;
    overflow: visible;
}
</style>
<style>
</style>