function convertToWan(num) {
  if (num >= 10000) {
    // 将数字除以 10000 并保留最多两位小数
    return (num / 10000).toFixed(2).replace(/\.00$/, "") + "万";
  }
  return num.toString();
}
export const notifyImport = function(_this,data){
    let info = data.info
    let taskDicts = _this.getDictDatas(_this.DICT_TYPE.CRM_IMPORT_TASK);
    let title = taskDicts.find(item => {
        return item.value == info.importTaskKey
    })
    const notify = _this.$notify({
        dangerouslyUseHTMLString: true,
        position: 'bottom-right',
        showClose: false,
        duration:15000,
        customClass:"my-notify",
        message: `<div style="width:332px;height:86px;margin:20px">
          <div style="display:flex;margin-bottom:20px;align-items:center;justify-content:space-between">
            <div style="display:flex;align-items:center;">
              <img style="width:16px;height:16px;margin-right:10px" src="${require("@/assets/images/crm/success.png")}" />
              <span style="font-size:14px;font-weight:600">${title.label}成功</span>
            </div>
            <div class="close" style="font-size:20px;color:#ccc">×</div>      
          </div>  
          <div style="display:flex;justify-content:space-between;align-items:flex-center">
            <div style="margin-left:26px;display:flex;align-items:center">总数<span style="margin:0 5px;color: #1377FF;">${info.totalNum}</span>条，成功<span style="margin:0 5px;color: #FF3B5C;">${info.successNum}</span>条</div>
            <div class="checkDetail" style="padding:6px 8px;border: 1px solid rgba(0,0,0,0.06);border-radius: 4px;text-align: center;cursor:pointer">
              查看详情
            </div>
          </div>
        </div>`
      });
    notify.$el.querySelector(".checkDetail").onclick = () => {
        _this.$store.state.missionCenter.openDialog(info)
        notify.close()
    }
    notify.$el.querySelector(".close").onclick = () => {
      notify.close()
    }
}

export const notifyInclude = function(_this,data){
    const country = {
      BR: {
        img: require('@/assets/images/country/BR.png'),
        name: _this.$t('title675')
      },
      TW: {
        img: require('@/assets/images/country/CN.png'),
        name: _this.$t('title492')
      },
      ID: {
        img: require('@/assets/images/country/ID.png'),
        name: _this.$t('title486')
      },
      MX: {
        img: require('@/assets/images/country/MX.png'),
        name: _this.$t('title676')
      },
      MY: {
        img: require('@/assets/images/country/MY.png'),
        name: _this.$t('title490')
      },
      PH: {
        img: require('@/assets/images/country/PH.png'),
        name: _this.$t('title487')
      },
      SG: {
        img: require('@/assets/images/country/SG.png'),
        name: _this.$t('title491')
      },
      TH: {
        img: require('@/assets/images/country/TH.png'),
        name: _this.$t('title488')
      },
      VN: {
        img: require('@/assets/images/country/VN.png'),
        name: _this.$t('title489')
      }
    }
    let info = data.info
    const notify = _this.$notify({
        dangerouslyUseHTMLString: true,
        position: 'bottom-right',
        showClose: false,
        duration:15000,
        customClass:"my-notify",
        message: `<div style="width:332px;height:128px;margin:20px">
          <div style="display:flex;margin-bottom:20px;align-items:center;justify-content:space-between">
            <div style="display:flex;align-items:center">
              <img style="width:16px;height:16px;margin-right:10px" src="${require("@/assets/images/crm/success.png")}" />
              <span style="font-size:14px;font-weight:600">收录完成</span>
            </div>
            <div class="close" style="font-size:20px;color:#ccc">×</div>      
          </div>  
          <div style="display:flex;justify-content:space-between;align-items:flex-start">
            <div style="display:flex">
              <img style="width:46px;height:46px;margin-right:20px;border-radius:50%" src="${info.avatar}" />  
              <div style="display:flex;flex-direction:column;line-height:1;"> 
                <div style="font-weight:600;margin-bottom:8px;font-size:12px">${info.nickName}</div>
                <div style="margin-bottom:8px;font-size:12px">ID：${info.uid}</div>
                <div style="margin-bottom:8px;font-size:12px">粉丝数：${convertToWan(info.follower)}</div>
                <div>
                  <img src="${country[info.region] ? country[info.region].img : '-'}" />
                  <span>${country[info.region] ? country[info.region].name : '-'}</span>
                </div>
              </div>
            </div>
            <div>
              <div class="checkDetail" style="margin-top:17px;padding:6px 8px;border: 1px solid rgba(0,0,0,0.06);border-radius: 4px;text-align: center;cursor:pointer">
                查看详情
              </div>
            </div>
          </div>
        </div>`
      });
    
    notify.$el.querySelector(".checkDetail").onclick = () => {
        _this.$router.push({
          name:'masterDetail',
          params:{
            id:info.id,
            uid:info.uid
          }
        })
        notify.close()
    }
    notify.$el.querySelector(".close").onclick = () => {
      notify.close()
    }
}