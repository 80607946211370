import request from "@/utils/request";
export default {
  // 获取收录任务分页
  getIncludeTask(params) {
    return request({
      url: "/crm/include-task/page",
      method: "get",
      params,
    });
  },
  //获得导入任务分页
  getImportTask(params) {
    return request({
      url: "/system/import-task/page",
      method: "get",
      params,
    });
  },
  //下载错误信息
  downError(url){
    return request({
      url: url,
      method: "get",
      responseType: 'blob',
    });
  }
};
