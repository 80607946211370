import { render, staticRenderFns } from "./SidebarItemSet.vue?vue&type=template&id=3d8335f3&"
import script from "./SidebarItemSet.vue?vue&type=script&lang=js&"
export * from "./SidebarItemSet.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/erp-admin-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d8335f3')) {
      api.createRecord('3d8335f3', component.options)
    } else {
      api.reload('3d8335f3', component.options)
    }
    module.hot.accept("./SidebarItemSet.vue?vue&type=template&id=3d8335f3&", function () {
      api.rerender('3d8335f3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/components/Sidebar/SidebarItemSet.vue"
export default component.exports